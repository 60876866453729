export const BAD_WORDS: string[] = [
    "인스타그램",
    "instagram",
    "インスタグラム",
    "อินสตาแกรม",
    "instakraem",
    "ig",
    "페이스북",
    "facebook",
    "フェイスブック",
    "เฟซบุ๊ก",
    "fesbuk",
    "왓츠앱",
    "워츠앱",
    "와츠앱",
    "whatsapp",
    "ワッツアップ",
    "วอตส์แอป",
    "wotsæ̀p",
    "라인",
    "line",
    "ライン",
    "ไลน์",
    "lai",
    "위챗",
    "wechat",
    "ウィーチャット",
    "微信",
    "weixin",
    "วีแชท",
    "wīchæt",
    "구글 챗",
    "구글챗",
    "구글 쳇",
    "구글쳇",
    "google chat",
    "googlechat",
    "グーグルチャット",
    "google 聊天",
    "google Liáotiān",
    "กูเกิลแชท",
    "kūkel chæt",
    "구글 메시지",
    "구글메시지",
    "구글 메세지",
    "구글메세지",
    "google message",
    "googlemessage",
    "グーグルメッセージ",
    "google 消息",
    "google 訊息",
    "กูเกิลเมสเสจ",
    "Kūkel Mèssech",
    "메신저",
    "messenger",
    "メッセンジャー",
    "信使",
    "xìnshǐ",
    "เมสเซนเจอร์",
    "mètsenjœ̄",
    "다이렉트 메세지",
    "다이렉트 메시지",
    "다이렉트메세지",
    "다이렉트메시지",
    "direct message",
    "directmessage",
    "ダイレクトメッセージ",
    "私信",
    "sīxìn",
    "私訊",
    "sīxùn",
    "ข้อความโดยตรง",
    "kh̀xkhwām doy trng",
    "tin nhắn trực tiếp",
    "pesan langsung",
    "dm",
    "아이 메세지",
    "아이메세지",
    "아이 메시지",
    "아이메시지",
    "i 메세지",
    "i메세지",
    "i 메시지",
    "i메시지",
    "imessage",
    "i message",
    "ไอเมสเสจ",
    "스냅 챗",
    "스냅챗",
    "스냅 쳇",
    "스냅 쳇",
    "스넵 챗",
    "스넵챗",
    "스넵 쳇",
    "스넵쳇",
    "스냅 chat",
    "스냅chat",
    "스넵 chat",
    "스넵chat",
    "snap 챗",
    "snap챗",
    "snap 쳇",
    "snap쳇",
    "snap chat",
    "snapchat",
    "スナップチャット",
    "สแนปแชท",
    "sanæp chæt",
    "시그널",
    "시그날",
    "signal",
    "シグナル",
    "สัญญาณ",
    "S̄ảỵỵāṇ",
    "위커",
    "wickr",
    "ウィッカー",
    "วิกเกอร์",
    "Wik̄kexr̒",
    "디스코드",
    "discord",
    "ディスコード",
    "ดิสคอร์ด",
    "diskhxd",
    "잘로",
    "zalo",
    "ザロ",
    "ซาโล",
    "Sālo",
    "텔레 그램",
    "텔레그램",
    "텔레 그람",
    "텔레그람",
    "테레 그램",
    "테레그램",
    "테레 그람",
    "테레그람",
    "텔레 글램",
    "텔레글램",
    "테레 글램",
    "테레글램",
    "telegram",
    "テレグラム",
    "เทเลแกรม",
    "thelekræm",
];
