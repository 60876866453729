import React, { useEffect, useRef, useState } from "react";
import ProfileComponent from "./Profile.component";
import { useLocation, useNavigate } from "react-router-dom";

import * as ProfileApi from "api/student/Profile.api";
import * as ImageHelper from "helpers/Image.helper";
import * as VideoHelper from "helpers/Video.helper";
import * as DataApi from "api/common/Data.api";
import * as AgencyApi from "api/agency/Agency.api";

const ProfileContainer = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tab, setTab] = useState(location.state?.tab || "profile");
  const [profileSubTab, setProfileSubTab] = useState(
    location.state?.profileSubTab || "main"
  );
  const [profileCategoryTab, setProfileCategoryTab] = useState("front");

  const [profile, setProfile] = useState(null);
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [practiceList, setPracticeList] = useState(null);
  const [isPracticeLoading, setIsPracticeLoading] = useState(true);
  const inputRef = useRef<any>();

  const [isOnNetworking, setIsOnNetworking] = useState(false);
  const videoRef = useRef(null);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [initFlag, setInitFlag] = useState(false);

  const [isShowPopup, setIsShowPopup] = useState(false);
  const [popupList, setPopupList] = useState([]);
  const [profileMediaType, setProfileMediaType] = useState("");
  const [isAgencyPopup, setIsAgencyPopup] = useState(false);
  const [agencyInfo, setAgencyInfo] = useState(null);

  const searchParams = new URLSearchParams(location.search);
  const limit = 8;
  const [page, setPage] = useState(
    location.state?.page || +searchParams.get("page") || 1
  );

  const [totalCount, setTotalcount] = useState(0);

  const getProfile = async () => {
    const result = await ProfileApi.getInfoForStudent();
    if (result?.info) {
      if (result.info.introVideoInfo) {
        const videoInfo = await DataApi.getVideoPresignedUrl(
          result.info.introVideoInfo.videoId
        );
        if (videoInfo) {
          result.info.introVideoInfo.url = videoInfo.result;
        }
      }

      if (result.info.faceImageList) {
        const frontImage = result.info.faceImageList.find(
          (image: { profileImageTypeCode: string }) =>
            image.profileImageTypeCode === "profileImageType:front"
        );

        const backImage = result.info.faceImageList.find(
          (image: { profileImageTypeCode: string }) =>
            image.profileImageTypeCode === "profileImageType:back"
        );

        const leftImage = result.info.faceImageList.find(
          (image: { profileImageTypeCode: string }) =>
            image.profileImageTypeCode === "profileImageType:left"
        );

        const rightImage = result.info.faceImageList.find(
          (image: { profileImageTypeCode: string }) =>
            image.profileImageTypeCode === "profileImageType:right"
        );
        result.info.faceImageInfo = {};
        result.info.faceImageInfo.front = frontImage;
        result.info.faceImageInfo.back = backImage;
        result.info.faceImageInfo.left = leftImage;
        result.info.faceImageInfo.right = rightImage;

        if (frontImage) {
          const frontInfo = await DataApi.getImagePresignedUrl(
            result.info.faceImageInfo.front.imageId
          );
          if (frontInfo) {
            result.info.faceImageInfo.front.url = frontInfo.result;
          }
        }

        if (backImage) {
          const backInfo = await DataApi.getImagePresignedUrl(
            result.info.faceImageInfo.back.imageId
          );
          if (backInfo) {
            result.info.faceImageInfo.back.url = backInfo.result;
          }
        }

        if (leftImage) {
          const leftInfo = await DataApi.getImagePresignedUrl(
            result.info.faceImageInfo.left.imageId
          );
          if (leftInfo) {
            result.info.faceImageInfo.left.url = leftInfo.result;
          }
        }

        if (rightImage) {
          const rightInfo = await DataApi.getImagePresignedUrl(
            result.info.faceImageInfo.right.imageId
          );
          if (rightInfo) {
            result.info.faceImageInfo.right.url = rightInfo.result;
          }
        }
      }

      if (result.info.fullBodyImageList) {
        const frontImage = result.info.fullBodyImageList.find(
          (image: { profileImageTypeCode: string }) =>
            image.profileImageTypeCode === "profileImageType:front"
        );

        const backImage = result.info.fullBodyImageList.find(
          (image: { profileImageTypeCode: string }) =>
            image.profileImageTypeCode === "profileImageType:back"
        );

        const leftImage = result.info.fullBodyImageList.find(
          (image: { profileImageTypeCode: string }) =>
            image.profileImageTypeCode === "profileImageType:left"
        );

        const rightImage = result.info.fullBodyImageList.find(
          (image: { profileImageTypeCode: string }) =>
            image.profileImageTypeCode === "profileImageType:right"
        );
        result.info.fullBodyImageInfo = {};
        result.info.fullBodyImageInfo.front = frontImage;
        result.info.fullBodyImageInfo.back = backImage;
        result.info.fullBodyImageInfo.left = leftImage;
        result.info.fullBodyImageInfo.right = rightImage;

        if (frontImage) {
          const frontInfo = await DataApi.getImagePresignedUrl(
            result.info.fullBodyImageInfo.front.imageId
          );
          if (frontInfo) {
            result.info.fullBodyImageInfo.front.url = frontInfo.result;
          }
        }

        if (backImage) {
          const backInfo = await DataApi.getImagePresignedUrl(
            result.info.fullBodyImageInfo.back.imageId
          );
          if (backInfo) {
            result.info.fullBodyImageInfo.back.url = backInfo.result;
          }
        }

        if (leftImage) {
          const leftInfo = await DataApi.getImagePresignedUrl(
            result.info.fullBodyImageInfo.left.imageId
          );
          if (leftInfo) {
            result.info.fullBodyImageInfo.left.url = leftInfo.result;
          }
        }

        if (rightImage) {
          const rightInfo = await DataApi.getImagePresignedUrl(
            result.info.fullBodyImageInfo.right.imageId
          );
          if (rightInfo) {
            result.info.fullBodyImageInfo.right.url = rightInfo.result;
          }
        }
      }

      setProfile(result.info);
      if (result.info.introVideoInfo) {
        setSelectedVideoUrl(result.info.introVideoInfo.url);
      }
    }
  };

  const getPractice = async () => {
    setIsPracticeLoading(true);
    const result = await ProfileApi.getListPractice({ page, limit });

    if (result?.list) {
      const updatedList = await Promise.all(
        result.list.map(async (video: any) => {
          const videoInfo = await DataApi.getVideoPresignedUrl(video.videoId);
          if (videoInfo) {
            return {
              ...video,
              url: videoInfo.result,
            };
          }
          return video;
        })
      );

      setPracticeList(updatedList);
      setTotalcount(result.totalCount);
    }
    setIsPracticeLoading(false);
  };

  const captureThumbnail = (videoUrl: string, idx: number) => {
    const videoElement = document.createElement("video");
    videoElement.src = videoUrl;
    videoElement.crossOrigin = "anonymous";
    videoElement.muted = true;
    videoElement.playsInline = true;

    videoElement.addEventListener("loadeddata", () => {
      videoElement.currentTime = 0;
    });

    videoElement.addEventListener("seeked", () => {
      const canvas = document.getElementById(
        `canvas-${idx}`
      ) as HTMLCanvasElement;

      if (canvas) {
        const context = canvas.getContext("2d");

        if (context) {
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;

          context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

          const imgElement = document.getElementById(
            `thumbnail-${idx}`
          ) as HTMLImageElement;
          imgElement.src = canvas.toDataURL("image/png");
          imgElement.style.visibility = "visible";
        }
      }
    });

    videoElement.load();
  };

  const toggleActiveProfile = async () => {
    inputRef.current.disabled = true;
    const result = await ProfileApi.updateActiveProfile({
      isProfileActive: !profile.isProfileActive,
    });

    if (result && result.status === 200) {
      await getProfile();
    }
    inputRef.current.disabled = false;
  };

  const uploadImage = async (evt: any, category: string, type: string) => {
    setIsOnNetworking(true);

    if (!evt) {
      return false;
    }

    const file = evt.target.files[0];
    const imageInfo = await ImageHelper.getImageInfo(file);
    if (!imageInfo) {
      alert("Please choose image file");
      return false;
    }

    const result = await DataApi.uploadImage(file, false);
    imageInfo.id = result.id;

    const upload = await ProfileApi.createProfileImage({
      imageId: result.id,
      profileImageCategoryCode: category,
      profileImageTypeCode: type,
    });

    if (upload && upload.status === 200) {
      await getProfile();
    }

    setIsOnNetworking(false);
  };

  const uploadVideo = async (evt: any) => {
    if (!isOnNetworking) {
      setIsOnNetworking(true);

      if (!evt) {
        return false;
      }

      const file = evt.target.files[0];
      const videoInfo = await VideoHelper.getVideoInfo(file);
      if (!videoInfo) {
        alert("Please choose video file");
        return false;
      }

      const result = await DataApi.uploadVideo(file, false);
      videoInfo.id = result.id;

      const upload = await ProfileApi.createIntroVideo({ videoId: result.id });
      if (upload && upload.status === 200) {
        await getProfile();
      }

      setIsOnNetworking(false);
    }
  };

  const getVideoUrl = async (id: string) => {
    if (id) {
      const videoInfo = await DataApi.getVideoPresignedUrl(id);
      if (videoInfo) {
        const url = videoInfo.result;
        return url;
      }
    }
  };

  const handleEnded = () => {
    console.log('ended')
    setCurrentTime(0);
  };

  const handleSeeked = () => {
    console.log('seek')
    const video = videoRef.current;
    if (video) {
      setCurrentTime(video.currentTime);
      video.play();
    }
  };

  const handlePlay = () => {
    console.log('play')
    setInitFlag(false);
    
  };

  const handlePause = () => {
    console.log('pause')
    const video = videoRef.current;
    if (video) {
      setCurrentTime(video.currentTime);
    }
    setInitFlag(true);
  };

  const openViewPopup = async (
    profileMediaId: string,
    profileMediaType: string
  ) => {
    if (profileMediaId) {
      const result = await ProfileApi.getListViewedAgency({
        profileMediaId: profileMediaId,
        profileMediaType: profileMediaType,
      });

      if (result.list) {
        setPopupList(result.list);
      }
      setProfileMediaType(profileMediaType);
      setIsShowPopup(true);
    }
  };

  const openAgencyPopup = async (id: number) => {
    const result = await AgencyApi.getInfo(id);
    if (result.info) {
      setAgencyInfo(result.info);
    }
    setIsAgencyPopup(true);
  };

  useEffect(() => {
    const handleInitFlagChange = async () => {
      if (!initFlag) {
        const url = await getVideoUrl(profile?.introVideoInfo?.videoId);
        setSelectedVideoUrl(url);
        setInitFlag(false);
      }
    };

    handleInitFlagChange();
  }, [initFlag]);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      video.src = selectedVideoUrl;
      video.muted = true;
      video.playsInline = true;
      video.currentTime = currentTime;
      video.load();
      const handleLoadedData = () => {
         //video.play().catch((e: any) => console.log(e));
      };

      const handleError = () => {
        console.log(45454545)
      }

      video.addEventListener("loadeddata", handleLoadedData);
      video.addEventListener("play", handlePlay);
      video.addEventListener("pause", handlePause);
      video.addEventListener("ended", handleEnded);
      video.addEventListener("seeked", handleSeeked);
      video.addEventListener("onerror", handleError);

      return () => {
        video.removeEventListener("loadeddata", handleLoadedData);
        video.removeEventListener("play", handlePlay);
        video.removeEventListener("pause", handlePause);
        video.removeEventListener("ended", handleEnded);
        video.removeEventListener("seeked", handleSeeked);
      };
    }
  }, [selectedVideoUrl]);

  const profileInit = async () => {
    setIsProfileLoading(true);
    await getProfile();
    setIsProfileLoading(false);
  };

  const practiceInit = async () => {
    await getPractice();
  };

  useEffect(() => {
    if (practiceList) {
      practiceList.forEach((item: any, idx: number) => {
        captureThumbnail(item.url, idx);
      });
    }
  }, [practiceList]);

  useEffect(() => {
    navigate(location.pathname, {
      replace: true,
      state: {
        ...location.state,
        tab: tab,
        profileSubTab: profileSubTab,
      },
    });

    if (tab === "profile" && profileSubTab === "main") {
      profileInit();
    } else if (tab === "practice" && profileSubTab === "main") {
      practiceInit();
    } else {
      setIsProfileLoading(false);
      setIsPracticeLoading(false);
    }
  }, [tab, profileSubTab]);

  useEffect(() => {
    console.log(page);
    practiceInit();
  }, [page]);

  return (
    <ProfileComponent
      {...props}
      tab={tab}
      setTab={setTab}
      profileSubTab={profileSubTab}
      setProfileSubTab={setProfileSubTab}
      //
      profile={profile}
      isProfileLoading={isProfileLoading}
      setIsProfileLoading={setIsProfileLoading}
      toggleActiveProfile={toggleActiveProfile}
      inputRef={inputRef}
      practiceList={practiceList}
      isPracticeLoading={isPracticeLoading}
      //upload
      uploadVideo={uploadVideo}
      videoRef={videoRef}
      uploadImage={uploadImage}
      //
      isShowPopup={isShowPopup}
      setIsShowPopup={setIsShowPopup}
      popupList={popupList}
      openViewPopup={openViewPopup}
      profileMediaType={profileMediaType}
      //
      isAgencyPopup={isAgencyPopup}
      setIsAgencyPopup={setIsAgencyPopup}
      agencyInfo={agencyInfo}
      openAgencyPopup={openAgencyPopup}
      // pagination
      limit={limit}
      page={page}
      setPage={setPage}
      totalCount={totalCount}
    />
  );
};

export default ProfileContainer;
