import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

import * as ImageHelper from "helpers/Image.helper";
import { checkIsNumberThatDoNotBeginWithZero } from "helpers/Reg.helper";

import * as StudentApi from "api/student/Student.api";
import * as DataApi from "api/common/Data.api";

import PublicProfileEditComponent from "./PublicProfileEdit.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import * as commonData from "data/common.data";
import { getCurrentLangName } from "helpers/String.helper";

const PublicProfileEditContainer = (props: any) => {
    const navigate = useNavigate();
    const [studentInfo, setStudentInfo] = useState<any>({});

    const [imageInfo, setImageInfo] = useState<any>({});

    const [imageInfoList, setImageInfoList] = useState([]);
    const [imageIdList, setImageIdList] = useState([]);

    const [hasChecked, setHasChecked] = useState({
        dance: false,
        vocal: false,
        rap: false,
    });

    const [profileParams, setProfileParams] = useState<any>({});

    const [isOnNetworking, setIsOnNetworking] = useState(false);

    const getInfo = async () => {
        const result = await StudentApi.getInfoForStudent();

        if (result?.info) {
            const { info } = result;
            setStudentInfo(info);

            if (info.specialtyCodeList?.length > 0) {
                let specialty = "";

                info.specialtyCodeList.map((code: any, idx: number) => {
                    const codeInfo = commonData.getCommonCodeListByParent("specialty").find((i: any) => i.code === code);

                    specialty =
                        idx + 1 < info.specialtyCodeList?.length
                            ? `${specialty}${getCurrentLangName(codeInfo.names)}, `
                            : `${specialty}${getCurrentLangName(codeInfo.names)}`;

                    info.specialty = specialty;
                });
            }
        }
    };

    const onChangeInput = (componentName: string, value: any) => {
        console.log("UpdateMyContainer:onChangeInput", componentName, value);

        if (componentName === "countryCode") {
            setProfileParams({ ...profileParams, countryCode: value });
        } else if (componentName === "firstName") {
            setProfileParams({ ...profileParams, firstName: value });
        } else if (componentName === "lastName") {
            setProfileParams({ ...profileParams, lastName: value });
        } else if (componentName === "birthday") {
            setProfileParams({ ...profileParams, birthday: value });
        } else if (componentName === "genderTypeCode") {
            setProfileParams({ ...profileParams, genderTypeCode: value });
        } else if (componentName === "nickname") {
            setProfileParams({ ...profileParams, nickname: value });
        } else if (componentName === "height") {
            const height = checkIsNumberThatDoNotBeginWithZero(value) ? value : profileParams.height;
            setProfileParams({ ...profileParams, height });
        } else if (componentName === "weight") {
            const weight = checkIsNumberThatDoNotBeginWithZero(value) ? value : profileParams.weight;
            setProfileParams({ ...profileParams, weight });
        } else if (componentName === "shoeSize") {
            const shoeSize = checkIsNumberThatDoNotBeginWithZero(value) ? value : profileParams.shoeSize;
            setProfileParams({ ...profileParams, shoeSize });
        } else if (componentName === "mbtiCode") {
            setProfileParams({ ...profileParams, mbtiCode: value });
        } else if (componentName === "bloodTypeCode") {
            setProfileParams({ ...profileParams, bloodTypeCode: value });
        } else if (componentName === "slug") {
            setProfileParams({ ...profileParams, slug: value });
        } else if (componentName === "isActive") {
            setProfileParams({ ...profileParams, isActive: value });
        }
    };

    const onChangeCheckbox = (componentName: string, evt: any) => {
        if (componentName === "dance") {
            setHasChecked({ ...hasChecked, dance: evt.target.checked });
        } else if (componentName === "vocal") {
            setHasChecked({ ...hasChecked, vocal: evt.target.checked });
        } else if (componentName === "rap") {
            setHasChecked({ ...hasChecked, rap: evt.target.checked });
        }
    };

    const setSpecialtyCodeList = () => {
        const specialtyCodeList = [];
        if (hasChecked.dance) {
            specialtyCodeList.push("specialty:dance");
        }
        if (hasChecked.vocal) {
            specialtyCodeList.push("specialty:vocal");
        }
        if (hasChecked.rap) {
            specialtyCodeList.push("specialty:rap");
        }

        return specialtyCodeList;
    };

    const isDisabled = async () => {
        if (!profileParams.countryCode) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errCountrySelection"));
            return true;
        } else if (!profileParams.firstName) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errFirstName"));
            return true;
        } else if (!profileParams.lastName) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errLastName"));
            return true;
        } else if (!profileParams.birthday) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errBirthdaySelection"));
            return true;
        } else if (!imageInfo?.id) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errProfileImageSelection"));
            return true;
        } else if (!profileParams.genderTypeCode) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errGenderSelection"));
            return true;
        } else {
            return false;
        }
    };

    const updateInfo = async () => {
        if (!(await isDisabled())) {
            const specialtyCodeList = setSpecialtyCodeList();
            profileParams.specialtyCodeList = JSON.stringify(specialtyCodeList);
            profileParams.profileImageId = imageInfo?.id || null;
            profileParams.imageIdList = imageIdList;

            const result: any = await StudentApi.updateInfo(studentInfo.id, profileParams);

            if (result.data.data) {
                setStudentInfo({});
                getInfo();
                cancelUpdate();
            }
        }
    };

    const uploadImage = async (type: string, evt: any) => {
        setIsOnNetworking(true);

        if (!evt) {
            return false;
        }

        const file = evt.target.files[0];
        const imageInfo = await ImageHelper.getImageInfo(file);
        if (!imageInfo) {
            alert("Please choose image file");
            return false;
        }

        const result = await DataApi.uploadImage(file);

        if (type === "profile") {
            setImageInfo({ id: result.id, url: imageInfo.url });
        }
        if (type === "addition") {
            imageInfoList.push(imageInfo);
            setImageInfoList(imageInfoList);

            imageIdList.push(result.id);
            setImageIdList(imageIdList);
        }

        setIsOnNetworking(false);
    };

    const deleteImage = (idx: number) => {
        setImageIdList(imageIdList.filter((_: any, i: number) => idx !== i));
        setImageInfoList(imageInfoList.filter((_: any, i: number) => idx !== i));
    };

    const cancelUpdate = () => {
        navigate("/student/profile");
    };

    useEffect(() => {
        if (studentInfo?.id) {
            setImageInfo(studentInfo.profileImageInfo);
            setImageInfoList([...studentInfo.imageInfoList]);

            const imageIdList: string[] = [];
            studentInfo.imageInfoList.map((item: any) => {
                imageIdList.push(item.id);
            });

            setImageIdList(imageIdList);

            setProfileParams({
                countryCode: studentInfo.countryCodeInfo.code,
                firstName: studentInfo.firstName,
                lastName: studentInfo.lastName,
                birthday: studentInfo.birthday,
                genderTypeCode: studentInfo.genderTypeCodeInfo.code,
                nickname: studentInfo.nickname,
                height: studentInfo.height || "",
                weight: studentInfo.weight || "",
                shoeSize: studentInfo.shoeSize || "",
                mbtiCode: studentInfo.mbtiCode,
                specialtyCodeList: studentInfo.specialtyCodeList,
                bloodTypeCode: studentInfo.bloodTypeCodeInfo?.code || null,
                slug: studentInfo.slug || "",
                isActive: studentInfo.isActive ? 1 : 0
            });

            setHasChecked({
                dance: studentInfo.specialtyCodeList?.includes("specialty:dance"),
                vocal: studentInfo.specialtyCodeList?.includes("specialty:vocal"),
                rap: studentInfo.specialtyCodeList?.includes("specialty:rap"),
            });
        }
    }, [studentInfo]);

    useEffect(() => {
        getInfo();
    }, []);

    return (
        <PublicProfileEditComponent
            id={studentInfo?.id}
            profileParams={profileParams}
            imageInfo={imageInfo}
            setImageInfo={setImageInfo}
            imageInfoList={imageInfoList}
            isOnNetworking={isOnNetworking}
            hasChecked={hasChecked}
            uploadImage={uploadImage}
            onClickBtnDeleteImage={deleteImage}
            onChangeInput={onChangeInput}
            onChangeCheckbox={onChangeCheckbox}
            onClickBtnCancelUpdate={cancelUpdate}
            onClickBtnUpdateInfo={updateInfo}
        />
    );
};

export default PublicProfileEditContainer;
