import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React from "react";
import { useNavigate } from "react-router-dom";

const SignupComponent = (props: any) => {
  const navigate = useNavigate();
  const { setAccountTypeCode } = props;

  return (
    <main className="signup">
      <div className="con">
        <div className="section">
          <div className="fp-overflow">
            <p className="message">SIGNUP</p>
            <div className="btn">
              <div>
                <div className="type-con">
                  <div className="wrap">
                    <p>
                      {getLanguageValue(
                        PAGE_TYPE.COMMON,
                        "signup.txtFirstStepToKPopStar"
                      )}
                    </p>
                    <button
                      onClick={(evt) => {
                        setAccountTypeCode("accountType:student");
                        navigate("/signup/student");
                      }}>
                      {getLanguageValue(
                        PAGE_TYPE.COMMON,
                        "signup.btnAspiringSignUp"
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div>
                <div className="type-con">
                  <div className="wrap">
                    <p>
                      {getLanguageValue(
                        PAGE_TYPE.COMMON,
                        "signup.txtIntroduceTalentedTrainees"
                      )}
                    </p>
                    <button
                      onClick={(evt) => {
                        setAccountTypeCode("accountType:school");
                        navigate("/signup/school");
                      }}>
                      {getLanguageValue(
                        PAGE_TYPE.COMMON,
                        "signup.btnAcademySignUp"
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div>
                <div className="type-con">
                  <div className="wrap">
                    <p>
                      {getLanguageValue(
                        PAGE_TYPE.COMMON,
                        "signup.txtFindTalentedAspiringStars"
                      )}
                    </p>
                    <button
                      onClick={(evt) => {
                        setAccountTypeCode("accountType:agency");
                        navigate("/signup/agency");
                      }}>
                      {getLanguageValue(
                        PAGE_TYPE.COMMON,
                        "signup.btnCastingManagerSignUp"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- <div>
                            <div className="btn">
                                <button>AGENCY</button>
                            </div>
                            <div className="btn">
                                <button>SCHOOL</button>
                            </div>
                            <div className="btn">
                                <button>STUDENT</button>
                            </div>
                        </div> --> */}
      </div>
    </main>
  );
};

export default SignupComponent;
