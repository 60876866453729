import React, { useEffect, useState } from "react";
import AuditionComponent from "./Audition.component";
import { useLocation, useNavigate } from "react-router-dom";
import * as HomeApi from "api/home/Home.api";

const AuditionContainer = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tab, setTab] = useState(location.state?.tab || "agency");

  const limit: number = 8;
  const lastIdx: number = 0;
  const [isLoading, setIsLoading] = useState(true);
  const [auditionList, setAuditionList] = useState([]);
  const [params, setParams] = useState({ limit, lastIdx });
  const [totalCount, setTotalCount] = useState(0);

  const getAuditionList = async () => {
    try {
      let result: any;
      if (tab === "wannabes") {
        result = await HomeApi.getWannabeAuditionList({
          limit: params.limit,
          lastId: params.lastIdx,
        });
      } else if (tab === "agency") {
        result = await HomeApi.getAgencyAuditionList({
          limit: params.limit,
          lastId: params.lastIdx,
        });
      }

      if (result?.list) {
        setAuditionList((prevList: any) => [...prevList, ...result.list]); // 함수형 업데이트 사용
        setTotalCount(result?.totalCount);
      }
    } catch (error) {
      console.error("Error fetching audition list:", error);
    } finally {
      //setIsLoading(false);
    }
  };

  const init = async () => {
    setIsLoading(true);
    setAuditionList([]);
    setParams({ limit, lastIdx });
    setTotalCount(0);
    await getAuditionList();
    setIsLoading(false);
  };

  useEffect(() => {
    navigate(location.pathname, {
      replace: true,
      state: {
        ...location.state,
        tab: tab,
      },
    });

    init();
  }, [tab]);

  useEffect(() => {
    if (params.lastIdx === 0) return;
    getAuditionList();
  }, [params]);

  return (
    <AuditionComponent
      {...props}
      tab={tab}
      setTab={setTab}
      isLoading={isLoading}
      auditionList={auditionList}
      totalCount={totalCount}
      setParams={setParams}
    />
  );
};

export default AuditionContainer;
