import React, { useEffect } from "react";
import Pagination from "../../../commons/pagination/Pagination.container";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "commons/loading/Loading.component";

const AuditionComponent = (props: any) => {
  const navigate = useNavigate();

  const {
    isParticipantLoading,
    isAuditionLoading,
    changeFavoriteStatus,
    auditionList,
    participatedList,
    go,
    // pagination
    limit,
    page,
    setPage,
    totalCount,
    isLikedFilter,
    toggleLike,
  } = props;

  return (
    <main className="">
      <div className="student-audition-home">
        <div className="title">
          <h2>AUDITION</h2>
        </div>
        <div className="wrap wrap-1">
          <div className="sub-title">
            <p>PARTICIPATED</p>
          </div>

          {isParticipantLoading ? (
            <LoadingComponent />
          ) : participatedList && participatedList.length === 0 ? (
            <div className="no-data">
              <p>No data</p>
            </div>
          ) : (
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <td className="no">
                      <p>No</p>
                    </td>
                    <td className="audition-name">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.STUDENT,
                          "audition.txtAuditionName"
                        )}
                      </p>
                    </td>
                    <td className="agency-name">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.STUDENT,
                          "audition.txtAgency"
                        )}
                      </p>
                    </td>
                    <td className="apply-date">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.STUDENT,
                          "audition.txtApplyDate"
                        )}
                      </p>
                    </td>
                    <td className="status">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.STUDENT,
                          "audition.txtStatus"
                        )}
                      </p>
                    </td>
                    {/* <td className="result">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.STUDENT,
                          "audition.txtResult"
                        )}
                      </p>
                    </td> */}
                    <td className="view">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.STUDENT,
                          "audition.txtView"
                        )}
                      </p>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {participatedList.map((item: any, idx: number) => (
                    <tr>
                      <td className="no">
                        <p>{totalCount - (page - 1) * limit - idx}</p>
                      </td>
                      <td className="audition-name">
                        <p>{item.title}</p>
                      </td>
                      <td className="agency-name">
                        <p>{item.companyName}</p>
                      </td>
                      <td className="apply-date">
                        <p>
                          {getFormattedDateValue(item.applyDate, "YYYY.MM.DD")}
                        </p>
                      </td>
                      <td className="status">
                        <p>
                          {getCurrentLangName(
                            item.auditionStatusCodeInfo.names
                          )}
                        </p>
                      </td>
                      {/* <td className="result">
                        <p>{item.result ? item.result : "-"}</p>
                      </td> */}
                      <td className="view">
                        <a
                          className="view-img"
                          style={{
                            backgroundImage:
                              'url("./imgs/icons/view-black.png")',
                          }}
                          onClick={(e) =>
                            navigate(`/student/audition/${item.id}`)
                          }></a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <Pagination
            url="/student/audition"
            totalCount={totalCount}
            currentPage={page}
            pageSize={limit}
            pageGroupSize={10}
            setPage={setPage}
          />
        </div>

        <div className="wrap wrap-2">
          <div>
            <div className="sub-title">
              <p>LIST</p>
              {isLikedFilter ? (
                <img
                  src="./imgs/icons/like.png"
                  alt="like"
                  onClick={() => toggleLike()}
                />
              ) : (
                <img
                  src="./imgs/icons/like-toggle.png"
                  alt="like"
                  onClick={() => toggleLike()}
                />
              )}
            </div>
            {isAuditionLoading ? (
              <LoadingComponent />
            ) : auditionList && auditionList.length === 0 ? (
              <div className="no-data">
                <p>No data</p>
              </div>
            ) : (
              <div className="list-wrap">
                {auditionList.map((item: any, idx: number) => (
                  <div
                    className="list-wrap-2"
                    key={`audition-${idx}`}
                    onClick={(e) => {
                      //   if (
                      //     item.auditionStatusCodeInfo.code ===
                      //     "auditionStatus:scheduled"
                      //   ) {
                      //     alert(
                      //       getLanguageValue(
                      //         PAGE_TYPE.AGENCY,
                      //         "audition.txtPreAuditionGuide"
                      //       )
                      //     );
                      //     return;
                      //   }

                      go(`/student/audition/${item.id}`, item.status);
                    }}>
                    <div className="list">
                      <div className="agency">
                        <img
                          src={`${
                            item.logoImageInfo
                              ? item.logoImageInfo.url
                              : "./imgs/icons/agency-logo-default.png"
                          }?w=110&h=110`}
                          alt="agency logo"
                        />
                        <span>{item.companyName}</span>
                      </div>
                      <div className="audition-info-wrap">
                        <div className="audition-info-1">
                          <div className="toggle">
                            {item.isFavorited ? (
                              <img
                                src="./imgs/icons/like.png"
                                alt="toggle button"
                                onClick={(evt) => {
                                  evt.stopPropagation();
                                  changeFavoriteStatus(item, !item.isFavorited);
                                }}
                              />
                            ) : (
                              <img
                                src="./imgs/icons/like-toggle.png"
                                alt="toggle button"
                                onClick={(evt) => {
                                  evt.stopPropagation();
                                  changeFavoriteStatus(item, !item.isFavorited);
                                }}
                              />
                            )}
                          </div>
                          <strong>{item.title}</strong>
                          <p>
                            &#45;{" "}
                            {getLanguageValue(
                              PAGE_TYPE.STUDENT,
                              "audition.txtNationality"
                            )}{" "}
                            :{" "}
                            {item?.countryCodeInfo
                              ? getCurrentLangName(item.countryCodeInfo.names)
                              : getLanguageValue(
                                  PAGE_TYPE.STUDENT,
                                  "audition.txtNotSpecified"
                                )}
                          </p>
                          <p>
                            &#45;{" "}
                            {getLanguageValue(
                              PAGE_TYPE.STUDENT,
                              "audition.txtGender"
                            )}{" "}
                            :{" "}
                            {item?.genderTypeCodeInfo
                              ? getCurrentLangName(
                                  item.genderTypeCodeInfo.names
                                )
                              : getLanguageValue(
                                  PAGE_TYPE.STUDENT,
                                  "audition.txtNotSpecified"
                                )}
                          </p>
                          <p>
                            &#45;{" "}
                            {getLanguageValue(
                              PAGE_TYPE.STUDENT,
                              "audition.txtAge"
                            )}{" "}
                            :{" "}
                            {!item.minimumAge && !item.maximumAge
                              ? getLanguageValue(
                                  PAGE_TYPE.STUDENT,
                                  "audition.txtNotSpecified"
                                )
                              : `${
                                  item.minimumAge ||
                                  getLanguageValue(
                                    PAGE_TYPE.STUDENT,
                                    "audition.txtNotSpecified"
                                  )
                                } ~ ${
                                  item.maximumAge ||
                                  getLanguageValue(
                                    PAGE_TYPE.STUDENT,
                                    "audition.txtNotSpecified"
                                  )
                                }`}
                          </p>
                        </div>
                        <div className="audition-info-2">
                          <p className="status">
                            {getCurrentLangName(
                              item?.auditionStatusCodeInfo?.names
                            )}
                          </p>
                          <p className="date">
                            {getFormattedDateValue(
                              item.startDate,
                              "YYYY.MM.DD"
                            )}{" "}
                            ~{" "}
                            {getFormattedDateValue(item.endDate, "YYYY.MM.DD")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};
export default AuditionComponent;
