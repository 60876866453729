import React, { useEffect } from "react";
import LoadingComponent from "commons/loading/Loading.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { useNavigate } from "react-router-dom";

const ProfileImageComponent = (props: any) => {
    const navigate = useNavigate();
    const {
        category,
        profileImageList,
        type,
        deleteProfileImage,
        //
        isShowPopup,
        setIsShowPopup,
        popupList,
        openViewPopup,
        profileMediaType,
        isAgencyPopup,
        setIsAgencyPopup,
        agencyInfo,
        openAgencyPopup,
    } = props;

    return (
        <main className="">
            <div className={`${category === "face" ? "profile-face-image" : "profile-full-body-image"}`}>
                <div className="con">
                    <div className="title">
                        {/* <!-- 선택한 title에 class active 추가 --> */}
                        <button
                            className=""
                            onClick={(e) => {
                                navigate("/student/profile", {
                                    state: { tab: "practice", profileSubTab: "main" },
                                });
                            }}>
                            {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtTitle")}
                        </button>
                        <button
                            className="active"
                            onClick={(e) => {
                                navigate("/student/agency-profile", {
                                    state: { tab: "profile", profileSubTab: "main" },
                                });
                            }}>
                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtTitle")}
                        </button>
                    </div>
                    <div className="main-title">
                        <h2>
                            {category === "face"
                                ? getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtAllFaceImages")
                                : getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtAllBodyImages")}{" "}
                            ({type ? getLanguageValue(PAGE_TYPE.STUDENT, `profile.txt${type.charAt(0).toUpperCase()}${type.slice(1)}`) : null})
                        </h2>
                    </div>
                    <div
                        className="back-btn"
                        onClick={() => {
                            navigate("/student/agency-profile", {
                                state: { tab: "profile", profileSubTab: "main" },
                            });
                        }}></div>
                    {profileImageList && profileImageList.length > 0 ? (
                        <div className={`wrap ${category === "face" ? "face-photo" : "full-body-photo"}`}>
                            <ul>
                                {profileImageList.map((imageInfo: any, index: number) => (
                                    <li>
                                        <div className="img">
                                            <div className="delete-btn">
                                                <button
                                                    onClick={() => {
                                                        deleteProfileImage(imageInfo.id);
                                                    }}></button>
                                            </div>
                                            <div className="view-count">
                                                <button
                                                    onClick={(e) => {
                                                        openViewPopup(imageInfo.id, "profileImage");
                                                    }}>
                                                    <img src="./imgs/icons/view-icon.png" alt="view count icon" />
                                                    {imageInfo.viewCnt}
                                                </button>
                                            </div>
                                            <img src={`${imageInfo.url ? imageInfo.url.replace(/ /g, "%20") : "./imgs/dummy/basic-img-1.png"}`} alt="photo" />
                                        </div>
                                        <div className="date">
                                            <p>{getFormattedDateValue(imageInfo.createdDate, "YYYY.MM.DD")}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtNoData")
                    )}
                </div>
                {isShowPopup ? (
                    <div className="popup popup-view-count">
                        <div className="con">
                            <div
                                className="close-btn"
                                onClick={(e) => {
                                    setIsShowPopup(false);
                                }}></div>
                            <div className="wrap">
                                <div className="title">
                                    <p className="ellipsis">
                                        <span>
                                            {profileMediaType === "profileImage"
                                                ? getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtPhoto")
                                                : getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtVideo")}
                                        </span>{" "}
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtViewedAgency")}
                                    </p>
                                </div>
                                {popupList && popupList.length > 0 ? (
                                    <div className="list">
                                        <ul>
                                            {popupList.map((item: any, index: number) => (
                                                <li
                                                    onClick={(e) => {
                                                        openAgencyPopup(item.id);
                                                    }}>
                                                    <div className="item">
                                                        <div
                                                            className="img"
                                                            style={{
                                                                backgroundImage: `url(${
                                                                    item && item.logoImageInfo
                                                                        ? item.logoImageInfo.url.replace(/ /g, "%20")
                                                                        : "./imgs/icons/agency-logo-default.png"
                                                                })`,
                                                            }}></div>
                                                        <div className="view-count">
                                                            <button>
                                                                <img src="./imgs/icons/view-icon.png" alt="view count icon" />
                                                                {item.viewCnt}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="agency-name">
                                                        <p className="ellipsis">{item.companyName}</p>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
            {isAgencyPopup && agencyInfo ? (
                <div className="popup popup-casting-manager-info">
                    <div className="con">
                        <div className="wrap">
                            <div
                                className="close-btn"
                                onClick={(e) => {
                                    setIsAgencyPopup(false);
                                }}></div>
                            <h2>{getLanguageValue(PAGE_TYPE.STUDENT, "messageDetail.txtCastingManagerInfo")}</h2>
                            <div className="agency-info">
                                <img
                                    src={agencyInfo.logoImageInfo ? agencyInfo.logoImageInfo.url.replace(/ /g, "%20") : "./imgs/icons/agency-logo-default.png"}
                                    alt="agency-logo"
                                />
                                <p>{agencyInfo.companyName}</p>
                                <div className="casting-manager-info">
                                    <div className="business-card"></div>
                                    <div className="name">
                                        {agencyInfo.name}
                                        {/* <!-- 이메일 인증 했을경우 뱃지 --> */}
                                        {agencyInfo.hasVerifiedEmail === 1 ? <img src="./imgs/icons/badge.png" alt="badge" /> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="btn">
                                <button
                                    onClick={(e) => {
                                        setIsAgencyPopup(false);
                                    }}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "messageDetail.txtClose")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </main>
    );
};
export default ProfileImageComponent;
