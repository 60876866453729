import { getAppPushActivatedInfo, setPushActivate } from "api/common/Auth.api";
import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const PushCheckComponent = (props: any) => {
    const { isApp } = useSelector((state: any) => state.common);
    const { history } = props;
    const [isActivated, setIsActivated] = useState(false);

    const getPushActivatedInfo = async () => {
        // device의 push 세팅값 가져오기
        const devicePushPermission = await await (window as any).flutter_inappwebview.callHandler("hasPushPermission");

        // device의 push 세팅값이 true 인 경우 서버의 세팅값 가져오기
        if (devicePushPermission) {
            const result = await getAppPushActivatedInfo();

            if (result) {
                setIsActivated(result.info?.isActivated);
            }
        }
        // device의 push 세팅값이 false 인 경우 서버에 세팅값 저장
        else {
            await setPushActivate({ isActivated: devicePushPermission });
            setIsActivated(devicePushPermission);
        }
    };

    // 푸시 알람 허용 여부 서버에 저장
    const saveIsActivateValue = async (isActivated: boolean) => {
        const result: any = await setPushActivate({ isActivated });

        if (result && result.status === 200) {
            getPushActivatedInfo();
        } else {
            alert(result?.data?.message);
        }
    };

    const changeActivated = async (e: ChangeEvent): Promise<void> => {
        const target = e.target as HTMLInputElement;
        const isChecked: boolean = target.checked;

        // device의 push 세팅값 가져오기
        const devicePushPermission: boolean = await (window as any).flutter_inappwebview.callHandler("hasPushPermission");

        // device의 push 세팅값이 false인데 push를 허용하는 경우 device 설정 화면 오픈
        if (isChecked && !devicePushPermission) {
            const confirmUpdate: boolean = window.confirm(getLanguageValue(PAGE_TYPE.COMMON, "app.txtDeviceAppSettingConfirm"));

            if (confirmUpdate) {
                await (window as any).flutter_inappwebview.callHandler("openAppSetting");

                (window as any).flutter_inappwebview.onResumeApp = async (event: any): Promise<void> => {
                    const deviceIsCheckedValue: boolean = await (window as any).flutter_inappwebview.callHandler("hasPushPermission");
                    await saveIsActivateValue(deviceIsCheckedValue);
                };
            } else {
                setIsActivated(!isChecked);
            }
        }
        // device의 push 세팅값이 true 인 경우는 바로 서버에 값 저장
        else {
            await saveIsActivateValue(isChecked);
        }
    };

    useEffect(() => {
        if (isApp) {
            getPushActivatedInfo();
        }
    }, []);

    return isApp ? (
        <div className="active">
            <label className="checkbox">
                <input
                    type="checkbox"
                    onChange={(evt) => {
                        changeActivated(evt);
                    }}
                    checked={isActivated}
                />
            </label>
            <span>{getLanguageValue(PAGE_TYPE.COMMON, "app.txtAppPushReceive")}</span>
        </div>
    ) : null;
};

export default PushCheckComponent;
