import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsShowFilterPopup, setSearchKeyword } from "reducers/Agency";
import StudentFilterPopupComponent from "commons/popup/StudentFilterPopup.component";
import { formatTimeAgo } from "helpers/Datetime.helper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const HeaderComponent = (props: any) => {
  const {
    logout,
    render,
    mobileMenuSlideClassName,
    setMobileMenuSlideClassName,
    showNotification,
    setShowNotification,
    notificationList,
    totalUnReadCount,
    onClickCheckNotification,
    mobileNotificationSlideClassName,
    setMobileNotificationMenuSlideClassName,
  } = props;
  const searchKeywordRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const { updatedFavoriteCount, isShowFilterPopup, newMessageCount } =
    useSelector((state: any) => state.agency);
  const { info: myInfo } = useSelector((state: any) => state.user);
  const searchParams = new URLSearchParams(location.search);

  const doKeywordSearch = () => {
    const keyword = searchKeywordRef?.current?.value.trim();

    dispatch(setSearchKeyword(keyword));
    searchParams.set("searchKeyword", keyword);
    render(`/agency/search?${searchParams.toString()}`);
  };

  useEffect(() => {
    const searchKeyword = searchParams.get("searchKeyword");
    if (searchKeyword) {
      searchKeywordRef.current.value = searchKeyword;
    }
  }, []);

  return (
    <header className="header-agency">
      <div className="con">
        <div className="top">
          <div className="logo">
            <a
              className="img-wrap"
              onClick={(e: any) => {
                e.preventDefault();
                render("/agency");
              }}>
              <img
                src="./imgs/common/logo/img-logo/logo-t2-125.png"
                alt="wannabes"
              />
            </a>
          </div>
          <div className="search-wrap">
            <div className="search-bar">
              <input
                id="input-search"
                name="input-search"
                type="text"
                ref={searchKeywordRef}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    doKeywordSearch();
                  }
                }}
              />
              <button
                className="filter"
                onClick={(e: any) => {
                  e.preventDefault();
                  dispatch(setIsShowFilterPopup(true));
                }}>
                <img
                  className="filter-icon"
                  src="./imgs/icons/icon-filter.png"
                />
              </button>
              <button className="search" onClick={doKeywordSearch}>
                <img
                  className="search-icon"
                  src="./imgs/icons/icon-search.png"
                />
              </button>
            </div>
          </div>

          <div className="pc-tb-util">
            <div className={`alarm-wrap ${showNotification ? "" : "off"}`}>
              <button
                className="alarm"
                onClick={(e) => {
                  setShowNotification(!showNotification);
                }}>
                <img src="./imgs/icons/alarm-icon.png" alt="alarm" />
                {totalUnReadCount ? <span>{totalUnReadCount}</span> : null}
              </button>

              <div className="alarm-layer-content">
                <strong>
                  {getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "header.txtUnreadNotifications"
                  )}{" "}
                  ({totalUnReadCount ? totalUnReadCount : 0})
                </strong>
                <a
                  className="button-all-view"
                  onClick={(e) => {
                    e.preventDefault();
                    render("/agency/notification");
                  }}>
                  {getLanguageValue(PAGE_TYPE.AGENCY, "header.txtViewAll")}
                </a>
                {notificationList && notificationList.length === 0 ? (
                  <p className="no-notification">
                    {getLanguageValue(
                      PAGE_TYPE.AGENCY,
                      "header.txtNodataNotification"
                    )}
                  </p>
                ) : (
                  <ul>
                    {notificationList.map((notification: any) => (
                      <li
                        onClick={(e) => {
                          onClickCheckNotification(notification);
                        }}
                        key={notification.id}
                        className={notification.isRead ? "read" : "not-read"}>
                        <div className="notification-header">
                          {formatTimeAgo(notification.sendAt)}
                        </div>
                        <div className="notification-body">
                          <div className="notification-title">
                            {notification.title}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <button
              className="logo"
              onClick={(e: any) => {
                e.preventDefault();
                render("/agency/my");
              }}>
              {/* <img src="./imgs/dummy/agency-logo/starship.png" alt="logo" /> */}
              <img
                src={`${
                  myInfo && myInfo.logoImageInfo
                    ? myInfo.logoImageInfo.url.replace(/ /g, "%20") + ""
                    : "./imgs/icons/agency-logo-default.png"
                }`}
                alt="agency logo"
              />
            </button>
          </div>

          <div className="mb mb-alarm">
            <button
              className="alarm"
              onClick={(e) => {
                e.preventDefault();
                setMobileNotificationMenuSlideClassName("on");
              }}>
              <img src="./imgs/icons/alarm-icon.png" alt="alarm" />
              {totalUnReadCount ? <span>{totalUnReadCount}</span> : null}
            </button>

            {/* <!-- 
                모바일일 경우 class 에 
                on 을 추가해 메뉴 slide in
                off 를 추가해 메뉴 slide out
            --> */}
            <nav className={`alarm-gnb ${mobileNotificationSlideClassName}`}>
              <div className="btn">
                <button
                  className="close-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setMobileNotificationMenuSlideClassName("off");
                  }}>
                  <img
                    src="./imgs/icons/icon-close-btn-mb.png"
                    alt="close button"
                  />
                </button>
              </div>
              <div className="con">
                <strong>
                  {getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "header.txtUnreadNotifications"
                  )}{" "}
                  ({totalUnReadCount ? totalUnReadCount : 0})
                </strong>
                <a
                  className="button-all-view"
                  onClick={(e) => {
                    e.preventDefault();
                    render("/agency/notification");
                  }}>
                  {getLanguageValue(PAGE_TYPE.AGENCY, "header.txtViewAll")}
                </a>
              </div>
              {notificationList && notificationList.length === 0 ? (
                <p className="no-notification">
                  {getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "header.txtNodataNotification"
                  )}
                </p>
              ) : (
                <ul>
                  {notificationList.map((notification: any) => (
                    <li
                      onClick={(e) => {
                        onClickCheckNotification(notification);
                      }}
                      key={notification.id}
                      className={notification.isRead ? "read" : "not-read"}>
                      <div className="notification-header">
                        {formatTimeAgo(notification.sendAt)}
                      </div>
                      <div className="notification-body">
                        <div className="notification-title">
                          {notification.title}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              {/* <div className="mb-util">
                <button
                  className="btn-mypage"
                  onClick={(e) => {
                    render("/agency/my");
                  }}>
                  MY PAGE
                </button>
              </div> */}
            </nav>
          </div>

          <div
            className="mb mb-btn-hamburger"
            onClick={() => setMobileMenuSlideClassName("on")}>
            <img src="./imgs/icons/icon-hamburger.png" alt="Hamburger menu" />
          </div>
        </div>

        {/* <!-- 
            모바일일 경우 class 에 
            on 을 추가해 메뉴 slide in
            off 를 추가해 메뉴 slide out
         --> */}
        <nav className={`agency-gnb landing-gnb ${mobileMenuSlideClassName}`}>
          <div className="btn">
            <button
              className="close-btn"
              onClick={() => setMobileMenuSlideClassName("off")}>
              <img
                src="./imgs/icons/icon-close-btn-mb.png"
                alt="close button"
              />
            </button>
          </div>
          <ol>
            <li>
              <div
                className={`item ${
                  location.pathname === "/agency/favorite"
                    ? "on"
                    : location.state?.from
                    ? location.state?.from.pathname === "/agency/favorite"
                      ? "on"
                      : ""
                    : ""
                }`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();

                    render(`/agency/favorite`);
                  }}>
                  FAVORITE
                </a>
                {/* 임시 주석처리  */}
                {/* {updatedFavoriteCount ? (
                                    <div className="badge">
                                        <p>{updatedFavoriteCount}</p>
                                    </div>
                                ) : null} */}
              </div>
            </li>
            <li>
              <div
                className={`item ${
                  location.pathname.startsWith("/agency/audition") ? "on" : ""
                }`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();

                    render(`/agency/audition`);
                  }}>
                  AUDITION
                </a>
                {/* <div className="badge">
                  <p>2</p>
                </div> */}
              </div>
            </li>
            <li>
              <div
                className={`item ${
                  location.pathname.startsWith("/agency/message") ? "on" : ""
                }`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();
                    render(`/agency/message`);
                  }}>
                  MESSAGE
                </a>
                {newMessageCount ? (
                  <div className="badge">
                    <p>{newMessageCount}</p>
                  </div>
                ) : null}
              </div>
            </li>
            {/* <li>
              <div className="item">
                <a>CONTRACT</a>
                <div className="badge">
                  <p>5</p>
                </div>
              </div>
            </li> */}
            <li>
              <div className={`item`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();
                    render(`/`);
                  }}>
                  PLAY GROUND
                </a>
              </div>
            </li>
          </ol>

          <div className="mb-util">
            <div className="col">
              <button
                className="btn-logout"
                onClick={(e: any) => {
                  e.preventDefault();
                  setMobileMenuSlideClassName("off");
                  logout();
                }}>
                LOGOUT
              </button>
            </div>
            <div className="col">
              <button
                className="btn-mypage"
                onClick={(e: any) => {
                  e.preventDefault();
                  render("/agency/my");
                }}>
                MY PAGE
              </button>
            </div>
          </div>
        </nav>
      </div>
      {isShowFilterPopup ? <StudentFilterPopupComponent /> : null}
    </header>
  );
};
export default HeaderComponent;
