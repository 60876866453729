import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { useNavigate } from "react-router-dom";

const SchoolSignupDoneComponent = (props: any) => {
  const navigate = useNavigate();
  const { schoolInfo, share, studentSignUpUrl } = props;

  return (
    <main className="school-sign-up-done">
      <div className="con">
        <div className="logo">
          <img src="./imgs/common/logo/img-logo/logo-250.png" alt="WANNABES" />
        </div>

        <div className="desc">
          <p>{getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.txtResult")}</p>
        </div>

        <div className="school-info">
          <div
            className="school-logo"
            style={{
              backgroundImage: schoolInfo.logoImageInfo
                ? `url("${schoolInfo.logoImageInfo.url}?w=400&h=400")`
                : `none`,
            }}></div>
          <h3>{schoolInfo.schoolName}</h3>
          <div className="desc">
            <p>{getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.txtDesc")}</p>
          </div>
          <div className="link">
            <span className="url">{studentSignUpUrl}</span>
            <span className="share">
              <button
                className="btn btn-share"
                onClick={(evt) => {
                  share();
                }}>
                {getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.btnShare")}
              </button>
            </span>
          </div>
        </div>

        <div className="button-wrap">
          <button
            className="btn"
            onClick={(e) => {
              navigate("/login");
            }}>
            {getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.btnLogin")}
          </button>
        </div>
      </div>
    </main>
  );
};

export default SchoolSignupDoneComponent;
