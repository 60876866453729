import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as commonData from "data/common.data";
import { getCurrentLangName } from "helpers/String.helper";

import LoadingComponent from "commons/loading/Loading.component";
import { useNavigate } from "react-router-dom";
import { getFormattedDateValue } from "helpers/Datetime.helper";

const PublicProfileComponent = (props: any) => {
    const navigate = useNavigate();

    const { studentInfo } = props;

    return (
        <main>
            <div className="profile-for-public">
                <div className="con">
                    <div className="title">
                        {/* <!-- 선택한 title에 class active 추가 --> */}
                        <button
                            className="active"
                            onClick={(e) => {
                                navigate("/student/profile", {
                                    state: { tab: "practice", profileSubTab: "main" },
                                });
                            }}>
                            {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtTitle")}
                        </button>
                        <button
                            className=""
                            onClick={(e) => {
                                navigate("/student/agency-profile", {
                                    state: { tab: "profile", profileSubTab: "main" },
                                });
                            }}>
                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtTitle")}
                        </button>
                    </div>

                    {studentInfo?.id ? (
                        <>
                            <div className="main-title">
                                <h2>{getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtTitle")}</h2>
                                {/* <p>{getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtAgencyAndCastingManagerOnly")}</p> */}
                            </div>
                            <div className="wrap">
                                <div className="cont">
                                    <div className={`profile-img ${studentInfo.profileImageInfo ? "" : " empty"}`}>
                                        <img
                                            src={
                                                studentInfo.profileImageInfo?.url
                                                    ? `${studentInfo.profileImageInfo?.url}?w=396&h=396`
                                                    : "./imgs/dummy/basic-img-2.png"
                                            }
                                            alt="profile image"
                                            style={{ height: "100%" }}
                                        />
                                    </div>
                                </div>
                                <div className="cont">
                                    <h3 className="title-con">{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtName")}</h3>
                                    <p className="text">{`${studentInfo.firstName} ${studentInfo.lastName}`}</p>
                                </div>
                                <div className="cont">
                                    <h3 className="title-con">{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtCountry")}</h3>
                                    <p className="text">{getCurrentLangName(studentInfo.countryCodeInfo.names)}</p>
                                </div>
                                <div className="cont">
                                    <h3 className="title-con">{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtBirthday")}</h3>
                                    <p className="text">{getFormattedDateValue(studentInfo.birthday, "YYYY.MM.DD")}</p>
                                </div>
                                <div className="cont">
                                    <h3 className="title-con">{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtGender")}</h3>
                                    <p className="text">{getCurrentLangName(studentInfo.genderTypeCodeInfo.names)}</p>
                                </div>
                                {studentInfo?.nickname ? (
                                    <div className="cont">
                                        <h3 className="title-con">{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtNickname")}</h3>
                                        <p className="text">{studentInfo.nickname}</p>
                                    </div>
                                ) : null}
                                {studentInfo?.height ? (
                                    <div className="cont">
                                        <h3 className="title-con">{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtHeight")}</h3>
                                        <p className="text">{studentInfo.height}cm</p>
                                    </div>
                                ) : null}
                                {studentInfo?.weight ? (
                                    <div className="cont">
                                        <h3 className="title-con">{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtWeight")}</h3>
                                        <p className="text">{studentInfo.weight}kg</p>
                                    </div>
                                ) : null}
                                {studentInfo?.shoeSize ? (
                                    <div className="cont">
                                        <h3 className="title-con">{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtShoeSize")}</h3>
                                        <p className="text">{studentInfo.shoeSize}mm</p>
                                    </div>
                                ) : null}
                                {studentInfo?.bloodTypeCodeInfo ? (
                                    <div className="cont">
                                        <h3 className="title-con">{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtBloodType")}</h3>
                                        <p className="text">{studentInfo.bloodTypeCodeInfo.names.en}</p>
                                    </div>
                                ) : null}
                                {studentInfo?.mbtiCode ? (
                                    <div className="cont">
                                        <h3 className="title-con">MBTI</h3>
                                        <p className="text">{studentInfo.mbtiCode}</p>
                                    </div>
                                ) : null}
                                {studentInfo?.specialtyCodeInfoList.length > 0 ? (
                                    <div className="cont">
                                        <h3 className="title-con">{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSpecialty")}</h3>
                                        <p className="text">{studentInfo.specialty}</p>
                                    </div>
                                ) : null}
                                {/* 추가 이미지 */}
                                {studentInfo.imageInfoList.length > 0 ? (
                                    <div className="img-list">
                                        <ul>
                                            {studentInfo.imageInfoList.map((item: any, idx: number) => (
                                                <li key={`artist-${idx}`}>
                                                    <div className="img-wrap">
                                                        <div
                                                            className="img"
                                                            style={{
                                                                backgroundImage: `url("${item.url}?w=440&h=440")`,
                                                            }}></div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : null}

                                <div className="cont">
                                    <h3 className="title-con">공개여부</h3>
                                    <p className="text">
                                        {studentInfo?.isActive
                                            ? `${getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtPublic")}`
                                            : `${getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtPrivate")}`}
                                    </p>
                                </div>

                                <div className="btn">
                                    <div className="update-btn">
                                        <button
                                            onClick={(evt) => {
                                                navigate("/student/profile/edit");
                                            }}>
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtTitleUpdate")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <LoadingComponent />
                    )}
                </div>
            </div>
        </main>
    );
};

export default PublicProfileComponent;
