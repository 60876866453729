import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsShowFilterPopup } from "reducers/Agency";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { getCurrentLangName, getNumberWithComma } from "helpers/String.helper";
import { getList as getStudentList } from "api/student/Student.api";
import { getCurrentLanguage } from "locales/i18n";
import {
    setSearchGenderTypeCode,
    setSearchCountryCodeList,
    setSearchHeightRange,
    setSearchWeightRange,
    setSsearchAgeRange,
} from "reducers/Agency";
import {
    getMaxBirthday,
    getMinBirthday,
    isSameOrAfterDate,
    isSameOrBeforeDate,
} from "helpers/Datetime.helper";
import { useLocation, useNavigate } from "react-router-dom";
import { setCountryCodeList } from "data/common.data";
import * as commonData from "data/common.data";

const StudentFilterPopupComponent = (props) => {
    const dispatch = useDispatch();
    const {
        searchKeyword,
        searchGenderTypeCode,
        searchCountryCodeList,
        searchHeightRange,
        searchWeightRange,
        searchAgeRange,
    } = useSelector((state) => state.agency);
    const navigate = useNavigate();
    const [isInit, setIsInit] = useState(false);
    const [resultCount, setResultCount] = useState(0);
    const [countryList, setCountryList] = useState([]);
    const [countryLoading, setCountryLoading] = useState(false);
    const [allStudentList, setAllStudentList] = useState([]);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const selectRef = useRef(null);
    const getQueryStringValue = (value) =>
        encodeURIComponent(JSON.stringify(value));

    const doFilter = (list) => {
        if (list.length > 0) {
            const filteredList = list.filter((item) => {
                if (
                    searchCountryCodeList.length > 0 &&
                    !searchCountryCodeList.find(
                        (v) => v.code === item.countryCodeInfo?.code
                    )
                ) {
                    return false;
                }

                if (
                    searchGenderTypeCode !== "" &&
                    searchGenderTypeCode !== item.genderTypeCodeInfo?.code
                ) {
                    return false;
                }

                if (
                    !(
                        searchHeightRange[0] <= item.height &&
                        searchHeightRange[1] >= item.height
                    ) &&
                    !(
                        searchHeightRange[0] === 140 &&
                        searchHeightRange[1] === 200
                    )
                ) {
                    console.log(1);
                    return false;
                }
                if (
                    !(
                        searchWeightRange[0] <= item.weight &&
                        searchWeightRange[1] >= item.weight
                    ) &&
                    !(
                        searchWeightRange[0] === 40 &&
                        searchWeightRange[1] === 100
                    )
                ) {
                    return false;
                }
                if (
                    !(
                        isSameOrAfterDate(
                            item.birthday,
                            getMaxBirthday(searchAgeRange[0])
                        ) &&
                        isSameOrBeforeDate(
                            item.birthday,
                            getMinBirthday(searchAgeRange[1])
                        )
                    ) &&
                    !(searchAgeRange[0] === 0 && searchAgeRange[1] === 30)
                ) {
                    return false;
                }
                return true;
            });

            setResultCount(filteredList.length);
        }
    };

    const getAllStudentListValue = async () => {
        const result = await getStudentList({ keyword: searchKeyword });

        if (result?.list) {
            const list = result.list;
            setAllStudentList(list);
            const countries = commonData.getCountryCodeList();
            setCountryList(countries);
            setCountryLoading(true);
            /*
            if (countryList.length < 1) {
                const countries = [];
                const lang = getCurrentLanguage();
                for (const item of list) {
                    const isExists = countries.find(
                        (v) => item.countryCodeInfo?.code === v.code
                    );

                    if (!isExists) {
                        console.log(item);
                        countries.push({
                            code: item.countryCodeInfo.code,
                            name: item.countryCodeInfo.names[lang],
                        });
                    }
                }

                countries.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });

                setCountryList(countries);
            }
            */
            doFilter(list);
            setIsInit(true);
        }
    };
    useEffect(() => {
        getAllStudentListValue();
    }, []);

    useEffect(() => {
        doFilter(allStudentList);
    }, [
        searchGenderTypeCode,
        searchCountryCodeList,
        searchHeightRange,
        searchWeightRange,
        searchAgeRange,
    ]);
    return (
        <div className="popup popup-search-filter">
            <div
                className="close-btn"
                onClick={(e) => {
                    e.preventDefault();
                    dispatch(setIsShowFilterPopup(false));
                }}></div>
            <div className="con">
                {/* <!-- 필터 팝업 시작 --> */}
                <h2>SEARCHING FILTER</h2>
                <div className="filter-wrap">
                    <ul>
                        <li className="nationality-wrap">
                            <label className="item-wrap" htmlFor="country">
                                NATIONALITY
                            </label>
                            <div className="filter">
                                <div className="select-box">
                                    <select
                                        className="country"
                                        id="country"
                                        name="country"
                                        ref={selectRef}
                                        required
                                        onChange={(e) => {
                                            e.preventDefault();

                                            if (
                                                e.currentTarget.value !== "all"
                                            ) {
                                                dispatch(
                                                    setSearchCountryCodeList([
                                                        ...searchCountryCodeList,
                                                        countryList.find(
                                                            (v) =>
                                                                v.code ===
                                                                e.currentTarget
                                                                    .value
                                                        ),
                                                    ])
                                                );
                                            }
                                            selectRef.current.value = "all";
                                        }}>
                                        <option value="all" selected disabled>
                                            {!countryLoading
                                                ? "Loading..."
                                                : "Choose Country"}
                                        </option>
                                        {countryList
                                            ? countryList.map((item, index) => (
                                                  <option
                                                      value={item.code}
                                                      key={`country-${index}`}
                                                      disabled={searchCountryCodeList.find(
                                                          (v) =>
                                                              v.code ===
                                                              item.code
                                                                  ? true
                                                                  : false
                                                      )}>
                                                      {getCurrentLangName(
                                                          item.names
                                                      )}
                                                  </option>
                                              ))
                                            : null}
                                    </select>
                                    {searchCountryCodeList &&
                                    searchCountryCodeList.length > 0 ? (
                                        <div className="selected-country">
                                            {searchCountryCodeList.map(
                                                (item, index) => (
                                                    <span
                                                        key={`country-name-${index}`}>
                                                        {getCurrentLangName(
                                                            item.names
                                                        )}
                                                        <button
                                                            onClick={(e) => {
                                                                e.preventDefault();

                                                                dispatch(
                                                                    setSearchCountryCodeList(
                                                                        searchCountryCodeList.filter(
                                                                            (
                                                                                v
                                                                            ) =>
                                                                                v.code !==
                                                                                item.code
                                                                        )
                                                                    )
                                                                );
                                                            }}>
                                                            <img
                                                                src="./imgs/icons/cancel-white.png"
                                                                alt="cancel"
                                                            />
                                                        </button>
                                                    </span>
                                                )
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="filter">
                                <form className="filter">
                                    <p className="item-wrap">GENDER</p>
                                    <label>
                                        <input
                                            type="radio"
                                            name="GENDER"
                                            value=""
                                            checked={
                                                searchGenderTypeCode === ""
                                            }
                                            onChange={() =>
                                                dispatch(
                                                    setSearchGenderTypeCode("")
                                                )
                                            }
                                        />
                                        ALL
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="GENDER"
                                            value="genderType:girl"
                                            checked={
                                                searchGenderTypeCode ===
                                                "genderType:girl"
                                            }
                                            onChange={() =>
                                                dispatch(
                                                    setSearchGenderTypeCode(
                                                        "genderType:girl"
                                                    )
                                                )
                                            }
                                        />
                                        GIRL
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="GENDER"
                                            value="genderType:boy"
                                            checked={
                                                searchGenderTypeCode ===
                                                "genderType:boy"
                                            }
                                            onChange={() =>
                                                dispatch(
                                                    setSearchGenderTypeCode(
                                                        "genderType:boy"
                                                    )
                                                )
                                            }
                                        />
                                        BOY
                                    </label>
                                </form>
                            </div>
                        </li>
                        <li>
                            <div className="filter">
                                <p>
                                    <label
                                        className="slider-part item-wrap"
                                        htmlFor="height">
                                        HEIGHT
                                    </label>
                                    <span>{`${searchHeightRange[0]}-${searchHeightRange[1]}cm`}</span>
                                </p>
                                <div
                                    id="height-slider"
                                    className="slider-range">
                                    <div className="slider-bar">
                                        <Slider
                                            range
                                            min={140}
                                            max={200}
                                            step={5}
                                            defaultValue={searchHeightRange}
                                            onChange={(value) => {
                                                dispatch(
                                                    setSearchHeightRange(value)
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="min-max">
                                        <span>min</span>
                                        <span>max</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="filter">
                                <p>
                                    <label
                                        className="slider-part item-wrap"
                                        htmlFor="weight">
                                        WEIGHT
                                    </label>
                                    <span>{`${searchWeightRange[0]}-${searchWeightRange[1]}kg`}</span>
                                </p>
                                <div
                                    id="weight-slider"
                                    className="slider-range">
                                    <div className="slider-bar">
                                        <Slider
                                            range
                                            min={40}
                                            max={100}
                                            step={5}
                                            defaultValue={searchWeightRange}
                                            onChange={(value) => {
                                                dispatch(
                                                    setSearchWeightRange(value)
                                                );
                                            }}
                                        />
                                    </div>

                                    <div className="min-max">
                                        <span>min</span>
                                        <span>max</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="filter">
                                <p>
                                    <label
                                        className="slider-part item-wrap"
                                        htmlFor="age">
                                        AGE
                                    </label>
                                    <span>{`${searchAgeRange[0]}-${searchAgeRange[1]}years`}</span>
                                </p>
                                <div id="age-slider" className="slider-range">
                                    <div className="slider-bar">
                                        <Slider
                                            range
                                            min={0}
                                            max={30}
                                            step={1}
                                            defaultValue={searchAgeRange}
                                            onChange={(value) => {
                                                dispatch(
                                                    setSsearchAgeRange(value)
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="min-max">
                                        <span>min</span>
                                        <span>max</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className="btn">
                        <button
                            className="search"
                            onClick={(e) => {
                                e.preventDefault();
                                dispatch(setIsShowFilterPopup(false));
                                searchParams.set(
                                    "searchCountryCodeList",
                                    getQueryStringValue(searchCountryCodeList)
                                );
                                searchParams.set(
                                    "searchGenderTypeCode",
                                    searchGenderTypeCode
                                );
                                searchParams.set(
                                    "searchHeightRange",
                                    getQueryStringValue(searchHeightRange)
                                );
                                searchParams.set(
                                    "searchWeightRange",
                                    getQueryStringValue(searchWeightRange)
                                );
                                searchParams.set(
                                    "searchAgeRange",
                                    getQueryStringValue(searchAgeRange)
                                );
                                navigate(
                                    `/agency/search?${searchParams.toString()}`,
                                    { replace: true }
                                );
                            }}>
                            SEARCH
                        </button>
                    </div>
                    {isInit ? (
                        <p className="wannabe-number">
                            <span>{getNumberWithComma(resultCount)} </span>
                            Result
                        </p>
                    ) : null}
                </div>
                {/* <!-- 필터 팝업 끝 --> */}
            </div>
        </div>
    );
};

export default StudentFilterPopupComponent;
