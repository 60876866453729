import { PAGE_TYPE } from "enums";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getLanguageValue } from "locales/i18n";
import StudentInfoPopup from "commons/popup/StudentInfoPopup.component";
import StudentSearchPopup from "commons/popup/StudentSearchPopup.component";
import LoadingComponent from "commons/loading/Loading.component";
import React from "react";
import { isBadWord } from "helpers/String.helper";

const MessageDetailComponent = (props: any) => {
    const {
        wsSubject,
        messages,
        userInfo,
        id,
        companyName,
        navigate,
        chatRef,
        fileInputRef,
        messageReportReasonCode,
        onChangeMessageReportReasonCode,
        isOnNetworking,
        onClickBtnSave,
        reportPopupOpen,
        openReportPopup,
        closeReportPopup,
        messageReportReason,
        onChangeMessageReportReason,
        message,
        setMessage,
        sendMessage,
        handleFileChange,
        reportReason,
        handleButtonClick,
        handleScroll,
        closeChannelUser,
        isShowStudentInfoPopup,
        setIsShowStudentInfoPopup,
        isShowStudentSearchPopup,
        setIsShowStudentSearchPopup,
        setSelectedStudnetId,
        isShowLoading,
        isShowWarnPopup,
        setIsShowWarnPopup,
        isShowFeedbackPopup,
        closeFeedbackPopup,
        sendFeedback,
        feedbackMessage,
        setFeedbackMessage,
        messageStatusCode,
    } = props;

    const renderImageMessage = (message: any) => {
        try {
            const obj = JSON.parse(message);

            return (
                <>
                    <div
                        className="student-img"
                        style={{ cursor: "pointer" }}
                        onClick={(e: any) => {
                            e.preventDefault();
                            setIsShowStudentInfoPopup(true);
                            setSelectedStudnetId(obj.userStudentId);
                        }}>
                        <div
                            className="img"
                            style={{
                                backgroundImage: `url("${obj.imageUrl}?w=112&h=112")`,
                            }}></div>
                        <span className="text">{obj.userStudentName}</span>
                    </div>
                </>
            );
        } catch {
            return (
                <div className="student-img" style={{ cursor: "pointer" }}>
                    <div
                        className="img"
                        style={{
                            backgroundImage: `url("${message}?w=112&h=112")`,
                        }}></div>
                </div>
            );
        }
    };

    return (
        <main className="">
            <div className="school-message-detail">
                <div className="con">
                    <div className="btn-wrap">
                        <div
                            className="back-btn"
                            onClick={() => {
                                navigate("/school/message");
                            }}></div>
                        <div className="btn">
                            {/* <button className="report" onClick={openReportPopup}>
                <img src="./imgs/icons/report.png" alt="report button" />
                {getLanguageValue(PAGE_TYPE.SCHOOL, "messageDetail.txtReport")}
              </button> */}
                            {messageStatusCode && messageStatusCode !== "messageType:close" ? (
                                <button className="close" onClick={closeChannelUser}>
                                    <img src="./imgs/icons/close.png" alt-="close button" />
                                    {getLanguageValue(PAGE_TYPE.SCHOOL, "messageDetail.txtClose")}
                                </button>
                            ) : null}
                        </div>
                    </div>
                    <h2>{companyName ? companyName : null}</h2>
                    <div className="chat-container">
                        <div className="chat-messages" ref={chatRef} onScroll={handleScroll}>
                            {isShowLoading ? (
                                <LoadingComponent />
                            ) : messages ? (
                                messages.map((message: any, index: number) => {
                                    const messageData = message;
                                    const userId = messageData.uid;
                                    const userType = messageData.userType;
                                    const text = message.message;
                                    const createdAt = message.createdAt ? message.createdAt : messageData.createdAt;
                                    const isSentByCurrentUser = parseInt(userId) === parseInt(userInfo.userSchoolId) && userType === userInfo.accountTypeCode;

                                    let isBadWordIncluded = false;

                                    if (isSentByCurrentUser && isBadWord(text)) {
                                        isBadWordIncluded = true;
                                    }

                                    if (message.messageType === "close") {
                                        wsSubject.current?.unsubscribe();
                                    }
                                    return (
                                        <div
                                            key={index}
                                            className={`message ${isSentByCurrentUser ? "sent" : "received"} ${isBadWordIncluded ? "warning" : ""}`}>
                                            {message.messageType === "image" ? (
                                                renderImageMessage(text)
                                            ) : (
                                                <>
                                                    <span className="text">{text}</span>
                                                </>
                                            )}
                                            <p className="date">{getFormattedDateValue(createdAt, "YYYY.MM.DD HH:mm")}</p>
                                            {isBadWordIncluded ? (
                                                <>
                                                    <br />
                                                    <p className="date">{getLanguageValue(PAGE_TYPE.SCHOOL, "messageDetail.txtWarning")}</p>{" "}
                                                </>
                                            ) : null}
                                        </div>
                                    );
                                })
                            ) : null}
                        </div>
                        <div className="chat-input">
                            <div className="wrap">
                                <input ref={fileInputRef} type="file" accept="image/*" onChange={handleFileChange} style={{ display: "none" }} />
                                <input
                                    placeholder={getLanguageValue(PAGE_TYPE.SCHOOL, "messageDetail.txtTypeYourMessage")}
                                    className="message-input"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            if (message.trim() !== "") {
                                                const newMessage = {
                                                    channelId: id,
                                                    userId: userInfo.userSchoolId,
                                                    userType: userInfo.accountTypeCode,
                                                    messageType: "text",
                                                    message: message,
                                                    createdAt: new Date().toISOString(),
                                                };

                                                sendMessage(newMessage);
                                                setMessage("");
                                            }
                                        }
                                    }}
                                />
                                <button
                                    className="popup-btn"
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setIsShowStudentSearchPopup(true);
                                    }}></button>

                                <button
                                    className="send-button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (message.trim() !== "") {
                                            const newMessage = {
                                                channelId: id,
                                                userId: userInfo.userSchoolId,
                                                userType: userInfo.accountTypeCode,
                                                messageType: "text",
                                                message: message,
                                                createdAt: new Date().toISOString(),
                                            };

                                            sendMessage(newMessage);
                                            setMessage("");
                                        }
                                    }}></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isShowStudentInfoPopup ? <StudentInfoPopup {...props} /> : null}
            {isShowStudentSearchPopup ? <StudentSearchPopup {...props} /> : null}

            {reportPopupOpen ? (
                <div className="popup popup-report-message">
                    <div className="con">
                        <div className="wrap">
                            <div className="close-btn" onClick={closeReportPopup}></div>
                            <h2>{getLanguageValue(PAGE_TYPE.SCHOOL, "messageDetail.txtReport")}</h2>
                            <form>
                                {reportReason.map((reason: any) => (
                                    <div className="input-box" key={reason.code}>
                                        <input
                                            type="radio"
                                            id={reason.code}
                                            name={reason.parentCode}
                                            value={reason.code}
                                            onChange={(evt) => onChangeMessageReportReasonCode(evt)}
                                            checked={reason.code === messageReportReasonCode}
                                        />
                                        <label htmlFor={reason.code}>{reason.names.en}</label>
                                    </div>
                                ))}
                                <textarea
                                    placeholder={getLanguageValue(PAGE_TYPE.SCHOOL, "messageDetail.txtInputOtherReason")}
                                    name="messageReportReason"
                                    value={messageReportReason}
                                    onChange={(evt) => onChangeMessageReportReason(evt)}></textarea>
                                <div className="btn">
                                    <button className="cancel" onClick={closeReportPopup}>
                                        {getLanguageValue(PAGE_TYPE.SCHOOL, "messageDetail.txtCancel")}
                                    </button>
                                    <button className="reporting" disabled={isOnNetworking} onClick={onClickBtnSave}>
                                        {getLanguageValue(PAGE_TYPE.SCHOOL, "messageDetail.txtReporting")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            ) : null}

            {isShowFeedbackPopup ? (
                <div className="popup popup-report-message">
                    <div className="con">
                        <div className="wrap">
                            <h2>{getLanguageValue(PAGE_TYPE.SCHOOL, "messageDetail.txtFeedback")}</h2>
                            <h3>{getLanguageValue(PAGE_TYPE.SCHOOL, "messageDetail.txtFeedbackDesc")}</h3>
                            <form>
                                <textarea
                                    placeholder={getLanguageValue(PAGE_TYPE.SCHOOL, "messageDetail.txtInputFeedback")}
                                    value={feedbackMessage}
                                    onChange={(evt) => setFeedbackMessage(evt.target.value)}></textarea>
                                <div className="btn">
                                    <button
                                        className="cancel"
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            closeFeedbackPopup();
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.SCHOOL, "messageDetail.txtClose")}
                                    </button>
                                    <button
                                        className="reporting"
                                        disabled={isOnNetworking}
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            sendFeedback();
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.COMMON, "save")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            ) : null}

            {isShowWarnPopup ? (
                <div className="popup popup-report-message">
                    <div className="con">
                        <div className="wrap">
                            <div
                                className="close-btn"
                                onClick={(e: any) => {
                                    setIsShowWarnPopup(false);
                                }}></div>
                            <h2>{getLanguageValue(PAGE_TYPE.SCHOOL, "messageDetail.txtWarnTitle")}</h2>
                            <form>
                                <div className="input-box">
                                    <p>
                                        {getLanguageValue(PAGE_TYPE.SCHOOL, "messageDetail.txtWarnDesc1")}
                                        <br />
                                        <br />
                                        {getLanguageValue(PAGE_TYPE.SCHOOL, "messageDetail.txtWarnDesc3")}
                                        <br />
                                        {getLanguageValue(PAGE_TYPE.SCHOOL, "messageDetail.txtWarnDesc4")}
                                        <br />
                                        <br />
                                        {getLanguageValue(PAGE_TYPE.SCHOOL, "messageDetail.txtWarnDesc5")}
                                        <br />
                                    </p>
                                </div>

                                <div className="btn">
                                    <button
                                        className="reporting"
                                        onClick={() => {
                                            setIsShowWarnPopup(false);
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.AGENCY, "messageDetail.txtConfirm")}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            ) : null}
        </main>
    );
};

export default MessageDetailComponent;
