import React from "react";
import { useLocation } from "react-router-dom";

const CommonHeaderComponent = (props: any) => {
  const {
    logout,
    render,
    mobileMenuSlideClassName,
    setMobileMenuSlideClassName,
    userInfo,
  } = props;
  const location = useLocation();

  const goPreRegist = (e: any) => {
    e.preventDefault();
    render("/?pre-registration=1");
  };

  return (
    <header className="header-agency header-landing">
      <div className="con">
        <div className="top">
          <div className="logo">
            <a
              className="img-wrap"
              onClick={(e: any) => {
                e.preventDefault();
                render("/");
              }}>
              <img
                src="./imgs/common/logo/img-logo/logo-t2-125.png"
                alt="wannabes"
              />
            </a>
          </div>

          <div
            className="mb-btn-hamburger"
            onClick={() => setMobileMenuSlideClassName("on")}>
            <img src="./imgs/icons/icon-hamburger.png" alt="Hamburger menu" />
          </div>

          {/* <!-- 
                            모바일일 경우 class 에 
                                on 을 추가해 메뉴 slide in
                                off 를 추가해 메뉴 slide out
                            --> */}
          <nav className={`agency-gnb landing-gnb ${mobileMenuSlideClassName}`}>
            <div className="btn">
              <button
                className="close-btn"
                onClick={() => setMobileMenuSlideClassName("off")}>
                <img
                  src="./imgs/icons/icon-close-btn-mb.png"
                  alt="close button"
                />
              </button>
            </div>
            <ol>
              <li>
                <div
                  className={`item ${
                    location.pathname.startsWith("/audition") ? "on" : ""
                  }`}>
                  <a
                    onClick={(e: any) => {
                      e.preventDefault();
                      render("/audition");
                    }}>
                    AUDITION
                  </a>
                </div>
              </li>
              <li>
                <div
                  className={`item ${
                    location.pathname === "/about" ? "on" : ""
                  }`}>
                  <a
                    onClick={(e: any) => {
                      e.preventDefault();
                      render("/about");
                    }}>
                    ABOUT
                  </a>
                </div>
              </li>
              <li>
                <div
                  className={`item ${
                    location.pathname === "/mission" ? "on" : ""
                  }`}>
                  <a
                    onClick={(e: any) => {
                      e.preventDefault();
                      render("/mission");
                    }}>
                    MISSION
                  </a>
                </div>
              </li>
              {/* <li>
                <div
                  className={`item ${
                    location.pathname === "/partner" ? "on" : ""
                  }`}
                >
                  <a
                    onClick={(e: any) => {
                      e.preventDefault();
                      render("/partner");
                    }}
                  >
                    PARTNER
                  </a>
                </div>
              </li> */}
              {/* <li>
                <div
                  className={`item ${
                    location.pathname === "/pricing" ? "on" : ""
                  }`}
                >
                  <a
                    onClick={(e: any) => {
                      e.preventDefault();
                      render("/pricing");
                    }}
                  >
                    PRICING
                  </a>
                </div>
              </li> */}
              <li>
                <div
                  className={`item ${
                    location.pathname === "/contact" ? "on" : ""
                  }`}>
                  <a
                    onClick={(e: any) => {
                      e.preventDefault();
                      render("/contact");
                    }}>
                    CONTACT
                  </a>
                </div>
              </li>
            </ol>

            <div className="mb-util">
              {/* <div className="col">
                <button className="btn-logout" onClick={goPreRegist}>
                  PRE-REGISTRATION
                </button>
              </div> */}
              {userInfo?.username ? (
                <>
                  <div className="col">
                    <button
                      className="btn-console"
                      onClick={(e: any) => {
                        e.preventDefault();
                        render("/login");
                      }}>
                      CONSOLE
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col">
                    <button
                      className="btn-login"
                      onClick={(e: any) => {
                        e.preventDefault();
                        render("/login");
                      }}>
                      LOGIN
                    </button>
                  </div>
                  <div className="col">
                    <button
                      className="btn-signup"
                      onClick={(e: any) => {
                        e.preventDefault();
                        render("/signup");
                      }}>
                      SIGNUP
                    </button>
                  </div>
                </>
              )}

              {/* <!-- 사전가입 버튼  --> */}
            </div>
          </nav>
          {/* <div className="pc-tb-util">
            <a className="btn pre-registration-btn" onClick={goPreRegist}>
              <button>PRE-REGISTRATION</button>
            </a>
          </div> */}
          <div className="pc-tb-util">
            {userInfo?.username ? (
              <a
                className="btn console-btn"
                onClick={(e: any) => {
                  e.preventDefault();
                  render("/login");
                }}>
                <button>CONSOLE</button>
              </a>
            ) : (
              <>
                <a
                  className="btn login-btn"
                  onClick={(evt) => render("/login")}>
                  <button>LOGIN</button>
                </a>
                <a
                  className="btn signup-btn"
                  onClick={(evt) => render("/signup")}>
                  <button>SIGNUP</button>
                </a>
              </>
            )}
          </div>
          {/* <div className="pc-tb-util">
            
          </div> */}
        </div>
      </div>
    </header>
  );
};
export default CommonHeaderComponent;
