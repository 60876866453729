import React, { useEffect, useRef, useState } from "react";
import StudentDetailComponent from "./StudentDetail.component";
import * as HomeApi from "api/home/Home.api";
import * as StudentApi from "api/student/Student.api";
import * as MessageApi from "api/message/Message.api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUpdatedFavoriteCount } from "reducers/Agency";
import { getAgencyId, setAgencyId } from "helpers/Auth.helper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const StudentDetailContainer = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [searchParams] = useSearchParams();
  const agencyId = searchParams.get("agencyId");

  const { isLogin, info: myInfo } = useSelector((state: any) => state.user);
  const [studentInfo, setStudentInfo] = useState<any>();
  const [isPublic, setIsPublic] = useState(null);

  // favorite
  const btnRef = useRef<any>();
  const [isFavorited, setIsFavorited] = useState(false);

  // image popup
  const sliderRef = useRef<any>(null);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [initialSlideIdx, setInitialSlideIdx] = useState(0);

  // message
  const [messageRequest, setMessageRequest] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);

  const getInfo = async () => {
    // cookie에 agencyId 값이 있는 경우 wannabe 정보 조회 api param에 추가
    const agencyIdValue = getAgencyId();
    const result = await HomeApi.getStudentInfo(slug, {
      agencyId: agencyIdValue ? agencyIdValue : null,
    });

    if (result?.info?.id) {
      setIsFavorited(result.info.isFavorited);
      setInfo(result.info);
      //if (isLogin && myInfo.accountTypeCode === "accountType:agency") {
      //  navigate(`/agency/wannabe/${result.info.id}`, { replace: true });
      //} else if (isLogin && myInfo.accountTypeCode === "accountType:school") {
      //  navigate("/school", { replace: true });
      //} else if (isLogin && myInfo.accountTypeCode === "accountType:student") {
      //  navigate("/student", { replace: true });
      //}
    } else {
      //if (isLogin && myInfo.accountTypeCode === "accountType:agency") {
      //  navigate(`/agency`, { replace: true });
      //} else if (isLogin && myInfo.accountTypeCode === "accountType:school") {
      //  navigate("/school", { replace: true });
      //} else if (isLogin && myInfo.accountTypeCode === "accountType:student") {
      //  navigate("/student", { replace: true });
      //} else {
      //  navigate("/");
      //}
      navigate("/");
    }
  };

  const setInfo = (info: any) => {
    const height = info?.height
      ? `${info.height}cm${
          info?.weight || info?.bloodTypeCodeInfo ? " / " : ""
        }`
      : "";
    const weight = info?.weight
      ? `${info.weight}kg${info?.bloodTypeCodeInfo ? " / " : ""}`
      : "";
    const bloodType = info?.bloodTypeCodeInfo
      ? info.bloodTypeCodeInfo.names.en
      : "";
    info.bodyInfo = `${height}${weight}${bloodType}`;

    setStudentInfo(info);
  };

  const changeFavoriteStatus = async () => {
    if (isLogin) {
      btnRef.current.disabled = true;

      const params = { studentId: studentInfo.id };
      const result = isFavorited
        ? await StudentApi.deleteFavorite(params)
        : await StudentApi.createFavorite(params);

      if (result) {
        isFavorited ? setIsFavorited(false) : setIsFavorited(true);
        btnRef.current.disabled = false;
      } else {
        btnRef.current.disabled = true;
      }
    } else {
      if (
        window.confirm(
          getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.txtConfirm")
        )
      ) {
        navigate("/login", {
          state: { isPrev: true, path: `/agency/wannabe/info/${studentInfo.id}` },
        });
      }
    }
  };

  // message
  const getMessageRequest = async (id: number) => {
    const requestInfo = await MessageApi.getRequestMessage(+id);
    if (requestInfo?.info) {
      setMessageRequest(requestInfo.info);
    }

    setIsLoading(false);
  };

  const createRequest = async (id: number) => {
    if (isLogin) {
      const result = await MessageApi.createRequest({ studentId: id });
      if (result) {
        await getMessageRequest(id);
      }
    } else {
      if (
        window.confirm(
          getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.txtConfirm")
        )
      ) {
        navigate("/login", {
          state: { isPrev: true, path: `/agency/wannabe/info/${id}` },
        });
      }
    }
  };

  const cancelRequest = async (id: number) => {
    if (messageRequest) {
      const result = await MessageApi.updateMessageInfo(messageRequest?.id, {
        messageType: "messageType:cancel",
      });
      if (result) {
        await getMessageRequest(id);
      }
    }
  };

  const showImagePopup = (idx: number) => {
    setIsShowPopup(true);
    setInitialSlideIdx(idx);
  };

  useEffect(() => {
    // agencyId가 있는 경우 cookie에 저장
    if (agencyId) {
      setAgencyId(agencyId);
    }
  }, [agencyId]);

  useEffect(() => {
    getInfo();
  }, [slug]);

  useEffect(() => {
    if (studentInfo?.id) {
      if (isLogin && myInfo.accountTypeCode === "accountType:agency") {
        getMessageRequest(studentInfo?.id);
      }
    }
  }, [studentInfo?.id]);

  useEffect(() => {
    // 팝업 노출 시 스크롤 금지
    document.body.style.overflow = isShowPopup ? "hidden" : "unset";
  }, [isShowPopup]);

  return (
    <StudentDetailComponent
      {...props}
      studentInfo={studentInfo}
      //   isPublic={isPublic}
      myInfo={myInfo}
      // favorite
      btnRef={btnRef}
      isFavorited={isFavorited}
      changeFavoriteStatus={changeFavoriteStatus}
      // image popup
      sliderRef={sliderRef}
      showImagePopup={showImagePopup}
      isShowPopup={isShowPopup}
      setIsShowPopup={setIsShowPopup}
      initialSlideIdx={initialSlideIdx}
      // message
      messageRequest={messageRequest}
      createRequest={createRequest}
      cancelRequest={cancelRequest}
      isLoading={isLoading}
      isLogin={isLogin}
      agencyId={agencyId}
    />
  );
};

export default StudentDetailContainer;
