import React, { useEffect } from "react";
import Pagination from "../../../commons/pagination/Pagination.container";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";
import LoadingComponent from "commons/loading/Loading.component";

const AuditionComponent = (props: any) => {
  const {
    isLoading,
    auditionList,
    go,
    // pagination
    limit,
    page,
    setPage,
    totalCount,
  } = props;

  return (
    <main className="">
      <div className="con">
        <div className="audition-home">
          <div className="title">
            <h2>AUDITION</h2>
          </div>

          <div className="controller">
            <button
              className="btn-add-audition"
              onClick={(evt) => go("/agency/audition/new")}>
              {`${getLanguageValue(
                PAGE_TYPE.AGENCY,
                "audition.btnNewAudition"
              )} +`}
            </button>
          </div>
          {isLoading ? (
            <LoadingComponent />
          ) : auditionList && auditionList.length === 0 ? (
            <div className="no-data">
              <p>No data</p>
            </div>
          ) : (
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <td className="no">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.AGENCY,
                          "audition.txtTableNo"
                        )}
                      </p>
                    </td>
                    <td className="title">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.AGENCY,
                          "audition.txtTableTitle"
                        )}
                      </p>
                    </td>
                    <td className="period">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.AGENCY,
                          "audition.txtTablePeriod"
                        )}
                      </p>
                    </td>
                    <td className="status">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.AGENCY,
                          "audition.txtTableStatus"
                        )}
                      </p>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {auditionList.map((item: any, idx: number) => (
                    <tr key={`audition-${idx}`}>
                      <td className="no">
                        <p>{totalCount - (page - 1) * limit - idx}</p>
                      </td>
                      <td className="title">
                        <p
                          onClick={(evt) =>
                            go(`/agency/audition/${item.id}`, item.status)
                          }>
                          {item.title}
                          {/* <!-- 업데이트 내용이 있을경우 --> */}
                          {item.updateCount ? (
                            <span>{item.updateCount}</span>
                          ) : null}
                        </p>
                      </td>
                      <td className="period">
                        <p>
                          {`${getFormattedDateValue(
                            item.startDate,
                            "YYYY.MM.DD"
                          )} ~ ${getFormattedDateValue(
                            item.endDate,
                            "YYYY.MM.DD"
                          )}`}
                        </p>
                      </td>
                      <td className="status">
                        <p
                          className={
                            item.status ? item.status.replace(" ", "-") : null
                          }>
                          {getCurrentLangName(
                            item.auditionStatusCodeInfo.names
                          )}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <Pagination
          url="/agency/audition"
          totalCount={totalCount}
          currentPage={page}
          pageSize={limit}
          pageGroupSize={10}
          setPage={setPage}
        />
      </div>
    </main>
  );
};
export default AuditionComponent;
