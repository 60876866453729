import React from "react";

import StudentCardComponent from "commons/card/StudentCard.component";
import LoadingComponent from "commons/loading/Loading.component";

const FavoriteComponent = (props: any) => {
    const { favoritedStudentList, deleteFavorite, isLoading } = props;

    return (
        <main>
            <div className="con">
                <div className="spotlight-wrap">
                    <h2>FAVORITES</h2>
                    <div className="spotlight">
                        {isLoading ? (
                            <LoadingComponent />
                        ) : (
                            <>
                                {favoritedStudentList.length === 0 ? (
                                    <div className="no-filter">
                                        <p>등록된 학생이 없습니다.</p>
                                    </div>
                                ) : (
                                    <ul>
                                        {favoritedStudentList.map((item: any, idx: number) => (
                                            <StudentCardComponent
                                                key={`favorite-${idx}`}
                                                item={item}
                                                changeFavoriteFunc={deleteFavorite}
                                                disableFavoriteButton={isLoading}
                                                {...props}
                                            />
                                        ))}
                                    </ul>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default FavoriteComponent;
