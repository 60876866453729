import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { getFormattedDateValue } from "helpers/Datetime.helper";
import { checkYoutubeId } from "helpers/Reg.helper";

import * as PortfolioApi from "api/school/Portfolio.api";
import * as StudentApi from "api/student/Student.api";

import CreatePortfolioComponent from "./CreatePortfolio.component";

const CreatePortfolioContainer = (props: any) => {
  const navigate = useNavigate();
  const { info: myInfo } = useSelector((state: any) => state.user);

  const btnRef = useRef<any>();

  const videoRef = useRef<any>(null);
  const [videoWidth, setVideoWidth] = useState(0);

  const [keyword, setKeyword] = useState("");

  const [studentList, setStudentList] = useState([]);
  const [taggedStudentList, setTaggedStudentList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const today = new Date().toString();
  const [params, setParams] = useState({
    youtubeId: null,
    title: "",
    performedDate: getFormattedDateValue(today, "YYYY-MM-DD"),
    studentIdList: "",
  });

  const getStudentList = async () => {
    const result = await StudentApi.getList({
      schoolId: myInfo.userSchoolId,
      keyword,
    });

    if (result.list.length > 0) {
      setStudentList(result.list);
    }
    setIsLoading(false);
  };

  const onChangeInput = (componentName: string, value: any) => {
    console.log("CreatePortfolioContainer:onChangeInput", componentName, value);

    if (componentName === "youtubeId") {
      setParams({ ...params, youtubeId: value });
    } else if (componentName === "title") {
      setParams({ ...params, title: value });
    }
    if (componentName === "performedDate") {
      setParams({ ...params, performedDate: value });
    }
  };

  const createInfo = async () => {
    btnRef.current.disabled = true;

    const studentIdList: number[] = [];
    taggedStudentList.map((item: any) => studentIdList.push(item.id));
    params.studentIdList =
      studentIdList.length > 0 ? JSON.stringify(studentIdList) : "";

    const result = await PortfolioApi.createInfo(params);

    if (result) {
      result.data?.errorCode
        ? alert(result.data.message)
        : navigate(`/school/portfolio/${result.data.data.insertedId}`, {
            state: 1,
          });
    }
  };

  const getTitle = async () => {
    if (params?.youtubeId && checkYoutubeId(params.youtubeId)) {
      const result = await PortfolioApi.getVideoTitle({
        youtubeId: params.youtubeId,
      });

      if (result) {
        setParams({ ...params, title: result.title });
      }
    }
  };

  const changeTaggedStudentList = (studentInfo: any) => {
    setTaggedStudentList((prev: any) => {
      let taggedStudentIdList = [...prev];

      if (!taggedStudentIdList.find((i: any) => i.id === studentInfo.id)) {
        taggedStudentIdList.push(studentInfo);
      } else {
        taggedStudentIdList = taggedStudentIdList.filter(
          (i: any) => i.id !== studentInfo.id
        );
      }

      return taggedStudentIdList;
    });
  };

  useEffect(() => {
    if (videoRef.current) {
      setVideoWidth(videoRef.current.offsetWidth);
    }
  }, [videoRef.current?.offsetWidth]);

  useEffect(() => {
    getStudentList();

    const updateSize = () => {
      if (videoRef.current) {
        setVideoWidth(videoRef.current.offsetWidth);
      }
    };
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  return (
    <CreatePortfolioComponent
      isLoading={isLoading}
      btnRef={btnRef}
      videoRef={videoRef}
      videoWidth={videoWidth}
      params={params}
      studentList={studentList}
      getStudentList={getStudentList}
      taggedStudentList={taggedStudentList}
      keyword={keyword}
      setKeyword={setKeyword}
      getTitle={getTitle}
      changeTaggedStudentList={changeTaggedStudentList}
      onChangeInput={onChangeInput}
      onClickBtnSave={createInfo}
    />
  );
};

export default CreatePortfolioContainer;
