import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as commonData from "data/common.data";
import { getCurrentLangName } from "helpers/String.helper";

const SchoolSignupComponent = (props: any) => {
  const {
    btnRef,
    accountParams,
    schoolParams,
    termList,
    imageInfo,
    fileInfo,
    isOnNetworking,
    uploadImage,
    uploadFile,
    onChangeInput,
    onChangeCheckbox,
    onClickBtnCancelSignup,
    onClickBtnSignup,
  } = props;

  return (
    <main className="signup-form school-sign-up">
      <div className="con">
        {/* 계정 정보 */}
        <h2>SCHOOL SIGNUP</h2>
        <div className="form account">
          <div className="title">
            <h3>ACCOUNT</h3>
            <p>
              {getLanguageValue(
                PAGE_TYPE.COMMON,
                "schoolSignup.txtAccountInfoSubTitle"
              )}
            </p>
          </div>
          <div className="input-box">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "schoolSignup.txtEmail"
            )}`}</label>
            <input
              type="text"
              value={accountParams.username}
              onChange={(evt) => onChangeInput("username", evt.target.value)}
              // placeholder="email"
            />
          </div>
          <div className="input-box">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "schoolSignup.txtPassword"
            )}`}</label>
            <input
              type="password"
              value={accountParams.password}
              onChange={(evt) => onChangeInput("password", evt.target.value)}
              // placeholder="password"
            />
          </div>
          <div className="input-box">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "schoolSignup.txtConfirmPassword"
            )}`}</label>
            <input
              type="password"
              value={accountParams.confirmedPassword}
              onChange={(evt) =>
                onChangeInput("confirmedPassword", evt.target.value)
              }
              // placeholder="confirm password"
            />
          </div>
        </div>
        {/* 필수 정보 */}
        <div className="form essential-information">
          <div className="title">
            <h3>ESSENTIAL INFORMATION</h3>
            <p>
              {getLanguageValue(
                PAGE_TYPE.COMMON,
                "schoolSignup.txtEssentialInfoSubTitle"
              )}
            </p>
          </div>
          <div className="input-box">
            <div className="img-upload">
              <div className="img-wrap">
                <div
                  className={imageInfo?.url ? "logo img-upload" : "img"}
                  style={{
                    backgroundImage: `url(${
                      imageInfo?.url || "./../../imgs/icons/camera.png"
                    })`,
                  }}
                />
              </div>
              <p>
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "schoolSignup.txtLogoUpload"
                )}
              </p>
              <input
                type="file"
                id="input-file-img"
                onChange={(evt) => uploadImage(evt)}
                accept="image/*"
              />
              <label htmlFor="input-file-img">
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "schoolSignup.btnUploadFile"
                )}
              </label>
            </div>
          </div>
          <div className="input-box">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "schoolSignup.txtSchoolName"
            )}`}</label>
            <input
              type="text"
              value={schoolParams.schoolName}
              onChange={(evt) => onChangeInput("schoolName", evt.target.value)}
              placeholder=""
            />
          </div>
          <div className="input-box input-file">
            <label>{`${getLanguageValue(
              PAGE_TYPE.COMMON,
              "schoolSignup.txtBusinessRegistration"
            )}`}</label>
            <div className="file-wrap">
              <label className="btn" htmlFor="file">
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "schoolSignup.btnUploadFile"
                )}
              </label>
              <input
                className="upload-file"
                type="file"
                id="file"
                onChange={(evt) => uploadFile(evt)}
              />
              <input
                readOnly={true}
                className="upload-name"
                value={fileInfo?.name}
                placeholder={getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "schoolSignup.txtFileUpload"
                )}
              />
            </div>
          </div>
          <div className="input-box">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "schoolSignup.txtRepresentative"
            )}`}</label>
            <input
              type="text"
              value={schoolParams.representative}
              onChange={(evt) =>
                onChangeInput("representative", evt.target.value)
              }
              placeholder=""
            />
          </div>
          <div className="input-box">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "schoolSignup.txtCountry"
            )}`}</label>
            <select
              value={schoolParams.countryCode}
              onChange={(evt) =>
                onChangeInput("countryCode", evt.target.value)
              }>
              <option value="" disabled selected>
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "schoolSignup.txtCountryOptional"
                )}
              </option>
              {commonData.getCountryCodeList().map((item: any, idx: number) => (
                <option key={idx} value={item.code}>
                  {getCurrentLangName(item.names)}
                </option>
              ))}
            </select>
          </div>
          <div className="input-box">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "schoolSignup.txtCity"
            )}`}</label>
            <input
              type="text"
              value={schoolParams.city}
              onChange={(evt) => onChangeInput("city", evt.target.value)}
              placeholder=""
            />
          </div>
          <div className="input-box input-file">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "schoolSignup.txtAddress1"
            )}`}</label>
            <input
              type="text"
              value={schoolParams.address1}
              onChange={(evt) => onChangeInput("address1", evt.target.value)}
              placeholder=""
            />
          </div>
          <div className="input-box">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "schoolSignup.txtAddress2"
            )}`}</label>
            <input
              type="text"
              value={schoolParams.address2}
              onChange={(evt) => onChangeInput("address2", evt.target.value)}
              placeholder=""
            />
          </div>
          <div className="input-box">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "schoolSignup.txtZipcode"
            )}`}</label>
            <input
              type="text"
              value={schoolParams.zipcode}
              onChange={(evt) => onChangeInput("zipcode", evt.target.value)}
              placeholder=""
            />
          </div>
          <div className="input-box">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "schoolSignup.txtTelephone"
            )}`}</label>
            <input
              type="text"
              value={schoolParams.telephone}
              onChange={(evt) => onChangeInput("telephone", evt.target.value)}
              placeholder=""
            />
          </div>
          <div className="input-box">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "schoolSignup.txtHomepageUrl"
            )}`}</label>
            <input
              type="text"
              value={schoolParams.homepageUrl}
              onChange={(evt) => onChangeInput("homepageUrl", evt.target.value)}
              placeholder=""
            />
          </div>
        </div>
        {/* 컨택 정보 */}
        <div className="form casting-manager">
          <div className="title">
            <h3>CONTACT INFORMATION</h3>
            <p>
              {getLanguageValue(
                PAGE_TYPE.COMMON,
                "schoolSignup.txtContactInfoSubTitle"
              )}
            </p>
          </div>
          <div className="input-box">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "schoolSignup.txtCellphone"
            )}`}</label>
            <input
              type="text"
              value={schoolParams.cellphone}
              onChange={(evt) => onChangeInput("cellphone", evt.target.value)}
              placeholder=""
            />
          </div>
          <div className="input-box">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "schoolSignup.txtEmail"
            )}`}</label>
            <input
              type="text"
              value={schoolParams.contactEmail}
              onChange={(evt) =>
                onChangeInput("contactEmail", evt.target.value)
              }
              placeholder=""
            />
          </div>
        </div>
        {/* sns 정보 */}
        <div className="form sns">
          <div className="title">
            <h3>SNS</h3>
            <p>
              {getLanguageValue(
                PAGE_TYPE.COMMON,
                "schoolSignup.txtSnsInfoSubTitle"
              )}
            </p>
          </div>
          <div className="input-box">
            <label>
              {getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.txtInstagram")}
            </label>
            <div className="input-prefix">
              <div className="prefix">
                <p>instagram.com/</p>
              </div>
              <input
                type="text"
                value={schoolParams.instagram}
                onChange={(evt) => onChangeInput("instagram", evt.target.value)}
                placeholder=""
              />
            </div>
          </div>
          <div className="input-box">
            <label>
              {getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.txtYoutube")}
            </label>
            <div className="input-prefix">
              <div className="prefix">
                <p>youtube.com/@</p>
              </div>
              <input
                type="text"
                value={schoolParams.youtube}
                onChange={(evt) => onChangeInput("youtube", evt.target.value)}
                placeholder=""
              />
            </div>
          </div>
          <div className="input-box">
            <label>
              {getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.txtTwitter")}
            </label>
            <div className="input-prefix">
              <div className="prefix">
                <p>twitter.com/</p>
              </div>
              <input
                type="text"
                value={schoolParams.twitter}
                onChange={(evt) => onChangeInput("twitter", evt.target.value)}
                placeholder=""
              />
            </div>
          </div>
          <div className="input-box">
            <label>
              {getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.txtFacebook")}
            </label>
            <div className="input-prefix">
              <div className="prefix">
                <p>facebook.com/</p>
              </div>
              <input
                type="text"
                value={schoolParams.facebook}
                onChange={(evt) => onChangeInput("facebook", evt.target.value)}
                placeholder=""
              />
            </div>
          </div>
          <div className="input-box">
            <label>
              {getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.txtTiktok")}
            </label>
            <div className="input-prefix">
              <div className="prefix">
                <p>tiktok.com/@</p>
              </div>
              <input
                type="text"
                value={schoolParams.tiktok}
                onChange={(evt) => onChangeInput("tiktok", evt.target.value)}
                placeholder=""
              />
            </div>
          </div>
        </div>

        {/* 약관 목록 */}
        <div className="form">
          <div className="input-box agreement-form">
            <ul className="list">
              {termList.length > 0 &&
                termList.map((item: any, idx: number) => (
                  <li key={`term-${idx}`} className="item">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        onChange={(evt) => {
                          onChangeCheckbox(idx, evt.target.checked);
                        }}
                        checked={item.checked}
                      />
                      <span className="txt">{`(${
                        item.isRequired
                          ? getLanguageValue(
                              PAGE_TYPE.COMMON,
                              "schoolSignup.txtRequired"
                            )
                          : getLanguageValue(
                              PAGE_TYPE.COMMON,
                              "schoolSignup.txtOptional"
                            )
                      }) ${item.title}`}</span>
                    </label>
                    <button
                      type="button"
                      className="btn"
                      onClick={(evt) =>
                        window.open(
                          item.isRequired
                            ? `/term/common/${item.id}`
                            : `/term/etc/${item.id}`
                        )
                      }>
                      {`(${getLanguageValue(
                        PAGE_TYPE.COMMON,
                        "schoolSignup.txtShow"
                      )})`}
                    </button>
                  </li>
                ))}
            </ul>
            <div className="all-check">
              <label className="checkbox">
                <input
                  type="checkbox"
                  className="select-all"
                  onChange={(evt) => {
                    onChangeCheckbox("all", evt.target.checked);
                  }}
                  checked={!termList.find((i: any) => !i.checked)}
                />
                <span className="txt">
                  {getLanguageValue(
                    PAGE_TYPE.COMMON,
                    "schoolSignup.txtAgreeWithAllTerms"
                  )}
                </span>
              </label>
            </div>
          </div>
        </div>

        <div className="btn">
          <button
            className="cancel-btn"
            onClick={(evt) => {
              onClickBtnCancelSignup();
            }}>
            {getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.btnCancel")}
          </button>
          <button
            className="signup-btn"
            ref={btnRef}
            onClick={(evt) => {
              onClickBtnSignup();
            }}
            disabled={isOnNetworking}>
            {getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.btnSignup")}
          </button>
        </div>
      </div>
    </main>
  );
};

export default SchoolSignupComponent;
