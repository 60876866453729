import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import LoadingComponent from "commons/loading/Loading.component";

const UpdateMyComponent = (props: any) => {
  const {
    id,
    updateType,
    agencyParams,
    isOnNetworking,
    uploadImage,
    onChangeInput,
    onClickBtnCancelUpdate,
    onClickBtnUpdateInfo,
    businessCardFrontImageInfo,
    businessCardBackImageInfo,
  } = props;

  return (
    <main className="casting-manager-info">
      <div className="con">
        <div className="form">
          <h2>ESSENTIAL INFORMATION UPDATE</h2>
          <div className="con img-con input-box">
            <div className="img-upload bussiness-card">
              <div className="img-wrap">
                <div
                  className={businessCardFrontImageInfo?.url ? "photo" : "img"}
                  style={{
                    backgroundImage: `url(${
                      businessCardFrontImageInfo?.url ||
                      "./../../imgs/icons/camera.png"
                    })`,
                  }}
                />
              </div>
              <p>
                {getLanguageValue(PAGE_TYPE.AGENCY, "my.txtBusinessCardFront")}
              </p>
              <input
                type="file"
                id="input-file-img1"
                onChange={(evt) => uploadImage("front", evt)}
                accept="image/*"
              />
              <label htmlFor="input-file-img1">
                {getLanguageValue(PAGE_TYPE.AGENCY, "my.btnUploadFile")}
              </label>
            </div>
            <div className="img-upload bussiness-card">
              <div className="img-wrap">
                <div
                  className={businessCardBackImageInfo?.url ? "photo" : "img"}
                  style={{
                    backgroundImage: `url(${
                      businessCardBackImageInfo?.url ||
                      "./../../imgs/icons/camera.png"
                    })`,
                  }}
                />
              </div>
              <p>
                {getLanguageValue(PAGE_TYPE.AGENCY, "my.txtBusinessCardBack")}
              </p>
              <input
                type="file"
                id="input-file-img2"
                onChange={(evt) => uploadImage("back", evt)}
                accept="image/*"
              />
              <label htmlFor="input-file-img2">
                {getLanguageValue(PAGE_TYPE.AGENCY, "my.btnUploadFile")}
              </label>
            </div>
          </div>
          <div className="con">
            <h3 className="title-con">
              {getLanguageValue(PAGE_TYPE.AGENCY, "my.txtName")}
            </h3>
            {/* <!-- my page일때 class에 bg-color 추가 --> */}
            <input
              type="text"
              className="text"
              value={agencyParams.name}
              onChange={(evt) => onChangeInput("name", evt.target.value)}
            />
          </div>
          <div className="con">
            <h3 className="title-con">
              {getLanguageValue(PAGE_TYPE.AGENCY, "my.txtPhoneNumber")}
            </h3>
            {/* <!-- my page일때 class에 bg-color 추가 --> */}
            <input
              type="text"
              className="text"
              value={agencyParams.cellphone}
              onChange={(evt) => onChangeInput("cellphone", evt.target.value)}
            />
          </div>

          {/* <!-- 기본정보 업데이트일때 cancel, update 버튼 사용 --> */}
          <div className="btn">
            <div className="cancel-btn">
              <button onClick={(evt) => onClickBtnCancelUpdate()}>
                {getLanguageValue(PAGE_TYPE.AGENCY, "my.btnCancel")}
              </button>
            </div>
            <div className="update-btn">
              <button
                onClick={(evt) => onClickBtnUpdateInfo()}
                disabled={isOnNetworking}>
                {getLanguageValue(PAGE_TYPE.AGENCY, "my.btnUpdate")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default UpdateMyComponent;
