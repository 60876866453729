import React, { useEffect, useState } from "react";
import MessageListComponent from "./MessageListComponent";
import { getSchoolChannels, createChannel } from "../../../api/common/Chat.api";
import {
  getMessageRequestBySchool,
  updateMessageInfo,
} from "../../../api/message/Message.api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const MessageListContainer = (props: any) => {
  const navigate = useNavigate();
  const { info: userInfo } = useSelector((state: any) => state.user);

  const [request, setRequest] = useState([]);
  const [channels, setChannels] = useState([]);
  const [isPopup, setIsPopup] = useState(false);
  const [popupItem, setPopupItem] = useState({});
  const [isRequestShowLoading, setIsRequestShowLoading] = useState(true);
  const [isChannelShowLoading, setIsChannelShowLoading] = useState(true);

  const getMessageRequestBySchoolValues = async () => {
    const requestResult = await getMessageRequestBySchool({
      status: "messageType:request",
    });
    if (requestResult.list) {
      setRequest(requestResult.list);
    } else {
      setRequest([]);
    }
    setIsRequestShowLoading(false);
  };

  const init = async () => {
    await getMessageRequestBySchoolValues();
    const channelResult = await getSchoolChannels();
    if (channelResult?.list) {
      setChannels(channelResult.list); // 채널 목록 설정
    }
    setIsChannelShowLoading(false);
  };

  const handleLiClick = (item: any) => {
    setIsPopup(!isPopup);
    setPopupItem(item);
    console.log(item);
  };

  const denyRequest = async (item: any) => {
    await updateMessageInfo(item.id, {
      messageType: "messageType:deny",
    });
    await getMessageRequestBySchoolValues();
    setIsPopup(false);
  };

  const acceptRequest = async (item: any) => {
    // 요청 수락 처리
    const result = await createChannel({
      otherUserId: item.userAgencyId,
      otherUserType: item.userAgencyType,
      userId: item.userSchoolId,
      userType: item.userSchoolType,
    });

    if (result) {
      const channelId = result.data?.data?.channelId;
      if (channelId) {
        await updateMessageInfo(item.id, {
          messageType: "messageType:accept",
          channelId: channelId,
        });
        await getMessageRequestBySchoolValues();
        setIsPopup(false);
        navigate(`/school/message/${channelId}`, {
          state: {
            companyName: item.userAgencyName,
            isInit: true,
            userAgencyId: item.userAgencyId,
            userStudentId: item.userStudentId,
            userStudentProfileImage: item.userStudentProfileImage,
            userStudentName: `${item.userStudentFirstName} ${item.userStudentLastName}`,
          },
        });
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <MessageListComponent
      request={request}
      userInfo={userInfo}
      channels={channels}
      navigate={navigate}
      denyRequest={denyRequest}
      acceptRequest={acceptRequest}
      handleLiClick={handleLiClick}
      isPopup={isPopup}
      popupItem={popupItem}
      isRequestShowLoading={isRequestShowLoading}
      isChannelShowLoading={isChannelShowLoading}
      {...props}
    />
  );
};

export default MessageListContainer;
