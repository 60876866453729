import React, { useEffect, useState } from "react";

import StudentNewRegister from "./StudentNewRegister.container";
import StudentDirectRegister from "./StudentDirectRegister.container";
import { getStudentInfo } from "api/school/School.api";
import { useLocation, useParams } from "react-router-dom";

const StudentNewRegisterContainer = (props: any) => {
  const location = useLocation();
  const [isInit, setIsInit] = useState(false);
  const [stepNum, setStepNum] = useState(0);
  const [studentInfo, setStudentInfo] = useState<any>({});
  const page = location?.state ? location.state : 1;
  const { id } = useParams();

  const getStudentInfoValues = async (id: number) => {
    const result = await getStudentInfo(id);
    if (result?.info) {
      setStudentInfo(result.info);
    }
  };

  useEffect(() => {
    doInit();
  }, []);

  const doInit = async () => {
    console.log("Student.container:doInit");
    if (id) {
      await getStudentInfoValues(id ? parseInt(id) : 0);
    }
    setIsInit(true);
  };

  const goToNextStep = () => {
    setStepNum(stepNum + 1);
  };
  const goToPrevStep = () => {
    setStepNum(stepNum - 1);
  };

  return isInit ? (
    <>
      {stepNum === 0 ? (
        <StudentNewRegister
          goToNextStep={goToNextStep}
          goToPrevStep={goToPrevStep}
          page={page}
          id={id}
          studentInfo={studentInfo}
        />
      ) : null}
      {stepNum === 1 ? (
        <StudentDirectRegister
          goToPrevStep={goToPrevStep}
          id={id}
          studentInfo={studentInfo}
        />
      ) : null}
    </>
  ) : null;
};

export default StudentNewRegisterContainer;
