import React, { useEffect, useState } from "react";
import UnSubscribeEmailDoneComponent from "./UnSubscribeEmailDone.component";
import { useLocation, useNavigate } from "react-router-dom";

const UnSubscribeEmailDoneContainer = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};
  const { email = "" } = state;

  useEffect(() => {
    if (!email) {
      navigate("/");
    }
  }, []);

  return <UnSubscribeEmailDoneComponent {...props} email={email} />;
};

export default UnSubscribeEmailDoneContainer;
