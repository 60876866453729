import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as commonData from "data/common.data";
import { getCurrentLangName } from "helpers/String.helper";
import { useNavigate } from "react-router-dom";

const StudentSignupDoneComponent = (props: any) => {
    const { accountParams, studentParams, parentEmail, checkIsUnder14, imageInfo } = props;

    const navigate = useNavigate();
    return (
        <main className="signup-form student-signup student-signup-done">
            <div className="con">
                <h2>{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtDoneTitle")}</h2>
                {/* 이미지 정보 */}
                <div className="form essential-information">
                    <div className="input-box">
                        <div className="img-upload">
                            <div className="img-wrap">
                                <div
                                    className={imageInfo?.url ? "casting-manager-img img-upload" : "img"}
                                    style={{
                                        backgroundImage: `url(${imageInfo?.url || "./../../imgs/icons/camera.png"})`,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* 설명 */}
                <div className="form">
                    <div className="title gap">
                        <p>
                            🎉
                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtDoneDesc1").replace(
                                "%NAME%",
                                `${studentParams.firstName} ${studentParams.lastName}`
                            )}
                            🎉
                            <br />
                            <br />
                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtDoneDesc2")}😁
                            <br />
                            <br />
                            {!checkIsUnder14(studentParams.birthday) ? (
                                <> {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtDoneDesc3").replace("%EMAIL%", accountParams.username)}</>
                            ) : (
                                <>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtDoneDesc4")
                                        .replace("%EMAIL%", accountParams.username)
                                        .replace("%PARENT_EMAIL%", parentEmail)}
                                    <br />
                                    <br />
                                    {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtDoneDesc5")}
                                </>
                            )}
                        </p>
                    </div>
                </div>

                <div className="btn">
                    <button
                        className="signup-btn"
                        onClick={(e) => {
                            navigate("/login");
                        }}>
                        {getLanguageValue(PAGE_TYPE.COMMON, "agencySignupDone.btnGoToLogin")}
                    </button>
                </div>
            </div>
        </main>
    );
};

export default StudentSignupDoneComponent;
