import React from "react";

const LoadingComponent = (props: any) => {
    return (
        <div className="loading-wrap">
            <div className="loading-spinner"></div>
        </div>
    );
};

export default LoadingComponent;
