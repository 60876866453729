import React, { useEffect, useState } from "react";
import MessageListComponent from "./MessageListComponent";
import {
  getStudentChannels,
  createChannel,
} from "../../../api/common/Chat.api";
import {
  getMessageRequestByStudent,
  updateMessageInfo,
} from "../../../api/message/Message.api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const MessageListContainer = (props: any) => {
  const navigate = useNavigate();
  const { info: userInfo } = useSelector((state: any) => state.user);

  const [request, setRequest] = useState([]);
  const [channels, setChannels] = useState([]);
  const [isPopup, setIsPopup] = useState(false);
  const [popupItem, setPopupItem] = useState({});
  const [isRequestShowLoading, setIsRequestShowLoading] = useState(true);
  const [isChannelShowLoading, setIsChannelShowLoading] = useState(true);

  const getMessageRequestByStudentValues = async () => {
    const requestResult = await getMessageRequestByStudent({
      status: "messageType:request",
    });
    if (requestResult.list) {
      setRequest(requestResult.list);
    } else {
      setRequest([]);
    }
    setIsRequestShowLoading(false);
  };

  const init = async () => {
    await getMessageRequestByStudentValues();
    const channelResult = await getStudentChannels();
    if (channelResult?.list) {
      setChannels(channelResult.list); // 채널 목록 설정
    }
    setIsChannelShowLoading(false);
  };

  const handleLiClick = (item: any) => {
    setIsPopup(!isPopup);
    setPopupItem(item);
  };

  const denyRequest = async (item: any) => {
    if (
      window.confirm(
        getLanguageValue(PAGE_TYPE.STUDENT, "messageList.txtConfirmDeny")
      )
    ) {
      await updateMessageInfo(item.id, {
        messageType: "messageType:deny",
      });
      await getMessageRequestByStudentValues();
      setIsPopup(false);
    }
  };

  const acceptRequest = async (item: any) => {
    // 요청 수락 처리
    const result = await createChannel({
      otherUserId: item.userAgencyId,
      otherUserType: item.userAgencyType,
      userId: item.userStudentId,
      userType: item.userStudentType,
    });

    if (result) {
      const channelId = result.data?.data?.channelId;
      if (channelId) {
        await updateMessageInfo(item.id, {
          messageType: "messageType:accept",
          channelId: channelId,
        });
        await getMessageRequestByStudentValues();
        setIsPopup(false);
        navigate(`/student/message/${channelId}`, {
          state: {
            companyName: item.userAgencyName,
            userAgencyId: item.userAgencyId,
          },
        });
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <MessageListComponent
      {...props}
      request={request}
      userInfo={userInfo}
      channels={channels}
      navigate={navigate}
      denyRequest={denyRequest}
      acceptRequest={acceptRequest}
      handleLiClick={handleLiClick}
      isPopup={isPopup}
      popupItem={popupItem}
      isRequestShowLoading={isRequestShowLoading}
      isChannelShowLoading={isChannelShowLoading}
    />
  );
};

export default MessageListContainer;
