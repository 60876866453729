import React, { useEffect, useRef, useState } from "react";
import AuditionMemoPopupComponent from "./AuditionMemoPopup.component";
import * as AuditionApi from "api/agency/Audition.api";

const AuditionMemoPopupContainer = (props: any) => {
  const {
    memoItem,
    auditionApplicantList,
    setAuditionApplicantList,
    setIsShowAuditionMemoPopup,
  } = props;
  const [memo, setMemo] = useState(memoItem.memo);
  const btnRef = useRef<any>();

  const onClickBtnSave = async () => {
    btnRef.current.disabled = true;

    const result = await AuditionApi.updateMemoInfo(memoItem.id, {
      memo,
    });
    if (result) {
      if (result.data?.errorCode) {
        alert(result.data.message);
      } else {
        setAuditionApplicantList((prevList: any) => {
          const updatedList = [...prevList];
          const itemIndex = updatedList.findIndex(
            (item: any) => item.id === memoItem.id
          );

          if (itemIndex > -1) {
            updatedList[itemIndex] = { ...updatedList[itemIndex], memo: memo };
          }

          return updatedList;
        });

        setIsShowAuditionMemoPopup(false);
      }
    }
    btnRef.current.disabled = false;
  };

  return (
    <AuditionMemoPopupComponent
      {...props}
      memoItem={memoItem}
      memo={memo}
      setMemo={setMemo}
      setIsShowAuditionMemoPopup={setIsShowAuditionMemoPopup}
      btnRef={btnRef}
      onClickBtnSave={onClickBtnSave}
    />
  );
};

export default AuditionMemoPopupContainer;
