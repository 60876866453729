import React from "react";
import DaumPostcodeEmbed from "react-daum-postcode";

import ArtistPopup from "./ArtistPopup";
import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";

const AgencySignupComponent = (props: any) => {
  const { agencyList, agencyInfo, selectedAgencyInfo, next, onAgencyChange } =
    props;

  return (
    <main className="signup-form choose-agency">
      <div className="con">
        <h2>CASTING MANAGER SIGNUP</h2>
        <div className="form form-choose-agency">
          <h3>
            {getLanguageValue(
              PAGE_TYPE.COMMON,
              "agencySignupPre.txtSelectAgencyPrompt"
            )}
          </h3>

          <div className="input-box input-box-select-agency">
            <div className="desc desc-1">
              <p>
                *
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "agencySignupPre.txtSelectAgency"
                )}
              </p>
            </div>
            <select onChange={(e) => onAgencyChange(e.target.value)}>
              <option value="" selected>
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "agencySignupPre.txtSelectAgencyPrompt"
                )}
              </option>
              {agencyList
                ? agencyList.map((item: any, idx: number) => (
                    <option key={idx} value={item.id}>
                      {item.nameKo}
                    </option>
                  ))
                : null}
            </select>
            <div className="desc desc-2">
              <p>
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "agencySignupPre.txtNoAgencyFound"
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="controller">
          <button className="btn-next" onClick={next}>
            {getLanguageValue(PAGE_TYPE.COMMON, "agencySignupPre.txtNext")}
          </button>
        </div>
      </div>
    </main>
  );
};

export default AgencySignupComponent;
