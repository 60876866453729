import axios from "axios";
import { getQueryStringFromObject } from "helpers/Api.helper";

export const get = async (url: string, slug = {}) => {
    console.log("Api.service:get");
    const params = getQueryStringFromObject(slug);
    return axios
        .get(`${url}${params}`)
        .then(({ status, data }) => {
            if (status === 200) {
                return data.data;
            }
        })
        .catch(errorHandler);
};

export const post = async (url: string, params: any) => {
    console.log("Api.service:post");

    return axios
        .post(url, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const put = async (url: string, params: any) => {
    console.log("Api.service:put");

    return axios
        .put(url, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const patch = async (url: string, params: any) => {
    console.log("Api.service:put");

    return axios
        .patch(url, params)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

export const remove = async (url: string, slug = {}) => {
    console.log("Api.service:remove");
    const params = getQueryStringFromObject(slug);

    return axios
        .delete(`${url}${params}`)
        .then(({ status, data }) => {
            return { status, data };
        })
        .catch(errorHandler);
};

const errorHandler = ({ response }: any) => {
    console.log(response);
};
