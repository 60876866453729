import React, { useEffect, useState } from "react";
import AuditionComponent from "./Audition.component";
import { useLocation, useNavigate } from "react-router-dom";

import * as AuditionApi from "api/agency/Audition.api";

const AuditionContainer = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [auditionList, setAuditionList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // pagination
  const searchParams = new URLSearchParams(location.search);
  const limit = 10;
  const [page, setPage] = useState(
    location.state?.page ||
      location.state?.prev ||
      +searchParams.get("page") ||
      1
  );
  const [totalCount, setTotalcount] = useState(0);

  const getList = async () => {
    setIsLoading(true);
    const params = { page, limit };
    const result = await AuditionApi.getList(params);

    if (result?.list) {
      setAuditionList(result.list);
      setTotalcount(result.totalCount);
    }
    setIsLoading(false);
  };

  const go = (path: string, status: string) => {
    if (
      status === "in progress" ||
      status === "done" ||
      status === "evaluation completed"
    ) {
      navigate(path, {
        state: { page: 1, prev: page },
      });
    } else {
      navigate(path, {
        state: { page },
      });
    }
  };

  useEffect(() => {
    getList();
  }, [page]);

  return (
    <AuditionComponent
      {...props}
      go={go}
      isLoading={isLoading}
      auditionList={auditionList}
      // pagination
      limit={limit}
      page={page}
      setPage={setPage}
      totalCount={totalCount}
    />
  );
};

export default AuditionContainer;
