import React, { useEffect } from "react";
import StudentEvaluationContainer from "./StudentEvaluation.container";
import Pagination from "../../../commons/pagination/Pagination.container";
import { Link } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";
import LoadingComponent from "commons/loading/Loading.component";

const StudentComponent = (props: any) => {
  const {
    studentList,
    genderFilter,
    verifyFilter,
    searchTerm,
    genderType,
    isEvaluationModalOpen,
    selectedStudent,
    // pagination
    limit,
    page,
    setPage,
    totalCount,
    isShowLoading,
  } = props;
  const {
    handleEvaluation,
    handlePortfolio,
    handleCertification,
    handleCertificationChange,
    closeEvaluationModal,
    setGenderFilter,
    setVerifyFilter,
    setSearchTerm,
    search,
    navigate,
  } = props;

  return (
    <main className="">
      <div className="student-list-table">
        <div className="title">
          <h2>
            {getLanguageValue(PAGE_TYPE.SCHOOL, "student.txtStudentList")}
          </h2>
        </div>

        <div className="search-wrap">
          <div className="col col-gender">
            <div className="select-list">
              <select
                value={genderFilter}
                onChange={(e) => setGenderFilter(e.target.value)}>
                <option value="">
                  {getLanguageValue(PAGE_TYPE.SCHOOL, "student.txtAll")}
                </option>
                {genderType
                  ? genderType.map((item: any) => (
                      <option key={item.code} value={item.code}>
                        {getCurrentLangName(item.names)}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </div>
          <div className="col col-input">
            <div className="input-wrap">
              <input
                type="text"
                placeholder={getLanguageValue(
                  PAGE_TYPE.SCHOOL,
                  "student.txtSearchingKeyword"
                )}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    search();
                  }
                }}
              />
              <div className="btn-search-icon" onClick={() => search()}>
                <img
                  src="./imgs/icons/icon-search.png"
                  alt={getLanguageValue(PAGE_TYPE.SCHOOL, "student.txtSearch")}
                />
              </div>
            </div>
          </div>
          <div className="col col-certi">
            <label>
              <input
                type="checkbox"
                checked={verifyFilter}
                onChange={(e) => {
                  setVerifyFilter(e.target.checked);
                }}
              />
              <p>
                {getLanguageValue(PAGE_TYPE.SCHOOL, "student.txtCertificated")}
              </p>
            </label>
          </div>
        </div>
        {isShowLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <td className="no">
                      <p>No</p>
                    </td>
                    <td className="photo">
                      <p>
                        {getLanguageValue(PAGE_TYPE.SCHOOL, "student.txtPhoto")}
                      </p>
                    </td>
                    <td className="name">
                      <p>
                        {getLanguageValue(PAGE_TYPE.SCHOOL, "student.txtName")}
                      </p>
                    </td>
                    <td className="gender">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.SCHOOL,
                          "student.txtGender"
                        )}
                      </p>
                    </td>
                    <td className="birthday">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.SCHOOL,
                          "student.txtBirthday"
                        )}
                      </p>
                    </td>
                    <td className="rank">
                      <p>
                        {getLanguageValue(PAGE_TYPE.SCHOOL, "student.txtRank")}
                      </p>
                    </td>
                    <td className="evaluation">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.SCHOOL,
                          "student.txtEvaluation"
                        )}
                      </p>
                    </td>
                    <td className="portfolio">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.SCHOOL,
                          "student.txtPortfolio"
                        )}
                      </p>
                    </td>
                    <td className="certification">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.SCHOOL,
                          "student.txtCertification"
                        )}
                      </p>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {studentList
                    ? studentList.map((item: any, index: number) => (
                        <tr key={item.id}>
                          <td className="no">
                            <p>{totalCount - (page - 1) * limit - index}</p>
                          </td>
                          <td className="photo">
                            <div
                              className="img"
                              style={{
                                backgroundImage: `url(${
                                  item.profileImageUrl
                                    ? item.profileImageUrl.replace(
                                        / /g,
                                        "%20"
                                      ) + "?w=160&h=160"
                                    : "./imgs/dummy/basic-img-2.png"
                                })`,
                              }}></div>
                          </td>
                          <td className="name">
                            <Link
                              to={`/school/student/${item.id}`}
                              state={{
                                page: page,
                                verified: verifyFilter,
                                search: searchTerm,
                                gender: genderFilter,
                              }}
                              className="btn-link">
                              {`${item.firstName} ${item.lastName}`}
                            </Link>
                          </td>
                          <td className="gender">
                            <p>
                              {getCurrentLangName(
                                item.genderTypeCodeInfo.names
                              )}
                            </p>
                          </td>
                          <td className="birthday">
                            <p>
                              {item.birthday
                                ? getFormattedDateValue(
                                    item.birthday,
                                    "YYYY.MM.DD"
                                  )
                                : "-"}
                            </p>
                          </td>
                          <td className="rank">
                            <p>{item.evaluation}</p>
                          </td>
                          <td className="evaluation">
                            <button
                              className="btn-show-evaluation"
                              onClick={(e: any) => {
                                e.preventDefault();
                                handleEvaluation(e, item);
                              }}>
                              {getLanguageValue(
                                PAGE_TYPE.SCHOOL,
                                "student.txtEvaluate"
                              )}
                            </button>
                          </td>
                          <td className="portfolio">
                            <Link
                              to={`/school/portfolio?page=1&searchKeyword=${item.firstName}+${item.lastName}`}
                              className="btn-link">
                              {getLanguageValue(
                                PAGE_TYPE.SCHOOL,
                                "student.txtView"
                              )}
                            </Link>
                          </td>
                          <td className="certification">
                            <label>
                              <input
                                type="checkbox"
                                checked={item.isVerified === 1}
                                onChange={(e) => {
                                  handleCertificationChange(
                                    item,
                                    e.target.checked ? 1 : 0
                                  );
                                }}
                                onClick={(e: any) => {
                                  handleCertification(e);
                                }}
                              />
                            </label>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
            <div className="controller">
              <button
                className="btn-add-student"
                onClick={(e: any) => {
                  e.preventDefault();
                  navigate(`/school/student/new`, {
                    state: {
                      page: page,
                      verified: verifyFilter,
                      search: searchTerm,
                      gender: genderFilter,
                    },
                  });
                }}>
                {getLanguageValue(PAGE_TYPE.SCHOOL, "student.txtNewStudent")}
              </button>
            </div>
          </>
        )}
      </div>

      <Pagination
        url={"/school/student"}
        totalCount={totalCount}
        currentPage={page}
        pageSize={limit}
        pageGroupSize={10}
        setPage={setPage}
      />
      {isEvaluationModalOpen && (
        <StudentEvaluationContainer
          selectedStudent={selectedStudent}
          onClose={closeEvaluationModal}
        />
      )}
    </main>
  );
};
export default StudentComponent;
