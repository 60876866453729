import React, { useEffect, useState } from "react";
import HomeComponent from "./Home.component";

import * as StudentApi from "api/student/Student.api";
import * as SchoolApi from "api/school/School.api";

const HomeContainer = (props: any) => {
  const [studentList, setStudentList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // graph
  const moment = require("moment");
  const [date, setDate] = useState({
    favorite: moment().format("YYYY-MM-DD"),
    view: moment().format("YYYY-MM-DD"),
  });
  const [favoriteCntInfo, setFavoriteCntInfo] = useState({
    labels: [],
    data: [],
    isLoading: true,
    max: 0,
  });
  const [viewCntInfo, setViewCntInfo] = useState({
    labels: [],
    data: [],
    isLoading: true,
    max: 0,
  });

  const getStudentList = async () => {
    const result = await StudentApi.getListForHome();

    if (result) {
      setStudentList(result);
      setIsLoading(false);
    }
  };

  const getFavoriteOrViewCount = async (type: string) => {
    const result =
      type === "favorite"
        ? await SchoolApi.getFavoriteCntList({ date: date.favorite })
        : await SchoolApi.getViewCntList({ date: date.view });

    const labels: any[] = [];
    const data: any = { week: [], lastWeek: [] };

    result.weekList.map((item: any) => {
      data.week.push(item.count);
      labels.push([item.count]);
    });
    result.lastWeekList.map((item: any, idx: number) => {
      data.lastWeek.push(item.count);
      labels[idx].push(item.count);
    });

    const max = (num: number) => {
      while (num === 0 || num % 4 > 0) {
        num = num + 1;
      }
      return num;
    };

    const params = {
      labels,
      data,
      isLoading: false,
      max: max(Math.max(...data.week, ...data.lastWeek)),
    };
    type === "favorite" ? setFavoriteCntInfo(params) : setViewCntInfo(params);
  };

  const dataset = (
    data: any,
    borderColor: string,
    pointBackgroundColor?: string,
    borderDash?: number[]
  ) => {
    return {
      data,
      borderColor,
      pointBackgroundColor,
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      borderDash,
      pointRadius: 2.5,
      clip: {
        top: false,
        bottom: false,
        right: false,
        left: false,
      },
    };
  };

  useEffect(() => {
    getFavoriteOrViewCount("favorite");
  }, [date.favorite]);

  useEffect(() => {
    getFavoriteOrViewCount("view");
  }, [date.view]);

  useEffect(() => {
    getStudentList();
  }, []);

  return (
    <HomeComponent
      studentList={studentList}
      isLoading={isLoading}
      date={date}
      setDate={setDate}
      dataset={dataset}
      favoriteCntInfo={favoriteCntInfo}
      viewCntInfo={viewCntInfo}
    />
  );
};

export default HomeContainer;
