import * as BaseApi from "../Base.api";

export const getList = async () => {
  const url = `/school`;
  return await BaseApi.get(url);
};

export const getInfo = async (schoolId: number) => {
  const url = `/school/info/${schoolId}`;
  return await BaseApi.get(url);
};

export const getStudentInfo = async (studentId: number) => {
  const url = `/school/student/${studentId}`;
  return await BaseApi.get(url);
};

export const getFavoriteCntList = async (params: any) => {
  const url = "/school/favorite";
  return await BaseApi.get(url, params);
};

export const getViewCntList = async (params: any) => {
  const url = "/school/view";
  return await BaseApi.get(url, params);
};

export const updateInfo = async (id: number, params: any) => {
  const url = `/school/${id}`;
  return await BaseApi.put(url, params);
};

export const updateContactInfo = async (id: number, params: any) => {
  const url = `/school/contact/${id}`;
  return await BaseApi.put(url, params);
};

export const updateSnsInfo = async (id: number, params: any) => {
  const url = `/school/sns/${id}`;
  return await BaseApi.put(url, params);
};
