import React, { useEffect } from "react";
import PricingComponent from "./Pricing.component";

const PricingContainer = (props: any) => {
  useEffect(() => {}, []);

  return <PricingComponent {...props} />;
};

export default PricingContainer;
