import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import LoadingComponent from "commons/loading/Loading.component";
import { useNavigate } from "react-router-dom";

const VerifyCastingEmailComponent = (props: any) => {
    const { isVerify } = props;
    const navigate = useNavigate();

    return (
        <div className="certification">
            <main>
                {typeof isVerify !== "boolean" ? (
                    <LoadingComponent />
                ) : (
                    <div className={`${isVerify ? "completed" : ""}`}>
                        <strong>{getLanguageValue(PAGE_TYPE.COMMON, isVerify ? "verifyEmail.txtSuccessful" : "verifyEmail.txtFailed1")}</strong>
                        {!isVerify ? (
                            <p>{getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtFailed2")}</p>
                        ) : (
                            <p>{getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtSuccessful2")}</p>
                        )}
                        {isVerify ? (
                            <div className="btn">
                                <button onClick={(e: any) => {
                                    navigate(`/login`);
                                }}>{getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.btnSuccessful")}</button>
                            </div>
                        ) : null}
                    </div>
                )}
            </main>
        </div>
    );
};

export default VerifyCastingEmailComponent;
