import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { useNavigate } from "react-router-dom";

const AgencySignupDoneComponent = (props: any) => {
  const navigate = useNavigate();
  const {
    accountResult,
    agencyParams,
    businessCardFrontImageInfo,
    businessCardBackImageInfo,
  } = props;

  return (
    <main className="registration-complete">
      <div className="con">
        <h2>
          {getLanguageValue(
            PAGE_TYPE.COMMON,
            "agencySignupDone.txtCongratulationsCastingManagerSignup"
          )}
        </h2>
        <div className="iamge">
          <div className="bussiness-card-img">
            <img
              src={`${businessCardFrontImageInfo?.url}`}
              alt="bussiness card image"
            />
          </div>
          <div className="bussiness-card-img">
            <img
              src={`${businessCardBackImageInfo?.url}`}
              alt="bussiness card image"
            />
          </div>
        </div>

        <div className="ment">
          <p>
            <span>"{agencyParams?.name}"</span> <br />
            {getLanguageValue(
              PAGE_TYPE.COMMON,
              "agencySignupDone.txtSignupComplete"
            )}
            <br />
            {getLanguageValue(
              PAGE_TYPE.COMMON,
              "agencySignupDone.txtIntroduceTalentedAspiring"
            )}
            {/* <br />
            {getLanguageValue(
              PAGE_TYPE.COMMON,
              "agencySignupDone.txtVerifyAccountEmail"
            )} */}
          </p>
        </div>

        <div className="btn">
          <div>
            <button
              className="login-btn"
              onClick={(e) => {
                navigate("/login");
              }}>
              {getLanguageValue(
                PAGE_TYPE.COMMON,
                "agencySignupDone.btnGoToLogin"
              )}
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AgencySignupDoneComponent;
