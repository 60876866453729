import { PAGE_TYPE } from "enums";
import { formatTimeAgo } from "helpers/Datetime.helper";
import { getLanguageValue } from "locales/i18n";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const StudentHeaderComponent = (props: any) => {
  const {
    logout,
    render,
    mobileMenuSlideClassName,
    setMobileMenuSlideClassName,
    showNotification,
    setShowNotification,
    notificationList,
    totalUnReadCount,
    onClickCheckNotification,
    mobileNotificationSlideClassName,
    setMobileNotificationMenuSlideClassName,
  } = props;
  const location = useLocation();
  const { info: myInfo } = useSelector((state: any) => state.user);

  return (
    <header className="header">
      <div className="con">
        <div className="logo">
          <a
            className="img-wrap"
            onClick={(e: any) => {
              e.preventDefault();
              render("/student");
            }}>
            <img
              src="./imgs/common/logo/img-logo/logo-t2-125.png"
              alt="wannabes"
            />
          </a>
        </div>

        <div className="gnb">
          <ul>
            <li>
              <div
                className={`item ${
                  location.pathname.startsWith("/student/agency-profile") || location.pathname.startsWith("/student/profile")? "on" : ""
                }`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();
                    render("/student/profile");
                  }}>
                  PROFILE
                </a>
                {/* <div className="badge">
                  <p>4</p>
                </div> */}
              </div>
            </li>
            {/* <li>
              <div className="item">
                <a>SCHOOL</a>
              </div>
            </li> */}
            <li>
              <div
                className={`item ${
                  location.pathname.startsWith("/student/audition") ? "on" : ""
                }`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();
                    render("/student/audition");
                  }}>
                  AUDITION
                </a>
                {/* <div className="badge">
                  <p>4</p>
                </div> */}
              </div>
            </li>
            <li>
              <div
                className={`item ${
                  location.pathname.startsWith("/student/message") ? "on" : ""
                }`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();
                    render("/student/message");
                  }}>
                  MESSAGE
                </a>
                {/* <div className="badge">
                  <p>21</p>
                </div> */}
              </div>
            </li>
            {/* <li>
              <div className="item">
                <a>CONTRACT</a>
                <div className="badge">
                  <p>21</p>
                </div>
              </div>
            </li> */}
            <li>
              <div className={`item`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();
                    render(`/`);
                  }}>
                  PLAY GROUND
                </a>
              </div>
            </li>
          </ul>
        </div>

        <div className="pc-tb-util">
          <div className={`alarm-wrap ${showNotification ? "" : "off"}`}>
            <button
              className="alarm"
              onClick={(e) => {
                setShowNotification(!showNotification);
              }}>
              <img src="./imgs/icons/alarm-icon.png" alt="alarm" />
              {totalUnReadCount ? <span>{totalUnReadCount}</span> : null}
            </button>

            <div className="alarm-layer-content">
              <strong>
                {getLanguageValue(
                  PAGE_TYPE.STUDENT,
                  "header.txtUnreadNotifications"
                )}{" "}
                ({totalUnReadCount ? totalUnReadCount : 0})
              </strong>
              <a
                className="button-all-view"
                onClick={(e) => {
                  e.preventDefault();
                  render("/student/notification");
                }}>
                {getLanguageValue(PAGE_TYPE.STUDENT, "header.txtViewAll")}
              </a>
              {notificationList && notificationList.length === 0 ? (
                <p className="no-notification">
                  {getLanguageValue(
                    PAGE_TYPE.STUDENT,
                    "header.txtNodataNotification"
                  )}
                </p>
              ) : (
                <ul>
                  {notificationList.map((notification: any) => (
                    <li
                      onClick={(e) => {
                        onClickCheckNotification(notification);
                      }}
                      key={notification.id}
                      className={notification.isRead ? "read" : "not-read"}>
                      <div className="notification-header">
                        {formatTimeAgo(notification.sendAt)}
                      </div>
                      <div className="notification-body">
                        <div className="notification-title">
                          {notification.title}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <button
            className="logo"
            onClick={(e: any) => {
              e.preventDefault();
              render("/student/my");
            }}>
            <img
              src={`${
                myInfo.profileImage
                  ? myInfo.profileImage
                  : "./imgs/dummy/basic-img-2.png"
              }?w=100&h=100`}
              alt="profile image"
            />
          </button>
        </div>

        <div className="mb mb-alarm">
          <button
            className="alarm"
            onClick={(e) => {
              e.preventDefault();
              setMobileNotificationMenuSlideClassName("on");
            }}>
            <img src="./imgs/icons/alarm-icon.png" alt="alarm" />
            {totalUnReadCount ? <span>{totalUnReadCount}</span> : null}
          </button>

          {/* <!-- 
                모바일일 경우 class 에 
                on 을 추가해 메뉴 slide in
                off 를 추가해 메뉴 slide out
            --> */}
          <nav className={`alarm-gnb ${mobileNotificationSlideClassName}`}>
            <div className="btn">
              <button
                className="close-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setMobileNotificationMenuSlideClassName("off");
                }}>
                <img
                  src="./imgs/icons/icon-close-btn-mb.png"
                  alt="close button"
                />
              </button>
            </div>
            <div className="con">
              <strong>
                {getLanguageValue(
                  PAGE_TYPE.STUDENT,
                  "header.txtUnreadNotifications"
                )}{" "}
                ({totalUnReadCount ? totalUnReadCount : 0})
              </strong>
              <a
                className="button-all-view"
                onClick={(e) => {
                  e.preventDefault();
                  render("/student/notification");
                }}>
                {getLanguageValue(PAGE_TYPE.STUDENT, "header.txtViewAll")}
              </a>
            </div>
            {notificationList && notificationList.length === 0 ? (
              <p className="no-notification">
                {getLanguageValue(
                  PAGE_TYPE.STUDENT,
                  "header.txtNodataNotification"
                )}
              </p>
            ) : (
              <ul>
                {notificationList.map((notification: any) => (
                  <li
                    onClick={(e) => {
                      onClickCheckNotification(notification);
                    }}
                    key={notification.id}
                    className={notification.isRead ? "read" : "not-read"}>
                    <div className="notification-header">
                      {formatTimeAgo(notification.sendAt)}
                    </div>
                    <div className="notification-body">
                      <div className="notification-title">
                        {notification.title}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {/* <div className="mb-util">
              <button
                className="btn-mypage"
                onClick={(e) => {
                  render("/student/my");
                }}>
                MY PAGE
              </button>
            </div> */}
          </nav>
        </div>

        {/* <div
          className="util"
          onClick={(e: any) => {
            e.preventDefault();
            render("/student/my");
          }}>
          <img
            src={`${
              myInfo.profileImage
                ? myInfo.profileImage
                : "./imgs/dummy/basic-img-2.png"
            }?w=100&h=100`}
            alt="profile image"
          />
        </div> */}

        <div
          className="mb mb-btn-hamburger"
          onClick={() => setMobileMenuSlideClassName("on")}>
          <img src="./imgs/icons/icon-hamburger.png" alt="Hamburger menu" />
        </div>

        {/* <!-- 
                모바일일 경우 className 에 
                    on 을 추가해 메뉴 slide in
                    off 를 추가해 메뉴 slide out
            --> */}
        <nav className={`mb mb-gnb ${mobileMenuSlideClassName}`}>
          <div className="btn">
            <button
              className="close-btn"
              onClick={() => setMobileMenuSlideClassName("off")}>
              <img
                src="./imgs/icons/icon-close-btn-mb.png"
                alt="close button"
              />
            </button>
          </div>
          <ul>
            {/* <li>
              <div className="item on">
                <a>PROFILE</a>
              </div>
            </li>
            <li>
              <div className="item">
                <a>SCHOOL</a>
              </div>
            </li> */}
            <li>
              <div
                className={`item ${
                  location.pathname.startsWith("/student/agency-profile") || location.pathname.startsWith("/student/profile") ? "on" : ""
                }`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();
                    render(`/student/profile`);
                  }}>
                  PROFILE
                </a>
                {/* <div className="badge">
                  <p>4</p>
                </div> */}
              </div>
            </li>
            <li>
              <div
                className={`item ${
                  location.pathname.startsWith("/student/audition") ? "on" : ""
                }`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();
                    render(`/student/audition`);
                  }}>
                  AUDITION
                </a>
                {/* <div className="badge">
                  <p>4</p>
                </div> */}
              </div>
            </li>
            <li>
              <div
                className={`item ${
                  location.pathname.startsWith("/student/message") ? "on" : ""
                }`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();
                    render(`/student/message`);
                  }}>
                  MESSAGE
                </a>
                {/* <div className="badge">
                  <p>21</p>
                </div> */}
              </div>
            </li>
            <li>
              <div className={`item`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();
                    render(`/`);
                  }}>
                  PLAY GROUND
                </a>
              </div>
            </li>
            {/* <li>
              <div className="item">
                <a>CONTRACT</a>
                <div className="badge">
                  <p>21</p>
                </div>
              </div>
            </li> */}
          </ul>

          <div className="mb-util">
            <div className="col">
              <button
                className="btn-mypage"
                onClick={(e: any) => {
                  e.preventDefault();
                  render("/student/my");
                }}>
                MY PAGE
              </button>
            </div>
            {/* <!-- 마이페이지 버튼  --> */}
          </div>
        </nav>
      </div>
    </header>
  );
};
export default StudentHeaderComponent;
