import React from "react";
import { useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import LoadingComponent from "commons/loading/Loading.component";
import Pagination from "../../../commons/pagination/Pagination.container";
import { formatTimeAgo, getFormattedDateValue } from "helpers/Datetime.helper";

const NotificationComponent = (props: any) => {
  const {
    isLoading,
    notificationList,
    go,
    // pagination
    limit,
    page,
    setPage,
    totalCount,
    //
    onClickCheckNotification,
  } = props;

  return (
    <main className="all-view-notification">
      {isLoading ? (
        <LoadingComponent />
      ) : notificationList && notificationList.length === 0 ? (
        <>
          <h2>
            {getLanguageValue(
              PAGE_TYPE.STUDENT,
              "notification.txtNotification"
            )}
          </h2>
          <div className="no-data">
            <p>No data</p>
          </div>
        </>
      ) : (
        <>
          <h2>
            {getLanguageValue(
              PAGE_TYPE.STUDENT,
              "notification.txtNotification"
            )}
          </h2>
          <div className="table">
            {/* <button>모두 읽음 처리</button> */}
            {/* <!-- pc --> */}
            <div className="pc">
              <table>
                <thead>
                  <tr>
                    <td className="alarm">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.AGENCY,
                          "notification.txtContent"
                        )}
                      </p>
                    </td>
                    <td className="date-time">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.AGENCY,
                          "notification.txtReceivedAt"
                        )}
                      </p>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {notificationList.map((notification: any) => (
                    <tr key={notification.id}>
                      <td className="alarm" style={{ cursor: "default" }}>
                        <p
                          onClick={(e) => {
                            onClickCheckNotification(notification);
                          }}
                          className={notification.isRead ? "" : "not-read"}
                          style={{
                            cursor:
                              notification.action || notification.linkUrl
                                ? "cursor"
                                : "default",
                          }}>
                          {notification.title}
                        </p>
                      </td>
                      <td className="date-time" style={{ cursor: "default" }}>
                        <p style={{ cursor: "default" }}>
                          {getFormattedDateValue(
                            notification.sendAt,
                            "YYYY.MM.DD HH:mm"
                          )}
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* <!-- mobile --> */}
            <div className="mb">
              <table>
                <thead>
                  <tr>
                    <td className="alarm-detail">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.AGENCY,
                          "notification.txtContent"
                        )}
                      </p>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {notificationList.map((notification: any) => (
                    <tr
                      key={notification.id}
                      onClick={(e) => {
                        onClickCheckNotification(notification);
                      }}>
                      <td className="alarm-detail">
                        <div
                          className={`alarm ${
                            notification.isRead ? "" : "not-read"
                          }`}>
                          <p>{notification.title}</p>
                        </div>
                        <div className="date-time">
                          <p>
                            {getFormattedDateValue(
                              notification.sendAt,
                              "YYYY.MM.DD HH:mm"
                            )}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              url="/agency/notification"
              totalCount={totalCount}
              currentPage={page}
              pageSize={limit}
              pageGroupSize={10}
              setPage={setPage}
            />
          </div>
        </>
      )}
    </main>
  );
};

export default NotificationComponent;
