import * as BaseApi from "../Base.api";

export const createInfo = async (params: any) => {
  const url = "/audition/comment";
  return await BaseApi.post(url, params);
};

export const createLikeInfo = async (params: any) => {
  const url = "/audition/comment/like";
  return await BaseApi.post(url, params);
};

export const createUnLikeInfo = async (id: number) => {
  const url = `/audition/comment/like/${id}`;
  return await BaseApi.remove(url);
};

export const deleteInfo = async (id: number) => {
  const url = `/audition/comment/${id}`;
  return await BaseApi.remove(url);
};
/*
export const getStudentInfo = async (slug: string, params: any) => {
  const url = `/home/student/${slug}`;
  return await BaseApi.get(url, params);
};

export const getWannabeAuditionList = async (params: any) => {
  const url = `/home/wannabes-audition`;
  return await BaseApi.get(url, params);
};

export const getAgencyAuditionList = async (params: any) => {
  const url = `/home/agency-audition`;
  return await BaseApi.get(url, params);
};

export const getAuditionInfo = async (id: number, params: any) => {
  const url = `/home/audition/${id}`;
  return await BaseApi.get(url, params);
};

export const updateUnSubscribedEmailInfo = async (id: number, params: any) => {
  const url = `/home/has-unsubscribed-email/${id}`;
  return await BaseApi.patch(url, params);
};

*/
