import React, { useEffect, useState } from "react";
import FooterComponent from "./Footer.component";
import { getHomeTermList } from "api/common/Auth.api";
import { useSelector } from "react-redux";

const FooterContainer = (props: any) => {
  const [termList, setTermList] = useState([]);
  const { isLogin, info: userInfo } = useSelector((state: any) => state.user);

  const getTermListValue = async () => {
    const result = await getHomeTermList();

    if (result) {
      setTermList(result?.list);
    }
  };

  useEffect(() => {
    getTermListValue();
  }, [isLogin]);

  return <FooterComponent {...props} termList={termList} />;
};
export default FooterContainer;
