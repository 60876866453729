import React, { useEffect, useState } from "react";
import AuditionComponent from "./Audition.component";
import { useLocation, useNavigate } from "react-router-dom";

import * as AuditionApi from "api/student/Audition.api";
import { isValidDateValue } from "@testing-library/user-event/dist/utils";

const AuditionContainer = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [auditionList, setAuditionList] = useState([]);
  const [participatedList, setParticipatedList] = useState([]);
  const [isParticipantLoading, setIsParticipantLoading] = useState(true);
  const [isAuditionLoading, setIsAuditionLoading] = useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  const isLikedParam = urlParams.get("isLiked") || true;

  const [isLikedFilter, setIsLikedFilter] = useState(
    isLikedParam === "true" ? true : null
  );

  // pagination
  const searchParams = new URLSearchParams(location.search);
  const limit = 10;
  const [page, setPage] = useState(
    location.state?.page ||
      location.state?.prev ||
      +searchParams.get("page") ||
      1
  );
  const [totalCount, setTotalcount] = useState(0);

  const getAuditionList = async () => {
    const params = {
      page,
      limit,
      isFavorited: isLikedFilter === true ? true : null,
    };
    const result = await AuditionApi.getAuditionList(params);

    if (result?.list) {
      setAuditionList(result.list);
      setIsAuditionLoading(false);
    }
  };

  const getParticipatedList = async () => {
    setIsParticipantLoading(true);
    const params = { page, limit };
    const result = await AuditionApi.getAppliedList(params);

    if (result?.list) {
      setParticipatedList(result.list);
      setTotalcount(result.totalCount);
    }
    setIsParticipantLoading(false);
  };

  const go = (path: string, status: string) => {
    if (
      status === "in progress" ||
      status === "done" ||
      status === "evaluation completed"
    ) {
      navigate(path, {
        state: { page: 1, prev: page, status },
      });
    } else {
      navigate(path, {
        state: { page, status },
      });
    }
  };

  const changeFavoriteStatus = async (item: any, isFavorited: number) => {
    console.log(item);
    const params = { auditionId: item.id };

    const result = isFavorited
      ? await AuditionApi.createFavoriteInfo(params)
      : await AuditionApi.deleteFavoriteInfo(item.id, params);

    setAuditionList((prevList) => {
      const applicantIndex = prevList.findIndex(
        (applicant) => applicant.id === item.id
      );

      if (applicantIndex === -1) {
        return prevList; // If the applicant is not found, return the list as is
      }

      const updatedApplicant = {
        ...prevList[applicantIndex],
        isFavorited: isFavorited,
      };

      const updatedList = [
        ...prevList.slice(0, applicantIndex),
        updatedApplicant,
        ...prevList.slice(applicantIndex + 1),
      ];

      return updatedList;
    });
  };

  const toggleLike = async () => {
    if (isLikedFilter) {
      setIsLikedFilter(false);
    } else {
      setIsLikedFilter(true);
    }
  };

  const search = async () => {
    await getAuditionList();
    setPage(1);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", "1");

    if (isLikedFilter) {
      searchParams.set("isLiked", isLikedFilter.toString());
    } else {
      searchParams.delete("isLiked");
    }
  };

  useEffect(() => {
    getAuditionList();
    getParticipatedList();
  }, [page]);

  useEffect(() => {
    if (isLikedFilter !== null) {
      search();
    }
  }, [isLikedFilter]);

  return (
    <AuditionComponent
      {...props}
      go={go}
      isParticipantLoading={isParticipantLoading}
      isAuditionLoading={isAuditionLoading}
      auditionList={auditionList}
      participatedList={participatedList}
      changeFavoriteStatus={changeFavoriteStatus}
      // pagination
      limit={limit}
      page={page}
      setPage={setPage}
      totalCount={totalCount}
      isLikedFilter={isLikedFilter}
      toggleLike={toggleLike}
    />
  );
};

export default AuditionContainer;
