import React, { useState, useEffect } from "react";
import * as StudentApi from "api/student/Student.api";

import FavoriteComponent from "./Favorite.component";

const FavoriteContainer = (props: any) => {
    const [favoritedStudentList, setFavoritedStudentList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getFavoritedStudentList = async () => {
        const result = await StudentApi.getList({ isFavorite: true });

        if (result?.list.length > 0) {
            setFavoritedStudentList(result.list);
        }
        setIsLoading(false);
    };

    const deleteFavorite = async (id: number) => {
        setIsLoading(true);
        const result = await StudentApi.deleteFavorite({ studentId: id });

        if (result) {
            setFavoritedStudentList(favoritedStudentList.filter((i: any) => i.id !== id));
            getFavoritedStudentList();
        } else {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getFavoritedStudentList();
    }, []);

    return <FavoriteComponent favoritedStudentList={favoritedStudentList} deleteFavorite={deleteFavorite} isLoading={isLoading} />;
};

export default FavoriteContainer;
