import { PAGE_TYPE } from "enums";
import { getCurrentLangName } from "helpers/String.helper";
import { getLanguageValue } from "locales/i18n";
import * as commonData from "../../../data/common.data";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingComponent from "commons/loading/Loading.component";

const AuditionNewComponent = (props: any) => {
  const navigate = useNavigate();
  const state = useLocation().state;
  const {
    id,
    isLoading,
    btnRef,
    onClickBtnSave,
    auditionParams,
    onChangeInput,
    onChangeCheckbox,
    hasCheckedSpecialty,
  } = props;

  return (
    <main className="">
      <div className="con">
        {id && isLoading ? (
          <LoadingComponent />
        ) : (
          <div className="audition-new">
            <div
              className="back-btn"
              onClick={(evt) => navigate("/agency/audition", { state })}></div>
            <h2>{id ? "AUDITION INFO" : "NEW AUDITION"}</h2>
            <div className="form essential-information">
              <div className="input-box">
                <label>
                  {getLanguageValue(PAGE_TYPE.AGENCY, "auditionNew.txtTitle")}
                </label>
                <input
                  type="text"
                  placeholder={getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "auditionNew.txtTitlePlaceholder"
                  )}
                  onChange={(evt) => onChangeInput("title", evt.target.value)}
                  value={auditionParams.title}
                />
              </div>
              <div className="input-box">
                <label>
                  {getLanguageValue(PAGE_TYPE.AGENCY, "auditionNew.txtPeriod")}
                </label>
                <div className="wrap">
                  <input
                    type="date"
                    data-placeholder="YYYY-MM-DD"
                    aria-required="true"
                    onChange={(evt) =>
                      onChangeInput("startDate", evt.target.value)
                    }
                    value={auditionParams.startDate}
                  />
                  <span>&#126;</span>
                  <input
                    type="date"
                    data-placeholder="YYYY-MM-DD"
                    aria-required="true"
                    onChange={(evt) =>
                      onChangeInput("endDate", evt.target.value)
                    }
                    value={auditionParams.endDate}
                  />
                </div>
              </div>
              <div className="input-box">
                <label>{`${getLanguageValue(
                  PAGE_TYPE.AGENCY,
                  "auditionNew.txtCountry"
                )}`}</label>
                <select
                  onChange={(evt) =>
                    onChangeInput("countryCode", evt.target.value)
                  }
                  value={auditionParams.countryCode}>
                  <option value="" selected>
                    {getLanguageValue(
                      PAGE_TYPE.AGENCY,
                      "auditionNew.txtCountryOptional"
                    )}
                  </option>
                  {commonData
                    .getCountryCodeList()
                    .map((item: any, idx: number) => (
                      <option key={idx} value={item.code}>
                        {getCurrentLangName(item.names)}
                      </option>
                    ))}
                </select>
              </div>
              <div className="input-box">
                <label>
                  {getLanguageValue(PAGE_TYPE.AGENCY, "auditionNew.txtAge")}
                </label>
                <div className="wrap">
                  <select
                    onChange={(evt) =>
                      onChangeInput("minimumAge", evt.target.value)
                    }
                    value={auditionParams.minimumAge}>
                    <option value="" selected>
                      {getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "auditionNew.txtAgeOptional"
                      )}
                    </option>
                    {Array.from({ length: 100 }, (_, i) => i + 1).map((age) => (
                      <option key={age} value={age}>
                        {age}
                      </option>
                    ))}
                  </select>
                  <span>&#126;</span>
                  <select
                    onChange={(evt) =>
                      onChangeInput("maximumAge", evt.target.value)
                    }
                    value={auditionParams.maximumAge}>
                    <option value="" selected>
                      {getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "auditionNew.txtAgeOptional"
                      )}
                    </option>
                    {Array.from({ length: 100 }, (_, i) => i + 1).map((age) => (
                      <option key={age} value={age}>
                        {age}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="input-box input-radio">
                <label>
                  {getLanguageValue(PAGE_TYPE.AGENCY, "auditionNew.txtGender")}
                </label>
                <form>
                  <div className="radio-box">
                    <input
                      type="radio"
                      name="genderTypeCode"
                      onChange={(evt) =>
                        onChangeInput("genderTypeCode", evt.target.value)
                      }
                      value=""
                      checked={auditionParams.genderTypeCode === null}
                    />
                    <label>
                      {getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "auditionNew.txtGenderOptional"
                      )}
                    </label>
                  </div>
                  <div className="radio-box">
                    <input
                      type="radio"
                      name="genderTypeCode"
                      onChange={(evt) =>
                        onChangeInput("genderTypeCode", evt.target.value)
                      }
                      value="genderType:girl"
                      checked={
                        auditionParams.genderTypeCode === "genderType:girl"
                      }
                    />
                    <label>
                      {getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "auditionNew.txtGenderGirl"
                      )}
                    </label>
                  </div>
                  <div className="radio-box">
                    <input
                      type="radio"
                      name="genderTypeCode"
                      onChange={(evt) =>
                        onChangeInput("genderTypeCode", evt.target.value)
                      }
                      value="genderType:boy"
                      checked={
                        auditionParams.genderTypeCode === "genderType:boy"
                      }
                    />
                    <label>
                      {getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "auditionNew.txtGenderBoy"
                      )}
                    </label>
                  </div>
                </form>
              </div>
              <div className="input-box input-checkbox">
                <label>
                  {getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "auditionNew.txtSpecialty"
                  )}
                </label>
                <form>
                  <div className="check-box">
                    <input
                      type="checkbox"
                      id="dance"
                      onChange={(evt) =>
                        onChangeCheckbox("dance", null, evt.target.checked)
                      }
                      value="specialty:dance"
                      checked={
                        hasCheckedSpecialty &&
                        hasCheckedSpecialty.dance === true
                      }
                    />
                    <span>
                      {getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "auditionNew.txtDance"
                      )}
                    </span>
                  </div>
                  <div className="check-box">
                    <input
                      type="checkbox"
                      id="vocal"
                      onChange={(evt) =>
                        onChangeCheckbox("vocal", null, evt.target.checked)
                      }
                      value="specialty:vocal"
                      checked={
                        hasCheckedSpecialty &&
                        hasCheckedSpecialty.vocal === true
                      }
                    />
                    <span>
                      {getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "auditionNew.txtVocal"
                      )}
                    </span>
                  </div>
                  <div className="check-box">
                    <input
                      type="checkbox"
                      id="rap"
                      onChange={(evt) =>
                        onChangeCheckbox("rap", null, evt.target.checked)
                      }
                      value="specialty:rap"
                      checked={
                        hasCheckedSpecialty && hasCheckedSpecialty.rap === true
                      }
                    />
                    <span>
                      {getLanguageValue(PAGE_TYPE.AGENCY, "auditionNew.txtRap")}
                    </span>
                  </div>
                </form>
              </div>
              <div className="input-box input-checkbox">
                <label>
                  {getLanguageValue(PAGE_TYPE.AGENCY, "auditionNew.txtFile")}
                </label>
                <form>
                  <div className="check-box">
                    <input
                      type="checkbox"
                      onChange={(evt) =>
                        onChangeCheckbox("isFile", null, evt.target.checked)
                      }
                      value={auditionParams.isFile}
                      checked={auditionParams && auditionParams.isFile === true}
                    />
                    <span>
                      {getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "auditionNew.txtVideo"
                      )}
                    </span>
                  </div>
                </form>
              </div>
              <div className="input-box input-checkbox">
                <label>
                  {getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "auditionNew.txtAdditionalInfo"
                  )}
                </label>
                <form>
                  <div className="check-box">
                    <input
                      type="checkbox"
                      onChange={(evt) =>
                        onChangeCheckbox("isProfile", null, evt.target.checked)
                      }
                      value={auditionParams.isProfile}
                      checked={
                        auditionParams && auditionParams.isProfile === true
                      }
                    />
                    <span>
                      {getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "auditionNew.txtAdditionalImage"
                      )}
                    </span>
                  </div>
                </form>
              </div>
              <div className="input-box">
                <label>SLUG</label>
                <div className="input-prefix">
                  <div className="prefix">
                    <p>wannabes.app/audition/</p>
                  </div>
                  <input
                    type="text"
                    placeholder=""
                    onChange={(evt) => onChangeInput("slug", evt.target.value)}
                    value={auditionParams.slug}
                  />
                </div>
              </div>
              <div className="input-box">
                <label>
                  {getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "auditionNew.txtIntroduce"
                  )}
                </label>
                <textarea
                  placeholder={getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "auditionNew.txtIntroducePlaceholder"
                  )}
                  onChange={(evt) =>
                    onChangeInput("introduce", evt.target.value)
                  }
                  value={auditionParams.introduce}></textarea>
              </div>
            </div>

            <div className="btn">
              <div>
                <button
                  className="cancel-btn"
                  onClick={(evt) => navigate("/agency/audition", { state })}>
                  {getLanguageValue(PAGE_TYPE.AGENCY, "auditionNew.btnCancel")}
                </button>
              </div>
              <div>
                <button
                  ref={btnRef}
                  className="save-btn"
                  onClick={(evt) => onClickBtnSave()}>
                  {id
                    ? getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "auditionNew.btnUpdate"
                      )
                    : getLanguageValue(PAGE_TYPE.AGENCY, "auditionNew.btnSave")}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};
export default AuditionNewComponent;
