import React, { useEffect } from "react";
import VerifyPasswordEmailDoneComponent from "./VerifyPasswordEmailDone.component";
import { useLocation, useNavigate } from "react-router-dom";

const VerifyPasswordEmailDoneContainer = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};
  const { result = false } = state;

  useEffect(() => {
    if (!result) {
      navigate("/");
    }
  }, []);

  return <VerifyPasswordEmailDoneComponent {...props} />;
};

export default VerifyPasswordEmailDoneContainer;
