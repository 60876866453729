import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as commonData from "data/common.data";
import { getCurrentLangName } from "helpers/String.helper";

const StudentSignupComponent = (props: any) => {
    const {
        btnRef,
        accountParams,
        studentParams,
        parentEmail,
        setParentEmail,
        checkIsUnder14,
        termList,
        imageInfo,
        imageInfoList,
        isOnNetworking,
        uploadImage,
        onClickBtnDeleteAdditionalImage,
        onChangeInput,
        onChangeCheckbox,
        onClickBtnCancelSignup,
        onClickBtnSignup,
    } = props;

    return (
        <main className="signup-form student-signup">
            <div className="con">
                <h2>WANNABE SIGNUP</h2>
                {/* 계정 정보 */}
                <div className="form account">
                    <div className="title">
                        <h3>ACCOUNT</h3>
                        <p>{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtAccountInfoSubTitle")}</p>
                    </div>
                    <div className="input-box">
                        <label>*{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtEmail")}</label>
                        <input
                            type="text"
                            value={accountParams.username}
                            onChange={(evt) => onChangeInput("username", evt.target.value)}
                            // placeholder="email"
                        />
                    </div>
                    <div className="input-box">
                        <label>*{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtPassword")}</label>
                        <input
                            type="password"
                            value={accountParams.password}
                            onChange={(evt) => onChangeInput("password", evt.target.value)}
                            // placeholder="password"
                        />
                    </div>
                    <div className="input-box">
                        <label>*{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtConfirmPassword")}</label>
                        <input
                            type="password"
                            value={accountParams.confirmedPassword}
                            onChange={(evt) => onChangeInput("confirmedPassword", evt.target.value)}
                            // placeholder="confirm password"
                        />
                    </div>
                </div>
                {/* 필수 정보 */}
                <div className="form essential-information">
                    <div className="title">
                        <h3>ESSENTIAL INFORMATION</h3>
                        <p>{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtEssentialInfoSubTitle")}</p>
                    </div>
                    <div className="input-box">
                        <label>*{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtCountry")}</label>
                        <select value={studentParams.countryCode} onChange={(evt) => onChangeInput("countryCode", evt.target.value)}>
                            <option value="" disabled selected>
                                {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtCountryOptional")}
                            </option>
                            {commonData.getCountryCodeList().map((item: any, idx: number) => (
                                <option key={idx} value={item.code}>
                                    {getCurrentLangName(item.names)}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="input-box">
                        <label>*{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtFirstName")}</label>
                        <input type="text" value={studentParams.firstName} onChange={(evt) => onChangeInput("firstName", evt.target.value)} placeholder="" />
                        <label>*{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtLastName")}</label>
                        <input type="text" value={studentParams.lastName} onChange={(evt) => onChangeInput("lastName", evt.target.value)} placeholder="" />
                    </div>
                    <div className="input-box">
                        <label>*{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtBirthday")}</label>
                        <input
                            type="date"
                            value={studentParams.birthday}
                            onChange={(evt) => onChangeInput("birthday", evt.target.value)}
                            data-placeholder="YYYY-MM-DD"
                            aria-required="true"
                            style={{ minHeight: "34px" }}
                        />
                    </div>
                    {checkIsUnder14(studentParams.birthday) ? (
                        <div className="input-box">
                            <label>*{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtParentEmail")}</label>
                            <input type="text" value={parentEmail} onChange={(evt) => setParentEmail(evt.target.value.trim())} />
                        </div>
                    ) : null}
                    <div className="input-box input-radio">
                        <label>*{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtGender")}</label>
                        <form>
                            <div className="radio-box">
                                <input
                                    type="radio"
                                    id="girl"
                                    name="genderTypeCode"
                                    onChange={(evt) => onChangeInput("genderTypeCode", evt.target.value)}
                                    value="genderType:girl"
                                />
                                <label htmlFor="girl">{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtGirl")}</label>
                            </div>
                            <div className="radio-box">
                                <input
                                    type="radio"
                                    id="boy"
                                    name="genderTypeCode"
                                    onChange={(evt) => onChangeInput("genderTypeCode", evt.target.value)}
                                    value="genderType:boy"
                                />
                                <label htmlFor="boy">{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtBoy")}</label>
                            </div>
                        </form>
                    </div>
                </div>
                {/* 추가 정보 */}
                <div className="form">
                    <div className="title">
                        <h3>TELL ME ABOUT YOU</h3>
                        <p>{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtAdditionalInfoSubTitle")}</p>
                    </div>
                    <div className="input-box">
                        <label>{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtNickname")}</label>
                        <input type="text" value={studentParams.nickname} onChange={(evt) => onChangeInput("nickname", evt.target.value)} placeholder="" />
                    </div>
                    <div className="input-box">
                        <label>{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtHeight")}</label>
                        <input type="text" value={studentParams.height} onChange={(evt) => onChangeInput("height", evt.target.value)} placeholder="" />
                    </div>
                    <div className="input-box">
                        <label>{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtWeight")}</label>
                        <input type="text" value={studentParams.weight} onChange={(evt) => onChangeInput("weight", evt.target.value)} placeholder="" />
                    </div>
                    <div className="input-box">
                        <label>{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtShoeSize")}</label>
                        <input type="text" value={studentParams.shoeSize} onChange={(evt) => onChangeInput("shoeSize", evt.target.value)} placeholder="" />
                    </div>
                    <div className="input-box">
                        <label>MBTI</label>
                        <select value={studentParams.mbtiCode} onChange={(evt) => onChangeInput("mbtiCode", evt.target.value)}>
                            <option value="" disabled selected>
                                {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtMbtiOptional")}
                            </option>
                            <option value="">{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtNotSetOptional")}</option>
                            {commonData.getMbtiCodeList().map((item: any, idx: number) => (
                                <option key={idx} value={item.code}>
                                    {item.names.en}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="input-box input-checkbox">
                        <label>{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtSpecialty")}</label>
                        <form>
                            <div className="check-box">
                                <input
                                    type="checkbox"
                                    id="vocal"
                                    onChange={(evt) => onChangeCheckbox("vocal", null, evt.target.checked)}
                                    value="specialty:vocal"
                                />
                                <label htmlFor="vocal">{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtVocal")}</label>
                            </div>
                            <div className="check-box">
                                <input
                                    type="checkbox"
                                    id="dance"
                                    onChange={(evt) => onChangeCheckbox("dance", null, evt.target.checked)}
                                    value="specialty:dance"
                                />
                                <label htmlFor="dance">{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtDance")}</label>
                            </div>
                            <div className="check-box">
                                <input type="checkbox" id="rap" onChange={(evt) => onChangeCheckbox("rap", null, evt.target.checked)} value="specialty:rap" />
                                <label htmlFor="rap">{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtRap")}</label>
                            </div>
                        </form>
                    </div>
                    <div className="input-box input-radio">
                        <label>{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtBloodType")}</label>
                        <form>
                            <div className="radio-box">
                                <input type="radio" name="bloodType" id="skip" onChange={(evt) => onChangeInput("bloodTypeCode", null)} checked />
                                <label htmlFor="skip">SIKP</label>
                            </div>
                            <div className="radio-box">
                                <input
                                    type="radio"
                                    name="bloodType"
                                    id="a"
                                    onChange={(evt) => onChangeInput("bloodTypeCode", evt.target.value)}
                                    value="bloodType:a"
                                />
                                <label htmlFor="a">A</label>
                            </div>
                            <div className="radio-box">
                                <input
                                    type="radio"
                                    name="bloodType"
                                    id="b"
                                    onChange={(evt) => onChangeInput("bloodTypeCode", evt.target.value)}
                                    value="bloodType:b"
                                />
                                <label htmlFor="b">B</label>
                            </div>
                            <div className="radio-box">
                                <input
                                    type="radio"
                                    name="bloodType"
                                    id="o"
                                    onChange={(evt) => onChangeInput("bloodTypeCode", evt.target.value)}
                                    value="bloodType:o"
                                />
                                <label htmlFor="o">O</label>
                            </div>
                            <div className="radio-box">
                                <input
                                    type="radio"
                                    name="bloodType"
                                    id="ab"
                                    onChange={(evt) => onChangeInput("bloodTypeCode", evt.target.value)}
                                    value="bloodType:ab"
                                />
                                <label htmlFor="ab">AB</label>
                            </div>
                        </form>
                    </div>
                </div>
                {/* 프로필 사진 */}
                <div className="form">
                    <div className="title gap">
                        <h3>SHOW ME WHO YOU ARE</h3>
                        <p>
                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtProfileImageSubTitleLine1")}
                            <br />
                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtProfileImageSubTitleLine2")}
                        </p>
                    </div>
                    <div className="input-box">
                        <div className="img-upload">
                            <div className="img-wrap">
                                <div
                                    className={imageInfo?.url ? "casting-manager-img img-upload" : "img"}
                                    style={{
                                        backgroundImage: `url(${imageInfo?.url || "./../../imgs/icons/camera.png"})`,
                                    }}
                                />
                            </div>
                            <p>{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtImageUpload")}</p>
                            <input type="file" id="input-file-img-profile" onChange={(evt) => uploadImage("profile", evt)} accept="image/*" />
                            <label htmlFor="input-file-img-profile">{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.btnUploadFile")}</label>
                        </div>
                    </div>
                </div>
                {/* 추가 사진 */}
                <div className="form artists">
                    <div className="title">
                        <h3>SHOW ME YOURSELF MORE</h3>
                        <p>
                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtAdditionalImageSubTitleLine1")}
                            <br />
                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtAdditionalImageSubTitleLine2")}
                        </p>
                    </div>
                    <div className="input-box artist-img">
                        {imageInfoList.length > 0 &&
                            imageInfoList.map((item: any, idx: number) => (
                                <div key={`image-${idx}`} className="img-upload">
                                    <div className="add" style={{ backgroundImage: `url(${item.url})` }}>
                                        <div className="close-btn" onClick={(evt) => onClickBtnDeleteAdditionalImage(idx)}></div>
                                    </div>
                                </div>
                            ))}
                        <div className="img-upload">
                            <input type="file" id="input-file-img-addition" onChange={(evt) => uploadImage("addition", evt)} accept="image/*" />
                            <label htmlFor="input-file-img-addition">
                                <div className="img-add"></div>
                            </label>
                        </div>
                    </div>
                </div>
                {/* 슬러그 입력 */}
                <div className="form">
                    <div className="title gap">
                        <h3>ENTER YOUR FAVORITES SLUG</h3>
                        <p>{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtSlugSubTitle")}</p>
                    </div>
                    <div className="input-box">
                        <label>SLUG</label>
                        <div className="input-prefix">
                            <div className="prefix">
                                <p>{process.env.REACT_APP_DOMAIN}/wannabe/info/</p>
                            </div>
                            <input type="text" value={studentParams.slug} onChange={(evt) => onChangeInput("slug", evt.target.value)} placeholder="" />
                        </div>
                        <p className="slug-message">{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtSlugDesc")}</p>
                    </div>
                </div>
                {/* 긴급 연락처 */}
                <div className="form">
                    <div className="title">
                        <h3>TELL ME YOUR EMERGENCY CONTACT</h3>
                        <p>
                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtContactTitle1")}
                            <br />
                            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtContactTitle2")}
                        </p>
                    </div>
                    <div className="input-box">
                        <label>*{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtContactType")}</label>
                        <select value={studentParams.contactTypeCode} onChange={(evt) => onChangeInput("contactTypeCode", evt.target.value)}>
                            <option value="" disabled selected>
                                {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtOptional")}
                            </option>
                            {commonData.getCommonCodeListByParent("contactType").map((item: any, idx: number) => (
                                <option key={idx} value={item.code}>
                                    {getCurrentLangName(item.names)}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="input-box">
                        <label>*{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtContactDetail")}</label>
                        <input
                            type="text"
                            value={studentParams.contact}
                            onChange={(evt) => onChangeInput("contact", evt.target.value)}
                            placeholder={getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtContactDetailDesc")}
                        />
                    </div>
                </div>
                {/* 약관 목록 */}
                <div className="form">
                    <div className="input-box agreement-form">
                        <ul className="list">
                            {termList.length > 0 &&
                                termList.map((item: any, idx: number) => (
                                    <li key={`term-${idx}`} className="item">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                onChange={(evt) => {
                                                    onChangeCheckbox(null, idx, evt.target.checked);
                                                }}
                                                checked={item.checked}
                                            />
                                            <span className="txt">{`(${
                                                item.isRequired
                                                    ? getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.txtRequired")
                                                    : getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtOptional")
                                            }) ${item.title}`}</span>
                                        </label>
                                        {item.termsTypeCode !== "termsType:over14" ? (
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={(evt) => window.open(item.isRequired ? `/term/common/${item.id}` : `/term/etc/${item.id}`)}>
                                                {`(${getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtShow")})`}
                                            </button>
                                        ) : null}
                                    </li>
                                ))}
                        </ul>
                        <div className="all-check">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    className="select-all"
                                    onChange={(evt) => {
                                        onChangeCheckbox(null, "all", evt.target.checked);
                                    }}
                                    checked={!termList.find((i: any) => !i.checked)}
                                />
                                <span className="txt">{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtAgreeWithAllTerms")}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="btn">
                    <button className="cancel-btn" onClick={(evt) => onClickBtnCancelSignup()}>
                        {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.btnCancel")}
                    </button>
                    <button className="signup-btn" ref={btnRef} onClick={(evt) => onClickBtnSignup()} disabled={isOnNetworking}>
                        {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.btnSignup")}
                    </button>
                </div>
            </div>
        </main>
    );
};

export default StudentSignupComponent;
