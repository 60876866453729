import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import AgencySignupPre from "./AgencySignupPre.container";
import AgencySignup from "./AgencySignup.container";
import SchoolSignup from "./SchoolSignup.container";
import StudentSignupPre from "./StudentSignupPre.container";
import StudentSignup from "./StudentSignup.container";

import SignupComponent from "./Signup.component";

const SignupContainer = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [studentStep, setStudentStep] = useState(0);
  const [agencyStep, setAgencyStep] = useState(0);
  const [selectedInfo, setSelectedInfo] = useState<any>(null);
  const [selectedAgencyInfo, setSelectedAgencyInfo] = useState<any>(null);

  const code = location.pathname.split("/")[2];
  const [accountTypeCode, setAccountTypeCode] = useState(
    code ? `accountType:${code}` : ""
  );

  const cancelSignup = () => {
    setAccountTypeCode("");
    navigate("/signup");
  };

  useEffect(() => {
    if (location.pathname === "/signup") {
      setAccountTypeCode("");
    } else if (location.pathname.includes("/signup/")) {
      const accountTypeCode = `accountType:${location.pathname.split("/")[2]}`;
      setAccountTypeCode(accountTypeCode);
      setStudentStep(0);
      setAgencyStep(0);
    }
  }, [location]);

  return !accountTypeCode ? (
    <SignupComponent setAccountTypeCode={setAccountTypeCode} />
  ) : (
    <>
      {accountTypeCode === "accountType:agency" ? (
        agencyStep === 0 ? (
          <AgencySignupPre
            setAgencyStep={setAgencyStep}
            selectedAgencyInfo={selectedAgencyInfo}
            setSelectedAgencyInfo={setSelectedAgencyInfo}
          />
        ) : (
          <AgencySignup
            cancelSignup={cancelSignup}
            selectedAgencyInfo={selectedAgencyInfo}
            accountTypeCode={accountTypeCode}
          />
        )
      ) : accountTypeCode === "accountType:school" ? (
        <SchoolSignup
          cancelSignup={cancelSignup}
          accountTypeCode={accountTypeCode}
        />
      ) : accountTypeCode === "accountType:student" ? (
        studentStep === 0 ? (
          <StudentSignupPre
            setStudentStep={setStudentStep}
            selectedInfo={selectedInfo}
            setSelectedInfo={setSelectedInfo}
          />
        ) : (
          <StudentSignup
            cancelSignup={cancelSignup}
            selectedInfo={selectedInfo}
            accountTypeCode={accountTypeCode}
          />
        )
      ) : null}
    </>
  );
};

export default SignupContainer;
