import React from "react";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";
import { useLocation, useNavigate } from "react-router-dom";

const StudentCardComponent = (props: any) => {
  const { item, changeFavoriteFunc, disableFavoriteButton } = props;
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <li>
      <a
        onClick={(e: any) => {
          e.preventDefault();
          navigate(`/agency/wannabe/info/${item.id}`, {
            state: {
              from: {
                pathname: location.pathname,
              },
            },
          });
        }}>
        <div className="btn-con">
          <button
            className={`favorite-btn ${
              item?.isFavorited ? "register-favorites" : ""
            }`}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              changeFavoriteFunc(item?.id);
            }}
            disabled={disableFavoriteButton}></button>
          {item?.favoriteCnt ? <span>{item?.favoriteCnt}</span> : null}
        </div>
        <img
          src={
            item?.profileImageUrl
              ? item?.profileImageUrl + "?w=480&h=760"
              : "./imgs/dummy/basic-img.png"
          }
          alt={item.firstName}
        />
        <div className="student-info">
          {item?.isUpdated ? <div className="badge" /> : null}
          <p className="ellipsis">
            {getCurrentLangName(item?.countryCodeInfo?.names)}
          </p>
          <p className="favorite-student-name ellipsis">
            {item.firstName} {item.lastName}
          </p>
          <p className="ellipsis">
            {getCurrentLangName(item?.genderTypeCodeInfo?.names)}
          </p>
          <p className="ellipsis">
            {getFormattedDateValue(item?.birthday, "YYYY.MM.DD")}
          </p>
          <p className="ellipsis">{item?.schoolName}</p>
        </div>
      </a>
    </li>
  );
};

export default StudentCardComponent;
