export const SET_IS_APP = "COMMON/SET_IS_APP";

export const setIsApp = (isApp: boolean) => ({
    type: SET_IS_APP,
    isApp,
});

const initialState = {
    isApp: false,
};

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case SET_IS_APP:
            return {
                ...state,
                isApp: action.isApp,
            };
        default:
            return state;
    }
}
