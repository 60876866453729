import React from "react";
import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";

const ContactComponent = (props: any) => {
  const { titleRef, contentRef, emailRef, save } = props;

  return (
    <main>
      <div className="con">
        <h2>CONTACT</h2>
        <div className="wrap">
          <h3>Title</h3>
          <input
            type="text"
            placeholder={getLanguageValue(
              PAGE_TYPE.COMMON,
              "contact.titlePlaceholder"
            )}
            ref={titleRef}
          />
          <h3>Content</h3>
          <textarea
            placeholder={getLanguageValue(
              PAGE_TYPE.COMMON,
              "contact.contentPlaceholder"
            )}
            ref={contentRef}
          ></textarea>
          <h3>Email</h3>
          <input
            type="text"
            placeholder={getLanguageValue(
              PAGE_TYPE.COMMON,
              "contact.emailPlaceholder"
            )}
            ref={emailRef}
          />
          <button className="save-btn" onClick={save}>
            Save
          </button>
        </div>
      </div>
    </main>
  );
};
export default ContactComponent;
