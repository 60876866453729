import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import * as StudentApi from "api/student/Student.api";

import UpdateContactComponent from "./UpdateMyContact.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { updateStudentContactInfo } from "api/common/Auth.api";

const UpdateContactContainer = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { studentInfo, setStudentInfo, getInfo, setUpdateType } = props;

    const [isCheckedPassword, setIsCheckedPassword] = useState(false);

    const [imageInfo, setImageInfo] = useState<any>({});

    const [imageInfoList, setImageInfoList] = useState([]);
    const [imageIdList, setImageIdList] = useState([]);

    const [hasChecked, setHasChecked] = useState({
        dance: false,
        vocal: false,
        rap: false,
    });

    const [contactParams, setContactParams] = useState<any>({});

    const [isOnNetworking, setIsOnNetworking] = useState(false);

    const onChangeInput = (componentName: string, value: any) => {
        console.log("UpdateMyContainer:onChangeInput", componentName, value);

        if (componentName === "contactTypeCode") {
            setContactParams({ ...contactParams, contactTypeCode: value });
        } else if (componentName === "contact") {
            setContactParams({ ...contactParams, contact: value });
        }
    };

    const isDisabled = async () => {
        if (!contactParams.contactTypeCode) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errContactTypeCode"));
            return true;
        } else if (!contactParams.contact) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errContact"));
            return true;
        } else {
            return false;
        }
    };

    const updateContactInfo = async () => {
        setIsOnNetworking(true);
        if (!(await isDisabled())) {
            const result: any = await updateStudentContactInfo(contactParams);

            if (result) {
                setStudentInfo({});
                getInfo();
                cancelUpdate();
            }
        }

        setIsOnNetworking(false);
    };

    const cancelUpdate = () => {
        setUpdateType("");
        navigate("/student/my");
    };

    useEffect(() => {
        if (studentInfo?.id) {
            setContactParams({
                contactTypeCode: studentInfo.contactTypeCode,
                contact: studentInfo.contact,
            });
        }
    }, [studentInfo]);

    useEffect(() => {
        getInfo();
    }, []);

    return (
        <UpdateContactComponent
            id={studentInfo.id}
            isCheckedPassword={isCheckedPassword}
            setIsCheckedPassword={setIsCheckedPassword}
            contactParams={contactParams}
            updateContactInfo={updateContactInfo}
            onChangeInput={onChangeInput}
            cancelUpdate={cancelUpdate}
            isOnNetworking={isOnNetworking}
        />
    );
};

export default UpdateContactContainer;
