import React from "react";
import { Outlet, useLocation } from "react-router-dom";

import { getLayoutClassNameByPath } from "helpers/Common.helper";

import HeaderContainer from "../header/Header.container";
import FooterContainer from "../footer/Footer.container";

const LayoutComponent = (props: any) => {
  const location = useLocation();
  const { getContentWrapClassName } = props;

  return (
    <div className={`content-wrap content-wrap-${getContentWrapClassName()}`}>
      <div className={`content ${getLayoutClassNameByPath(location.pathname)}`}>
        <HeaderContainer {...props} />
        <Outlet />
        <FooterContainer {...props} />
      </div>
    </div>
  );
};

export default LayoutComponent;
