import React from "react";

import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import LoadingComponent from "commons/loading/Loading.component";

const UpdatePortfolioComponent = (props: any) => {
  const {
    isInfoLoading,
    isStudentListLoading,
    videoRef,
    videoWidth,
    params,
    studentList,
    getStudentList,
    taggedStudentList,
    keyword,
    setKeyword,
    getTitle,
    onChangeInput,
    onClickBtnUpdate,
    changeTaggedStudentList,
    onClickBtnClose,
  } = props;

  return (
    <main className="">
      <div className="new-portfolio">
        <div className="back-btn" onClick={(evt) => onClickBtnClose()}></div>
        <div className="con">
          <div className="wrap">
            <div className="title">
              <h2>EDIT PORTFOLIO</h2>
            </div>
            {!isInfoLoading ? (
              <>
                <div className="preview">
                  <h3>
                    {getLanguageValue(PAGE_TYPE.SCHOOL, "portfolio.txtPreview")}
                  </h3>
                  <div className="preview-wrap">
                    <div
                      ref={videoRef}
                      className="img"
                      style={{
                        borderRadius: "20px",
                        overflow: "hidden",
                        height: `${(videoWidth / 16) * 9}px`,
                      }}>
                      <iframe
                        width="100%"
                        height={`${(videoWidth / 16) * 9}px`}
                        src={`https://www.youtube.com/embed/${params.youtubeId}`}
                      />
                    </div>
                    <div className="info">
                      <div className="input-box">
                        <h3>
                          {getLanguageValue(
                            PAGE_TYPE.SCHOOL,
                            "portfolio.txtYoutubeId"
                          )}
                        </h3>
                        <input
                          type="text"
                          value={params.youtubeId}
                          onChange={(evt) =>
                            onChangeInput("youtubeId", evt.target.value)
                          }
                          onBlur={(evt) => getTitle()}
                          placeholder=""
                        />
                      </div>
                      <div className="input-box">
                        <h3>
                          {getLanguageValue(
                            PAGE_TYPE.SCHOOL,
                            "portfolio.txtVideoTitle"
                          )}
                        </h3>
                        <input
                          type="text"
                          value={params.title}
                          onChange={(evt) =>
                            onChangeInput("title", evt.target.value)
                          }
                          placeholder=""
                        />
                      </div>
                      <div className="input-box">
                        <h3>
                          {getLanguageValue(
                            PAGE_TYPE.SCHOOL,
                            "portfolio.txtPerformedDate"
                          )}
                        </h3>
                        <input
                          type="date"
                          value={params.performedDate}
                          onChange={(evt) =>
                            onChangeInput("performedDate", evt.target.value)
                          }
                          id="currentDate"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="student-tag">
                  <h3>
                    {getLanguageValue(
                      PAGE_TYPE.SCHOOL,
                      "portfolio.txtStudentTag"
                    )}
                  </h3>
                  {taggedStudentList.length <= 0 ? (
                    <p className="not-tagged">
                      {getLanguageValue(
                        PAGE_TYPE.SCHOOL,
                        "portfolio.txtNoTaggedStudent"
                      )}
                    </p>
                  ) : null}
                  <ul>
                    {taggedStudentList.length > 0 &&
                      taggedStudentList.map((item: any, idx: number) => (
                        <li onClick={(evt) => changeTaggedStudentList(item)}>
                          <div className="item" style={{ cursor: "pointer" }}>
                            <div
                              className="img"
                              style={{
                                backgroundImage: `url("${
                                  item.profileImageUrl
                                    ? item.profileImageUrl + "?w=360&h=360"
                                    : "./imgs/dummy/basic-img-2.png"
                                }")`,
                              }}></div>
                            <div className="name ellipsis">
                              <p>{`${item.firstName} ${item.lastName}`}</p>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="list">
                  <h3>
                    {getLanguageValue(
                      PAGE_TYPE.SCHOOL,
                      "portfolio.txtStudentList"
                    )}
                  </h3>
                  <div className="input-wrap">
                    <input
                      type="text"
                      value={keyword}
                      onChange={(evt) => setKeyword(evt.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          getStudentList();
                        }
                      }}
                      placeholder={getLanguageValue(
                        PAGE_TYPE.SCHOOL,
                        "portfolio.txtSearchingName"
                      )}
                    />
                    <div
                      className="btn-search-icon"
                      style={{ cursor: "pointer" }}
                      onClick={(evt) => getStudentList()}>
                      <img src="./imgs/icons/icon-search-gray.png" alt="검색" />
                    </div>
                  </div>
                  {!isStudentListLoading ? (
                    <ul>
                      {studentList.length > 0 &&
                        studentList.map((item: any) => (
                          <li onClick={(evt) => changeTaggedStudentList(item)}>
                            <div
                              className={`item${
                                taggedStudentList.find(
                                  (i: any) => i.id === item.id
                                )
                                  ? " tagged-student"
                                  : ""
                              }`}>
                              <div
                                className="img"
                                style={{
                                  backgroundImage: `url("${
                                    item.profileImageUrl
                                      ? item.profileImageUrl + "?w=360&h=360"
                                      : "./imgs/dummy/basic-img-2.png"
                                  }")`,
                                }}></div>
                              <div className="name ellipsis">
                                <p>{`${item.firstName} ${item.lastName}`}</p>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  ) : (
                    <LoadingComponent />
                  )}
                </div>
                <div className="btn">
                  <button
                    className="cancel-btn"
                    onClick={(evt) => onClickBtnClose()}>
                    {getLanguageValue(PAGE_TYPE.SCHOOL, "portfolio.btnCancel")}
                  </button>
                  <button
                    className="save-btn"
                    onClick={(evt) => onClickBtnUpdate()}>
                    {getLanguageValue(PAGE_TYPE.SCHOOL, "portfolio.btnUpdate")}
                  </button>
                </div>
              </>
            ) : (
              <LoadingComponent />
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default UpdatePortfolioComponent;
