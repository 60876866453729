import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import { getFormattedDateValue, addDate } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";

import LoadingComponent from "commons/loading/Loading.component";
import LineChartComponent from "commons/chart/LineChart.component";

const HomeComponent = (props: any) => {
    const { info, evaluationCategory, favoriteCntInfo, viewCntInfo, date, setDate, dataset, isLoading, isUnder14, parentEmail } = props;

    return (
        <>
            <main className="">
                {isLoading || favoriteCntInfo.isLoading || viewCntInfo.isLoading ? (
                    <LoadingComponent />
                ) : isUnder14 ? (
                    <div className="v2-sticky-panel v2-sticky-panel-warn">
                        <p>
                            {getLanguageValue(PAGE_TYPE.STUDENT, 'home.txtNeedParentConfirm1')} 😥
                            <br />
                            <strong>{parentEmail}</strong> {getLanguageValue(PAGE_TYPE.STUDENT, 'home.txtNeedParentConfirm2')}
                        </p>
                    </div>
                ) : (
                    <div className="wannabe-info" style={{ paddingBottom: "0" }}>
                        <div className="wrap">
                            {/* 평가 */}
                            {info.evaluationList.length > 0 ? (
                                <section className="evaluation" style={{ marginBottom: "0" }}>
                                    <div className="title">
                                        <h2>EVALUATION</h2>
                                    </div>
                                    <div className="summary" style={{ marginBottom: "0" }}>
                                        <dl>
                                            {evaluationCategory.map((item: any) => {
                                                const matchingEvaluation = info.evaluationList.find(
                                                    (evaluation: any) => evaluation.evaluationCategoryCode === item.code
                                                );
                                                return matchingEvaluation ? (
                                                    <>
                                                        <dt>{getCurrentLangName(item.names)}</dt>
                                                        <dd>{matchingEvaluation.evaluation}</dd>
                                                    </>
                                                ) : null;
                                            })}
                                        </dl>
                                    </div>
                                </section>
                            ) : null}

                            {/* 통계 */}
                            <div className="info-3">
                                <h2>STATISTICS</h2>
                                <div className="wrap">
                                    <div className="follower">
                                        <img src="./imgs/wannabe-info/follower.png" alt="follower" />
                                        <span>0</span>
                                    </div>
                                    <div className="favorite">
                                        <img src="./imgs/wannabe-info/favorite.png" alt="favorite" />
                                        <span>{info.favoriteCnt}</span>
                                    </div>
                                    <div className="view">
                                        <img src="./imgs/wannabe-info/view.png" alt="view" />
                                        <span>{info.viewCnt}</span>
                                    </div>
                                </div>
                            </div>

                            {/* 그래프 */}
                            <section className="evaluation" style={{ marginBottom: "0" }}>
                                <ul>
                                    <li>
                                        <div className="evaluation-graph">
                                            <div className="header">
                                                <div className="title">
                                                    <p>{getLanguageValue(PAGE_TYPE.STUDENT, "home.favoriteGraph")}</p>
                                                </div>
                                                <div className="control">
                                                    <div
                                                        className="btn-prev"
                                                        onClick={(evt) => {
                                                            if (!favoriteCntInfo.isDisabled) {
                                                                setDate({
                                                                    ...date,
                                                                    favorite: addDate(-7, date.favorite, "YYYY-MM-DD"),
                                                                });
                                                            }
                                                        }}>
                                                        ◀
                                                    </div>
                                                    <div className="text">
                                                        {`${addDate(-6, date.favorite, "M/D")} ~ ${getFormattedDateValue(date.favorite, "M/D")}`}
                                                    </div>
                                                    <div
                                                        className="btn-next"
                                                        onClick={(evt) => {
                                                            if (!favoriteCntInfo.isDisabled) {
                                                                setDate({
                                                                    ...date,
                                                                    favorite: addDate(7, date.favorite, "YYYY-MM-DD"),
                                                                });
                                                            }
                                                        }}>
                                                        ▶
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="body">
                                                <LineChartComponent
                                                    labels={favoriteCntInfo.labels}
                                                    datasets={[dataset(favoriteCntInfo.data)]}
                                                    min={0}
                                                    max={favoriteCntInfo.max}
                                                    stepSize={favoriteCntInfo.max / 4}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="evaluation-graph" style={{ marginBottom: "0" }}>
                                            <div className="header">
                                                <div className="title">
                                                    <p>{getLanguageValue(PAGE_TYPE.STUDENT, "home.viewGraph")}</p>
                                                </div>
                                                <div className="control">
                                                    <div
                                                        className="btn-prev"
                                                        onClick={(evt) => {
                                                            if (!viewCntInfo.isDisabled) {
                                                                setDate({
                                                                    ...date,
                                                                    view: addDate(-7, date.view, "YYYY-MM-DD"),
                                                                });
                                                            }
                                                        }}>
                                                        ◀
                                                    </div>
                                                    <div className="text">
                                                        {`${addDate(-6, date.view, "M/D")} ~ ${getFormattedDateValue(date.view, "M/D")}`}
                                                    </div>
                                                    <div
                                                        className="btn-next"
                                                        onClick={(evt) => {
                                                            if (!viewCntInfo.isDisabled) {
                                                                setDate({
                                                                    ...date,
                                                                    view: addDate(7, date.view, "YYYY-MM-DD"),
                                                                });
                                                            }
                                                        }}>
                                                        ▶
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="body">
                                                <LineChartComponent
                                                    labels={viewCntInfo.labels}
                                                    datasets={[dataset(viewCntInfo.data)]}
                                                    min={0}
                                                    max={viewCntInfo.max}
                                                    stepSize={viewCntInfo.max / 4}
                                                />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </section>
                        </div>
                    </div>
                )}
            </main>
        </>
    );
};

export default HomeComponent;
