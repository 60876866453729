import React from "react";
import { Link, useLocation } from "react-router-dom";

const FooterComponent = (props: any) => {
  const { termList } = props;
  const location = useLocation();
  return location.pathname.startsWith("/student/message/") ||
    location.pathname.startsWith("/school/message/") ||
    location.pathname.startsWith("/agency/message/") ? null : (
    <footer>
      <div className="con">
        <div className="policy-list">
          <p>THEETHRU inc. all rights reserved</p>
          <ul>
            {termList
              ? termList.map((item: any, index: number) => (
                  <li key={`term-${index}`}>
                    <Link className="th-link-2" to={`/term/common/${item?.id}`}>
                      {item?.title}
                    </Link>
                  </li>
                ))
              : null}
          </ul>
        </div>
      </div>
    </footer>
  );
};
export default FooterComponent;
