import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as NoticeApi from "api/bbs/Notice.api";
import NoticeDetailComponent from "./NoticeDetail.component";

const NoticeDetailContainer = (props: any) => {
    const location = useLocation();

    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [info, setInfo] = useState(null);

    const getInfo = async () => {
        const result = await NoticeApi.getInfo(Number(id));

        if (result?.info) {
            setInfo(result.info);
        }

        setIsLoading(false);
    };
    useEffect(() => {
        getInfo();
    }, [id]);

    return <NoticeDetailComponent isLoading={isLoading} info={info} />;
};

export default NoticeDetailContainer;
