import * as BaseApi from "../Base.api";

export const getAuditionList = async (params: any) => {
  const url = "/student/audition/matched";
  return await BaseApi.get(url, params);
};

export const getAuditionInfo = async (id: number, params: any) => {
  const url = `/student/audition/info/${id}`;
  return await BaseApi.get(url, params);
};

export const getAppliedList = async (params: any) => {
  const url = "/student/audition/applied";
  return await BaseApi.get(url, params);
};

export const getApplicantInfo = async (id: number, params: any) => {
  const url = `/student/audition/applicant/info/${id}`;
  return await BaseApi.get(url, params);
};

export const createApplicantInfo = async (params: any) => {
  const url = "/student/audition/applicant";
  return await BaseApi.post(url, params);
};

export const updateApplicantInfo = async (id: number, params: any) => {
  const url = `/student/audition/applicant/${id}`;
  return await BaseApi.patch(url, params);
};

export const deleteApplicantInfo = async (id: number, params: any) => {
  const url = `/student/audition/applicant/${id}`;
  return await BaseApi.remove(url, params);
};

export const createFavoriteInfo = async (params: any) => {
  const url = `/student/audition/favorite`;
  return await BaseApi.post(url, params);
};

export const deleteFavoriteInfo = async (id: number, params: any) => {
  const url = `/student/audition/favorite/${id}`;
  return await BaseApi.remove(url, params);
};
