import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { checkIsEmail, checkPassword, checkBusinessRegistrationNumber, checkPhoneNumber } from "helpers/Reg.helper";
import * as ImageHelper from "helpers/Image.helper";
import { removeAuthTokens, setAccessToken } from "helpers/Auth.helper";

import * as AuthApi from "api/common/Auth.api";
import * as DataApi from "api/common/Data.api";

import AgencySignupComponent from "./AgencySignup.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const AgencySignupContainer = (props: any) => {
    const { selectedAgencyInfo } = props;
    const btnRef = useRef<any>();
    const navigate = useNavigate();
    const { cancelSignup } = props;

    const [termList, setTermList] = useState([]);

    // upload image / file
    const [businessCardFrontImageInfo, setBusinessCardFrontImageInfo] = useState<any>();
    const [businessCardBackImageInfo, setBusinessCardBackImageInfo] = useState<any>();

    const [isOnNetworking, setIsOnNetworking] = useState(false);
    const [isEmailVarified, setIsEmailVarified] = useState(false);
    const [isSentEmail, setIsSentEmail] = useState(false);

    const [emailVericationCode, setEmailVericationCode] = useState("");

    const [accountParams, setAccountParams] = useState<any>({
        username: "",
        password: "",
        confirmedPassword: "",
        accountTypeCode: "accountType:agency",
    });

    const [agencyParams, setAgencyParams] = useState<any>({
        businessCardFrontImageId: null,
        businessCardBackImageId: null,
        name: "",
        cellphone: "",
        agencyId: "",
    });

    const getTermList = async () => {
        const result = await AuthApi.getTermListByType({
            accountType: "agency",
        });

        if (result?.list) {
            result.list.map((item: any) => (item.checked = false));
            setTermList(result.list);
        }
    };

    const onChangeInput = (componentName: string, value: any) => {
        console.log("AgencySignupContainer:onChangeInput", componentName, value);

        // 계정 정보
        if (componentName === "username") {
            setAccountParams({ ...accountParams, username: value.trim() });
        } else if (componentName === "password") {
            setAccountParams({ ...accountParams, password: value });
        }
        if (componentName === "confirmedPassword") {
            setAccountParams({ ...accountParams, confirmedPassword: value });
        }

        // 기본 정보
        if (componentName === "name") {
            setAgencyParams({ ...agencyParams, name: value });
        } else if (componentName === "cellphone") {
            setAgencyParams({ ...agencyParams, cellphone: value });
        }
    };

    const onChangeCheckbox = (checkedItem: any, checked: boolean) => {
        setTermList((prev: any) => {
            const termsList = [...prev];
            checkedItem === "all" ? termsList.map((item: any) => (item.checked = checked)) : (termsList[+checkedItem].checked = checked);

            return termsList;
        });
    };

    const uploadImage = async (type: string, evt: any) => {
        setIsOnNetworking(true);

        if (!evt) {
            return false;
        }

        const file = evt.target.files[0];
        const imageInfo = await ImageHelper.getImageInfo(file);
        if (!imageInfo) {
            alert("Please choose image file");
            return false;
        }

        const result = await DataApi.uploadImage(file);

        if (type === "front") {
            setBusinessCardFrontImageInfo({ id: result.id, url: imageInfo.url });
        } else if (type === "back") {
            setBusinessCardBackImageInfo({ id: result.id, url: imageInfo.url });
        }

        setIsOnNetworking(false);
    };

    const isDisabled = () => {
        if (!accountParams.username) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.errEmail"));
            return true;
        } else if (checkIsEmail(accountParams.username)) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errInvalidEmail"));
            return true;
        } else if (!accountParams.password) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.errPassword"));
            return true;
        } else if (!accountParams.confirmedPassword) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.errConfirmPassword"));
            return true;
        } else if (accountParams.password !== accountParams.confirmedPassword) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.errPasswordMismatch"));
            return true;
        } else if (!businessCardFrontImageInfo?.id) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.errUploadFrontCard"));
            return true;
        } else if (!businessCardBackImageInfo?.id) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.errUploadBackCard"));
            return true;
        } else if (!agencyParams.name) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.errName"));
            return true;
        } else if (!agencyParams.cellphone) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.errPhoneNumber"));
            return true;
        } else if (!termList[0].checked) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.errAgreeTerms"));
            return true;
        } else if (!termList[1].checked) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.errAgreePrivacyPolicy"));
            return true;
        } else if (!isEmailVarified) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.errEmailVerication"));
            return true;
        } else {
            return false;
        }
    };

    const createInfo = async () => {
        if (!isDisabled()) {
            btnRef.current.disabled = true;

            accountParams.username = accountParams.username + "@" + selectedAgencyInfo.emailDomain;
            accountParams.termsOfService = true;
            accountParams.personalPolicy = true;
            accountParams.receiveEmail = termList[2].checked;
            accountParams.receiveMessage = termList[3].checked;

            // 계정 생성
            const accountResult = await AuthApi.signupByEmail(accountParams);

            if (accountResult) {
                setAccessToken(accountResult?.data?.data?.jwt);
                agencyParams.businessCardFrontImageId = businessCardFrontImageInfo.id;
                agencyParams.businessCardBackImageId = businessCardBackImageInfo.id;
                agencyParams.agencyId = selectedAgencyInfo.id;

                // 에이전시 정보 생성
                const agencyResult = await AuthApi.createInfo("agency", agencyParams);

                if (agencyResult) {
                    btnRef.current.disabled = false;
                    removeAuthTokens();

                    //navigate("/login");
                    navigate("/signup/agency/done", {
                        state: {
                            accountResult,
                            agencyParams,
                            businessCardFrontImageInfo: businessCardFrontImageInfo,
                            businessCardBackImageInfo: businessCardBackImageInfo,
                        },
                    });
                }
            } else {
                btnRef.current.disabled = false;
            }
        }
    };

    const sendEmailForVerification = async () => {
        setIsOnNetworking(true);
        if (!accountParams.username) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.errEmail"));
            return;
        }

        const email = `${accountParams.username}@${selectedAgencyInfo.emailDomain}`;

        const result: any = await AuthApi.sendEmailForAgencyVeification({ email });

        console.log(result);

        if (result.status === 200 && result.data?.data?.result) {
            setIsSentEmail(true);
        }
        setIsOnNetworking(false);
    };

    const emailVerification = async () => {
        setIsOnNetworking(true);
        if (!accountParams.username) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.errEmail"));
            return;
        }

        if (!emailVericationCode) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.errEmailVericationCode"));
            return;
        }

        const email = `${accountParams.username}@${selectedAgencyInfo.emailDomain}`;

        const result: any = await AuthApi.verifyAgencyEmail({ email, verificationCode: emailVericationCode });

        if (result.status === 200) {
            if (result.data?.data?.result) {
                setIsEmailVarified(true);
                alert(getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtSuccessEmailVericationCode"));
            } else {
                alert(getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtFailEmailVericationCode"));
            }
        } else {
            alert(result.data.message);
        }
        setIsOnNetworking(false);
    };

    useEffect(() => {
        if (!selectedAgencyInfo) {
            navigate("/signup");
        }

        getTermList();
    }, []);

    return (
        termList.length > 0 && (
            <AgencySignupComponent
                btnRef={btnRef}
                // account
                accountParams={accountParams}
                // essential
                agencyParams={agencyParams}
                businessCardFrontImageInfo={businessCardFrontImageInfo}
                businessCardBackImageInfo={businessCardBackImageInfo}
                termList={termList}
                isOnNetworking={isOnNetworking}
                uploadImage={uploadImage}
                onChangeInput={onChangeInput}
                onChangeCheckbox={onChangeCheckbox}
                onClickBtnCancelSignup={cancelSignup}
                onClickBtnSignup={createInfo}
                selectedAgencyInfo={selectedAgencyInfo}
                emailVericationCode={emailVericationCode}
                setEmailVericationCode={setEmailVericationCode}
                isEmailVarified={isEmailVarified}
                setIsEmailVarified={setIsEmailVarified}
                isSentEmail={isSentEmail}
                sendEmailForVerification={sendEmailForVerification}
                emailVerification={emailVerification}
            />
        )
    );
};

export default AgencySignupContainer;
