import React, { useEffect, useRef, useState } from "react";
import StudentDirectRegisterComponent from "./StudentDirectRegister.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import * as ImageHelper from "../../../helpers/Image.helper";
import * as DataApi from "../../../api/common/Data.api";
import * as StudentApi from "../../../api/student/Student.api";
import {
  checkIsEmail,
  checkPassword,
  checkIsNumberThatDoNotBeginWithZero,
  checkSlug,
} from "helpers/Reg.helper";
import * as AuthApi from "api/common/Auth.api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//import * as StudentApi from "../../../api//Student.api";
//import * as DataApi from "../../../api/Data.api";

const StudentDirectRegisterContainer = (props: any) => {
  const { info: myInfo } = useSelector((state: any) => state.user);
  const btnRef = useRef<any>();
  const navigate = useNavigate();
  const { goToPrevStep, id, studentInfo } = props;
  const [termList, setTermList] = useState([]);

  const [isOnNetworking, setIsOnNetworking] = useState(false);
  const [imageInfo, setImageInfo] = useState({});
  const [imageInfoList, setImageInfoList] = useState([]);

  const [imageIdList, setImageIdList] = useState([]);

  const [accountParams, setAccountParams] = useState<any>({
    username: "",
    accountTypeCode: "accountType:student",
  });

  const [studentParams, setStudentParams] = useState({
    id: "",
    profileImageId: null,
    countryCode: "",
    firstName: "",
    lastName: "",
    birthday: "",
    genderTypeCode: "",
    slug: "",
    schoolId: null,
    nickname: "",
    height: "",
    weight: "",
    shoeSize: "",
    mbtiCode: "",
    specialtyCodeList: null,
    bloodTypeCode: "",
    imageIdList: [],
  });

  const [hasCheckedSpecialty, setHasCheckedSpecialty] = useState({
    dance: false,
    vocal: false,
    rap: false,
  });

  const getTermList = async () => {
    const result = await AuthApi.getTermListByType({ accountType: "student" });

    if (result.list) {
      result.list.map((item: any) => (item.checked = false));
      result.list.map(
        (item: any) =>
          (item.link = item.isRequired === 1 ? "/term/common/" : "/term/etc/")
      );

      if (studentInfo && studentInfo.termList) {
        const updatedTermList = result.list.map((term: any) => {
          const matchingTerm = studentInfo.termList.find(
            (item: any) => item.termsId === term.id
          );
          if (matchingTerm && matchingTerm.hasAgreed === 1) {
            return { ...term, checked: true };
          }
          return term;
        });
        setTermList(updatedTermList);
      } else {
        setTermList(result.list);
      }
    }
  };

  const onChangeInput = (componentName: string, value: any) => {
    console.log("StudentSignupContainer:onChangeInput", componentName, value);

    // 계정 정보
    if (componentName === "username") {
      setAccountParams({ ...accountParams, username: value.trim() });
    } else if (componentName === "password") {
      setAccountParams({ ...accountParams, password: value });
    }
    if (componentName === "confirmedPassword") {
      setAccountParams({ ...accountParams, confirmedPassword: value });
    }

    // 학생 정보
    if (componentName === "countryCode") {
      setStudentParams({ ...studentParams, countryCode: value });
    } else if (componentName === "firstName") {
      setStudentParams({ ...studentParams, firstName: value });
    } else if (componentName === "lastName") {
      setStudentParams({ ...studentParams, lastName: value });
    } else if (componentName === "birthday") {
      setStudentParams({ ...studentParams, birthday: value });
    } else if (componentName === "genderTypeCode") {
      setStudentParams({ ...studentParams, genderTypeCode: value });
    } else if (componentName === "nickname") {
      setStudentParams({ ...studentParams, nickname: value });
    } else if (componentName === "height") {
      const height = checkIsNumberThatDoNotBeginWithZero(value)
        ? value
        : studentParams.height;
      setStudentParams({ ...studentParams, height });
    } else if (componentName === "weight") {
      const weight = checkIsNumberThatDoNotBeginWithZero(value)
        ? value
        : studentParams.weight;
      setStudentParams({ ...studentParams, weight });
    } else if (componentName === "shoeSize") {
      const shoeSize = checkIsNumberThatDoNotBeginWithZero(value)
        ? value
        : studentParams.shoeSize;
      setStudentParams({ ...studentParams, shoeSize });
    } else if (componentName === "mbtiCode") {
      setStudentParams({ ...studentParams, mbtiCode: value });
    } else if (componentName === "specialtyCodeList") {
      setStudentParams({ ...studentParams, specialtyCodeList: value });
    } else if (componentName === "bloodTypeCode") {
      setStudentParams({ ...studentParams, bloodTypeCode: value });
    } else if (componentName === "slug") {
      setStudentParams({ ...studentParams, slug: value });
    }
  };

  const onChangeCheckbox = (
    componentName: string,
    checkedItem: any,
    checked: boolean
  ) => {
    // 특기 선택
    if (componentName === "vocal") {
      setHasCheckedSpecialty({ ...hasCheckedSpecialty, vocal: checked });
    } else if (componentName === "dance") {
      setHasCheckedSpecialty({ ...hasCheckedSpecialty, dance: checked });
    } else if (componentName === "rap") {
      setHasCheckedSpecialty({ ...hasCheckedSpecialty, rap: checked });
    } else {
      // 약관 동의
      setTermList((prev: any) => {
        const termList = [...prev];
        checkedItem === "all"
          ? termList.map((item: any) => (item.checked = checked))
          : (termList[+checkedItem].checked = checked);

        return termList;
      });
    }
  };

  const uploadImage = async (type: string, evt: any) => {
    setIsOnNetworking(true);

    if (!evt) {
      return false;
    }

    const file = evt.target.files[0];
    const imageInfo = await ImageHelper.getImageInfo(file);
    if (!imageInfo) {
      alert("Please choose image file");
      return false;
    }

    const result = await DataApi.uploadImage(file);

    if (type === "profile") {
      setStudentParams({ ...studentParams, profileImageId: result.id });
      setImageInfo(imageInfo);
    } else if (type === "addition") {
      imageInfoList.push(imageInfo);
      setImageInfoList(imageInfoList);
      imageIdList.push(result.id);
      setImageIdList(imageIdList);
    }

    setIsOnNetworking(false);
  };

  const deleteAdditionalImageInfo = (idx: number) => {
    const idList = imageIdList.filter((a: any, i: number) => i !== idx);
    const infoList = imageInfoList.filter((a: any, i: number) => i !== idx);

    setImageIdList(idList);
    setImageInfoList(infoList);
  };

  const checkIsUnder14 = (birthday: string) => {
    const today = new Date();
    const birthDate = new Date(birthday);

    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return 14 > age ? true : false;
  };

  const checkExistsSlug = async () => {
    const slug = studentParams.slug;
    const result = await AuthApi.checkExistsSlug({ slug });

    return result;
  };

  const isDisabled = async () => {
    if (!accountParams.username) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errEmail"));
      return true;
    } else if (!checkIsEmail(accountParams.username)) {
      alert(
        getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errInvalidEmail")
      );
      return true;
    } else if (!studentParams.countryCode) {
      alert(
        getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errCountrySelection")
      );
      return true;
    } else if (!studentParams.firstName) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errFirstName"));
      return true;
    } else if (!studentParams.lastName) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errLastName"));
      return true;
    } else if (!studentParams.birthday) {
      alert(
        getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errBirthdaySelection")
      );
      return true;
    } else if (checkIsUnder14(studentParams.birthday)) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errAgreeOver14"));
      return true;
    } else if (!studentParams.genderTypeCode) {
      alert(
        getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errGenderSelection")
      );
      return true;
    } else if (studentParams.slug && id ? true : await checkExistsSlug()) {
      if (!id) {
        alert(
          getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errExistsSlug")
        );
        return true;
      }
    } else if (
      studentParams.slug && id ? true : !checkSlug(studentParams.slug)
    ) {
      if (!id) {
        alert(
          getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errInvalidSlug")
        );
        return true;
      }
    } else if (!termList[0].checked) {
      if (!id) {
        alert(
          getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errAgreeOver14")
        );
        return true;
      }
    } else if (!termList[1].checked) {
      if (!id) {
        alert(
          getLanguageValue(
            PAGE_TYPE.COMMON,
            "studentSignup.errAgreeTermOfService"
          )
        );
        return true;
      }
    } else if (!termList[2].checked) {
      if (!id) {
        alert(
          getLanguageValue(
            PAGE_TYPE.COMMON,
            "studentSignup.errAgreePrivacyPolicy"
          )
        );
        return true;
      }
    } else {
      return false;
    }
  };

  const setSpecialtyCodeList = () => {
    const specialtyCodeList = [];
    if (hasCheckedSpecialty.vocal) {
      specialtyCodeList.push("specialty:vocal");
    }
    if (hasCheckedSpecialty.dance) {
      specialtyCodeList.push("specialty:dance");
    }

    if (hasCheckedSpecialty.rap) {
      specialtyCodeList.push("specialty:rap");
    }

    studentParams.specialtyCodeList = JSON.stringify(specialtyCodeList);
  };

  const createInfo = async () => {
    if (!(await isDisabled())) {
      btnRef.current.disabled = true;

      accountParams.agreeOver14 = true;
      accountParams.termsOfService = true;
      accountParams.personalPolicy = true;
      accountParams.receiveEmail = true;
      accountParams.receiveMessage = true;
      accountParams.schoolId = myInfo?.userSchoolId;
      // 계정 생성
      const accountResult = await AuthApi.schoolSignupByEmail(accountParams);
      if (accountResult) {
        const id = accountResult?.data?.data?.id;

        setSpecialtyCodeList();
        studentParams.id = id;
        studentParams.schoolId = myInfo?.userSchoolId;
        studentParams.imageIdList = imageIdList;

        // 학생 정보 생성
        const studentResult = await AuthApi.createStudentInfoBySchool(
          "student",
          studentParams
        );
        if (studentResult) {
          btnRef.current.disabled = false;

          //goToPrevStep();
          navigate(
            `/school/student/${studentResult?.data?.data?.userStudentId}`
          );
        }
      } else {
        btnRef.current.disabled = false;
      }
    }
  };

  const updateInfo = async () => {
    if (!(await isDisabled())) {
      btnRef.current.disabled = true;

      accountParams.id = id;
      accountParams.agreeOver14 = true;
      accountParams.termsOfService = true;
      accountParams.personalPolicy = true;
      accountParams.receiveEmail = true;
      accountParams.receiveMessage = true;

      // 계정 생성
      //const accountResult = await StudentApi.updateTermsInfo(id, accountParams);
      //if (accountResult) {
      setSpecialtyCodeList();
      studentParams.id = id;
      studentParams.schoolId = myInfo?.userSchoolId;
      studentParams.imageIdList = imageIdList;
      const result = await StudentApi.updateAdditionalInfo(id, studentParams);
      if (result) {
        navigate(`/school/student/${id}`);
      }
      //}
      btnRef.current.disabled = false;
    }
  };

  const cancelSignup = () => {
    goToPrevStep();
  };

  const setStudentInfo = async () => {
    console.log(studentInfo);
    setAccountParams({ ...accountParams, username: studentInfo.username });
    setStudentParams({
      ...studentParams,
      countryCode: studentInfo.countryCodeInfo
        ? studentInfo.countryCodeInfo.code
        : null,
      firstName: studentInfo.firstName,
      lastName: studentInfo.lastName,
      birthday: studentInfo.birthday,
      genderTypeCode: studentInfo.genderTypeCodeInfo
        ? studentInfo.genderTypeCodeInfo.code
        : null,
      bloodTypeCode: studentInfo.bloodTypeCodeInfo
        ? studentInfo.bloodTypeCodeInfo.code
        : null,
      nickname: studentInfo.nickname,
      height: studentInfo.height,
      weight: studentInfo.weight,
      shoeSize: studentInfo.shoeSize,
      mbtiCode: studentInfo.mbtiCode,
      slug: studentInfo.slug,
      profileImageId: studentInfo.profileImageInfo
        ? studentInfo.profileImageInfo.id
        : null,
    });

    let defaultHasCheckedSpecialty = {
      vocal: false,
      dance: false,
      rap: false,
    };

    let updatedHasCheckedSpecialty = { ...defaultHasCheckedSpecialty };
    if (studentInfo.specialtyCodeInfoList) {
      studentInfo.specialtyCodeInfoList.forEach((specialty: any) => {
        const code = specialty.code.split(":")[1];
        if (code === "vocal") {
          updatedHasCheckedSpecialty.vocal = true;
        } else if (code === "dance") {
          updatedHasCheckedSpecialty.dance = true;
        } else if (code === "rap") {
          updatedHasCheckedSpecialty.rap = true;
        }
      });
    }
    setHasCheckedSpecialty(updatedHasCheckedSpecialty);
    if (studentInfo.profileImageInfo) {
      const profile = await ImageHelper.getImageInfoByUrl(
        studentInfo.profileImageInfo.url.replace(/ /g, "%20")
      );
      setImageInfo(profile);
    }

    const updatedImageInfoList = [];
    const updatedImageIdList = [];

    if (studentInfo.imageInfoList) {
      for (const image of studentInfo.imageInfoList) {
        if (image) {
          const additional = await ImageHelper.getImageInfoByUrl(
            image.url.replace(/ /g, "%20")
          );
          updatedImageInfoList.push(additional);
          updatedImageIdList.push(image.id);
        }
      }

      setImageInfoList(updatedImageInfoList);
      setImageIdList(updatedImageIdList);
    }
  };

  const init = async () => {
    if (id) {
      await setStudentInfo();
    }
    await getTermList();
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <StudentDirectRegisterComponent
      {...props}
      id={id}
      btnRef={btnRef}
      accountParams={accountParams}
      studentParams={studentParams}
      imageInfo={imageInfo}
      imageInfoList={imageInfoList}
      termList={termList}
      hasCheckedSpecialty={hasCheckedSpecialty}
      isOnNetworking={isOnNetworking}
      uploadImage={uploadImage}
      onClickBtnDeleteAdditionalImage={deleteAdditionalImageInfo}
      onChangeInput={onChangeInput}
      onChangeCheckbox={onChangeCheckbox}
      onClickBtnUpdate={updateInfo}
      onClickBtnCancelSignup={cancelSignup}
      onClickBtnSignup={createInfo}
    />
  );
};

export default StudentDirectRegisterContainer;
