import React, { useEffect } from "react";
import LoadingComponent from "commons/loading/Loading.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import ProfileIntroContainer from "./ProfileIntro.container";
import ProfileImageContainer from "./ProfileImage.container";
import PracticeDetailContainer from "./PracticeDetail.container";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import Pagination from "../../../commons/pagination/Pagination.container";
import { useNavigate } from "react-router-dom";

const ProfileComponent = (props: any) => {
    const navigate = useNavigate();

    const {
        tab,
        setTab,
        profileSubTab,
        setProfileSubTab,
        profile,
        isProfileLoading,
        toggleActiveProfile,
        inputRef,
        practiceList,
        isPracticeLoading,
        //
        uploadVideo,
        videoRef,
        selectedVideoUrl,
        uploadImage,
        //
        isShowPopup,
        setIsShowPopup,
        popupList,
        openViewPopup,
        profileMediaType,
        isAgencyPopup,
        setIsAgencyPopup,
        agencyInfo,
        openAgencyPopup,
        //
        limit,
        page,
        setPage,
        totalCount,
    } = props;

    return (
        <main className="">
            <div
                className={`${
                    tab === "profile" && profileSubTab === "main"
                        ? "profile-for-agency"
                        : tab === "practice" && profileSubTab === "main"
                        ? "profile-training-video"
                        : "profile-for-agency"
                } `}>
                <div className={`${tab === "practice" && profileSubTab === "main" ? "wrap" : "con"}`}>
                    <div className="title">
                        {/* <!-- 선택한 title에 class active 추가 --> */}
                        <button
                            className=""
                            onClick={(e) => {
                                navigate("/student/profile", {
                                    state: { tab: "practice", profileSubTab: "main" },
                                });
                            }}>
                            {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtTitle")}
                        </button>
                        <button
                            className="active"
                            onClick={(e) => {
                                navigate("/student/agency-profile", {
                                    state: { tab: "profile", profileSubTab: "main" },
                                });
                            }}>
                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtTitle")}
                        </button>
                    </div>
                    {tab === "profile" ? (
                        isProfileLoading ? (
                            <LoadingComponent />
                        ) : profileSubTab === "main" ? (
                            <>
                                <div className="main-title">
                                    <h2>{getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtTitle")}</h2>
                                    <p>{getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtAgencyAndCastingManagerOnly")}</p>
                                </div>
                                <div className="wrap video-wrap">
                                    <div className="title">
                                        <h3>Introduction Video</h3>
                                        <p>{getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtUploadIntroductionVideo")}</p>
                                    </div>
                                    <div className="preview">
                                        <div className="preview-wrap">
                                            <div className="img">
                                                <div className="btn">
                                                    <div className="view-count">
                                                        <button
                                                            onClick={(e) => {
                                                                openViewPopup(profile?.introVideoInfo?.id, "introVideo");
                                                            }}>
                                                            <img src="./imgs/icons/view-icon.png" alt="view count icon" />
                                                            {profile?.introVideoInfo?.viewCnt || 0}
                                                        </button>
                                                    </div>
                                                    <div className="all-view">
                                                        <button
                                                            onClick={(e) => {
                                                                navigate("/student/agency-profile/intro");
                                                            }}>
                                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnViewAll")} {">"}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="play-img">
                                                    {/* <div className="view-count">
                                                        {profile?.introVideoInfo ? (
                                                            <button
                                                                onClick={(e) => {
                                                                    openViewPopup(profile?.introVideoInfo?.id, "introVideo");
                                                                }}>
                                                                <img src="./imgs/icons/view-icon.png" alt="view count icon" />
                                                                {profile?.introVideoInfo?.viewCnt || 0}
                                                            </button>
                                                        ) : null}
                                                    </div> */}
                                                    <video controls ref={videoRef} muted playsInline controlsList="nodownload">
                                                        <source src={selectedVideoUrl} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>
                                            </div>
                                            <input
                                                type="file"
                                                id="input-file-video"
                                                onChange={(evt) => uploadVideo(evt)}
                                                accept="video/*"
                                                style={{ display: "none" }}
                                            />
                                            <button
                                                onClick={() => {
                                                    document.getElementById("input-file-video").click();
                                                }}>
                                                {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnRegister")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="wrap face-photo">
                                    <div className="title">
                                        <h3>Face Photo</h3>
                                        <p>
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtUploadMinimalMakeupPhoto")}
                                            <br />
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtShowMostAttractiveExpression")}
                                        </p>
                                    </div>
                                    <ul>
                                        <li>
                                            <div className="btn">
                                                <div className="all-view">
                                                    <button
                                                        onClick={() => {
                                                            navigate("/student/agency-profile/face/front");
                                                        }}>
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnViewAll")} {">"}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="img">
                                                <div className="view-count">
                                                    {profile?.faceImageInfo?.front ? (
                                                        <button
                                                            onClick={(e) => {
                                                                openViewPopup(profile?.faceImageInfo?.front?.id, "profileImage");
                                                            }}>
                                                            <img src="./imgs/icons/view-icon.png" alt="view count icon" />
                                                            {profile?.faceImageInfo?.front?.viewCnt || 0}
                                                        </button>
                                                    ) : null}
                                                </div>
                                                {/* <!-- 이미지가 없을경우 해당이미지 사용 --> */}
                                                <img
                                                    src={`${
                                                        profile?.faceImageInfo?.front && profile.faceImageInfo.front.url
                                                            ? profile.faceImageInfo.front.url.replace(/ /g, "%20")
                                                            : "./imgs/dummy/basic-img-3.png"
                                                    }`}
                                                    alt="photo"
                                                />
                                            </div>
                                            <div className="type">
                                                <p>{getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtFront")}</p>
                                                <input
                                                    type="file"
                                                    id="input-file-image-face-front"
                                                    onChange={(evt) => uploadImage(evt, "profileImageCategory:face", "profileImageType:front")}
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                />
                                                <button
                                                    onClick={() => {
                                                        document.getElementById("input-file-image-face-front").click();
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnRegister")}
                                                </button>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="btn">
                                                <div className="all-view">
                                                    <button
                                                        onClick={() => {
                                                            navigate("/student/agency-profile/face/back");
                                                        }}>
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnViewAll")} {">"}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="img">
                                                <div className="view-count">
                                                    {profile?.faceImageInfo?.back ? (
                                                        <button
                                                            onClick={(e) => {
                                                                openViewPopup(profile?.faceImageInfo?.back?.id, "profileImage");
                                                            }}>
                                                            <img src="./imgs/icons/view-icon.png" alt="view count icon" />
                                                            {profile?.faceImageInfo?.back?.viewCnt || 0}
                                                        </button>
                                                    ) : null}
                                                </div>
                                                <img
                                                    src={`${
                                                        profile?.faceImageInfo?.back && profile.faceImageInfo.back.url
                                                            ? profile.faceImageInfo.back.url.replace(/ /g, "%20")
                                                            : "./imgs/dummy/basic-img-3.png"
                                                    }`}
                                                    alt="photo"
                                                />
                                            </div>
                                            <div className="type">
                                                <p>{getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtBack")}</p>
                                                <input
                                                    type="file"
                                                    id="input-file-image-face-back"
                                                    onChange={(evt) => uploadImage(evt, "profileImageCategory:face", "profileImageType:back")}
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                />
                                                <button
                                                    onClick={() => {
                                                        document.getElementById("input-file-image-face-back").click();
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnRegister")}
                                                </button>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="btn">
                                                <div className="all-view">
                                                    <button
                                                        onClick={() => {
                                                            navigate("/student/agency-profile/face/right");
                                                        }}>
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnViewAll")} {">"}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="img">
                                                <div className="view-count">
                                                    {profile?.faceImageInfo?.right ? (
                                                        <button
                                                            onClick={(e) => {
                                                                openViewPopup(profile?.faceImageInfo?.right?.id, "profileImage");
                                                            }}>
                                                            <img src="./imgs/icons/view-icon.png" alt="view count icon" />
                                                            {profile?.faceImageInfo?.right?.viewCnt || 0}
                                                        </button>
                                                    ) : null}
                                                </div>
                                                <img
                                                    src={`${
                                                        profile?.faceImageInfo?.right && profile.faceImageInfo.right.url
                                                            ? profile.faceImageInfo.right.url.replace(/ /g, "%20")
                                                            : "./imgs/dummy/basic-img-3.png"
                                                    }`}
                                                    alt="photo"
                                                />
                                            </div>
                                            <div className="type">
                                                <p>{getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtRight")}</p>
                                                <input
                                                    type="file"
                                                    id="input-file-image-face-right"
                                                    onChange={(evt) => uploadImage(evt, "profileImageCategory:face", "profileImageType:right")}
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                />
                                                <button
                                                    onClick={() => {
                                                        document.getElementById("input-file-image-face-right").click();
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnRegister")}
                                                </button>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="btn">
                                                <div className="all-view">
                                                    <button
                                                        onClick={() => {
                                                            navigate("/student/agency-profile/face/left");
                                                        }}>
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnViewAll")} {">"}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="img">
                                                <div className="view-count">
                                                    {profile?.faceImageInfo?.left ? (
                                                        <button
                                                            onClick={(e) => {
                                                                openViewPopup(profile?.faceImageInfo?.left?.id, "profileImage");
                                                            }}>
                                                            <img src="./imgs/icons/view-icon.png" alt="view count icon" />
                                                            {profile?.faceImageInfo?.left?.viewCnt || 0}
                                                        </button>
                                                    ) : null}
                                                </div>
                                                <img
                                                    src={`${
                                                        profile?.faceImageInfo?.left && profile.faceImageInfo.left.url
                                                            ? profile.faceImageInfo.left.url.replace(/ /g, "%20")
                                                            : "./imgs/dummy/basic-img-3.png"
                                                    }`}
                                                    alt="photo"
                                                />
                                            </div>
                                            <div className="type">
                                                <p>{getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtLeft")}</p>
                                                <input
                                                    type="file"
                                                    id="input-file-image-face-left"
                                                    onChange={(evt) => uploadImage(evt, "profileImageCategory:face", "profileImageType:left")}
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                />
                                                <button
                                                    onClick={() => {
                                                        document.getElementById("input-file-image-face-left").click();
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnRegister")}
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="wrap full-body-photo">
                                    <div className="title">
                                        <h3>Full-Body Photo</h3>
                                        <p>{getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtUploadNoMakeupPhoto")}</p>
                                    </div>
                                    <ul>
                                        <li>
                                            <div className="btn">
                                                <div className="all-view">
                                                    <button
                                                        onClick={() => {
                                                            navigate("/student/agency-profile/fullBody/front");
                                                        }}>
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnViewAll")} {">"}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="img">
                                                <div className="view-count">
                                                    {profile?.fullBodyImageInfo?.front ? (
                                                        <button
                                                            onClick={(e) => {
                                                                openViewPopup(profile?.fullBodyImageInfo?.front?.id, "profileImage");
                                                            }}>
                                                            <img src="./imgs/icons/view-icon.png" alt="view count icon" />
                                                            {profile?.fullBodyImageInfo?.front?.viewCnt || 0}
                                                        </button>
                                                    ) : null}
                                                </div>
                                                {/* <!-- 이미지가 없을경우 해당이미지 사용 --> */}
                                                <img
                                                    src={`${
                                                        profile?.fullBodyImageInfo?.front && profile.fullBodyImageInfo.front.url
                                                            ? profile.fullBodyImageInfo.front.url.replace(/ /g, "%20")
                                                            : "./imgs/dummy/basic-img.png"
                                                    }`}
                                                    alt="photo"
                                                />
                                            </div>
                                            <div className="type">
                                                <p>{getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtFront")}</p>
                                                <input
                                                    type="file"
                                                    id="input-file-image-fullBody-front"
                                                    onChange={(evt) => uploadImage(evt, "profileImageCategory:fullBody", "profileImageType:front")}
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                />
                                                <button
                                                    onClick={() => {
                                                        document.getElementById("input-file-image-fullBody-front").click();
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnRegister")}
                                                </button>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="btn">
                                                <div className="all-view">
                                                    <button
                                                        onClick={() => {
                                                            navigate("/student/agency-profile/fullBody/back");
                                                        }}>
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnViewAll")} {">"}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="img">
                                                <div className="view-count">
                                                    {profile?.fullBodyImageInfo?.back ? (
                                                        <button
                                                            onClick={(e) => {
                                                                openViewPopup(profile?.fullBodyImageInfo?.back?.id, "profileImage");
                                                            }}>
                                                            <img src="./imgs/icons/view-icon.png" alt="view count icon" />
                                                            {profile?.fullBodyImageInfo?.back?.viewCnt || 0}
                                                        </button>
                                                    ) : null}
                                                </div>
                                                <img
                                                    src={`${
                                                        profile?.fullBodyImageInfo?.back && profile.fullBodyImageInfo.back.url
                                                            ? profile.fullBodyImageInfo.back.url.replace(/ /g, "%20")
                                                            : "./imgs/dummy/basic-img.png"
                                                    }`}
                                                    alt="photo"
                                                />
                                            </div>
                                            <div className="type">
                                                <p>{getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtBack")}</p>
                                                <input
                                                    type="file"
                                                    id="input-file-image-fullBody-back"
                                                    onChange={(evt) => uploadImage(evt, "profileImageCategory:fullBody", "profileImageType:back")}
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                />
                                                <button
                                                    onClick={() => {
                                                        document.getElementById("input-file-image-fullBody-back").click();
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnRegister")}
                                                </button>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="btn">
                                                <div className="all-view">
                                                    <button
                                                        onClick={() => {
                                                            navigate("/student/agency-profile/fullBody/right");
                                                        }}>
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnViewAll")} {">"}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="img">
                                                <div className="view-count">
                                                    {profile?.fullBodyImageInfo?.right ? (
                                                        <button
                                                            onClick={(e) => {
                                                                openViewPopup(profile?.fullBodyImageInfo?.right?.id, "profileImage");
                                                            }}>
                                                            <img src="./imgs/icons/view-icon.png" alt="view count icon" />
                                                            {profile?.fullBodyImageInfo?.right?.viewCnt || 0}
                                                        </button>
                                                    ) : null}
                                                </div>
                                                <img
                                                    src={`${
                                                        profile?.fullBodyImageInfo?.right && profile.fullBodyImageInfo.right.url
                                                            ? profile.fullBodyImageInfo.right.url.replace(/ /g, "%20")
                                                            : "./imgs/dummy/basic-img.png"
                                                    }`}
                                                    alt="photo"
                                                />
                                            </div>
                                            <div className="type">
                                                <p>{getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtRight")}</p>
                                                <input
                                                    type="file"
                                                    id="input-file-image-fullBody-right"
                                                    onChange={(evt) => uploadImage(evt, "profileImageCategory:fullBody", "profileImageType:right")}
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                />
                                                <button
                                                    onClick={() => {
                                                        document.getElementById("input-file-image-fullBody-right").click();
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnRegister")}
                                                </button>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="btn">
                                                <div className="all-view">
                                                    <button
                                                        onClick={() => {
                                                            navigate("/student/agency-profile/fullBody/left");
                                                        }}>
                                                        {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnViewAll")} {">"}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="img">
                                                <div className="view-count">
                                                    {profile?.fullBodyImageInfo?.left ? (
                                                        <button
                                                            onClick={(e) => {
                                                                openViewPopup(profile?.fullBodyImageInfo?.left?.id, "profileImage");
                                                            }}>
                                                            <img src="./imgs/icons/view-icon.png" alt="view count icon" />
                                                            {profile?.fullBodyImageInfo?.left?.viewCnt || 0}
                                                        </button>
                                                    ) : null}
                                                </div>
                                                <img
                                                    src={`${
                                                        profile?.fullBodyImageInfo?.left && profile.fullBodyImageInfo.left.url
                                                            ? profile.fullBodyImageInfo.left.url.replace(/ /g, "%20")
                                                            : "./imgs/dummy/basic-img.png"
                                                    }`}
                                                    alt="photo"
                                                />
                                            </div>
                                            <div className="type">
                                                <p>{getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtLeft")}</p>
                                                <input
                                                    type="file"
                                                    id="input-file-image-fullBody-left"
                                                    onChange={(evt) => uploadImage(evt, "profileImageCategory:fullBody", "profileImageType:left")}
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                />
                                                <button
                                                    onClick={() => {
                                                        document.getElementById("input-file-image-fullBody-left").click();
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnRegister")}
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="wrap activate">
                                    <div className="title">
                                        <h3>Active Status</h3>
                                        <p>{getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtDecideProfileActivation")}</p>
                                    </div>
                                    <div className="check-box">
                                        <input
                                            type="checkbox"
                                            onChange={(evt) => {
                                                toggleActiveProfile();
                                            }}
                                            checked={profile?.isProfileActive}
                                            ref={inputRef}
                                        />
                                        <span>{getLanguageValue(PAGE_TYPE.STUDENT, "profile.inputActivate")}</span>
                                    </div>
                                </div>
                            </>
                        ) : null
                    ) : tab === "practice" ? (
                        isPracticeLoading ? (
                            <LoadingComponent />
                        ) : profileSubTab === "main" ? (
                            practiceList && practiceList.length > 0 ? (
                                <div className="training-video-wrap">
                                    <h2>{getLanguageValue(PAGE_TYPE.STUDENT, "profilePractice.txtTitle")}</h2>
                                    <div className="btn">
                                        <button
                                            className="new-btn"
                                            onClick={() => {
                                                navigate("/student/agency-profile/practice/new", {
                                                    state: {
                                                        tab: "practice",
                                                        profileSubTab: "main",
                                                        page: page || 1,
                                                    },
                                                });
                                            }}>
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnRegister")}
                                        </button>
                                    </div>
                                    <ul className="training-video-list-wrap">
                                        {practiceList.map((item: any, idx: number) => (
                                            <li className="training-video-list" key={`video-${idx}`}>
                                                <div className="training-video">
                                                    <div className="con">
                                                        <p className="shooting-date">{getFormattedDateValue(item.shootingDate, "YYYY.MM.DD")}</p>

                                                        {/* <!-- 영상이 비공개 처리일때 출력 --> */}
                                                        {item.visibilityCode === "visibility:private" ? (
                                                            <p className="private">{getLanguageValue(PAGE_TYPE.STUDENT, "profilePractice.txtPrivate")}</p>
                                                        ) : null}
                                                    </div>
                                                    <div className="video-img">
                                                        <a
                                                            onClick={(e) => {
                                                                navigate(`/student/agency-profile/practice/${item.id}`, {
                                                                    state: {
                                                                        tab: "practice",
                                                                        profileSubTab: "main",
                                                                        page: page || 1,
                                                                    },
                                                                });
                                                            }}>
                                                            <div className="thumbnail">
                                                                <div className="space">
                                                                    <div className="view">
                                                                        <img src="./imgs/icons/view-icon.png" alt="view count icon" />
                                                                        {item.viewCnt}
                                                                    </div>
                                                                    <div className="like">
                                                                        <img src="./imgs/icons/like-3.png" alt="number of likes" />
                                                                        <span>{item.favoriteCnt}</span>
                                                                    </div>
                                                                </div>
                                                                <button className="play-btn"></button>
                                                                <canvas id={`canvas-${idx}`} style={{ display: "none" }}></canvas>
                                                                <img id={`thumbnail-${idx}`} alt="portfolio thumbnail" style={{ visibility: "hidden" }} />
                                                                <p>{item.title}</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : (
                                <div className="training-video-wrap">
                                    <h2>{getLanguageValue(PAGE_TYPE.STUDENT, "profilePractice.txtTitle")}</h2>
                                    <div className="btn">
                                        <button
                                            className="new-btn"
                                            onClick={() => {
                                                navigate("/student/agency-profile/practice/new", {
                                                    state: {
                                                        tab: "practice",
                                                        profileSubTab: "main",
                                                        page: page || 1,
                                                    },
                                                });
                                            }}>
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnRegister")}
                                        </button>
                                    </div>
                                    <div className="no-filter">
                                        <p>
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtNoPracticeVideo")} <br />
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtUploadPracticeVideoMessage")} <br />
                                            <br />[{getLanguageValue(PAGE_TYPE.STUDENT, "profile.btnRegister")}]{" "}
                                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtUploadVideoPrompt")}
                                        </p>
                                    </div>
                                </div>
                            )
                        ) : null
                    ) : null}
                </div>
                {isShowPopup ? (
                    <div className="popup popup-view-count">
                        <div className="con">
                            <div
                                className="close-btn"
                                onClick={(e) => {
                                    setIsShowPopup(false);
                                }}></div>
                            <div className="wrap">
                                <div className="title">
                                    <p className="ellipsis">
                                        <span>
                                            {profileMediaType === "profileImage"
                                                ? getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtPhoto")
                                                : getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtVideo")}
                                        </span>{" "}
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtViewedAgency")}
                                    </p>
                                </div>
                                {popupList && popupList.length > 0 ? (
                                    <div className="list">
                                        <ul>
                                            {popupList.map((item: any, index: number) => (
                                                <li
                                                    onClick={(e) => {
                                                        openAgencyPopup(item.id);
                                                    }}>
                                                    <div className="item">
                                                        <div
                                                            className="img"
                                                            style={{
                                                                backgroundImage: `url(${
                                                                    item && item.logoImageInfo
                                                                        ? item.logoImageInfo.url.replace(/ /g, "%20")
                                                                        : "./imgs/icons/agency-logo-default.png"
                                                                })`,
                                                            }}></div>
                                                        <div className="view-count">
                                                            <button>
                                                                <img src="./imgs/icons/view-icon.png" alt="view count icon" />
                                                                {item.viewCnt}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="agency-name">
                                                        <p className="ellipsis">{item.companyName}</p>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>

            {tab === "practice" && profileSubTab === "main" ? (
                <Pagination url="/student/agency-profile" totalCount={totalCount} currentPage={page} pageSize={limit} pageGroupSize={10} setPage={setPage} />
            ) : null}

            {isAgencyPopup && agencyInfo ? (
                <div className="popup popup-casting-manager-info">
                    <div className="con">
                        <div className="wrap">
                            <div
                                className="close-btn"
                                onClick={(e) => {
                                    setIsAgencyPopup(false);
                                }}></div>
                            <h2>{getLanguageValue(PAGE_TYPE.STUDENT, "messageDetail.txtCastingManagerInfo")}</h2>
                            <div className="agency-info">
                                <img
                                    src={agencyInfo.logoImageInfo ? agencyInfo.logoImageInfo.url.replace(/ /g, "%20") : "./imgs/icons/agency-logo-default.png"}
                                    alt="agency-logo"
                                />
                                <p>{agencyInfo.companyName}</p>
                                <div className="casting-manager-info">
                                    <div className="business-card"></div>
                                    <div className="name">
                                        {agencyInfo.name}
                                        {/* <!-- 이메일 인증 했을경우 뱃지 --> */}
                                        {agencyInfo.hasVerifiedEmail === 1 ? <img src="./imgs/icons/badge.png" alt="badge" /> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="btn">
                                <button
                                    onClick={(e) => {
                                        setIsAgencyPopup(false);
                                    }}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "messageDetail.txtClose")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </main>
    );
};
export default ProfileComponent;
