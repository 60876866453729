import React, { useEffect } from "react";
import SearchComponent from "./Search.component";

const SearchContainer = (props: any) => {
  useEffect(() => {}, []);

  return <SearchComponent {...props} />;
};

export default SearchContainer;
