import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StudentSignupPreComponent from "./StudentSignupPre.component";
import * as SchoolApi from "api/school/School.api";

const StudentSignupPreContainer = (props: any) => {
  const { setStudentStep, selectedInfo, setSelectedInfo } = props;
  const navigate = useNavigate();
  const [schoolList, setSchoolList] = useState([]);
  const [schoolInfo, setSchoolInfo] = useState<any>(null);
  const [schoolId, setSchoolId] = useState<string | null>(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const getSchoolList = async () => {
    const result = await SchoolApi.getList();

    if (result.list.length > 0) {
      setSchoolList(result.list);
      if (schoolId) {
        const selectedSchool = result.list.find(
          (school: any) => school.id === parseInt(schoolId)
        );
        if (selectedSchool) {
          setSchoolInfo(selectedSchool);
        } else {
          setSchoolId(null);
          navigate("/signup/student");
        }
      }
    }
  };

  const onSchoolChange = (id: string) => {
    const selectedSchool = schoolList.find(
      (school: any) => school.id === parseInt(id)
    );
    if (selectedSchool) {
      setSelectedInfo(selectedSchool);
    } else {
      setSelectedInfo(null);
    }
  };

  const next = () => {
    setStudentStep(1);
  };

  useEffect(() => {
    const id = params.get("school-id");
    if (id) {
      if (isNaN(Number(id))) {
        setSchoolId(null);
        navigate("/signup/student");
      } else {
        setSchoolId(id);
      }
    }
  }, [location.search]);

  useEffect(() => {
    getSchoolList();
  }, [schoolId]);

  useEffect(() => {
    setSelectedInfo(null);
  }, []);

  return (
    <StudentSignupPreComponent
      schoolList={schoolList}
      schoolId={schoolId}
      schoolInfo={schoolInfo}
      selectedInfo={selectedInfo}
      next={next}
      onSchoolChange={onSchoolChange}
    />
  );
};

export default StudentSignupPreContainer;
