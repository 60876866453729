import React, { useEffect, useRef, useState } from "react";
import LoadingComponent from "commons/loading/Loading.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";
import { useNavigate } from "react-router-dom";
import { getCommonCodeListByParent } from "data/common.data";
import Slider from "react-slick";
import ThumbnailComponent from "commons/video/Thumbnail.component";

const AuditionApplyComponent = (props: any) => {
  const navigate = useNavigate();
  const {
    applicant,
    auditionInfo,
    auditionApplicantInfo,
    btnRef,
    onClickBtnSave,
    onClickBtnDelete,
    // profile
    setImageInfo,
    uploadImage,
    uploadPrevImage,
    imageInfo,
    imageInfoList,
    onClickBtnDeleteImage,
    // video
    videoInfoList,
    onClickBtnDeleteVideo,
    uploadVideo,
    selectedVideoUrl,
    setSelectedVideoUrl,
    isShowVideoPopup,
    setIsShowVideoPopup,
    getVideoUrl,
    selectedVideoId,
    setSelectedVideoId,
    //
    initAdditionalImage,
    initProfileImage,
    initImageInfoList,
    initImageIdList,
    initImageInfo,
  } = props;

  const videoPopupRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [initFlag, setInitFlag] = useState(false);

  const getSelectedVideoUrl = async () => {
    const url = await getVideoUrl(selectedVideoId);
    setSelectedVideoUrl(url);
  };
  useEffect(() => {
    setCurrentTime(0);
    getSelectedVideoUrl();
  }, [selectedVideoId]);

  useEffect(() => {
    const video = videoPopupRef.current;

    if (video && isShowVideoPopup) {
      video.src = selectedVideoUrl;
      video.muted = true;
      video.playsInline = true;
      video.currentTime = currentTime;
      video.load();
      const handleLoadedData = () => {
        video.play().catch((e: any) => console.log(e));
      };

      video.addEventListener("loadeddata", handleLoadedData);
      video.addEventListener("play", handlePlay);
      video.addEventListener("pause", handlePause);
      video.addEventListener("ended", handleEnded);
      video.addEventListener("seeked", handleSeeked);

      return () => {
        video.removeEventListener("loadeddata", handleLoadedData);
        video.removeEventListener("play", handlePlay);
        video.removeEventListener("pause", handlePause);
        video.removeEventListener("ended", handleEnded);
        video.removeEventListener("seeked", handleSeeked);
      };
    }
  }, [isShowVideoPopup, selectedVideoUrl]);

  useEffect(() => {
    const handleInitFlagChange = async () => {
      if (!initFlag) {
        const url = await getVideoUrl(selectedVideoId);
        setSelectedVideoUrl(url);
        setInitFlag(false);
      }
    };

    handleInitFlagChange();
  }, [initFlag]);

  const handleEnded = () => {
    setCurrentTime(0);
  };

  const handleSeeked = () => {
    const video = videoPopupRef.current;
    setCurrentTime(video.currentTime);
  };

  const handlePlay = () => {
    setInitFlag(false);
  };

  const handlePause = () => {
    const video = videoPopupRef.current;
    setCurrentTime(video.currentTime);
    setInitFlag(true);
  };

  return (
    <main className="agency-audition-applicant">
      {!auditionApplicantInfo?.id ? (
        <LoadingComponent />
      ) : (
        <div className="con">
          <div className="audition-application">
            <div
              className="back-btn"
              onClick={() => {
                navigate(-1);
              }}></div>
            <div className="agency-logo">
              <img
                src={`${
                  auditionInfo && auditionInfo.logoImageInfo
                    ? auditionInfo.logoImageInfo.url.replace(/ /g, "%20") + ""
                    : "./imgs/icons/agency-logo-default.png"
                }`}
                alt="agency logo"
              />
              <p>{auditionInfo && auditionInfo.companyName}</p>
            </div>
            <h2>{auditionInfo && auditionInfo.title}</h2>
            <div className="application-info">
              <h2>APPLICATION</h2>
              <div className="profile-image">
                {/* <!-- 지원서 작성화면 리셋버튼 --> */}
                {JSON.stringify(initImageInfo) !== JSON.stringify(imageInfo) ? (
                  <button
                    className="reset"
                    onClick={(e) => {
                      initProfileImage();
                    }}></button>
                ) : null}

                <input
                  type="file"
                  id={`input-file-profile`}
                  onChange={(evt) => uploadPrevImage("profile", null, evt)}
                  accept="image/*"
                />
                <label htmlFor={`input-file-profile`}>
                  <img
                    src={`${
                      imageInfo && imageInfo.url
                        ? imageInfo.url.replace(/ /g, "%20")
                        : "./imgs/dummy/basic-img-2.png"
                    }`}
                    alt="main profile image"
                  />
                </label>
              </div>
              <div className="info-1">
                <div className="info-1-wrap">
                  <p className="name">
                    {auditionApplicantInfo.firstName}{" "}
                    {auditionApplicantInfo.lastName}
                  </p>
                  <p className="birth">
                    {getFormattedDateValue(
                      auditionApplicantInfo.birthday,
                      "YYYY.MM.DD"
                    )}
                  </p>
                  <p className="country">
                    {auditionApplicantInfo.countryCodeInfo
                      ? getCurrentLangName(
                          auditionApplicantInfo.countryCodeInfo.names
                        )
                      : ""}
                  </p>
                  <p>
                    {auditionApplicantInfo.height
                      ? `${auditionApplicantInfo.height}cm`
                      : null}
                    {auditionApplicantInfo.weight
                      ? ` / ${auditionApplicantInfo.weight}kg`
                      : null}
                    {auditionApplicantInfo.bloodTypeCodeInfo
                      ? ` / ${getCurrentLangName(
                          auditionApplicantInfo.bloodTypeCodeInfo.names
                        )}`
                      : null}
                  </p>
                  <p>
                    {auditionApplicantInfo.specialtyCodeInfoList
                      ? auditionApplicantInfo.specialtyCodeInfoList.map(
                          (item: any) => (
                            <span>{getCurrentLangName(item.names)}</span>
                          )
                        )
                      : null}
                    {auditionApplicantInfo.mbtiCode ? (
                      <span>{auditionApplicantInfo.mbtiCode}</span>
                    ) : null}
                  </p>
                </div>
              </div>

              <div className="info-2">
                <h2>MEDIA</h2>
                <ul className="media-list-wrap">
                  {videoInfoList && videoInfoList.length > 0
                    ? videoInfoList.map((videoInfo: any, index: number) => (
                        <ThumbnailComponent
                          key={videoInfo.id}
                          videoId={videoInfo.id}
                          videoUrl={videoInfo.url}
                          setSelectedVideoUrl={setSelectedVideoUrl}
                          setSelectedVideoId={setSelectedVideoId}
                          setIsShowVideoPopup={setIsShowVideoPopup}
                          isEdit={true}
                          index={index}
                          onClickBtnDeleteVideo={onClickBtnDeleteVideo}
                          getVideoUrl={getVideoUrl}
                        />
                      ))
                    : null}
                  <li className="media-list">
                    <div className="img-upload">
                      <input
                        type="file"
                        id="input-file-video"
                        onChange={(evt) => uploadVideo(evt)}
                        accept="video/*"
                      />
                      <label
                        className="input-file-img"
                        htmlFor="input-file-video">
                        <div className="img-add"></div>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              {isShowVideoPopup ? (
                <div
                  id="popup"
                  className="popup media-popup"
                  style={{ display: "flex" }}>
                  <div className="popup-content">
                    <span
                      className="close-btn"
                      onClick={(e) => {
                        setIsShowVideoPopup(false);
                      }}>
                      <img
                        src="./imgs/icons/close-white.png"
                        alt="close button"
                      />
                    </span>
                    <video
                      controls
                      ref={videoPopupRef}
                      muted
                      playsInline
                      preload={"auto"}>
                      <source src={selectedVideoUrl} type="video/mp4" />
                      {getLanguageValue(
                        PAGE_TYPE.STUDENT,
                        "auditionApply.txtVideoGuide"
                      )}
                    </video>
                  </div>
                </div>
              ) : null}
              <div className="info-3">
                {/* <!-- 지원서 작성화면 리셋버튼 --> */}
                {JSON.stringify(imageInfoList) !==
                JSON.stringify(initImageInfoList) ? (
                  <button
                    className="reset"
                    onClick={(e) => {
                      initAdditionalImage();
                    }}></button>
                ) : null}

                <h2>ADDITIONAL PHOTOS</h2>
                <ul>
                  {imageInfoList.length > 0 &&
                    imageInfoList.map((item: any, idx: number) => (
                      <li>
                        <div className="img-upload" key={`image-${idx}`}>
                          <div
                            className="btn-delete"
                            onClick={(evt) => onClickBtnDeleteImage(idx)}></div>
                          <input
                            type="file"
                            id={`input-file-img-${idx}`}
                            onChange={(evt) =>
                              uploadPrevImage("addition", item, evt)
                            }
                            accept="image/*"
                          />
                          <label htmlFor={`input-file-img-${idx}`}>
                            <img
                              src={`${
                                item && item.url
                                  ? item.url.replace(/ /g, "%20") + ""
                                  : "./imgs/dummy/basic-img-2.png"
                              }`}
                              alt="additional photo"
                            />
                          </label>
                        </div>
                      </li>
                    ))}
                  <li>
                    <div className="img-upload">
                      <input
                        type="file"
                        id="input-file-img"
                        onChange={(evt) => uploadImage("addition", evt)}
                        accept="image/*"
                      />
                      <label
                        className="input-file-img"
                        htmlFor="input-file-img">
                        <div className="img-add"></div>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {applicant ? (
              <div className="btn">
                {/* <!-- 지원서 수정화면일때 - delete, update --> */}
                <div>
                  <button
                    className="delete-btn"
                    ref={btnRef}
                    onClick={(e) => {
                      onClickBtnDelete();
                    }}>
                    {getLanguageValue(
                      PAGE_TYPE.STUDENT,
                      "auditionApply.btnDelete"
                    )}
                  </button>
                </div>
                <div>
                  <button
                    className="update-btn"
                    ref={btnRef}
                    onClick={(e) => {
                      onClickBtnSave();
                    }}>
                    {getLanguageValue(
                      PAGE_TYPE.STUDENT,
                      "auditionApply.btnUpdate"
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <div className="btn">
                {/* <!-- 지원서 작성화면일때 - save --> */}
                <div>
                  <button
                    className="save-btn"
                    ref={btnRef}
                    onClick={(e) => {
                      onClickBtnSave();
                    }}>
                    {getLanguageValue(
                      PAGE_TYPE.STUDENT,
                      "auditionApply.btnSave"
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </main>
  );
};
export default AuditionApplyComponent;
