import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import * as AgencyApi from "api/agency/Agency.api";
import AgencySignupDoneComponent from "./AgencySignupDone.component";

const AgencySignupDoneContainer = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [accountResult, setAccountResult] = useState(
    location.state?.accountResult
  );
  const [agencyParams, setAgencyParams] = useState(
    location.state?.agencyParams
  );

  const [businessCardFrontImageInfo, setBusinessCardFrontImageInfo] = useState(
    location.state?.businessCardFrontImageInfo
  );

  const [businessCardBackImageInfo, setBusinessCardBackImageInfo] = useState(
    location.state?.businessCardBackImageInfo
  );

  useEffect(() => {
    if (!accountResult) {
      navigate("/");
    }
    console.log(accountResult);
    console.log(agencyParams);
    console.log(businessCardFrontImageInfo);
    console.log(businessCardBackImageInfo);
  }, []);

  return (
    <AgencySignupDoneComponent
      accountResult={accountResult}
      agencyParams={agencyParams}
      businessCardFrontImageInfo={businessCardFrontImageInfo}
      businessCardBackImageInfo={businessCardBackImageInfo}
    />
  );
};

export default AgencySignupDoneContainer;
