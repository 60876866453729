export const getVideoInfo = async (fileObj: any): Promise<any | null> => {
    console.log("fileObj", fileObj);
  
    return new Promise((resolve, reject) => {
      // 파일 객체의 URL 생성
      const videoURL = URL.createObjectURL(fileObj);
      
      var video = document.createElement("video");
      video.src = videoURL;
  
      video.onloadedmetadata = () => {
        console.log(video);
        console.log(video.videoWidth, video.videoHeight, video.duration);


        console.log(45454, videoURL)
  
        resolve({
          name: fileObj.name,
          size: fileObj.size,
          width: video.videoWidth,
          height: video.videoHeight,
          duration: video.duration,
          url: videoURL, // videoURL 반환
        });
  
        // 메모리 해제
        URL.revokeObjectURL(videoURL);
      };
  
      video.onerror = (err) => {
        console.error(err);
        alert(err)
        URL.revokeObjectURL(videoURL); // 오류 시 URL 해제
        resolve(null);
      };
    });
  };

// export const getVideoInfo = async (fileObj: any): Promise<any | null> => {
//   console.log("fileObj", fileObj);

//   const result = new Promise((resolve, reject) => {
//     var reader = new FileReader();
//     reader.readAsDataURL(fileObj);

//     reader.onload = (evt: any) => {
//       var video = document.createElement("video");
//       video.src = evt.target.result;

//       video.onloadedmetadata = () => {
//         console.log(video);
//         console.log(video.videoWidth, video.videoHeight, video.duration);

//         resolve({
//           name: fileObj.name,
//           size: fileObj.size,
//           url: evt.target.result,
//         });
//       };

//       video.onerror = (err) => {
//         console.error(err);
//         resolve(null);
//       };
//     };

//     reader.onerror = (err) => {
//       console.error(err);
//       reject(err);
//     };
//   });

//   return result;
// };

export const getVideoInfoByUrl = async (url: string): Promise<any | null> => {
  const result = new Promise((resolve, reject) => {
    var img = new Image();
    img.src = url;

    img.onload = (e: any) => {
      console.log(e);
      console.log(img);
      console.log(img.width, img.height);

      resolve({
        url: url,
        width: img.width,
        height: img.height,
      });
    };

    img.onerror = (err) => {
      console.error(err);
      resolve(null);
    };
  });

  return result;
};
