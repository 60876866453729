import React, { useEffect, useState } from "react";
import AuditionDetailComponent from "./AuditionDetail.component";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import * as AuditionApi from "api/agency/Audition.api";

const AuditionDetailContainer = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  //const { status, page } = location.state || {};
  const searchParams = new URLSearchParams(location.search);
  const [prev, setPrev] = useState(
    searchParams.get("prev") || location.state?.prev || 1
  );
  const [page, setPage] = useState(
    searchParams.get("page") || location.state?.page || 1
  );
  const [status, setStatus] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [auditionInfo, setAuditionInfo] = useState(null);

  const getAuditionInfo = async () => {
    const result = await AuditionApi.getInfo(parseInt(id), {
      hasStatistics: 1,
    });

    if (result?.info) {
      setAuditionInfo(result.info);
      setStatus(result.info.status);
      setInitialLoad(false);
    } else {
      navigate(`/agency/audition`);
    }
  };

  useEffect(() => {
    getAuditionInfo();
  }, [id]);

  useEffect(() => {
    if (!initialLoad) {
      getAuditionInfo();
    }
  }, [status]);

  return (
    <AuditionDetailComponent
      {...props}
      id={id}
      status={status}
      setStatus={setStatus}
      auditionInfo={auditionInfo}
    />
  );
};

export default AuditionDetailContainer;
