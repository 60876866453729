import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { checkYoutubeId } from "helpers/Reg.helper";

import * as PortfolioApi from "api/school/Portfolio.api";
import * as StudentApi from "api/student/Student.api";

import UpdatePortfolioComponent from "./UpdatePortfolio.component";

const UpdatePortfolioContainer = (props: any) => {
  const navigate = useNavigate();
  const state = useLocation().state;

  const videoRef = useRef<any>(null);
  const [videoWidth, setVideoWidth] = useState(0);

  const { id } = useParams();
  const { info: myInfo } = useSelector((state: any) => state.user);

  const [params, setParams] = useState<any>({});

  const [keyword, setKeyword] = useState("");

  const [studentList, setStudentList] = useState([]);
  const [taggedStudentList, setTaggedStudentList] = useState([]);

  const [isInfoLoading, setIsInfoLoading] = useState(true);
  const [isStudentListLoading, setIsStudentListLoading] = useState(true);

  const getInfo = async () => {
    const result = await PortfolioApi.getInfo(+id);

    if (result?.info) {
      setParams(result.info);
      setTaggedStudentList(result.info.studentInfoList);
    }
    setIsInfoLoading(false);
  };

  const getStudentList = async () => {
    const result = await StudentApi.getList({
      schoolId: myInfo.userSchoolId,
      keyword,
    });

    if (result.list.length > 0) {
      setStudentList(result.list);
    }
    setIsStudentListLoading(false);
  };

  const updateInfo = async () => {
    const studentIdList: number[] = [];
    taggedStudentList.map((item: any) => studentIdList.push(item.id));

    params.studentIdList =
      studentIdList.length > 0 ? JSON.stringify(studentIdList) : "";

    const result = await PortfolioApi.updateInfo(params.id, params);
    if (result) {
      result.data?.errorCode ? alert(result.data.message) : close();
    }
  };

  const onChangeInput = (componentName: string, value: any) => {
    console.log("UpdatePortfolioContainer:onChangeInput", componentName, value);

    if (componentName === "youtubeId") {
      setParams({ ...params, youtubeId: value });
    } else if (componentName === "title") {
      setParams({ ...params, title: value });
    }
    if (componentName === "performedDate") {
      setParams({ ...params, performedDate: value });
    }
  };

  const getTitle = async () => {
    if (params?.youtubeId && checkYoutubeId(params.youtubeId)) {
      const result = await PortfolioApi.getVideoTitle({
        youtubeId: params.youtubeId,
      });

      if (result) {
        setParams({ ...params, title: result.title });
      }
    }
  };

  const changeTaggedStudentList = (studentInfo: any) => {
    setTaggedStudentList((prev: any) => {
      let taggedStudentIdList = [...prev];

      if (!taggedStudentIdList.find((i: any) => i.id === studentInfo.id)) {
        taggedStudentIdList.push(studentInfo);
      } else {
        taggedStudentIdList = taggedStudentIdList.filter(
          (i: any) => i.id !== studentInfo.id
        );
      }

      return taggedStudentIdList;
    });
  };

  const close = () => {
    navigate(`/school/portfolio/${id}`, { state });
  };

  useEffect(() => {
    if (videoRef.current) {
      setVideoWidth(videoRef.current.offsetWidth);
    }
  }, [videoRef.current?.offsetWidth]);

  useEffect(() => {
    getStudentList();
    getInfo();

    const updateSize = () => {
      if (videoRef.current) {
        setVideoWidth(videoRef.current.offsetWidth);
      }
    };
    updateSize();
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  return (
    <UpdatePortfolioComponent
      isInfoLoading={isInfoLoading}
      isStudentListLoading={isStudentListLoading}
      videoRef={videoRef}
      videoWidth={videoWidth}
      params={params}
      studentList={studentList}
      getStudentList={getStudentList}
      taggedStudentList={taggedStudentList}
      keyword={keyword}
      setKeyword={setKeyword}
      getTitle={getTitle}
      onChangeInput={onChangeInput}
      changeTaggedStudentList={changeTaggedStudentList}
      onClickBtnUpdate={updateInfo}
      onClickBtnClose={close}
    />
  );
};

export default UpdatePortfolioContainer;
