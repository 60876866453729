import { get, post } from "../Base.api";

// 파트너 목록
export const getPartnerList = async () => {
  console.log("Landing.api::getPartnerList");
  const url = "/home/partner";
  return get(url);
};

// 이메일 contact 저장
export const saveContactInfo = async (params: any) => {
  console.log("Landing.api::v");
  const url = "/home/contact";
  return post(url, params);
};
