import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import * as commonData from "data/common.data";
import CheckPassword from "pages/common/checkPassword/CheckPassword.container";
import { getCurrentLangName } from "helpers/String.helper";
import LoadingComponent from "commons/loading/Loading.component";

const UpdatePasswordComponent = (props: any) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { id, isCheckedPassword, setIsCheckedPassword, contactParams, updateContactInfo, onChangeInput, cancelUpdate, isOnNetworking } = props;

    return !isCheckedPassword ? (
        <CheckPassword setIsCheckedPassword={setIsCheckedPassword} />
    ) : (
        <main>
            <div className="con profile-update">
                <h2>EMERGENCY CONTACT UPDATE</h2>
                {id ? (
                    <>
                        <div className="form essential-information">
                            <div className="input-box">
                                <label>*{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtContactType")}</label>
                                <select value={contactParams.contactTypeCode} onChange={(evt) => onChangeInput("contactTypeCode", evt.target.value)}>
                                    <option value="" disabled selected>
                                        {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtOptional")}
                                    </option>
                                    {commonData.getCommonCodeListByParent("contactType").map((item: any, idx: number) => (
                                        <option key={idx} value={item.code}>
                                            {getCurrentLangName(item.names)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="input-box">
                                <label>*{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtContactDetail")}</label>
                                <div className="con">
                                    <input
                                        type="text"
                                        value={contactParams.contact}
                                        onChange={(evt) => onChangeInput("contact", evt.target.value)}
                                        placeholder={getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtContactDetailDesc")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="btn">
                            <div className="cancen-btn">
                                <button onClick={(evt) => cancelUpdate()}>{getLanguageValue(PAGE_TYPE.STUDENT, "my.btnCancel")}</button>
                            </div>
                            <div className="update-btn">
                                <button onClick={(evt) => updateContactInfo()} disabled={isOnNetworking}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnUpdate")}
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <LoadingComponent />
                )}
            </div>
        </main>
    );
};

export default UpdatePasswordComponent;
