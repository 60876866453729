import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as SchoolApi from "api/school/School.api";
import * as DataApi from "api/common/Data.api";
import * as AppApi from "api/App.api";
import * as AuthApi from "api/common/Auth.api";

import MyComponent from "./My.component";
import UpdateMy from "./UpdateMy.container";
import { removeAuthTokens } from "helpers/Auth.helper";
import { setInfo as setUserInfo, setIsLogin } from "reducers/User";
import { removePushToken } from "helpers/Firebase.helper";
import { removeAppAccessToken } from "helpers/appLocalStorageHelper";

const MyContainer = (props: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { info: myInfo } = useSelector((state: any) => state.user);

  const [schoolInfo, setSchoolInfo] = useState<any>({});

  const [marketingTermList, setMarketingTermList] = useState([]);
  const [termList, setTermList] = useState([]);
  const [termId, setTermId] = useState(0);

  const type = location.pathname?.split("-")[1] || "";
  const [updateType, setUpdateType] = useState(type);

  const getInfo = async () => {
    const result = await SchoolApi.getInfo(myInfo.userSchoolId);

    if (result?.schoolInfo) {
      setSchoolInfo(result.schoolInfo);

      const termList = result.schoolInfo.termList;

      if (!termId) {
        termList.map((item: any) => (item.disabled = false));
        setMarketingTermList(termList);
      } else {
        setMarketingTermList((prev: any) => {
          const list = [...prev];
          list.find((i: any) => i.id === termId).disabled = false;

          return list;
        });

        setTermId(0);
      }
    }
  };

  const getTermList = async () => {
    const accountType = "school";
    const result = await AuthApi.getTermListByType({ accountType });

    if (result?.list) {
      setTermList(result.list.slice(0, 2));
    }
  };

  const downloadFile = () => {
    DataApi.fileDownload(schoolInfo.businessRegistrationFileInfo.url);
  };

  const updateUserTerm = async () => {
    const { hasAgreed } = marketingTermList.find((i: any) => i.id === termId);
    const result: any = await AppApi.updateUserTerm(termId, {
      hasAgreed,
    });

    if (result) {
      getInfo();
    }
  };

  const updateMarketingTermList = (idx: number, checked: boolean) => {
    setMarketingTermList((prev: any) => {
      const list = [...prev];
      list[idx].hasAgreed = checked;
      list[idx].disabled = true;

      return list;
    });
  };

  const sendVerifyEmail = async () => {
    const params = {
      username: myInfo.username,
      accountTypeCode: "accountType:school",
    };
    const result: any = await AuthApi.sendVerifyEmail(params);

    if (result.status === 200) {
      alert(getLanguageValue(PAGE_TYPE.SCHOOL, "my.sendEmail"));
    }
  };

  const logout = async () => {
    await removePushToken();
    removeAuthTokens();
    dispatch(setIsLogin(false));
    dispatch(setUserInfo({}));
    navigate("/", { replace: true });
    await removeAppAccessToken();
  };

  useEffect(() => {
    if (termId) {
      updateUserTerm();
    }
  }, [marketingTermList]);

  useEffect(() => {
    if (location.pathname === "/school/my") {
      setUpdateType("");
    } else if (location.pathname.includes("/school/my/")) {
      const updateType = location.pathname?.split("-")[1] || "";
      setUpdateType(updateType);
    }
  }, [location]);

  useEffect(() => {
    getTermList();
    getInfo();
  }, []);

  return !updateType ? (
    <MyComponent
      email={myInfo.username}
      schoolInfo={schoolInfo}
      setTermId={setTermId}
      marketingTermList={marketingTermList}
      termList={termList}
      setUpdateType={setUpdateType}
      onClickBtnDownloadFile={downloadFile}
      updateMarketingTermList={updateMarketingTermList}
      sendVerifyEmail={sendVerifyEmail}
      logout={logout}
    />
  ) : (
    <UpdateMy
      schoolInfo={schoolInfo}
      setSchoolInfo={setSchoolInfo}
      getInfo={getInfo}
      updateType={updateType}
      setUpdateType={setUpdateType}
    />
  );
};

export default MyContainer;
