import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import LoadingComponent from "commons/loading/Loading.component";

const VerifyEmailComponent = (props: any) => {
  const { isVerify } = props;

  return (
    <div className="certification">
      <main>
        {typeof isVerify !== "boolean" ? (
          <LoadingComponent />
        ) : (
          <div>
            <strong>
              {getLanguageValue(
                PAGE_TYPE.COMMON,
                isVerify
                  ? "verifyEmail.txtSuccessful"
                  : "verifyEmail.txtFailed1"
              )}
            </strong>
            {!isVerify ? (
              <p>
                {getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtFailed2")}
              </p>
            ) : null}
          </div>
        )}
      </main>
    </div>
  );
};

export default VerifyEmailComponent;
