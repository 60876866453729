import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import { checkIsEmail, checkPassword, checkIsNumberThatDoNotBeginWithZero, checkSlug } from "helpers/Reg.helper";
import { removeAuthTokens, setAccessToken } from "helpers/Auth.helper";
import * as ImageHelper from "helpers/Image.helper";

import * as AuthApi from "api/common/Auth.api";
import * as DataApi from "api/common/Data.api";

import StudentSignupComponent from "./StudentSignup.component";
import StudentSignupDoneComponent from "./StudentSignupDone.component";

const StudentSignupContainer = (props: any) => {
    const { selectedInfo } = props;
    const btnRef = useRef<any>();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const schoolId = selectedInfo?.id || null;
    const token = searchParams.get("token");

    const { cancelSignup } = props;

    const [termList, setTermList] = useState([]);

    const [imageInfo, setImageInfo] = useState<any>({});
    const [imageInfoList, setImageInfoList] = useState([]);
    const [isOnNetworking, setIsOnNetworking] = useState(false);

    const [imageIdList, setImageIdList] = useState([]);
    const [isDone, setIsDone] = useState(false);

    const [accountParams, setAccountParams] = useState<any>({
        username: "",
        password: "",
        confirmedPassword: "",
        accountTypeCode: "accountType:student",
    });

    const [studentParams, setStudentParams] = useState<any>({
        profileImageId: null,
        countryCode: "",
        firstName: "",
        lastName: "",
        birthday: "",
        genderTypeCode: "",
        slug: "",
        schoolId: schoolId ? schoolId : null,
        nickname: "",
        height: "",
        weight: "",
        shoeSize: "",
        mbtiCode: "",
        specialtyCodeList: null,
        bloodTypeCode: "",
        imageIdList: [],
        contactTypeCode: "",
        contact: "",
    });

    const [hasCheckedSpecialty, setHasCheckedSpecialty] = useState({
        dance: false,
        vocal: false,
        rap: false,
    });

    const [parentEmail, setParentEmail] = useState("");

    const getTermList = async () => {
        const result = await AuthApi.getTermListByType({ accountType: "wannabe" });

        if (result?.list) {
            result.list.map((item: any) => (item.checked = false));
            setTermList(result.list);
        }
    };

    const onChangeInput = (componentName: string, value: any) => {
        console.log("StudentSignupContainer:onChangeInput", componentName, value);

        // 계정 정보
        if (componentName === "username") {
            setAccountParams({ ...accountParams, username: value.trim() });
        } else if (componentName === "password") {
            setAccountParams({ ...accountParams, password: value });
        }
        if (componentName === "confirmedPassword") {
            setAccountParams({ ...accountParams, confirmedPassword: value });
        }

        // 학생 정보
        if (componentName === "countryCode") {
            setStudentParams({ ...studentParams, countryCode: value });
        } else if (componentName === "firstName") {
            setStudentParams({ ...studentParams, firstName: value });
        } else if (componentName === "lastName") {
            setStudentParams({ ...studentParams, lastName: value });
        } else if (componentName === "birthday") {
            setStudentParams({ ...studentParams, birthday: value });
        } else if (componentName === "genderTypeCode") {
            setStudentParams({ ...studentParams, genderTypeCode: value });
        } else if (componentName === "nickname") {
            setStudentParams({ ...studentParams, nickname: value });
        } else if (componentName === "height") {
            const height = checkIsNumberThatDoNotBeginWithZero(value) ? value : studentParams.height;
            setStudentParams({ ...studentParams, height });
        } else if (componentName === "weight") {
            const weight = checkIsNumberThatDoNotBeginWithZero(value) ? value : studentParams.weight;
            setStudentParams({ ...studentParams, weight });
        } else if (componentName === "shoeSize") {
            const shoeSize = checkIsNumberThatDoNotBeginWithZero(value) ? value : studentParams.shoeSize;
            setStudentParams({ ...studentParams, shoeSize });
        } else if (componentName === "mbtiCode") {
            setStudentParams({ ...studentParams, mbtiCode: value });
        } else if (componentName === "specialtyCodeList") {
            setStudentParams({ ...studentParams, specialtyCodeList: value });
        } else if (componentName === "bloodTypeCode") {
            setStudentParams({ ...studentParams, bloodTypeCode: value });
        } else if (componentName === "slug") {
            setStudentParams({ ...studentParams, slug: value });
        } else if (componentName === "contactTypeCode") {
            setStudentParams({ ...studentParams, contactTypeCode: value });
        } else if (componentName === "contact") {
            setStudentParams({ ...studentParams, contact: value });
        }
    };

    const onChangeCheckbox = (componentName: string, checkedItem: any, checked: boolean) => {
        // 특기 선택
        if (componentName === "vocal") {
            setHasCheckedSpecialty({ ...hasCheckedSpecialty, vocal: checked });
        } else if (componentName === "dance") {
            setHasCheckedSpecialty({ ...hasCheckedSpecialty, dance: checked });
        } else if (componentName === "rap") {
            setHasCheckedSpecialty({ ...hasCheckedSpecialty, rap: checked });
        } else {
            // 약관 동의
            setTermList((prev: any) => {
                const termList = [...prev];
                checkedItem === "all" ? termList.map((item: any) => (item.checked = checked)) : (termList[+checkedItem].checked = checked);

                return termList;
            });
        }
    };

    const uploadImage = async (type: string, evt: any) => {
        setIsOnNetworking(true);

        if (!evt) {
            return false;
        }

        const file = evt.target.files[0];
        const imageInfo = await ImageHelper.getImageInfo(file);
        if (!imageInfo) {
            alert("Please choose image file");
            return false;
        }

        const result = await DataApi.uploadImage(file);

        if (type === "profile") {
            setImageInfo({ id: result.id, url: imageInfo.url });
        } else if (type === "addition") {
            imageInfoList.push(imageInfo);
            setImageInfoList(imageInfoList);
            imageIdList.push(result.id);
            setImageIdList(imageIdList);
        }

        setIsOnNetworking(false);
    };

    const deleteAdditionalImageInfo = (idx: number) => {
        const idList = imageIdList.filter((a: any, i: number) => i !== idx);
        const infoList = imageInfoList.filter((a: any, i: number) => i !== idx);

        setImageIdList(idList);
        setImageInfoList(infoList);
    };

    const checkIsUnder14 = (birthday: string) => {
        const today = new Date();
        const birthDate = new Date(birthday);

        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return 14 > age ? true : false;
    };

    const checkExistsSlug = async () => {
        const slug = studentParams.slug;
        const result = await AuthApi.checkExistsSlug({ slug });

        return result;
    };

    const isDisabled = async () => {
        if (!accountParams.username) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errEmail"));
            return true;
        } else if (!checkIsEmail(accountParams.username)) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errInvalidEmail"));
            return true;
        } else if (!accountParams.password) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errPassword"));
            return true;
        } else if (!checkPassword(accountParams.password)) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errInvalidPassword"));
            return true;
        } else if (!accountParams.confirmedPassword) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errConfirmPassword"));
            return true;
        } else if (accountParams.password !== accountParams.confirmedPassword) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errPasswordAndConfirmPasswordMatch"));
            return true;
        } else if (!studentParams.countryCode) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errCountrySelection"));
            return true;
        } else if (!studentParams.firstName) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errFirstName"));
            return true;
        } else if (!studentParams.lastName) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errLastName"));
            return true;
        } else if (!studentParams.birthday) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errBirthdaySelection"));
            return true;
        } else if (!imageInfo?.id) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errProfileImageSelection"));
            return true;
        } else if (checkIsUnder14(studentParams.birthday) && !parentEmail) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errParentEmail"));
            return true;
        } else if (checkIsUnder14(studentParams.birthday) && !checkIsEmail(parentEmail)) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errInvalidEmail"));
            return true;
        } else if (checkIsUnder14(studentParams.birthday) && parentEmail === accountParams.username) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errParentEmailAndUserEmailSame"));
            return true;
        } else if (!studentParams.genderTypeCode) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errGenderSelection"));
            return true;
        } else if (studentParams.slug && (await checkExistsSlug())) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errExistsSlug"));
            return true;
        } else if (studentParams.slug && !checkSlug(studentParams.slug)) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errInvalidSlug"));
            return true;
        } else if (!termList[0].checked) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errAgreeTermOfService"));
            return true;
        } else if (!termList[1].checked) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errAgreePrivacyPolicy"));
            return true;
        } else if (!studentParams.contactTypeCode) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errContactTypeCode"));
            return true;
        } else if (!studentParams.contact) {
            alert(getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.errContact"));
            return true;
        } else {
            return false;
        }
    };

    const setSpecialtyCodeList = () => {
        const specialtyCodeList = [];
        if (hasCheckedSpecialty.vocal) {
            specialtyCodeList.push("specialty:vocal");
        }
        if (hasCheckedSpecialty.dance) {
            specialtyCodeList.push("specialty:dance");
        }

        if (hasCheckedSpecialty.rap) {
            specialtyCodeList.push("specialty:rap");
        }

        studentParams.specialtyCodeList = JSON.stringify(specialtyCodeList);
    };

    const createInfo = async () => {
        if (!(await isDisabled())) {
            btnRef.current.disabled = true;

            accountParams.termsOfService = true;
            accountParams.personalPolicy = true;
            accountParams.receiveEmail = termList[2].checked;
            accountParams.receiveMessage = termList[3].checked;

            // 계정 생성
            const accountResult = await AuthApi.signupByEmail(accountParams);

            if (accountResult) {
                const isUnder14 = checkIsUnder14(studentParams.birthday);

                setAccessToken(accountResult?.data?.data?.jwt);
                setSpecialtyCodeList();

                if (imageInfo?.id) {
                    studentParams.profileImageId = imageInfo.id;
                }

                studentParams.imageIdList = imageIdList;

                studentParams.under14 = isUnder14 ? 1 : 0;
                studentParams.isActive = isUnder14 ? 0 : 1;

                // 학생 정보 생성
                const studentResult = await AuthApi.createInfo("student", studentParams);

                if (studentResult) {
                    if (isUnder14) {
                        // 법적 보호자 이메일로 인증 메일 발송
                        await AuthApi.sendVerifyParentEmail({
                            parentEmail,
                        });
                    }

                    btnRef.current.disabled = false;
                    removeAuthTokens();
                    setIsDone(true);

                    // navigate("/login");
                }
            } else {
                btnRef.current.disabled = false;
            }
        }
    };

    useEffect(() => {
        getTermList();
    }, []);

    return (
        termList.length > 0 &&
        (!isDone ? (
            <StudentSignupComponent
                btnRef={btnRef}
                accountParams={accountParams}
                studentParams={studentParams}
                parentEmail={parentEmail}
                setParentEmail={setParentEmail}
                checkIsUnder14={checkIsUnder14}
                imageInfo={imageInfo}
                imageInfoList={imageInfoList}
                termList={termList}
                isOnNetworking={isOnNetworking}
                uploadImage={uploadImage}
                onClickBtnDeleteAdditionalImage={deleteAdditionalImageInfo}
                onChangeInput={onChangeInput}
                onChangeCheckbox={onChangeCheckbox}
                onClickBtnCancelSignup={cancelSignup}
                onClickBtnSignup={createInfo}
            />
        ) : (
            <StudentSignupDoneComponent
                accountParams={accountParams}
                studentParams={studentParams}
                parentEmail={parentEmail}
                imageInfo={imageInfo}
                checkIsUnder14={checkIsUnder14}
            />
        ))
    );
};

export default StudentSignupContainer;
