import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getList as getStudentList,
  createFavorite,
  deleteFavorite,
} from "api/student/Student.api";
import StudentCardComponent from "commons/card/StudentCard.component";
import { useLocation } from "react-router-dom";
import {
  setSearchGenderTypeCode,
  setSearchCountryCodeList,
  setSearchHeightRange,
  setSearchWeightRange,
  setSsearchAgeRange,
  setSearchKeyword,
} from "reducers/Agency";
import LoadingComponent from "commons/loading/Loading.component";
import { getCurrentLangName } from "helpers/String.helper";

const SearchComponent = (props: any) => {
  const {
    isShowFilterPopup,
    searchKeyword,
    searchGenderTypeCode,
    searchCountryCodeList,
    searchHeightRange,
    searchWeightRange,
    searchAgeRange,
  } = useSelector((state: any) => state.agency);
  const [list, setList] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const [isInit, setIsInit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [disableFavoriteButton, setDisableFavoriteButton] = useState(true);

  const getListValue = async (isShowLoading: boolean) => {
    if (isShowLoading) {
      setIsLoading(true);
    }

    const params = {
      keyword: searchKeyword,
      genderTypeCode: searchGenderTypeCode,
      heightRange: JSON.stringify(searchHeightRange),
      countryCodeList: JSON.stringify(
        searchCountryCodeList.map((item: any) => item.code)
      ),
      weightRange: JSON.stringify(searchWeightRange),
      ageRange: JSON.stringify(searchAgeRange),
    };
    const result = await getStudentList(params);

    if (result?.list) {
      setList(result.list);
    }
    setIsLoading(false);
    setDisableFavoriteButton(false);
  };

  const changeIsFavorite = async (studentId: number) => {
    setDisableFavoriteButton(true);
    const student = list.find((item: any) => item.id === studentId);
    const isFavorited = student?.isFavorited;

    // 바로 화면에서 반영되도록 state 값 수정
    if (isFavorited === 0) {
      student.isFavorited = 1;
      student.favoriteCnt = student.favoriteCnt + 1;
    } else {
      student.isFavorited = 0;
      student.favoriteCnt = student.favoriteCnt - 1;
    }

    setList([...list]);

    const param = { studentId };
    const result: any = isFavorited
      ? await deleteFavorite(param)
      : await createFavorite(param);

    if (result?.status !== 200) {
      alert(result.data.message);
    }
    getListValue(false);
  };

  const getQueryStringObjectValue = (key: string) => {
    const stringValue = searchParams.get(key);
    if (!stringValue) {
      return "";
    }

    try {
      return JSON.parse(decodeURIComponent(stringValue));
    } catch {
      return "";
    }
  };

  const isShowFilterArea = () => {
    if (
      (searchCountryCodeList && searchCountryCodeList.length > 0) ||
      searchGenderTypeCode ||
      !(searchHeightRange[0] === 140 && searchHeightRange[1] === 200) ||
      !(searchWeightRange[0] === 40 && searchWeightRange[1] === 100) ||
      !(searchAgeRange[0] === 0 && searchAgeRange[1] === 30)
    ) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (searchParams.toString().length) {
      const searchKeyword = searchParams.get("searchKeyword");
      const searchGenderTypeCode = searchParams.get("searchGenderTypeCode");
      const searchHeightRange = getQueryStringObjectValue("searchHeightRange");
      const searchCountryCodeList = getQueryStringObjectValue(
        "searchCountryCodeList"
      );
      const searchWeightRange = getQueryStringObjectValue("searchWeightRange");
      const searchAgeRange = getQueryStringObjectValue("searchAgeRange");

      if (searchKeyword) {
        dispatch(setSearchKeyword(searchKeyword));
      }

      if (searchCountryCodeList?.length > 0) {
        dispatch(setSearchCountryCodeList(searchCountryCodeList));
      }

      if (searchGenderTypeCode) {
        dispatch(setSearchGenderTypeCode(searchGenderTypeCode));
      }

      if (searchHeightRange?.length === 2) {
        dispatch(setSearchHeightRange(searchHeightRange));
      }

      if (searchWeightRange?.length === 2) {
        dispatch(setSearchWeightRange(searchWeightRange));
      }

      if (searchAgeRange?.length === 2) {
        dispatch(setSsearchAgeRange(searchAgeRange));
      }
    }

    setIsInit(true);
  }, []);

  useEffect(() => {
    if (!isShowFilterPopup && isInit) {
      getListValue(true);
    }
  }, [isShowFilterPopup, isInit]);

  useEffect(() => {
    if (isInit) {
      getListValue(true);
    }
  }, [searchKeyword]);

  return (
    <main>
      <div className="con">
        <div className="spotlight-wrap">
          <h2>SEARCH RESULT</h2>
          <div className="filter-value">
            {/* <!-- 적용된 필터 없을경우 --> */}
            {isShowFilterArea() ? (
              <div className="wrap-1">
                <span>no filter</span>
              </div>
            ) : (
              <>
                {/* <!-- 적용된 필터 있을경우 --> */}
                <div className="wrap-2">
                  {searchCountryCodeList && searchCountryCodeList.length > 0 ? (
                    <div className="value-wrap value-1">
                      <p>Nationality</p>
                      <div className="value">
                        {searchCountryCodeList.map(
                          (item: any, index: number) => (
                            <span key={`country-name-${index}`}>
                              {getCurrentLangName(item.names)}
                            </span>
                          )
                        )}
                      </div>
                    </div>
                  ) : null}

                  {searchGenderTypeCode ? (
                    <div className="value-wrap value-2">
                      <p>Gender</p>
                      <div className="value">
                        <span>
                          {searchGenderTypeCode === "genderType:boy"
                            ? "Boy"
                            : "Girl"}
                        </span>
                      </div>
                    </div>
                  ) : null}

                  {!(
                    searchHeightRange[0] === 140 && searchHeightRange[1] === 200
                  ) ? (
                    <div className="value-wrap value-3">
                      <p>Height</p>
                      <div className="value">
                        <span>{searchHeightRange[0]}cm</span>
                        <span className="space">~</span>
                        <span>{searchHeightRange[1]}cm</span>
                      </div>
                    </div>
                  ) : null}

                  {!(
                    searchWeightRange[0] === 40 && searchWeightRange[1] === 100
                  ) ? (
                    <div className="value-wrap value-4">
                      <p>Weight</p>
                      <div className="value">
                        <span>{searchWeightRange[0]}kg</span>
                        <span className="space">~</span>
                        <span>{searchWeightRange[1]}kg</span>
                      </div>
                    </div>
                  ) : null}

                  {!(searchAgeRange[0] === 0 && searchAgeRange[1] === 30) ? (
                    <div className="value-wrap value-5">
                      <p>Age</p>
                      <div className="value">
                        <span>{searchAgeRange[0]}</span>
                        <span className="space">~</span>
                        <span>{searchAgeRange[1]}</span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </div>
          <div className="spotlight">
            {list && !isLoading ? (
              <>
                {list.length === 0 ? (
                  <div className="no-filter">
                    <p>검색 결과가 없습니다.</p>
                  </div>
                ) : (
                  <ul>
                    {list
                      ? list.map((item: any, index: number) => (
                          <StudentCardComponent
                            key={`search-${index}`}
                            item={item}
                            changeFavoriteFunc={changeIsFavorite}
                            disableFavoriteButton={disableFavoriteButton}
                          />
                        ))
                      : null}
                  </ul>
                )}
              </>
            ) : (
              <LoadingComponent />
            )}
          </div>
        </div>
      </div>
    </main>
  );
};
export default SearchComponent;
