import { PAGE_TYPE } from "enums";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getLanguageValue, getCurrentLanguage } from "locales/i18n";
import LoadingComponent from "commons/loading/Loading.component";
import React from "react";

const MessageListComponent = (props: any) => {
  const {
    request,
    channels,
    navigate,
    denyRequest,
    acceptRequest,
    handleLiClick,
    isPopup,
    popupItem,
    isRequestShowLoading,
    isChannelShowLoading,
  } = props;

  const lastMessageValue = (message: any) => {
    try {
      const obj = JSON.parse(message);

      return `(IMAGE) ${obj.userStudentName}`;
    } catch {
      return message;
    }
  };

  return (
    <main className="message-home">
      <div className="con">
        <h2>{getLanguageValue(PAGE_TYPE.SCHOOL, "messageList.txtMessage")}</h2>

        <div className="request-list">
          <div className="title">
            <img src="./imgs/icons/message.png" alt="message icon" />
            <p>
              {getLanguageValue(PAGE_TYPE.SCHOOL, "messageList.txtRequest")}
              <em>({request ? request.length : 0})</em>
            </p>
          </div>

          {isRequestShowLoading ? (
            <LoadingComponent />
          ) : (
            <ul className="list">
              {request
                ? request.map((item: any) => (
                    <li key={item.id} onClick={() => handleLiClick(item)}>
                      <div
                        className={`agency-logo`}
                        style={{
                          backgroundImage: `url(${
                            item.userAgencyLogo
                              ? `${
                                  process.env
                                    .REACT_APP_PUBLIC_RESOURCE_URL_PREFIX
                                }${item.userAgencyLogo.replace(/ /g, "%20")}`
                              : "./imgs/icons/agency-logo-default.png"
                          }?w=220&h=220)`,
                        }}></div>
                      <div className="agency-name ellipsis">
                        {item.userAgencyName}
                      </div>
                    </li>
                  ))
                : null}
            </ul>
          )}
        </div>

        <div className="message-list">
          <div className="title">
            <p>
              {getLanguageValue(PAGE_TYPE.SCHOOL, "messageList.txtList")}
              <em>({channels ? channels.length : 0})</em>
            </p>
          </div>
          {isChannelShowLoading ? (
            <LoadingComponent />
          ) : channels && channels.length > 0 ? (
            channels.map((channel: any, index: number) => (
              <div
                key={index}
                className={`list list-${index + 1}`}
                onClick={(e: any) => {
                  e.preventDefault();
                  navigate(`/school/message/${channel.channelId}`, {
                    state: {
                      companyName: channel.userAgencyName,
                      userStudentId: channel.userStudentId,
                      userStudentName: `${channel.userStudentFirstName} ${channel.userStudentLastName}`,
                    },
                  });
                }}>
                <p className="school-name ellipsis">
                  {channel.userAgencyName} <span>{channel.country}</span>
                </p>
                {channel.lastMessageStatus === "read" ||
                channel.lastMessageStatus === "sent" ? (
                  <p className="situation">{channel.lastMessageStatus}</p>
                ) : (
                  <p className="situation not-read">
                    {channel.lastMessageStatus}
                    <span>({channel.lastMessageUnReadCount})</span>
                  </p>
                )}

                <div className="text ellipsis">
                  {lastMessageValue(channel.lastMessage)}
                </div>
                <p className="date-time">
                  {getFormattedDateValue(
                    channel.lastMessageCreatedAt,
                    "YYYY.MM.DD HH:mm:ss"
                  )}
                </p>
              </div>
            ))
          ) : (
            <div className="no-filter">
              <p>
                {getLanguageValue(PAGE_TYPE.SCHOOL, "messageList.txtNoMessage")}
              </p>
            </div>
          )}
        </div>
      </div>

      {isPopup ? (
        <div className="popup popup-request-receive">
          <div className="con">
            <div className="wrap">
              <div
                className="close-btn"
                onClick={() => handleLiClick(popupItem)}></div>
              <h2>
                {getLanguageValue(PAGE_TYPE.SCHOOL, "messageList.txtRequest")}
              </h2>
              <div className="info">
                <div className="item item-agency">
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url(
                        
                        ${
                          popupItem.userAgencyLogo
                            ? process.env.REACT_APP_PUBLIC_RESOURCE_URL_PREFIX +
                              popupItem.userAgencyLogo.replace(/ /g, "%20")
                            : "./imgs/icons/agency-logo-default.png"
                        }?w=240&h=240)`,
                    }}></div>
                  <div className="name">
                    <p>{popupItem.castingManagerName}</p>
                    <p>({popupItem.userAgencyName})</p>
                  </div>
                </div>
                <div className="item item-wannabe">
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url(${
                        popupItem.userStudentProfileImage
                          ? process.env.REACT_APP_PUBLIC_RESOURCE_URL_PREFIX +
                            popupItem.userStudentProfileImage.replace(
                              / /g,
                              "%20"
                            )
                          : "./imgs/dummy/basic-img-2.png"
                      }?w=240&h=240)`,
                    }}></div>
                  <div className="name">
                    <p style={{ padding: "5px 0" }}>
                      {popupItem.userStudentFirstName}{" "}
                      {popupItem.userStudentLastName}
                    </p>
                  </div>
                </div>
              </div>
              <p className="text">
                <span>
                  {popupItem.castingManagerName} ({popupItem.userAgencyName})
                </span>
                {getLanguageValue(
                  PAGE_TYPE.SCHOOL,
                  "messageList.txtRequestMessage"
                )}
                <span>
                  {`${popupItem.userStudentFirstName} ${popupItem.userStudentLastName}`}
                </span>
                {getCurrentLanguage() === "ko" || getCurrentLanguage() === "ja"
                  ? getLanguageValue(
                      PAGE_TYPE.SCHOOL,
                      "messageList.txtRequestMessage2"
                    )
                  : "."}
              </p>
              <div className="btn">
                <button
                  onClick={() => {
                    denyRequest(popupItem);
                  }}>
                  {getLanguageValue(
                    PAGE_TYPE.SCHOOL,
                    "messageList.txtDenyRequest"
                  )}
                </button>
                <button
                  className="deny-btn"
                  onClick={() => {
                    acceptRequest(popupItem);
                  }}>
                  {getLanguageValue(
                    PAGE_TYPE.SCHOOL,
                    "messageList.txtAceeptRequest"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </main>
  );
};

export default MessageListComponent;
