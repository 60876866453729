import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import * as SchoolApi from "api/school/School.api";
import SchoolSignupDoneComponent from "./SchoolSignupDone.component";

const SchoolSignupDoneContainer = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [accountResult, setAccountResult] = useState(
    location.state?.accountResult
  );
  const [schoolParams, setSchoolParams] = useState(
    location.state?.schoolParams
  );
  const [schoolInfo, setSchoolInfo] = useState<any>({});
  const [schoolId, setSchoolId] = useState(location.state?.id);
  const studentSignUpUrl = `${process.env.REACT_APP_DOMAIN}/signup/student?school-id=${schoolId}`;
  const getInfo = async () => {
    const result = await SchoolApi.getInfo(schoolId);

    if (result?.schoolInfo) {
      setSchoolInfo(result.schoolInfo);
    }
  };

  const share = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "WANNABES",
          url: studentSignUpUrl,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      var url = "";
      var textarea = document.createElement("textarea");
      document.body.appendChild(textarea);
      url = studentSignUpUrl;
      textarea.value = url;
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.alertMessage"));
    }
  };

  useEffect(() => {
    if (!accountResult) {
      //navigate("/");
    }
    getInfo();
  }, []);

  return (
    <SchoolSignupDoneComponent
      schoolInfo={schoolInfo}
      studentSignUpUrl={studentSignUpUrl}
      share={share}
    />
  );
};

export default SchoolSignupDoneContainer;
