import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import {
  checkIsEmail,
  checkPassword,
  checkPhoneNumber,
} from "helpers/Reg.helper";
import { removeAuthTokens, setAccessToken } from "helpers/Auth.helper";
import * as ImageHelper from "helpers/Image.helper";

import * as AuthApi from "api/common/Auth.api";
import * as DataApi from "api/common/Data.api";

import SchoolSignupComponent from "./SchoolSignup.component";

const SchoolSignupContainer = (props: any) => {
  const btnRef = useRef<any>();
  const navigate = useNavigate();
  const { cancelSignup } = props;

  const [termList, setTermList] = useState([]);

  const [imageInfo, setImageInfo] = useState<any>({});
  const [fileInfo, setFileInfo] = useState<any>();
  const [isOnNetworking, setIsOnNetworking] = useState(false);

  const [accountParams, setAccountParams] = useState<any>({
    username: "",
    password: "",
    confirmedPassword: "",
    accountTypeCode: "accountType:school",
  });

  const [schoolParams, setSchoolParams] = useState<any>({
    logoImageId: null,
    schoolName: "",
    businessRegistrationFileId: null,
    representative: "",
    countryCode: "",
    city: "",
    address1: "",
    address2: "",
    zipcode: "",
    telephone: "",
    homepageUrl: "",
    contactEmail: "",
    cellphone: "",
  });

  const [snsInfo, setSnsInfo] = useState<any>();

  const getTermList = async () => {
    const result = await AuthApi.getTermListByType({ accountType: "school" });

    if (result?.list) {
      result.list.map((item: any) => (item.checked = false));
      setTermList(result.list);
    }
  };

  const onChangeInput = (componentName: string, value: any) => {
    console.log("SchoolSignupContainer:onChangeInput", componentName, value);

    // 계정 정보
    if (componentName === "username") {
      setAccountParams({ ...accountParams, username: value.trim() });
    } else if (componentName === "password") {
      setAccountParams({ ...accountParams, password: value });
    }
    if (componentName === "confirmedPassword") {
      setAccountParams({ ...accountParams, confirmedPassword: value });
    }

    // 기본 정보
    if (componentName === "schoolName") {
      setSchoolParams({ ...schoolParams, schoolName: value });
    } else if (componentName === "representative") {
      setSchoolParams({ ...schoolParams, representative: value });
    } else if (componentName === "countryCode") {
      setSchoolParams({ ...schoolParams, countryCode: value });
    } else if (componentName === "city") {
      setSchoolParams({ ...schoolParams, city: value });
    } else if (componentName === "address1") {
      setSchoolParams({ ...schoolParams, address1: value });
    } else if (componentName === "address2") {
      setSchoolParams({ ...schoolParams, address2: value });
    } else if (componentName === "zipcode") {
      setSchoolParams({ ...schoolParams, zipcode: value });
    } else if (componentName === "telephone") {
      setSchoolParams({ ...schoolParams, telephone: value });
    } else if (componentName === "homepageUrl") {
      setSchoolParams({ ...schoolParams, homepageUrl: value });
    } else if (componentName === "contactEmail") {
      setSchoolParams({ ...schoolParams, contactEmail: value });
    } else if (componentName === "cellphone") {
      setSchoolParams({ ...schoolParams, cellphone: value });
    }

    // sns 정보
    if (componentName === "instagram") {
      setSnsInfo({ ...snsInfo, instagram: value });
    } else if (componentName === "youtube") {
      setSnsInfo({ ...snsInfo, youtube: value });
    } else if (componentName === "twitter") {
      setSnsInfo({ ...snsInfo, twitter: value });
    } else if (componentName === "facebook") {
      setSnsInfo({ ...snsInfo, facebook: value });
    } else if (componentName === "tiktok") {
      setSnsInfo({ ...snsInfo, tiktok: value });
    }
  };

  const onChangeCheckbox = (checkedItem: any, checked: boolean) => {
    setTermList((prev: any) => {
      const termsList = [...prev];
      checkedItem === "all"
        ? termsList.map((item: any) => (item.checked = checked))
        : (termsList[+checkedItem].checked = checked);

      return termsList;
    });
  };

  const uploadImage = async (evt: any) => {
    setIsOnNetworking(true);

    if (!evt) {
      return false;
    }

    const file = evt.target.files[0];
    const imageInfo = await ImageHelper.getImageInfo(file);
    if (!imageInfo) {
      alert("Please choose image file");
      return false;
    }

    const result = await DataApi.uploadImage(file);
    setImageInfo({ id: result.id, url: imageInfo.url });

    setIsOnNetworking(false);
  };

  const uploadFile = async (evt: any) => {
    setIsOnNetworking(true);

    if (!evt) {
      return false;
    }

    const file = evt.target.files[0];

    const result = await DataApi.uploadFile(file);
    setFileInfo({ id: result.id, name: file.name });

    setIsOnNetworking(false);
  };

  const isDisabled = () => {
    if (!accountParams.username) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errEmail"));
      return true;
    } else if (!checkIsEmail(accountParams.username)) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errInvalidEmail"));
    } else if (!accountParams.password) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errPassword"));
      return true;
    } else if (!checkPassword(accountParams.password)) {
      alert();
      return true;
    } else if (!accountParams.confirmedPassword) {
      alert(
        getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errConfirmPassword")
      );
      return true;
    } else if (accountParams.password !== accountParams.confirmedPassword) {
      alert(
        getLanguageValue(
          PAGE_TYPE.COMMON,
          "schoolSignup.errPasswordAndConfirmPasswordMatch"
        )
      );
      return true;
    } else if (!imageInfo?.id) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errLogoUpload"));
      return true;
    } else if (!schoolParams.schoolName) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errSchoolName"));
      return true;
    } else if (!schoolParams.representative) {
      alert(
        getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errRepresentative")
      );
      return true;
    } else if (!schoolParams.countryCode) {
      alert(
        getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errCountrySelection")
      );
      return true;
    } else if (!schoolParams.city) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errCity"));
      return true;
    } else if (!schoolParams.address1) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errAddress1"));
      return true;
    } else if (!schoolParams.address2) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errAddress2"));
      return true;
    } else if (!schoolParams.zipcode) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errZipcode"));
      return true;
    } else if (!schoolParams.telephone) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errTelephone"));
      return true;
    } else if (!schoolParams.homepageUrl) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errHomepageUrl"));
      return true;
    } else if (!schoolParams.cellphone) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errCellphone"));
      return true;
    } else if (!schoolParams.contactEmail) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errEmail"));
      return true;
    } else if (!checkIsEmail(schoolParams.contactEmail)) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errInvalidEmail"));
      return true;
    } else if (!termList[0].checked) {
      alert(
        getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errAgreeTermOfService")
      );
      return true;
    } else if (!termList[1].checked) {
      alert(
        getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errAgreePrivacyPolicy")
      );
      return true;
    } else {
      return false;
    }
  };

  const createInfo = async () => {
    if (!isDisabled()) {
      btnRef.current.disabled = true;

      accountParams.termsOfService = true;
      accountParams.personalPolicy = true;
      accountParams.receiveEmail = termList[2].checked;
      accountParams.receiveMessage = termList[3].checked;

      // 계정 생성
      const accountResult = await AuthApi.signupByEmail(accountParams);

      if (accountResult) {
        setAccessToken(accountResult?.data?.data?.jwt);

        schoolParams.logoImageId = imageInfo.id;
        if (fileInfo) {
          schoolParams.businessRegistrationFileId = fileInfo.id;
        }

        schoolParams.snsInfo = snsInfo;

        // 학교 정보 생성
        const schoolResult = await AuthApi.createInfo("school", schoolParams);

        if (schoolResult) {
          btnRef.current.disabled = false;
          removeAuthTokens();

          //navigate("/signup/school/done", state: { page, prev: page, status });
          navigate("/signup/school/done", {
            state: {
              accountResult,
              schoolParams,
              id: schoolResult.data.data.insertedId,
            },
          });
        }
      } else {
        btnRef.current.disabled = false;
      }
    }
  };

  useEffect(() => {
    getTermList();
  }, []);

  return (
    termList.length > 0 && (
      <SchoolSignupComponent
        btnRef={btnRef}
        accountParams={accountParams}
        schoolParams={schoolParams}
        imageInfo={imageInfo}
        fileInfo={fileInfo}
        termList={termList}
        isOnNetworking={isOnNetworking}
        uploadImage={uploadImage}
        uploadFile={uploadFile}
        onChangeInput={onChangeInput}
        onChangeCheckbox={onChangeCheckbox}
        onClickBtnCancelSignup={cancelSignup}
        onClickBtnSignup={createInfo}
      />
    )
  );
};

export default SchoolSignupContainer;
