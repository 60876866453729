import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect } from "react";

const PasswordResetComponent = (props: any) => {
  const { email, setEmail, resetPassword, btnRef } = props;

  return (
    <main className="find-password">
      <div className="con">
        <h2>
          {getLanguageValue(PAGE_TYPE.COMMON, "passwordReset.txtFindPassword")}
        </h2>

        <div className="form">
          <div className="input-box">
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(evt) => setEmail(evt.target.value)}
            />
          </div>
          <p>
            *
            {getLanguageValue(
              PAGE_TYPE.COMMON,
              "passwordReset.txtPasswordResetLink"
            )}
          </p>
        </div>

        <div className="btn">
          <div>
            <button ref={btnRef} onClick={resetPassword}>
              {getLanguageValue(
                PAGE_TYPE.COMMON,
                "passwordReset.btnPasswordResetEmailSent"
              )}
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};
export default PasswordResetComponent;
