import React, { useMemo } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChartComponent = (props: any) => {
  const { labels, datasets, title, titlePosition, type, min, max, stepSize } =
    props;

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: titlePosition,
        display: false,
      },
      title: {
        display: !!title,
        text: title,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        ticks: { font: { size: 13 } },
        grid: {
          display: false,
        },
        border: { display: false },
      },
      y: {
        min,
        max,
        ticks: {
          callback: (value: any) => {
            if (type === "evaluation") {
              if (value === 10) {
                return "S";
              } else if (value === 9) {
                return "A";
              } else if (value === 8) {
                return "B";
              } else if (value === 7) {
                return "C";
              } else if (value === 6) {
                return "D";
              } else if (value === 5) {
                return "F";
              }
            } else {
              return Math.round(value);
            }
          },
          stepSize,
        },
        grid: {
          display: true,
          ticks: {
            stepSize: 1,
          },
        },
        border: { display: false },
      },
    },
    elements: {
      bar: { borderWidth: 1.7 },
    },
  };

  const data = useMemo(
    () => ({
      labels,
      datasets,
    }),
    [labels, datasets]
  );

  return (
    <div>
      <Bar options={options} data={data} style={{ height: "260px" }} />
    </div>
  );
};

BarChartComponent.propTypes = {
  labels: PropTypes.array.isRequired,
  datasets: PropTypes.array.isRequired,
  title: PropTypes.string,
  titlePosition: PropTypes.string.isRequired,
  type: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  stepSize: PropTypes.number,
};

export default BarChartComponent;
