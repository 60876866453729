import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as commonData from "data/common.data";
import { getCurrentLangName } from "helpers/String.helper";

const StudentEvaluationComponent = (props: any) => {
  const {
    onClose,
    evaluationDataArray,
    onChangeInput,
    onClickBtnSave,
    isOnNetworking,
  } = props;

  return (
    <div className="popup popup-student-home">
      <div className="con">
        <div className="wrap">
          <div className="close-btn" onClick={onClose}></div>
          <h2>
            {getLanguageValue(
              PAGE_TYPE.SCHOOL,
              "studentEvaluation.txtEvaluationTitle"
            )}
          </h2>
          <p>
            {getLanguageValue(
              PAGE_TYPE.SCHOOL,
              "studentEvaluation.txtSubTitle"
            )}
          </p>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <td className="item">
                    <p>
                      {getLanguageValue(
                        PAGE_TYPE.SCHOOL,
                        "studentEvaluation.txtItems"
                      )}
                    </p>
                  </td>
                  <td className="today-evaluation">
                    <p>
                      {getLanguageValue(
                        PAGE_TYPE.SCHOOL,
                        "studentEvaluation.txtCurrentEvaluation"
                      )}
                    </p>
                  </td>
                  <td className="date">
                    <p>
                      {getLanguageValue(
                        PAGE_TYPE.SCHOOL,
                        "studentEvaluation.txtEvaluationDate"
                      )}
                    </p>
                  </td>
                  <td className="evaluation">
                    <p>
                      {getLanguageValue(
                        PAGE_TYPE.SCHOOL,
                        "studentEvaluation.txtEvaluation"
                      )}
                    </p>
                  </td>
                  <td className="apply">
                    <p>
                      {getLanguageValue(
                        PAGE_TYPE.SCHOOL,
                        "studentEvaluation.txtApply"
                      )}
                    </p>
                  </td>
                </tr>
              </thead>
              <tbody>
                {evaluationDataArray.map((category: any) => (
                  <tr key={category.code}>
                    <td className="item date">
                      <p>{getCurrentLangName(category.names)}</p>
                      {/* <label className="mb">
                        <input
                          type="date"
                          onChange={(evt) =>
                            onChangeInput(
                              "evaluationDate",
                              evt.target.value,
                              category.code
                            )
                          }
                          data-placeholder="YYYY-MM-DD"
                          aria-required="true"
                          value={category.evaluationDate}
                        />
                      </label> */}
                    </td>
                    <td className="today-evaluation">
                      <p>{category.evaluationStatus}</p>
                      {/* <select
                        className="evaluation-box mb"
                        id="evaluation-box"
                        name="evaluation-box"
                        required
                        onChange={(evt) =>
                          onChangeInput(
                            "scoreCode",
                            evt.target.value,
                            category.code
                          )
                        }
                        value={category.evaluation}>
                        <option value="" disabled selected>
                          {getLanguageValue(
                            PAGE_TYPE.SCHOOL,
                            "studentEvaluation.txtChoose"
                          )}
                        </option>
                        <option value="cancel">
                          {getLanguageValue(
                            PAGE_TYPE.SCHOOL,
                            "studentEvaluation.txtCancel"
                          )}
                        </option>
                        {commonData
                          .getScoreCodeList()
                          .map((item: any, idx: number) => (
                            <option key={idx} value={item.code}>
                              {item.names.en}
                            </option>
                          ))}
                      </select> */}
                    </td>
                    <td className="date pc">
                      <label>
                        <input
                          type="date"
                          onChange={(evt) =>
                            onChangeInput(
                              "evaluationDate",
                              evt.target.value,
                              category.code
                            )
                          }
                          data-placeholder="YYYY-MM-DD"
                          aria-required="true"
                          value={category.evaluationDate}
                        />
                      </label>
                    </td>
                    <td className="evaluation pc">
                      <select
                        className="evaluation-box"
                        id="evaluation-box"
                        name="evaluation-box"
                        required
                        onChange={(evt) =>
                          onChangeInput(
                            "scoreCode",
                            evt.target.value,
                            category.code
                          )
                        }
                        value={category.evaluation}>
                        <option value="" disabled selected>
                          {getLanguageValue(
                            PAGE_TYPE.SCHOOL,
                            "studentEvaluation.txtChoose"
                          )}
                        </option>
                        <option value="cancel">
                          {getLanguageValue(
                            PAGE_TYPE.SCHOOL,
                            "studentEvaluation.txtCancel"
                          )}
                        </option>
                        {commonData
                          .getScoreCodeList()
                          .map((item: any, idx: number) => (
                            <option key={idx} value={item.code}>
                              {item.names.en}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td className="apply">
                      <button
                        onClick={(e) => {
                          onClickBtnSave(e, category);
                        }}
                        disabled={isOnNetworking}>
                        {getLanguageValue(
                          PAGE_TYPE.SCHOOL,
                          "studentEvaluation.txtApply"
                        )}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button className="btn" onClick={onClose}>
            {getLanguageValue(PAGE_TYPE.SCHOOL, "studentEvaluation.txtClose")}
          </button>
        </div>
      </div>
    </div>
  );
};
export default StudentEvaluationComponent;
