import React, { useEffect, useState } from "react";
import HomeComponent from "./Home.component";

import { getCommonCodeListByParent } from "data/common.data";
import { getFormattedDateValue } from "helpers/Datetime.helper";

import * as StudentApi from "api/student/Student.api";

const HomeContainer = (props: any) => {
    const [info, setInfo] = useState({});
    const evaluationCategory = getCommonCodeListByParent("evaluationCategory");

    // graph
    const [favoriteCntInfo, setFavoriteCntInfo] = useState({
        labels: [],
        data: [],
        isLoading: true,
        isDisabled: false,
        max: 0,
    });
    const [viewCntInfo, setViewCntInfo] = useState({
        labels: [],
        data: [],
        isLoading: true,
        isDisabled: false,
        max: 0,
    });
    const moment = require("moment");
    const [date, setDate] = useState({
        favorite: moment().format("YYYY-MM-DD"),
        view: moment().format("YYYY-MM-DD"),
    });

    const [isUnder14, setIsUnder14] = useState(false);
    const [parentEmail, setParentEmail] = useState("");

    const [isLoading, setIsLoading] = useState(true);

    const getInfo = async () => {
        const studentInfo = await StudentApi.getInfoForStudent();

        if (studentInfo?.info) {
            const info = studentInfo.info;
            setIsUnder14(info.under14 && !info.isParentConfirm);
            setParentEmail(info.parentEmail);
        }

        const result = await StudentApi.getInfoForHome();

        if (result?.info) {
            setInfo(result.info);
        }

        setIsLoading(false);
    };

    const getFavoriteOrViewCount = async (type: string) => {
        type === "favorite" ? setFavoriteCntInfo({ ...favoriteCntInfo, isDisabled: true }) : setViewCntInfo({ ...viewCntInfo, isDisabled: true });

        const result =
            type === "favorite" ? await StudentApi.getFavoriteCntList({ date: date.favorite }) : await StudentApi.getViewCntList({ date: date.view });

        const labels: string[] = [];
        const data: number[] = [];

        if (result?.list) {
            result.list.map((item: any) => {
                data.push(item.count);
                labels.push(getFormattedDateValue(item.date, "M.D"));
            });
        }

        const max = (num: number) => {
            while (num === 0 || num % 4 > 0) {
                num = num + 1;
            }
            return num;
        };

        const params = {
            labels,
            data,
            isLoading: false,
            isDisabled: false,
            max: max(Math.max(...data)),
        };
        type === "favorite" ? setFavoriteCntInfo(params) : setViewCntInfo(params);
    };

    const dataset = (data: any) => {
        return {
            data,
            borderColor: "#CF3688",
            pointBackgroundColor: "#CF3688",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            pointRadius: 2.5,
            clip: {
                top: false,
                bottom: false,
                right: false,
                left: false,
            },
        };
    };

    useEffect(() => {
        getFavoriteOrViewCount("favorite");
    }, [date.favorite]);

    useEffect(() => {
        getFavoriteOrViewCount("view");
    }, [date.view]);

    useEffect(() => {
        getInfo();
    }, []);

    return (
        <HomeComponent
            info={info}
            evaluationCategory={evaluationCategory}
            favoriteCntInfo={favoriteCntInfo}
            viewCntInfo={viewCntInfo}
            date={date}
            setDate={setDate}
            dataset={dataset}
            isLoading={isLoading}
            isUnder14={isUnder14}
            parentEmail={parentEmail}
        />
    );
};

export default HomeContainer;
