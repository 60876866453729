import * as BaseApi from "../Base.api";

export const getList = async (params: any) => {
  const url = "/school/evaluation";
  return await BaseApi.get(url, params);
};

export const getListByStudentId = async (params: any) => {
  const url = "/school/evaluation/student";
  return await BaseApi.get(url, params);
};

export const getAllListByStudentId = async (params: any) => {
  const url = "/school/evaluation/student/all";
  return await BaseApi.get(url, params);
};

export const getInfo = async (id: number, params: any) => {
  const url = `/school/evaluation/info/${id}`;
  return await BaseApi.get(url, params);
};

export const getRecentyInfo = async (id: number, params: any) => {
  const url = `/school/evaluation/recently/${id}`;
  return await BaseApi.get(url, params);
};

export const createInfo = async (params: any) => {
  const url = "/school/evaluation";
  return await BaseApi.post(url, params);
};

export const updateInfo = async (id: number, params: any) => {
  const url = `/school/evaluation/${id}`;
  return await BaseApi.put(url, params);
};

export const deleteInfo = async (id: number) => {
  const url = `/school/evaluation/${id}`;
  return await BaseApi.remove(url);
};
