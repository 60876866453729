import React, { useEffect, useState } from "react";
import { getTermList, getTermInfo } from "api/common/Auth.api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TermComponent from "./Term.component";

const TermContainer = (props: any) => {
  const [termList, setTermList] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isInit, setIsInit] = useState(false);
  const [info, setInfo] = useState(null);

  const getTermListValue = async () => {
    const result = await getTermList({});

    console.log(result);
    if (result) {
      const list = result.list.filter((item: any) => item !== null) 
      setTermList(list);
      setIsInit(true);
    }
  };

  const getInfo = () => {
    const item = termList.find((item: any) => item?.id === Number(params.id));

    if (item) {
      setInfo(item);
    } else {
      navigate("/");
    }
  };

  const getTermInfoValue = async () => {
    const result = await getTermInfo(Number(params.id));

    console.log(result);

    if (result) {
      setInfo(result?.info);
    }
  };

  useEffect(() => {
    console.log(location);

    if (location.pathname.includes("common")) {
      getTermListValue();
    } else {
      getTermInfoValue();
    }
  }, []);

  useEffect(() => {
    if (isInit) {
      getInfo();
    }
  }, [isInit, params]);

  return <TermComponent termList={termList} info={info} {...props} />;
};

export default TermContainer;
