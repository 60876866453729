import React, { useEffect, useRef, useState } from "react";
import AuditionApplicantComponent from "./AuditionApplicant.component";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import * as AuditionApi from "api/agency/Audition.api";
import * as StudentApi from "api/student/Student.api";
import * as MessageApi from "api/message/Message.api";
import * as PortFolioApi from "api/school/Portfolio.api";
import * as DataApi from "api/common/Data.api";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const AuditionApplicantContainer = (props: any) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [auditionApplicantInfo, setAuditionApplicantInfo] = useState([] as any);
  const [isShowLoading, setIsShowLoading] = useState(true);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [initialSlideIdx, setInitialSlideIdx] = useState(0);
  const [status, setStatus] = useState(location.state?.status);
  const [memo, setMemo] = useState("");
  const btnRef = useRef<any>();
  //const [result, setResult] = useState("");
  // message
  const [messageRequest, setMessageRequest] = useState<any>({});
  // portfolio
  const [portfolioList, setPortfolioList] = useState([]);
  const [isShowTaggedStudentPopup, setIsShowTaggedStudentPopup] =
    useState(false);
  const [taggedStudentPopupInfo, setTaggedStudentPopupInfo] = useState({});
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [isShowVideoPopup, setIsShowVideoPopup] = useState("");
  const [selectedVideoId, setSelectedVideoId] = useState("");

  const sliderRef = useRef<any>(null);

  const getApplicantInfo = async () => {
    const result = await AuditionApi.getApplicantInfo(parseInt(id), {});

    if (result?.info) {
      if (result?.info) {
        if (result.info.profileImageInfo) {
          const profileInfo = await DataApi.getImagePresignedUrl(
            result.info.profileImageInfo.id
          );
          if (profileInfo) {
            result.info.profileImageInfo.url = profileInfo.result;
          }
        }

        if (result.info.imageInfoList && result.info.imageInfoList.length > 0) {
          const promises = result.info.imageInfoList.map(
            async (imageInfo: any) => {
              const imageInfoResult = await DataApi.getImagePresignedUrl(
                imageInfo.id
              );
              if (imageInfoResult) {
                imageInfo.url = imageInfoResult.result;
              }
            }
          );

          // 모든 비동기 작업이 완료될 때까지 기다립니다.
          await Promise.all(promises);
        }

        if (result.info.videoInfoList && result.info.videoInfoList.length > 0) {
          const promises = result.info.videoInfoList.map(
            async (videoInfo: any) => {
              const videoInfoResult = await DataApi.getVideoPresignedUrl(
                videoInfo.id
              );
              if (videoInfoResult) {
                videoInfo.url = videoInfoResult.result;
              }
            }
          );

          // 모든 비동기 작업이 완료될 때까지 기다립니다.
          await Promise.all(promises);
        }

        setAuditionApplicantInfo(result.info);
      }

      setAuditionApplicantInfo(result.info);
      //setResult(result.info.result);
      setMemo(result.info.memo);
      setIsShowLoading(false);
    } else {
      navigate(`/agency/audition`);
    }
  };

  const showImagePopup = async (idx: number) => {
    if (
      auditionApplicantInfo.imageInfoList &&
      auditionApplicantInfo.imageInfoList.length > 0
    ) {
      const promises = auditionApplicantInfo.imageInfoList.map(
        async (imageInfo: any) => {
          const imageInfoResult = await DataApi.getImagePresignedUrl(
            imageInfo.id
          );
          if (imageInfoResult) {
            imageInfo.url = imageInfoResult.result;
          }
        }
      );

      // 모든 비동기 작업이 완료될 때까지 기다립니다.
      await Promise.all(promises);
    }

    setIsShowPopup(true);
    setInitialSlideIdx(idx);
  };

  const getVideoUrl = async (id: string) => {
    if (id) {
      const videoInfo = await DataApi.getVideoPresignedUrl(id);
      if (videoInfo) {
        const url = videoInfo.result;
        return url;
      }
    }
  };

  const onChangeResult = async (result: string) => {
    if (status !== "auditionStatus:done") {
      alert(
        getLanguageValue(
          PAGE_TYPE.AGENCY,
          "auditionApplicant.txtEvaluationGuide"
        )
      );

      return;
    }
    setAuditionApplicantInfo((prev: any) => ({
      ...prev,
      result: result,
    }));
    await AuditionApi.updateResultInfo(parseInt(id), { result });
  };

  const onClickBtnSave = async () => {
    btnRef.current.disabled = true;

    const result = await AuditionApi.updateMemoInfo(parseInt(id), {
      memo,
    });
    if (result) {
      if (result.data?.errorCode) {
        alert(result.data.message);
      }
    }
    btnRef.current.disabled = false;
  };

  const toggleUserLike = async (isLiked: boolean) => {
    if (status === "auditionStatus:evaluationCompleted") {
      return;
    }
    setAuditionApplicantInfo((prev: any) => ({
      ...prev,
      isLiked: isLiked,
    }));
    await AuditionApi.updateLike(parseInt(id), { isLiked });
  };

  const getPortfolioList = async () => {
    const result = await PortFolioApi.getListWithStudentId(
      auditionApplicantInfo.userStudentId
    );
    if (result.list.length > 0) {
      setPortfolioList(result.list);
    }
  };

  const getMessageRequest = async () => {
    const requestInfo = await MessageApi.getRequestMessage(
      auditionApplicantInfo.userStudentId
    );
    if (requestInfo?.info) {
      setMessageRequest(requestInfo.info);
    }
  };

  const createRequest = async () => {
    const result = await MessageApi.createRequest({
      studentId: auditionApplicantInfo.userStudentId,
    });
    if (result) {
      await getMessageRequest();
    }
  };

  const cancelRequest = async () => {
    if (messageRequest) {
      const result = await MessageApi.updateMessageInfo(messageRequest?.id, {
        messageType: "messageType:cancel",
      });
      if (result) {
        await getMessageRequest();
      }
    }
  };

  const changeFavoriteStatus = async (isFavorited: number) => {
    const params = { studentId: auditionApplicantInfo.userStudentId };
    const result = isFavorited
      ? await StudentApi.createFavorite(params)
      : await StudentApi.deleteFavorite(params);

    setAuditionApplicantInfo((prev: any) => ({
      ...prev,
      isFavorited: isFavorited,
    }));
  };

  const init = async () => {
    await getApplicantInfo();
  };

  useEffect(() => {
    if (auditionApplicantInfo.userStudentId) {
      getPortfolioList();
      getMessageRequest();
    }
  }, [auditionApplicantInfo.userStudentId]);
  useEffect(() => {
    init();
  }, [id]);

  return (
    <AuditionApplicantComponent
      id={id}
      auditionApplicantInfo={auditionApplicantInfo}
      isShowLoading={isShowLoading}
      status={status}
      changeFavoriteStatus={changeFavoriteStatus}
      //result={result}
      onChangeResult={onChangeResult}
      memo={memo}
      setMemo={setMemo}
      btnRef={btnRef}
      onClickBtnSave={onClickBtnSave}
      toggleUserLike={toggleUserLike}
      // popup
      isShowPopup={isShowPopup}
      setIsShowPopup={setIsShowPopup}
      sliderRef={sliderRef}
      initialSlideIdx={initialSlideIdx}
      showImagePopup={showImagePopup}
      // message
      messageRequest={messageRequest}
      createRequest={createRequest}
      cancelRequest={cancelRequest}
      //
      portfolioList={portfolioList}
      isShowTaggedStudentPopup={isShowTaggedStudentPopup}
      setTaggedStudentPopupInfo={setTaggedStudentPopupInfo}
      setIsShowTaggedStudentPopup={setIsShowTaggedStudentPopup}
      taggedStudentPopupInfo={taggedStudentPopupInfo}
      //
      selectedVideoUrl={selectedVideoUrl}
      setSelectedVideoUrl={setSelectedVideoUrl}
      isShowVideoPopup={isShowVideoPopup}
      setIsShowVideoPopup={setIsShowVideoPopup}
      setPortfolioList={setPortfolioList}
      getVideoUrl={getVideoUrl}
      selectedVideoId={selectedVideoId}
      setSelectedVideoId={setSelectedVideoId}
    />
  );
};

export default AuditionApplicantContainer;
