import React from "react";

const PaginationComponent = (props: any) => {
  const { pageButtonCount, pagingData, currentPage, changePage } = props;

  return pagingData?.totalPage ? (
    <div className="pagination">
      <table>
        <tbody>
          <tr>
            <td
              className="first"
              style={{
                visibility: parseInt(currentPage) !== 1 ? "visible" : "hidden",
              }}
              onClick={(evt) => {
                if (parseInt(currentPage) !== 1) changePage(1);
              }}>
              <a className="btn-pagination">
                <img
                  src="./imgs/icons/btn-pagination-first.png"
                  alt="go to first page"
                />
              </a>
            </td>
            <td
              className="prev"
              style={{
                visibility: parseInt(currentPage) !== 1 ? "visible" : "hidden",
              }}
              onClick={(evt) => {
                if (parseInt(currentPage) !== 1)
                  changePage(parseInt(currentPage) - 1);
              }}>
              <a className="btn-pagination">
                <img
                  src="./imgs/icons/btn-pagination-prev.png"
                  alt="go to first prev"
                />
              </a>
            </td>
            {pageButtonCount
              ? [...Array(pageButtonCount)].map((item: any, idx: number) => (
                  <td
                    key={`pageBtn-${idx}`}
                    className={`no${
                      parseInt(currentPage) === pagingData.from + idx
                        ? " current"
                        : ""
                    }`}
                    onClick={(evt) => {
                      if (parseInt(currentPage) !== pagingData.from + idx) {
                        changePage(pagingData.from + idx);
                      }
                    }}>
                    <p>{pagingData.from + idx}</p>
                  </td>
                ))
              : null}
            <td
              className="next"
              style={{
                visibility:
                  parseInt(currentPage) !== pagingData.totalPage
                    ? "visible"
                    : "hidden",
              }}
              onClick={(evt) => {
                if (parseInt(currentPage) !== pagingData.totalPage)
                  changePage(parseInt(currentPage) + 1);
              }}>
              <a className="btn-pagination">
                <img
                  src="./imgs/icons/btn-pagination-next.png"
                  alt="go to next page"
                />
              </a>
            </td>
            <td
              className="last"
              style={{
                visibility:
                  parseInt(currentPage) !== pagingData.totalPage
                    ? "visible"
                    : "hidden",
              }}
              onClick={(evt) => {
                if (parseInt(currentPage) !== pagingData.totalPage)
                  changePage(pagingData.totalPage);
              }}>
              <a className="btn-pagination">
                <img
                  src="./imgs/icons/btn-pagination-last.png"
                  alt="go to last page"
                />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : null;
};

export default PaginationComponent;
