import React, { useEffect, useRef, useState } from "react";
import ProfileIntroComponent from "./ProfileIntro.component";
import { useLocation } from "react-router-dom";
import LoadingComponent from "commons/loading/Loading.component";

import * as AgencyApi from "api/agency/Agency.api";
import * as ProfileApi from "api/student/Profile.api";
import * as DataApi from "api/common/Data.api";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const ProfileIntroContainer = (props: any) => {
  const location = useLocation();
  const [introVideoList, setIntroVideoList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const videoRefs = useRef<any[]>([]);
  const [currentTimes, setCurrentTimes] = useState<number[]>([]);
  const [initFlags, setInitFlags] = useState<boolean[]>([]);

  //
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [popupList, setPopupList] = useState([]);
  const [profileMediaType, setProfileMediaType] = useState("");
  const [isAgencyPopup, setIsAgencyPopup] = useState(false);
  const [agencyInfo, setAgencyInfo] = useState(null);

  const getIntroVideoList = async () => {
    const result = await ProfileApi.getListIntroVideo();
    if (result.list) {
      const updatedList = await Promise.all(
        result.list.map(async (video: any) => {
          const videoInfo = await DataApi.getVideoPresignedUrl(video.videoId);
          if (videoInfo) {
            return {
              ...video,
              url: videoInfo.result,
            };
          }
          return video;
        })
      );

      setIntroVideoList(updatedList);
      // Initialize initFlags and currentTimes
      setInitFlags(new Array(updatedList.length).fill(false));
      setCurrentTimes(new Array(updatedList.length).fill(0));
    }
  };

  const deleteIntroVideo = async (id: number) => {
    if (
      window.confirm(
        getLanguageValue(
          PAGE_TYPE.STUDENT,
          "profile.txtDeleteIntroductionVideoConfirmation"
        )
      )
    ) {
      const result = await ProfileApi.deleteIntroVideo(id);
      if (result) {
        await getIntroVideoList();
      }
    }
  };

  const introVideoInit = async () => {
    setIsLoading(true);
    await getIntroVideoList();
    setIsLoading(false);
  };

  const handlePlay = async (index: number) => {
    const videoInfo = introVideoList[index];
    if (!initFlags[index]) {
      const url = await DataApi.getVideoPresignedUrl(videoInfo.videoId);
      if (url) {
        setIntroVideoList((prevList) => {
          const newList = [...prevList];
          newList[index] = {
            ...newList[index],
            url: url.result,
          };
          return newList;
        });

        const video = videoRefs.current[index];
        if (video) {
          video.src = url.result;
          video.currentTime = currentTimes[index];
          video.load();
          video.play();
        }
      }
    }

    initFlags[index] = true;
  };

  const handlePause = (index: number) => {
    const video = videoRefs.current[index];
    if (video) {
      setCurrentTimes((prevTimes) => {
        const newTimes = [...prevTimes];
        newTimes[index] = video.currentTime;
        return newTimes;
      });
      initFlags[index] = false;
    }
  };

  const handleSeeked = (index: number) => {
    const video = videoRefs.current[index];
    if (video) {
      setCurrentTimes((prevTimes) => {
        const newTimes = [...prevTimes];
        newTimes[index] = video.currentTime; // Seeked 후의 시간 저장
        return newTimes;
      });
    }
  };

  const handleEnded = (index: number) => {
    setCurrentTimes((prevTimes) => {
      const newTimes = [...prevTimes];
      newTimes[index] = 0; // Ended 후 currentTime을 0으로 설정
      return newTimes;
    });
  };

  const openViewPopup = async (
    profileMediaId: string,
    profileMediaType: string
  ) => {
    console.log("openViewPopup");
    console.log(profileMediaId);
    console.log(profileMediaType);
    if (profileMediaId) {
      const result = await ProfileApi.getListViewedAgency({
        profileMediaId: profileMediaId,
        profileMediaType: profileMediaType,
      });

      if (result.list) {
        setPopupList(result.list);
      }
      setProfileMediaType(profileMediaType);
      setIsShowPopup(true);
    }
  };

  const openAgencyPopup = async (id: number) => {
    const result = await AgencyApi.getInfo(id);
    if (result.info) {
      setAgencyInfo(result.info);
    }
    setIsAgencyPopup(true);
  };

  useEffect(() => {
    introVideoInit();
  }, []);

  return isLoading ? (
    <LoadingComponent />
  ) : (
    <ProfileIntroComponent
      {...props}
      introVideoList={introVideoList}
      deleteIntroVideo={deleteIntroVideo}
      videoRefs={videoRefs}
      handlePlay={handlePlay}
      handlePause={handlePause}
      handleSeeked={handleSeeked}
      handleEnded={handleEnded}
      //
      isShowPopup={isShowPopup}
      setIsShowPopup={setIsShowPopup}
      popupList={popupList}
      openViewPopup={openViewPopup}
      profileMediaType={profileMediaType}
      //
      isAgencyPopup={isAgencyPopup}
      setIsAgencyPopup={setIsAgencyPopup}
      agencyInfo={agencyInfo}
      openAgencyPopup={openAgencyPopup}
    />
  );
};

export default ProfileIntroContainer;
