import React, { useEffect, useRef, useState } from "react";
import AuditionDetailComponent from "./AuditionDetail.component";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import * as AuditionApi from "api/student/Audition.api";
import * as DataApi from "api/common/Data.api";

const AuditionDetailContainer = (props: any) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isShowLoading, setIsShowLoading] = useState(true);
  const [auditionApplicantInfo, setAuditionApplicantInfo] = useState([] as any);
  const [prev, setPrev] = useState(location.state?.prev || 1);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [initialSlideIdx, setInitialSlideIdx] = useState(0);
  const [selectedVideoId, setSelectedVideoId] = useState("");
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [isShowVideoPopup, setIsShowVideoPopup] = useState(false);
  const btnRef = useRef<any>();
  const sliderRef = useRef<any>(null);

  const [auditionInfo, setAuditionInfo] = useState([]);

  const getAuditionInfo = async () => {
    setIsShowLoading(true);
    const result = await AuditionApi.getAuditionInfo(parseInt(id), {});

    if (result?.info) {
      setAuditionInfo(result.info);
      if (result.info.auditionApplicantId) {
        await getApplicantInfo(result.info);
      }
    } else {
      navigate(`/student/audition`);
    }

    setIsShowLoading(false);
  };

  const getApplicantInfo = async (info: any) => {
    const result = await AuditionApi.getApplicantInfo(
      info.auditionApplicantId,
      {}
    );

    if (result?.info) {
      if (result.info.profileImageInfo) {
        const profileInfo = await DataApi.getImagePresignedUrl(
          result.info.profileImageInfo.id
        );
        if (profileInfo) {
          result.info.profileImageInfo.url = profileInfo.result;
        }
      }

      if (result.info.imageInfoList && result.info.imageInfoList.length > 0) {
        const promises = result.info.imageInfoList.map(
          async (imageInfo: any) => {
            const imageInfoResult = await DataApi.getImagePresignedUrl(
              imageInfo.id
            );
            if (imageInfoResult) {
              imageInfo.url = imageInfoResult.result;
            }
          }
        );

        // 모든 비동기 작업이 완료될 때까지 기다립니다.
        await Promise.all(promises);
      }

      if (result.info.videoInfoList && result.info.videoInfoList.length > 0) {
        const promises = result.info.videoInfoList.map(
          async (videoInfo: any) => {
            const videoInfoResult = await DataApi.getVideoPresignedUrl(
              videoInfo.id
            );
            if (videoInfoResult) {
              videoInfo.url = videoInfoResult.result;
            }
          }
        );

        // 모든 비동기 작업이 완료될 때까지 기다립니다.
        await Promise.all(promises);
      }

      setAuditionApplicantInfo(result.info);
    }
  };

  const showImagePopup = async (idx: number) => {
    if (
      auditionApplicantInfo.imageInfoList &&
      auditionApplicantInfo.imageInfoList.length > 0
    ) {
      const promises = auditionApplicantInfo.imageInfoList.map(
        async (imageInfo: any) => {
          const imageInfoResult = await DataApi.getImagePresignedUrl(
            imageInfo.id
          );
          if (imageInfoResult) {
            imageInfo.url = imageInfoResult.result;
          }
        }
      );

      // 모든 비동기 작업이 완료될 때까지 기다립니다.
      await Promise.all(promises);
    }

    setIsShowPopup(true);
    setInitialSlideIdx(idx);
  };

  const getVideoUrl = async (id: string) => {
    if (id) {
      const videoInfo = await DataApi.getVideoPresignedUrl(id);
      if (videoInfo) {
        const url = videoInfo.result;
        return url;
      }
    }
  };

  useEffect(() => {
    getAuditionInfo();
  }, [id]);

  return (
    <AuditionDetailComponent
      {...props}
      id={id}
      isShowLoading={isShowLoading}
      auditionInfo={auditionInfo}
      auditionApplicantInfo={auditionApplicantInfo}
      prev={prev}
      btnRef={btnRef}
      // popup
      isShowPopup={isShowPopup}
      setIsShowPopup={setIsShowPopup}
      sliderRef={sliderRef}
      initialSlideIdx={initialSlideIdx}
      showImagePopup={showImagePopup}
      selectedVideoUrl={selectedVideoUrl}
      setSelectedVideoUrl={setSelectedVideoUrl}
      isShowVideoPopup={isShowVideoPopup}
      setIsShowVideoPopup={setIsShowVideoPopup}
      getVideoUrl={getVideoUrl}
      selectedVideoId={selectedVideoId}
      setSelectedVideoId={setSelectedVideoId}
    />
  );
};

export default AuditionDetailContainer;
