import React, { useEffect, useRef, useState } from "react";
import HomeComponent from "./Home.component";
import {
  getListForHome as getStudentList,
  createFavorite,
  deleteFavorite,
} from "api/student/Student.api";
import * as PortfolioApi from "api/school/Portfolio.api";

const HomeContainer = (props: any) => {
  const [portfolioList, setPortfolioList] = useState([]);
  const [spotlightStudentList, setSpotlightStudentList] = useState([]);
  const [newStudentList, setNewStudentList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  const [disableFavoriteButton, setDisableFavoriteButton] = useState(true);

  // popup
  const [isShowTaggedStudentPopup, setIsShowTaggedStudentPopup] =
    useState(false);
  const [taggedStudentPopupInfo, setTaggedStudentPopupInfo] = useState({});

  const studentListValue = async () => {
    const result = await getStudentList();

    if (result?.mostViewList) {
      setSpotlightStudentList(result.mostViewList);
    }
    if (result?.newList) {
      setNewStudentList(result.newList);
    }

    setDisableFavoriteButton(false);
    setIsLoading(false);
  };

  const getPortfolioList = async () => {
    const result = await PortfolioApi.getList({ limit: 3 });

    if (result?.list) {
      const updatedList = result.list.map((item: any) => ({
        ...item,
        performedDate: item.performedDate.replaceAll("-", "."),
        firstview: false,
      }));

      setPortfolioList(updatedList);
    }
    setIsLoading2(false);
  };

  const changeIsFavorited = async (studentId: number, type: string) => {
    setDisableFavoriteButton(true);
    const spotlightStudent = spotlightStudentList.find(
      (item: any) => item.id === studentId
    );
    const newStudent = newStudentList.find(
      (item: any) => item.id === studentId
    );

    const isFavorited = spotlightStudent
      ? spotlightStudent.isFavorited
      : newStudent.isFavorited;

    // 바로 화면에서 반영되도록 state 값 수정
    const changeState = (student: any, isFavorited: number) => {
      student.isFavorited = isFavorited;
      student.favoriteCnt = student.favoriteCnt + (isFavorited ? 1 : -1);
    };

    if (spotlightStudent && newStudent) {
      changeState(spotlightStudent, +!spotlightStudent.isFavorited);
      changeState(newStudent, +!newStudent.isFavorited);
    } else {
      const student = spotlightStudent ? spotlightStudent : newStudent;
      changeState(student, +!isFavorited);
    }

    setSpotlightStudentList([...spotlightStudentList]);
    setNewStudentList([...newStudentList]);

    const param = { studentId };
    const result: any = isFavorited
      ? await deleteFavorite(param)
      : await createFavorite(param);

    if (result?.status !== 200) {
      alert(result.data.message);
    }

    studentListValue();
  };

  useEffect(() => {
    studentListValue();
    getPortfolioList();
  }, []);

  return (
    <HomeComponent
      {...props}
      portfolioList={portfolioList}
      spotlightStudentList={spotlightStudentList}
      newStudentList={newStudentList}
      changeIsFavorited={changeIsFavorited}
      isLoading={isLoading}
      isLoading2={isLoading2}
      disableFavoriteButton={disableFavoriteButton}
      // popup
      isShowTaggedStudentPopup={isShowTaggedStudentPopup}
      setIsShowTaggedStudentPopup={setIsShowTaggedStudentPopup}
      taggedStudentPopupInfo={taggedStudentPopupInfo}
      setTaggedStudentPopupInfo={setTaggedStudentPopupInfo}
      setPortfolioList={setPortfolioList}
    />
  );
};

export default HomeContainer;
