import { PAGE_TYPE } from "enums";
import { getCurrentLangName } from "helpers/String.helper";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const MessageAgencyDetailComponent = (props: any) => {
  const navigate = useNavigate();
  const { agencyInfo, isShowPopup, setIsShowPopup, popupItem, setPopupItem } =
    props;

  return (
    <main className="stuent-agency-detail">
      {agencyInfo ? (
        <div className="con">
          <div className="back-btn" onClick={(e) => navigate(-1)}></div>
          <h2>{agencyInfo.companyName}</h2>
          <p className="country">
            (
            {agencyInfo.countryCodeInfo
              ? getCurrentLangName(agencyInfo.countryCodeInfo.names)
              : ""}
            )
          </p>

          <div className="form-wrap">
            <div
              className="agency-logo"
              style={{
                backgroundImage: `url(${
                  agencyInfo.logoImageInfo
                    ? agencyInfo.logoImageInfo.url.replace(/ /g, "%20")
                    : "./imgs/icons/agency-logo-default.png"
                }?w=400&h=400)`,
              }}></div>
            <div className="form form-2">
              <div className="con">
                <h3 className="title-con">
                  {getLanguageValue(
                    PAGE_TYPE.STUDENT,
                    "messageAgencyDetail.txtRepresentaiveName"
                  )}
                </h3>
                <p className="text">{agencyInfo.representative}</p>
              </div>
              <div className="con">
                <h3 className="title-con">
                  {getLanguageValue(
                    PAGE_TYPE.STUDENT,
                    "messageAgencyDetail.txtAddress"
                  )}
                </h3>
                <p className="text">
                  {agencyInfo.address1} {agencyInfo.address2}
                </p>
              </div>
              <div className="con">
                <h3 className="title-con">
                  {getLanguageValue(
                    PAGE_TYPE.STUDENT,
                    "messageAgencyDetail.txtTelephone"
                  )}
                </h3>
                <p className="text">{agencyInfo.telephone}</p>
              </div>
              <div className="con">
                <h3 className="title-con">
                  {getLanguageValue(
                    PAGE_TYPE.STUDENT,
                    "messageAgencyDetail.txtHomepage"
                  )}
                </h3>
                <p className="text">{agencyInfo.homepageUrl}</p>
              </div>
            </div>

            <div className="form">
              <h2>CASTING MANAGER</h2>
              <div
                className="manager-img"
                style={{
                  backgroundImage: `url(${
                    agencyInfo.casterInfo.imageInfo
                      ? agencyInfo.casterInfo.imageInfo.url.replace(/ /g, "%20")
                      : "./imgs/dummy/basic-img-2.png"
                  }?w=360&h=360)`,
                }}></div>
              <div className="con">
                <h3 className="title-con">
                  {getLanguageValue(
                    PAGE_TYPE.STUDENT,
                    "messageAgencyDetail.txtName"
                  )}
                </h3>
                <p className="text">{agencyInfo.casterInfo?.name}</p>
              </div>
            </div>

            <div className="form">
              <h2>SNS</h2>
              <div className="sns-form">
                <div
                  className="sns-con"
                  onClick={(e) => {
                    if (agencyInfo.instagram) {
                      window.open(
                        `https://www.instagram.com/${agencyInfo.instagram}`,
                        "_blank"
                      );
                    }
                  }}>
                  <img src="./imgs/icons/instagram-icon.png" alt="instagram" />

                  {/* <!-- 해당 sns 없을경우 className에 on 추가 --> */}
                  <div
                    className={`dim ${agencyInfo.instagram ? "" : "on"}`}></div>
                  <p className="text">Instagram</p>
                </div>
                <div
                  className="sns-con"
                  onClick={(e) => {
                    if (agencyInfo.facebook) {
                      window.open(
                        `https://www.facebook.com/${agencyInfo.facebook}`,
                        "_blank"
                      );
                    }
                  }}>
                  <img src="./imgs/icons/facebook-icon.png" alt="facebook" />
                  <div
                    className={`dim ${agencyInfo.facebook ? "" : "on"}`}></div>
                  <p className="text">facebook</p>
                </div>
                <div
                  className="sns-con"
                  onClick={(e) => {
                    if (agencyInfo.twitter) {
                      window.open(
                        `https://twitter.com/${agencyInfo.twitter}`,
                        "_blank"
                      );
                    }
                  }}>
                  <img src="./imgs/icons/twitter-icon.png" alt="twitter" />
                  <div
                    className={`dim ${agencyInfo.twitter ? "" : "on"}`}></div>
                  <p className="text">Twitter</p>
                </div>
                <div
                  className="sns-con"
                  onClick={(e) => {
                    if (agencyInfo.tiktok) {
                      window.open(
                        `https://www.tiktok.com/@${agencyInfo.tiktok}`,
                        "_blank"
                      );
                    }
                  }}>
                  <img src="./imgs/icons/tiktok-icon.png" alt="tiktok" />
                  <div className={`dim ${agencyInfo.tiktok ? "" : "on"}`}></div>
                  <p className="text">Tiktok</p>
                </div>
                <div
                  className="sns-con"
                  onClick={(e) => {
                    if (agencyInfo.youtube) {
                      window.open(
                        `https://www.youtube.com/user/${agencyInfo.youtube}`,
                        "_blank"
                      );
                    }
                  }}>
                  <img src="./imgs/icons/youtube-icon.png" alt="youtube" />
                  <div
                    className={`dim ${agencyInfo.youtube ? "" : "on"}`}></div>
                  <p className="text">Youtube</p>
                </div>
              </div>
            </div>

            <div className="artist-info">
              <h2>ARTIST INFORMATION</h2>
              {agencyInfo.artistInfoList.length > 0 ? (
                <div className="list">
                  <ul>
                    {agencyInfo.artistInfoList.map((item: any, idx: number) => (
                      <li
                        key={`artist-${idx}`}
                        onClick={(e) => {
                          setIsShowPopup(true);
                          setPopupItem(item);
                        }}>
                        <div
                          className="img"
                          style={{
                            backgroundImage: `url(${
                              item.imageInfo
                                ? item.imageInfo.url.replace(/ /g, "%20")
                                : "./imgs/dummy/basic-img-2.png"
                            }?w=400&h=400)`,
                          }}></div>
                        <div className="student-info">
                          <p className="artist-name ellipsis">{item.name}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div style={{ paddingBottom: "10px" }}>
                  {getLanguageValue(
                    PAGE_TYPE.STUDENT,
                    "messageAgencyDetail.txtNoRegisteredArtistInfo"
                  )}
                </div>
              )}
            </div>
          </div>

          {/* <!-- 아티스트 정보 팝업 --> */}
          {isShowPopup ? (
            <div className="popup artist-info-popup">
              <div className="con">
                <div className="wrap">
                  <div
                    className="close-btn"
                    onClick={(e) => {
                      setIsShowPopup(false);
                    }}></div>
                  <h2>{popupItem.name}</h2>
                  <img
                    src={`
                    ${
                      popupItem.imageInfo
                        ? popupItem.imageInfo.url.replace(/ /g, "%20")
                        : "./imgs/dummy/basic-img-2.png"
                    }?w=300&h=300`}
                    alt="artist image"
                  />
                  <div className="sns">
                    <ul>
                      <li>
                        <a
                          onClick={(e) => {
                            if (popupItem.instagram) {
                              window.open(
                                `https://www.instagram.com/${popupItem.instagram}`,
                                "_blank"
                              );
                            }
                          }}>
                          <img
                            src="./imgs/icons/instagram-icon.png"
                            alt="instagram"
                          />

                          {/* <!-- 해당 sns 없을경우 className에 on 추가 --> */}
                          <div
                            className={`dim ${
                              popupItem.instagram ? "" : "on"
                            }`}></div>
                          <p>Instagram</p>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={(e) => {
                            if (popupItem.facebook) {
                              window.open(
                                `https://www.facebook.com/${popupItem.facebook}`,
                                "_blank"
                              );
                            }
                          }}>
                          <img
                            src="./imgs/icons/facebook-icon.png"
                            alt="facebook"
                          />
                          <div
                            className={`dim ${
                              popupItem.facebook ? "" : "on"
                            }`}></div>
                          <p>Facebook</p>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={(e) => {
                            if (popupItem.twitter) {
                              window.open(
                                `https://twitter.com/${popupItem.twitter}`,
                                "_blank"
                              );
                            }
                          }}>
                          <img
                            src="./imgs/icons/twitter-icon.png"
                            alt="twitter"
                          />
                          <div
                            className={`dim ${
                              popupItem.twitter ? "" : "on"
                            }`}></div>
                          <p>Twitter</p>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={(e) => {
                            if (popupItem.tiktok) {
                              window.open(
                                `https://www.tiktok.com/@${popupItem.tiktok}`,
                                "_blank"
                              );
                            }
                          }}>
                          <img
                            src="./imgs/icons/tiktok-icon.png"
                            alt="tiktok"
                          />
                          <div
                            className={`dim ${
                              popupItem.tiktok ? "" : "on"
                            }`}></div>
                          <p>Tiktok</p>
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={(e) => {
                            if (popupItem.youtube) {
                              window.open(
                                `https://www.youtube.com/user/${popupItem.youtube}`,
                                "_blank"
                              );
                            }
                          }}>
                          <img
                            src="./imgs/icons/youtube-icon.png"
                            alt="youtube"
                          />
                          <div
                            className={`dim ${
                              popupItem.youtube ? "" : "on"
                            }`}></div>
                          <p>Youtube</p>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="btn">
                    <button
                      onClick={(e) => {
                        setIsShowPopup(false);
                      }}>
                      {getLanguageValue(
                        PAGE_TYPE.STUDENT,
                        "messageAgencyDetail.txtClose"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </main>
  );
};
export default MessageAgencyDetailComponent;
