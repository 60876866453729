import React, { useRef, useEffect, useState } from "react";

interface ImageWithWatermarkProps {
    imageUrl: string;
    watermarkText: string;
}

const ImageWithWatermarkComponent: React.FC<ImageWithWatermarkProps> = ({ imageUrl, watermarkText }) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        const image = new Image();
        image.src = imageUrl;

        image.onload = () => {
            // 이미지가 로드되면 캔버스 크기를 이미지 크기에 맞추기
            canvas.width = image.width;
            canvas.height = image.height;

            // 이미지 그리기
            ctx.drawImage(image, 0, 0);

            // 워터마크 추가
            const fontSize = Math.max(canvas.width, canvas.height) * 0.02; // 이미지 크기의 2%로 폰트 크기 설정
            ctx.font = `${fontSize}px Arial`;
            ctx.fillStyle = "rgba(255, 255, 255, 0.3)"; // 투명도 적용
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";

            // 텍스트의 간격과 회전 설정
            const text = watermarkText; // 넣고 싶은 텍스트
            const lineGap = fontSize * 4; // 텍스트 간격 (폰트 크기에 비례)
            const textAngle = (-45 * Math.PI) / 180; // 대각선으로 회전 (45도)

            // 텍스트를 빗살무늬 형태로 반복적으로 배치
            for (let y = -canvas.height; y < canvas.height * 2; y += lineGap) {
                for (let x = -canvas.width; x < canvas.width * 2; x += lineGap * 3) {
                    ctx.save(); // 이전 상태 저장
                    ctx.translate(x, y); // 텍스트 위치 설정
                    ctx.rotate(textAngle); // 텍스트를 대각선으로 회전
                    ctx.fillText(text, 0, 0); // 텍스트 그리기
                    ctx.restore(); // 이전 상태 복구
                }
            }

            setImageLoaded(true);
        };
    }, [imageUrl, watermarkText]);

    return <canvas className="img" ref={canvasRef}></canvas>;
};

export default ImageWithWatermarkComponent;
