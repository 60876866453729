import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import * as NoticeApi from "api/bbs/Notice.api";
import NoticeComponent from './Notice.component'

const NoticeContainer = (props: any) => {
    const location = useLocation();

    const [noticeList, setNoticeList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // pagination
    const searchParams = new URLSearchParams(location.search);
    const limit = 10;
    const [page, setPage] = useState(location.state?.page || location.state?.prev || +searchParams.get("page") || 1);
    const [totalCount, setTotalcount] = useState(0);

    const [importantList, setImportantList] = useState([]);

    const getImportantList = async () => {
        const result = await NoticeApi.getImportantList();

        if (result?.list) {
            setImportantList(result.list);
        }
    };

    const getList = async () => {
        setIsLoading(true);
        const params = { page, limit };
        const result = await NoticeApi.getList(params);

        if (result?.list) {
            setNoticeList(result.list);
            setTotalcount(result.totalCount);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getImportantList();
    }, []);

    useEffect(() => {
        getList();
    }, [page]);

    return (
        <NoticeComponent
            isLoading={isLoading}
            noticeList={noticeList}
            importantList={importantList}
            // pagination
            limit={limit}
            page={page}
            setPage={setPage}
            totalCount={totalCount}
        />
    );
};

export default NoticeContainer;
