export const makeRank = (score: number) => {
  if (score >= 10) {
    return "S";
  } else if (score >= 9.5) {
    return "A+";
  } else if (score >= 9) {
    return "A";
  } else if (score >= 8.5) {
    return "B+";
  } else if (score >= 8) {
    return "B";
  } else if (score >= 7.5) {
    return "C+";
  } else if (score >= 7) {
    return "C";
  } else if (score >= 6.5) {
    return "D+";
  } else if (score >= 6) {
    return "D";
  } else if (score >= 5) {
    return "F";
  } else {
    return "-";
  }
};
