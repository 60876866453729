import React, { useEffect, useRef, useState } from "react";
import AuditionNewComponent from "./AuditionNew.component";
import { useLocation, useNavigate } from "react-router-dom";

import * as AuditionApi from "api/agency/Audition.api";
import * as AuthApi from "api/common/Auth.api";
import { addDate, getCurrentDate } from "helpers/Datetime.helper";
import { checkIsEmail, checkSlug } from "helpers/Reg.helper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const AuditionNewContainer = (props: any) => {
  const btnRef = useRef<any>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { id, auditionInfo, setAuditionInfo } = props;

  const [auditionParams, setAuditionParams] = useState({
    id: null,
    title: "",
    startDate: getCurrentDate(),
    endDate: addDate(7, getCurrentDate(), "YYYY-MM-DD"),
    countryCode: null,
    minimumAge: null,
    maximumAge: null,
    genderTypeCode: null,
    specialtyCodeList: null,
    isFile: false,
    isProfile: false,
    slug: "",
    introduce: "",
  });

  const [hasCheckedSpecialty, setHasCheckedSpecialty] = useState({
    dance: true,
    vocal: true,
    rap: true,
  });

  const onChangeInput = (componentName: string, value: any) => {
    console.log("AuditionNewContainer:onChangeInput", componentName, value);

    // 오디션 정보 정보
    if (componentName === "title") {
      setAuditionParams({ ...auditionParams, title: value });
    } else if (componentName === "startDate") {
      setAuditionParams({ ...auditionParams, startDate: value });
    } else if (componentName === "endDate") {
      setAuditionParams({ ...auditionParams, endDate: value });
    } else if (componentName === "countryCode") {
      setAuditionParams({ ...auditionParams, countryCode: value });
    } else if (componentName === "minimumAge") {
      setAuditionParams({ ...auditionParams, minimumAge: value });
    } else if (componentName === "maximumAge") {
      setAuditionParams({ ...auditionParams, maximumAge: value });
    } else if (componentName === "genderTypeCode") {
      if (value === "") {
        value = null;
      }
      setAuditionParams({ ...auditionParams, genderTypeCode: value });
    } else if (componentName === "specialtyCodeList") {
      setAuditionParams({ ...auditionParams, specialtyCodeList: value });
    } else if (componentName === "isFile") {
      setAuditionParams({ ...auditionParams, isFile: value });
    } else if (componentName === "isProfile") {
      setAuditionParams({ ...auditionParams, isProfile: value });
    } else if (componentName === "slug") {
      setAuditionParams({ ...auditionParams, slug: value });
    } else if (componentName === "introduce") {
      setAuditionParams({ ...auditionParams, introduce: value });
    }
  };

  const onChangeCheckbox = (
    componentName: string,
    checkedItem: any,
    checked: boolean
  ) => {
    // 특기 선택
    if (componentName === "vocal") {
      setHasCheckedSpecialty({ ...hasCheckedSpecialty, vocal: checked });
    } else if (componentName === "dance") {
      setHasCheckedSpecialty({ ...hasCheckedSpecialty, dance: checked });
    } else if (componentName === "rap") {
      setHasCheckedSpecialty({ ...hasCheckedSpecialty, rap: checked });
    } else if (componentName === "isFile") {
      setAuditionParams({ ...auditionParams, isFile: checked });
    } else if (componentName === "isProfile") {
      setAuditionParams({ ...auditionParams, isProfile: checked });
    }
  };
  const setSpecialtyCodeList = () => {
    const specialtyCodeList = [];
    if (hasCheckedSpecialty.vocal) {
      specialtyCodeList.push("specialty:vocal");
    }
    if (hasCheckedSpecialty.dance) {
      specialtyCodeList.push("specialty:dance");
    }

    if (hasCheckedSpecialty.rap) {
      specialtyCodeList.push("specialty:rap");
    }

    auditionParams.specialtyCodeList = JSON.stringify(specialtyCodeList);
  };

  const checkExistsSlug = async () => {
    const slug = auditionParams.slug;
    const result = await AuthApi.checkExistsSlug({ slug });

    return result;
  };

  const isValidDateRange = (startDate: string, endDate: string) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // 오늘 날짜의 시간을 0으로 설정하여 비교할 때 시간 부분을 무시합니다.

    const start = new Date(startDate);
    start.setHours(0, 0, 0, 0);

    const end = new Date(endDate);
    end.setHours(0, 0, 0, 0);

    const isStartDateValid = start >= today; // 시작일이 오늘 포함 이후인지 확인합니다.
    const isEndDateValid = end >= start; // 종료일이 시작일 포함 이후인지 확인합니다.

    return isStartDateValid && isEndDateValid;
  };

  const isDisabled = async () => {
    if (!auditionParams.title) {
      alert(getLanguageValue(PAGE_TYPE.AGENCY, "auditionNew.errTitle"));
      return true;
    } else if (!(auditionParams.startDate && auditionParams.endDate)) {
      alert(getLanguageValue(PAGE_TYPE.AGENCY, "auditionNew.errPeriod"));
      return true;
    } else if (
      auditionParams.startDate &&
      auditionParams.endDate &&
      !isValidDateRange(auditionParams.startDate, auditionParams.endDate)
    ) {
      alert(getLanguageValue(PAGE_TYPE.AGENCY, "auditionNew.errPeriod"));
      return true;
    } else {
      return false;
    }
  };

  const onClickBtnSave = async () => {
    if (!(await isDisabled())) {
      btnRef.current.disabled = true;
      setSpecialtyCodeList();

      if (id) {
        const result = await AuditionApi.updateInfo(id, auditionParams);
        if (result) {
          btnRef.current.disabled = false;
          result.data?.errorCode
            ? alert(result.data.message)
            : navigate(`/agency/audition/`, {
                state: { page: 1 },
              });
        }
      } else {
        const result = await AuditionApi.createInfo(auditionParams);
        if (result) {
          btnRef.current.disabled = false;
          result.data?.errorCode
            ? alert(result.data.message)
            : navigate(`/agency/audition/`, {
                state: { page: 1 },
              });
        }
      }
    }
  };

  const editInit = async () => {
    setAuditionParams({
      id: id,
      title: auditionInfo.title || "",
      startDate: auditionInfo.startDate || getCurrentDate(),
      endDate:
        auditionInfo.endDate || addDate(7, getCurrentDate(), "YYYY-MM-DD"),
      countryCode: auditionInfo.countryCodeInfo?.code || null,
      minimumAge: auditionInfo.minimumAge || null,
      maximumAge: auditionInfo.maximumAge || null,
      genderTypeCode: auditionInfo.genderTypeCodeInfo?.code || null,
      specialtyCodeList:
        auditionInfo.specialtyCodeList?.map((item: any) => item.code) || [],
      isFile: auditionInfo.isFile || false,
      isProfile: auditionInfo.isProfile || false,
      slug: auditionInfo.slug || "",
      introduce: auditionInfo.introduce || "",
    });

    setHasCheckedSpecialty({
      dance:
        auditionInfo.specialtyCodeList?.some(
          (item: any) => item.code === "specialty:dance"
        ) || false,
      vocal:
        auditionInfo.specialtyCodeList?.some(
          (item: any) => item.code === "specialty:vocal"
        ) || false,
      rap:
        auditionInfo.specialtyCodeList?.some(
          (item: any) => item.code === "specialty:rap"
        ) || false,
    });
    setIsLoading(false);
  };
  useEffect(() => {
    if (id) {
      editInit();
    }
  }, []);

  return (
    <AuditionNewComponent
      id={id}
      isLoading={isLoading}
      auditionParams={auditionParams}
      onChangeInput={onChangeInput}
      onChangeCheckbox={onChangeCheckbox}
      hasCheckedSpecialty={hasCheckedSpecialty}
      btnRef={btnRef}
      onClickBtnSave={onClickBtnSave}
    />
  );
};

export default AuditionNewContainer;
