import { getCookie, setCookie, removeCookie } from "./Cookie.helper";

const accessTokenKey: string = process.env.REACT_APP_COOKIE_JWT_TOKEN_KEY ?? "";
const refreshTokenKey: string = process.env.REACT_APP_COOKIE_JWT_REFRESH_TOKEN_KEY ?? "";
const agencyTokenKey: string = process.env.REACT_APP_COOKIE_AGENCY_TOKEN_KEY ?? "";

export const getAccessToken = (): string => {
    return getCookie(accessTokenKey);
};

export const getRefreshToken = (): string => {
    return getCookie(refreshTokenKey);
};

export const setAccessToken = (accessToken: string): void => {
    setCookie(accessTokenKey, accessToken, null);
};

export const setRefreshToken = (refreshToken: string, expiredIn: Date): void => {
    setCookie(refreshTokenKey, refreshToken, null);
};

export const removeAuthTokens = (): void => {
    removeCookie(accessTokenKey);
    removeCookie(refreshTokenKey);
};

export const getAgencyId = (): string => {
    return getCookie(agencyTokenKey);
};

export const setAgencyId = (agencyId: string) => {
    setCookie(agencyTokenKey, agencyId, null);
};

export const removeAgencyId = (): void => {
    removeCookie(agencyTokenKey);
};
