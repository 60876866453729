import React, { useEffect, useRef, useState } from "react";
import MessageAgencyDetailComponent from "./MessageAgencyDetail.component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as AgencyApi from "api/agency/Agency.api";

const MessageAgencyDetailContainer = (props: any) => {
  const { id } = useParams();
  const [agencyInfo, setAgencyInfo] = useState(null);
  const [popupItem, setPopupItem] = useState(null);
  const [isShowPopup, setIsShowPopup] = useState(false);

  const getAgencyInfo = async () => {
    const result = await AgencyApi.getInfo(parseInt(id));
    if (result.info) {
      setAgencyInfo(result.info);
    }
  };

  useEffect(() => {
    getAgencyInfo();
  }, []);

  return (
    <MessageAgencyDetailComponent
      {...props}
      agencyInfo={agencyInfo}
      isShowPopup={isShowPopup}
      setIsShowPopup={setIsShowPopup}
      popupItem={popupItem}
      setPopupItem={setPopupItem}
    />
  );
};

export default MessageAgencyDetailContainer;
