export const IS_SHOW_INFO_POPUP = "SCHOOL/IS_SHOW_INFO_POPUP";
export const IS_SHOW_SEARCH_POPUP = "SCHOOL/IS_SHOW_SEARCH_POPUP";
export const NEW_MESSAGE_COUNT = "SCHOOL/NEW_MESSAGE_COUNT";

export const setIsShowInfoPopup = (isShowInfoPopup: boolean) => ({ type: IS_SHOW_INFO_POPUP, isShowInfoPopup });
export const setIsShowSearchPopup = (isShowSearchPopup: boolean) => ({ type: IS_SHOW_SEARCH_POPUP, isShowSearchPopup });
export const setNewMessageCount = (newMessageCount: number) => ({ type: NEW_MESSAGE_COUNT, newMessageCount });

const initialState = {
    isShowInfoPopup: false,
    isShowSearchPopup: false,
    newMessageCount: 0,
};

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case IS_SHOW_INFO_POPUP:
            return {
                ...state,
                isShowInfoPopup: action.isShowInfoPopup,
            };
        case IS_SHOW_SEARCH_POPUP:
            return {
                ...state,
                isShowSearchPopup: action.isShowSearchPopup,
            };
        case NEW_MESSAGE_COUNT:
            return {
                ...state,
                newMessageCount: action.newMessageCount,
            };

        default:
            return state;
    }
}
