import React, { useEffect, useRef, useState } from "react";
import AuditionClosePopupComponent from "./AuditionClosePopup.component";
import * as AuditionApi from "api/agency/Audition.api";
import { getCommonCodeListByParent } from "data/common.data";
import { useLocation, useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const AuditionClosePopupContainer = (props: any) => {
  console.log(props);
  const location = useLocation();
  const navigate = useNavigate();
  const state = location?.state;
  const { auditionInfo, setIsShowAuditionClosePopup, setStatus } = props;
  const auditionEarlyCloseReasonList = getCommonCodeListByParent(
    "auditionEarlyCloseReason"
  );
  const [selectedReason, setSelectedReason] = useState(
    auditionEarlyCloseReasonList[0].code
  );
  const [etcReason, setEtcReason] = useState("");
  const btnRef = useRef<any>();

  const onClickBtnClose = async () => {
    btnRef.current.disabled = true;

    if (
      selectedReason === "auditionEarlyCloseReason:etc" &&
      etcReason.trim() === ""
    ) {
      alert(
        getLanguageValue(PAGE_TYPE.AGENCY, "auditionClosePopup.txtValidation")
      );
      btnRef.current.disabled = false;
      return;
    }

    if (
      window.confirm(
        getLanguageValue(PAGE_TYPE.AGENCY, "auditionClosePopup.txtConfirm")
      )
    ) {
      const result = await AuditionApi.updateAuditionEarlyCloseInfo(
        auditionInfo.id,
        {
          auditionEarlyCloseReasonCode: selectedReason,
          auditionEarlyCloseOtherReason: etcReason,
        }
      );

      if (result) {
        if (result.data?.errorCode) {
          alert(result.data.message);
        } else {
          setIsShowAuditionClosePopup(false);
          setStatus("auditionStatus:done");
        }
      }
    }

    btnRef.current.disabled = false;
  };

  return (
    <AuditionClosePopupComponent
      {...props}
      etcReason={etcReason}
      setEtcReason={setEtcReason}
      selectedReason={selectedReason}
      setSelectedReason={setSelectedReason}
      setIsShowAuditionClosePopup={setIsShowAuditionClosePopup}
      auditionEarlyCloseReasonList={auditionEarlyCloseReasonList}
      btnRef={btnRef}
      onClickBtnClose={onClickBtnClose}
    />
  );
};

export default AuditionClosePopupContainer;
