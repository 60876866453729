import LoadingComponent from "commons/loading/Loading.component";
import { PAGE_TYPE } from "enums";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AuditionComponent = (props: any) => {
  const navigate = useNavigate();

  const { tab, setTab, auditionList, isLoading, totalCount, setParams } = props;

  return (
    <main className="landing-audition-list">
      <div className="landing-con">
        <div className="wrap-2">
          <div className="auditions wannabes-auditions">
            <div className="title">
              {/* <!-- 선택한 audition title에 class active 추가 --> */}
              {/* <button
                className={`${tab === "wannabes" ? "active" : ""}`}
                onClick={(e) => {
                  setTab("wannabes");
                }}>
                Wannabes Auditions
              </button> */}
              <button
                className={`${tab === "agency" ? "active" : ""}`}
                onClick={(e) => {
                  setTab("agency");
                }}>
                Agency Auditions
              </button>
            </div>
            {isLoading ? (
              <LoadingComponent />
            ) : auditionList.length > 0 ? (
              <ul>
                {/* <!-- 에이전시에서 주최하는 오디션일경우 class명 agency-auditions 추가 --> */}
                {auditionList.map((item: any, idx: number) => (
                  <li
                    className={`${tab === "agency" ? "agency-auditions" : ""}`}
                    onClick={(e: any) => {
                      e.preventDefault();
                      navigate(
                        `/audition/${item.id}/${
                          tab === "agency" ? "agency" : "wannabes"
                        }`
                      );
                    }}>
                    <div className="audition-content">
                      <div className="wannabes-logo"></div>
                      <span
                        className={`${
                          item?.auditionStatusCodeInfo?.code ===
                          "auditionStatus:inProgress"
                            ? "ongoing"
                            : item?.auditionStatusCodeInfo?.code ===
                              "auditionStatus:scheduled"
                            ? "schedule"
                            : item?.auditionStatusCodeInfo?.code ===
                              "auditionStatus:done"
                            ? "done"
                            : ""
                        }`}>
                        {getCurrentLangName(
                          item?.auditionStatusCodeInfo?.names
                        )}
                      </span>
                      <img
                        src={
                          item.logoImageUrl
                            ? item.logoImageUrl
                            : "./imgs/icons/agency-logo-default.png"
                        }
                        alt="agency logo"
                      />
                    </div>
                    <div className="text">
                      <p className="agency-name ellipsis">{item.companyName}</p>
                      <p className="nationality ellipsis">
                        (
                        {item.countryCodeInfo
                          ? getCurrentLangName(item.countryCodeInfo.names)
                          : null}
                        )
                      </p>
                      <p className="audition-name ellipsis">{item.title}</p>
                      <p className="audition-period ellipsis">
                        {`(${getFormattedDateValue(
                          item.startDate,
                          "YYYY.MM.DD"
                        )}~${getFormattedDateValue(
                          item.endDate,
                          "YYYY.MM.DD"
                        )})`}
                      </p>

                      {item.favoriteCnt != null ? (
                        <div className={`like ${item.isFavorited ? "on" : ""}`}>
                          <span>{item.favoriteCnt}</span>
                        </div>
                      ) : null}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="no-data">
                <p>No data</p>
              </div>
            )}
            {totalCount !== auditionList.length ? (
              <div className="btn">
                <button
                  onClick={(e: any) => {
                    e.preventDefault();
                    const lastValue = auditionList[auditionList.length - 1];
                    setParams((prev: any) => {
                      return {
                        ...prev,
                        lastIdx: lastValue.id,
                      };
                    });
                  }}>
                  {getLanguageValue(PAGE_TYPE.COMMON, "audition.btnViewMore")}
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </main>
  );
};
export default AuditionComponent;
