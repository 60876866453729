import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import * as AuthApi from "api/common/Auth.api";

import VerifyEmailComponent from "./VerifyEmail.component";

const UpdateEmailContainer = (props: any) => {
  const location = useLocation();

  const [isVerify, setIsVerify] = useState(null);

  const verifyEmail = async () => {
    const code = location.pathname.split("/").pop();
    const result: any = await AuthApi.verifyEmail(code);

    setIsVerify(result);
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return <VerifyEmailComponent isVerify={isVerify} />;
};

export default UpdateEmailContainer;
