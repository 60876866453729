import React from "react";

import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const PortfolioComponent = (props: any) => {
  const { info, setIsShowTaggedStudentPopup } = props;

  return (
    <main className="">
      <div className="popup popup-portfolio-tag-list">
        <div className="con">
          <div className="wrap">
            <div className="title">
              <p className="ellipsis">{info.title}</p>
            </div>
            <div className="date">
              <p>{info.performedDate.replaceAll("-", ".")}</p>
            </div>
            <div className="list">
              <ul>
                {info.studentInfoList.length > 0 &&
                  info.studentInfoList.map((item: any, idx: number) => (
                    <li key={`student-${idx}`}>
                      <div className="item">
                        <div
                          className="img"
                          style={{
                            backgroundImage: `url("${
                              item.imageUrl
                                ? item.imageUrl
                                : "imgs/dummy/basic-img-2.png"
                            }?w=360&h=360")`,
                          }}></div>
                        <div className="name">
                          <p>{`${item.firstName} ${item.lastName}`}</p>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="control">
              <button
                className="btn-close"
                onClick={(evt) => setIsShowTaggedStudentPopup(false)}>
                {getLanguageValue(PAGE_TYPE.SCHOOL, "portfolio.btnClose")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PortfolioComponent;
