import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PasswordResetDoneComponent = (props: any) => {
  const navigate = useNavigate();
  const { email } = props;

  return (
    <main className="find-password-success">
      <div className="con">
        <div className="form">
          <div className="box">
            <p>{email}</p>
          </div>
          <p>
            {getLanguageValue(
              PAGE_TYPE.COMMON,
              "passwordResetDone.txtEmailSent"
            )}
            <br />
            {getLanguageValue(
              PAGE_TYPE.COMMON,
              "passwordResetDone.txtCheckInbox"
            )}
          </p>
        </div>

        <div className="btn">
          <div>
            <button
              onClick={(e) => {
                navigate("/login");
              }}>
              {getLanguageValue(PAGE_TYPE.COMMON, "passwordResetDone.btnLogin")}
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};
export default PasswordResetDoneComponent;
