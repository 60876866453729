import React, { useEffect } from "react";
import Pagination from "../../../commons/pagination/Pagination.container";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";
import { useLocation, useNavigate } from "react-router-dom";
import { getCommonCodeListByParent } from "data/common.data";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const AuditionMemoPopupComponent = (props: any) => {
  const {
    memo,
    setMemo,
    memoItem,
    setIsShowAuditionMemoPopup,
    btnRef,
    onClickBtnSave,
  } = props;

  return (
    <div className="popup popup-audition-memo">
      <div className="con">
        <div className="memo">
          <div
            className="close-btn"
            onClick={() => {
              setIsShowAuditionMemoPopup(false);
            }}></div>
          <h2>
            {getLanguageValue(PAGE_TYPE.AGENCY, "auditionMemoPopup.txtMemo")}
          </h2>
          <div className="form">
            <div className="wrap">
              <div className="img">
                <img
                  src={
                    memoItem.profileImageInfo
                      ? `${memoItem.profileImageInfo.url.replace(/ /g, "%20")}`
                      : "./imgs/dummy/basic-img-2.png"
                  }
                  alt="profile image"
                />
              </div>
              <span>
                {memoItem.firstName} {memoItem.lastName}
              </span>
            </div>
            <textarea
              placeholder={getLanguageValue(
                PAGE_TYPE.AGENCY,
                "auditionMemoPopup.txtMemoPlaceholder"
              )}
              onChange={(e) => {
                setMemo(e.target.value);
              }}
              value={memo}></textarea>
          </div>

          <div className="btn">
            <div>
              <button
                className="cancel-btn"
                onClick={() => {
                  setIsShowAuditionMemoPopup(false);
                }}>
                {getLanguageValue(
                  PAGE_TYPE.AGENCY,
                  "auditionMemoPopup.txtCancel"
                )}
              </button>
            </div>
            <div>
              <button
                className=""
                ref={btnRef}
                onClick={() => {
                  onClickBtnSave();
                }}>
                {getLanguageValue(
                  PAGE_TYPE.AGENCY,
                  "auditionMemoPopup.txtSave"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AuditionMemoPopupComponent;
