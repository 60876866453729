import React, { useEffect, useRef, useState } from "react";
import AuditionInfoPopupComponent from "./AuditionInfoPopup.component";

const AuditionInfoPopupContainer = (props: any) => {
  const { auditionInfo, setIsShowAuditionInfoPopup } = props;
  return (
    <AuditionInfoPopupComponent
      {...props}
      auditionInfo={auditionInfo}
      setIsShowAuditionInfoPopup={setIsShowAuditionInfoPopup}
    />
  );
};

export default AuditionInfoPopupContainer;
