import React, { useEffect, useRef, useState } from "react";
import * as ImageHelper from "helpers/Image.helper";
import * as DataApi from "api/common/Data.api";
import LoadingComponent from "commons/loading/Loading.component";
import { getPostInfo } from "api/Post.api";

const PostFormPopupComponent = (props: any) => {
    const { isShowFormPopup, setIsShowFormPopup, postId, createPostValue, editPostValue, isOnNetworking, setIsOnNetworking, setSelectedPostId } = props;

    const [content, setContent] = useState("");
    const [visibility, setVisibility] = useState(0);
    const [imageInfoList, setImageInfoList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fileInputRef = useRef(null);

    const uploadImage = async (evt: any) => {
        setIsOnNetworking(true);

        if (!evt) {
            return false;
        }

        const file = evt.target.files[0];
        const imageInfo = await ImageHelper.getImageInfo(file);
        if (!imageInfo) {
            alert("Please choose image file");
            return false;
        }

        const result = await DataApi.uploadImage(file);
        setImageInfoList([...imageInfoList, { id: result.id, url: imageInfo.url }]);

        setIsOnNetworking(false);
    };

    const getPostInfoValue = async () => {
        const result = await getPostInfo(postId);

        if (result?.info) {
            const info = result?.info;
            setContent(info.content);
            setVisibility(info.visibility);
            if (info.imageList && info.imageList.length > 0) {
                setImageInfoList(info.imageList);
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (postId) getPostInfoValue();
    }, [postId]);

    useEffect(() => {
        if (!isShowFormPopup) {
            setContent("");
            setVisibility(0);
            setImageInfoList([]);
            setSelectedPostId(0);
            setIsLoading(true);
            fileInputRef.current.value = null
        }
    }, [isShowFormPopup]);

    return (
        <div className="v2-popup v2-popup-posting-form" style={{ display: isShowFormPopup ? "block" : "none" }}>
            <div className="wrap">
                <div className="con">
                    <div className="header">
                        <div className="title">
                            <h6>
                                {/* <!-- 신규 생성일경우 : "새 포스팅" 작성 -->
                                            <!-- 포스팅 수정일경우 : "기존 포스팅" 수정 --> */}
                                {postId ? (
                                    <>
                                        <strong>기존 포스팅</strong>
                                        수정
                                    </>
                                ) : (
                                    <>
                                        <strong>새 포스팅</strong>
                                        작성
                                    </>
                                )}
                            </h6>
                        </div>

                        <div
                            className="btn-close-popup"
                            onClick={(e: any) => {
                                setIsShowFormPopup(false);
                            }}>
                            <img src="./imgs/common/icon-close-popup.png" alt="close popup button" />
                        </div>
                    </div>

                    {isLoading && postId ? (
                        <LoadingComponent />
                    ) : (
                        <>
                            <div className="body">
                                <div className="img-form">
                                    <ul>
                                        {imageInfoList.map((item: any, index: number) => (
                                            <li key={`post-image-${index}`}>
                                                <div className="item">
                                                    <div className="pic">
                                                        <div className="img">
                                                            <img src={item.url} alt={`post-image-${index}`} />
                                                        </div>
                                                        <div
                                                            className="btn-remove"
                                                            onClick={(e: any) => {
                                                                setImageInfoList(imageInfoList.filter((v: any) => v.id !== item.id));
                                                            }}>
                                                            <img src="./imgs/common/icon-remove-t1.png" alt="remove photo" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                        {imageInfoList.length < 10 ? (
                                            <li>
                                                <div className="item">
                                                    <div className="btn-upload">
                                                        <label>
                                                            <input type="file" ref={fileInputRef} accept="image/*" onChange={(evt) => uploadImage(evt)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </li>
                                        ) : null}
                                        {Array.from({ length: 9 - imageInfoList.length }).map((_, index) => (
                                            <li key={`post-empty-image-${index}`}>
                                                <div className="item">
                                                    <div className="empty"></div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                <div className="v2-textarea">
                                    <textarea placeholder="내용을 입력해주세요~" value={content} onChange={(e) => setContent(e.target.value)}></textarea>
                                </div>
                            </div>
                            <div className="footer">
                                <div className="col col-left">
                                    <label className="select-form">
                                        공개범위
                                        <div className="v2-select">
                                            <select onChange={(e) => setVisibility(Number(e.target.value))} value={visibility}>
                                                <option value={0}>전체 공개</option>
                                                <option value={1}>팔로워 공개</option>
                                                <option value={2}>비공개</option>
                                            </select>
                                        </div>
                                    </label>
                                </div>
                                <div className="col col-right">
                                    <button
                                        className="btn btn-save"
                                        disabled={isOnNetworking}
                                        onClick={(e: any) => {
                                            if (imageInfoList.length === 0) {
                                                alert("need to select image");
                                                return;
                                            }

                                            if (!content) {
                                                alert("need to input content");
                                                return;
                                            }

                                            const imageIdList = [];

                                            for (const image of imageInfoList) {
                                                imageIdList.push(image.id);
                                            }

                                            if (postId) {
                                                editPostValue(postId, { imageIdList, content, visibility });
                                            } else {
                                                createPostValue({ imageIdList, content, visibility });
                                            }
                                        }}>
                                        저장
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PostFormPopupComponent;
