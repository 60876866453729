import React from "react";
import { useNavigate } from "react-router-dom";

import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getCurrentLangName } from "helpers/String.helper";

import StudentEvaluationGraphContainer from "commons/evaluation/StudentEvalutionGraph.container";
import LoadingComponent from "commons/loading/Loading.component";
import YouTube, { YouTubeProps } from "react-youtube";
import { createPortfolioNotificationInfo } from "api/notification/Notification.api";

const WannabeInfoComponent = (props: any) => {
    const navigate = useNavigate();

    const {
        studentInfo,
        portfolioList,
        setPortfolioList,
        isPublic,
        isPortfolioShowLoading,
        // favorite
        btnRef,
        isFavorited,
        changeFavoriteStatus,
        // image popup
        sliderRef,
        showImagePopup,
        isShowPopup,
        setIsShowPopup,
        initialSlideIdx,
        // message
        messageRequest,
        createRequest,
        cancelRequest,
        isLoading,
        messageBtnRef,
    } = props;

    const Arrow = () => {
        return <div />;
    };

    const settings = {
        className: "slider",
        infinite: studentInfo?.imageInfoList.length > 1 ? true : false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: initialSlideIdx,
        prevArrow: <Arrow />,
        nextArrow: <Arrow />,
    };

    const opts: YouTubeProps["opts"] = {
        height: "100%",
        width: "100%",
        playerVars: {},
    };

    return (
        <main className="">
            {(studentInfo?.userSchoolId && !isLoading) || (!studentInfo?.userSchoolId && studentInfo?.id) ? (
                <>
                    <div className="area">
                        <div className="title">
                            {/* <!-- 선택한 title에 class active 추가 --> */}
                            <button className="active">{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeInfo.txtTitle")}</button>
                            {/* <button onClick={(evt) => navigate(`/agency/wannabe/practice-video/${studentInfo?.id}`)}>
                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabePracticeVideo.txtTitle")}
                            </button> */}
                            <button onClick={(evt) => navigate(`/agency/wannabe/profile/${studentInfo?.id}`)}>
                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtTitle")}
                            </button>
                            {studentInfo?.userSchoolId ? (
                                <button onClick={(evt) => navigate(`/agency/wannabe/school-evaluation/${studentInfo?.id}`)}>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeSchoolEvaluation.txtTitle")}</button>
                            ) : null}
                        </div>
                        <div className="back-btn" onClick={(evt) => navigate(-1)}></div>
                    </div>

                    <div className="student-detail-info">
                        <div className="wrap">
                            <h2>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeInfo.txtDesc")}</h2>
                            <img
                                src={studentInfo.profileImageInfo?.url ? studentInfo.profileImageInfo?.url + "?w=600&h=600" : "./imgs/dummy/basic-img.png"}
                                alt="main profile image"
                                onClick={(e: any) => {
                                    if (studentInfo.profileImageInfo?.url) {
                                        showImagePopup(0);
                                    }
                                }}
                                style={{ cursor: `${studentInfo.profileImageInfo?.url ? "pointer" : "default"}` }}
                            />

                            {/* 기본 정보 */}
                            <div className="info-1">
                                <div className="info-1-wrap">
                                    <p className="name">{`${studentInfo.firstName} ${studentInfo.lastName}`}</p>
                                    <p className="birth">{studentInfo.birthday.replaceAll("-", ".")}</p>
                                    <p className="country">{getCurrentLangName(studentInfo.countryCodeInfo.names)}</p>
                                    <p>{studentInfo.bodyInfo}</p>
                                    <p>
                                        {studentInfo.specialtyCodeInfoList.length > 0
                                            ? studentInfo.specialtyCodeInfoList.map((item: any) => <span>{`${getCurrentLangName(item.names)}`}</span>)
                                            : null}
                                        {studentInfo.mbtiCode ? <span>{`${studentInfo.mbtiCode}`}</span> : null}
                                    </p>
                                </div>
                            </div>

                            {/* 통계 */}
                            <div className="info-3 infof-3-landing">
                                <h2>STATISTICS</h2>
                                <div className="wrap">
                                    <div className="favorite">
                                        {/* <!-- pc에서 마우스 호버시 툴팁 --> */}
                                        <div className="tip">
                                            <img src="./imgs/wannabe-info/favorite.png" alt="favorite" />
                                            <span className="tooltiptext">{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeInfo.txtFavoriteDescPc")}</span>
                                            <p className="mb">
                                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeInfo.txtFavoriteDescMb1")}
                                                <br />
                                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeInfo.txtFavoriteDescMb2")}
                                            </p>
                                        </div>
                                        <span>
                                            <span>{studentInfo.favoriteCnt}</span>
                                        </span>
                                    </div>
                                    <div className="view">
                                        <div className="tip">
                                            <img src="./imgs/wannabe-info/view.png" alt="view" />
                                            <span className="tooltiptext">{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeInfo.txtViewDescPc")}</span>
                                            <p className="mb">
                                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeInfo.txtViewDescMb1")}
                                                <br />
                                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeInfo.txtViewDescMb2")}
                                            </p>
                                        </div>
                                        <span>{studentInfo.viewCnt}</span>
                                    </div>
                                </div>
                            </div>

                            {/* 추가 사진 */}
                            {studentInfo.imageInfoList.length > 0 || studentInfo.profileImageInfo?.url ? (
                                <div className="info-9">
                                    {studentInfo.imageInfoList.length > 0 ? (
                                        <>
                                            <h2>ADDITIONAL PHOTOS</h2>
                                            <ul>
                                                {studentInfo.imageInfoList.map((item: any, idx: number) => (
                                                    <li key={`student-${idx}`} onClick={(evt) => showImagePopup(idx + 1)}>
                                                        <img src={`${item.url}?w=700&h=700`} alt="additional photo" />
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    ) : null}

                                    {/* 이미지 클릭시 팝업 */}
                                    {isShowPopup ? (
                                        <div className="popup popup-wannabe-info">
                                            <div className="close-btn" onClick={(evt) => setIsShowPopup(false)}></div>
                                            <h2>PHOTOS</h2>
                                            <div className="wrap">
                                                <Slider ref={sliderRef} {...settings}>
                                                    <>
                                                        <div
                                                            className="img"
                                                            style={{
                                                                backgroundImage: `url("${
                                                                    studentInfo.profileImageInfo?.url
                                                                        ? `${studentInfo.profileImageInfo?.url}?w=1200&h=1200`
                                                                        : "./imgs/dummy/basic-img.png"
                                                                })`,
                                                            }}></div>
                                                    </>
                                                    {studentInfo.imageInfoList.map((item: any) => (
                                                        <>
                                                            <div
                                                                className="img"
                                                                style={{
                                                                    backgroundImage: `url("${item.url}?w=1200&h=1200")`,
                                                                }}></div>
                                                        </>
                                                    ))}
                                                </Slider>
                                                {studentInfo.imageInfoList.length > 1 ? (
                                                    <>
                                                        <button className="custom-prev" onClick={(evt) => sliderRef.current.slickPrev()} />
                                                        <button className="custom-next" onClick={(evt) => sliderRef.current.slickNext()} />
                                                    </>
                                                ) : null}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}

                            {/* 메세지 버튼 */}
                            <div className="floating-bar">
                                <div className="wrap">
                                    <div className="chat-request-btn">
                                        {messageRequest ? (
                                            messageRequest.messageStatusCode === "messageType:accept" ? (
                                                <button
                                                    ref={messageBtnRef}
                                                    onClick={() => {
                                                        messageBtnRef.current.disabled = true;
                                                        navigate(`/agency/message/${messageRequest.channelId}`, {
                                                            state: {
                                                                schoolName: studentInfo.schoolInfo?.name,
                                                            },
                                                        });
                                                        messageBtnRef.current.disabled = false;
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnGoToMessagePage")}
                                                </button>
                                            ) : messageRequest.messageStatusCode === "messageType:request" ? (
                                                <button
                                                    ref={messageBtnRef}
                                                    onClick={(e) => {
                                                        cancelRequest();
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnCancelRequest")}
                                                </button>
                                            ) : (
                                                <button
                                                    ref={messageBtnRef}
                                                    onClick={(e) => {
                                                        createRequest();
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnRequestMessage")}
                                                </button>
                                            )
                                        ) : (
                                            <button
                                                ref={messageBtnRef}
                                                onClick={(e) => {
                                                    createRequest();
                                                }}>
                                                {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnRequestMessage")}
                                            </button>
                                        )}
                                    </div>

                                    <div className="btn-con">
                                        <button
                                            className={isFavorited ? "favorite-btn" : "favorite-disabled-btn"}
                                            ref={btnRef}
                                            onClick={(evt) => changeFavoriteStatus()}>
                                            <img src={isFavorited ? "./imgs/student/home/favorite-btn-2.png" : "./imgs/student/home/favorite-btn-1.png"} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : isPublic !== null && !isPublic ? (
                <div className="private-profile">
                    <img src="./imgs/icons/lock-icon.png" alt="lock" />
                    <p>{getLanguageValue(PAGE_TYPE.AGENCY, "studentInfo.txtPrivateProfile")}</p>
                </div>
            ) : (
                <LoadingComponent />
            )}
        </main>
    );
};

export default WannabeInfoComponent;
