import React, { useEffect } from "react";
import MissionComponent from "./Mission.component";

const MissionContainer = (props: any) => {
  useEffect(() => {}, []);

  return <MissionComponent {...props} />;
};

export default MissionContainer;
