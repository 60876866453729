import React from "react";
import DaumPostcodeEmbed from "react-daum-postcode";

import ArtistPopup from "./ArtistPopup";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const AgencySignupComponent = (props: any) => {
    const {
        btnRef,
        // account
        accountParams,
        // essential
        agencyParams,
        businessCardFrontImageInfo,
        businessCardBackImageInfo,
        termList,
        isOnNetworking,
        uploadImage,
        onChangeInput,
        onChangeCheckbox,
        onClickBtnCancelSignup,
        onClickBtnSignup,
        selectedAgencyInfo,

        // 이메일 인증 관련
        emailVericationCode,
        setEmailVericationCode,
        isEmailVarified,
        setIsEmailVarified,
        isSentEmail,
        sendEmailForVerification,
        emailVerification,
    } = props;

    return (
        <main className="signup-form casting-manager-signup">
            <div className="con">
                <h2>CASTING MANAGER SIGNUP</h2>

                <div className="form">
                    <div className="form-wrap">
                        <h2>"{selectedAgencyInfo?.nameKo}"</h2>
                        <p>{getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtSignUpAsAgencyMember")}</p>
                    </div>
                </div>

                <div className="form account">
                    <div className="title">
                        <h3>ACCOUNT</h3>
                        <p>{getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtAccountInfoForLogin")}</p>
                    </div>
                    <div className="input-box">
                        <label>*{getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtEmail")}</label>
                        <div className="input-box-wrap">
                            <div className="input-prefix">
                                <input
                                    type="text"
                                    disabled={isSentEmail || isEmailVarified}
                                    value={accountParams.username}
                                    onChange={(evt) => onChangeInput("username", evt.target.value)}
                                />
                            </div>
                            <div className="fix">
                                <p>@{selectedAgencyInfo?.emailDomain}</p>
                            </div>
                        </div>
                        <div className="button-wrap">
                            <button
                                type="button"
                                className={`btn ${!accountParams.username || isEmailVarified ? "disabled" : ""}`}
                                disabled={!accountParams.username || isOnNetworking || isEmailVarified ? true : false}
                                onClick={sendEmailForVerification}>
                                {isSentEmail
                                    ? getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtReSendEmailVericationCode")
                                    : getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtSendEmailVericationCode")}
                            </button>
                        </div>
                    </div>
                    <div className="input-box">
                        <label>*{getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtEmailVericationCode")}</label>
                        <div className="input-box-wrap">
                            <div className="input-prefix">
                                <input
                                    type="text"
                                    disabled={!isSentEmail || isEmailVarified}
                                    placeholder={getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtPlaceHolderEmailVericationCode")}
                                    value={emailVericationCode}
                                    onChange={(evt) => setEmailVericationCode(evt.target.value.trim())}
                                />
                            </div>
                        </div>
                        <div className="button-wrap">
                            <button
                                type="button"
                                onClick={emailVerification}
                                disabled={isEmailVarified || !emailVericationCode || !isSentEmail || isOnNetworking}
                                className={`btn ${isEmailVarified || !emailVericationCode || !isSentEmail ? "disabled" : ""}`}>
                                {getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtConfirmEmailVericationCode")}
                            </button>
                        </div>
                    </div>
                    <div className="input-box">
                        <label>*{getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtPassword")}</label>
                        <input type="password" value={accountParams.password} onChange={(evt) => onChangeInput("password", evt.target.value)} />
                    </div>
                    <div className="input-box">
                        <label>*{getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtConfirmPassword")}</label>
                        <input
                            type="password"
                            value={accountParams.confirmedPassword}
                            onChange={(evt) => onChangeInput("confirmedPassword", evt.target.value)}
                        />
                    </div>
                </div>

                <div className="form essential-information">
                    <div className="title">
                        <h3>ESSENTIAL INFORMATION</h3>
                        <p>{getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtEnterBasicInfo")}</p>
                    </div>
                    <div className="con img-con input-box">
                        <div className="img-upload bussiness-card">
                            <div className="img-wrap">
                                <div
                                    className={businessCardFrontImageInfo?.url ? "photo" : "img"}
                                    style={{
                                        backgroundImage: `url(${businessCardFrontImageInfo?.url || "./../../imgs/icons/camera.png"})`,
                                    }}
                                />
                            </div>
                            <p>{getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtUploadBusinessCardFront")}</p>
                            <input type="file" id="input-file-img1" onChange={(evt) => uploadImage("front", evt)} accept="image/*" />
                            <label htmlFor="input-file-img1">{getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtChooseFile")}</label>
                        </div>

                        <div className="img-upload bussiness-card">
                            <div className="img-wrap">
                                <div
                                    className={businessCardBackImageInfo?.url ? "photo" : "img"}
                                    style={{
                                        backgroundImage: `url(${businessCardBackImageInfo?.url || "./../../imgs/icons/camera.png"})`,
                                    }}
                                />
                            </div>
                            <p>{getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtUploadBusinessCardBack")}</p>
                            <input type="file" id="input-file-img2" onChange={(evt) => uploadImage("back", evt)} accept="image/*" />
                            <label htmlFor="input-file-img2">{getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtChooseFile")}</label>
                        </div>
                    </div>
                    <div className="con">
                        <h3 className="title-con">* {getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtName")}</h3>
                        <input type="text" className="text" value={agencyParams.name} onChange={(evt) => onChangeInput("name", evt.target.value)} />
                    </div>
                    <div className="con">
                        <h3 className="title-con">* {getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtPhoneNumber")}</h3>
                        <input type="text" className="text" value={agencyParams.cellphone} onChange={(evt) => onChangeInput("cellphone", evt.target.value)} />
                    </div>
                </div>

                <div className="form">
                    <div className="input-box agreement-form">
                        <ul className="list">
                            {termList.length > 0 &&
                                termList.map((item: any, idx: number) => (
                                    <li key={`term-${idx}`} className="item">
                                        <label className="checkbox">
                                            <input
                                                type="checkbox"
                                                onChange={(evt) => {
                                                    onChangeCheckbox(idx, evt.target.checked);
                                                }}
                                                checked={item.checked}
                                            />
                                            <span className="txt">{`(${item.isRequired ? "필수" : "선택"}) ${item.title}`}</span>
                                        </label>
                                        <button
                                            type="button"
                                            className="btn"
                                            onClick={(evt) => window.open(item.isRequired ? `/term/common/${item.id}` : `/term/etc/${item.id}`)}>
                                            ({getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtConfirm")})
                                        </button>
                                    </li>
                                ))}
                        </ul>
                        <div className="all-check">
                            <label className="checkbox">
                                <input
                                    className="select-all"
                                    type="checkbox"
                                    onChange={(evt) => {
                                        onChangeCheckbox("all", evt.target.checked);
                                    }}
                                    checked={!termList.find((i: any) => !i.checked)}
                                />
                                <span className="txt">{getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.txtAgreeAll")}</span>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="btn">
                    <div>
                        <button
                            className="cancel-btn"
                            onClick={(evt) => {
                                onClickBtnCancelSignup();
                            }}>
                            {getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.btnCancel")}
                        </button>
                    </div>
                    <div>
                        <button className="signup-btn" ref={btnRef} onClick={(evt) => onClickBtnSignup()} disabled={isOnNetworking}>
                            {getLanguageValue(PAGE_TYPE.COMMON, "agencySignup.btnSignUp")}
                        </button>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default AgencySignupComponent;
