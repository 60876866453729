import LoadingComponent from "commons/loading/Loading.component";
import { PAGE_TYPE } from "enums";
import { getUserType } from "helpers/Common.helper";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const NoticeDetailComponent = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { info, isLoading } = props;
    const { info: userInfo } = useSelector((state: any) => state.user);

    const noticePath = `/${getUserType(userInfo?.accountTypeCode)}/notice`;

    return (
        <main className="">
            {isLoading ? (
                <LoadingComponent />
            ) : (
                <div className="notice-info">
                    <div className="con">
                        <div
                            className="back-btn"
                            onClick={() => {
                                navigate(noticePath, {
                                    state: {
                                        page: location?.state?.page || 1,
                                    },
                                });
                            }}></div>
                        <div className="wrap">
                            <div className="title">
                                <h2>{info.title}</h2>
                                <p className="date">{getFormattedDateValue(info.createdAt, "YYYY.MM.DD HH:mm")}</p>
                            </div>
                            {info.attachedPcImageUrl ? (
                                <div className="img pc">
                                    <img src={info.attachedPcImageUrl} alt={info.title} />
                                </div>
                            ) : null}

                            {info.attachedMobileImageUrl ? (
                                <div className="img mb">
                                    <img src={info.attachedMobileImageUrl} alt={info.title} />
                                </div>
                            ) : null}

                            <div className="text">
                                <p>
                                    {info.content.split("\n").map((line: any, index: any) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </p>
                            </div>
                            <div className="btn">
                                <div>
                                    <button
                                        onClick={() => {
                                            navigate(noticePath, {
                                                state: {
                                                    page: location?.state?.page || 1,
                                                },
                                            });
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.COMMON, "notice.btnToList")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </main>
    );
};
export default NoticeDetailComponent;
