import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import * as NotificationApi from "api/notification/Notification.api";
import NotificationComponent from "./Notification.component";
import {
  listenForForegroundMessages,
  notificationListener,
} from "helpers/Firebase.helper";

const NotificationContainer = (props: any) => {
  const location = useLocation();

  const [notificationList, setNotificationList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // pagination
  const searchParams = new URLSearchParams(location.search);
  const limit = 10;
  const [page, setPage] = useState(
    location.state?.page ||
      location.state?.prev ||
      +searchParams.get("page") ||
      1
  );
  const [totalCount, setTotalcount] = useState(0);

  const getList = async () => {
    setIsLoading(true);
    const params = { page, limit };
    const result = await NotificationApi.getList(params);

    if (result?.list) {
      setNotificationList(result.list);
      setTotalcount(result.totalCount);
      const idList = result.list
        .filter((notification: any) => notification.isRead === 0)
        .map((notification: any) => notification.id);

      if (idList.length > 0) {
        await NotificationApi.updateAllReadInfo({ idList });
      }
    }
    setIsLoading(false);
  };

  const onClickCheckNotification = async (item: any) => {
    if (item.isRead === 0) {
      await NotificationApi.updateReadInfo(item.id, {});
    }

    if (item.type === "action") {
      if (item.action === "reload") {
        window.location.reload();
      }
    }
    if (item.type === "link") {
      if (item.linkUrl) {
        window.location.href = item.linkUrl;
      }
    }
  };

  useEffect(() => {
    getList();
    //listenForForegroundMessagesUpdate(getList);
    //listenForForegroundMessages(() => {
    //  getList();
    //});
    notificationListener.on("notification", getList);

    return () => {
      notificationListener.off("notification", getList);
    };
  }, [page]);

  return (
    <NotificationComponent
      isLoading={isLoading}
      notificationList={notificationList}
      onClickCheckNotification={onClickCheckNotification}
      // pagination
      limit={limit}
      page={page}
      setPage={setPage}
      totalCount={totalCount}
    />
  );
};

export default NotificationContainer;
