import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import CheckPassword from "../checkPassword/CheckPassword.container";

const UpdateEmailComponent = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    isCheckedPassword,
    setIsCheckedPassword,
    accountTypeCode,
    username,
    email,
    setEmail,
    code,
    setCode,
    isSendEmail,
    isCheckedToken,
    onClickBtnSendEmail,
    onClickBtnCheckVerificationToken,
    onClickBtnUpdateEmail,
  } = props;

  return !isCheckedPassword ? (
    <CheckPassword setIsCheckedPassword={setIsCheckedPassword} />
  ) : (
    <main className="email-update">
      <div className="con">
        <h2>EMAIL UPDATE</h2>
        <div className="form">
          <div className="con">
            <h3 className="title-con">
              {getLanguageValue(PAGE_TYPE.COMMON, "emailUpdate.txtEmail")}
            </h3>
            <p className="text">{username}</p>
          </div>
          <div className="con input">
            <div className="input-box">
              <label>
                *{getLanguageValue(PAGE_TYPE.COMMON, "emailUpdate.txtNewEmail")}
              </label>
              {accountTypeCode === "accountType:agency" ? (
                <div className="input-box-wrap">
                  <div className="input-prefix">
                    <input
                      type="text"
                      value={email}
                      onChange={(evt) => setEmail(evt.target.value)}
                      placeholder=""
                    />
                  </div>
                  <div className="fix">
                    <p>@{username.split("@")[1]}</p>
                  </div>
                </div>
              ) : (
                <input
                  type="text"
                  value={email}
                  onChange={(evt) => setEmail(evt.target.value)}
                  placeholder=""
                />
              )}
            </div>
            <div className="btn">
              <div className="update-btn">
                <button
                  onClick={(evt) => onClickBtnSendEmail()}
                  disabled={!email || isCheckedToken}>
                  {getLanguageValue(
                    PAGE_TYPE.COMMON,
                    "emailUpdate.btnSendCode"
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="con input">
            <div className="input-box">
              <label>
                *
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "emailUpdate.txtNewEmailVerificationCode"
                )}
              </label>
              <input
                type="text"
                value={code}
                onChange={(evt: any) => setCode(evt.target.value)}
                disabled={isCheckedToken}
                placeholder=""
              />
            </div>
            <div className="btn">
              <div className="update-btn">
                <button
                  onClick={(evt) => onClickBtnCheckVerificationToken()}
                  disabled={!code || !isSendEmail || isCheckedToken}>
                  {getLanguageValue(
                    PAGE_TYPE.COMMON,
                    "emailUpdate.btnVerifyCode"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="btn">
          <div className="cancel-btn">
            <button
              onClick={(evt) => {
                navigate(location.pathname.replace("/update-email", ""));
              }}>
              {getLanguageValue(PAGE_TYPE.COMMON, "emailUpdate.btnCancel")}
            </button>
          </div>
          <div className="update-btn">
            <button
              onClick={(evt) => onClickBtnUpdateEmail()}
              disabled={!isCheckedToken}>
              {getLanguageValue(PAGE_TYPE.COMMON, "emailUpdate.btnUpdate")}
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default UpdateEmailComponent;
