import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import LoadingComponent from "commons/loading/Loading.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { getCurrentLangName } from "helpers/String.helper";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import StudentEvaluationGraphContainer from "commons/evaluation/StudentEvalutionGraph.container";
import ThumbnailComponent from "commons/video/Thumbnail.component";
import YouTube, { YouTubeProps } from "react-youtube";

const AuditionApplicantComponent = (props: any) => {
  const navigate = useNavigate();
  const {
    id,
    auditionApplicantInfo,
    status,
    changeFavoriteStatus,
    //result,
    onChangeResult,
    memo,
    setMemo,
    btnRef,
    onClickBtnSave,
    toggleUserLike,
    // popup
    isShowPopup,
    isShowLoading,
    setIsShowPopup,
    sliderRef,
    initialSlideIdx,
    showImagePopup,
    // message
    messageRequest,
    createRequest,
    cancelRequest,
    // portfolio
    portfolioList,
    isShowTaggedStudentPopup,
    setIsShowTaggedStudentPopup,
    taggedStudentPopupInfo,
    setTaggedStudentPopupInfo,
    //
    setSelectedVideoUrl,
    setIsShowVideoPopup,
    isShowVideoPopup,
    selectedVideoUrl,
    setPortfolioList,
    getVideoUrl,
    selectedVideoId,
    setSelectedVideoId,
  } = props;

  const opts: YouTubeProps["opts"] = {
    height: "100%",
    width: "100%",
    playerVars: {},
  };

  const Arrow = () => {
    return <div />;
  };

  const settings = {
    className: "slider",
    infinite:
      auditionApplicantInfo.imageInfoList &&
      auditionApplicantInfo?.imageInfoList.length > 1
        ? true
        : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialSlideIdx,
    prevArrow: <Arrow />,
    nextArrow: <Arrow />,
  };

  const videoPopupRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [initFlag, setInitFlag] = useState(false);

  const getSelectedVideoUrl = async () => {
    const url = await getVideoUrl(selectedVideoId);
    setSelectedVideoUrl(url);
  };
  useEffect(() => {
    setCurrentTime(0);
    getSelectedVideoUrl();
  }, [selectedVideoId]);

  useEffect(() => {
    const video = videoPopupRef.current;

    if (video && isShowVideoPopup) {
      video.src = selectedVideoUrl;
      video.muted = true;
      video.playsInline = true;
      video.currentTime = currentTime;
      video.load();
      const handleLoadedData = () => {
        video.play().catch((e: any) => console.log(e));
      };

      video.addEventListener("loadeddata", handleLoadedData);
      video.addEventListener("play", handlePlay);
      video.addEventListener("pause", handlePause);
      video.addEventListener("ended", handleEnded);
      video.addEventListener("seeked", handleSeeked);

      return () => {
        video.removeEventListener("loadeddata", handleLoadedData);
        video.removeEventListener("play", handlePlay);
        video.removeEventListener("pause", handlePause);
        video.removeEventListener("ended", handleEnded);
        video.removeEventListener("seeked", handleSeeked);
      };
    }
  }, [isShowVideoPopup, selectedVideoUrl]);

  useEffect(() => {
    const handleInitFlagChange = async () => {
      if (!initFlag) {
        const url = await getVideoUrl(selectedVideoId);
        setSelectedVideoUrl(url);
        setInitFlag(false);
      }
    };

    handleInitFlagChange();
  }, [initFlag]);

  const handleEnded = () => {
    setCurrentTime(0);
  };

  const handleSeeked = () => {
    const video = videoPopupRef.current;
    setCurrentTime(video.currentTime);
  };

  const handlePlay = () => {
    setInitFlag(false);
  };

  const handlePause = () => {
    const video = videoPopupRef.current;
    setCurrentTime(video.currentTime);
    setInitFlag(true);
  };

  return (
    <main className="agency-audition-applicant">
      <div className="wannabe-info">
        <div className="wrap">
          <div
            className="back-btn"
            onClick={() => {
              navigate(-1);
            }}></div>
          {isShowLoading ? (
            <LoadingComponent />
          ) : (
            <>
              {auditionApplicantInfo.isActive === 1 ? (
                <div className="btn-con">
                  <button
                    className={
                      auditionApplicantInfo.isFavorited
                        ? "favorite-btn"
                        : "favorite-disabled-btn"
                    }
                    ref={btnRef}
                    onClick={(evt) =>
                      changeFavoriteStatus(!auditionApplicantInfo.isFavorited)
                    }>
                    <img
                      src={
                        auditionApplicantInfo.isFavorited
                          ? "./imgs/student/home/favorite-btn-2.png"
                          : "./imgs/student/home/favorite-btn-1.png"
                      }
                    />
                  </button>
                </div>
              ) : null}
              <img
                src={`${
                  auditionApplicantInfo &&
                  auditionApplicantInfo.profileImageInfo
                    ? auditionApplicantInfo.profileImageInfo.url.replace(
                        / /g,
                        "%20"
                      )
                    : "./imgs/dummy/basic-img-2.png"
                }`}
                alt="main profile image"
              />
              <div className="info-1">
                <div className="info-1-wrap">
                  <p className="name">
                    {auditionApplicantInfo.firstName}{" "}
                    {auditionApplicantInfo.lastName}
                  </p>
                  <p className="birth">
                    {getFormattedDateValue(
                      auditionApplicantInfo.birthday,
                      "YYYY.MM.DD"
                    )}
                  </p>
                  <p className="country">
                    {auditionApplicantInfo.countryCodeInfo
                      ? getCurrentLangName(
                          auditionApplicantInfo.countryCodeInfo.names
                        )
                      : ""}
                  </p>
                  <p>
                    {auditionApplicantInfo.height
                      ? `${auditionApplicantInfo.height}cm`
                      : null}
                    {auditionApplicantInfo.weight
                      ? ` / ${auditionApplicantInfo.weight}kg`
                      : null}
                    {auditionApplicantInfo.bloodTypeCodeInfo
                      ? ` / ${getCurrentLangName(
                          auditionApplicantInfo.bloodTypeCodeInfo.names
                        )}`
                      : ""}
                  </p>
                  <p>
                    {auditionApplicantInfo.specialtyCodeInfoList
                      ? auditionApplicantInfo.specialtyCodeInfoList.map(
                          (item: any) => (
                            <span>{getCurrentLangName(item.names)}</span>
                          )
                        )
                      : null}
                    {auditionApplicantInfo.mbtiCode ? (
                      <span>{auditionApplicantInfo.mbtiCode}</span>
                    ) : null}
                  </p>
                </div>
              </div>
              {auditionApplicantInfo.videoInfoList &&
              auditionApplicantInfo.videoInfoList.length > 0 ? (
                <div className="info-2">
                  <h2>MEDIA</h2>
                  <ul className="media-list-wrap">
                    {auditionApplicantInfo.videoInfoList.map(
                      (videoInfo: any, index: number) => (
                        <ThumbnailComponent
                          key={videoInfo.id}
                          videoId={videoInfo.id}
                          videoUrl={videoInfo.url}
                          setSelectedVideoUrl={setSelectedVideoUrl}
                          setSelectedVideoId={setSelectedVideoId}
                          setIsShowVideoPopup={setIsShowVideoPopup}
                          getVideoUrl={getVideoUrl}
                        />
                      )
                    )}
                  </ul>
                  {isShowVideoPopup ? (
                    <div id="popup" className="popup media-popup">
                      <div className="popup-content">
                        <span
                          className="close-btn"
                          onClick={(e) => {
                            setIsShowVideoPopup(false);
                          }}>
                          <img
                            src="./imgs/icons/close-white.png"
                            alt="close button"
                          />
                        </span>
                        <video
                          controls
                          ref={videoPopupRef}
                          muted
                          playsInline
                          preload={"auto"}>
                          <source src={selectedVideoUrl} type="video/mp4" />
                          {getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionApplicant.txtVideoGuide"
                          )}
                        </video>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
              <div className="info-3">
                <h2>STATISTICS</h2>
                <div className="wrap">
                  <div className="follower">
                    <img
                      src="./imgs/wannabe-info/follower.png"
                      alt="follower"
                    />
                    <span>0</span>
                  </div>
                  <div className="favorite">
                    <img
                      src="./imgs/wannabe-info/favorite.png"
                      alt="favorite"
                    />
                    <span>{auditionApplicantInfo.favoriteCnt}</span>
                  </div>
                  <div className="view">
                    <img src="./imgs/wannabe-info/view.png" alt="view" />
                    <span>{auditionApplicantInfo.viewCnt}</span>
                  </div>
                </div>
              </div>

              {/* <!-- 평가 --> */}
              {auditionApplicantInfo.evaluationInfoList &&
              auditionApplicantInfo.evaluationInfoList.length > 0 &&
              auditionApplicantInfo.schoolName ? (
                <section className="evaluation">
                  <div className="title">
                    <h2>EVALUATION</h2>
                  </div>
                  <div className="summary">
                    {auditionApplicantInfo.schoolName ? (
                      <span>by {auditionApplicantInfo.schoolName}</span>
                    ) : null}
                    <dl>
                      {auditionApplicantInfo.evaluationInfoList.map(
                        (item: any) => (
                          <>
                            <dt>
                              {getCurrentLangName(
                                item.evaluationCategoryCodeInfo.names
                              )}
                            </dt>
                            <dd>{item.evaluationScore}</dd>
                          </>
                        )
                      )}
                    </dl>
                  </div>

                  <StudentEvaluationGraphContainer
                    id={auditionApplicantInfo.userStudentId}
                  />
                </section>
              ) : null}

              {auditionApplicantInfo.imageInfoList &&
              auditionApplicantInfo.imageInfoList.length > 0 ? (
                <div className="info-9">
                  <h2>ADDITIONAL PHOTOS</h2>
                  <ul>
                    {auditionApplicantInfo.imageInfoList.map(
                      (imageInfo: any, index: number) => (
                        <li
                          key={index}
                          onClick={(evt) => showImagePopup(index)}>
                          <img
                            src={`${
                              imageInfo
                                ? imageInfo.url.replace(/ /g, "%20")
                                : ""
                            }`}
                            alt="additional photo"
                          />
                        </li>
                      )
                    )}
                  </ul>
                  {/* <!-- 이미지 클릭시 팝업 --> */}
                  {isShowPopup ? (
                    <div className="popup popup-wannabe-info">
                      <div
                        className="close-btn"
                        onClick={(evt) => setIsShowPopup(false)}></div>
                      <h2>PHOTOS</h2>
                      <div className="wrap">
                        <Slider ref={sliderRef} {...settings}>
                          {auditionApplicantInfo.imageInfoList.map(
                            (item: any) => (
                              <img className="img" src={`${item.url}`} />
                            )
                          )}
                        </Slider>
                        {auditionApplicantInfo.imageInfoList.length > 1 ? (
                          <>
                            <button
                              className="custom-prev"
                              onClick={(evt) => sliderRef.current.slickPrev()}
                            />
                            <button
                              className="custom-next"
                              onClick={(evt) => sliderRef.current.slickNext()}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}

              {portfolioList.length > 0 ? (
                <div className="info-10">
                  <h2>TAGGED PORTFOLIO</h2>
                  <ul className="new-portfolio-list-wrap">
                    {portfolioList.map((item: any, idx: number) => (
                      <li className="new-portfolio-list">
                        <div className="portfolio portfolio-3">
                          <div className="con">
                            <p className="portfolio-date">
                              {item.performedDate.replaceAll("-", ".")}
                            </p>
                          </div>
                          <div className="portfolio-img">
                            <a>
                              <div className="thumbnail">
                                <YouTube
                                  className="img"
                                  videoId={item.youtubeId}
                                  opts={opts}
                                  onStateChange={(e: any) => {
                                    // player state
                                    // 시작되지 않음: -1, 종료: 0, 재생 중: 1, 일시중지: 2, 버퍼링: 3, 동영상 신호: 5
                                    const videoState =
                                      e.target.getPlayerState();
                                    const newList = [...portfolioList];
                                    const selectedItem = newList.find(
                                      (v: any) => v.id === item.id
                                    );

                                    // 재생중일 때만 체크
                                    selectedItem.hideTitle =
                                      videoState === 1 ||
                                      videoState === 2 ||
                                      videoState === 3;
                                    setPortfolioList(newList);
                                  }}
                                />
                                {/* 재생중일 때 제목 영역 비노출*/}
                                {!item.hideTitle ? (
                                  <p className="ellipsis">{item.title}</p>
                                ) : null}
                              </div>
                            </a>
                            <div className="student-list">
                              <div className="student-img">
                                <ul
                                  onClick={(evt) => {
                                    setTaggedStudentPopupInfo(item);
                                    setIsShowTaggedStudentPopup(true);
                                  }}
                                  style={{
                                    width: "fit-content",
                                    cursor: "pointer",
                                  }}>
                                  {item.studentInfoList?.length > 0 &&
                                    item.studentInfoList
                                      .filter((_: any, idx: number) => idx < 4)
                                      .map((i: any, key: number) => (
                                        <li key={`student-${idx}-${key}`}>
                                          <img
                                            src={`${
                                              i.imageUrl
                                                ? i.imageUrl
                                                : "imgs/dummy/basic-img-2.png"
                                            }?w=100&h=100`}
                                            alt="Participating students"
                                          />
                                        </li>
                                      ))}
                                  {item.studentInfoList.length > 4 ? (
                                    <li className="over">
                                      <img
                                        src="./imgs/student/home/over.png"
                                        alt=""
                                      />
                                      <span>{`+${
                                        item.studentInfoList.length - 4
                                      }`}</span>
                                    </li>
                                  ) : null}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}

              <div className="memo-wrap">
                <h2>MEMO</h2>
                <div className="th-textarea">
                  <textarea
                    placeholder={getLanguageValue(
                      PAGE_TYPE.AGENCY,
                      "auditionMemoPopup.txtMemoPlaceholder"
                    )}
                    onChange={(e) => {
                      setMemo(e.target.value);
                    }}
                    value={memo}></textarea>
                </div>
                <div className="button-wrap">
                  <button
                    className="btn-save-memo"
                    ref={btnRef}
                    onClick={() => {
                      onClickBtnSave();
                    }}>
                    {getLanguageValue(
                      PAGE_TYPE.AGENCY,
                      "auditionMemoPopup.txtSave"
                    )}
                  </button>
                </div>
              </div>

              {status === "evaluation completed" ? (
                messageRequest ? (
                  messageRequest.messageStatusCode === "messageType:accept" ? (
                    <button
                      className="chat-request-btn"
                      onClick={() => {
                        navigate(
                          `/agency/message/${messageRequest.channelId}`,
                          {
                            state: {
                              schoolName:
                                auditionApplicantInfo?.schoolInfo?.name,
                            },
                          }
                        );
                      }}>
                      {getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "studentInfo.btnGoToMessagePage"
                      )}
                    </button>
                  ) : messageRequest.messageStatusCode ===
                    "messageType:request" ? (
                    <button
                      className="chat-request-btn"
                      onClick={cancelRequest}>
                      {getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "studentInfo.btnCancelRequest"
                      )}
                    </button>
                  ) : (
                    <button
                      className="chat-request-btn"
                      onClick={createRequest}>
                      {getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "studentInfo.btnRequestMessage"
                      )}
                    </button>
                  )
                ) : (
                  <button className="chat-request-btn" onClick={createRequest}>
                    {getLanguageValue(
                      PAGE_TYPE.AGENCY,
                      "studentInfo.btnRequestMessage"
                    )}
                  </button>
                )
              ) : null}

              <div className="judge-wrap">
                <div className="inner-wrap">
                  <div className="btn-judge-wrap">
                    {/* <!-- on className 추가해서 셀렉트 상태로 변경 --> */}
                    <button
                      className={`btn-judge btn-judge-hold ${
                        auditionApplicantInfo.result === "hold" ? "on" : ""
                      }`}
                      onClick={(e) => {
                        onChangeResult("hold");
                      }}>
                      HOLD
                    </button>
                    {/* <!-- on className 추가해서 셀렉트 상태로 변경 --> */}
                    <button
                      className={`btn-judge btn-judge-bad ${
                        auditionApplicantInfo.result === "bad" ? "on" : ""
                      }`}
                      onClick={(e) => {
                        onChangeResult("bad");
                      }}>
                      BAD
                    </button>
                    {/* <!-- on className 추가해서 셀렉트 상태로 변경 --> */}
                    <button
                      className={`btn-judge btn-judge btn-judge-good ${
                        auditionApplicantInfo.result === "good" ? "on" : ""
                      }`}
                      onClick={(e) => {
                        onChangeResult("good");
                      }}>
                      GOOD
                    </button>
                  </div>
                  <div className="btn-like-wrap">
                    <label className="th-like-toggle">
                      <input
                        type="checkbox"
                        checked={auditionApplicantInfo.isLiked}
                        onClick={(e: any) => {
                          toggleUserLike(!auditionApplicantInfo.isLiked);
                        }}
                      />
                      <div className="icon">
                        <img
                          className="off"
                          src="./imgs/icons/btn-like.png"
                          alt="like off"
                        />
                        <img
                          className="on"
                          src="./imgs/icons/btn-like-on.png"
                          alt="like off"
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* 태그된 학생 팝업 */}
      {isShowTaggedStudentPopup ? (
        <div className="popup popup-portfolio-tag-list">
          <div className="con">
            <div className="wrap">
              <div className="title">
                <p className="ellipsis">{taggedStudentPopupInfo.title}</p>
              </div>
              <div className="date">
                <p>
                  {taggedStudentPopupInfo.performedDate.replaceAll("-", ".")}
                </p>
              </div>
              <div className="list">
                <ul>
                  {taggedStudentPopupInfo.studentInfoList.length > 0 &&
                    taggedStudentPopupInfo.studentInfoList.map(
                      (item: any, idx: number) => (
                        <li key={`student-${idx}`}>
                          <div className="item">
                            <div
                              className="img"
                              style={{
                                backgroundImage: `url("${
                                  item.imageUrl
                                    ? item.imageUrl
                                    : "imgs/dummy/basic-img-2.png"
                                }?w=360&h=360")`,
                              }}></div>
                            <div className="name">
                              <p>{`${item.firstName} ${item.lastName}`}</p>
                            </div>
                          </div>
                        </li>
                      )
                    )}
                </ul>
              </div>
              <div className="control">
                <button
                  className="btn-close"
                  onClick={(evt) => setIsShowTaggedStudentPopup(false)}>
                  {getLanguageValue(PAGE_TYPE.SCHOOL, "portfolio.btnClose")}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </main>
  );
};
export default AuditionApplicantComponent;
