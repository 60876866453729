import * as BaseApi from "../Base.api";

export const getList = async (params: any) => {
  const url = "/agency/audition";
  return await BaseApi.get(url, params);
};

export const getInfo = async (id: number, params: any) => {
  const url = `/agency/audition/info/${id}`;
  return await BaseApi.get(url, params);
};

export const createInfo = async (params: any) => {
  const url = "/agency/audition";
  return await BaseApi.post(url, params);
};

export const updateInfo = async (id: number, params: any) => {
  const url = `/agency/audition/${id}`;
  return await BaseApi.patch(url, params);
};

export const updateMemoInfo = async (id: number, params: any) => {
  const url = `/agency/audition/memo/${id}`;
  return await BaseApi.patch(url, params);
};

export const updateResultInfo = async (id: number, params: any) => {
  const url = `/agency/audition/result/${id}`;
  return await BaseApi.patch(url, params);
};

export const updateAuditionEarlyCloseInfo = async (id: number, params: any) => {
  const url = `/agency/audition/early-close/${id}`;
  return await BaseApi.patch(url, params);
};

export const updateAuditionDoneInfo = async (id: number, params: any) => {
  const url = `/agency/audition/done/${id}`;
  return await BaseApi.patch(url, params);
};

export const getApplicantList = async (params: any) => {
  const url = "/agency/audition/applicant";
  return await BaseApi.get(url, params);
};

export const getApplicantInfo = async (id: number, params: any) => {
  const url = `/agency/audition/applicant/info/${id}`;
  return await BaseApi.get(url, params);
};

export const updateLike = async (id: number, params: any) => {
  const url = `/agency/audition/like/${id}`;
  return await BaseApi.patch(url, params);
};

export const getDailyApplicantCount = async (params: any) => {
  const url = `/agency/audition/applicant/count`;
  return await BaseApi.get(url, params);
};

export const getDailyViewCount = async (params: any) => {
  const url = `/agency/audition/view`;
  return await BaseApi.get(url, params);
};
