import * as BaseApi from "../Base.api";

export const getInfoForStudent = async () => {
  const url = "/student/profile";
  return await BaseApi.get(url);
};

export const createIntroVideo = async (params: any) => {
  const url = "/student/profile/intro-video";
  return await BaseApi.post(url, params);
};

export const createProfileImage = async (params: any) => {
  const url = "/student/profile/profile-image";
  return await BaseApi.post(url, params);
};

export const updateActiveProfile = async (params: any) => {
  const url = `/student/profile/active`;
  return await BaseApi.patch(url, params);
};

export const getListIntroVideo = async () => {
  const url = "/student/profile/intro-video";
  return await BaseApi.get(url);
};

export const deleteIntroVideo = async (id: number) => {
  const url = `/student/profile/intro-video/${id}`;
  return await BaseApi.remove(url);
};

export const getListProfileImage = async (params: any) => {
  const url = "/student/profile/profile-image";
  return await BaseApi.get(url, params);
};

export const deleteProfileImage = async (id: number) => {
  const url = `/student/profile/profile-image/${id}`;
  return await BaseApi.remove(url);
};

export const getListViewedAgency = async (params: any) => {
  const url = "/student/profile/agency";
  return await BaseApi.get(url, params);
};

export const getListPractice = async (params: any) => {
  const url = "/student/profile/practice";
  return await BaseApi.get(url, params);
};

export const getInfoPractice = async (id: number) => {
  const url = `/student/profile/practice/info/${id}`;
  return await BaseApi.get(url);
};

export const createPractice = async (params: any) => {
  const url = "/student/profile/practice";
  return await BaseApi.post(url, params);
};

export const updatePracticeVideo = async (id: number, params: any) => {
  const url = `/student/profile/practice/${id}`;
  return await BaseApi.patch(url, params);
};

export const deletePracticeVideo = async (id: number) => {
  const url = `/student/profile/practice/${id}`;
  return await BaseApi.remove(url);
};

export const getListPraciceViewedAgency = async (params: any) => {
  const url = "/student/profile/practice/agency";
  return await BaseApi.get(url, params);
};

export const updatePracticeVideoVisibility = async (
  id: number,
  params: any
) => {
  const url = `/student/profile/practice/visibility-code/${id}`;
  return await BaseApi.patch(url, params);
};
