import React, { useState, useEffect } from "react";
import { MainLayout } from "./commons";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import {
  // common
  CommonHome,
  CommonLogin,
  CommonAbout,
  CommonContact,
  CommonMission,
  CommonPartner,
  CommonSignup,
  CommonPasswordReset,
  CommonPasswordResetDone,
  CommonVerifyPasswordEmail,
  CommonVerifyPasswordEmailDone,
  CommonPricing,
  CommonTerm,
  CommonUpdatePassword,
  CommonUpdateEmail,
  CommonDeleteAccount,
  CommonVerifyEmail,
  CommonVerifyCastingEmail,
  CommonVerifyParentEmail,
  CommonAudition,
  CommonAuditionDetail,
  CommonStudentDetail,
  CommonSubscribeEmail,
  CommonSubscribeEmailDone,
  CommonUnSubscribeEmail,
  CommonUnSubscribeEmailDone,
  CommonNotice,
  CommonNoticeDetail,
  // agency
  AgencyHome,
  AgencySignupDone,
  AgencyWannabeInfo,
  AgencyWannabePracticeVideo,
  AgencyWannabePracticeVideoInfo,
  AgencyWannabeProfile,
  AgencyWannabeSchoolEvaluation,
  AgencyFavorite,
  AgencyAudition,
  AgencyAuditionNew,
  AgencyAuditionDetail,
  AgencyAuditionApplicant,
  AgencyMessage,
  AgencyMessageDetail,
  AgencySearch,
  AgencyMy,
  AgencyNotification,
  // school
  SchoolHome,
  SchoolSignupDone,
  SchoolStudent,
  SchoolStudentDetail,
  SchoolStudentRegister,
  SchoolPortfolio,
  SchoolPortfolioDetail,
  SchoolCreatePortfolio,
  SchoolUpdatePortfolio,
  SchoolAudition,
  SchoolMessage,
  SchoolMessageDetail,
  SchoolMy,
  SchoolNotification,
  // Student
  StudentHome,
  StudentProfile,
  StudentProfileIntro,
  StudentProfileImage,
  StudentPracticeDetail,
  StudentPracticeNew,
  StudentAudition,
  StudentAuditionDetail,
  StudentAuditionApply,
  StudentMessage,
  StudentMessageDetail,
  StudentMessageAgencyDetail,
  StudentMy,
  StudentNotification,
  StudentHomeV2,
  StudentPublicProfile,
  StudentPublicProfileEdit
} from "./pages";
import TagManager from "react-gtm-module";
import { PAGE_TYPE } from "enums";
import * as AuthApi from "api/common/Auth.api";
import * as commonData from "./data/common.data";

const Router = (props: any) => {
  const location = useLocation();
  const [isInit, setIsInit] = useState(false);

  const setCommonData = async () => {
    console.log("Router:setCommonData");

    const getAllCodeListResult = await AuthApi.getAllCodeList();
    commonData.setAllCodeList(getAllCodeListResult);
    commonData.setLanguageCode(navigator.language);

    setIsInit(true);
  };

  useEffect(() => {
    setCommonData();
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== "local") {
      TagManager.dataLayer({
        dataLayer: {
          event: "config",
          gtmId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID ?? "",
        },
      });
    }
  }, [location]);

  return (
    isInit && (
      <Routes>
        {/* common*/}
        <Route
          element={
            <MainLayout isPrivate={false} pageType={PAGE_TYPE.COMMON} />
          }>
          <Route path="/" element={<CommonHome />} />
          <Route path="/audition" element={<CommonAudition />} />
          <Route
            path="/audition/:id/:type"
            element={<CommonAuditionDetail />}
          />
          <Route path="/about" element={<CommonAbout />} />
          <Route path="/mission" element={<CommonMission />} />
          <Route path="/partner" element={<CommonPartner />} />
          <Route path="/pricing" element={<CommonPricing />} />
          <Route path="/contact" element={<CommonContact />} />
          <Route path="/wannabe/info/:slug" element={<CommonStudentDetail />} />
          <Route
            path="/subscribed/new-wannabe"
            element={<CommonSubscribeEmail />}
          />
          <Route
            path="/subscribed/new-wannabe/done"
            element={<CommonSubscribeEmailDone />}
          />
          <Route
            path="/unsubscribed/new-wannabe"
            element={<CommonUnSubscribeEmail />}
          />
          <Route
            path="/unsubscribed/new-wannabe/done"
            element={<CommonUnSubscribeEmailDone />}
          />
          <Route
            path="/verification/email/signup/:token"
            element={<CommonVerifyEmail />}
          />
          <Route
            path="/verification/email/casting/signup/:token"
            element={<CommonVerifyCastingEmail />}
          />
          <Route
            path="verification/email/parent/:token"
            element={<CommonVerifyParentEmail />}
          />
          <Route
            path="/verification/email/password/reset/:token"
            element={<CommonVerifyPasswordEmail />}
          />
          <Route
            path="/verification/email/password/reset/done"
            element={<CommonVerifyPasswordEmailDone />}
          />
          <Route path="/password/reset" element={<CommonPasswordReset />} />
          <Route
            path="/password/reset/done"
            element={<CommonPasswordResetDone />}
          />
        </Route>

        {/* 로그인한 사용자 접근 불가 페이지 */}
        <Route
          element={
            <MainLayout
              isPrivate={false}
              isSignOut={true}
              pageType={PAGE_TYPE.COMMON}
            />
          }>
          <Route path="/login" element={<CommonLogin />} />
          <Route path="/signup" element={<CommonSignup />} />
          <Route path="/signup/agency" element={<CommonSignup />} />
          <Route path="/signup/agency/done" element={<AgencySignupDone />} />
          <Route path="/signup/school" element={<CommonSignup />} />
          <Route path="/signup/student" element={<CommonSignup />} />
          <Route path="/signup/school/done" element={<SchoolSignupDone />} />
        </Route>

        {/* agency*/}
        <Route
          path="/agency"
          element={<MainLayout isPrivate={true} pageType={PAGE_TYPE.AGENCY} />}>
          <Route index element={<AgencyHome />} />
          <Route path="wannabe/info/:id" element={<AgencyWannabeInfo />} />
          {/* <Route path="wannabe/practice-video/:id" element={<AgencyWannabePracticeVideo />} /> */}
          {/* <Route path="wannabe/practice-video-info/:id" element={<AgencyWannabePracticeVideoInfo />} /> */}
          <Route path="wannabe/profile/:id" element={<AgencyWannabeProfile />} />
          <Route path="wannabe/:id" element={<AgencyWannabeInfo />} />
          <Route path="wannabe/school-evaluation/:id" element={<AgencyWannabeSchoolEvaluation />} />
          <Route path="favorite" element={<AgencyFavorite />} />
          <Route path="audition" element={<AgencyAudition />} />
          <Route path="audition/:id" element={<AgencyAuditionDetail />} />
          <Route path="audition/new" element={<AgencyAuditionNew />} />
          <Route
            path="audition/applicant/:id"
            element={<AgencyAuditionApplicant />}
          />
          <Route path="message" element={<AgencyMessage />} />
          <Route path="message/:id" element={<AgencyMessageDetail />} />
          <Route path="my" element={<AgencyMy />} />
          <Route path="my/update-password" element={<CommonUpdatePassword />} />
          <Route path="my/update-email" element={<CommonUpdateEmail />} />
          <Route path="my/update-agency" element={<AgencyMy />} />
          <Route path="my/update-caster" element={<AgencyMy />} />
          <Route path="my/update-sns" element={<AgencyMy />} />
          <Route path="my/update-artist" element={<AgencyMy />} />
          <Route path="my/delete-account" element={<CommonDeleteAccount />} />
          <Route path="search" element={<AgencySearch />} />
          <Route path="notification" element={<AgencyNotification />} />
          <Route path="notice" element={<CommonNotice />} />
          <Route path="notice/:id" element={<CommonNoticeDetail />} />
        </Route>

        {/* school*/}
        <Route
          path="/school"
          element={<MainLayout isPrivate={true} pageType={PAGE_TYPE.SCHOOL} />}>
          <Route index element={<SchoolHome />} />
          <Route path="student" element={<SchoolStudent />} />
          <Route path="student/:id" element={<SchoolStudentDetail />} />
          <Route path="student/new" element={<SchoolStudentRegister />} />
          <Route path="student/:id/edit" element={<SchoolStudentRegister />} />
          <Route path="portfolio" element={<SchoolPortfolio />} />
          <Route path="portfolio/:id" element={<SchoolPortfolioDetail />} />
          <Route path="portfolio/new" element={<SchoolCreatePortfolio />} />
          <Route
            path="portfolio/edit/:id"
            element={<SchoolUpdatePortfolio />}
          />
          <Route path="audition" element={<SchoolAudition />} />
          <Route path="message" element={<SchoolMessage />} />
          <Route path="message/:id" element={<SchoolMessageDetail />} />
          <Route path="my" element={<SchoolMy />} />
          <Route path="my/update-password" element={<CommonUpdatePassword />} />
          <Route path="my/update-email" element={<CommonUpdateEmail />} />
          <Route path="my/update-school" element={<SchoolMy />} />
          <Route path="my/update-contact" element={<SchoolMy />} />
          <Route path="my/update-sns" element={<SchoolMy />} />
          <Route path="my/delete-account" element={<CommonDeleteAccount />} />
          <Route path="notification" element={<SchoolNotification />} />
          <Route path="notice" element={<CommonNotice />} />
          <Route path="notice/:id" element={<CommonNoticeDetail />} />
        </Route>

        {/* student*/}
        <Route
          path="/student"
          element={
            <MainLayout isPrivate={true} pageType={PAGE_TYPE.STUDENT} />
          }>


          <Route index element={<StudentHome />} />
          <Route path="v2" element={<StudentHomeV2 />} />
          <Route path="profile" element={<StudentPublicProfile />} />
          <Route path="profile/edit" element={<StudentPublicProfileEdit />} />

          <Route path="agency-profile" element={<StudentProfile />} />
          <Route path="agency-profile/intro" element={<StudentProfileIntro />} />
          <Route
            path="agency-profile/:category/:type"
            element={<StudentProfileImage />}
          />
          {/* <Route
            path="profile/practice/:id"
            element={<StudentPracticeDetail />}
          />

          <Route path="profile/practice/new" element={<StudentPracticeNew />} />
          <Route
            path="profile/practice/:id/edit"
            element={<StudentPracticeNew />}
          /> */}

          <Route path="audition" element={<StudentAudition />} />
          <Route path="audition/:id" element={<StudentAuditionDetail />} />
          <Route path="audition/apply/:id" element={<StudentAuditionApply />} />
          <Route
            path="audition/apply/:id/id/:applicant"
            element={<StudentAuditionApply />}
          />
          <Route path="message" element={<StudentMessage />} />
          <Route path="message/:id" element={<StudentMessageDetail />} />
          <Route
            path="message/agency/:id"
            element={<StudentMessageAgencyDetail />}
          />
          <Route path="my" element={<StudentMy />} />
          <Route path="my/update-password" element={<CommonUpdatePassword />} />
          <Route path="my/update-email" element={<CommonUpdateEmail />} />
          {/* <Route path="my/update-profile" element={<StudentMy />} /> */}
          <Route path="my/update-contact" element={<StudentMy />} />
          <Route path="my/delete-account" element={<CommonDeleteAccount />} />
          <Route path="notification" element={<StudentNotification />} />
          <Route path="notice" element={<CommonNotice />} />
          <Route path="notice/:id" element={<CommonNoticeDetail />} />
        </Route>

        <Route path="/term/common/:id" element={<CommonTerm />} />
        <Route path="/term/etc/:id" element={<CommonTerm />} />

        {/* 404 */}
        <Route path="/*" element={<Navigate replace to="/" />} />
      </Routes>
    )
  );
};

export default Router;
