import * as BaseApi from "../Base.api";

export const getPopupList = async () => {
    const url = "/bbs/popup";
    return await BaseApi.get(url);
};

export const getImportantList = async () => {
    const url = "/bbs/important";
    return await BaseApi.get(url);
};

export const getList = async (params: any) => {
    const url = "/bbs/list";
    return await BaseApi.get(url, params);
};

export const getInfo = async (id: number) => {
    const url = `/bbs/info/${id}`;
    return await BaseApi.get(url);
};
