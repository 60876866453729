import React, { useEffect } from "react";
import AuditionComponent from "./Audition.component";

const AuditionContainer = (props: any) => {
  useEffect(() => {}, []);

  return <AuditionComponent {...props} />;
};

export default AuditionContainer;
