import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const UnSubscribeEmailComponent = (props: any) => {
  const { email, setEmail, unSubscribedEmail } = props;

  return (
    <main className="deny-email">
      <div className="con">
        <h2>
          {getLanguageValue(PAGE_TYPE.COMMON, "unSubscribeEmail.txtContent")}
        </h2>

        <div className="form">
          <div className="input-box">
            <input
              type="text"
              placeholder={getLanguageValue(
                PAGE_TYPE.COMMON,
                "unSubscribeEmail.emailPlaceholder"
              )}
              value={email}
              onChange={(evt) => setEmail(evt.target.value)}
            />
          </div>
        </div>

        <div className="btn">
          <div>
            <button onClick={unSubscribedEmail}>
              {getLanguageValue(
                PAGE_TYPE.COMMON,
                "unSubscribeEmail.btnRequestUnSubscribe"
              )}
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};
export default UnSubscribeEmailComponent;
