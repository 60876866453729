import { PAGE_TYPE } from "enums";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getLanguageValue } from "locales/i18n";
import LoadingComponent from "commons/loading/Loading.component";
import React from "react";
import { getCurrentLangName } from "helpers/String.helper";

const MessageListComponent = (props: any) => {
  const {
    request,
    channels,
    navigate,
    cancelRequest,
    handleLiClick,
    isPopup,
    popupItem,
    isRequestShowLoading,
    isChannelShowLoading,
  } = props;

  const lastMessageValue = (message: any) => {
    try {
      const obj = JSON.parse(message);

      return `(IMAGE) ${obj.userStudentName}`;
    } catch {
      return message;
    }
  };
  return (
    <main className="message-home">
      <div className="con">
        <h2>MESSAGE</h2>

        <div className="request-list">
          <div className="title">
            <img src="./imgs/icons/message.png" alt="message icon" />
            <p>
              SENT REQUEST
              <em>({request ? request.length : 0})</em>
            </p>
          </div>

          {isRequestShowLoading ? (
            <LoadingComponent />
          ) : request && request.length > 0 ? (
            <ul className="list">
              {request.map((item: any) =>
                item.userSchoolId ? (
                  <li key={item.id} onClick={() => handleLiClick(item)}>
                    <div
                      className={`agency-logo`}
                      style={{
                        backgroundImage: `url(${
                          item.userSchoolLogo
                            ? process.env.REACT_APP_PUBLIC_RESOURCE_URL_PREFIX +
                              item.userSchoolLogo.replace(/ /g, "%20") +
                              "?w=220&h=220"
                            : ""
                        })`,
                      }}></div>
                    <div className="wannabe-name ellipsis">
                      {item.userStudentFirstName} {item.userStudentLastName}
                    </div>
                    <div className="school-name ellipsis">
                      {item.userSchoolName}
                    </div>
                  </li>
                ) : (
                  <li key={item.id} onClick={() => handleLiClick(item)}>
                    <div
                      className={`agency-logo`}
                      style={{
                        backgroundImage: `url(${
                          item.userStudentProfileImage
                            ? process.env.REACT_APP_PUBLIC_RESOURCE_URL_PREFIX +
                              item.userStudentProfileImage.replace(
                                / /g,
                                "%20"
                              ) +
                              "?w=220&h=220"
                            : "./imgs/dummy/basic-img-2.png"
                        })`,
                      }}></div>
                    <div className="wannabe-name ellipsis">
                      {item.userStudentFirstName} {item.userStudentLastName}
                    </div>
                    <div className="school-name ellipsis">-</div>
                  </li>
                )
              )}
            </ul>
          ) : (
            <div className="no-content">
              <p>
                {getLanguageValue(PAGE_TYPE.AGENCY, "messageList.txtNoRequest")}
              </p>
            </div>
          )}
        </div>

        <div className="message-list">
          <div className="title">
            <p>
              LIST
              <em>({channels ? channels.length : 0})</em>
            </p>
          </div>
          {isChannelShowLoading ? (
            <LoadingComponent />
          ) : channels && channels.length > 0 ? (
            channels.map((channel: any, index: number) =>
              channel.userSchoolId ? (
                <div
                  key={index}
                  className={`list list-${index + 1}`}
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate(`/agency/message/${channel.channelId}`, {
                      state: {
                        schoolId: channel.userSchoolId,
                        schoolName: channel.schoolName,
                        userStudentId: channel.userStudentId,
                      },
                    });
                  }}>
                  <p className="school-name ellipsis">
                    {channel.schoolName}{" "}
                    <span>
                      {channel.country
                        ? getCurrentLangName(channel.country.names)
                        : ""}
                    </span>
                  </p>
                  {channel.lastMessageStatus === "read" ||
                  channel.lastMessageStatus === "sent" ? (
                    <p className="situation">{channel.lastMessageStatus}</p>
                  ) : (
                    <p className="situation not-read">
                      {channel.lastMessageStatus}
                      <span>({channel.lastMessageUnReadCount})</span>
                    </p>
                  )}

                  <div className="text ellipsis">
                    {lastMessageValue(channel.lastMessage)}
                  </div>
                  <p className="date-time">
                    {getFormattedDateValue(
                      channel.lastMessageCreatedAt,
                      "YYYY.MM.DD HH:mm:ss"
                    )}
                  </p>
                </div>
              ) : (
                <div
                  key={index}
                  className={`list list-${index + 1}`}
                  onClick={(e: any) => {
                    e.preventDefault();
                    navigate(`/agency/message/${channel.channelId}`, {
                      state: {
                        userStudentId: channel.userStudentId,
                        studentName:
                          channel.userStudentFirstName +
                          " " +
                          channel.userStudentLastName,
                      },
                    });
                  }}>
                  <p className="school-name ellipsis">
                    {channel.userStudentFirstName} {channel.userStudentLastName}{" "}
                    <span>
                      {channel.studentCountry
                        ? getCurrentLangName(channel.studentCountry.names)
                        : ""}
                    </span>
                  </p>
                  {channel.lastMessageStatus === "read" ||
                  channel.lastMessageStatus === "sent" ? (
                    <p className="situation">{channel.lastMessageStatus}</p>
                  ) : (
                    <p className="situation not-read">
                      {channel.lastMessageStatus}
                      <span>({channel.lastMessageUnReadCount})</span>
                    </p>
                  )}

                  <div className="text ellipsis">
                    {lastMessageValue(channel.lastMessage)}
                  </div>
                  <p className="date-time">
                    {getFormattedDateValue(
                      channel.lastMessageCreatedAt,
                      "YYYY.MM.DD HH:mm:ss"
                    )}
                  </p>
                </div>
              )
            )
          ) : (
            <div className="no-filter">
              <p>
                {getLanguageValue(PAGE_TYPE.AGENCY, "messageList.txtNoMessage")}
              </p>
            </div>
          )}
        </div>
      </div>

      {isPopup ? (
        <div className="popup popup-request-receive">
          <div className="con">
            <div className="wrap">
              <div
                className="close-btn"
                onClick={() => handleLiClick(popupItem)}></div>
              <h2>
                {getLanguageValue(
                  PAGE_TYPE.AGENCY,
                  "messageList.txtRequestInfo"
                )}
              </h2>
              <div className="info agency-info">
                <div className="item">
                  <p className="date-time">
                    {getFormattedDateValue(
                      popupItem.createdAt,
                      "YYYY.MM.DD HH:mm"
                    )}
                  </p>
                  <h2 className="agency-name">{popupItem.userSchoolName}</h2>
                  <div
                    className="wannabe-img"
                    style={{
                      backgroundImage: `url(${
                        popupItem.userStudentProfileImage
                          ? process.env.REACT_APP_PUBLIC_RESOURCE_URL_PREFIX +
                            popupItem.userStudentProfileImage.replace(
                              / /g,
                              "%20"
                            )
                          : "./imgs/dummy/basic-img-2.png"
                      }?w=300&h=300)`,
                    }}></div>
                  <div className="name">
                    <p className="wannabe-name">
                      {popupItem.userStudentFirstName}{" "}
                      {popupItem.userStudentLastName}
                    </p>
                  </div>
                </div>
              </div>
              <div className="cancel-btn">
                <button
                  onClick={() => {
                    cancelRequest(popupItem);
                  }}>
                  {getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "messageList.txtCancelRequest"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </main>
  );
};

export default MessageListComponent;
