const accessTokenKey = 'accessToken';
const isInitDataKey = 'isInit';

// value setting
const setLocalData = async (key: string, value: string): Promise<void> => {
  if ((window as any).flutter_inappwebview) {
    await (window as any).flutter_inappwebview.callHandler('setData', key, value);
  }
};

// get value
const getLocalData = async (key: string): Promise<string | null> => {
  if ((window as any).flutter_inappwebview) {
    return await (window as any).flutter_inappwebview.callHandler('getData', key);
  }
  return null;
};

// remove value
const removeLocalData = async (key: string): Promise<void> => {
  if ((window as any).flutter_inappwebview) {
    await (window as any).flutter_inappwebview.callHandler('removeData', key);
  }
};

// splash Hide
export const hideSplash = async (): Promise<void> => {
  if ((window as any).flutter_inappwebview) {
    await (window as any).flutter_inappwebview.callHandler('hideSplash');
  }
};

// set accessToken
export const setAppAccessToken = async (token: string): Promise<void> => {
  await setLocalData(accessTokenKey, token);
};

// get accessToken
export const getAppAccessToken = async (): Promise<string | null> => {
  return await getLocalData(accessTokenKey);
};

// remove accessToken
export const removeAppAccessToken = async (): Promise<void> => {
  await removeLocalData(accessTokenKey);
};

// set init data
export const setIsAppInitData = async (): Promise<void> => {
  await setLocalData(isInitDataKey, '1');
};

// get init data
export const getIsAppInitData = async (): Promise<string | null> => {
  return await getLocalData(isInitDataKey);
};
