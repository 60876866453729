import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import LoadingComponent from "commons/loading/Loading.component";

const VerifyPasswordEmailComponent = (props: any) => {
  const {
    isVerify,
    password,
    setPassword,
    confirmedPassword,
    setConfirmedPassword,
    onClickBtnUpdatePassword,
    btnRef,
  } = props;

  return (
    <main className="reset-password">
      {typeof isVerify !== "boolean" ? (
        <LoadingComponent />
      ) : isVerify ? (
        <div className="con">
          <h2>
            {getLanguageValue(
              PAGE_TYPE.COMMON,
              "verifyPasswordEmail.txtResetPassword"
            )}
          </h2>

          <div className="form">
            <div className="input-box">
              <input
                type="password"
                value={password}
                onChange={(evt) => setPassword(evt.target.value)}
                placeholder={getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "verifyPasswordEmail.txtEnterPassword"
                )}
              />
            </div>
            <div className="input-box">
              <input
                type="password"
                value={confirmedPassword}
                onChange={(evt) => setConfirmedPassword(evt.target.value)}
                placeholder={getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "verifyPasswordEmail.txtReenterPassword"
                )}
              />
            </div>
          </div>

          <div className="btn">
            <div>
              <button
                ref={btnRef}
                onClick={(evt) => onClickBtnUpdatePassword()}>
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "verifyPasswordEmail.btnSavePassword"
                )}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </main>
  );
};

export default VerifyPasswordEmailComponent;
