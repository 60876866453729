import * as BaseApi from "../Base.api";

export const getInfo = async (agencyId: number) => {
  const url = `/agency/info/${agencyId}`;
  return await BaseApi.get(url);
};

export const updateInfo = async (id: number, params: any) => {
  const url = `/agency/info/${id}`;
  return await BaseApi.put(url, params);
};

export const updateCasterInfo = async (id: number, params: any) => {
  const url = `/agency/caster/${id}`;
  return await BaseApi.put(url, params);
};

export const updateSnsInfo = async (id: number, params: any) => {
  const url = `/agency/sns/${id}`;
  return await BaseApi.put(url, params);
};

export const updateArtistInfo = async (params: any) => {
  const url = "/agency/artist";
  return await BaseApi.put(url, params);
};
