import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HomeComponent from "./Home.component";
import { createFavorite, deleteFavorite } from "api/student/Student.api";
import * as HomeApi from "api/home/Home.api";

const HomeContainer = (props: any) => {
  const navigate = useNavigate();
  const { info: myInfo } = useSelector((state: any) => state.user);

  const [spotlightStudentList, setSpotlightStudentList] = useState([]);
  const [popularStudentList, setPopularStudentList] = useState([]);
  const [newStudentList, setNewStudentList] = useState([]);

  const [wannabeAuditionList, setWannabeAuditionList] = useState([]);
  const [agencyAuditionList, setAgencyAuditionList] = useState([]);

  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);
  const [isLoading4, setIsLoading4] = useState(true);
  const [isLoading5, setIsLoading5] = useState(true);

  const [disableFavoriteButton, setDisableFavoriteButton] = useState(true);
  const sliderRef = useRef<any>(null);

  const studentListValue = async () => {
    const result = await HomeApi.getStudentList({ limit: 20 });

    if (result?.mostViewList) {
      setSpotlightStudentList(result.mostViewList);
    }

    if (result?.mostFavoriteList) {
      setPopularStudentList(result.mostFavoriteList);
    }

    if (result?.newList) {
        const newList = result.newList;
       
        // 전체 목록 shuffle
        for (let i = newList.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [newList[i], newList[j]] = [newList[j], newList[i]];
        }

        // list의 length가 8보다 큰 경우 8개까지만 노출
        if (newList.length > 10) {
            newList.splice(10);
        }
        
        setNewStudentList(newList);
    }

    setDisableFavoriteButton(false);
    setIsLoading1(false);
    setIsLoading2(false);
    setIsLoading3(false);
  };

  const getAuditionList = async () => {
    const wannabeAuditionresult = await HomeApi.getWannabeAuditionList({
      limit: 8,
    });
    const agencyAuditionresult = await HomeApi.getAgencyAuditionList({
      limit: 8,
    });

    if (wannabeAuditionresult?.list) {
      setWannabeAuditionList(wannabeAuditionresult.list);
    }

    if (agencyAuditionresult?.list) {
      setAgencyAuditionList(agencyAuditionresult.list);
    }

    setIsLoading4(false);
    setIsLoading5(false);
  };

  useEffect(() => {
    studentListValue();
    getAuditionList();
  }, []);

  return (
    <HomeComponent
      {...props}
      myInfo={myInfo}
      spotlightStudentList={spotlightStudentList}
      popularStudentList={popularStudentList}
      newStudentList={newStudentList}
      wannabeAuditionList={wannabeAuditionList}
      agencyAuditionList={agencyAuditionList}
      isLoading1={isLoading1}
      isLoading2={isLoading2}
      isLoading3={isLoading3}
      isLoading4={isLoading4}
      isLoading5={isLoading5}
      disableFavoriteButton={disableFavoriteButton}
      sliderRef={sliderRef}
    />
  );
};

export default HomeContainer;
