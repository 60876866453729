import React, { useEffect, useState } from "react";
import MessageListComponent from "./MessageListComponent";
import { getAgencyChannels } from "../../../api/common/Chat.api";
import {
  getMessageRequestByAgency,
  updateMessageInfo,
} from "../../../api/message/Message.api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const MessageListContainer = (props: any) => {
  const navigate = useNavigate();
  const { info: userInfo } = useSelector((state: any) => state.user);

  const [request, setRequest] = useState([]);
  const [channels, setChannels] = useState([]);
  const [isPopup, setIsPopup] = useState(false);
  const [popupItem, setPopupItem] = useState({});
  const [isRequestShowLoading, setIsRequestShowLoading] = useState(true);
  const [isChannelShowLoading, setIsChannelShowLoading] = useState(true);

  const getMessageRequestByAgencyValues = async () => {
    const requestResult = await getMessageRequestByAgency({
      status: "messageType:request",
    });
    if (requestResult.list) {
      setRequest(requestResult.list);
    } else {
      setRequest([]);
    }
    setIsRequestShowLoading(false);
  };

  const init = async () => {
    await getMessageRequestByAgencyValues();
    const channelResult = await getAgencyChannels();
    if (channelResult?.list) {
      setChannels(channelResult.list); // 채널 목록 설정
    }
    setIsChannelShowLoading(false);
  };

  const handleLiClick = (item: any) => {
    setIsPopup(!isPopup);
    setPopupItem(item);
  };

  const cancelRequest = async (item: any) => {
    if (
      window.confirm(
        getLanguageValue(PAGE_TYPE.AGENCY, "messageList.txtConfirmCancel")
      )
    ) {
      await updateMessageInfo(item.id, {
        messageType: "messageType:cancel",
      });
      await getMessageRequestByAgencyValues();
      setIsPopup(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <MessageListComponent
      request={request}
      userInfo={userInfo}
      channels={channels}
      navigate={navigate}
      cancelRequest={cancelRequest}
      handleLiClick={handleLiClick}
      isPopup={isPopup}
      popupItem={popupItem}
      isRequestShowLoading={isRequestShowLoading}
      isChannelShowLoading={isChannelShowLoading}
      {...props}
    />
  );
};

export default MessageListContainer;
