export const getQueryStringFromObject = (slug: any) => {
  if (typeof slug !== "object") {
    return "";
  }

  return (
    "?" +
    Object.keys(slug)
      .filter((key) => slug[key] != null && slug[key] !== "null")
      .map((key) => key + "=" + slug[key])
      .join("&")
  );
};
