import React, { useEffect, useRef, useState } from "react";
import ProfileImageComponent from "./ProfileImage.component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingComponent from "commons/loading/Loading.component";

import * as AgencyApi from "api/agency/Agency.api";
import * as ProfileApi from "api/student/Profile.api";
import * as DataApi from "api/common/Data.api";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const ProfileImageContainer = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [profileImageList, setProfileImageList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { category, type } = useParams();
  //
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [popupList, setPopupList] = useState([]);
  const [profileMediaType, setProfileMediaType] = useState("");
  const [isAgencyPopup, setIsAgencyPopup] = useState(false);
  const [agencyInfo, setAgencyInfo] = useState(null);

  const getProfileImageList = async () => {
    const result = await ProfileApi.getListProfileImage({
      profileImageCategoryCode: `profileImageCategory:${category}`,
      profileImageTypeCode: `profileImageType:${type}`,
    });

    if (result.list) {
      const updatedList = await Promise.all(
        result.list.map(async (image: any) => {
          const imageInfo = await DataApi.getImagePresignedUrl(image.imageId);
          if (imageInfo) {
            return {
              ...image,
              url: imageInfo.result,
            };
          }
          return image;
        })
      );
      setProfileImageList(updatedList);
    }
  };

  const deleteProfileImage = async (id: number) => {
    if (
      window.confirm(
        getLanguageValue(
          PAGE_TYPE.STUDENT,
          "profile.txtDeletePhotoConfirmation"
        )
      )
    ) {
      const result = await ProfileApi.deleteProfileImage(id);
      if (result) {
        await getProfileImageList();
      }
    }
  };

  const openViewPopup = async (
    profileMediaId: string,
    profileMediaType: string
  ) => {
    if (profileMediaId) {
      const result = await ProfileApi.getListViewedAgency({
        profileMediaId: profileMediaId,
        profileMediaType: profileMediaType,
      });

      if (result.list) {
        setPopupList(result.list);
      }
      setProfileMediaType(profileMediaType);
      setIsShowPopup(true);
    }
  };

  const openAgencyPopup = async (id: number) => {
    const result = await AgencyApi.getInfo(id);
    if (result.info) {
      setAgencyInfo(result.info);
    }
    setIsAgencyPopup(true);
  };

  const introProfileInit = async () => {
    setIsLoading(true);
    await getProfileImageList();
    setIsLoading(false);
  };

  useEffect(() => {
    if (!(category === "face" || category === "fullBody")) {
      navigate("/student/profile");
    }

    if (
      !(
        type === "front" ||
        type === "back" ||
        type === "right" ||
        type === "left"
      )
    ) {
      navigate("/student/profile");
    }

    introProfileInit();
  }, []);

  return isLoading ? (
    <LoadingComponent />
  ) : (
    <ProfileImageComponent
      {...props}
      category={category}
      type={type}
      profileImageList={profileImageList}
      deleteProfileImage={deleteProfileImage}
      //
      isShowPopup={isShowPopup}
      setIsShowPopup={setIsShowPopup}
      popupList={popupList}
      openViewPopup={openViewPopup}
      profileMediaType={profileMediaType}
      //
      isAgencyPopup={isAgencyPopup}
      setIsAgencyPopup={setIsAgencyPopup}
      agencyInfo={agencyInfo}
      openAgencyPopup={openAgencyPopup}
    />
  );
};

export default ProfileImageContainer;
