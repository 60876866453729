import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUpdatedFavoriteCount } from "reducers/Agency";

import { makeRank } from "helpers/Score.helper";

import * as AgencyApi from "api/student/Student.api";
import * as StudentApi from "api/student/Student.api";
import * as PortFolioApi from "api/school/Portfolio.api";
import * as MessageApi from "api/message/Message.api";

import WannabeInfoComponent from "./WannabeInfo.component";

const WannabeInfoContainer = (props: any) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [studentInfo, setStudentInfo] = useState<any>();
  const [portfolioList, setPortfolioList] = useState([]);
  const [tabName, setTabName] = useState('')

  const [isPublic, setIsPublic] = useState(null);

  // favorite
  const btnRef = useRef<any>();
  const [isFavorited, setIsFavorited] = useState(false);

  // image popup
  const sliderRef = useRef<any>(null);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [initialSlideIdx, setInitialSlideIdx] = useState(0);

  // message
  const [messageRequest, setMessageRequest] = useState<any>({});
  const messageBtnRef = useRef<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [isPortfolioShowLoading, setIsPortfolioShowLoading] = useState(true);

  const getInfo = async () => {
    const result = await AgencyApi.getInfoForAgency(+id);

    if (result?.info?.id) {
      setIsFavorited(result.info.isFavorited);
      getUpdatedFavoriteCount();

      // 정보조회 탭에서만 viewCount 업데이트
      await AgencyApi.updateViewCount({ userStudentId: id });
      
      setInfo(result.info);
    } else {
      setIsPublic(result.isActive);
    }
  };

  const setInfo = (info: any) => {
    const height = info?.height
      ? `${info.height}cm${
          info?.weight || info?.bloodTypeCodeInfo ? " / " : ""
        }`
      : "";
    const weight = info?.weight
      ? `${info.weight}kg${info?.bloodTypeCodeInfo ? " / " : ""}`
      : "";
    const bloodType = info?.bloodTypeCodeInfo
      ? info.bloodTypeCodeInfo.names.en
      : "";
    info.bodyInfo = `${height}${weight}${bloodType}`;

    info.latestEvaluationList.map((item: any) => {
      item.evaluation = makeRank(item.evaluationScore);
    });

    setStudentInfo(info);
  };

  const getPortfolioList = async () => {
    const result = await PortFolioApi.getListWithStudentId(+id);
    if (result.list.length > 0) {
      //setPortfolioList(result.list);
      const updatedList = result.list.map((item: any) => ({
        ...item,
        firstview: false,
      }));

      setPortfolioList(updatedList);
    }
    setIsPortfolioShowLoading(false);
  };

  const getUpdatedFavoriteCount = async () => {
    const result = await StudentApi.getUpdatedFavoriteCount();
    if (result) {
      dispatch(setUpdatedFavoriteCount(result.count));
    }
  };

  const changeFavoriteStatus = async () => {
    btnRef.current.disabled = true;

    const params = { studentId: studentInfo.id };
    const result = isFavorited
      ? await StudentApi.deleteFavorite(params)
      : await StudentApi.createFavorite(params);

    if (result) {
      isFavorited ? setIsFavorited(false) : setIsFavorited(true);
      btnRef.current.disabled = false;
    } else {
      btnRef.current.disabled = true;
    }
  };

  // message
  const getMessageRequest = async () => {
    const requestInfo = await MessageApi.getRequestMessage(+id);
    console.log(requestInfo);
    if (requestInfo?.info) {
      setMessageRequest(requestInfo.info);
    }

    setIsLoading(false);
  };

  const createRequest = async () => {
    messageBtnRef.current.disabled = true;
    const result = await MessageApi.createRequest({ studentId: id });
    if (result) {
      await getMessageRequest();
    }
    messageBtnRef.current.disabled = false;
  };

  const cancelRequest = async () => {
    messageBtnRef.current.disabled = true;
    if (messageRequest) {
      const result = await MessageApi.updateMessageInfo(messageRequest?.id, {
        messageType: "messageType:cancel",
      });
      if (result) {
        await getMessageRequest();
      }
    }
    messageBtnRef.current.disabled = false;
  };

  const showImagePopup = (idx: number) => {
    setIsShowPopup(true);
    setInitialSlideIdx(idx);
  };

  useEffect(() => {
    getInfo();
    getPortfolioList();
  }, []);

  useEffect(() => {
    //if (studentInfo?.userSchoolId) {
    getMessageRequest();
    // }
  }, [studentInfo?.id]);

  useEffect(() => {
    // 팝업 노출 시 스크롤 금지
    document.body.style.overflow = isShowPopup ? "hidden" : "unset";
  }, [isShowPopup]);

  return (
    <WannabeInfoComponent
      studentInfo={studentInfo}
      portfolioList={portfolioList}
      setPortfolioList={setPortfolioList}
      isPublic={isPublic}
      isPortfolioShowLoading={isPortfolioShowLoading}
      // favorite
      btnRef={btnRef}
      isFavorited={isFavorited}
      changeFavoriteStatus={changeFavoriteStatus}
      // image popup
      sliderRef={sliderRef}
      showImagePopup={showImagePopup}
      isShowPopup={isShowPopup}
      setIsShowPopup={setIsShowPopup}
      initialSlideIdx={initialSlideIdx}
      // message
      messageRequest={messageRequest}
      createRequest={createRequest}
      cancelRequest={cancelRequest}
      isLoading={isLoading}
      messageBtnRef={messageBtnRef}
    />
  );
};

export default WannabeInfoContainer;
