import React, { useEffect, useState } from "react";
import StudentEvaluationGraphComponent from "./StudentEvalutionGraph.component";
import { useNavigate } from "react-router-dom";
import * as EvaluationApi from "../../api/school/Evaluation.api";
import { getCommonCodeListByParent } from "data/common.data";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const StudentEvaluationGraphContainer = (props: any) => {
  const navigate = useNavigate();
  const { id } = props;
  const [evaluationCategory, setEvaluationCategory] = useState([]);
  const categoryResult = getCommonCodeListByParent("evaluationCategory");
  const [evaluationInfo, setEvaluationInfo] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [isFlag, setIsFlag] = useState(true);
  const [isShowLoading, setIsShowLoading] = useState(true);

  const getEvaluationList = async (categoryCode?: any) => {
    setIsDisabled(true);

    const studentId = id;

    if (categoryCode) {
      const params = {
        userStudentId: studentId,
        evaluationCategoryCode: categoryCode,
        limit: 5,
        offset: evaluationInfo[categoryCode]?.offset || 0,
      };

      const result = await EvaluationApi.getListByStudentId(params);

      if (result.list.length > 0) {
        const labels: any[] = [];
        const data: number[] = [];

        result.list.forEach((item: any) => {
          labels.push([
            item.round,
            `(${item.evaluationDate.replaceAll("-", ".").slice(2)})`,
          ]);
          data.push(item.evaluationScore);
        });

        const newEvaluationInfo = {
          labels: labels,
          data: data,
          hasPrev: labels[0][0] !== 1,
          hasNext: labels[labels.length - 1][0] < result.totalCount,
          round: `${labels[0][0]}${getLanguageValue(
            PAGE_TYPE.SCHOOL,
            "studentDetail.txtRounds"
          )} ~ ${labels[labels.length - 1][0]}${getLanguageValue(
            PAGE_TYPE.SCHOOL,
            "studentDetail.txtRounds"
          )}`,
          offset: evaluationInfo[categoryCode]?.offset || 0,
        };

        // 새로운 평가 정보를 해당 카테고리에 설정
        setEvaluationInfo((prevEvaluationInfo) => ({
          ...prevEvaluationInfo,
          [categoryCode]: newEvaluationInfo,
        }));
      }
    } else {
      const params = {
        userStudentId: studentId,
      };
      const result = await EvaluationApi.getAllListByStudentId(params);

      if (result && result.categories && result.categories.length > 0) {
        const newEvaluationInfo: any = {};

        result.categories.forEach((category: any) => {
          const { code, list, totalCount } = category;

          if (list.length > 0) {
            const labels: any[] = [];
            const data: number[] = [];

            list.forEach((item: any) => {
              labels.push([
                item.round,
                `(${item.evaluationDate.replaceAll("-", ".").slice(2)})`,
              ]);
              data.push(item.evaluationScore);
            });

            newEvaluationInfo[code] = {
              labels: labels,
              data: data,
              hasPrev: labels[0][0] !== 1,
              hasNext: labels[labels.length - 1][0] < totalCount,
              round: `${labels[0][0]}${getLanguageValue(
                PAGE_TYPE.SCHOOL,
                "studentDetail.txtRounds"
              )}${
                labels.length > 1
                  ? ` ~ ${labels[labels.length - 1][0]}${getLanguageValue(
                      PAGE_TYPE.SCHOOL,
                      "studentDetail.txtRounds"
                    )}`
                  : ""
              }`,
              offset: evaluationInfo[code]?.offset || 0,
            };
          }
        });

        setEvaluationInfo((prevEvaluationInfo: any) => ({
          ...prevEvaluationInfo,
          ...newEvaluationInfo,
        }));
      }
    }

    setIsDisabled(false);
  };

  const evaluationInit = async () => {
    const initialState = {
      labels: "",
      data: [] as any,
      hasPrev: false,
      hasNext: false,
      round: "",
      offset: 0,
    };

    const initialStateMap = categoryResult.reduce((acc: any, category: any) => {
      const { code } = category;
      acc[code] = {
        ...initialState,
        labels: [],
        data: [],
        offset: evaluationInfo[code]?.offset || 0,
      };
      return acc;
    }, {});

    setEvaluationInfo(initialStateMap);
  };

  const init = async () => {
    setEvaluationCategory(categoryResult);
    await evaluationInit();
    await getEvaluationList();
    setIsShowLoading(false);
  };

  useEffect(() => {
    if (!initialRender) {
      // setIsDisabled(true);
      getEvaluationList(selectedCategory);
      // setIsDisabled(false);
    } else {
      setInitialRender(false);
    }
  }, [isFlag]);

  useEffect(() => {
    init();
  }, []);

  return (
    <StudentEvaluationGraphComponent
      {...props}
      evaluationCategory={evaluationCategory}
      isDisabled={isDisabled}
      isFlag={isFlag}
      evaluationInfo={evaluationInfo}
      setEvaluationInfo={setEvaluationInfo}
      setSelectedCategory={setSelectedCategory}
      setIsFlag={setIsFlag}
      isShowLoading={isShowLoading}
    />
  );
};

export default StudentEvaluationGraphContainer;
