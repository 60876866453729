import React from "react";
import { useNavigate } from "react-router-dom";

import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getCurrentLangName } from "helpers/String.helper";

import StudentEvaluationGraphContainer from "commons/evaluation/StudentEvalutionGraph.container";
import LoadingComponent from "commons/loading/Loading.component";
import YouTube, { YouTubeProps } from "react-youtube";
import { createPortfolioNotificationInfo } from "api/notification/Notification.api";

const WannabeSchoolEvaluationComponent = (props: any) => {
    const navigate = useNavigate();

    const {
        studentInfo,
        portfolioList,
        setPortfolioList,
        isPublic,
        isPortfolioShowLoading,
        selectedPortfolio,
        setSelectedPortfolio,
        // favorite
        btnRef,
        isFavorited,
        changeFavoriteStatus,
        // image popup
        sliderRef,
        showImagePopup,
        isShowPopup,
        setIsShowPopup,
        initialSlideIdx,
        // message
        messageRequest,
        createRequest,
        cancelRequest,
        isLoading,
        messageBtnRef,
    } = props;

    const Arrow = () => {
        return <div />;
    };

    const settings = {
        className: "slider",
        infinite: studentInfo?.imageInfoList.length > 1 ? true : false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: initialSlideIdx,
        prevArrow: <Arrow />,
        nextArrow: <Arrow />,
    };

    const opts: YouTubeProps["opts"] = {
        height: "100%",
        width: "100%",
        playerVars: {},
    };

    const popupOpts: YouTubeProps["opts"] = {
        height: "100%",
        width: "100%",
        playerVars: { autoplay: 1 },
    };

    return (
        <main className="">
            {(studentInfo?.userSchoolId && !isLoading) || (!studentInfo?.userSchoolId && studentInfo?.id) ? (
                <>
                    <div className="area">
                        <div className="title">
                            {/* <!-- 선택한 title에 class active 추가 --> */}
                            <button onClick={(evt) => navigate(`/agency/wannabe/info/${studentInfo?.id}`)}>
                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeInfo.txtTitle")}
                            </button>
                            <button onClick={(evt) => navigate(`/agency/wannabe/practice-video/${studentInfo?.id}`)}>
                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabePracticeVideo.txtTitle")}
                            </button>
                            <button onClick={(evt) => navigate(`/agency/wannabe/profile/${studentInfo?.id}`)}>
                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtTitle")}
                            </button>
                            {studentInfo?.userSchoolId ? (
                                <button className="active">{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeSchoolEvaluation.txtTitle")}</button>
                            ) : null}
                        </div>
                        <div className="back-btn" onClick={(evt) => navigate(-1)}></div>
                    </div>

                    <div className="school-evaluation">
                        <div className="wrap">
                            {/* 평가 */}
                            {studentInfo.latestEvaluationList.length > 0 ? (
                                <section className="evaluation">
                                    <div className="title">
                                        <h2>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeSchoolEvaluation.txtDesc")}</h2>
                                    </div>
                                    <div className="summary">
                                        {studentInfo.schoolInfo ? <span>{`by ${studentInfo.schoolInfo.name}`}</span> : null}
                                        <dl>
                                            {studentInfo.latestEvaluationList.map((item: any, index: number) => (
                                                <React.Fragment key={`student-info-${index}`}>
                                                    <dt>{getCurrentLangName(item.evaluationCategoryCodeInfo.names)}</dt>
                                                    <dd>{item.evaluation}</dd>
                                                </React.Fragment>
                                            ))}
                                        </dl>
                                    </div>
                                    {/* 평가 그래프 */}
                                    <StudentEvaluationGraphContainer id={studentInfo.id} />
                                </section>
                            ) : (
                                <div className="no-data">
                                    <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeSchoolEvaluation.txtNoData")}</p>
                                </div>
                            )}

                            {/* 포트폴리오 목록 */}
                            {isPortfolioShowLoading ? (
                                <LoadingComponent />
                            ) : portfolioList.length > 0 ? (
                                <div className="info-10">
                                    <h2>PORTFOLIO</h2>
                                    <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeSchoolEvaluation.txtPortfolioDesc")}</p>
                                    <ul className="new-portfolio-list-wrap">
                                        {portfolioList.map((item: any, idx: number) => (
                                            <li className="new-portfolio-list" key={`port-${idx}`}>
                                                <div className="portfolio portfolio-3">
                                                    <div className="con">
                                                        <p className="portfolio-date">{item.performedDate.replaceAll("-", ".")}</p>
                                                    </div>
                                                    <div className="portfolio-img">
                                                        <a
                                                            href="#"
                                                            onClick={(e: any) => {
                                                                e.preventDefault();
                                                                setIsShowPopup(true);
                                                                setSelectedPortfolio(item);
                                                            }}>
                                                            <div className="thumbnail">
                                                                <YouTube
                                                                    className="img"
                                                                    videoId={item.youtubeId}
                                                                    opts={opts}
                                                                    onPlay={(e: any) => {
                                                                        e.target.stopVideo();
                                                                        setIsShowPopup(true);
                                                                        setSelectedPortfolio(item);
                                                                    }}
                                                                    // onStateChange={(e: any) => {
                                                                    //     // player state
                                                                    //     // 시작되지 않음: -1, 종료: 0, 재생 중: 1, 일시중지: 2, 버퍼링: 3, 동영상 신호: 5
                                                                    //     const videoState = e.target.getPlayerState();
                                                                    //     const newList = [...portfolioList];
                                                                    //     const selectedItem = newList.find((v: any) => v.id === item.id);

                                                                    //     // 재생중일 때만 체크
                                                                    //     selectedItem.hideTitle = videoState === 1 || videoState === 2 || videoState === 3;
                                                                    //     setPortfolioList(newList);

                                                                    //     if (videoState !== -1 && !item.firstview) {
                                                                    //         createPortfolioNotificationInfo({
                                                                    //             portfolioId: item.id,
                                                                    //         });
                                                                    //         setPortfolioList((prevList: any) =>
                                                                    //             prevList.map((prevItem: any) =>
                                                                    //                 prevItem.id === item.id ? { ...prevItem, firstview: true } : prevItem
                                                                    //             )
                                                                    //         );
                                                                    //     }
                                                                    // }}
                                                                />
                                                                {/* 재생중일 때 제목 영역 비노출*/}
                                                                {!item.hideTitle ? <p className="ellipsis">{item.title}</p> : null}
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    {isShowPopup ? (
                                        <div id="popup" className="popup media-popup agency-portfolio-popup">
                                            <div className="popup-content">
                                                <span
                                                    className="close-btn"
                                                    onClick={(e: any) => {
                                                        e.preventDefault();
                                                        setIsShowPopup(false);
                                                    }}>
                                                    <img src="./imgs/icons/close-btn.png" alt="close button" />
                                                </span>
                                                <div className="wrap">
                                                    <strong className="ellipsis">{selectedPortfolio.title}</strong>
                                                    <p className="date">{selectedPortfolio.performedDate.replaceAll("-", ".")}</p>
                                                </div>
                                                <YouTube className="img" videoId={selectedPortfolio.youtubeId} opts={popupOpts} />
                                                <div className="list">
                                                    <ul>
                                                        {selectedPortfolio.studentInfoList.map((item: any, index: number) => (
                                                            <li
                                                                key={`portfolio-std-${index}`}
                                                                onClick={(e: any) => {
                                                                    e.preventDefault();
                                                                    window.open(`/agency/wannabe/info/${item.id}`);
                                                                }}>
                                                                <div className="item">
                                                                    <div
                                                                        className="img"
                                                                        style={{
                                                                            backgroundImage: `url(${
                                                                                item.imageUrl ? item.imageUrl : "imgs/dummy/basic-img-2.png"
                                                                            })`,
                                                                        }}></div>
                                                                    <div className="name ellipsis">
                                                                        <p>
                                                                            {item.firstName} {item.lastName}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}

                            {/* 메세지 버튼 */}
                            <div className="floating-bar">
                                <div className="wrap">
                                    <div className="chat-request-btn">
                                        {messageRequest ? (
                                            messageRequest.messageStatusCode === "messageType:accept" ? (
                                                <button
                                                    ref={messageBtnRef}
                                                    onClick={() => {
                                                        messageBtnRef.current.disabled = true;
                                                        navigate(`/agency/message/${messageRequest.channelId}`, {
                                                            state: {
                                                                schoolName: studentInfo.schoolInfo?.name,
                                                            },
                                                        });
                                                        messageBtnRef.current.disabled = false;
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnGoToMessagePage")}
                                                </button>
                                            ) : messageRequest.messageStatusCode === "messageType:request" ? (
                                                <button
                                                    ref={messageBtnRef}
                                                    onClick={(e) => {
                                                        cancelRequest();
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnCancelRequest")}
                                                </button>
                                            ) : (
                                                <button
                                                    ref={messageBtnRef}
                                                    onClick={(e) => {
                                                        createRequest();
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnRequestMessage")}
                                                </button>
                                            )
                                        ) : (
                                            <button
                                                ref={messageBtnRef}
                                                onClick={(e) => {
                                                    createRequest();
                                                }}>
                                                {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnRequestMessage")}
                                            </button>
                                        )}
                                    </div>

                                    <div className="btn-con">
                                        <button
                                            className={isFavorited ? "favorite-btn" : "favorite-disabled-btn"}
                                            ref={btnRef}
                                            onClick={(evt) => changeFavoriteStatus()}>
                                            <img src={isFavorited ? "./imgs/student/home/favorite-btn-2.png" : "./imgs/student/home/favorite-btn-1.png"} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : isPublic !== null && !isPublic ? (
                <div className="private-profile">
                    <img src="./imgs/icons/lock-icon.png" alt="lock" />
                    <p>{getLanguageValue(PAGE_TYPE.AGENCY, "studentInfo.txtPrivateProfile")}</p>
                </div>
            ) : (
                <LoadingComponent />
            )}
        </main>
    );
};

export default WannabeSchoolEvaluationComponent;
