import cookie from "react-cookies";

export const setCookie = (key: string, value: string, expires: Date) => {
  if (!expires) {
    expires = new Date();
    expires.setDate(expires.getDate() + 1);
  }

  cookie.save(key, value, { expires, path: "/" });
};

export const getCookie = (key: string) => {
  return cookie.load(key);
};

export const removeCookie = (key: string) => {
  return cookie.remove(key, { path: "/" });
};
