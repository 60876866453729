import React, { useEffect, useRef, useState } from "react";
import AuditionDetailComponent from "./AuditionDetail.component";
import { useNavigate, useParams } from "react-router-dom";
import * as HomeApi from "api/home/Home.api";
import { useSelector } from "react-redux";
import * as CommentApi from "api/comment/Comment.api";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import * as AuditionApi from "api/student/Audition.api";

const AuditionDetailContainer = (props: any) => {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const [auditionInfo, setAuditionInfo] = useState(null);
  const [commentGuideValue, setCommentGuideValue] = useState(
    getLanguageValue(PAGE_TYPE.COMMON, "auditionDetail.txtNewComment")
  );
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [commentValue, setCommentValue] = useState("");
  const [commentList, setCommentList] = useState(null);
  const [commentTotalCount, setCommentTotalCount] = useState(0);
  const [replyCancelFlag, setReplyCancelFlag] = useState(false);
  const [accountId, setAccountId] = useState(0);
  const [accountType, setAccountType] = useState(0);
  const [isFavorited, setIsFavorited] = useState(false);

  const btnRef = useRef<any>();
  const likeBtnRef = useRef<any>();
  const favoriteRef = useRef<any>();
  const inputRef = useRef(null);

  const { isLogin, info: myInfo } = useSelector((state: any) => state.user);

  const processComments = (comments: any): any => {
    const parentComments = comments.filter(
      (comment: any) => comment.level === 0
    );
    const replyComments = comments.filter(
      (comment: any) => comment.level === 1
    );

    parentComments.sort(
      (a: any, b: any) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    );

    const commentMap = new Map<number, any>();
    parentComments.forEach((comment: any) => {
      comment.commentReplyList = [];
      commentMap.set(comment.id, comment);
    });

    replyComments.forEach((reply: any) => {
      const parentComment = commentMap.get(reply.seq);
      if (parentComment) {
        parentComment.commentReplyList.push(reply);
      }
    });

    return Array.from(commentMap.values());
  };

  const getAuditionInfo = async () => {
    const result = await HomeApi.getAuditionInfo(parseInt(id), {
      auditionType: type === "wannabes" ? "wannabe" : "ext",
    });

    if (result?.info) {
      setAuditionInfo(result.info);
      const processedComments = processComments(result.info.comment.list);
      setCommentList(processedComments);
      setIsFavorited(result.info.isFavorited);
      setCommentTotalCount(result.info.comment.totalCount);
    } else {
      navigate(`/audition`);
    }
  };

  const createComment = async () => {
    if (commentValue) {
      btnRef.current.disabled = true;
      const result = await CommentApi.createInfo({
        auditionId: parseInt(id),
        auditionType: type === "wannabes" ? "wannabe" : "ext",
        comment: commentValue,
        commentId: selectedCommentId,
      });

      if (result && result.status == 200) {
        await getAuditionInfo();
        setCommentValue("");
        setSelectedCommentId(null);
        setCommentGuideValue(
          getLanguageValue(PAGE_TYPE.COMMON, "auditionDetail.txtNewComment")
        );
        setReplyCancelFlag(false);
      }
      btnRef.current.disabled = false;
    }
  };

  const createRecomment = async (id: number, comment: string) => {
    if (inputRef.current) {
      inputRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      inputRef.current.focus();
    }
    setCommentGuideValue(
      `"${comment}" ${getLanguageValue(
        PAGE_TYPE.COMMON,
        "auditionDetail.txtReplyToComment"
      )}`
    );
    setSelectedCommentId(id);
    setReplyCancelFlag(true);
  };

  const delComment = async (id: number) => {
    if (
      window.confirm(
        getLanguageValue(
          PAGE_TYPE.COMMON,
          "auditionDetail.txtConfirmDeleteComment"
        )
      )
    ) {
      const result = await CommentApi.deleteInfo(id);

      if (result) {
        await getAuditionInfo();
      }
    }
  };

  const commentLike = async (id: number) => {
    if (isLogin) {
      likeBtnRef.current.disabled = true;
      const result = await CommentApi.createLikeInfo({
        commentId: id,
      });
      if (result && result.status == 200) {
        await getAuditionInfo();
      }
      likeBtnRef.current.disabled = false;
    } else {
      if (
        window.confirm(
          getLanguageValue(PAGE_TYPE.COMMON, "auditionDetail.txtLoginRequired")
        )
      ) {
        navigate("/login");
      }
    }
  };

  const commentUnLike = async (id: number) => {
    if (isLogin) {
      likeBtnRef.current.disabled = true;
      const result = await CommentApi.createUnLikeInfo(id);
      if (result && result.status == 200) {
        await getAuditionInfo();
      }
      likeBtnRef.current.disabled = false;
    } else {
      if (
        window.confirm(
          getLanguageValue(PAGE_TYPE.COMMON, "auditionDetail.txtLoginRequired")
        )
      ) {
        navigate("/login");
      }
    }
  };

  const replyCancel = async () => {
    setCommentValue("");
    setSelectedCommentId(null);
    setCommentGuideValue(
      getLanguageValue(PAGE_TYPE.COMMON, "auditionDetail.txtNewComment")
    );
    setReplyCancelFlag(false);
  };

  const applyAudition = async () => {
    if (isLogin) {
      if (type === "wannabes") {
        navigate(`/student/audition/${id}`);
      } else {
        if (auditionInfo.linkUrl) {
          if (auditionInfo.isEmailLink === 0) {
            window.open(auditionInfo.linkUrl);
          } else {
            window.location.href = `mailto:${auditionInfo.linkUrl}`;
          }
        }
      }
    } else {
      if (type === "wannabes") {
        if (
          window.confirm(
            getLanguageValue(
              PAGE_TYPE.COMMON,
              "auditionDetail.txtLoginRequired"
            )
          )
        ) {
          navigate("/login");
        }
      } else {
        if (auditionInfo.linkUrl) {
          if (auditionInfo.isEmailLink === 0) {
            window.open(auditionInfo.linkUrl);
          } else {
            window.location.href = `mailto:${auditionInfo.linkUrl}`;
          }
        }
      }
    }
  };

  const changeFavoriteStatus = async (isFavorited: number) => {
    if (isLogin) {
      favoriteRef.current.disabled = true;
      isFavorited ? setIsFavorited(true) : setIsFavorited(false);
      const result = isFavorited
        ? await AuditionApi.createFavoriteInfo({ auditionId: id })
        : await AuditionApi.deleteFavoriteInfo(parseInt(id), {
            auditionId: id,
          });

      favoriteRef.current.disabled = false;
    } else {
      if (
        window.confirm(
          getLanguageValue(PAGE_TYPE.COMMON, "auditionDetail.txtLoginRequired")
        )
      ) {
        navigate("/login");
      }
    }
  };

  useEffect(() => {
    if (type === "wannabes" || type === "agency") {
      if (isLogin) {
        setAccountType(myInfo.accountTypeCode);
        let accountId = 0;
        if (myInfo.accountTypeCode === "accountType:agency") {
          accountId = myInfo.userAgencyId;
        } else if (myInfo.accountTypeCode === "accountType:school") {
          accountId = myInfo.userSchoolId;
        } else if (myInfo.accountTypeCode === "accountType:student") {
          accountId = myInfo.userStudentId;
        }
        setAccountId(accountId);
      }
      getAuditionInfo();
    } else {
      navigate(`/audition`);
    }
  }, [id, type]);

  return (
    <AuditionDetailComponent
      {...props}
      type={type}
      auditionInfo={auditionInfo}
      commentList={commentList}
      commentTotalCount={commentTotalCount}
      isLogin={isLogin}
      myInfo={myInfo}
      commentValue={commentValue}
      commentGuideValue={commentGuideValue}
      setCommentValue={setCommentValue}
      createComment={createComment}
      createRecomment={createRecomment}
      delComment={delComment}
      commentLike={commentLike}
      commentUnLike={commentUnLike}
      btnRef={btnRef}
      likeBtnRef={likeBtnRef}
      replyCancelFlag={replyCancelFlag}
      replyCancel={replyCancel}
      accountId={accountId}
      accountType={accountType}
      applyAudition={applyAudition}
      inputRef={inputRef}
      //
      favoriteRef={favoriteRef}
      isFavorited={isFavorited}
      setIsFavorited={setIsFavorited}
      changeFavoriteStatus={changeFavoriteStatus}
    />
  );
};

export default AuditionDetailContainer;
