import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const UnSubscribeEmailDoneComponent = (props: any) => {
  const { email } = props;

  return (
    <main className="deny-complete">
      <div className="con">
        <h2>
          <span>{email}</span>{" "}
          {getLanguageValue(PAGE_TYPE.COMMON, "unSubscribeEmailDone.txtResult")}
        </h2>
      </div>
    </main>
  );
};
export default UnSubscribeEmailDoneComponent;
