import React, { useState, useEffect } from "react";
import * as StudentApi from "api/student/Student.api";
import PublicProfileComponent from "./PublicProfile.component";

import * as commonData from "data/common.data";
import { getCurrentLangName } from "helpers/String.helper";

const PublicProfileContainer = (props: any) => {
    const [studentInfo, setStudentInfo] = useState<any>({});

    const getInfo = async () => {
        const result = await StudentApi.getInfoForStudent();

        if (result?.info) {
            const { info } = result;
            setStudentInfo(info);

            if (info.specialtyCodeList?.length > 0) {
                let specialty = "";

                info.specialtyCodeList.map((code: any, idx: number) => {
                    const codeInfo = commonData.getCommonCodeListByParent("specialty").find((i: any) => i.code === code);

                    specialty =
                        idx + 1 < info.specialtyCodeList?.length
                            ? `${specialty}${getCurrentLangName(codeInfo.names)}, `
                            : `${specialty}${getCurrentLangName(codeInfo.names)}`;

                    info.specialty = specialty;
                });
            }
        }
    };

    useEffect(() => {
        getInfo();
    }, []);

    return <PublicProfileComponent studentInfo={studentInfo} />;
};

export default PublicProfileContainer;
