import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React from "react";

const StudentSignupPreComponent = (props: any) => {
  const {
    schoolId,
    schoolList,
    schoolInfo,
    selectedInfo,
    onSchoolChange,
    next,
  } = props;

  return (
    <main className="signup-form signup-form-choose-school">
      <div className="con">
        <h2>WANNABE SIGNUP</h2>
        {schoolId ? (
          <div className="form form-choose-school">
            <h3 className="school-name">
              {schoolInfo ? schoolInfo.schoolName : null}
            </h3>

            <div
              className="school-logo"
              style={{
                backgroundImage: schoolInfo
                  ? schoolInfo.logoImageInfo
                    ? `url("${schoolInfo.logoImageInfo.url}?w=400&h=400")`
                    : `url("./imgs/common/logo/empty-school-logo-2.png")`
                  : null,
              }}></div>

            <div className="desc">
              <p>
                {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtPreDesc")}
              </p>
            </div>
          </div>
        ) : (
          <div className="form form-choose-school">
            <h3>
              {getLanguageValue(
                PAGE_TYPE.COMMON,
                "studentSignup.txtPreChooseSchool"
              )}
            </h3>

            <div
              className="school-logo"
              style={{
                backgroundImage:
                  selectedInfo && selectedInfo.logoImageInfo
                    ? `url("${selectedInfo.logoImageInfo.url}?w=400&h=400")`
                    : `url("./imgs/common/logo/empty-school-logo-2.png")`,
              }}></div>

            <div className="input-box input-box-select-school">
              <select onChange={(e) => onSchoolChange(e.target.value)}>
                <option value="">
                  {getLanguageValue(
                    PAGE_TYPE.COMMON,
                    "studentSignup.txtPreNoSchool"
                  )}
                </option>
                {schoolList
                  ? schoolList.map((item: any, idx: number) => (
                      <option key={idx} value={item.id}>
                        {item.schoolName}
                      </option>
                    ))
                  : null}
              </select>
              <div className="desc">
                <p>
                  {getLanguageValue(
                    PAGE_TYPE.COMMON,
                    "studentSignup.txtPreNoSchoolDesc"
                  )}
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="controller">
          <button className="btn-next" onClick={next}>
            {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.btnNext")}
          </button>
        </div>
      </div>
    </main>
  );
};

export default StudentSignupPreComponent;
