import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import { setInfo as setUserInfo } from "reducers/User";

import * as AuthApi from "api/common/Auth.api";

import UpdateEmailComponent from "./UpdateEmail.component";

const UpdateEmailContainer = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { info: myInfo } = useSelector((state: any) => state.user);

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  const [isCheckedPassword, setIsCheckedPassword] = useState(false);

  const [isSendEmail, setIsSendEmail] = useState(false);
  const [isCheckedToken, setIsCheckedToken] = useState(false);

  const sendEmail = async () => {
    let sendEmail = "";
    if (myInfo.accountTypeCode === "accountType:agency") {
      sendEmail = email + "@" + myInfo.username.split("@")[1];
    } else {
      sendEmail = email;
    }

    const result: any = await AuthApi.sendEmailForEmailUpdate({
      username: sendEmail,
    });

    if (result.data.data.result) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "emailUpdate.sendEmail"));
      setIsSendEmail(true);
    }
  };

  const checkVerificationToken = async () => {
    const result = await AuthApi.verifyEmail(code);

    if (result) {
      alert(
        getLanguageValue(PAGE_TYPE.COMMON, "emailUpdate.verifyEmailSuccess")
      );
      setIsCheckedToken(true);
    }
  };

  const updateEmail = async () => {
    const result = await AuthApi.updateEmail({ username: email });

    if (result) {
      myInfo.username = email;
      dispatch(setUserInfo(myInfo));
      navigate(location.pathname.replace("/update-email", ""));
    }
  };

  return (
    <UpdateEmailComponent
      isCheckedPassword={isCheckedPassword}
      setIsCheckedPassword={setIsCheckedPassword}
      accountTypeCode={myInfo.accountTypeCode}
      username={myInfo.username}
      email={email}
      setEmail={setEmail}
      isSendEmail={isSendEmail}
      onClickBtnSendEmail={sendEmail}
      code={code}
      setCode={setCode}
      isCheckedToken={isCheckedToken}
      onClickBtnCheckVerificationToken={checkVerificationToken}
      onClickBtnUpdateEmail={updateEmail}
    />
  );
};

export default UpdateEmailContainer;
