import * as BaseApi from "../Base.api";

export const getRequestMessage = async (id: number) => {
  const url = "/agency/message/request";

  return await BaseApi.get(`${url}/${id}`);
};

export const createRequest = async (params: any) => {
  const url = "/message/request";

  return await BaseApi.post(url, params);
};

export const updateMessageInfo = async (id: number, params: any) => {
  const url = "/message/status";

  return await BaseApi.patch(`${url}/${id}`, params);
};

export const getMessageRequestByAgency = async (slug = {}) => {
  const url = "/agency/message/request";

  return await BaseApi.get(url, slug);
};

export const getMessageRequestBySchool = async (slug = {}) => {
  const url = "/school/message/request";

  return await BaseApi.get(url, slug);
};

export const getMessageRequestByStudent = async (slug = {}) => {
  const url = "/student/message/request";

  return await BaseApi.get(url, slug);
};

export const createReportMessageInfo = async (params: any) => {
  const url = "/message/report";

  return await BaseApi.post(url, params);
};

export const createFeedbackInfo = async (params: any) => {
    const url = "/message/feedback";
  
    return await BaseApi.post(url, params);
  };