import React from "react";
import { useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import LoadingComponent from "commons/loading/Loading.component";
import PushCheckComponent from "commons/my/PushCheck.component";

const MyComponent = (props: any) => {
    const navigate = useNavigate();

    const {
        email,
        agencyInfo,
        setTermId,
        marketingTermList,
        termList,
        setUpdateType,
        onClickBtnDownloadFile,
        updateMarketingTermList,
        sendVerifyEmail,
        logout,
    } = props;

    return (
        <main className="mypage casting-manager-mypage">
            <div className="con">
                <h2>MY</h2>
                {agencyInfo?.id ? (
                    <>
                        <h2>ACCOUNT INFORMATION</h2>
                        <div className="form account">
                            <div className="con">
                                <h3 className="title-con">{getLanguageValue(PAGE_TYPE.AGENCY, "my.txtEmail")}</h3>
                                <p className="text">{email}</p>
                            </div>
                            <div className="btn">
                                <div className="email-btn">
                                    <button
                                        onClick={(evt) => {
                                            navigate("/agency/my/update-email");
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.AGENCY, "my.btnUpdateEmail")}
                                    </button>
                                </div>
                                <div className="password-btn">
                                    <button
                                        onClick={(evt) => {
                                            navigate("/agency/my/update-password");
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.AGENCY, "my.btnUpdatePassword")}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="form">
                            <h2>ESSENTIAL INFORMATION</h2>
                            <div className="con img-con">
                                <div className="bussiness-card">
                                    <div className="wrap bussiness-card-wrap">
                                        <img src={`${agencyInfo?.businessCardFrontImageInfo?.url}`} />
                                    </div>
                                </div>
                                <div className="bussiness-card">
                                    <div className="wrap bussiness-card-wrap">
                                        <img src={`${agencyInfo?.businessCardBackImageInfo?.url}`} />
                                    </div>
                                </div>
                            </div>
                            <div className="con">
                                <h3 className="title-con">{getLanguageValue(PAGE_TYPE.AGENCY, "my.txtName")}</h3>
                                <p className="text">{agencyInfo.name}</p>
                            </div>
                            <div className="con">
                                <h3 className="title-con">{getLanguageValue(PAGE_TYPE.AGENCY, "my.txtPhoneNumber")}</h3>
                                <p className="text">{agencyInfo.cellphone}</p>
                            </div>
                            <div className="btn">
                                <div className="update-btn">
                                    <button
                                        onClick={(evt) => {
                                            navigate("/agency/my/update-agency");
                                            setUpdateType("agency");
                                        }}>
                                        UPDATE ESSENTIAL INFORMATION
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="form">
                            <div className="agreement-form">
                                <ul className="list">
                                    {marketingTermList?.length > 0 &&
                                        marketingTermList.map((item: any, idx: number) => (
                                            <li key={`term-${idx}`} className="item">
                                                <label className="checkbox" style={{ alignItems: "start" }}>
                                                    <input
                                                        type="checkbox"
                                                        onChange={(evt) => {
                                                            if (!item.disabled) {
                                                                setTermId(item.id);
                                                                updateMarketingTermList(idx, evt.target.checked);
                                                            }
                                                        }}
                                                        checked={item.hasAgreed}
                                                    />
                                                    <span
                                                        className="txt"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={(evt) => window.open(`/term/etc/${item.termsId}`)}>
                                                        {`(${getLanguageValue(PAGE_TYPE.AGENCY, "my.txtOptional")}) ${item.title}`}
                                                    </span>
                                                </label>
                                            </li>
                                        ))}
                                </ul>
                                <ul className="link">
                                    {termList.map((item: any, idx: number) => (
                                        <li key={`term2-${idx}`}>
                                            <a onClick={(evt) => window.open(`/term/common/${item.id}`)}>{item.title}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="agreement-form">
                                <ul className="list">
                                    <PushCheckComponent></PushCheckComponent>
                                </ul>
                            </div>
                        </div>

                        {!agencyInfo.hasVerifiedEmail ? (
                            <div className="btn">
                                <div className="update-btn">
                                    <button onClick={(evt) => sendVerifyEmail()}>{getLanguageValue(PAGE_TYPE.AGENCY, "my.btnSendVerifiedEmail")}</button>
                                </div>
                            </div>
                        ) : null}

                        <div className="btn logout-btn">
                            <button
                                onClick={(evt) => {
                                    navigate("/agency/notice");
                                }}>
                                {getLanguageValue(PAGE_TYPE.AGENCY, "my.btnNotice")}
                            </button>
                        </div>

                        <div className="btn logout-btn">
                            <button
                                onClick={(evt) => {
                                    logout();
                                }}>
                                {getLanguageValue(PAGE_TYPE.AGENCY, "my.btnLogout")}
                            </button>
                        </div>

                        <div className="btn update-btn">
                            <button onClick={(evt) => navigate("/agency/my/delete-account")}>
                                {getLanguageValue(PAGE_TYPE.AGENCY, "my.btnDeleteAccount")}
                            </button>
                        </div>
                    </>
                ) : (
                    <LoadingComponent />
                )}
            </div>
        </main>
    );
};

export default MyComponent;
