import React from "react";
import { useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";

import LoadingComponent from "commons/loading/Loading.component";
import { getCommonCodeInfo } from "data/common.data";
import PushCheckCoponent from "commons/my/PushCheck.component";

const MyComponent = (props: any) => {
    const navigate = useNavigate();
    const {
        email,
        studentInfo,
        setTermId,
        marketingTermList,
        termList,
        setUpdateType,
        parentEmail,
        setParentEmail,
        isDisabled,
        isActive,
        updateMarketingTermList,
        updateActive,
        sendVerifyEmail,
        sendVerifyParentEmail,
        logout,
    } = props;

    return (
        <main className="mypage school-mypage">
            <div className="con">
                <h2>MY</h2>
                {studentInfo?.id ? (
                    <>
                        {/* 계정 정보 */}
                        <h2>ACCOUNT INFORMATION</h2>
                        <div className="form account">
                            <div className="con">
                                <h3 className="title-con">{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtEmail")}</h3>
                                <p className="text">{email}</p>
                            </div>
                            <div className="btn">
                                <div className="email-btn">
                                    <button
                                        onClick={(evt) => {
                                            navigate("/student/my/update-email");
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnUpdateEmail")}
                                    </button>
                                </div>
                                <div className="password-btn">
                                    <button
                                        onClick={(evt) => {
                                            navigate("/student/my/update-password");
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnUpdatePassword")}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="form">
                            <h2>EMERGENCY CONTACT</h2>

                            {studentInfo.contactTypeCode ? (
                                <div className="con">
                                    <h3 className="title-con">{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtContactType")}</h3>
                                    <p className="text">{getCurrentLangName(getCommonCodeInfo(studentInfo.contactTypeCode)?.names)}</p>
                                </div>
                            ) : null}
                            {studentInfo.contact ? (
                                <div className="con">
                                    <h3 className="title-con">{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtContactDetail")}</h3>
                                    <p className="text">{studentInfo.contact}</p>
                                </div>
                            ) : null}

                            <div className="btn">
                                <div className="update-btn">
                                    <button
                                        onClick={(evt) => {
                                            navigate("/student/my/update-contact");
                                            setUpdateType("contact");
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnContactProfile")}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* 약관 정보 */}
                        <div className="form">
                            <div className="agreement-form">
                                <ul className="list">
                                    {marketingTermList?.length > 0 &&
                                        marketingTermList.map((item: any, idx: number) => (
                                            <li key={`term-${idx}`} className="item">
                                                <label className="checkbox" style={{ alignItems: "start" }}>
                                                    <input
                                                        type="checkbox"
                                                        onChange={(evt) => {
                                                            if (!item.disabled) {
                                                                setTermId(item.id);
                                                                updateMarketingTermList(idx, evt.target.checked);
                                                            }
                                                        }}
                                                        checked={item.hasAgreed}
                                                    />
                                                </label>
                                                <span className="txt" style={{ cursor: "pointer" }} onClick={(evt) => window.open(`/term/etc/${item.termsId}`)}>
                                                    {`(${getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtOptional")}) ${item.title}`}
                                                </span>
                                            </li>
                                        ))}
                                </ul>
                                <ul className="link">
                                    {termList.map((item: any, idx: number) => (
                                        <li key={`term2-${idx}`}>
                                            <a onClick={(evt) => window.open(`/term/common/${item.id}`)}>{item.title}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="btn">
                            <div className="update-btn">
                                <button onClick={(evt) => navigate("/student/notice")}>{getLanguageValue(PAGE_TYPE.STUDENT, "my.btnNotice")}</button>
                            </div>
                        </div>

                        {/* 인증 이메일 재발송 */}
                        {!studentInfo.hasVerifiedEmail ? (
                            <div className="btn">
                                <div className="update-btn">
                                    <button onClick={(evt) => sendVerifyEmail()}>{getLanguageValue(PAGE_TYPE.STUDENT, "my.btnSendVerifiedEmail")}</button>
                                </div>
                            </div>
                        ) : null}

                        {/* 법적 대리인 이메일 재발송 */}
                        {studentInfo.under14 && !studentInfo.isParentConfirm ? (
                            <div className="parent-email">
                                <label>{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtParentEmail")}</label>
                                <div>
                                    <button onClick={(evt) => sendVerifyParentEmail()}>{getLanguageValue(PAGE_TYPE.STUDENT, "my.btnSendEmail")}</button>
                                    <input value={parentEmail} onChange={(evt) => setParentEmail(evt.target.value)} placeholder="" />
                                </div>
                            </div>
                        ) : null}

                        {/* 프로필 공개 여부 */}
                        {/* <div className="active">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    onChange={(evt) => {
                                        if (!isDisabled) {
                                            updateActive();
                                        }
                                    }}
                                    checked={isActive}
                                    disabled={studentInfo.under14 && !studentInfo.isParentConfirm}
                                />
                            </label>
                            <span>
                                {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtActiveProfile")}
                                {studentInfo.under14 && !studentInfo.isParentConfirm
                                    ? `(${getLanguageValue(PAGE_TYPE.STUDENT, "my.txtActivationAfterVerification")})`
                                    : null}
                            </span>
                        </div> */}
                        
                        <PushCheckCoponent/>

                        {/* 회원 탈퇴 */}
                        <div className="btn">
                            <div className="update-btn" onClick={(evt) => navigate("/student/my/delete-account")}>
                                <button>{getLanguageValue(PAGE_TYPE.STUDENT, "my.btnDeleteAccount")}</button>
                            </div>
                        </div>

                        {/* 로그아웃 */}
                        <div className="btn">
                            <div
                                className="update-btn"
                                onClick={(evt) => {
                                    logout();
                                }}>
                                <button>{getLanguageValue(PAGE_TYPE.STUDENT, "my.btnLogout")}</button>
                            </div>
                        </div>
                    </>
                ) : (
                    <LoadingComponent />
                )}
            </div>
        </main>
    );
};
export default MyComponent;
