import React, { useEffect } from "react";
import AboutComponent from "./About.component";

const AboutContainer = (props: any) => {
  useEffect(() => {}, []);

  return <AboutComponent {...props} />;
};

export default AboutContainer;
