import React, { useEffect } from "react";
import Pagination from "../../../commons/pagination/Pagination.container";
import AuditionInfoPopupContainer from "./AuditionInfoPopup.container";
import AuditionMemoPopupContainer from "./AuditionMemoPopup.container";
import AuditionClosePopupContainer from "./AuditionClosePopup.container";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";
import { useLocation, useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import LoadingComponent from "commons/loading/Loading.component";

const AuditionCloseComponent = (props: any) => {
  const navigate = useNavigate();
  const state = useLocation().state;
  const {
    id,
    isLoading,
    auditionApplicantList,
    setAuditionApplicantList,
    auditionInfo,
    go,
    toggleUserLike,
    updateResultInfo,
    updateAuditionDoneInfo,
    btnRef,
    // search
    search,
    keywordFilter,
    setKeywordFilter,
    resultFilter,
    setResultFilter,
    isLikedFilter,
    setIsLikedFilter,
    toggleLike,
    // pagination
    limit,
    prev,
    page,
    setPage,
    totalCount,
    // popup
    isShowAuditionInfoPopup,
    setIsShowAuditionInfoPopup,
    isShowAuditionMemoPopup,
    setIsShowAuditionMemoPopup,
    isShowAuditionClosePopup,
    setIsShowAuditionClosePopup,
    memoItem,
    setMemoItem,
  } = props;

  return (
    <main className="">
      {auditionInfo ? (
        <div className="audition-list-table">
          <div
            className="back-btn"
            onClick={(evt) =>
              navigate("/agency/audition", { state: { page: prev } })
            }></div>
          <div className="title">
            <h2>
              {auditionInfo.title}
              <img
                src="./imgs/icons/info-icon.png"
                alt="audition information"
                onClick={() => {
                  setIsShowAuditionInfoPopup(true);
                }}
              />
            </h2>
          </div>
          <div className="period-status">
            <p>
              {`${getFormattedDateValue(
                auditionInfo.startDate,
                "YYYY.MM.DD"
              )} ~ ${getFormattedDateValue(
                auditionInfo.endDate,
                "YYYY.MM.DD"
              )}`}
              <span>
                {getLanguageValue(PAGE_TYPE.AGENCY, "auditionClose.txtStatus")}
              </span>
            </p>
          </div>

          <div className="search-wrap">
            <div className="center">
              {isLikedFilter ? (
                <img
                  src="./imgs/icons/like.png"
                  alt="like"
                  onClick={() => toggleLike()}
                />
              ) : (
                <img
                  src="./imgs/icons/like-toggle.png"
                  alt="like"
                  onClick={() => toggleLike()}
                />
              )}
              <div className="col col-status">
                <div className="select-list">
                  <select onChange={(e) => setResultFilter(e.target.value)}>
                    <option value="">
                      {getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "auditionClose.txtFilterSelectAll"
                      )}
                    </option>
                    <option value="unrated">
                      {getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "auditionClose.txtFilterSelectUnrated"
                      )}
                    </option>
                    <option value="hold">
                      {getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "auditionClose.txtFilterSelectHold"
                      )}
                    </option>
                    <option value="bad">
                      {getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "auditionClose.txtFilterSelectBad"
                      )}
                    </option>
                    <option value="good">
                      {getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "auditionClose.txtFilterSelectGood"
                      )}
                    </option>
                  </select>
                </div>
              </div>
              <div className="col col-input">
                <div className="input-wrap">
                  <input
                    type="text"
                    placeholder="Searching Keyword"
                    value={keywordFilter}
                    onChange={(e) => setKeywordFilter(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        search();
                      }
                    }}
                  />
                  <div className="btn-search-icon" onClick={() => search()}>
                    <img
                      src="./imgs/icons/icon-search.png"
                      alt={getLanguageValue(
                        PAGE_TYPE.AGENCY,
                        "auditionClose.txtSearch"
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-close">
              <button
                ref={btnRef}
                onClick={() => {
                  updateAuditionDoneInfo();
                }}>
                {getLanguageValue(PAGE_TYPE.AGENCY, "auditionClose.btnDone")}
              </button>
            </div>
          </div>

          {isLoading ? (
            <LoadingComponent />
          ) : auditionApplicantList && auditionApplicantList.length === 0 ? (
            <>
              <div className="no-data">
                <p>No data</p>
              </div>
              <div className="table">
                {isShowAuditionInfoPopup ? (
                  <AuditionInfoPopupContainer
                    auditionInfo={auditionInfo}
                    setIsShowAuditionInfoPopup={setIsShowAuditionInfoPopup}
                  />
                ) : null}

                {/* <!-- 오디션 메모 팝업 --> */}
                {isShowAuditionMemoPopup ? (
                  <AuditionMemoPopupContainer
                    memoItem={memoItem}
                    auditionApplicantList={auditionApplicantList}
                    setAuditionApplicantList={setAuditionApplicantList}
                    setIsShowAuditionMemoPopup={setIsShowAuditionMemoPopup}
                  />
                ) : null}

                {/* <!-- 오디션 종료 팝업 --> */}
                {isShowAuditionClosePopup ? (
                  <AuditionClosePopupContainer
                    auditionInfo={auditionInfo}
                    setIsShowAuditionClosePopup={setIsShowAuditionClosePopup}
                  />
                ) : null}
              </div>
            </>
          ) : (
            <>
              <div className="table">
                <table>
                  <thead>
                    <tr>
                      <td className="no">
                        <p>
                          {getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionClose.txtTableNo"
                          )}
                        </p>
                      </td>
                      <td className="photo">
                        <p>
                          {getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionClose.txtTablePhoto"
                          )}
                        </p>
                      </td>
                      <td className="name">
                        <p>
                          {getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionClose.txtTableName"
                          )}
                        </p>
                      </td>
                      <td className="gender">
                        <p>
                          {getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionClose.txtTableGender"
                          )}
                        </p>
                      </td>
                      <td className="nationality">
                        <p>
                          {getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionClose.txtTableNationality"
                          )}
                        </p>
                      </td>
                      <td className="school-name">
                        <p>
                          {getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionClose.txtTableSchoolName"
                          )}
                        </p>
                      </td>
                      <td className="birth-year">
                        <p>
                          {getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionClose.txtTableBirthYear"
                          )}
                        </p>
                      </td>
                      <td className="memo">
                        <p>
                          {getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionClose.txtTableMemo"
                          )}
                        </p>
                      </td>
                      <td className="evaluation" style={{ display: "block" }}>
                        <p>
                          {getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionClose.txtEvaluation"
                          )}
                        </p>
                      </td>
                      <td className="portfolio">
                        <p>
                          {getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionClose.txtTableLike"
                          )}
                        </p>
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    {auditionApplicantList.length > 0
                      ? auditionApplicantList.map((item: any, idx: number) => (
                          <tr>
                            <td className="no">
                              <p>{totalCount - (page - 1) * limit - idx}</p>
                            </td>
                            <td className="photo">
                              <div
                                className="img"
                                style={{
                                  backgroundImage: `url(${
                                    item.profileImageInfo
                                      ? item.profileImageInfo.url.replace(
                                          / /g,
                                          "%20"
                                        )
                                      : "./imgs/dummy/basic-img-2.png"
                                  })`,
                                }}></div>
                            </td>
                            <td className="name">
                              <a
                                className="btn-link"
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(
                                    `/agency/audition/applicant/${item.id}`,
                                    {
                                      state: {
                                        ...state,
                                        status: auditionInfo.status,
                                      },
                                    }
                                  );
                                }}>
                                {`${item.firstName} ${item.lastName}`}
                              </a>
                            </td>
                            <td className="gender">
                              <p>
                                {getCurrentLangName(
                                  item.genderTypeCodeInfo.names
                                )}
                              </p>
                            </td>
                            <td className="nationality">
                              <p>
                                {getCurrentLangName(item.countryCodeInfo.names)}
                              </p>
                            </td>
                            <td className="school-name">
                              <p>{item.schoolName ? item.schoolName : "-"}</p>
                            </td>
                            <td className="birth-year">
                              <p>
                                {item.birthday
                                  ? getFormattedDateValue(
                                      item.birthday,
                                      "YYYY.MM.DD"
                                    )
                                  : "-"}
                              </p>
                            </td>
                            <td className="memo">
                              <p
                                onClick={() => {
                                  setMemoItem(item);
                                  setIsShowAuditionMemoPopup(true);
                                }}>
                                {item.memo === ""
                                  ? getLanguageValue(
                                      PAGE_TYPE.AGENCY,
                                      "auditionClose.txtWrtie"
                                    )
                                  : getLanguageValue(
                                      PAGE_TYPE.AGENCY,
                                      "auditionClose.txtShow"
                                    )}
                              </p>
                            </td>
                            <td
                              className="evaluation"
                              style={{ display: "table-cell" }}>
                              <div className="select-list">
                                <select
                                  onChange={(e) => {
                                    updateResultInfo(item.id, e.target.value);
                                  }}>
                                  <option value="">
                                    {getLanguageValue(
                                      PAGE_TYPE.AGENCY,
                                      "auditionClose.txtFilterSelectEvaluation"
                                    )}
                                  </option>
                                  <option
                                    value="hold"
                                    selected={item.result === "hold"}>
                                    {getLanguageValue(
                                      PAGE_TYPE.AGENCY,
                                      "auditionClose.txtFilterSelectHold"
                                    )}
                                  </option>
                                  <option
                                    value="bad"
                                    selected={item.result === "bad"}>
                                    {getLanguageValue(
                                      PAGE_TYPE.AGENCY,
                                      "auditionClose.txtFilterSelectBad"
                                    )}
                                  </option>
                                  <option
                                    value="good"
                                    selected={item.result === "good"}>
                                    {getLanguageValue(
                                      PAGE_TYPE.AGENCY,
                                      "auditionClose.txtFilterSelectGood"
                                    )}
                                  </option>
                                </select>
                              </div>
                            </td>
                            <td className="like">
                              <div className="btn-like">
                                <button>
                                  {item.isLiked === true ? (
                                    <img
                                      src="./imgs/icons/like.png"
                                      alt="like"
                                      onClick={(e: any) => {
                                        toggleUserLike(item, !item.isLiked);
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src="./imgs/icons/like-toggle.png"
                                      alt="like"
                                      onClick={(e: any) => {
                                        toggleUserLike(item, !item.isLiked);
                                      }}
                                    />
                                  )}
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>

                {isShowAuditionInfoPopup ? (
                  <AuditionInfoPopupContainer
                    auditionInfo={auditionInfo}
                    setIsShowAuditionInfoPopup={setIsShowAuditionInfoPopup}
                  />
                ) : null}

                {/* <!-- 오디션 메모 팝업 --> */}
                {isShowAuditionMemoPopup ? (
                  <AuditionMemoPopupContainer
                    memoItem={memoItem}
                    auditionApplicantList={auditionApplicantList}
                    setAuditionApplicantList={setAuditionApplicantList}
                    setIsShowAuditionMemoPopup={setIsShowAuditionMemoPopup}
                  />
                ) : null}

                {/* <!-- 오디션 종료 팝업 --> */}
                {isShowAuditionClosePopup ? (
                  <AuditionClosePopupContainer
                    auditionInfo={auditionInfo}
                    setIsShowAuditionClosePopup={setIsShowAuditionClosePopup}
                  />
                ) : null}
              </div>
              <Pagination
                url={`/agency/audition/${id}`}
                totalCount={totalCount}
                prevPage={prev}
                currentPage={page}
                pageSize={limit}
                pageGroupSize={10}
                setPage={setPage}
              />
            </>
          )}
        </div>
      ) : null}
    </main>
  );
};
export default AuditionCloseComponent;
