import React from "react";

import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { useNavigate, useLocation } from "react-router-dom";

const TaggedStudentPopupComponent = (props: any) => {
  const { info, setIsShowTaggedStudentPopup, studentDetailUrlPrefix} = props;
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="popup">
      <div className="con">
        <div className="wrap">
          <div
            className="close-btn"
            onClick={(evt) => setIsShowTaggedStudentPopup(false)}></div>
          <h2 className="portfolio-title">{info.title}</h2>
          <p className="date">{info.performedDate}</p>
          <div className="student-list">
            <ul>
              {info.studentInfoList.length > 0 &&
                info.studentInfoList.map((item: any, idx: number) => (
                  <li key={`tagged-student-${idx}`} onClick={() => {
                    navigate(`/agency/wannabe/info/${item.id}`, {
                        state: {
                          from: {
                            pathname: location.pathname,
                          },
                        },
                      });
                  }}>
                    <div
                      className="photo"
                      style={{
                        backgroundImage: `url(${
                          item.imageUrl
                            ? item.imageUrl + "?w=360&h=360"
                            : "imgs/dummy/basic-img-2.png"
                        })`,
                      }}></div>
                    <p>{`${item.firstName} ${item.lastName}`}</p>
                  </li>
                ))}
            </ul>
            <button
              className="close-btn"
              onClick={(evt) => setIsShowTaggedStudentPopup(false)}>
              {getLanguageValue(PAGE_TYPE.AGENCY, "home.btnClose")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaggedStudentPopupComponent;
