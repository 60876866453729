import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import YouTube, { YouTubeProps } from "react-youtube";

import LoadingComponent from "commons/loading/Loading.component";

const PortfolioDetailComponent = (props: any) => {
  const navigate = useNavigate();
  const state = useLocation().state;

  const {
    isLoading,
    portfolioInfo,
    onClickBtnDelete,
    hideTitle,
    setHideTitle,
  } = props;

  const opts: YouTubeProps["opts"] = {
    height: "100%",
    width: "100%",
    playerVars: {},
  };

  return (
    <main className="">
      <div className="portfolio-info">
        <div className="con">
          <div
            className="back-btn"
            onClick={(evt) => navigate("/school/portfolio", { state })}
          />
          <div className="wrap">
            <div className="title">
              <h2>PORTFOLIO INFO</h2>
            </div>
            {!isLoading ? (
              <>
                <div className="preview">
                  <div className="preview-wrap">
                    <div className="img" style={{ paddingBottom: "5px" }}>
                      <div className="play-img">
                        <p className="date">
                          {portfolioInfo.performedDate.replaceAll("-", ".")}
                        </p>
                        <YouTube
                          videoId={portfolioInfo.youtubeId}
                          opts={opts}
                          onStateChange={(e: any) => {
                            // player state
                            // 시작되지 않음: -1, 종료: 0, 재생 중: 1, 일시중지: 2, 버퍼링: 3, 동영상 신호: 5
                            const videoState = e.target.getPlayerState();

                            // 재생중일 때만 체크
                            setHideTitle(
                              videoState === 1 ||
                                videoState === 2 ||
                                videoState === 3
                            );
                          }}
                        />
                        {/* 재생중일 때 제목 영역 비노출*/}
                        {!hideTitle ? (
                          <p className="portfolio-title">
                            {portfolioInfo.title}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="info" style={{ marginLeft: "auto" }}>
                      <div className="input-box">
                        <p className="youtube-id">
                          {getLanguageValue(
                            PAGE_TYPE.SCHOOL,
                            "portfolio.txtYoutubeId"
                          )}
                        </p>
                        <p>{`youtube.com/watch?v=${portfolioInfo.youtubeId}`}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="student-tag">
                  <h3>
                    {getLanguageValue(
                      PAGE_TYPE.SCHOOL,
                      "portfolio.txtStudentTag"
                    )}
                  </h3>
                  <ul>
                    {portfolioInfo.studentInfoList.length > 0 &&
                      portfolioInfo.studentInfoList.map((item: any) => (
                        <li>
                          <div className="item">
                            <div
                              className="img"
                              style={{
                                backgroundImage: `url("${
                                  item.profileImageUrl
                                    ? item.profileImageUrl + "?w=360&h=360"
                                    : "./imgs/dummy/basic-img-2.png"
                                }")`,
                              }}></div>
                            <div className="name ellipsis">
                              <p>{`${item.firstName} ${item.lastName}`}</p>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="btn">
                  <button
                    className="remove-btn"
                    onClick={(evt) => onClickBtnDelete()}>
                    {getLanguageValue(PAGE_TYPE.SCHOOL, "portfolio.btnDelete")}
                  </button>
                  <button
                    className="edit-btn"
                    onClick={(evt) =>
                      navigate(`/school/portfolio/edit/${portfolioInfo.id}`, {
                        state,
                      })
                    }>
                    {getLanguageValue(PAGE_TYPE.SCHOOL, "portfolio.btnEdit")}
                  </button>
                </div>
              </>
            ) : (
              <LoadingComponent />
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default PortfolioDetailComponent;
