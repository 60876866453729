import React, { useEffect } from "react";
import Pagination from "../../../commons/pagination/Pagination.container";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";
import { useLocation, useNavigate } from "react-router-dom";
import { getCommonCodeListByParent } from "data/common.data";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const AuditionInfoPopupComponent = (props: any) => {
  const { auditionInfo, setIsShowAuditionInfoPopup } = props;
  const specialtyList = getCommonCodeListByParent("specialty");
  const selectedSpecialties = specialtyList.filter((specialty: any) =>
    auditionInfo.specialtyCodeList?.some(
      (item: any) => item.code === specialty.code
    )
  );

  return (
    <div className="popup popup-audition-info">
      <div className="con">
        <div className="info">
          <div className="header">
            <div
              className="close-btn"
              onClick={(evt) => setIsShowAuditionInfoPopup(false)}></div>
            <h2>오디션 정보</h2>
          </div>
          <div className="body">
            <div className="form">
              <div className="check">
                <h3>
                  {getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "auditionInfoPopup.txtTitle"
                  )}
                </h3>
                <div className="check-wrap">
                  <p className="ellipsis">- {auditionInfo.title}</p>
                </div>
              </div>
              <div className="check">
                <h3>
                  {getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "auditionInfoPopup.txtPeriod"
                  )}
                </h3>
                <div className="check-wrap">
                  <p className="ellipsis">{`- ${getFormattedDateValue(
                    auditionInfo.startDate,
                    "YYYY.MM.DD"
                  )} ~ ${getFormattedDateValue(
                    auditionInfo.endDate,
                    "YYYY.MM.DD"
                  )}`}</p>
                </div>
              </div>
              <div className="check">
                <h3>
                  {getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "auditionInfoPopup.txtCountry"
                  )}
                </h3>
                <div className="check-wrap">
                  <p>
                    {auditionInfo.countryCodeInfo
                      ? `- ${getCurrentLangName(
                          auditionInfo.countryCodeInfo.names
                        )}`
                      : `- ${getLanguageValue(
                          PAGE_TYPE.AGENCY,
                          "auditionInfoPopup.txtGenderOptional"
                        )}`}
                  </p>
                </div>
              </div>
              <div className="check">
                <h3>
                  {getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "auditionInfoPopup.txtAge"
                  )}
                </h3>
                <div className="check-wrap">
                  <p>
                    -{" "}
                    {!auditionInfo.minimumAge && !auditionInfo.maximumAge
                      ? getLanguageValue(
                          PAGE_TYPE.AGENCY,
                          "auditionInfoPopup.txtGenderOptional"
                        )
                      : `${
                          auditionInfo.minimumAge ||
                          getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionInfoPopup.txtGenderOptional"
                          )
                        } ~ ${
                          auditionInfo.maximumAge ||
                          getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionInfoPopup.txtGenderOptional"
                          )
                        }`}
                  </p>
                </div>
              </div>
              <div className="check">
                <h3>
                  {getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "auditionInfoPopup.txtGender"
                  )}
                </h3>
                <div className="check-wrap">
                  <p className="ellipsis">
                    -{" "}
                    {auditionInfo.genderTypeCodeInfo === null
                      ? getLanguageValue(
                          PAGE_TYPE.AGENCY,
                          "auditionInfoPopup.txtGenderOptional"
                        )
                      : auditionInfo.genderTypeCodeInfo.code ===
                        "genderType:girl"
                      ? getLanguageValue(
                          PAGE_TYPE.AGENCY,
                          "auditionInfoPopup.txtGenderGirl"
                        )
                      : getLanguageValue(
                          PAGE_TYPE.AGENCY,
                          "auditionInfoPopup.txtGenderBoy"
                        )}
                  </p>
                </div>
              </div>
              <div className="check">
                <h3>
                  {getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "auditionInfoPopup.txtSpecialty"
                  )}
                </h3>
                <div className="check-wrap">
                  <p className="ellipsis">
                    -{" "}
                    {selectedSpecialties.map((specialty: any, idx: number) => {
                      const isLastItem = idx === selectedSpecialties.length - 1;
                      return (
                        <>
                          {getCurrentLangName(specialty.names)}
                          {!isLastItem && ", "}
                        </>
                      );
                    })}
                  </p>
                </div>
              </div>
              <div className="check">
                <h3>
                  {getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "auditionInfoPopup.txtFile"
                  )}
                </h3>
                <div className="check-wrap">
                  <p className="ellipsis">
                    -{" "}
                    {getLanguageValue(
                      PAGE_TYPE.AGENCY,
                      "auditionInfoPopup.txtVideo"
                    )}{" "}
                    {auditionInfo.isFile ? "O" : "X"}
                  </p>
                </div>
              </div>
              <div className="check">
                <h3>
                  {getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "auditionInfoPopup.txtAdditionalInfo"
                  )}
                </h3>
                <div className="check-wrap">
                  <p className="ellipsis">
                    -{" "}
                    {getLanguageValue(
                      PAGE_TYPE.AGENCY,
                      "auditionInfoPopup.txtAdditionalImage"
                    )}{" "}
                    {auditionInfo.isFile ? "O" : "X"}
                  </p>
                </div>
              </div>
              <div className="check">
                <h3>SLUG</h3>
                <div className="check-wrap">
                  <p className="">
                    {process.env.REACT_APP_DOMAIN}/audition/{auditionInfo.slug}
                  </p>
                </div>
              </div>
              <div className="check">
                <h3>
                  {getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "auditionInfoPopup.txtIntroduce"
                  )}
                </h3>
                <div className="text-wrap">
                  <div className="text">{auditionInfo.introduce}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="btn">
              <div>
                <button
                  className="save-btn"
                  onClick={(evt) => setIsShowAuditionInfoPopup(false)}>
                  {getLanguageValue(
                    PAGE_TYPE.AGENCY,
                    "auditionInfoPopup.btnClose"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AuditionInfoPopupComponent;
