import React from "react";
import { useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import { getCurrentLangName } from "helpers/String.helper";

import LoadingComponent from "commons/loading/Loading.component";
import PushCheckComponent from "commons/my/PushCheck.component";

const MyComponent = (props: any) => {
    const navigate = useNavigate();

    const {
        email,
        schoolInfo,
        setTermId,
        marketingTermList,
        termList,
        setUpdateType,
        onClickBtnDownloadFile,
        updateMarketingTermList,
        sendVerifyEmail,
        logout,
    } = props;

    return (
        <main className="mypage school-mypage">
            <div className="con">
                <h2>MY</h2>
                {schoolInfo?.id ? (
                    <>
                        {/* 계정 정보 */}
                        <h2>ACCOUNT INFORMATION</h2>
                        <div className="form account">
                            <div className="con">
                                <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtEmail")}</h3>
                                <p className="text">{email}</p>
                            </div>
                            <div className="btn">
                                <div className="email-btn">
                                    <button
                                        onClick={(evt) => {
                                            navigate("/school/my/update-email");
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.SCHOOL, "my.btnUpdateEmail")}
                                    </button>
                                </div>
                                <div className="password-btn">
                                    <button
                                        onClick={(evt) => {
                                            navigate("/school/my/update-password");
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.SCHOOL, "my.btnUpdatePassword")}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* 학원 정보 */}
                        <div className="form">
                            <h2>SCHOOL INFORMATION</h2>
                            <div className="con">
                                <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtSchoolName")}</h3>
                                <p className="text">{schoolInfo.schoolName}</p>
                            </div>
                            <div className="con">
                                <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtRepresentative")}</h3>
                                <p className="text">{schoolInfo.representative}</p>
                            </div>
                            {schoolInfo.businessRegistrationFileInfo ? (
                                <div className="con">
                                    <h3 className="title-con" onClick={(evt) => onClickBtnDownloadFile()}>
                                        {getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtBusinessRegistration")}
                                    </h3>
                                    <span className="text line">{schoolInfo.businessRegistrationFileInfo?.name}</span>
                                </div>
                            ) : null}

                            <div className="con">
                                <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtLogo")}</h3>
                                <div className="logo">
                                    <img src={`${schoolInfo.logoImageInfo?.url}?w=396&h=396`} alt="logo image" style={{ height: "100%" }} />
                                </div>
                            </div>
                            <div className="con">
                                <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtCountry")}</h3>
                                <p className="text">{getCurrentLangName(schoolInfo.countryCodeInfo.names)}</p>
                            </div>
                            <div className="con">
                                <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtCity")}</h3>
                                <p className="text">{schoolInfo.city}</p>
                            </div>
                            <div className="con">
                                <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtAddress1")}</h3>
                                <p className="text">{schoolInfo.address1}</p>
                            </div>
                            <div className="con">
                                <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtAddress2")}</h3>
                                <p className="text">{schoolInfo.address2}</p>
                            </div>
                            <div className="con">
                                <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtZipcode")}</h3>
                                <p className="text">{schoolInfo.zipcode}</p>
                            </div>
                            <div className="con">
                                <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtTelephone")}</h3>
                                <p className="text">{schoolInfo.telephone}</p>
                            </div>
                            <div className="con">
                                <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtHomepageUrl")}</h3>
                                <p className="text">{schoolInfo.homepageUrl}</p>
                            </div>
                            <div className="btn">
                                <div className="update-btn">
                                    <button
                                        onClick={(evt) => {
                                            navigate("/school/my/update-school");
                                            setUpdateType("school");
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.SCHOOL, "my.btnUpdateSchoolInfo")}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* 연락 정보 */}
                        <div className="form">
                            <h2>CONTACT INFORMATION</h2>
                            <div className="con">
                                <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtCellphone")}</h3>
                                <p className="text">{schoolInfo.cellphone}</p>
                            </div>
                            <div className="con">
                                <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtContactEmail")}</h3>
                                <p className="text">{schoolInfo.contactEmail}</p>
                            </div>
                            <div className="btn">
                                <div className="update-btn">
                                    <button
                                        onClick={(evt) => {
                                            navigate("/school/my/update-contact");
                                            setUpdateType("contact");
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.SCHOOL, "my.btnUpdateContactInfo")}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* sns 정보 */}
                        <div className="form">
                            <h2>SNS INFORMATION</h2>
                            {schoolInfo?.instagram || schoolInfo?.youtube || schoolInfo?.twitter || schoolInfo?.facebook || schoolInfo?.tiktok ? (
                                <>
                                    {schoolInfo?.instagram ? (
                                        <div className="con">
                                            <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtInstagram")}</h3>
                                            <p className="text">{`instagram.com/${schoolInfo.instagram}`}</p>
                                        </div>
                                    ) : null}
                                    {schoolInfo?.youtube ? (
                                        <div className="con">
                                            <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtYoutube")}</h3>
                                            <p className="text">{`youtube.com/@${schoolInfo.youtube}`}</p>
                                        </div>
                                    ) : null}
                                    {schoolInfo?.twitter ? (
                                        <div className="con">
                                            <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtTwitter")}</h3>
                                            <p className="text">{`twitter.com/${schoolInfo.twitter}`}</p>
                                        </div>
                                    ) : null}
                                    {schoolInfo?.facebook ? (
                                        <div className="con">
                                            <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtFacebook")}</h3>
                                            <p className="text">{`facebook.com/${schoolInfo.facebook}`}</p>
                                        </div>
                                    ) : null}
                                    {schoolInfo?.tiktok ? (
                                        <div className="con">
                                            <h3 className="title-con">{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtTiktok")}</h3>
                                            <p className="text">{`tiktok.com/@${schoolInfo.tiktok}`}</p>
                                        </div>
                                    ) : null}
                                </>
                            ) : (
                                <div style={{ paddingBottom: "10px" }}>{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtNoRegisteredSnsInfo")}</div>
                            )}
                            <div className="btn">
                                <div className="update-btn">
                                    <button
                                        onClick={(evt) => {
                                            navigate("/school/my/update-sns");
                                            setUpdateType("sns");
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.SCHOOL, "my.btnUpdateSnsInfo")}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* 약관 정보 */}
                        <div className="form">
                            <div className="agreement-form">
                                <ul className="list">
                                    {marketingTermList?.length > 0 &&
                                        marketingTermList.map((item: any, idx: number) => (
                                            <li key={`term-${idx}`} className="item" style={{ alignItems: "start" }}>
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        onChange={(evt) => {
                                                            if (!item.disabled) {
                                                                setTermId(item.id);
                                                                updateMarketingTermList(idx, evt.target.checked);
                                                            }
                                                        }}
                                                        checked={item.hasAgreed}
                                                    />
                                                </label>
                                                <span className="txt" style={{ cursor: "pointer" }} onClick={(evt) => window.open(`/term/etc/${item.termsId}`)}>
                                                    {`(${getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtOptional")}) ${item.title}`}
                                                </span>
                                            </li>
                                        ))}
                                </ul>
                                <ul className="link">
                                    {termList.map((item: any, idx: number) => (
                                        <li key={`term2-${idx}`}>
                                            <a onClick={(evt) => window.open(`/term/common/${item.id}`)}>{item.title}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {/*  */}
                        <div className="btn">
                            <div className="update-btn" onClick={(evt) => navigate("/school/notice")}>
                                <button>{getLanguageValue(PAGE_TYPE.SCHOOL, "my.btnNotice")}</button>
                            </div>
                        </div>

                        {/* 인증 이메일 재발송 */}
                        {!schoolInfo.hasVerifiedEmail ? (
                            <div className="btn">
                                <div className="update-btn">
                                    <button onClick={(evt) => sendVerifyEmail()}>{getLanguageValue(PAGE_TYPE.SCHOOL, "my.btnSendVerifiedEmail")}</button>
                                </div>
                            </div>
                        ) : null}
                        <div className="form">
                            <div className="agreement-form">
                                <ul className="list">
                                    <PushCheckComponent />
                                </ul>
                            </div>
                        </div>

                        {/* 회원 탈퇴 */}
                        <div className="btn">
                            <div className="update-btn" onClick={(evt) => navigate("/school/my/delete-account")}>
                                <button>{getLanguageValue(PAGE_TYPE.SCHOOL, "my.btnDeleteAccount")}</button>
                            </div>
                        </div>

                        {/* 로그아웃 */}
                        <div className="btn">
                            <div
                                className="update-btn"
                                onClick={(evt) => {
                                    logout();
                                }}>
                                <button>{getLanguageValue(PAGE_TYPE.SCHOOL, "my.btnLogout")}</button>
                            </div>
                        </div>
                    </>
                ) : (
                    <LoadingComponent />
                )}
            </div>
        </main>
    );
};

export default MyComponent;
