import React, { useEffect } from "react";
import LoadingComponent from "commons/loading/Loading.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { useNavigate } from "react-router-dom";

const ProfileIntroComponent = (props: any) => {
    const navigate = useNavigate();
    const {
        introVideoList,
        setProfileSubTab,
        deleteIntroVideo,
        videoRefs,
        handlePlay,
        handlePause,
        handleSeeked,
        handleEnded,
        //
        isShowPopup,
        setIsShowPopup,
        popupList,
        openViewPopup,
        profileMediaType,
        isAgencyPopup,
        setIsAgencyPopup,
        agencyInfo,
        openAgencyPopup,
    } = props;

    return (
        <main className="">
            <div className="profile-intro-movie">
                <div className="con">
                    <div className="title">
                        {/* <!-- 선택한 title에 class active 추가 --> */}
                        <button
                            className=""
                            onClick={(e) => {
                                navigate("/student/profile", {
                                    state: { tab: "practice", profileSubTab: "main" },
                                });
                            }}>
                            {getLanguageValue(PAGE_TYPE.STUDENT, "publicProfile.txtTitle")}
                        </button>
                        <button
                            className="active"
                            onClick={(e) => {
                                navigate("/student/agency-profile", {
                                    state: { tab: "profile", profileSubTab: "main" },
                                });
                            }}>
                            {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtTitle")}
                        </button>
                    </div>
                    <div className="main-title">
                        <h2>{getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtAllIntroVideo")}</h2>
                    </div>
                    <div
                        className="back-btn"
                        onClick={() => {
                            navigate("/student/agency-profile", {
                                state: { tab: "profile", profileSubTab: "main" },
                            });
                        }}></div>
                    {introVideoList && introVideoList.length > 0 ? (
                        <div className="wrap video-wrap">
                            {introVideoList.map((videoInfo: any, index: number) => (
                                <div className="preview" key={index}>
                                    <div className="preview-wrap">
                                        <div className="img">
                                            <div className="play-img">
                                                <div className="view-count">
                                                    <button
                                                        onClick={(e) => {
                                                            openViewPopup(videoInfo.id, "introVideo");
                                                        }}>
                                                        <img src="./imgs/icons/view-icon.png" alt="view count icon" />
                                                        {videoInfo.viewCnt}
                                                    </button>
                                                </div>
                                                <div className="delete-btn">
                                                    <button
                                                        onClick={() => {
                                                            deleteIntroVideo(videoInfo.id);
                                                        }}></button>
                                                </div>
                                                <video
                                                    ref={(el) => (videoRefs.current[index] = el)}
                                                    controls
                                                    muted
                                                    playsInline
                                                    controlsList="nodownload"
                                                    onPlay={() => handlePlay(index)}
                                                    onPause={() => handlePause(index)}
                                                    onSeeked={() => handleSeeked(index)}
                                                    onEnded={() => handleEnded(index)}>
                                                    <source src={videoInfo.url} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        </div>
                                        <div className="date">
                                            <p>{getFormattedDateValue(videoInfo.createdDate, "YYYY.MM.DD")}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtNoData")
                    )}
                </div>
                {isShowPopup ? (
                    <div className="popup popup-view-count">
                        <div className="con">
                            <div
                                className="close-btn"
                                onClick={(e) => {
                                    setIsShowPopup(false);
                                }}></div>
                            <div className="wrap">
                                <div className="title">
                                    <p className="ellipsis">
                                        <span>
                                            {profileMediaType === "profileImage"
                                                ? getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtPhoto")
                                                : getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtVideo")}
                                        </span>{" "}
                                        {getLanguageValue(PAGE_TYPE.STUDENT, "profile.txtViewedAgency")}
                                    </p>
                                </div>
                                {popupList && popupList.length > 0 ? (
                                    <div className="list">
                                        <ul>
                                            {popupList.map((item: any, index: number) => (
                                                <li
                                                    onClick={(e) => {
                                                        openAgencyPopup(item.id);
                                                    }}>
                                                    <div className="item">
                                                        <div
                                                            className="img"
                                                            style={{
                                                                backgroundImage: `url(${
                                                                    item && item.logoImageInfo
                                                                        ? item.logoImageInfo.url.replace(/ /g, "%20")
                                                                        : "./imgs/icons/agency-logo-default.png"
                                                                })`,
                                                            }}></div>
                                                        <div className="view-count">
                                                            <button>
                                                                <img src="./imgs/icons/view-icon.png" alt="view count icon" />
                                                                {item.viewCnt}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="agency-name">
                                                        <p className="ellipsis">{item.companyName}</p>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
            {isAgencyPopup && agencyInfo ? (
                <div className="popup popup-casting-manager-info">
                    <div className="con">
                        <div className="wrap">
                            <div
                                className="close-btn"
                                onClick={(e) => {
                                    setIsAgencyPopup(false);
                                }}></div>
                            <h2>{getLanguageValue(PAGE_TYPE.STUDENT, "messageDetail.txtCastingManagerInfo")}</h2>
                            <div className="agency-info">
                                <img
                                    src={agencyInfo.logoImageInfo ? agencyInfo.logoImageInfo.url.replace(/ /g, "%20") : "./imgs/icons/agency-logo-default.png"}
                                    alt="agency-logo"
                                />
                                <p>{agencyInfo.companyName}</p>
                                <div className="casting-manager-info">
                                    <div className="business-card"></div>
                                    <div className="name">
                                        {agencyInfo.name}
                                        {/* <!-- 이메일 인증 했을경우 뱃지 --> */}
                                        {agencyInfo.hasVerifiedEmail === 1 ? <img src="./imgs/icons/badge.png" alt="badge" /> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="btn">
                                <button
                                    onClick={(e) => {
                                        setIsAgencyPopup(false);
                                    }}>
                                    {getLanguageValue(PAGE_TYPE.STUDENT, "messageDetail.txtClose")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </main>
    );
};
export default ProfileIntroComponent;
