import StudentCardComponent from "commons/card/StudentCard.component";
import LoadingComponent from "commons/loading/Loading.component";
import { PAGE_TYPE } from "enums";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";
import { getCurrentLanguage, getLanguageValue } from "locales/i18n";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const HomeComponent = (props: any) => {
  const navigate = useNavigate();
  const {
    spotlightStudentList,
    popularStudentList,
    newStudentList,
    wannabeAuditionList,
    agencyAuditionList,
    isLoading1,
    isLoading2,
    isLoading3,
    isLoading4,
    isLoading5,
    sliderRef,
  } = props;

  const PrevArrow = (props: {
    onClick?: React.MouseEventHandler<HTMLImageElement>;
  }) => {
    return (
      <img
        src="./imgs/dummy/landing/banner-prev-btn.png"
        className="slick-prev slick-arrow"
        alt="Previous"
        onClick={props.onClick} // onClick 이벤트 핸들러 추가
      />
    );
  };

  const NextArrow = (props: {
    onClick?: React.MouseEventHandler<HTMLImageElement>;
  }) => {
    return (
      <img
        src="./imgs/dummy/landing/banner-next-btn.png"
        className="slick-next slick-arrow"
        alt="Next"
        onClick={props.onClick} // onClick 이벤트 핸들러 추가
      />
    );
  };

  const settings = {
    className: "slider pc",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    dots: true,
    prevArrow: <PrevArrow onClick={() => sliderRef.current?.slickPrev()} />,
    nextArrow: <NextArrow onClick={() => sliderRef.current?.slickNext()} />,
    autoplay: true,
    autoplaySpeed: 3000,
    customPaging: function (i: number) {
      const totalSlides = 3;

      return (
        <button
          type="button"
          role="tab"
          id={`slick-slide-control0${i}`}
          aria-controls={`slick-slide0${i}`}
          aria-label={`${i + 1} of ${totalSlides}`}
          tabIndex={i === 0 ? 0 : -1}
          aria-selected={i === 0 ? "true" : "false"}>
          {i + 1}
        </button>
      );
    },
  };

  const settingsMB = {
    className: "slider mb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    dots: true,
    prevArrow: <PrevArrow onClick={() => sliderRef.current?.slickPrev()} />,
    nextArrow: <NextArrow onClick={() => sliderRef.current?.slickNext()} />,
    autoplay: true,
    autoplaySpeed: 3000,
    customPaging: function (i: number) {
      const totalSlides = 3;

      return (
        <button
          type="button"
          role="tab"
          id={`slick-slide-control0${i}`}
          aria-controls={`slick-slide0${i}`}
          aria-label={`${i + 1} of ${totalSlides}`}
          tabIndex={i === 0 ? 0 : -1}
          aria-selected={i === 0 ? "true" : "false"}>
          {i + 1}
        </button>
      );
    },
  };

  return (
    <main className="landing">
      <div className="landing-con">
        {getCurrentLanguage() === "ko" ? (
          // ko
          <>
            <Slider ref={sliderRef} {...settings}>
              <div className="slider-img1">
                <img
                  src="./imgs/dummy/landing/banner-1-kor.png"
                  alt="banner image1"
                />
              </div>
              <div className="slider-img2">
                <img
                  src="./imgs/dummy/landing/banner-2-kor.png"
                  alt="banner image2"
                />
              </div>
              <div className="slider-img3">
                <img
                  src="./imgs/dummy/landing/banner-3-kor.png"
                  alt="banner image3"
                />
              </div>
            </Slider>

            <Slider ref={sliderRef} {...settingsMB}>
              <div className="slider-img1">
                <img
                  src="./imgs/dummy/landing/mb/banner-1-kor.png"
                  alt="banner image1"
                />
              </div>
              <div className="slider-img2">
                <img
                  src="./imgs/dummy/landing/mb/banner-2-kor.png"
                  alt="banner image2"
                />
              </div>
              <div className="slider-img3">
                <img
                  src="./imgs/dummy/landing/mb/banner-3-kor.png"
                  alt="banner image3"
                />
              </div>
            </Slider>
          </>
        ) : getCurrentLanguage() === "ja" ? (
          // ja
          <>
            <Slider ref={sliderRef} {...settings}>
              <div className="slider-img1">
                <img
                  src="./imgs/dummy/landing/banner-1-jpn.png"
                  alt="banner image1"
                />
              </div>
              <div className="slider-img2">
                <img
                  src="./imgs/dummy/landing/banner-2-jpn.png"
                  alt="banner image2"
                />
              </div>
              <div className="slider-img3">
                <img
                  src="./imgs/dummy/landing/banner-3-jpn.png"
                  alt="banner image3"
                />
              </div>
            </Slider>
            <Slider ref={sliderRef} {...settingsMB}>
              <div className="slider-img1">
                <img
                  src="./imgs/dummy/landing/mb/banner-1-jpn.png"
                  alt="banner image1"
                />
              </div>
              <div className="slider-img2">
                <img
                  src="./imgs/dummy/landing/mb/banner-2-jpn.png"
                  alt="banner image2"
                />
              </div>
              <div className="slider-img3">
                <img
                  src="./imgs/dummy/landing/mb/banner-3-jpn.png"
                  alt="banner image3"
                />
              </div>
            </Slider>
          </>
        ) : (
          // en
          <>
            <Slider ref={sliderRef} {...settings}>
              <div className="slider-img1">
                <img
                  src="./imgs/dummy/landing/banner-1-eng.png"
                  alt="banner image1"
                />
              </div>
              <div className="slider-img2">
                <img
                  src="./imgs/dummy/landing/banner-2-eng.png"
                  alt="banner image2"
                />
              </div>
              <div className="slider-img3">
                <img
                  src="./imgs/dummy/landing/banner-3-eng.png"
                  alt="banner image3"
                />
              </div>
            </Slider>
            <Slider ref={sliderRef} {...settingsMB}>
              <div className="slider-img1">
                <img
                  src="./imgs/dummy/landing/mb/banner-1-eng.png"
                  alt="banner image1"
                />
              </div>
              <div className="slider-img2">
                <img
                  src="./imgs/dummy/landing/mb/banner-2-eng.png"
                  alt="banner image2"
                />
              </div>
              <div className="slider-img3">
                <img
                  src="./imgs/dummy/landing/mb/banner-3-eng.png"
                  alt="banner image3"
                />
              </div>
            </Slider>
          </>
        )}

        <div className="wrap">
          {/* <div className="wannabes-wrap">
            <div className="title">
              <h2>
                <span>SPOTLIGHT</span> WANNABES
              </h2>
              <div className="tip">
                <img src="./imgs/icons/info-icon.png" alt="information" />
                <div className="tooltip">
                  {getLanguageValue(PAGE_TYPE.COMMON, "home.viewTooltip")}
                </div>
              </div>
            </div>
            <div className="wannabes-info">
              {isLoading1 ? (
                <LoadingComponent />
              ) : spotlightStudentList.length > 0 ? (
                <ul>
                  {spotlightStudentList.map((item: any, idx: number) => (
                    <li>
                      <div className={`rank ${idx === 0 ? " ranking-1" : ""}`}>
                        <span>{item.rank}</span>
                      </div>

                      {item.rankChange ? (
                        <div className="score-ud">
                          <p className={item.rankChange > 0 ? "rise" : "fall"}>
                            <img
                              src={
                                item.rankChange > 0
                                  ? "./imgs/icons/arrow-green.png"
                                  : "./imgs/icons/arrow-red.png"
                              }
                              alt={`a${
                                item.rankChange > 0 ? " rise" : " fall"
                              }`}
                            />
                            {Math.abs(item.rankChange)}
                          </p>
                        </div>
                      ) : null}
                      <a
                        onClick={(e: any) => {
                          e.preventDefault();
                          navigate(`/wannabe/info/${item.slug}`);
                        }}>
                        <img
                          src={
                            item?.profileImageUrl
                              ? item.profileImageUrl + "?w=480&h=760"
                              : "./imgs/dummy/basic-img.png"
                          }
                          alt="spotlight wannabe"
                        />
                        <div className="student-info">
                          <p className="nationality ellipsis">
                            {getCurrentLangName(item?.countryCodeInfo?.names)}
                          </p>
                          <p className="student-name ellipsis">
                            {item.firstName} {item.lastName}
                          </p>
                          <p className="ellipsis">
                            {getCurrentLangName(
                              item?.genderTypeCodeInfo?.names
                            )}
                          </p>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="no-data">
                  <p>No data</p>
                </div>
              )}
            </div>
          </div> */}

          {/* <div className="wannabes-wrap">
            <div className="title">
              <h2>
                <span>POPULAR</span> WANNABES
              </h2>
              <div className="tip">
                <img src="./imgs/icons/info-icon.png" alt="information" />
                <div className="tooltip">
                  {getLanguageValue(PAGE_TYPE.COMMON, "home.favoriteTooltip")}
                </div>
              </div>
            </div>
            <div className="wannabes-info">
              {isLoading2 ? (
                <LoadingComponent />
              ) : popularStudentList.length > 0 ? (
                <ul>
                  {popularStudentList.map((item: any, idx: number) => (
                    <li>
                      <div className={`rank ${idx === 0 ? " ranking-1" : ""}`}>
                        <span>{item.rank}</span>
                      </div>

                      {item.rankChange !== 0 ? (
                        <div className="score-ud">
                          <p className={item.rankChange > 0 ? "rise" : "fall"}>
                            <img
                              src={
                                item.rankChange > 0
                                  ? "./imgs/icons/arrow-green.png"
                                  : "./imgs/icons/arrow-red.png"
                              }
                              alt={`a${
                                item.rankChange > 0 ? " rise" : " fall"
                              }`}
                            />
                            {Math.abs(item.rankChange)}
                          </p>
                        </div>
                      ) : null}

                      <a
                        onClick={(e: any) => {
                          e.preventDefault();
                          navigate(`/wannabe/info/${item.slug}`);
                        }}>
                        <img
                          src={
                            item?.profileImageUrl
                              ? item.profileImageUrl + "?w=480&h=760"
                              : "./imgs/dummy/basic-img.png"
                          }
                          alt="popular wannabe"
                        />
                        <div className="student-info">
                          <p className="nationality ellipsis">
                            {getCurrentLangName(item?.countryCodeInfo?.names)}
                          </p>
                          <p className="student-name ellipsis">
                            {item.firstName} {item.lastName}
                          </p>
                          <p className="ellipsis">
                            {getCurrentLangName(
                              item?.genderTypeCodeInfo?.names
                            )}
                          </p>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="no-data">
                  <p>No data</p>
                </div>
              )}
            </div>
          </div> */}

          <div className="wannabes-wrap hot-wannabes-wrap">
            <div className="title">
              <h2>
                <span>HOT</span> WANNABES
              </h2>
            </div>
            <div className="hot-wannabes">
              {isLoading3 ? (
                <LoadingComponent />
              ) : newStudentList.length > 0 ? (
                <ul>
                  {newStudentList.map((item: any, idx: number) => (
                    <li>
                      <div className="item">
                        <div
                          className="img"
                          style={{
                            backgroundImage: `url(${
                              item.profileImageUrl
                                ? item.profileImageUrl + "?w=360&h=360"
                                : "./imgs/dummy/basic-img.png"
                            })`,
                          }}></div>
                      </div>
                      {/* <a
                        style={{cursor: "default"}}
                        onClick={(e: any) => {
                          e.preventDefault();
                        }}>
                        <img
                          src={
                            item?.profileImageUrl
                              ? item.profileImageUrl + "?w=480&h=760"
                              : "./imgs/dummy/basic-img.png"
                          }
                          alt="spotlight student"
                        />
                      </a> */}
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="no-data">
                  <p>No data</p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="wrap-2">
          {/* <div className="auditions wannabes-auditions">
            <div className="title">
              <h2>
                <span>WANNABES</span> AUDITIONS
              </h2>
            </div>
            <div className="btn">
              <button
                onClick={(e: any) => {
                  e.preventDefault();
                  navigate(`/audition`, { state: { tab: "wannabes" } });
                }}>
                {getLanguageValue(PAGE_TYPE.COMMON, "home.txtViewAll")}
              </button>
            </div>
            {isLoading4 ? (
              <LoadingComponent />
            ) : wannabeAuditionList.length > 0 ? (
              <ul>
                {wannabeAuditionList.map((item: any, idx: number) => (
                  <li
                    onClick={(e: any) => {
                      e.preventDefault();
                      navigate(`/audition/${item.id}/wannabes`);
                    }}>
                    <div className="audition-content">
                      <span
                        className={`${
                          item?.auditionStatusCodeInfo?.code ===
                          "auditionStatus:inProgress"
                            ? "ongoing"
                            : "schedule"
                        }`}>
                        {getCurrentLangName(
                          item?.auditionStatusCodeInfo?.names
                        )}
                      </span>
                      <img
                        src={
                          item.logoImageInfo
                            ? item.logoImageInfo.url
                            : "./imgs/icons/agency-logo-default.png"
                        }
                        alt="agency logo"
                      />
                    </div>
                    <div className="text">
                      <p className="agency-name">{item.companyName}</p>
                      <p className="audition-name">{item.title}</p>
                      <p className="audition-period">
                        {`(${getFormattedDateValue(
                          item.startDate,
                          "YYYY.MM.DD"
                        )}~${getFormattedDateValue(
                          item.endDate,
                          "YYYY.MM.DD"
                        )})`}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="no-data">
                <p>No data</p>
              </div>
            )}
          </div> */}
          <div className="auditions agency-auditions">
            <div className="title">
              <h2>
                <span>AGENCY</span> AUDITIONS
              </h2>
            </div>
            <div className="btn">
              <button
                onClick={(e: any) => {
                  e.preventDefault();
                  navigate(`/audition`, { state: { tab: "agency" } });
                }}>
                {getLanguageValue(PAGE_TYPE.COMMON, "home.txtViewAll")}
              </button>
            </div>
            {isLoading5 ? (
              <LoadingComponent />
            ) : agencyAuditionList.length > 0 ? (
              <ul>
                {agencyAuditionList.map((item: any, idx: number) => (
                  <li
                    onClick={(e: any) => {
                      e.preventDefault();
                      navigate(`/audition/${item.id}/agency`);
                    }}>
                    <div className="audition-content">
                      <span
                        className={`${
                          item?.auditionStatusCodeInfo?.code ===
                          "auditionStatus:inProgress"
                            ? "ongoing"
                            : "schedule"
                        }`}>
                        {getCurrentLangName(
                          item?.auditionStatusCodeInfo?.names
                        )}
                      </span>
                      <img
                        src={
                          item.logoImageUrl
                            ? item.logoImageUrl
                            : "./imgs/icons/agency-logo-default.png"
                        }
                        alt="agency logo"
                      />
                    </div>
                    <div className="text">
                      <p className="agency-name">{item.companyName}</p>
                      <p className="audition-name">{item.title}</p>
                      <p className="audition-period">
                        {`(${getFormattedDateValue(
                          item.startDate,
                          "YYYY.MM.DD"
                        )}~${getFormattedDateValue(
                          item.endDate,
                          "YYYY.MM.DD"
                        )})`}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="no-data">
                <p>No data</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};
export default HomeComponent;
