import * as BaseApi from "./Base.api";

export const getTranslateText = async (param: any) => {
    const url = "/translate";
    return await BaseApi.post(url, param);
};

export const getIsNeedTranslate = async (params: any) => {
    const url = "/translate/is-needed";
    return await BaseApi.post(url, params);
};
