import { PAGE_TYPE } from "enums";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AuditionDetailComponent = (props: any) => {
  const navigate = useNavigate();
  const {
    type,
    auditionInfo,
    commentList,
    isLogin,
    myInfo,
    commentValue,
    setCommentValue,
    commentGuideValue,
    //
    createComment,
    createRecomment,
    delComment,
    commentLike,
    commentUnLike,
    commentTotalCount,
    btnRef,
    likeBtnRef,
    replyCancel,
    replyCancelFlag,
    accountId,
    accountType,
    applyAudition,
    inputRef,
    //
    favoriteRef,
    isFavorited,
    changeFavoriteStatus,
  } = props;

  return (
    <main className="landing-audition-detail">
      {auditionInfo ? (
        <div className="landing-con">
          <div
            className="back-btn"
            onClick={(e) => {
              navigate("/audition", { state: { tab: type } });
            }}></div>
          <div className="auditions wannabes-auditions">
            <div className="agency-logo">
              <img
                src={
                  auditionInfo.logoImageInfo
                    ? auditionInfo.logoImageInfo.url
                    : "./imgs/icons/agency-logo-default.png"
                }
                alt="agency logo"
              />

              <p>{auditionInfo.companyName}</p>
            </div>
            <div className="wrap">
              {/* <!-- 에이전시에서 주최하는 오디션일경우 class명 agency-auditions 추가 --> */}
              <div
                className={`audition-content-wrap ${
                  type === "wannabes" ? "agency-auditions" : ""
                }`}>
                <div className="audition-content">
                  <div className="wannabes-logo"></div>
                  {auditionInfo.postImageInfo ? (
                    <img
                      src={auditionInfo.postImageInfo?.url}
                      alt="audition image"
                    />
                  ) : null}
                </div>
                <div className="text">
                  <p className="audition-name ellipsis">{auditionInfo.title}</p>
                  <p className="qualification ellipsis">
                    {(() => {
                      const currentYear = new Date().getFullYear();

                      const minimumBirthYear =
                        auditionInfo.minimumAge !== null
                          ? currentYear - auditionInfo.minimumAge
                          : null;
                      const maximumBirthYear =
                        auditionInfo.maximumAge !== null
                          ? currentYear - auditionInfo.maximumAge
                          : null;

                      if (
                        minimumBirthYear === null &&
                        maximumBirthYear === null
                      ) {
                        return getLanguageValue(
                          PAGE_TYPE.COMMON,
                          "auditionDetail.txtOptional"
                        );
                      } else if (
                        minimumBirthYear !== null &&
                        maximumBirthYear === null
                      ) {
                        return `~${minimumBirthYear}`;
                      } else if (
                        minimumBirthYear === null &&
                        maximumBirthYear !== null
                      ) {
                        return `${maximumBirthYear}~`;
                      } else {
                        return `${maximumBirthYear}~${minimumBirthYear}`;
                      }
                    })()}
                    &#47;{" "}
                    {auditionInfo.genderTypeCodeInfo === null
                      ? getLanguageValue(
                          PAGE_TYPE.COMMON,
                          "auditionDetail.txtOptional"
                        )
                      : auditionInfo.genderTypeCodeInfo.code ===
                        "genderType:girl"
                      ? getLanguageValue(
                          PAGE_TYPE.COMMON,
                          "auditionDetail.txtGenderGirl"
                        )
                      : getLanguageValue(
                          PAGE_TYPE.COMMON,
                          "auditionDetail.txtGenderBoy"
                        )}{" "}
                    &#47;{" "}
                    {auditionInfo.countryCodeInfo
                      ? `${getCurrentLangName(
                          auditionInfo.countryCodeInfo.names
                        )}`
                      : `${getLanguageValue(
                          PAGE_TYPE.COMMON,
                          "auditionDetail.txtOptional"
                        )}`}
                  </p>
                  <p className="audition-period ellipsis">
                    {`(${getFormattedDateValue(
                      auditionInfo.startDate,
                      "YYYY.MM.DD"
                    )}~${getFormattedDateValue(
                      auditionInfo.endDate,
                      "YYYY.MM.DD"
                    )})`}{" "}
                    &#47;{" "}
                    {getCurrentLangName(
                      auditionInfo.auditionStatusCodeInfo?.names
                    )}
                  </p>
                  <p className="introduction">
                    {auditionInfo.introduce
                      .split("\n")
                      .map((line: any, index: any) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                  {/* <!-- 좋아요가 있을경우 class에 on 추가 --> */}
                  {auditionInfo.favoriteCnt != null ? (
                    <div
                      className={`like ${
                        auditionInfo.isFavorited ? "on" : ""
                      }`}>
                      <span>{auditionInfo.favoriteCnt}</span>
                    </div>
                  ) : null}
                </div>
                <div className="comments-wrap">
                  <h2>
                    {getLanguageValue(
                      PAGE_TYPE.COMMON,
                      "auditionDetail.txtComments"
                    )}{" "}
                    ({commentTotalCount})
                  </h2>

                  {/* <!-- 대댓글 입력 --> */}
                  <div className="new-comment">
                    <p className="ellipsis">{commentGuideValue}</p>
                    {replyCancelFlag ? (
                      <img
                        onClick={replyCancel}
                        src="./imgs/icons/close-btn.png"
                        alt="cancel"
                      />
                    ) : null}
                  </div>
                  {/* <!-- 대댓글 입력 끝 --> */}

                  <div className="input-box">
                    <input
                      type="text"
                      placeholder={`${
                        isLogin
                          ? getLanguageValue(
                              PAGE_TYPE.COMMON,
                              "auditionDetail.txtEnterComment"
                            )
                          : getLanguageValue(
                              PAGE_TYPE.COMMON,
                              "auditionDetail.txtLoginToComment"
                            )
                      }`}
                      ref={inputRef}
                      readOnly={!isLogin}
                      value={commentValue}
                      onChange={(e) => setCommentValue(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          createComment();
                        }
                      }}
                    />
                    <button
                      type="button"
                      className="btn btn-submit"
                      ref={btnRef}
                      disabled={!isLogin}
                      onClick={createComment}>
                      {getLanguageValue(
                        PAGE_TYPE.COMMON,
                        "auditionDetail.btnSubmit"
                      )}
                    </button>
                  </div>

                  {/* <!-- 작성된 코멘트가 없을경우 --> */}
                  {commentList && commentList.length === 0 ? (
                    <div className="no-data">
                      <p>
                        {getLanguageValue(
                          PAGE_TYPE.COMMON,
                          "auditionDetail.txtNoComments"
                        )}
                      </p>
                    </div>
                  ) : (
                    <div className="comments">
                      <div className="con">
                        <div className="comments-wrap">
                          {commentList.map((item: any, index: number) => (
                            <React.Fragment key={index}>
                              <div className="wrap">
                                <div className="wannabe-date">
                                  <div className="left">
                                    <img
                                      src={`${item.commenterInfo.imageInfo.url}`}
                                      alt="wannabe image"
                                    />
                                    <div className="wrap">
                                      <p className="name">
                                        {item.commenterInfo.name}
                                      </p>
                                      <p className="date">
                                        {getFormattedDateValue(
                                          item.createdAt,
                                          "YYYY.MM.DD HH:mm"
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="right">
                                    <button
                                      className={`like ${
                                        item.isLiked === 1 ? "on" : ""
                                      }`}
                                      ref={likeBtnRef}
                                      onClick={(e) => {
                                        item.isLiked === 1
                                          ? commentUnLike(item.id)
                                          : commentLike(item.id);
                                      }}>
                                      <span>{item.likeCnt}</span>
                                    </button>
                                    {accountId ===
                                      item.commenterInfo.accountId &&
                                    accountType ===
                                      item.commenterInfo.accountTypeCode ? (
                                      <>
                                        {/* <button className="modification">
                                          <img
                                            src="./imgs/icons/modification-icon.png"
                                            alt="modification button"
                                          />
                                        </button> */}
                                        {!(item.isBlocked || item.isRemoved) ? (
                                          <button
                                            className="delete"
                                            onClick={(e) =>
                                              delComment(item.id)
                                            }>
                                            <img
                                              src="./imgs/icons/delete-btn.png"
                                              alt="delete"
                                            />
                                          </button>
                                        ) : null}
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="comment-wrap">
                                  <div className="comment">
                                    <p>
                                      {item.isBlocked || item.isRemoved
                                        ? getLanguageValue(
                                            PAGE_TYPE.COMMON,
                                            "auditionDetail.txtDeletedComment"
                                          )
                                        : item.comment}
                                    </p>
                                  </div>
                                  {isLogin ? (
                                    <div className="btn">
                                      {/* <button>신고</button> */}
                                      <button
                                        onClick={(e) =>
                                          createRecomment(
                                            item.id,
                                            item.isBlocked || item.isRemoved
                                              ? getLanguageValue(
                                                  PAGE_TYPE.COMMON,
                                                  "auditionDetail.txtDeletedComment"
                                                )
                                              : item.comment
                                          )
                                        }>
                                        {getLanguageValue(
                                          PAGE_TYPE.COMMON,
                                          "auditionDetail.btnReply"
                                        )}
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              {item.commentReplyList &&
                              item.commentReplyList.length > 0
                                ? item.commentReplyList.map(
                                    (replyItem: any, replyIndex: number) => (
                                      <div
                                        key={replyIndex}
                                        className="comment-reply">
                                        <div className="wannabe-date">
                                          <img
                                            src="./imgs/icons/reply.png"
                                            alt="reply"
                                          />
                                          <div className="left">
                                            <img
                                              src={`${replyItem.commenterInfo.imageInfo.url}`}
                                              alt="wannabe image"
                                            />
                                            <div className="wrap">
                                              <p className="name">
                                                {replyItem.commenterInfo.name}
                                              </p>
                                              <p className="date">
                                                {getFormattedDateValue(
                                                  replyItem.createdAt,
                                                  "YYYY.MM.DD HH:mm"
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="right">
                                            <button
                                              className={`like ${
                                                replyItem.isLiked === 1
                                                  ? "on"
                                                  : ""
                                              }`}
                                              ref={likeBtnRef}
                                              onClick={(e) => {
                                                replyItem.isLiked === 1
                                                  ? commentUnLike(replyItem.id)
                                                  : commentLike(replyItem.id);
                                              }}>
                                              <span>{replyItem.likeCnt}</span>
                                            </button>

                                            {accountId ===
                                              replyItem.commenterInfo
                                                .accountId &&
                                            accountType ===
                                              replyItem.commenterInfo
                                                .accountTypeCode ? (
                                              <>
                                                {/* <button className="modification">
                                                  <img
                                                    src="./imgs/icons/modification-icon.png"
                                                    alt="modification button"
                                                  />
                                                </button> */}
                                                {!(
                                                  replyItem.isBlocked ||
                                                  replyItem.isRemoved
                                                ) ? (
                                                  <button
                                                    className="delete"
                                                    onClick={(e) =>
                                                      delComment(replyItem.id)
                                                    }>
                                                    <img
                                                      src="./imgs/icons/delete-btn.png"
                                                      alt="delete"
                                                    />
                                                  </button>
                                                ) : null}
                                              </>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="comment-wrap">
                                          <div className="comment">
                                            <p>
                                              {replyItem.isBlocked ||
                                              replyItem.isRemoved
                                                ? getLanguageValue(
                                                    PAGE_TYPE.COMMON,
                                                    "auditionDetail.txtDeletedComment"
                                                  )
                                                : replyItem.comment}
                                            </p>
                                          </div>
                                          {isLogin ? (
                                            <div className="btn">
                                              {/* <button>신고</button> */}
                                              <button
                                                onClick={(e) =>
                                                  createRecomment(
                                                    item.id,
                                                    replyItem.isBlocked ||
                                                      replyItem.isRemoved
                                                      ? getLanguageValue(
                                                          PAGE_TYPE.COMMON,
                                                          "auditionDetail.txtDeletedComment"
                                                        )
                                                      : replyItem.comment
                                                  )
                                                }>
                                                {getLanguageValue(
                                                  PAGE_TYPE.COMMON,
                                                  "auditionDetail.btnReply"
                                                )}
                                              </button>
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    )
                                  )
                                : null}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {type === "agency" ? (
              <div className="floating-bar">
                <div className="wrap">
                  <div className="chat-request-btn">
                    <button onClick={applyAudition}>
                      {getLanguageValue(
                        PAGE_TYPE.COMMON,
                        "auditionDetail.btnApply"
                      )}
                    </button>
                  </div>
                  <div
                    className="btn-con"
                    style={{
                      display: type !== "wannabes" ? "none" : "block",
                    }}>
                    <button
                      className={
                        isFavorited ? "favorite-btn" : "favorite-disabled-btn"
                      }
                      ref={favoriteRef}
                      onClick={(evt) => {
                        changeFavoriteStatus(!isFavorited);
                      }}>
                      <img
                        src={
                          isFavorited
                            ? "./imgs/icons/like-3.png"
                            : "./imgs/icons/btn-like.png"
                        }
                      />
                    </button>
                  </div>
                </div>
              </div>
            ) : isLogin &&
              (myInfo.accountTypeCode === "accountType:agency" ||
                myInfo.accountTypeCode === "accountType:school" ||
                (myInfo.accountTypeCode === "accountType:student" &&
                  !auditionInfo.isEligibleToApply)) ? null : (
              <div className="floating-bar">
                <div className="wrap">
                  <div className="chat-request-btn">
                    <button onClick={applyAudition}>
                      {getLanguageValue(
                        PAGE_TYPE.COMMON,
                        "auditionDetail.btnApply"
                      )}
                    </button>
                  </div>
                  <div
                    className="btn-con"
                    style={{
                      display: type !== "wannabes" ? "none" : "block",
                    }}>
                    <button
                      className={
                        isFavorited ? "favorite-btn" : "favorite-disabled-btn"
                      }
                      ref={favoriteRef}
                      onClick={(evt) => {
                        changeFavoriteStatus(!isFavorited);
                      }}>
                      <img
                        src={
                          isFavorited
                            ? "./imgs/icons/like-3.png"
                            : "./imgs/icons/btn-like.png"
                        }
                      />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </main>
  );
};
export default AuditionDetailComponent;
