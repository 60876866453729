export const IS_SHOW_INFO_POPUP = "STUDENT/IS_SHOW_INFO_POPUP";
export const setIsShowInfoPopup = (isShowInfoPopup: boolean) => ({ type: IS_SHOW_INFO_POPUP, isShowInfoPopup });

const initialState = {
    isShowSearchPopup: false,
};

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case IS_SHOW_INFO_POPUP:
            return {
                ...state,
                isShowInfoPopup: action.isShowInfoPopup,
            };

        default:
            return state;
    }
}
