import React, { useEffect, useState } from "react";
import StudentEvaluationComponent from "./StudentEvaluation.component";
import * as EvaluationApi from "../../../api/school/Evaluation.api";
import { getCommonCodeListByParent } from "data/common.data";
import { getCurrentDate, getFormattedDateValue } from "helpers/Datetime.helper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const StudentEvaluationContainer = (props: any) => {
  const { onClose, selectedStudent } = props;

  const [evaluationCategory, setEvaluationCategory] = useState([]);
  const [evaluationList, setEvaluationList] = useState([]);
  const [evaluationDataArray, setEvaluationDataArray] = useState([]);
  const [isOnNetworking, setIsOnNetworking] = useState(false);

  const getEvaluationListValues = async () => {
    const categoryResult = getCommonCodeListByParent("evaluationCategory");
    const listResult = await EvaluationApi.getList({
      studentId: selectedStudent.id,
    });

    if (listResult?.list) {
      setEvaluationCategory(categoryResult);
      setEvaluationList(listResult.list);
    }
  };

  const getEvaluationInfoValues = async (
    category: string,
    evaluationDate: string
  ) => {
    const result = await EvaluationApi.getInfo(selectedStudent.id, {
      category: category,
      evaluationDate: evaluationDate,
    });

    if (result?.info) {
      setEvaluationDataArray((prevDataArray) => {
        const updatedDataArray = prevDataArray.map((item) => {
          if (item.code === category) {
            const statusMatch = item.evaluationStatus.match(/\((.*?)\)/);
            const statusDate = statusMatch ? statusMatch[1] : "-";

            if (
              result.info.evaluationDate &&
              (statusDate === "-" ||
                new Date(result.info.evaluationDate) >= new Date(statusDate))
            ) {
              return {
                ...item,
                item: result.info,
                evaluation: result.info.evaluation,
                evaluationDate: result.info.evaluationDate,
                evaluationStatus: `${
                  result.info.evaluation
                }(${getFormattedDateValue(
                  result.info.evaluationDate,
                  "YYYY.MM.DD"
                )})`,
              };
            } else {
              return {
                ...item,
                item: result.info,
                evaluation: result.info.evaluation,
                evaluationDate: result.info.evaluationDate,
                evaluationStatus: item.evaluationStatus,
              };
            }
          }
          return item;
        });
        return updatedDataArray;
      });
    } else {
      setEvaluationDataArray((prevDataArray) => {
        const updatedDataArray = prevDataArray.map((item) => {
          if (item.code === category) {
            return {
              ...item,
              evaluation: "",
              evaluationDate: "",
              evaluationStatus: item.evaluationStatus,
              item: {
                evaluationScore: "",
                evaluation: "",
                evaluationDate: "",
              },
            };
          }
          return item;
        });
        return updatedDataArray;
      });
    }
  };

  const getEvaluationRecentlyInfoValues = async (category: string) => {
    const result = await EvaluationApi.getRecentyInfo(selectedStudent.id, {
      category: category,
    });
    if (result?.info) {
      setEvaluationDataArray((prevDataArray) => {
        const updatedDataArray = prevDataArray.map((item) => {
          if (item.code === category) {
            const statusMatch = item.evaluationStatus.match(/\((.*?)\)/);
            const statusDate = statusMatch ? statusMatch[1] : "-";
            if (
              result.info.evaluationDate &&
              statusDate !== "-" &&
              new Date(result.info.evaluationDate) === new Date(statusDate)
            ) {
              return {
                ...item,
                item: {
                  evaluationScore: "",
                  evaluation: "",
                  evaluationDate: "",
                },
                evaluation: "",
                evaluationDate: item.evaluationDate,
                evaluationStatus: item.evaluationStatus,
              };
            } else {
              return {
                ...item,
                item: {
                  evaluationScore: "",
                  evaluation: "",
                  evaluationDate: "",
                },
                evaluation: "",
                evaluationDate: item.evaluationDate,
                evaluationStatus: `${
                  result.info.evaluation
                }(${getFormattedDateValue(
                  result.info.evaluationDate,
                  "YYYY.MM.DD"
                )})`,
              };
            }
          }
          return item;
        });
        return updatedDataArray;
      });
    } else {
      setEvaluationDataArray((prevDataArray) => {
        const updatedDataArray = prevDataArray.map((item) => {
          if (item.code === category) {
            return {
              ...item,
              evaluation: "",
              evaluationDate: item.evaluationDate,
              evaluationStatus: "-",
              item: {
                evaluationScore: "",
                evaluation: "",
                evaluationDate: "",
              },
            };
          }
          return item;
        });
        return updatedDataArray;
      });
    }
  };

  const combineDataIntoArray = () => {
    const combinedArray = evaluationCategory.map((category) => {
      const correspondingItems = evaluationList.filter(
        (item) => item.evaluationCategoryCode === category.code
      );
      const combinedObject = { ...category };
      if (correspondingItems.length > 0) {
        const item = correspondingItems[0];
        combinedObject.item = { ...item };
      } else {
        combinedObject.item = {
          evaluationScore: "",
          evaluation: "",
          evaluationDate: "",
        };
      }

      combinedObject.evaluationStatus = combinedObject.item.evaluation
        ? `${combinedObject.item.evaluation}(${getFormattedDateValue(
            combinedObject.item.evaluationDate,
            "YYYY.MM.DD"
          )})`
        : "-";

      combinedObject.evaluation =
        combinedObject.item.evaluationDate &&
        getFormattedDateValue(
          combinedObject.item.evaluationDate,
          "YYYY-MM-DD"
        ) === getCurrentDate()
          ? combinedObject.item.evaluation
          : "";
      combinedObject.evaluationDate = getCurrentDate() || "";

      if (
        combinedObject.item.evaluation &&
        getCurrentDate() !==
          getFormattedDateValue(
            combinedObject.item.evaluationDate,
            "YYYY-MM-DD"
          )
      ) {
        combinedObject.item = {
          evaluationScore: "",
          evaluation: "",
          evaluationDate: "",
        };
      }

      return combinedObject;
    });

    console.log(combinedArray);
    return combinedArray;
  };

  const onChangeInput = async (
    componentName: string,
    value: any,
    categoryCode: string
  ) => {
    console.log(
      "StudentSignupContainer:onChangeInput",
      componentName,
      value,
      categoryCode
    );

    // 계정 정보
    if (componentName === "evaluationDate") {
      await getEvaluationInfoValues(categoryCode, value);
      setEvaluationDataArray((prevDataArray) => {
        const updatedDataArray = prevDataArray.map((item: any) => {
          if (item.code === categoryCode) {
            return {
              ...item,
              evaluationDate: value,
            };
          }
          return item;
        });
        return updatedDataArray;
      });
    } else if (componentName === "scoreCode") {
      setEvaluationDataArray((prevDataArray) => {
        const updatedDataArray = prevDataArray.map((item: any) => {
          if (item.code === categoryCode) {
            return {
              ...item,
              evaluation: value,
            };
          }
          return item;
        });
        return updatedDataArray;
      });
    }
  };

  const onClickBtnSave = (e: any, item: any) => {
    setIsOnNetworking(true);

    if (!item.evaluation) {
      alert(
        getLanguageValue(PAGE_TYPE.SCHOOL, "studentEvaluation.errEvaluation")
      );
      setIsOnNetworking(false);
      return true;
    }

    if (item.item.evaluation) {
      if (item.evaluation === "cancel") {
        deleteInfo(item);
      } else {
        updateInfo(item);
      }
    } else {
      createInfo(item);
    }
    setIsOnNetworking(false);
  };

  const createInfo = async (item: any) => {
    const result = await EvaluationApi.createInfo({
      studentId: selectedStudent.id,
      evaluationCategoryCode: item.code,
      evaluation: item.evaluation,
      evaluationDate: item.evaluationDate,
    });

    if (result) {
      await getEvaluationInfoValues(item.code, item.evaluationDate);
    }
  };

  const updateInfo = async (item: any) => {
    const result = await EvaluationApi.updateInfo(item.item.id, {
      evaluation: item.evaluation,
    });
    if (result) {
      await getEvaluationInfoValues(item.code, item.evaluationDate);
    }
  };

  const deleteInfo = async (item: any) => {
    const result = await EvaluationApi.deleteInfo(item.item.id);
    if (result) {
      await getEvaluationRecentlyInfoValues(item.code);
    }
  };

  useEffect(() => {
    setEvaluationDataArray(combineDataIntoArray());
  }, [evaluationCategory, evaluationList]);

  useEffect(() => {
    getEvaluationListValues();
  }, []);

  return (
    <StudentEvaluationComponent
      {...props}
      onClose={onClose}
      evaluationDataArray={evaluationDataArray}
      isOnNetworking={isOnNetworking}
      onChangeInput={onChangeInput}
      onClickBtnSave={onClickBtnSave}
    />
  );
};

export default StudentEvaluationContainer;
