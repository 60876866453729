import React, { useEffect, useState } from "react";
import UnSubscribeEmailComponent from "./UnSubscribeEmail.component";
import * as HomeApi from "api/home/Home.api";
import { useNavigate, useSearchParams } from "react-router-dom";

const UnSubscribeEmailContainer = (props: any) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const agencyId = searchParams.get("agencyId");
  const [email, setEmail] = useState("");

  const unSubscribedEmail = async () => {
    const result = await HomeApi.updateUnSubscribedEmailInfo(+agencyId, {
      email: email,
      hasUnsubscribedEmail: true,
    });

    if (result && result.status === 200) {
      navigate("/unsubscribed/new-wannabe/done", { state: { email: email } });
    }
  };

  useEffect(() => {
    if (!agencyId || isNaN(+agencyId)) {
      navigate("/");
    }
  }, []);

  return (
    <UnSubscribeEmailComponent
      {...props}
      unSubscribedEmail={unSubscribedEmail}
      email={email}
      setEmail={setEmail}
    />
  );
};

export default UnSubscribeEmailContainer;
