import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as commonData from "data/common.data";
import { getCurrentLangName } from "helpers/String.helper";

import * as StudentApi from "api/student/Student.api";
import * as AppApi from "api/App.api";
import * as AuthApi from "api/common/Auth.api";

import MyComponent from "./My.component";
import UpdateMy from "./UpdateMy.container";
import UpdateMyContact from "./UpdateMyContact.container";
import { removeAuthTokens } from "helpers/Auth.helper";
import { setInfo as setUserInfo, setIsLogin } from "reducers/User";
import { removePushToken } from "helpers/Firebase.helper";
import { removeAppAccessToken } from "helpers/appLocalStorageHelper";

const MyContainer = (props: any) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { info: myInfo } = useSelector((state: any) => state.user);

    const [studentInfo, setStudentInfo] = useState<any>({});

    const [marketingTermList, setMarketingTermList] = useState([]);
    const [termList, setTermList] = useState([]);
    const [termId, setTermId] = useState(0);

    const [parentEmail, setParentEmail] = useState("");

    const [isActive, setIsActive] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const type = location.pathname?.split("-")[1] || "";
    const [updateType, setUpdateType] = useState(type);

    const getInfo = async () => {
        const result = await StudentApi.getInfoForStudent();

        if (result?.info) {
            const { info } = result;
            setStudentInfo(info);

            setParentEmail(info.parentEmail);
            setIsActive(info.isActive);

            if (info.specialtyCodeList?.length > 0) {
                let specialty = "";

                info.specialtyCodeList.map((code: any, idx: number) => {
                    const codeInfo = commonData.getCommonCodeListByParent("specialty").find((i: any) => i.code === code);

                    specialty =
                        idx + 1 < info.specialtyCodeList?.length
                            ? `${specialty}${getCurrentLangName(codeInfo.names)}, `
                            : `${specialty}${getCurrentLangName(codeInfo.names)}`;

                    info.specialty = specialty;
                });
            }

            const termList = info.termList;

            if (!termId) {
                termList.map((item: any) => (item.disabled = false));
                setMarketingTermList(termList);
            } else {
                setMarketingTermList((prev: any) => {
                    const list = [...prev];
                    list.find((i: any) => i.id === termId).disabled = false;

                    return list;
                });

                setTermId(0);
            }
        }
    };

    const getTermList = async () => {
        const accountType = "student";
        const result = await AuthApi.getTermListByType({ accountType });

        if (result?.list) {
            setTermList(result.list.slice(1, 3));
        }
    };

    const updateUserTerm = async () => {
        const { hasAgreed } = marketingTermList.find((i: any) => i.id === termId);
        const result: any = await AppApi.updateUserTerm(termId, {
            hasAgreed,
        });

        if (result) {
            getInfo();
        }
    };

    const updateMarketingTermList = (idx: number, checked: boolean) => {
        setMarketingTermList((prev: any) => {
            const list = [...prev];
            list[idx].hasAgreed = checked;
            list[idx].disabled = true;

            return list;
        });
    };

    const sendVerifyEmail = async () => {
        const params = {
            username: myInfo.username,
            accountTypeCode: "accountType:student",
        };
        const result: any = await AuthApi.sendVerifyEmail(params);

        if (result.status === 200) {
            alert(getLanguageValue(PAGE_TYPE.AGENCY, "my.sendEmail"));
        }
    };

    const sendVerifyParentEmail = async () => {
        const result: any = await AuthApi.sendVerifyParentEmail({ parentEmail });

        if (result.status === 200) {
            alert(getLanguageValue(PAGE_TYPE.AGENCY, "my.sendEmail"));
        }
    };

    const updateActive = async () => {
        setIsDisabled(true);
        setIsActive(!studentInfo.isActive);

        const id = studentInfo.id;
        const params = { isActive: !studentInfo.isActive };
        const result = await StudentApi.updateActive(id, params);

        if (result) {
            getInfo();
            setIsDisabled(false);
        }
    };

    const logout = async () => {
        await removePushToken();
        removeAuthTokens();
        dispatch(setIsLogin(false));
        dispatch(setUserInfo({}));
        navigate("/", { replace: true });
        await removeAppAccessToken()
    };

    useEffect(() => {
        if (termId) {
            updateUserTerm();
        }
    }, [marketingTermList]);

    useEffect(() => {
        if (location.pathname === "/student/my") {
            setUpdateType("");
        } else if (location.pathname.includes("/student/my/")) {
            const updateType = location.pathname?.split("-")[1] || "";
            setUpdateType(updateType);
        }
    }, [location]);

    useEffect(() => {
        getTermList();
        getInfo();
    }, []);

    return !updateType ? (
        <MyComponent
            email={myInfo.username}
            studentInfo={studentInfo}
            setTermId={setTermId}
            marketingTermList={marketingTermList}
            termList={termList}
            setUpdateType={setUpdateType}
            parentEmail={parentEmail}
            setParentEmail={setParentEmail}
            isDisabled={isDisabled}
            isActive={isActive}
            updateMarketingTermList={updateMarketingTermList}
            updateActive={updateActive}
            sendVerifyEmail={sendVerifyEmail}
            sendVerifyParentEmail={sendVerifyParentEmail}
            logout={logout}
        />
    ) : (
        <>
            {updateType === "profile" ? (
                <UpdateMy studentInfo={studentInfo} setStudentInfo={setStudentInfo} setUpdateType={setUpdateType} getInfo={getInfo} />
            ) : (
                <UpdateMyContact studentInfo={studentInfo} setStudentInfo={setStudentInfo} setUpdateType={setUpdateType} getInfo={getInfo} />
            )}
        </>
    );
};

export default MyContainer;
