import React, { useEffect, useRef, useState } from "react";
import AuditionApplyComponent from "./AuditionApply.component";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as AuditionApi from "api/student/Audition.api";
import * as StudentApi from "api/student/Student.api";
import * as ImageHelper from "helpers/Image.helper";
import * as VideoHelper from "helpers/Video.helper";
import * as DataApi from "api/common/Data.api";
import { getCurrentDate } from "helpers/Datetime.helper";

const AuditionApplyContainer = (props: any) => {
  const { id, applicant } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [auditionInfo, setAuditionInfo] = useState(null);
  const [auditionApplicantInfo, setAuditionApplicantInfo] = useState([] as any);
  const btnRef = useRef<any>();

  const [initImageInfoList, setInitImageInfoList] = useState([]);
  const [initImageIdList, setInitImageIdList] = useState([]);
  const [initImageInfo, setInitImageInfo] = useState<any>({});

  //image
  const [imageInfoList, setImageInfoList] = useState([]);
  const [imageIdList, setImageIdList] = useState([]);
  const [imageInfo, setImageInfo] = useState<any>({});
  const [isOnNetworking, setIsOnNetworking] = useState(false);

  //video
  const [videoInfoList, setVideoInfoList] = useState([]);
  const [videoIdList, setVideoIdList] = useState([]);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [isShowVideoPopup, setIsShowVideoPopup] = useState("");
  const [selectedVideoId, setSelectedVideoId] = useState("");

  const getAuditionInfo = async () => {
    const result = await AuditionApi.getAuditionInfo(parseInt(id), {});
    if (result?.info) {
      setAuditionInfo(result.info);
    } else {
      navigate(`/student/audition`);
    }
  };

  const getApplicantInfo = async () => {
    if (applicant) {
      const result = await AuditionApi.getApplicantInfo(
        parseInt(applicant),
        {}
      );

      if (result?.info) {
        if (result.info.profileImageInfo) {
          const profileInfo = await DataApi.getImagePresignedUrl(
            result.info.profileImageInfo.id
          );
          if (profileInfo) {
            result.info.profileImageInfo.url = profileInfo.result;
          }
        }

        if (result.info.imageInfoList && result.info.imageInfoList.length > 0) {
          const promises = result.info.imageInfoList.map(
            async (imageInfo: any) => {
              const imageInfoResult = await DataApi.getImagePresignedUrl(
                imageInfo.id
              );
              if (imageInfoResult) {
                imageInfo.url = imageInfoResult.result;
              }
            }
          );

          // 모든 비동기 작업이 완료될 때까지 기다립니다.
          await Promise.all(promises);
        }

        if (result.info.videoInfoList && result.info.videoInfoList.length > 0) {
          const promises = result.info.videoInfoList.map(
            async (videoInfo: any) => {
              const videoInfoResult = await DataApi.getVideoPresignedUrl(
                videoInfo.id
              );
              if (videoInfoResult) {
                videoInfo.url = videoInfoResult.result;
              }
            }
          );

          // 모든 비동기 작업이 완료될 때까지 기다립니다.
          await Promise.all(promises);
        }

        setImageInfo(result.info.profileImageInfo);
        setImageInfoList([...result.info.imageInfoList]);

        setInitImageInfo(result.info.profileImageInfo);
        setInitImageInfoList([...result.info.imageInfoList]);
        const imageIdList: string[] = [];
        result.info.imageInfoList.map((item: any) => {
          imageIdList.push(item.id);
        });
        setImageIdList(imageIdList);
        setInitImageIdList(imageIdList);

        setVideoInfoList([...result.info.videoInfoList]);
        const videoIdList: string[] = [];
        result.info.videoInfoList.map((item: any) => {
          videoIdList.push(item.id);
        });

        setVideoIdList(videoIdList);

        setAuditionApplicantInfo(result.info);
      } else {
        navigate(`/student/audition`);
      }
    } else {
      const result = await StudentApi.getInfoForStudent();

      if (result?.info) {
        setAuditionApplicantInfo(result.info);
        setImageInfo(result.info.profileImageInfo);
        setImageInfoList([...result.info.imageInfoList]);
        setInitImageInfo(result.info.profileImageInfo);
        setInitImageInfoList([...result.info.imageInfoList]);
        const imageIdList: string[] = [];
        result.info.imageInfoList.map((item: any) => {
          imageIdList.push(item.id);
        });

        setImageIdList(imageIdList);
        setInitImageIdList(imageIdList);
      } else {
        navigate(`/student/audition`);
      }
    }
  };

  const getVideoUrl = async (id: string) => {
    if (id) {
      const videoInfo = await DataApi.getVideoPresignedUrl(id);
      if (videoInfo) {
        const url = videoInfo.result;
        return url;
      }
    }
  };

  const initProfileImage = () => {
    setImageInfo(initImageInfo);
  };

  const initAdditionalImage = () => {
    setImageInfoList(initImageInfoList);
    setImageIdList(initImageIdList);
  };

  const isDisabled = async () => {
    if (auditionInfo.isFile && (!videoIdList || videoIdList.length === 0)) {
      alert(
        getLanguageValue(PAGE_TYPE.STUDENT, "auditionApply.errNeedVideoFile")
      );
      return true;
    } else if (
      auditionInfo.isProfile &&
      (!imageIdList || imageIdList.length === 0)
    ) {
      alert(
        getLanguageValue(
          PAGE_TYPE.STUDENT,
          "auditionApply.errNeedAdditionalImage"
        )
      );
      return true;
    } else {
      return false;
    }
  };

  const onClickBtnSave = async () => {
    if (!(await isDisabled())) {
      btnRef.current.disabled = true;

      let updatedParams = {
        auditionId: id,
        userSchoolId: auditionApplicantInfo.userSchoolId || "",
        userStudentId: applicant
          ? auditionApplicantInfo.userSchoolId || ""
          : auditionApplicantInfo.id || "",
        countryCode: auditionApplicantInfo.countryCodeInfo
          ? auditionApplicantInfo.countryCodeInfo.code
          : null,
        firstName: auditionApplicantInfo.firstName || "",
        lastName: auditionApplicantInfo.lastName || "",
        birthday: auditionApplicantInfo.birthday || "",
        genderTypeCode: auditionApplicantInfo.genderTypeCodeInfo
          ? auditionApplicantInfo.genderTypeCodeInfo.code
          : null,
        height: auditionApplicantInfo.height || "",
        weight: auditionApplicantInfo.weight || "",
        bloodTypeCode: auditionApplicantInfo.bloodTypeCodeInfo
          ? auditionApplicantInfo.bloodTypeCodeInfo.code
          : null,
        mbtiCode: auditionApplicantInfo.mbtiCode || null,
        specialtyCodeList: auditionApplicantInfo.specialtyCodeInfoList
          ? auditionApplicantInfo.specialtyCodeInfoList.map(
              (item: any) => item.code
            )
          : null,
        memo: "",
        applyDate: getCurrentDate(),
        profileImageId: imageInfo ? imageInfo.id : "",
        imageIdList: imageIdList ? imageIdList : [],
        videoIdList: videoIdList ? videoIdList : [],
      };

      if (applicant) {
        const result = await AuditionApi.updateApplicantInfo(
          parseInt(applicant),
          updatedParams
        );
        if (result) {
          btnRef.current.disabled = false;
          result.data?.errorCode
            ? alert(result.data.message)
            : navigate(`/student/audition/`, {
                state: 1,
              });
        }
      } else {
        // 이미지 복제
        let profileImageId = "";
        if (
          auditionApplicantInfo.profileImageInfo &&
          imageInfo &&
          auditionApplicantInfo.profileImageInfo.id === imageInfo.id
        ) {
          profileImageId = auditionApplicantInfo.profileImageInfo.id;
        }

        let additionalImageIdList = [];
        let newImages = [];
        if (auditionApplicantInfo.imageInfoList && imageInfoList) {
          additionalImageIdList = auditionApplicantInfo.imageInfoList
            .filter((imageInfoItem: any) =>
              imageInfoList.some((image) => image.id === imageInfoItem.id)
            )
            .map((imageInfoItem: any) => imageInfoItem.id);

          newImages = imageInfoList
            .filter(
              (image) =>
                !auditionApplicantInfo.imageInfoList.some(
                  (imageInfoItem: any) => imageInfoItem.id === image.id
                )
            )
            .map((image) => image.id);
        }
        const resultImageCopy = await DataApi.makeImageCopy({
          profileImageId: profileImageId,
          imageIdList: additionalImageIdList,
          isPublic: false,
        });

        if (resultImageCopy) {
          updatedParams.profileImageId = resultImageCopy.copiedProfileImageId
            ? resultImageCopy.copiedProfileImageId
            : imageInfo
            ? imageInfo.id
            : "";

          updatedParams.imageIdList = Array.from(
            new Set([...resultImageCopy.copiedImageIdList, ...newImages])
          );
        }

        const result = await AuditionApi.createApplicantInfo(updatedParams);
        if (result) {
          btnRef.current.disabled = false;
          result.data?.errorCode
            ? alert(result.data.message)
            : navigate(`/student/audition/`, {
                state: 1,
              });
        }
      }

      btnRef.current.disabled = false;
    }
  };

  const onClickBtnDelete = async () => {
    if (
      window.confirm(
        getLanguageValue(PAGE_TYPE.STUDENT, "auditionApply.txtConfirmCancel")
      )
    ) {
      const result = await AuditionApi.deleteApplicantInfo(
        parseInt(applicant),
        {}
      );

      if (result) {
        result.data?.errorCode
          ? alert(result.data.message)
          : navigate("/student/audition");
      }
    }
  };

  const deleteImage = (idx: number) => {
    setImageIdList(imageIdList.filter((_: any, i: number) => idx !== i));
    setImageInfoList(imageInfoList.filter((_: any, i: number) => idx !== i));
  };

  const deleteVideo = (idx: number) => {
    setVideoIdList(videoIdList.filter((_: any, i: number) => idx !== i));
    setVideoInfoList(videoInfoList.filter((_: any, i: number) => idx !== i));
  };

  const uploadPrevImage = async (type: string, item: any, evt: any) => {
    setIsOnNetworking(true);

    if (!evt) {
      return false;
    }

    const file = evt.target.files[0];
    let imageInfo = await ImageHelper.getImageInfo(file);
    if (!imageInfo) {
      alert("Please choose image file");
      return false;
    }

    const result = await DataApi.uploadImage(file, false);
    imageInfo.id = result.id;

    if (type === "profile") {
      setImageInfo({ id: result.id, url: imageInfo.url });
    }

    if (type === "addition") {
      const updatedImageInfoList = imageInfoList.map((info) =>
        info.id === item.id ? { ...info, ...imageInfo } : info
      );

      setImageInfoList(updatedImageInfoList);
      const updatedImageIdList = imageIdList.map((id) =>
        id === item.id ? result.id : id
      );

      setImageIdList(updatedImageIdList);
    }

    setIsOnNetworking(false);
  };

  const uploadImage = async (type: string, evt: any) => {
    setIsOnNetworking(true);

    if (!evt) {
      return false;
    }

    const file = evt.target.files[0];
    const imageInfo = await ImageHelper.getImageInfo(file);
    if (!imageInfo) {
      alert("Please choose image file");
      return false;
    }

    const result = await DataApi.uploadImage(file, false);
    imageInfo.id = result.id;
    if (type === "profile") {
      setImageInfo({ id: result.id, url: imageInfo.url });
    }
    if (type === "addition") {
      imageInfoList.push(imageInfo);
      setImageInfoList(imageInfoList);

      imageIdList.push(result.id);
      setImageIdList(imageIdList);
    }

    setIsOnNetworking(false);
  };

  const uploadVideo = async (evt: any) => {
    setIsOnNetworking(true);

    if (!evt) {
      return false;
    }

    const file = evt.target.files[0];
    const videoInfo = await VideoHelper.getVideoInfo(file);
    if (!videoInfo) {
      alert("Please choose video file");
      return false;
    }

    const result = await DataApi.uploadVideo(file, false);
    videoInfo.id = result.id;
    videoInfoList.push(videoInfo);
    setVideoInfoList(videoInfoList);

    videoIdList.push(result.id);
    setVideoIdList(videoIdList);

    setIsOnNetworking(false);
  };

  const init = async () => {
    getAuditionInfo();
    getApplicantInfo();
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <AuditionApplyComponent
      {...props}
      applicant={applicant}
      auditionInfo={auditionInfo}
      auditionApplicantInfo={auditionApplicantInfo}
      btnRef={btnRef}
      onClickBtnSave={onClickBtnSave}
      onClickBtnDelete={onClickBtnDelete}
      // profile
      imageInfo={imageInfo}
      setImageInfo={setImageInfo}
      uploadImage={uploadImage}
      uploadPrevImage={uploadPrevImage}
      imageInfoList={imageInfoList}
      onClickBtnDeleteImage={deleteImage}
      // video
      videoInfoList={videoInfoList}
      onClickBtnDeleteVideo={deleteVideo}
      uploadVideo={uploadVideo}
      selectedVideoUrl={selectedVideoUrl}
      setSelectedVideoUrl={setSelectedVideoUrl}
      isShowVideoPopup={isShowVideoPopup}
      setIsShowVideoPopup={setIsShowVideoPopup}
      getVideoUrl={getVideoUrl}
      //
      initProfileImage={initProfileImage}
      initAdditionalImage={initAdditionalImage}
      initImageInfoList={initImageInfoList}
      initImageIdList={initImageIdList}
      initImageInfo={initImageInfo}
      selectedVideoId={selectedVideoId}
      setSelectedVideoId={setSelectedVideoId}
    />
  );
};

export default AuditionApplyContainer;
