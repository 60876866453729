import React, { useEffect, useState } from "react";
import SubscribeEmailDoneComponent from "./SubscribeEmailDone.component";
import { useLocation, useNavigate } from "react-router-dom";

const SubscribeEmailDoneContainer = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};
  const { email = "" } = state;

  useEffect(() => {
    if (!email) {
      navigate("/");
    }
  }, []);

  return <SubscribeEmailDoneComponent {...props} email={email} />;
};

export default SubscribeEmailDoneContainer;
