import * as BaseApi from "./Base.api";

export const getRecommendWannabeList = async () => {
    const url = "/post/recommend-wannabe";
    return await BaseApi.get(url);
};

export const getPostList = async (params: any) => {
    const url = "/post/list";
    return await BaseApi.get(url, params);
};

export const getPostInfo = async (id: number) => {
    const url = `/post/info/${id}`;
    return await BaseApi.get(url);
};

export const createPost = async (params: any) => {
    const url = "/post";
    return await BaseApi.post(url, params);
};

export const editPost = async (id: number, params: any) => {
    const url = `/post/info/${id}`;
    return await BaseApi.patch(url, params);
};

export const deletePost = async (id: number) => {
    const url = `/post/info/${id}`;
    return await BaseApi.remove(url);
};

export const createPostComment = async (params: any) => {
    const url = "/post/comment";
    return await BaseApi.post(url, params);
};

export const editPostComment = async (id: number, params: any) => {
    const url = `/post/comment/${id}`;
    return await BaseApi.patch(url, params);
};

export const deletePostComment = async (id: number) => {
    const url = `/post/comment/${id}`;
    return await BaseApi.remove(url);
};

export const createPostLike = async (params: any) => {
    const url = "/post/like";
    return await BaseApi.post(url, params);
};

export const deletePostLike = async (params: any) => {
    const url = `/post/like`;
    return await BaseApi.remove(url, params);
};

export const getPostLikeList = async (id: any) => {
    const url = `/post/like/${id}`;
    return await BaseApi.get(url);
};

export const createFollow = async (params: any) => {
    const url = "/post/follow";
    return await BaseApi.post(url, params);
};

export const deleteFollow = async (params: any) => {
    const url = `/post/follow`;
    return await BaseApi.remove(url, params);
};

export const getFollowInfo = async (id: any, params: any) => {
    const url = `/post/follow/${id}`;
    return await BaseApi.get(url, params);
};

export const createPostReport = async (params: any) => {
    const url = "/post/report";
    return await BaseApi.post(url, params);
};

export const createPostCommentReport = async (params: any) => {
    const url = "/post/comment-report";
    return await BaseApi.post(url, params);
};
