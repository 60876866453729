import React, { useEffect, useRef, useState } from "react";
import { getListBySchool } from "api/student/Student.api";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";
import { useLocation } from "react-router-dom";
import { getList as getStudentForAgencyList } from "api/student/Student.api";
import { useSelector } from "react-redux";
import { getUserType } from "helpers/Common.helper";
import { PAGE_TYPE } from "enums";

const StudentSearchPopupComponent = (props: any) => {
  const { setIsShowStudentSearchPopup, sendStudentImageMessage } = props;
  const { info: userInfo } = useSelector((state: any) => state.user);
  const location = useLocation();
  const { schoolId } = location.state ? location.state : { schoolId: false };
  const keywordRef = useRef(null);
  const [list, setList] = useState([]);

  const getListValue = async () => {
    const keyword = keywordRef.current.value;

    const userType = getUserType(userInfo?.accountTypeCode);
    const result =
      userType === PAGE_TYPE.AGENCY
        ? await getStudentForAgencyList({ keyword, schoolId })
        : await getListBySchool({ keyword });

    if (result?.list) {
      setList(result.list);
    }
  };
  useEffect(() => {
    getListValue();
  }, []);
  return (
    <div className="popup popup-student-search">
      <div
        className="close-btn"
        onClick={(e: any) => {
          e.preventDefault();
          setIsShowStudentSearchPopup(false);
        }}></div>
      {/* <!-- 학생 검색 팝업 시작 --> */}
      <div className="con">
        <h2>STUDENT SEARCH</h2>
        <div className="search-wrap">
          <div className="search-bar">
            <input
              id="input-search"
              name="input-search"
              type="text"
              placeholder="search by name"
              ref={keywordRef}
              onKeyDown={(evt) => {
                if (evt.key === "Enter") getListValue();
              }}
            />
            <button className="search" onClick={getListValue}>
              <img
                className="search-icon"
                src="./imgs/icons/icon-search-gray.png"
              />
            </button>
          </div>
        </div>
        <div className="list-wrap">
          <div className="list">
            <ul>
              {list
                ? list.map((item, index) => (
                    <li key={`search-std-${index}`}>
                      <a
                        onClick={(
                          e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                        ) => {
                          e.preventDefault();

                          setIsShowStudentSearchPopup(false);

                          sendStudentImageMessage(
                            item?.id,
                            `${item?.firstName} ${item?.lastName}`,
                            item?.profileImageUrl
                          );
                        }}>
                        <div
                          className="img"
                          style={{
                            backgroundImage: `url(${
                              item?.profileImageUrl
                                ? item.profileImageUrl
                                : "imgs/dummy/basic-img.png"
                            }?w=480&h=760)`,
                          }}></div>
                        <div className="student-info">
                          <p className="ellipsis">
                            {getCurrentLangName(item?.countryCodeInfo.names)}
                          </p>
                          <p className="favorite-student-name ellipsis">
                            {item?.firstName} {item?.lastName}
                          </p>
                          <p className="ellipsis">
                            {getCurrentLangName(item?.genderTypeCodeInfo.names)}
                          </p>
                          <p className="ellipsis">
                            {getFormattedDateValue(
                              item?.birthday,
                              "YYYY.MM.DD"
                            )}
                          </p>
                        </div>
                      </a>
                    </li>
                  ))
                : null}
            </ul>
          </div>
        </div>

        {/* <!-- 학생 검색 팝업 끝 --> */}
      </div>
    </div>
  );
};

export default StudentSearchPopupComponent;
