import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect } from "react";

const MissionComponent = (props: any) => {
    useEffect(() => {}, []);

    return (
        <main className="mission">
            <div className="con">
                <div className="mission-message">
                    <h1 className="main-title">MISSION</h1>
                    <h2 className="title">WE HELP MORE PEOPLE BECOME ENTERTAINERS.</h2>
                    <p className="message">{getLanguageValue(PAGE_TYPE.COMMON, "mission.title1")}</p>
                </div>
                <div className="vision-message">
                    <h1 className="main-title">VISION</h1>
                    <h2 className="title">GLOBAL NO.1 SUPPLY CHAIN FOR NEXT-GENERATION K-POP IDOL WANNABES.</h2>
                    <p className="message">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: getLanguageValue(PAGE_TYPE.COMMON, "mission.title2"),
                            }}></div>
                    </p>
                </div>
                <div className="wrap">
                    <div className="message-wrap">
                        <h3 className="">
                            <span>1.</span>
                            <span className="sub-title">WANNABES</span>
                        </h3>
                        <div>
                            <p>
                                <span>· </span>
                                {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub11")}
                            </p>
                            <p>
                                <span>· </span>
                                {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub12")}
                            </p>
                            <p>
                                <span>· </span>
                                {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub13")}
                            </p>
                        </div>
                    </div>
                    <div className="message-wrap">
                        <h3 className="">
                            <span>2.</span>
                            <span className="sub-title">AGENCY</span>
                        </h3>
                        <div>
                            <p>
                                <span>· </span>
                                {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub21")}
                            </p>
                            <p>
                                <span>· </span>
                                {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub22")}
                            </p>
                            <p>
                                <span>· </span>
                                {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub23")}
                            </p>
                        </div>
                    </div>
                    <div className="message-wrap">
                        <h3 className="">
                            <span>3.</span>
                            <span className="sub-title">LOCAL SCHOOL AROUND THE WORLD</span>
                        </h3>
                        <div>
                            <p>
                                <span>· </span>
                                {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub31")}
                            </p>
                            <p>
                                <span>· </span>
                                {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub32")}
                            </p>
                            <p>
                                <span>· </span>
                                {getLanguageValue(PAGE_TYPE.COMMON, "mission.sub33")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};
export default MissionComponent;
