// 페이지 type
export const enum PAGE_TYPE {
  COMMON = "common",
  AGENCY = "agency",
  SCHOOL = "school",
  STUDENT = "student",
}

// chart title position
export const enum CHART_TITLE_POSITION {
  TOP = "top",
  LEFT = "left",
  RIGHT = "right",
  bottom = "bottom",
}
