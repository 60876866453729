import { getCurrentLangName } from "helpers/String.helper";

let commonData: any = {};
let languageCode: string = "ko";
export const setAllCodeList = async (allCodeList: any) => {
  Object.keys(allCodeList).forEach((key) => {
    commonData[key] = allCodeList[key];
  });
};

export const setCommonCodeList = (commonCodeList: any) => {
  commonData["commonCodeList"] = commonCodeList;
};
export const getCommonCodeList = () => {
  return commonData["commonCodeList"];
};
export const getCommonCodeListByParent = (parent: string) => {
  return commonData["commonCodeList"].filter(
    (code: any) => code.parentCode === parent
  );
};

export const getCommonCodeInfo = (parmaCode: string) => {
    return commonData["commonCodeList"].find(
      (code: any) => code.code === parmaCode
    );
  };

export const setCountryCodeList = (countryCodeList: any) => {
  commonData["countryCodeList"] = countryCodeList;
};

export const getCountryCodeList = () => {
  console.log("common.data:getCountryCodeList");
  let sortedCountryList = [...commonData.countryCodeList].sort((a, b) => {
    if (getCurrentLangName(a.names) < getCurrentLangName(b.names)) {
      return -1;
    }
    if (getCurrentLangName(a.names) > getCurrentLangName(b.names)) {
      return 1;
    }
    return 0;
  });
  return sortedCountryList;
};

export const getCountryPhoneCodeList = () => {
    console.log("common.data:getCountryPhoneCodeList");
    const sortedCountryPhoneList = [...commonData.countryPhoneCodeList].sort((a, b) => {
      if (getCurrentLangName(a.names) < getCurrentLangName(b.names)) {
        return -1;
      }
      if (getCurrentLangName(a.names) > getCurrentLangName(b.names)) {
        return 1;
      }
      return 0;
    });
    return sortedCountryPhoneList;
  };

export const setMbtiCodeList = (mbtiCodeList: any) => {
  commonData["mbtiCodeList"] = mbtiCodeList;
};
export const getMbtiCodeList = () => {
  return commonData["mbtiCodeList"];
};

export const setScoreCodeList = (mbtiCodeList: any) => {
  commonData["scoreCodeList"] = mbtiCodeList;
};
export const getScoreCodeList = () => {
  return commonData["scoreCodeList"];
};

export const setLanguageCode = (langCode: string) => {
  languageCode = langCode;
};
export const getLanguageCode = (onlyLanguageCode: boolean = true) => {
  if (onlyLanguageCode) {
    return languageCode.split("-")[0];
  } else {
    return languageCode;
  }
};

export const setTermsList = (termsList: any) => {
  commonData["termsList"] = termsList;
};
export const getTermsList = () => {
  return commonData["termsList"];
};

