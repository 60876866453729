export const SET_INFO = "USER/SET_INFO";
export const SET_IS_LOGIN = "USER/SET_IS_LOGIN";

export const setInfo = (info: any) => ({ type: SET_INFO, info });
export const setIsLogin = (isLogin: boolean) => ({
  type: SET_IS_LOGIN,
  isLogin,
});

const initialState = {
  isLogin: false,
  info: {},
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_INFO:
      return {
        ...state,
        info: action.info,
      };
    case SET_IS_LOGIN:
      return {
        ...state,
        isLogin: action.isLogin,
      };
    default:
      return state;
  }
}