import * as BaseApi from "../Base.api";

export const getList = async (params?: any) => {
  const url = "/student";
  return await BaseApi.get(url, params);
};

export const getListForHome = async () => {
  const url = "/student/home";
  return await BaseApi.get(url);
};

export const getListBySchool = async (params?: any) => {
  const url = "/school/student";
  return await BaseApi.get(url, params);
};

export const getInfoForStudent = async () => {
  const url = "/student/info/student";
  return await BaseApi.get(url);
};

export const getInfoForAgency = async (studentId: number) => {
  const url = `/student/info/agency/${studentId}`;
  return await BaseApi.get(url);
};

export const getInfoForHome = async () => {
  const url = "/student/info/home";
  return await BaseApi.get(url);
};

export const getFavoriteCntList = async (params: any) => {
  const url = "/student/favorite";
  return await BaseApi.get(url, params);
};

export const getViewCntList = async (params: any) => {
  const url = "/student/view";
  return await BaseApi.get(url, params);
};

export const updateInfo = async (id: number, params: any) => {
  const url = `/student/${id}`;
  return await BaseApi.put(url, params);
};

export const updateAdditionalInfo = async (id: number, params: any) => {
  const url = `/student/additional-info/${id}`;
  return await BaseApi.patch(url, params);
};

export const updateTermsInfo = async (id: number, params: any) => {
  const url = `/student/terms/${id}`;
  return await BaseApi.put(url, params);
};

export const createFavorite = async (params: any) => {
  const url = "/student/favorite";
  return await BaseApi.post(url, params);
};

export const deleteFavorite = async (params: any) => {
  const url = "/student/favorite";
  return await BaseApi.remove(url, params);
};

export const getUpdatedFavoriteCount = async () => {
  const url = "/student/updated-favorite-count";
  return await BaseApi.get(url);
};

export const updateVerify = async (id: number, params: any) => {
  const url = `/student/verify/${id}`;
  return await BaseApi.patch(url, params);
};

export const updateActive = async (id: number, params: any) => {
  const url = `/student/active/${id}`;
  return await BaseApi.patch(url, params);
};

export const updateViewCount = async (params: any) => {
    const url = `/student/view-count`;
    return await BaseApi.post(url, params);
  };
