import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import StudentSearchPopupComponent from "commons/popup/StudentSearchPopup.component";
import { formatTimeAgo } from "helpers/Datetime.helper";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const SchoolHeaderComponent = (props: any) => {
  const {
    logout,
    render,
    mobileMenuSlideClassName,
    setMobileMenuSlideClassName,
    showNotification,
    setShowNotification,
    notificationList,
    totalUnReadCount,
    onClickCheckNotification,
    mobileNotificationSlideClassName,
    setMobileNotificationMenuSlideClassName,
  } = props;
  const location = useLocation();
  const { isShowSearchPopup, newMessageCount } = useSelector(
    (state: any) => state.school
  );
  const { info: myInfo } = useSelector((state: any) => state.user);

  return (
    <header className="header">
      <div className="con">
        <div className="logo">
          <a
            className="img-wrap"
            onClick={(e: any) => {
              e.preventDefault();
              render("/school");
            }}>
            <img
              src="./imgs/common/logo/img-logo/logo-t2-125.png"
              alt="wannabes"
            />
          </a>
        </div>

        <div className="gnb">
          <ul>
            <li>
              <div
                className={`item ${
                  location.pathname.startsWith("/school/student") ? "on" : ""
                }`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();

                    render(`/school/student`);
                  }}>
                  STUDENT
                </a>
              </div>
            </li>
            <li>
              <div
                className={`item ${
                  location.pathname.startsWith("/school/portfolio") ? "on" : ""
                }`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();

                    render(`/school/portfolio`);
                  }}>
                  PORTFOLIO
                </a>
              </div>
            </li>
            <li>
              <div
                className={`item ${
                  location.pathname.startsWith("/school/message") ? "on" : ""
                }`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();

                    render(`/school/message`);
                  }}>
                  MESSAGE
                </a>
                {newMessageCount ? (
                  <div className="badge">
                    <p>{newMessageCount}</p>
                  </div>
                ) : null}
              </div>
            </li>
            <li>
              <div className={`item`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();
                    render(`/`);
                  }}>
                  PLAY GROUND
                </a>
              </div>
            </li>
          </ul>
        </div>

        <div className="pc-tb-util">
          <div className={`alarm-wrap ${showNotification ? "" : "off"}`}>
            <button
              className="alarm"
              onClick={(e) => {
                setShowNotification(!showNotification);
              }}>
              <img src="./imgs/icons/alarm-icon.png" alt="alarm" />
              {totalUnReadCount ? <span>{totalUnReadCount}</span> : null}
            </button>

            <div className="alarm-layer-content">
              <strong>
                {getLanguageValue(
                  PAGE_TYPE.SCHOOL,
                  "header.txtUnreadNotifications"
                )}{" "}
                ({totalUnReadCount ? totalUnReadCount : 0})
              </strong>
              <a
                className="button-all-view"
                onClick={(e) => {
                  e.preventDefault();
                  render("/school/notification");
                }}>
                {getLanguageValue(PAGE_TYPE.SCHOOL, "header.txtViewAll")}
              </a>
              {notificationList && notificationList.length === 0 ? (
                <p className="no-notification">
                  {getLanguageValue(
                    PAGE_TYPE.SCHOOL,
                    "header.txtNodataNotification"
                  )}
                </p>
              ) : (
                <ul>
                  {notificationList.map((notification: any) => (
                    <li
                      onClick={(e) => {
                        onClickCheckNotification(notification);
                      }}
                      key={notification.id}
                      className={notification.isRead ? "read" : "not-read"}>
                      <div className="notification-header">
                        {formatTimeAgo(notification.sendAt)}
                      </div>
                      <div className="notification-body">
                        <div className="notification-title">
                          {notification.title}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <button
            className="logo"
            onClick={(e: any) => {
              e.preventDefault();
              render("/school/my");
            }}>
            <img
              src={`${
                myInfo && myInfo.logoImageInfo
                  ? myInfo.logoImageInfo.url.replace(/ /g, "%20") + ""
                  : "./imgs/dummy/basic-img-2.png"
              }`}
              alt="agency logo"
            />
          </button>
        </div>

        <div className="mb mb-alarm">
          <button
            className="alarm"
            onClick={(e) => {
              e.preventDefault();
              render("/school/notification");
              setMobileNotificationMenuSlideClassName("on");
            }}>
            <img src="./imgs/icons/alarm-icon.png" alt="alarm" />
            {totalUnReadCount ? <span>{totalUnReadCount}</span> : null}
          </button>

          <nav className={`alarm-gnb ${mobileNotificationSlideClassName}`}>
            <div className="btn">
              <button
                className="close-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setMobileNotificationMenuSlideClassName("off");
                }}>
                <img
                  src="./imgs/icons/icon-close-btn-mb.png"
                  alt="close button"
                />
              </button>
            </div>
            <div className="con">
              <strong>
                {getLanguageValue(
                  PAGE_TYPE.SCHOOL,
                  "header.txtUnreadNotifications"
                )}{" "}
                ({totalUnReadCount ? totalUnReadCount : 0})
              </strong>
              <a
                className="button-all-view"
                onClick={(e) => {
                  e.preventDefault();
                  render("/school/notification");
                }}>
                {getLanguageValue(PAGE_TYPE.SCHOOL, "header.txtViewAll")}
              </a>
            </div>
            {notificationList && notificationList.length === 0 ? (
              <p className="no-notification">
                {getLanguageValue(
                  PAGE_TYPE.SCHOOL,
                  "header.txtNodataNotification"
                )}
              </p>
            ) : (
              <ul>
                {notificationList.map((notification: any) => (
                  <li
                    onClick={(e) => {
                      onClickCheckNotification(notification);
                    }}
                    key={notification.id}
                    className={notification.isRead ? "read" : "not-read"}>
                    <div className="notification-header">
                      {formatTimeAgo(notification.sendAt)}
                    </div>
                    <div className="notification-body">
                      <div className="notification-title">
                        {notification.title}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {/* <div className="mb-util">
              <button
                className="btn-mypage"
                onClick={(e) => {
                  render("/school/my");
                }}>
                MY PAGE
              </button>
            </div> */}
            {/* <li>
              <div className={`item`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();
                    render(`/`);
                  }}>
                  PLAY GROUND
                </a>
              </div>
            </li> */}
          </nav>
        </div>

        <div
          className="mb-btn-hamburger"
          onClick={() => setMobileMenuSlideClassName("on")}>
          <img src="./imgs/icons/icon-hamburger.png" alt="Hamburger menu" />
        </div>

        {/* <!-- 
            모바일일 경우 class 에 
            on 을 추가해 메뉴 slide in
            off 를 추가해 메뉴 slide out
        --> */}
        <nav className={`mb-gnb ${mobileMenuSlideClassName}`}>
          <div className="btn">
            <button
              className="close-btn"
              onClick={() => setMobileMenuSlideClassName("off")}>
              <img
                src="./imgs/icons/icon-close-btn-mb.png"
                alt="close button"
              />
            </button>
          </div>
          <ul>
            <li>
              <div
                className={`item ${
                  location.pathname.startsWith("/school/student") ? "on" : ""
                }`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();

                    render(`/school/student`);
                  }}>
                  STUDENT
                </a>
              </div>
            </li>
            <li>
              <div
                className={`item ${
                  location.pathname.startsWith("/school/portfolio") ? "on" : ""
                }`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();

                    render(`/school/portfolio`);
                  }}>
                  PORTFOLIO
                </a>
              </div>
            </li>
            <li>
              <div
                className={`item ${
                  location.pathname.startsWith("/school/message") ? "on" : ""
                }`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();

                    render(`/school/message`);
                  }}>
                  MESSAGE
                </a>
                {newMessageCount ? (
                  <div className="badge">
                    <p>{newMessageCount}</p>
                  </div>
                ) : null}
              </div>
            </li>
            <li>
              <div className={`item`}>
                <a
                  onClick={(e: any) => {
                    e.preventDefault();

                    render(`/`);
                  }}>
                  PLAY GROUND
                </a>
              </div>
            </li>
          </ul>

          <div className="mb-util">
            <div className="col">
              <button
                className="btn-logout"
                onClick={(e: any) => {
                  e.preventDefault();
                  setMobileMenuSlideClassName("off");
                  logout();
                }}>
                LOGOUT
              </button>
            </div>
            <div className="col">
              <button
                className="btn-mypage"
                onClick={(e: any) => {
                  e.preventDefault();
                  render("/school/my");
                }}>
                MY PAGE
              </button>
            </div>
            {/* <!-- 로그아웃, 마이페이지 버튼  --> */}
          </div>
        </nav>
      </div>
      {isShowSearchPopup ? <StudentSearchPopupComponent /> : null}
    </header>
  );
};
export default SchoolHeaderComponent;
