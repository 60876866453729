import React, { useEffect, useRef, useState } from "react";
import LoadingComponent from "commons/loading/Loading.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";
import { useNavigate } from "react-router-dom";
import { getCommonCodeListByParent } from "data/common.data";
import Slider from "react-slick";
import ThumbnailComponent from "commons/video/Thumbnail.component";

const AuditionDetailComponent = (props: any) => {
  const navigate = useNavigate();
  const {
    id,
    isShowLoading,
    auditionInfo,
    prev,
    // applicant info
    auditionApplicantInfo,
    showImagePopup,
    isShowPopup,
    initialSlideIdx,
    setIsShowPopup,
    sliderRef,
    selectedVideoUrl,
    setSelectedVideoUrl,
    isShowVideoPopup,
    setIsShowVideoPopup,
    selectedVideoId,
    setSelectedVideoId,
    getVideoUrl,
  } = props;

  const specialtyList = getCommonCodeListByParent("specialty");
  const selectedSpecialties = specialtyList.filter((specialty: any) =>
    auditionInfo.specialtyCodeList?.some(
      (item: any) => item.code === specialty.code
    )
  );

  const videoPopupRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [initFlag, setInitFlag] = useState(false);

  const getSelectedVideoUrl = async () => {
    const url = await getVideoUrl(selectedVideoId);
    setSelectedVideoUrl(url);
  };
  useEffect(() => {
    setCurrentTime(0);
    getSelectedVideoUrl();
  }, [selectedVideoId]);

  useEffect(() => {
    const video = videoPopupRef.current;

    if (video && isShowVideoPopup) {
      video.src = selectedVideoUrl;
      video.muted = true;
      video.playsInline = true;
      video.currentTime = currentTime;
      video.load();
      const handleLoadedData = () => {
        video.play().catch((e: any) => console.log(e));
      };

      video.addEventListener("loadeddata", handleLoadedData);
      video.addEventListener("play", handlePlay);
      video.addEventListener("pause", handlePause);
      video.addEventListener("ended", handleEnded);
      video.addEventListener("seeked", handleSeeked);

      return () => {
        video.removeEventListener("loadeddata", handleLoadedData);
        video.removeEventListener("play", handlePlay);
        video.removeEventListener("pause", handlePause);
        video.removeEventListener("ended", handleEnded);
        video.removeEventListener("seeked", handleSeeked);
      };
    }
  }, [isShowVideoPopup, selectedVideoUrl]);

  useEffect(() => {
    const handleInitFlagChange = async () => {
      if (!initFlag) {
        const url = await getVideoUrl(selectedVideoId);
        setSelectedVideoUrl(url);
        setInitFlag(false);
      }
    };

    handleInitFlagChange();
  }, [initFlag]);

  const handleEnded = () => {
    setCurrentTime(0);
  };

  const handleSeeked = () => {
    const video = videoPopupRef.current;
    setCurrentTime(video.currentTime);
  };

  const handlePlay = () => {
    setInitFlag(false);
  };

  const handlePause = () => {
    const video = videoPopupRef.current;
    setCurrentTime(video.currentTime);
    setInitFlag(true);
  };

  const Arrow = () => {
    return <div />;
  };

  const settings = {
    className: "slider",
    infinite:
      auditionApplicantInfo.imageInfoList &&
      auditionApplicantInfo?.imageInfoList.length > 1
        ? true
        : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialSlideIdx,
    prevArrow: <Arrow />,
    nextArrow: <Arrow />,
  };

  return (
    <main className="">
      {isShowLoading ? (
        <LoadingComponent />
      ) : (
        <div className="con">
          <div className="audition-details">
            <div
              className="back-btn"
              onClick={() => {
                navigate(`/student/audition`, { state: { page: prev } });
              }}></div>
            <div className="agency-logo">
              <img
                src={`${
                  auditionInfo && auditionInfo.logoImageInfo
                    ? auditionInfo.logoImageInfo.url.replace(/ /g, "%20") + ""
                    : "./imgs/icons/agency-logo-default.png"
                }`}
                alt="agency logo"
              />
              <p>{auditionInfo.companyName}</p>
            </div>
            <h2>{auditionInfo.title}</h2>
            <div className="form">
              <div className="check">
                <h3>
                  {getLanguageValue(
                    PAGE_TYPE.STUDENT,
                    "auditionDetail.txtPeriod"
                  )}
                </h3>
                <div className="check-wrap">
                  <p>{`- ${getFormattedDateValue(
                    auditionInfo.startDate,
                    "YYYY.MM.DD"
                  )}~${getFormattedDateValue(
                    auditionInfo.endDate,
                    "YYYY.MM.DD"
                  )}`}</p>
                </div>
              </div>
              <div className="check">
                <h3>
                  {getLanguageValue(
                    PAGE_TYPE.STUDENT,
                    "auditionDetail.txtCountry"
                  )}
                </h3>
                <div className="check-wrap">
                  <p>
                    {auditionInfo.countryCodeInfo
                      ? `- ${getCurrentLangName(
                          auditionInfo.countryCodeInfo.names
                        )}`
                      : `- ${getLanguageValue(
                          PAGE_TYPE.STUDENT,
                          "auditionDetail.txtGenderOptional"
                        )}`}
                  </p>
                </div>
              </div>
              <div className="check">
                <h3>
                  {getLanguageValue(PAGE_TYPE.STUDENT, "auditionDetail.txtAge")}
                </h3>
                <div className="check-wrap">
                  {auditionInfo.minimumAge || auditionInfo.maximumAge ? (
                    <p>{`- ${
                      auditionInfo.minimumAge
                        ? auditionInfo.minimumAge
                        : getLanguageValue(
                            PAGE_TYPE.STUDENT,
                            "auditionDetail.txtGenderOptional"
                          )
                    } ~ ${
                      auditionInfo.maximumAge
                        ? auditionInfo.maximumAge
                        : getLanguageValue(
                            PAGE_TYPE.STUDENT,
                            "auditionDetail.txtGenderOptional"
                          )
                    }`}</p>
                  ) : (
                    <p>{`- ${getLanguageValue(
                      PAGE_TYPE.STUDENT,
                      "auditionDetail.txtGenderOptional"
                    )}`}</p>
                  )}
                </div>
              </div>
              <div className="check">
                <h3>
                  {getLanguageValue(
                    PAGE_TYPE.STUDENT,
                    "auditionDetail.txtGender"
                  )}
                </h3>
                <div className="check-wrap">
                  <p>{`- ${
                    auditionInfo.genderTypeCodeInfo === null
                      ? getLanguageValue(
                          PAGE_TYPE.STUDENT,
                          "auditionDetail.txtGenderOptional"
                        )
                      : auditionInfo.genderTypeCodeInfo.code ===
                        "genderType:girl"
                      ? getLanguageValue(
                          PAGE_TYPE.STUDENT,
                          "auditionDetail.txtGenderGirl"
                        )
                      : getLanguageValue(
                          PAGE_TYPE.STUDENT,
                          "auditionDetail.txtGenderBoy"
                        )
                  }`}</p>
                </div>
              </div>
              <div className="check">
                <h3>
                  {getLanguageValue(
                    PAGE_TYPE.STUDENT,
                    "auditionDetail.txtSpecialty"
                  )}
                </h3>
                <div className="check-wrap">
                  <p>
                    -{" "}
                    {selectedSpecialties.map((specialty: any, idx: number) => {
                      const isLastItem = idx === selectedSpecialties.length - 1;
                      return (
                        <>
                          {getCurrentLangName(specialty.names)}
                          {!isLastItem && ", "}
                        </>
                      );
                    })}
                  </p>
                </div>
              </div>
              <div className="check">
                <h3>
                  {getLanguageValue(
                    PAGE_TYPE.STUDENT,
                    "auditionDetail.txtFile"
                  )}
                </h3>
                <div className="check-wrap">
                  <p>{`- ${getLanguageValue(
                    PAGE_TYPE.STUDENT,
                    "auditionDetail.txtVideo"
                  )} ${auditionInfo.isFile ? "O" : "X"}`}</p>
                </div>
              </div>
              <div className="check">
                <h3>
                  {getLanguageValue(
                    PAGE_TYPE.STUDENT,
                    "auditionDetail.txtAdditionalInfo"
                  )}
                </h3>
                <div className="check-wrap">
                  <p>{`- ${getLanguageValue(
                    PAGE_TYPE.STUDENT,
                    "auditionDetail.txtAdditionalImage"
                  )} ${auditionInfo.isProfile ? "O" : "X"}`}</p>
                </div>
              </div>
              <div className="check">
                <h3>
                  {getLanguageValue(
                    PAGE_TYPE.STUDENT,
                    "auditionDetail.txtIntroduce"
                  )}
                </h3>
                <div className="check-wrap">
                  <p>{auditionInfo.introduce}</p>
                </div>
              </div>
            </div>

            {auditionInfo.isApplied === 1 ? (
              <div className="application-info">
                <h2>MY APPLICATION</h2>
                <img
                  src={`${
                    auditionApplicantInfo &&
                    auditionApplicantInfo.profileImageInfo
                      ? auditionApplicantInfo.profileImageInfo.url.replace(
                          / /g,
                          "%20"
                        )
                      : "./imgs/dummy/basic-img-2.png"
                  }`}
                  alt="main profile image"
                />
                <div className="info-1">
                  <div className="info-1-wrap">
                    <p className="name">
                      {auditionApplicantInfo.firstName}{" "}
                      {auditionApplicantInfo.lastName}
                    </p>
                    <p className="birth">
                      {getFormattedDateValue(
                        auditionApplicantInfo.birthday,
                        "YYYY.MM.DD"
                      )}
                    </p>
                    <p className="country">
                      {auditionApplicantInfo.countryCodeInfo
                        ? getCurrentLangName(
                            auditionApplicantInfo.countryCodeInfo.names
                          )
                        : ""}
                    </p>
                    <p>
                      {auditionApplicantInfo.height
                        ? `${auditionApplicantInfo.height}cm`
                        : null}
                      {auditionApplicantInfo.weight
                        ? ` / ${auditionApplicantInfo.weight}kg`
                        : null}
                      {auditionApplicantInfo.bloodTypeCodeInfo
                        ? ` / ${getCurrentLangName(
                            auditionApplicantInfo.bloodTypeCodeInfo.names
                          )}`
                        : null}
                    </p>
                    <p>
                      {auditionApplicantInfo.specialtyCodeInfoList
                        ? auditionApplicantInfo.specialtyCodeInfoList.map(
                            (item: any) => (
                              <span>{getCurrentLangName(item.names)}</span>
                            )
                          )
                        : null}

                      {auditionApplicantInfo.mbtiCode ? (
                        <span>{auditionApplicantInfo.mbtiCode}</span>
                      ) : null}
                    </p>
                  </div>
                </div>

                {auditionApplicantInfo.videoInfoList &&
                auditionApplicantInfo.videoInfoList.length > 0 ? (
                  <div className="info-2">
                    <h2>MEDIA</h2>
                    <ul className="media-list-wrap">
                      {auditionApplicantInfo.videoInfoList.map(
                        (videoInfo: any, index: number) => (
                          <ThumbnailComponent
                            key={videoInfo.id}
                            videoId={videoInfo.id}
                            videoUrl={videoInfo.url}
                            setSelectedVideoUrl={setSelectedVideoUrl}
                            setSelectedVideoId={setSelectedVideoId}
                            setIsShowVideoPopup={setIsShowVideoPopup}
                            getVideoUrl={getVideoUrl}
                          />
                        )
                      )}
                    </ul>
                    {isShowVideoPopup ? (
                      <div
                        id="popup"
                        className="popup media-popup"
                        style={{ display: "flex" }}>
                        <div className="popup-content">
                          <span
                            className="close-btn"
                            onClick={(e) => {
                              setIsShowVideoPopup(false);
                            }}>
                            <img
                              src="./imgs/icons/close-white.png"
                              alt="close button"
                            />
                          </span>
                          <video
                            controls
                            ref={videoPopupRef}
                            muted
                            playsInline
                            preload={"auto"}>
                            <source src={selectedVideoUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}

                {auditionApplicantInfo.imageInfoList &&
                auditionApplicantInfo.imageInfoList.length > 0 ? (
                  <div className="info-3">
                    <h2>ADDITIONAL PHOTOS</h2>
                    <ul>
                      {auditionApplicantInfo.imageInfoList.map(
                        (imageInfo: any, index: number) => (
                          <li
                            key={index}
                            onClick={(evt) => showImagePopup(index)}>
                            <img
                              src={`${
                                imageInfo
                                  ? imageInfo.url.replace(/ /g, "%20")
                                  : ""
                              }`}
                              alt="additional photo"
                            />
                          </li>
                        )
                      )}
                    </ul>
                    {isShowPopup ? (
                      <div className="popup popup-additional-photo">
                        <div
                          className="close-btn"
                          onClick={(evt) => {
                            setIsShowPopup(false);
                          }}></div>
                        <h2>PHOTOS</h2>
                        <div className="wrap">
                          <Slider ref={sliderRef} {...settings}>
                            {auditionApplicantInfo.imageInfoList.map(
                              (item: any) => (
                                <img className="img" src={`${item.url}`} />
                              )
                            )}
                          </Slider>
                          {auditionApplicantInfo.imageInfoList.length > 1 ? (
                            <>
                              <button
                                className="custom-prev"
                                onClick={(evt) => sliderRef.current.slickPrev()}
                              />
                              <button
                                className="custom-next"
                                onClick={(evt) => sliderRef.current.slickNext()}
                              />
                            </>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            ) : null}

            <div className="btn">
              {auditionInfo.isApplied === 0 ? (
                <div>
                  <button
                    className="apply-btn"
                    style={{
                      cursor:
                        auditionInfo.status === "auditionStatus:inProgress"
                          ? "pointer"
                          : "default",
                    }}
                    onClick={() => {
                      if (auditionInfo.status === "auditionStatus:inProgress") {
                        navigate(`/student/audition/apply/${id}`);
                      }
                    }}>
                    {auditionInfo.status === "auditionStatus:inProgress"
                      ? getLanguageValue(
                          PAGE_TYPE.STUDENT,
                          "auditionDetail.btnApply"
                        )
                      : "Comming Soon"}
                  </button>
                </div>
              ) : auditionInfo.status === "auditionStatus:inProgress" ? (
                <div>
                  <button
                    className="modify-btn"
                    onClick={(e) => {
                      navigate(
                        `/student/audition/apply/${id}/id/${auditionApplicantInfo.id}`
                      );
                    }}>
                    {getLanguageValue(
                      PAGE_TYPE.STUDENT,
                      "auditionDetail.btnModify"
                    )}
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </main>
  );
};
export default AuditionDetailComponent;
