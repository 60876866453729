import React, { useEffect, useState } from "react";
import SubscribeEmailComponent from "./SubscribeEmail.component";
import * as HomeApi from "api/home/Home.api";
import { useNavigate, useSearchParams } from "react-router-dom";

const UnSubscribeEmailContainer = (props: any) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const agencyId = searchParams.get("agencyId");
  const [email, setEmail] = useState("");

  const subscribedEmail = async () => {
    const result = await HomeApi.updateUnSubscribedEmailInfo(+agencyId, {
      email: email,
      hasUnsubscribedEmail: false,
    });

    if (result && result.status === 200) {
      navigate("/subscribed/new-wannabe/done", { state: { email: email } });
    }
  };

  useEffect(() => {
    if (!agencyId || isNaN(+agencyId)) {
      navigate("/");
    }
  }, []);

  return (
    <SubscribeEmailComponent
      {...props}
      subscribedEmail={subscribedEmail}
      email={email}
      setEmail={setEmail}
    />
  );
};

export default UnSubscribeEmailContainer;
