import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as commonData from "data/common.data";
import { getCurrentLangName } from "helpers/String.helper";

import LoadingComponent from "commons/loading/Loading.component";

const UpdateMyComponent = (props: any) => {
  const {
    id,
    updateType,
    schoolParams,
    contactParams,
    snsParams,
    isOnNetworking,
    imageInfo,
    setImageInfo,
    fileInfo,
    uploadImage,
    uploadFile,
    onChangeInput,
    onClickBtnCancelUpdate,
    onClickBtnUpdateInfo,
  } = props;

  const buttonComponent = () => {
    return (
      <div className="btn">
        <div className="cancel-btn">
          <button onClick={(evt) => onClickBtnCancelUpdate()}>
            {getLanguageValue(PAGE_TYPE.SCHOOL, "my.btnCancel")}
          </button>
        </div>
        <div className="update-btn">
          <button
            onClick={(evt) => onClickBtnUpdateInfo()}
            disabled={isOnNetworking}>
            {getLanguageValue(PAGE_TYPE.SCHOOL, "my.btnUpdate")}
          </button>
        </div>
      </div>
    );
  };

  return (
    <main className="sns-update school-information-update">
      <div className="con">
        {updateType === "school" ? (
          <>
            <h2>SCHOOL INFORMATION UPDATE</h2>
            <div className="form">
              <div className="input-box">
                <label>
                  *{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtSchoolName")}
                </label>
                <input
                  type="text"
                  onChange={(evt) =>
                    onChangeInput("schoolName", evt.target.value)
                  }
                  value={schoolParams.schoolName}
                />
              </div>
              <div className="input-box">
                <label>
                  *{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtRepresentative")}
                </label>
                <input
                  type="text"
                  onChange={(evt) =>
                    onChangeInput("representative", evt.target.value)
                  }
                  value={schoolParams.representative}
                />
              </div>
              <div className="input-box">
                <h3 className="title-con">
                  *
                  {getLanguageValue(
                    PAGE_TYPE.SCHOOL,
                    "my.txtBusinessRegistration"
                  )}
                </h3>
                <div className="file-upload-btn">
                  <label className="file-btn" htmlFor="input-file">
                    {getLanguageValue(PAGE_TYPE.SCHOOL, "my.btnUploadFile")}
                  </label>
                  <input
                    className="file-upload"
                    id="input-file"
                    type="file"
                    onChange={(evt) => uploadFile(evt)}
                  />
                  <span className="text ellipsis">
                    <span
                      className="line"
                      style={{ cursor: "pointer" }}
                      onClick={(evt) => window.open(fileInfo?.url, "download")}>
                      {fileInfo?.name}
                    </span>
                  </span>
                </div>
              </div>
              <div className="input-box">
                <h3 className="title-con">
                  *{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtLogo")}
                </h3>
                <div className="img-upload">
                  <input
                    type="file"
                    id="input-img"
                    onChange={(evt) => uploadImage(evt)}
                    accept="image/*"
                  />
                  <label
                    htmlFor="input-img"
                    style={{
                      backgroundImage: `url("${
                        imageInfo?.url ||
                        "./../../imgs/dummy/border/border-bl.png"
                      }")`,
                      backgroundPosition: "center",
                    }}>
                    {imageInfo?.url ? (
                      <div
                        className="close-btn"
                        onClick={(evt: any) => {
                          evt.preventDefault();
                          setImageInfo({});
                        }}></div>
                    ) : (
                      <div
                        className="img-add"
                        style={{ cursor: "pointer" }}></div>
                    )}
                  </label>
                  <p className="size">size(150 x 150)</p>
                </div>
              </div>
              <div className="input-box">
                <label>
                  *{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtCountry")}
                </label>
                <select
                  value={schoolParams.countryCode}
                  onChange={(evt) =>
                    onChangeInput("countryCode", evt.target.value)
                  }>
                  {commonData
                    .getCountryCodeList()
                    .map((item: any, idx: number) => (
                      <option
                        key={idx}
                        value={item.code}
                        selected={schoolParams.countryCode === item.code}>
                        {getCurrentLangName(item.names)}
                      </option>
                    ))}
                </select>
              </div>
              <div className="input-box">
                <label>
                  *{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtCity")}
                </label>
                <input
                  type="text"
                  onChange={(evt) => onChangeInput("city", evt.target.value)}
                  value={schoolParams.city}
                />
              </div>
              <div className="input-box">
                <label>
                  *{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtAddress1")}
                </label>
                <input
                  type="text"
                  onChange={(evt) =>
                    onChangeInput("address1", evt.target.value)
                  }
                  value={schoolParams.address1}
                />
              </div>
              <div className="input-box">
                <label>
                  *{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtAddress2")}
                </label>
                <input
                  type="text"
                  onChange={(evt) =>
                    onChangeInput("address2", evt.target.value)
                  }
                  value={schoolParams.address2}
                />
              </div>
              <div className="input-box">
                <label>
                  *{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtZipcode")}
                </label>
                <input
                  type="text"
                  onChange={(evt) => onChangeInput("zipcode", evt.target.value)}
                  value={schoolParams.zipcode}
                />
              </div>
              <div className="input-box">
                <label>
                  *{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtTelephone")}
                </label>
                <input
                  type="text"
                  onChange={(evt) =>
                    onChangeInput("telephone", evt.target.value)
                  }
                  value={schoolParams.telephone}
                />
              </div>
              <div className="input-box">
                <label>
                  *{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtHomepageUrl")}
                </label>
                <input
                  type="text"
                  onChange={(evt) =>
                    onChangeInput("homepageUrl", evt.target.value)
                  }
                  value={schoolParams.homepageUrl}
                />
              </div>
            </div>
            {buttonComponent()}
          </>
        ) : updateType === "contact" ? (
          <>
            <h2>CONTACT UPDATE</h2>
            <div className="form">
              <>
                <div className="input-box">
                  <label>
                    *{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtEmail")}
                  </label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      onChangeInput("contactEmail", evt.target.value)
                    }
                    value={contactParams.contactEmail}
                  />
                </div>
                <div className="input-box">
                  <label>
                    *{getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtCellphone")}
                  </label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      onChangeInput("cellphone", evt.target.value)
                    }
                    value={contactParams.cellphone}
                  />
                </div>
              </>
            </div>
            {buttonComponent()}
          </>
        ) : updateType === "sns" ? (
          <>
            <h2>SNS INFORMATION UPDATE</h2>
            {id ? (
              <>
                <div className="form">
                  <div className="input-box sns-box">
                    <label>
                      {getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtInstagram")}
                    </label>
                    <div className="input-prefix">
                      <div className="prefix">
                        <p>instagram.com/</p>
                      </div>
                      <input
                        type="text"
                        value={snsParams.instagram}
                        onChange={(evt) =>
                          onChangeInput("instagram", evt.target.value)
                        }
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="input-box sns-box">
                    <label>
                      {getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtYoutube")}
                    </label>
                    <div className="input-prefix">
                      <div className="prefix">
                        <p>youtube.com/@</p>
                      </div>
                      <input
                        type="text"
                        value={snsParams.youtube}
                        onChange={(evt) =>
                          onChangeInput("youtube", evt.target.value)
                        }
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="input-box sns-box">
                    <label>
                      {getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtTwitter")}
                    </label>
                    <div className="input-prefix">
                      <div className="prefix">
                        <p>twitter.com/</p>
                      </div>
                      <input
                        type="text"
                        value={snsParams.twitter}
                        onChange={(evt) =>
                          onChangeInput("twitter", evt.target.value)
                        }
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="input-box sns-box">
                    <label>
                      {getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtFacebook")}
                    </label>
                    <div className="input-prefix">
                      <div className="prefix">
                        <p>facebook.com/</p>
                      </div>
                      <input
                        type="text"
                        value={snsParams.facebook}
                        onChange={(evt) =>
                          onChangeInput("facebook", evt.target.value)
                        }
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="input-box sns-box">
                    <label>
                      {getLanguageValue(PAGE_TYPE.SCHOOL, "my.txtTiktok")}
                    </label>
                    <div className="input-prefix">
                      <div className="prefix">
                        <p>tiktok.com/@</p>
                      </div>
                      <input
                        type="text"
                        value={snsParams.tiktok}
                        onChange={(evt) =>
                          onChangeInput("tiktok", evt.target.value)
                        }
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                {buttonComponent()}
              </>
            ) : (
              <LoadingComponent />
            )}
          </>
        ) : null}
      </div>
    </main>
  );
};

export default UpdateMyComponent;
