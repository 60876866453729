import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import * as AuthApi from "api/common/Auth.api";

import VerifyPasswordEmailComponent from "./VerifyPasswordEmail.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { checkPassword } from "helpers/Reg.helper";

const VerifyPasswordEmailContainer = (props: any) => {
  const navigate = useNavigate();
  const [isVerify, setIsVerify] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const btnRef = useRef<any>();
  const { token } = useParams();

  const verifyEmail = async () => {
    const result: any = await AuthApi.verifyResetPasswordEmail(token);
    if (result) {
      setIsVerify(result);
    } else {
      navigate("/");
    }
  };

  const isDisabled = async () => {
    if (!password) {
      alert(
        getLanguageValue(PAGE_TYPE.COMMON, "verifyPasswordEmail.errPassword")
      );
      return true;
    } else if (!checkPassword(password)) {
      alert(
        getLanguageValue(
          PAGE_TYPE.COMMON,
          "verifyPasswordEmail.errInvalidPassword"
        )
      );
      return true;
    } else if (!confirmedPassword) {
      alert(
        getLanguageValue(
          PAGE_TYPE.COMMON,
          "verifyPasswordEmail.errConfirmPassword"
        )
      );
      return true;
    } else if (password !== confirmedPassword) {
      alert(
        getLanguageValue(
          PAGE_TYPE.COMMON,
          "verifyPasswordEmail.errPasswordAndConfirmPasswordMatch"
        )
      );
      return true;
    } else {
      return false;
    }
  };

  const updatePassword = async () => {
    if (!(await isDisabled())) {
      const result = await AuthApi.verifyResetPassword({
        token,
        password,
        confirmedPassword,
      });

      if (result) {
        navigate("/verification/email/password/reset/done", {
          state: { result: true },
        });
      }
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (
    <VerifyPasswordEmailComponent
      isVerify={isVerify}
      password={password}
      btnRef={btnRef}
      setPassword={setPassword}
      confirmedPassword={confirmedPassword}
      setConfirmedPassword={setConfirmedPassword}
      onClickBtnUpdatePassword={updatePassword}
    />
  );
};

export default VerifyPasswordEmailContainer;
