import React, { useEffect, useState } from "react";
import StudentComponent from "./Student.component";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as StudentApi from "api/student/Student.api";
import { getCommonCodeListByParent } from "data/common.data";
import { makeRank } from "helpers/Score.helper";

const StudentContainer = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { info: myInfo } = useSelector((state: any) => state.user);
  const [studentList, setStudentList] = useState([]);

  const [initialRender, setInitialRender] = useState(true);
  const genderType = getCommonCodeListByParent("genderType");
  const [isEvaluationModalOpen, setIsEvaluationModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);

  // pagination
  const urlParams = new URLSearchParams(window.location.search);
  const pageNum = parseInt(urlParams.get("page")) || 1;
  const searchParam = urlParams.get("search") || "";
  const genderParam = urlParams.get("gender") || "";
  const verifiedParam = urlParams.get("verified") || true;
  const limit = 10;

  const [page, setPage] = useState(pageNum);
  const [searchTerm, setSearchTerm] = useState(searchParam);
  const [verifyFilter, setVerifyFilter] = useState(verifiedParam === "true");
  const [genderFilter, setGenderFilter] = useState(genderParam);
  const [totalCount, setTotalcount] = useState(0);
  const [isShowLoading, setIsShowLoading] = useState(true);

  // 팝업 창 열기
  const openEvaluationModal = () => {
    setIsEvaluationModalOpen(true);
  };

  // 팝업 창 닫기
  const closeEvaluationModal = async () => {
    getStudentList();
    setIsEvaluationModalOpen(false);
  };

  const getStudentList = async () => {
    setIsShowLoading(true);
    const result = await StudentApi.getListBySchool({
      schoolId: myInfo?.userSchoolId,
      keyword: searchTerm,
      genderTypeCode: genderFilter,
      isVerified: verifyFilter,
      limit: limit,
      page: page,
    });

    if (result?.list) {
      const studentListWithAverage = result.list.map((student: any) => {
        const evaluationScores = student.evaluation.map(
          (evaluation: any) => evaluation.evaluationScore
        );
        const evaluationTotal = evaluationScores.reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue,
          0
        );
        const evaluationAverageString: string =
          evaluationScores.length > 0
            ? (evaluationTotal / evaluationScores.length).toFixed(2)
            : "0";

        const evaluationAverage: number = parseFloat(evaluationAverageString);
        return {
          ...student,
          evaluation: makeRank(evaluationAverage),
        };
      });
      setStudentList(studentListWithAverage);
      setTotalcount(result.totalCount);
    } else {
      setStudentList([]);
      setTotalcount(0);
    }
    setIsShowLoading(false);
  };

  const handleEvaluation = (e: any, item: any) => {
    openEvaluationModal();
    setSelectedStudent(item);
  };

  const handlePortfolio = (e: any, item: any) => {};

  const handleCertification = (e: any) => {};

  const handleCertificationChange = async (item: any, isVerified: number) => {
    setStudentList((prevStudentList) => {
      const updatedList = prevStudentList.map((prev) =>
        prev.id === item.id
          ? { ...prev, isVerified: isVerified === 1 ? 1 : 0 }
          : prev
      );
      return updatedList;
    });

    await StudentApi.updateVerify(item.id, { isVerified });
  };

  const search = async () => {
    await getStudentList();
    setPage(1);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", "1");

    if (searchTerm) {
      searchParams.set("search", searchTerm);
    } else {
      searchParams.delete("search");
    }

    if (genderFilter) {
      searchParams.set("gender", genderFilter);
    } else {
      searchParams.delete("gender");
    }

    if (verifyFilter) {
      searchParams.set("verified", verifyFilter.toString());
    } else {
      searchParams.delete("verified");
    }
    navigate(`/school/student?${searchParams}`);
  };

  useEffect(() => {
    if (!initialRender) {
      search();
    } else {
      setInitialRender(false);
    }
  }, [verifyFilter]);

  useEffect(() => {
    getStudentList();
  }, [page]);

  return (
    <StudentComponent
      {...props}
      studentList={studentList}
      genderFilter={genderFilter}
      verifyFilter={verifyFilter}
      searchTerm={searchTerm}
      genderType={genderType}
      selectedStudent={selectedStudent}
      isEvaluationModalOpen={isEvaluationModalOpen}
      handleEvaluation={handleEvaluation}
      handlePortfolio={handlePortfolio}
      handleCertification={handleCertification}
      handleCertificationChange={handleCertificationChange}
      closeEvaluationModal={closeEvaluationModal}
      openEvaluationModal={openEvaluationModal}
      setSearchTerm={setSearchTerm}
      setGenderFilter={setGenderFilter}
      setVerifyFilter={setVerifyFilter}
      search={search}
      navigate={navigate}
      // pagination
      limit={limit}
      page={page}
      setPage={setPage}
      totalCount={totalCount}
      isShowLoading={isShowLoading}
    />
  );
};

export default StudentContainer;
