import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../../commons/pagination/Pagination.container";
import AuditionInfoPopupContainer from "./AuditionInfoPopup.container";
import AuditionMemoPopupContainer from "./AuditionMemoPopup.container";
import AuditionClosePopupContainer from "./AuditionClosePopup.container";
import LoadingComponent from "commons/loading/Loading.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { addDate, getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";
import LineChartComponent from "commons/chart/LineChart.component";
import BarChartComponent from "commons/chart/BarChart.component";

const AuditionCompleteComponent = (props: any) => {
  const navigate = useNavigate();
  const state = useLocation().state;
  const {
    isShowLoading,
    id,
    isLoading,
    auditionApplicantList,
    setAuditionApplicantList,
    auditionInfo,
    go,
    toggleUserLike,
    updateResultInfo,
    updateAuditionDoneInfo,
    btnRef,
    // search
    search,
    keywordFilter,
    setKeywordFilter,
    resultFilter,
    setResultFilter,
    isLikedFilter,
    setIsLikedFilter,
    toggleLike,
    // pagination
    limit,
    prev,
    page,
    setPage,
    totalCount,
    // popup
    isShowAuditionInfoPopup,
    setIsShowAuditionInfoPopup,
    isShowAuditionMemoPopup,
    setIsShowAuditionMemoPopup,
    isShowAuditionClosePopup,
    setIsShowAuditionClosePopup,
    memoItem,
    setMemoItem,
    // chart
    date,
    setDate,
    dailyAuditionApplicantCountByAgeInfo,
    dailyAuditionApplicantCountInfo,
    dailyAuditionViewCountInfo,
    barDataset,
    dataset,
    // message
    cancelRequest,
    createRequest,
  } = props;

  return (
    <main className="">
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <div className="audition-evaluation-completed">
            <div className="wrap audition-list-table">
              <div
                className="back-btn"
                onClick={(evt) => {
                  navigate("/agency/audition", { state: { page: prev } });
                }}></div>
              <div className="title">
                <h2>
                  {auditionInfo.title}
                  <img
                    src="./imgs/icons/info-icon.png"
                    alt="audition information"
                    onClick={() => {
                      setIsShowAuditionInfoPopup(true);
                    }}
                  />
                </h2>
              </div>
              <div className="period-status">
                <p>
                  {`${getFormattedDateValue(
                    auditionInfo.startDate,
                    "YYYY.MM.DD"
                  )} ~ ${getFormattedDateValue(
                    auditionInfo.endDate,
                    "YYYY.MM.DD"
                  )}`}
                  <span>
                    {getLanguageValue(
                      PAGE_TYPE.AGENCY,
                      "auditionComplete.txtStatus"
                    )}
                  </span>
                </p>
              </div>

              <div className="summary">
                <div className="summary-wrap">
                  <h2>SUMMARY</h2>
                  <p className="gender-color">
                    <span>
                      <img src="./imgs/icons/girl-icon.png" alt="girl" />
                      girl
                    </span>
                    <span>
                      <img src="./imgs/icons/boy-icon.png" alt="boy" />
                      boy
                    </span>
                  </p>
                  <div className="summary-check">
                    <div className="applicant">
                      <p>APPLICANT</p>
                      <div>
                        <p className="total">
                          {auditionInfo.countInfo.applicant?.girl +
                            auditionInfo.countInfo.applicant?.boy}
                        </p>
                        <p className="total-detail">
                          (
                          <span className="girl">
                            {auditionInfo.countInfo.applicant?.girl}
                          </span>
                          /
                          <span className="boy">
                            {auditionInfo.countInfo.applicant?.boy}
                          </span>
                          )
                        </p>
                      </div>
                    </div>
                    <div className="favorite">
                      <p>FAVORITE</p>
                      <div>
                        <p className="total">
                          {auditionInfo.countInfo.favorite?.girl +
                            auditionInfo.countInfo.favorite?.boy}
                        </p>
                        <p className="total-detail">
                          (
                          <span className="girl">
                            {auditionInfo.countInfo.favorite?.girl}
                          </span>
                          /
                          <span className="boy">
                            {auditionInfo.countInfo.favorite?.boy}
                          </span>
                          )
                        </p>
                      </div>
                    </div>
                    <div className="view">
                      <p>VIEW</p>
                      <div>
                        <p className="total">
                          {auditionInfo.countInfo.view?.girl +
                            auditionInfo.countInfo.view?.boy}
                        </p>
                        <p className="total-detail">
                          (
                          <span className="girl">
                            {auditionInfo.countInfo.view?.girl}
                          </span>
                          /
                          <span className="boy">
                            {auditionInfo.countInfo.view?.boy}
                          </span>
                          )
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="statistics">
                <h2>STATISTICS</h2>
                <ul>
                  <li>
                    <div className="evaluation-graph">
                      <div className="header">
                        <div className="title">
                          <p>
                            {getLanguageValue(
                              PAGE_TYPE.AGENCY,
                              "auditionComplete.txtNumberofApplicantsByAge"
                            )}
                          </p>
                        </div>
                        <div className="gender-color">
                          <span>
                            <img src="./imgs/icons/girl-icon.png" alt="girl" />
                            girl
                          </span>
                          <span>
                            <img src="./imgs/icons/boy-icon.png" alt="boy" />
                            boy
                          </span>
                        </div>
                      </div>
                      {dailyAuditionApplicantCountByAgeInfo?.isLoading ? (
                        <LoadingComponent />
                      ) : (
                        <div className="body">
                          <BarChartComponent
                            labels={dailyAuditionApplicantCountByAgeInfo.labels}
                            datasets={[
                              barDataset(
                                dailyAuditionApplicantCountByAgeInfo.data.girl,
                                "#CF3688",
                                "#CF3688",
                                "Girl"
                              ),
                              barDataset(
                                dailyAuditionApplicantCountByAgeInfo.data.boy,
                                "#36A2EB",
                                "#36A2EB",
                                "Boy"
                              ),
                            ]}
                            min={0}
                            max={dailyAuditionApplicantCountByAgeInfo.max}
                            stepSize={
                              dailyAuditionApplicantCountByAgeInfo.max / 4
                            }
                          />
                        </div>
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="evaluation-graph">
                      <div className="header">
                        <div className="title">
                          <p>
                            {getLanguageValue(
                              PAGE_TYPE.AGENCY,
                              "auditionComplete.txtNumberofApplicantsByDate"
                            )}
                          </p>
                        </div>
                        <div className="control">
                          <div
                            className="btn-prev"
                            onClick={(evt) => {
                              setDate({
                                ...date,
                                applicant: addDate(
                                  -6,
                                  date.applicant,
                                  "YYYY-MM-DD"
                                ),
                              });
                            }}>
                            ◀
                          </div>
                          <div className="text">{`${addDate(
                            -6,
                            date.applicant,
                            "YYYY.MM.DD"
                          )} ~ ${getFormattedDateValue(
                            date.applicant,
                            "YYYY.MM.DD"
                          )}`}</div>
                          <div
                            className="btn-next"
                            onClick={(evt) => {
                              setDate({
                                ...date,
                                applicant: addDate(
                                  6,
                                  date.applicant,
                                  "YYYY-MM-DD"
                                ),
                              });
                            }}>
                            ▶
                          </div>
                        </div>
                      </div>
                      {dailyAuditionApplicantCountInfo?.isLoading ? (
                        <LoadingComponent />
                      ) : (
                        <div className="body">
                          <LineChartComponent
                            labels={dailyAuditionApplicantCountInfo.labels}
                            datasets={[
                              dataset(
                                dailyAuditionApplicantCountInfo.data.girl,
                                "#F77676",
                                "#F77676"
                              ),
                              dataset(
                                dailyAuditionApplicantCountInfo.data.boy,
                                "#3C91DF",
                                "#3C91DF"
                              ),
                            ]}
                            min={0}
                            max={dailyAuditionApplicantCountInfo.max}
                            stepSize={dailyAuditionApplicantCountInfo.max / 4}
                          />
                        </div>
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="evaluation-graph">
                      <div className="header">
                        <div className="title">
                          <p>
                            {getLanguageValue(
                              PAGE_TYPE.AGENCY,
                              "auditionComplete.txtNumberofViewCountsByDate"
                            )}
                          </p>
                        </div>
                        <div className="control">
                          <div
                            className="btn-prev"
                            onClick={(evt) => {
                              setDate({
                                ...date,
                                view: addDate(-6, date.view, "YYYY-MM-DD"),
                              });
                            }}>
                            ◀
                          </div>
                          <div className="text">{`${addDate(
                            -6,
                            date.view,
                            "YYYY.MM.DD"
                          )} ~ ${getFormattedDateValue(
                            date.view,
                            "YYYY.MM.DD"
                          )}`}</div>
                          <div
                            className="btn-next"
                            onClick={(evt) => {
                              setDate({
                                ...date,
                                view: addDate(6, date.view, "YYYY-MM-DD"),
                              });
                            }}>
                            ▶
                          </div>
                        </div>
                      </div>
                      {dailyAuditionViewCountInfo?.isLoading ? (
                        <LoadingComponent />
                      ) : (
                        <div className="body">
                          <LineChartComponent
                            labels={dailyAuditionViewCountInfo.labels}
                            datasets={[
                              dataset(
                                dailyAuditionViewCountInfo.data.girl,
                                "#F77676",
                                "#F77676"
                              ),
                              dataset(
                                dailyAuditionViewCountInfo.data.boy,
                                "#3C91DF",
                                "#3C91DF"
                              ),
                            ]}
                            min={0}
                            max={dailyAuditionViewCountInfo.max}
                            stepSize={dailyAuditionViewCountInfo.max / 4}
                          />
                        </div>
                      )}
                    </div>
                  </li>
                </ul>
              </div>

              <div className="search-wrap">
                <div className="center">
                  {isLikedFilter ? (
                    <img
                      src="./imgs/icons/like.png"
                      alt="like"
                      onClick={() => toggleLike()}
                    />
                  ) : (
                    <img
                      src="./imgs/icons/like-toggle.png"
                      alt="like"
                      onClick={() => toggleLike()}
                    />
                  )}
                  <div className="col col-status">
                    <div className="select-list">
                      <select onChange={(e) => setResultFilter(e.target.value)}>
                        <option value="">
                          {getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionComplete.txtFilterSelectAll"
                          )}
                        </option>
                        {/* <option value="unrated">
                        {getLanguageValue(
                          PAGE_TYPE.AGENCY,
                          "auditionComplete.txtFilterSelectUnrated"
                        )}
                      </option>
                      <option value="hold">
                        {getLanguageValue(
                          PAGE_TYPE.AGENCY,
                          "auditionComplete.txtFilterSelectHold"
                        )}
                      </option> */}
                        <option value="bad">
                          {getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionComplete.txtFilterSelectBad"
                          )}
                        </option>
                        <option value="good">
                          {getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionComplete.txtFilterSelectGood"
                          )}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col col-input">
                    <div className="input-wrap">
                      <input
                        type="text"
                        placeholder="Searching Keyword"
                        value={keywordFilter}
                        onChange={(e) => {
                          setKeywordFilter(e.target.value);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            search();
                          }
                        }}
                      />
                      <div className="btn-search-icon" onClick={() => search()}>
                        <img
                          src="./imgs/icons/icon-search.png"
                          alt={getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionComplete.txtSearch"
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {auditionApplicantList && auditionApplicantList.length === 0 ? (
                <>
                  <div className="no-data">
                    <p>No data</p>
                  </div>
                  <div className="table">
                    {isShowAuditionInfoPopup ? (
                      <AuditionInfoPopupContainer
                        auditionInfo={auditionInfo}
                        setIsShowAuditionInfoPopup={setIsShowAuditionInfoPopup}
                      />
                    ) : null}

                    {/* <!-- 오디션 메모 팝업 --> */}
                    {isShowAuditionMemoPopup ? (
                      <AuditionMemoPopupContainer
                        memoItem={memoItem}
                        auditionApplicantList={auditionApplicantList}
                        setAuditionApplicantList={setAuditionApplicantList}
                        setIsShowAuditionMemoPopup={setIsShowAuditionMemoPopup}
                      />
                    ) : null}

                    {/* <!-- 오디션 종료 팝업 --> */}
                    {isShowAuditionClosePopup ? (
                      <AuditionClosePopupContainer
                        auditionInfo={auditionInfo}
                        setIsShowAuditionClosePopup={
                          setIsShowAuditionClosePopup
                        }
                      />
                    ) : null}
                  </div>
                </>
              ) : (
                <div className="table">
                  <table>
                    <thead>
                      <tr>
                        <td className="no">
                          <p>
                            {getLanguageValue(
                              PAGE_TYPE.AGENCY,
                              "auditionComplete.txtTableNo"
                            )}
                          </p>
                        </td>
                        <td className="photo">
                          <p>
                            {getLanguageValue(
                              PAGE_TYPE.AGENCY,
                              "auditionComplete.txtTablePhoto"
                            )}
                          </p>
                        </td>
                        <td className="name">
                          <p>
                            {getLanguageValue(
                              PAGE_TYPE.AGENCY,
                              "auditionComplete.txtTableName"
                            )}
                          </p>
                        </td>
                        <td className="gender">
                          <p>
                            {getLanguageValue(
                              PAGE_TYPE.AGENCY,
                              "auditionComplete.txtTableGender"
                            )}
                          </p>
                        </td>
                        <td className="nationality">
                          <p>
                            {getLanguageValue(
                              PAGE_TYPE.AGENCY,
                              "auditionComplete.txtTableNationality"
                            )}
                          </p>
                        </td>
                        <td className="school-name">
                          <p>
                            {getLanguageValue(
                              PAGE_TYPE.AGENCY,
                              "auditionComplete.txtTableSchoolName"
                            )}
                          </p>
                        </td>
                        <td className="birth-year">
                          <p>
                            {getLanguageValue(
                              PAGE_TYPE.AGENCY,
                              "auditionComplete.txtTableBirthYear"
                            )}
                          </p>
                        </td>
                        {/* <td className="memo">
                        <p>
                          {getLanguageValue(
                            PAGE_TYPE.AGENCY,
                            "auditionComplete.txtTableMemo"
                          )}
                        </p>
                      </td> */}
                        <td
                          className="evaluation"
                          style={{ display: "table-cell" }}>
                          <p>
                            {getLanguageValue(
                              PAGE_TYPE.AGENCY,
                              "auditionComplete.txtEvaluation"
                            )}
                          </p>
                        </td>
                        <td className="like">
                          <p>
                            {getLanguageValue(
                              PAGE_TYPE.AGENCY,
                              "auditionComplete.txtTableLike"
                            )}
                          </p>
                        </td>
                        <td className="chatting">
                          <p>Chatting</p>
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      {auditionApplicantList.length > 0
                        ? auditionApplicantList.map(
                            (item: any, idx: number) => (
                              <tr>
                                <td className="no">
                                  <p>{totalCount - (page - 1) * limit - idx}</p>
                                </td>
                                <td className="photo">
                                  <div
                                    className="img"
                                    style={{
                                      backgroundImage: `url(${
                                        item.profileImageInfo
                                          ? item.profileImageInfo.url.replace(
                                              / /g,
                                              "%20"
                                            )
                                          : "./imgs/dummy/basic-img-2.png"
                                      })`,
                                    }}></div>
                                </td>
                                <td className="name">
                                  <a
                                    className="btn-link"
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      navigate(
                                        `/agency/audition/applicant/${item.id}`,
                                        {
                                          state: {
                                            ...state,
                                            status: auditionInfo.status,
                                          },
                                        }
                                      );
                                    }}>
                                    {`${item.firstName} ${item.lastName}`}
                                  </a>
                                </td>
                                <td className="gender">
                                  <p>
                                    {getCurrentLangName(
                                      item.genderTypeCodeInfo.names
                                    )}
                                  </p>
                                </td>
                                <td className="nationality">
                                  <p>
                                    {getCurrentLangName(
                                      item.countryCodeInfo.names
                                    )}
                                  </p>
                                </td>
                                <td className="school-name">
                                  <p>
                                    {item.schoolName ? item.schoolName : "-"}
                                  </p>
                                </td>
                                <td className="birth-year">
                                  <p>
                                    {item.birthday
                                      ? getFormattedDateValue(
                                          item.birthday,
                                          "YYYY.MM.DD"
                                        )
                                      : "-"}
                                  </p>
                                </td>
                                {/* <td className="memo">
                              <p
                                onClick={() => {
                                  setMemoItem(item);
                                  setIsShowAuditionMemoPopup(true);
                                }}>
                                {item.memo === ""
                                  ? getLanguageValue(
                                      PAGE_TYPE.AGENCY,
                                      "auditionComplete.txtWrtie"
                                    )
                                  : getLanguageValue(
                                      PAGE_TYPE.AGENCY,
                                      "auditionComplete.txtShow"
                                    )}
                              </p>
                            </td> */}
                                <td
                                  className="evaluation"
                                  style={{ display: "table-cell" }}>
                                  <p>
                                    {item.result
                                      ? item.result.toUpperCase()
                                      : ""}
                                  </p>
                                </td>
                                <td className="like">
                                  <div className="btn-like">
                                    <button style={{ cursor: "default" }}>
                                      {item.isLiked === true ? (
                                        <img
                                          src="./imgs/icons/like.png"
                                          alt="like"
                                        />
                                      ) : (
                                        <img
                                          src="./imgs/icons/like-toggle.png"
                                          alt="like"
                                        />
                                      )}
                                    </button>
                                  </div>
                                </td>
                                <td className="chatting">
                                  {item ? (
                                    item.mssageRequestInfo
                                      ?.messageStatusCode ===
                                    "messageType:accept" ? (
                                      <button
                                        className="talk"
                                        onClick={() => {
                                          navigate(
                                            `/agency/message/${item.mssageRequestInfo?.channelId}`,
                                            {
                                              state:
                                                item.schoolName !== null
                                                  ? {
                                                      schoolName:
                                                        item.schoolName,
                                                    }
                                                  : {
                                                      studentName:
                                                        item.firstName +
                                                        " " +
                                                        item.lastName,
                                                    },
                                            }
                                          );
                                        }}>
                                        {getLanguageValue(
                                          PAGE_TYPE.AGENCY,
                                          "studentInfo.btnGoToMessagePage"
                                        )}
                                      </button>
                                    ) : item.mssageRequestInfo
                                        ?.messageStatusCode ===
                                      "messageType:request" ? (
                                      <button
                                        className="cancel"
                                        onClick={(e) => {
                                          cancelRequest(
                                            item.userStudentId,
                                            item.mssageRequestInfo?.id
                                          );
                                        }}>
                                        {getLanguageValue(
                                          PAGE_TYPE.AGENCY,
                                          "studentInfo.btnCancelRequest"
                                        )}
                                      </button>
                                    ) : (
                                      <button
                                        className={`${item.userStudentId}`}
                                        onClick={(e) => {
                                          createRequest(item.userStudentId);
                                        }}>
                                        {getLanguageValue(
                                          PAGE_TYPE.AGENCY,
                                          "studentInfo.btnRequestMessage"
                                        )}{" "}
                                      </button>
                                    )
                                  ) : (
                                    <button
                                      onClick={(e) => {
                                        createRequest(item.userStudentId);
                                      }}>
                                      {getLanguageValue(
                                        PAGE_TYPE.AGENCY,
                                        "studentInfo.btnRequestMessage"
                                      )}{" "}
                                    </button>
                                  )}
                                </td>
                              </tr>
                            )
                          )
                        : null}
                    </tbody>
                  </table>
                  {isShowAuditionInfoPopup ? (
                    <AuditionInfoPopupContainer
                      auditionInfo={auditionInfo}
                      setIsShowAuditionInfoPopup={setIsShowAuditionInfoPopup}
                    />
                  ) : null}

                  {/* <!-- 오디션 메모 팝업 --> */}
                  {isShowAuditionMemoPopup ? (
                    <AuditionMemoPopupContainer
                      memoItem={memoItem}
                      auditionApplicantList={auditionApplicantList}
                      setAuditionApplicantList={setAuditionApplicantList}
                      setIsShowAuditionMemoPopup={setIsShowAuditionMemoPopup}
                    />
                  ) : null}

                  {/* <!-- 오디션 종료 팝업 --> */}
                  {isShowAuditionClosePopup ? (
                    <AuditionClosePopupContainer
                      auditionInfo={auditionInfo}
                      setIsShowAuditionClosePopup={setIsShowAuditionClosePopup}
                    />
                  ) : null}
                </div>
              )}
            </div>
          </div>
          <Pagination
            url={`/agency/audition/${id}`}
            totalCount={totalCount}
            prevPage={prev}
            currentPage={page}
            pageSize={limit}
            pageGroupSize={10}
            setPage={setPage}
          />
        </>
      )}
    </main>
  );
};
export default AuditionCompleteComponent;
