import React from "react";
import LineChartComponent from "commons/chart/LineChart.component";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { getCurrentLangName } from "helpers/String.helper";
import LoadingComponent from "commons/loading/Loading.component";

const StudentEvaluationGraphComponent = (props: any) => {
  const {
    evaluationCategory,
    isDisabled,
    evaluationInfo,
    setEvaluationInfo,
    setSelectedCategory,
    isFlag,
    setIsFlag,
    isShowLoading,
  } = props;

  return isShowLoading ? (
    <LoadingComponent />
  ) : (
    <ul>
      {evaluationCategory.map((category: any) => {
        if (evaluationInfo[category.code].round !== "") {
          return (
            <li key={category.code}>
              <div className="evaluation-graph">
                <div className="header">
                  <div className="title">
                    <p>
                      {getLanguageValue(
                        PAGE_TYPE.SCHOOL,
                        "studentDetail.txtByRounds"
                      )}{" "}
                      {getCurrentLangName(category.names)}{" "}
                      {getLanguageValue(
                        PAGE_TYPE.SCHOOL,
                        "studentDetail.txtEvaluation"
                      )}
                    </p>
                  </div>
                  <div className="control">
                    <div
                      className="btn-prev"
                      onClick={(evt) => {
                        if (!isDisabled) {
                          setEvaluationInfo((prevEvaluationInfo: any) => ({
                            ...prevEvaluationInfo,
                            [category.code]: {
                              ...prevEvaluationInfo[category.code],
                              offset:
                                prevEvaluationInfo[category.code].offset + 1,
                            },
                          }));
                          setSelectedCategory(category.code);
                          setIsFlag(!isFlag);
                        }
                      }}
                      style={{
                        visibility: evaluationInfo[category.code].hasPrev
                          ? "visible"
                          : "hidden",
                      }}>
                      ◀
                    </div>
                    <div className="text">
                      {evaluationInfo[category.code].round}
                    </div>
                    <div
                      className="btn-next"
                      onClick={(evt) => {
                        if (!isDisabled) {
                          setEvaluationInfo((prevEvaluationInfo: any) => ({
                            ...prevEvaluationInfo,
                            [category.code]: {
                              ...prevEvaluationInfo[category.code],
                              offset:
                                prevEvaluationInfo[category.code].offset - 1,
                            },
                          }));
                          setSelectedCategory(category.code);
                          setIsFlag(!isFlag);
                        }
                      }}
                      style={{
                        visibility: evaluationInfo[category.code].hasNext
                          ? "visible"
                          : "hidden",
                      }}>
                      ▶
                    </div>
                  </div>
                </div>
                <div className="body">
                  <LineChartComponent
                    labels={evaluationInfo[category.code].labels}
                    datasets={[
                      {
                        data: evaluationInfo[category.code].data,
                        borderColor: "#CF3688",
                        pointBackgroundColor: "#CF3688",
                        backgroundColor: "rgba(255, 99, 132, 0.5)",
                        pointRadius: 2.5,
                        clip: {
                          top: false,
                          bottom: false,
                          right: false,
                          left: false,
                        },
                      },
                    ]}
                    type={"evaluation"}
                    min={5}
                    max={10}
                  />
                </div>
              </div>
            </li>
          );
        } else {
          return null; // 데이터가 없는 카테고리는 렌더링하지 않음
        }
      })}
    </ul>
  );
};

export default StudentEvaluationGraphComponent;
