import React, { useEffect, useState } from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import { getFormattedDateValue, addDate, formatTimeAgo } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";

import LoadingComponent from "commons/loading/Loading.component";
import { createFollow, deleteFollow, getFollowInfo, getPostLikeList } from "api/Post.api";
import { getInfo } from "api/bbs/Notice.api";
import { getIn, setIn } from "immutable";
import { diffLinesRaw } from "jest-diff";

const FollowPopupComponent = (props: any) => {
    const { isShowFollowPopup, setIsShowFollowPopup, selectedWannabeId, setSelectedWannabeId } = props;

    const [info, setInfo] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isOnNetworking, setIsOnNetworking] = useState(false);

    const getInfo = async () => {
        const result = await getFollowInfo(selectedWannabeId, { accountTypeCode: "accountType:student" });

        if (result?.info) {
            setInfo(result.info);
        }

        setIsLoading(false);
    };

    const createFollowValue = async (followedUserId: string) => {
        setIsOnNetworking(true);
        const result: any = await createFollow({ followedUserId });
        if (result?.status === 200) {
            getInfo()
        }
        setIsOnNetworking(false);
    };

    const deleteFollowValue = async (followedUserId: string) => {
        setIsOnNetworking(true);
        const result: any = await deleteFollow({ followedUserId });
        if (result?.status === 200) {
            getInfo()
        }
        setIsOnNetworking(false);
    };

    useEffect(() => {
        if (!isShowFollowPopup) {
            setInfo(null);
            setSelectedWannabeId(0);
        }
    }, [isShowFollowPopup]);

    useEffect(() => {
        if (selectedWannabeId) {
            getInfo();
        }
    }, [selectedWannabeId]);

    return (
        <div className="v2-popup v2-popup-follow" style={{ display: isShowFollowPopup ? "block" : "none" }}>
            <div className="wrap">
                <div className="con">
                    <div className="header">
                        <div className="title">
                            <h6>
                                <strong>지망생</strong> 정보
                            </h6>
                        </div>
                        <div className="btn-close-popup" onClick={(e: any) => setIsShowFollowPopup(false)}>
                            <img src="./imgs/common/icon-close-popup.png" alt="close popup button" />
                        </div>
                    </div>
                    <div className="body">
                        {isLoading || !info ? (
                            <LoadingComponent></LoadingComponent>
                        ) : (
                            <>
                                <div className="v2-wannabe-info">
                                    <div className="v2-wannabe-thumbnail">
                                        <img src={info?.profileImageUrl}></img>
                                    </div>
                                    <div className="info-text">
                                        <p className="country">{getCurrentLangName(info?.countryCodeInfo?.names)}</p>
                                        <p className="name">
                                            {info?.name}({getCurrentLangName(info?.genderTypeCodeInfo?.names)})
                                        </p>
                                        <p className="year">{info?.birthYear}</p>
                                    </div>
                                    <div className="specialty">
                                        {info?.specialtyCodeInfoList?.length > 0
                                            ? info?.specialtyCodeInfoList.map((item: any, index: number) => (
                                                  <div className="specialty-tip" key={`sp-${index}`}>
                                                      {getCurrentLangName(item?.names)}
                                                  </div>
                                              ))
                                            : null}
                                    </div>

                                    {/* <ul>
                                    {list.map((item: any, number) => (
                                        <li key={`post-like-${number}`}>
                                            <div className="item">
                                                <div className="v2-wannabe-follow-item">
                                                    <div className="con">
                                                        <div className="col col-profile">
                                                            <div className="profile">
                                                                <div className="con">
                                                                    <div className="col-thumbnail">
                                                                        <div className="v2-wannabe-thumbnail">
                                                                            <img src={item.imageUrl} alt={item.name} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-info">
                                                                        <div className="name">
                                                                            <p>{item.isOwner ? "나" : item.name}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {!item.isOwner ? (
                                                            <>
                                                                {item.relationship === 0 ? (
                                                                    <div className="col col-control">
                                                                        <button
                                                                            className="v2-btn-toggle-t1 on"
                                                                            disabled={isOnNetworking}
                                                                            onClick={(e) => {
                                                                                createFollowValue(item.creatorUserId);
                                                                            }}>
                                                                            팔로우
                                                                        </button>
                                                                    </div>
                                                                ) : item.relationship === 1 ? (
                                                                    <div className="col col-control">
                                                                        <button
                                                                            className="v2-btn-toggle-t1 on"
                                                                            disabled={isOnNetworking}
                                                                            onClick={(e) => {
                                                                                createFollowValue(item.creatorUserId);
                                                                            }}>
                                                                            맞 팔로우
                                                                        </button>
                                                                    </div>
                                                                ) : item.relationship === 2 || item.relationship === 3 ? (
                                                                    <div className="col col-control">
                                                                        <button
                                                                            disabled={isOnNetworking}
                                                                            className="v2-btn-toggle-t1"
                                                                            onClick={(e) => {
                                                                                deleteFollowValue(item.creatorUserId);
                                                                            }}>
                                                                            팔로우 취소
                                                                        </button>
                                                                    </div>
                                                                ) : null}
                                                            </>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul> */}
                                </div>
                                <div className="btn">
                                    {info?.relationship === 0 ? (
                                        <button
                                            className="v2-btn-toggle-t1 on"
                                            disabled={isOnNetworking}
                                            onClick={(e) => {
                                                createFollowValue(info?.userId);
                                            }}>
                                            팔로우
                                        </button>
                                    ) : info?.relationship === 1 ? (
                                        <button
                                            className="v2-btn-toggle-t1 on"
                                            disabled={isOnNetworking}
                                            onClick={(e) => {
                                                createFollowValue(info?.userId);
                                            }}>
                                            맞 팔로우
                                        </button>
                                    ) : info?.relationship === 2 || info?.relationship === 3 ? (
                                        <button
                                            className="v2-btn-toggle-t1"
                                            disabled={isOnNetworking}
                                            onClick={(e) => {
                                                deleteFollowValue(info?.userId);
                                            }}>
                                            팔로우 취소
                                        </button>
                                    ) : null}
                                </div>
                            </>
                        )}
                    </div>
                    <div className="footer"></div>
                </div>
            </div>
        </div>
    );
};

export default FollowPopupComponent;
