import React, { useEffect } from "react";
import Pagination from "../../../commons/pagination/Pagination.container";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";
import { useLocation, useNavigate } from "react-router-dom";
import { getCommonCodeListByParent } from "data/common.data";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const AuditionClosePopupComponent = (props: any) => {
  const {
    auditionEarlyCloseReasonList,
    setIsShowAuditionClosePopup,
    selectedReason,
    setSelectedReason,
    etcReason,
    setEtcReason,
    btnRef,
    onClickBtnClose,
  } = props;

  return (
    <div className="popup popup-audition-close">
      <div className="con">
        <div className="audition-close">
          <div
            className="close-btn"
            onClick={() => {
              setIsShowAuditionClosePopup(false);
            }}></div>
          <h2>
            {getLanguageValue(
              PAGE_TYPE.AGENCY,
              "auditionClosePopup.txtAuditionClose"
            )}
          </h2>
          <div className="form">
            {auditionEarlyCloseReasonList.map((reason: any, index: number) => (
              <div className="input-box" key={index}>
                <label>
                  <input
                    type="radio"
                    value={reason.code}
                    name="report-reason"
                    onChange={(e) => {
                      setEtcReason("");
                      setSelectedReason(e.target.value);
                    }}
                    checked={selectedReason === reason.code}
                  />
                  {getCurrentLangName(reason.names)}
                </label>
              </div>
            ))}
            <textarea
              placeholder={getLanguageValue(
                PAGE_TYPE.AGENCY,
                "auditionClosePopup.txtReasonPlaceholder"
              )}
              value={etcReason}
              onChange={(e) => setEtcReason(e.target.value)}
              disabled={
                selectedReason !== "auditionEarlyCloseReason:etc"
              }></textarea>
          </div>

          <div className="btn">
            <div>
              <button
                className="cancel-btn"
                onClick={() => {
                  setIsShowAuditionClosePopup(false);
                }}>
                {getLanguageValue(
                  PAGE_TYPE.AGENCY,
                  "auditionClosePopup.txtCancel"
                )}
              </button>
            </div>
            <div>
              <button
                className=""
                ref={btnRef}
                onClick={() => {
                  onClickBtnClose();
                }}>
                {getLanguageValue(
                  PAGE_TYPE.AGENCY,
                  "auditionClosePopup.txtClose"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AuditionClosePopupComponent;
