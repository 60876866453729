import React, { useMemo } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import { IBaseResponse } from "interfaces/Api.interface";
import { IChartProps } from "interfaces/Chart.interface";

import datatype from "prop-types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChartComponent = (props: any) => {
  const { labels, datasets, title, titlePosition, type, min, max, stepSize } =
    props as IChartProps;

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: titlePosition,
        display: false,
      },
      title: {
        display: title ? true : false,
        text: title,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        ticks: { font: { size: 13 } },
        grid: {
          display: false,
        },
        border: { display: false },
      },
      y: {
        // offset: true,
        min,
        max,
        ticks: {
          callback: (value: any) => {
            if (type === "evaluation") {
              // y값을 평가(S, A, B...)로 변경
              if (value === 10) {
                return "S";
              } else if (value === 9) {
                return "A";
              } else if (value === 8) {
                return "B";
              } else if (value === 7) {
                return "C";
              } else if (value === 6) {
                return "D";
              } else if (value === 5) {
                return "F";
              }
            } else {
              return Math.round(value);
            }
          },
          stepSize,
        },
        grid: {
          display: true,
          ticks: {
            stepSize: 1,
          },
        },
        border: { display: false },
      },
    },
    elements: {
      line: { borderWidth: 1.7 },
    },
  };

  const data = useMemo(
    () => ({
      labels,
      datasets,
    }),
    [labels]
  );

  return (
    <div>
      <Line options={options} data={data} style={{ height: "260px" }} />
    </div>
  );
};
LineChartComponent.prototype = {
  labels: datatype.array.isRequired,
  datasets: datatype.any.isRequired,
  title: datatype.string,
  titlePosition: datatype.string.isRequired,
};

export default LineChartComponent;
