import * as BaseApi from "../Base.api";

export const getList = async (params: any) => {
  const url = "/notification";
  return await BaseApi.get(url, params);
};

export const updateReadInfo = async (id: number, params: any) => {
  const url = `/notification/${id}`;
  return await BaseApi.patch(url, params);
};

export const updateAllReadInfo = async (params: any) => {
  const url = `/notification`;
  return await BaseApi.patch(url, params);
};

export const createPortfolioNotificationInfo = async (params: any) => {
  const url = `/notification/check-portfolio`;
  return await BaseApi.post(url, params);
};
