// 공통 Helper

// 계정의 accountTypeCode으로 User Type 리턴
export const getUserType = (accountTypeCode: string) => {
  if (!accountTypeCode) {
    return "";
  }

  return accountTypeCode.split(":").pop();
};

const contentClassName: any = {
  about: "",
  partner: "content-partner",
  mission: "",
  pricing: "",
  contact: "content-contact",
};

// common layout에 정의된 element의 classname을 path별로 가져오는 공통 로직
export const getLayoutClassNameByPath = (pathName: string) => {
  const pathNameList = pathName.split("/");

  const firstPath = pathNameList[1];

  // common home
  if (firstPath === "") {
    return "content-home";
  }

  // agency
  if (firstPath === "agency") {
    return "content-home content-agency content-message";
  }

  if (firstPath === "school") {
    return "content-home content-school content-message content-student-detail";
  }

  if (firstPath === "student") {
    return "content-home content-student content-message";
  }

  try {
    if (pathNameList.length === 2) {
      return contentClassName[firstPath];
    }
  } catch {
    return "";
  }

  return "";
};
