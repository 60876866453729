import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import * as PortfolioApi from "api/school/Portfolio.api";

import PortfolioComponent from "./Portfolio.component";

const PortfolioContainer = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [portfolioList, setPortfolioList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  // search keyword
  const searchParams = new URLSearchParams(location.search);
  const [keyword, setKeyword] = useState(
    location?.state?.keyword || searchParams.get("searchKeyword") || ""
  );

  // pagination
  const limit = 8;
  const [page, setPage] = useState(
    location.state?.page || +searchParams.get("page") || 1
  );
  const [totalCount, setTotalcount] = useState(0);

  // popup
  const [isShowTaggedStudentPopup, setIsShowTaggedStudentPopup] =
    useState(false);
  const [taggedStudentPopupInfo, setTaggedStudentPopupInfo] = useState({});

  const getList = async () => {
    setIsLoading(true);
    const keyword = searchParams.get("searchKeyword") || "";
    const params = { keyword, limit, page };
    const result = await PortfolioApi.getList(params);

    if (result?.list) {
      setPortfolioList(result.list);
      setTotalcount(result.totalCount);
    }
    setIsLoading(false);
  };

  const setQueryString = () => {
    searchParams.set("page", location.state?.page ? page : "1");

    if (keyword) {
      searchParams.set("searchKeyword", keyword);
    } else {
      searchParams.delete("searchKeyword");
    }

    navigate(`/school/portfolio?${searchParams}`);
  };

  const searchKeyword = () => {
    setQueryString();
    setPage(1);
    getList();
  };

  const go = (path: string) => {
    const keyword = searchParams.get("searchKeyword");
    navigate(path, {
      state: { page, keyword },
    });
  };

  useEffect(() => {
    if (location.state?.keyword) {
      setQueryString();
    }

    getList();
  }, [page]);

  return (
    <PortfolioComponent
      isLoading={isLoading}
      isSearched={searchParams.get("searchKeyword")}
      portfolioList={portfolioList}
      go={go}
      // search keyword
      keyword={keyword}
      setKeyword={setKeyword}
      searchKeyword={searchKeyword}
      // pagination
      limit={limit}
      page={page}
      setPage={setPage}
      totalCount={totalCount}
      // popup
      isShowTaggedStudentPopup={isShowTaggedStudentPopup}
      setIsShowTaggedStudentPopup={setIsShowTaggedStudentPopup}
      taggedStudentPopupInfo={taggedStudentPopupInfo}
      setTaggedStudentPopupInfo={setTaggedStudentPopupInfo}
    />
  );
};

export default PortfolioContainer;
