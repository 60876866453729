import * as BaseApi from "../Base.api";

export const getList = async (params: any) => {
  const url = "/portfolio";
  return await BaseApi.get(url, params);
};

export const getListWithStudentId = async (id: number) => {
  const url = `/portfolio/student/${id}`;
  return await BaseApi.get(url);
};

export const getInfo = async (id: number) => {
  const url = `/portfolio/info/${id}`;
  return await BaseApi.get(url);
};

export const getVideoTitle = async (params: any) => {
  const url = "/portfolio/youtube-title";
  return await BaseApi.get(url, params);
};

export const createInfo = async (params: any) => {
  const url = "/portfolio";
  return await BaseApi.post(url, params);
};

export const updateInfo = async (id: number, params: any) => {
  const url = `/portfolio/${id}`;
  return await BaseApi.put(url, params);
};

export const deleteInfo = async (id: number) => {
  const url = `/portfolio/${id}`;
  return await BaseApi.remove(url);
};
