import React, { useEffect } from "react";
import LoginComponent from "./Login.component";
import { useLocation } from "react-router-dom";

const LoginContainer = (props: any) => {
  const location = useLocation();
  const state = location.state || {};
  const { isPrev = false, path = "/" } = state;
  useEffect(() => {}, []);

  return <LoginComponent {...props} isPrev={isPrev} path={path} />;
};

export default LoginContainer;
