import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AgencySignupPreComponent from "./AgencySignupPre.component";
import * as AuthApi from "api/common/Auth.api";

const AgencySignupContainer = (props: any) => {
  const { setAgencyStep, selectedAgencyInfo, setSelectedAgencyInfo } = props;
  const navigate = useNavigate();
  const [agencyList, setAgencyList] = useState([]);
  const [agencyInfo, setAgencyInfo] = useState<any>(null);

  const getAgencyList = async () => {
    const result = await AuthApi.getAgencyList();

    if (result.list.length > 0) {
      setAgencyList(result.list);
    }
  };

  const onAgencyChange = (id: string) => {
    const selectedAgency = agencyList.find(
      (agency: any) => agency.id === parseInt(id)
    );
    if (selectedAgency) {
      setSelectedAgencyInfo(selectedAgency);
    } else {
      setSelectedAgencyInfo(null);
    }
  };

  const next = () => {
    if (selectedAgencyInfo) {
      setAgencyStep(1);
    }
  };

  useEffect(() => {
    setSelectedAgencyInfo(null);
    getAgencyList();
  }, []);

  return (
    <AgencySignupPreComponent
      agencyList={agencyList}
      agencyInfo={agencyInfo}
      selectedAgencyInfo={selectedAgencyInfo}
      next={next}
      onAgencyChange={onAgencyChange}
    />
  );
};

export default AgencySignupContainer;
