import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as ImageHelper from "helpers/Image.helper";

import * as SchoolApi from "api/school/School.api";
import * as DataApi from "api/common/Data.api";

import UpdateMyComponent from "./UpdateMy.component";

const UpdateMyContainer = (props: any) => {
  const navigate = useNavigate();
  const { schoolInfo, setSchoolInfo, getInfo, updateType, setUpdateType } =
    props;

  const [imageInfo, setImageInfo] = useState<any>({});
  const [fileInfo, setFileInfo] = useState<any>({});

  const [schoolParams, setSchoolParams] = useState<any>({});
  const [contactParams, setContactParams] = useState({});
  const [snsParams, setSnsParams] = useState({});

  const [isOnNetworking, setIsOnNetworking] = useState(false);

  const onChangeInput = (componentName: string, value: any) => {
    console.log("UpdateMyContainer:onChangeInput", componentName, value);

    if (componentName === "schoolName") {
      setSchoolParams({ ...schoolParams, schoolName: value });
    } else if (componentName === "representative") {
      setSchoolParams({ ...schoolParams, representative: value });
    } else if (componentName === "countryCode") {
      setSchoolParams({ ...schoolParams, countryCode: value });
    } else if (componentName === "city") {
      setSchoolParams({ ...schoolParams, city: value });
    } else if (componentName === "address1") {
      setSchoolParams({ ...schoolParams, address1: value });
    } else if (componentName === "address2") {
      setSchoolParams({ ...schoolParams, address2: value });
    } else if (componentName === "zipcode") {
      setSchoolParams({ ...schoolParams, zipcode: value });
    } else if (componentName === "telephone") {
      setSchoolParams({ ...schoolParams, telephone: value });
    } else if (componentName === "homepageUrl") {
      setSchoolParams({ ...schoolParams, homepageUrl: value });
    }

    if (componentName === "contactEmail") {
      setContactParams({ ...contactParams, contactEmail: value });
    } else if (componentName === "cellphone") {
      setContactParams({ ...contactParams, cellphone: value });
    }

    if (componentName === "instagram") {
      setSnsParams({ ...snsParams, instagram: value });
    } else if (componentName === "youtube") {
      setSnsParams({ ...snsParams, youtube: value });
    } else if (componentName === "twitter") {
      setSnsParams({ ...snsParams, twitter: value });
    } else if (componentName === "facebook") {
      setSnsParams({ ...snsParams, facebook: value });
    } else if (componentName === "tiktok") {
      setSnsParams({ ...snsParams, tiktok: value });
    }
  };

  const updateInfo = async () => {
    if (updateType === "school" && !imageInfo?.id) {
      alert(getLanguageValue(PAGE_TYPE.SCHOOL, "my.errLogoUpload"));
      return;
    }

    if (updateType === "school") {
      schoolParams.logoImageId = imageInfo.id;
      schoolParams.businessRegistrationFileId = fileInfo.id;
    }

    const result: any =
      updateType === "school"
        ? await SchoolApi.updateInfo(schoolInfo.id, schoolParams)
        : updateType === "contact"
        ? await SchoolApi.updateContactInfo(schoolInfo.id, contactParams)
        : await SchoolApi.updateSnsInfo(schoolInfo.id, snsParams);

    if (result.data.data) {
      setSchoolInfo({});
      getInfo();
      cancelUpdate();
    } else {
      alert(result.data.message);
    }
  };

  const uploadFile = async (evt: any) => {
    setIsOnNetworking(true);

    if (!evt) {
      return false;
    }

    const file = evt.target.files[0];

    const result = await DataApi.uploadFile(file);
    setFileInfo({
      id: result.id,
      name: file.name,
      url: result.uploadUrl.split("?")[0],
    });

    setIsOnNetworking(false);
  };

  const uploadImage = async (evt: any) => {
    setIsOnNetworking(true);

    if (!evt) {
      return false;
    }

    const file = evt.target.files[0];
    const imageInfo = await ImageHelper.getImageInfo(file);
    if (!imageInfo) {
      alert("Please choose image file");
      return false;
    }

    const result = await DataApi.uploadImage(file);
    setImageInfo({ id: result.id, url: imageInfo.url });

    setIsOnNetworking(false);
  };

  const cancelUpdate = () => {
    setUpdateType("");
    navigate("/school/my");
  };

  useEffect(() => {
    if (schoolInfo?.id) {
      if (updateType === "school") {
        setImageInfo(schoolInfo.logoImageInfo);
        setFileInfo(schoolInfo.businessRegistrationFileInfo);

        setSchoolParams({
          logoImageId: schoolInfo.logoImageInfo.id,
          schoolName: schoolInfo.schoolName,
          businessRegistrationFileId:
            schoolInfo.businessRegistrationFileInfo?.id,
          representative: schoolInfo.representative,
          countryCode: schoolInfo.countryCodeInfo.code,
          city: schoolInfo.city,
          address1: schoolInfo.address1,
          address2: schoolInfo.address2,
          zipcode: schoolInfo.zipcode,
          telephone: schoolInfo.telephone,
          homepageUrl: schoolInfo.homepageUrl,
        });
      } else if (updateType === "contact") {
        setContactParams({
          contactEmail: schoolInfo.contactEmail,
          cellphone: schoolInfo.cellphone,
        });
      } else if (updateType === "sns") {
        setSnsParams({
          instagram: schoolInfo.instagram,
          youtube: schoolInfo.youtube,
          twitter: schoolInfo.twitter,
          facebook: schoolInfo.facebook,
          tiktok: schoolInfo.tiktok,
        });
      }
    }
  }, [schoolInfo]);

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <UpdateMyComponent
      id={schoolInfo.id}
      updateType={updateType}
      schoolParams={schoolParams}
      contactParams={contactParams}
      snsParams={snsParams}
      isOnNetworking={isOnNetworking}
      imageInfo={imageInfo}
      setImageInfo={setImageInfo}
      fileInfo={fileInfo}
      uploadImage={uploadImage}
      uploadFile={uploadFile}
      onChangeInput={onChangeInput}
      onClickBtnCancelUpdate={cancelUpdate}
      onClickBtnUpdateInfo={updateInfo}
    />
  );
};

export default UpdateMyContainer;
