import React, { useEffect, useRef, useState } from "react";
import AuditionCompleteComponent from "./AuditionComplete.component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as DataApi from "api/common/Data.api";
import * as AuditionApi from "api/agency/Audition.api";
import * as MessageApi from "api/message/Message.api";
import {
  getCurrentDate,
  getFormattedDateValue,
  subtractDate,
} from "helpers/Datetime.helper";

const AuditionCompleteContainer = (props: any) => {
  const { id } = useParams();
  const { auditionInfo, setAuditionInfo } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const btnRef = useRef<any>();
  const state = location?.state;
  const [isShowLoading, setIsShowLoading] = useState(true);
  const [auditionApplicantList, setAuditionApplicantList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const keywordParam = urlParams.get("keyword") || "";
  const resultParam = urlParams.get("result") || "";
  const isLikedParam = urlParams.get("isLiked") || true;

  const [keywordFilter, setKeywordFilter] = useState(keywordParam);
  const [resultFilter, setResultFilter] = useState(resultParam || null);
  const [isLikedFilter, setIsLikedFilter] = useState(
    isLikedParam === "true" ? true : null
  );

  // popup
  const [isShowAuditionInfoPopup, setIsShowAuditionInfoPopup] = useState(false);
  const [isShowAuditionMemoPopup, setIsShowAuditionMemoPopup] = useState(false);
  const [memoItem, setMemoItem] = useState(null);
  const [isShowAuditionClosePopup, setIsShowAuditionClosePopup] =
    useState(false);

  // pagination
  const searchParams = new URLSearchParams(location.search);
  const limit = 10;
  const [prev, setPrev] = useState(
    searchParams.get("prev") || location.state?.prev || 1
  );
  const [page, setPage] = useState(
    searchParams.get("page") || location.state?.page || 1
  );
  const [totalCount, setTotalcount] = useState(0);

  const [date, setDate] = useState({
    applicant: getCurrentDate("YYYY-MM-DD"),
    view: getCurrentDate("YYYY-MM-DD"),
  });

  const [
    dailyAuditionApplicantCountByAgeInfo,
    setDailyAuditionApplicantCountByAgeInfo,
  ] = useState({
    labels: [],
    data: {
      girl: [],
      boy: [],
    },
    isLoading: true,
    max: 0,
  });

  const [dailyAuditionApplicantCountInfo, setDailyAuditionApplicantCountInfo] =
    useState({
      labels: [],
      data: {
        girl: [],
        boy: [],
      },
      isLoading: true,
      max: 0,
    });

  const [dailyAuditionViewCountInfo, setDailyAuditionViewCountInfo] = useState({
    labels: [],
    data: {
      girl: [],
      boy: [],
    },
    isLoading: true,
    max: 0,
  });

  const max = (num: number) => {
    while (num === 0 || num % 4 > 0) {
      num = num + 1;
    }
    return num;
  };

  const getAuditionInfo = async () => {
    if (auditionInfo) {
      const labels: any[] = [];
      const data: any = { girl: [], boy: [] };

      for (let age = 12; age <= 23; age++) {
        labels.push(age);
        data.girl.push(0);
        data.boy.push(0);
      }

      auditionInfo.ageCountList.forEach((item: any) => {
        const index = labels.indexOf(item.age);
        if (index !== -1) {
          data.girl[index] = item.count.girl;
          data.boy[index] = item.count.boy;
        }
      });

      const params = {
        labels,
        data,
        isLoading: false,
        max: max(Math.max(...data.girl, ...data.boy)),
      };
      setDailyAuditionApplicantCountByAgeInfo(params);
      setIsShowLoading(false);
    }
  };

  const getApplicantList = async () => {
    setIsLoading(true);
    const params = {
      auditionId: id,
      page,
      limit,
      keyword: keywordFilter,
      result: resultFilter,
      isLiked: isLikedFilter === true ? true : null,
    };
    const result = await AuditionApi.getApplicantList(params);

    if (result?.list) {
      const updatedList = await Promise.all(
        result.list.map(async (applicant: any) => {
          if (applicant.profileImageInfo) {
            const profileInfo = await DataApi.getImagePresignedUrl(
              applicant.profileImageInfo.id
            );
            if (profileInfo) {
              applicant.profileImageInfo.url = profileInfo.result;
            }
          }
          return applicant;
        })
      );
      setAuditionApplicantList(updatedList);
      setTotalcount(result.totalCount);
    }
    setIsLoading(false);
  };

  const search = async () => {
    await getApplicantList();
    setPage(1);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", "1");

    if (keywordFilter) {
      searchParams.set("keyword", keywordFilter);
    } else {
      searchParams.delete("keyword");
    }

    if (resultFilter) {
      searchParams.set("result", keywordFilter);
    } else {
      searchParams.delete("result");
    }

    if (isLikedFilter) {
      searchParams.set("isLiked", isLikedFilter.toString());
    } else {
      searchParams.delete("isLiked");
    }

    if (state) {
      navigate(`/agency/audition/${id}?${searchParams}`, state);
    } else {
      navigate(`/agency/audition/${id}?${searchParams}`);
    }
  };

  const toggleLike = async () => {
    if (isLikedFilter) {
      setIsLikedFilter(false);
    } else {
      setIsLikedFilter(true);
    }
  };

  const toggleUserLike = async (item: any, isLiked: boolean) => {
    setAuditionApplicantList((prevAuditionApplicantList) => {
      const updatedList = prevAuditionApplicantList.map((prev) =>
        prev.id === item.id ? { ...prev, isLiked: isLiked } : prev
      );
      return updatedList;
    });

    await AuditionApi.updateLike(item.id, { isLiked });
  };

  const barDataset = (
    data: any,
    backgroundColor: string,
    borderColor: string,
    label: string
  ) => {
    return {
      label,
      data: [...data],
      backgroundColor,
      borderColor,
      borderWidth: 1,
      pointRadius: 2.5,
      clip: {
        top: false,
        bottom: false,
        right: false,
        left: false,
      },
    };
  };

  const dataset = (
    data: any,
    borderColor: string,
    pointBackgroundColor?: string,
    borderDash?: number[]
  ) => {
    return {
      data,
      borderColor,
      pointBackgroundColor,
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      borderDash,
      pointRadius: 2.5,
      clip: {
        top: false,
        bottom: false,
        right: false,
        left: false,
      },
    };
  };

  const getFavoriteOrViewCount = async (type: string) => {
    const result =
      type === "applicant"
        ? await AuditionApi.getDailyApplicantCount({
            auditionId: id,
            date: date.applicant,
          })
        : await AuditionApi.getDailyViewCount({
            auditionId: id,
            date: date.view,
          });

    const labels: any[] = [];
    const data: any = { girl: [], boy: [] };

    result.list.map((item: any) => {
      data.girl.push(item.count.girl);
      data.boy.push(item.count.boy);
      labels.push(getFormattedDateValue(item.date, "M.D"));
    });

    const params = {
      labels,
      data,
      isLoading: false,
      max: max(Math.max(...data.girl, ...data.boy)),
    };
    type === "applicant"
      ? setDailyAuditionApplicantCountInfo(params)
      : setDailyAuditionViewCountInfo(params);
  };

  const getMessageRequest = async (userStudentId: number) => {
    const requestInfo = await MessageApi.getRequestMessage(userStudentId);
    if (requestInfo?.info) {
      setAuditionApplicantList((prevList) => {
        const applicantIndex = prevList.findIndex(
          (applicant) =>
            applicant.userStudentId === requestInfo?.info.userStudentId
        );

        if (applicantIndex === -1) {
          return prevList; // If the applicant is not found, return the list as is
        }

        const updatedApplicant = {
          ...prevList[applicantIndex],
          mssageRequestInfo: {
            ...prevList[applicantIndex].mssageRequestInfo,
            id: requestInfo?.info.id,
            messageStatusCode: requestInfo?.info.messageStatusCode,
          },
        };

        const updatedList = [
          ...prevList.slice(0, applicantIndex),
          updatedApplicant,
          ...prevList.slice(applicantIndex + 1),
        ];

        return updatedList;
      });
    }
  };

  const createRequest = async (userStudentId: number) => {
    const result = await MessageApi.createRequest({
      studentId: userStudentId,
    });
    if (result) {
      await getMessageRequest(userStudentId);
    }
  };

  const cancelRequest = async (userStudentId: number, id: number) => {
    const result = await MessageApi.updateMessageInfo(id, {
      messageType: "messageType:cancel",
    });

    if (result) {
      await getMessageRequest(userStudentId);
    }
  };

  useEffect(() => {
    getAuditionInfo();
    getApplicantList();
  }, [page]);

  useEffect(() => {
    if (isLikedFilter !== null) {
      search();
    }
  }, [isLikedFilter]);

  useEffect(() => {
    if (resultFilter !== null) {
      search();
    }
  }, [resultFilter]);

  useEffect(() => {
    getFavoriteOrViewCount("applicant");
  }, [date.applicant]);

  useEffect(() => {
    getFavoriteOrViewCount("view");
  }, [date.view]);

  return (
    <AuditionCompleteComponent
      {...props}
      id={id}
      isLoading={isLoading}
      isShowLoading={isShowLoading}
      auditionApplicantList={auditionApplicantList}
      setAuditionApplicantList={setAuditionApplicantList}
      auditionInfo={auditionInfo}
      toggleUserLike={toggleUserLike}
      btnRef={btnRef}
      // search
      search={search}
      keywordFilter={keywordFilter}
      setKeywordFilter={setKeywordFilter}
      resultFilter={resultFilter}
      setResultFilter={setResultFilter}
      isLikedFilter={isLikedFilter}
      setIsLikedFilter={setIsLikedFilter}
      toggleLike={toggleLike}
      // pagination
      prev={prev}
      limit={limit}
      page={page}
      setPage={setPage}
      totalCount={totalCount}
      // popup
      isShowAuditionInfoPopup={isShowAuditionInfoPopup}
      setIsShowAuditionInfoPopup={setIsShowAuditionInfoPopup}
      isShowAuditionMemoPopup={isShowAuditionMemoPopup}
      setIsShowAuditionMemoPopup={setIsShowAuditionMemoPopup}
      isShowAuditionClosePopup={isShowAuditionClosePopup}
      setIsShowAuditionClosePopup={setIsShowAuditionClosePopup}
      memoItem={memoItem}
      setMemoItem={setMemoItem}
      //
      date={date}
      setDate={setDate}
      dailyAuditionApplicantCountByAgeInfo={
        dailyAuditionApplicantCountByAgeInfo
      }
      dailyAuditionViewCountInfo={dailyAuditionViewCountInfo}
      dailyAuditionApplicantCountInfo={dailyAuditionApplicantCountInfo}
      barDataset={barDataset}
      dataset={dataset}
      //message
      createRequest={createRequest}
      cancelRequest={cancelRequest}
    />
  );
};

export default AuditionCompleteContainer;
