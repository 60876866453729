import React, { useEffect } from "react";
import { getLanguageValue } from "locales/i18n";

const PostFormPopupComponent = (props: any) => {
    const { isShowCommentPopup, setIsShowCommentPopup, selectedCommentValue, setSelectedCommentValue, isOnNetworking, editCommnetValue } = props;

    useEffect(() => {
        if (!isShowCommentPopup) {
            setSelectedCommentValue("");
        }
    }, [isShowCommentPopup]);

    return (
        <div className="v2-popup v2-popup-posting-form" style={{ display: isShowCommentPopup ? "block" : "none" }}>
            <div className="wrap">
                <div className="con">
                    <div className="header">
                        <div className="title">
                            <h6>
                                <strong>댓글</strong>
                                수정
                            </h6>
                        </div>

                        <div
                            className="btn-close-popup"
                            onClick={(e: any) => {
                                setIsShowCommentPopup(false);
                            }}>
                            <img src="./imgs/common/icon-close-popup.png" alt="close popup button" />
                        </div>
                    </div>

                    <div className="body">
                        <div className="v2-textarea">
                            <input
                                placeholder="내용을 입력해주세요~"
                                value={selectedCommentValue}
                                onChange={(e) => setSelectedCommentValue(e.target.value)}
                                onKeyDown={(evt) => {
                                    if (evt.key === "Enter") {
                                        if (!selectedCommentValue) {
                                            alert("need to input comment");
                                            return;
                                        }

                                        editCommnetValue({ comment: selectedCommentValue });
                                    }
                                }}></input>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="col col-left"></div>
                        <div className="col col-right">
                            <button
                                className="btn btn-save"
                                disabled={isOnNetworking}
                                onClick={(e: any) => {
                                    if (!selectedCommentValue) {
                                        alert("need to input comment");
                                        return;
                                    }

                                    editCommnetValue({ comment: selectedCommentValue });
                                }}>
                                저장
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostFormPopupComponent;
