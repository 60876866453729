import * as BaseApi from "../Base.api";

export const getListPractice = async (params: any) => {
  const url = "/agency/profile/practice";
  return await BaseApi.get(url, params);
};

export const getInfoPractice = async (id: number) => {
  const url = `/agency/profile/practice/${id}`;
  return await BaseApi.get(url);
};

export const createPracticeFavorite = async (params: any) => {
  const url = "/agency/profile/practice/favorite";
  return await BaseApi.post(url, params);
};

export const deletePracticeFavorite = async (params: any) => {
  const url = "/agency/profile/practice/favorite";
  return await BaseApi.remove(url, params);
};

export const getInfoForAgency = async (params: any) => {
  const url = "/agency/profile";
  return await BaseApi.get(url, params);
};

export const createRequestProfile = async (params: any) => {
  const url = "/agency/profile/profile-request";
  return await BaseApi.post(url, params);
};

export const getListProfileForAgency = async (params: any) => {
  const url = "/agency/profile/profile-image";
  return await BaseApi.get(url, params);
};

export const updateViewCount = async (params: any) => {
  const url = `/agency/profile/view-count`;
  return await BaseApi.post(url, params);
};
