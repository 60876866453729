import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import LoadingComponent from "commons/loading/Loading.component";
import * as commonData from "data/common.data";
import { getCurrentLangName, getOnlyNumberValue } from "helpers/String.helper";

const VerifyEmailComponent = (props: any) => {
    const { isLoading, userInfo, params, onChangeInput, saveInfo, btnRef } = props;

    return isLoading && !userInfo ? (
        <div className="certification">
            <main>
                <LoadingComponent />
            </main>
        </div>
    ) : (
        <>
            {!userInfo?.isVerify ? (
                <div className="certification">
                    <main>
                        <div>
                            <strong>{getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtFailed1")}</strong>
                            <p>{getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtFailed2")}</p>
                        </div>
                    </main>
                </div>
            ) : (
                <main className="signup-form consent-to-join">
                    <div className="con">
                        <div className="form">
                            <strong>
                                {userInfo?.firstName} {userInfo?.lastName}
                            </strong>
                            <p>{getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtParentDesc")}</p>
                            <div className="input-box input-box-select">
                                <div className="desc">
                                    <label>*{getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtCountry")}</label>
                                </div>
                                <select value={params.countryCode} onChange={(evt) => onChangeInput("countryCode", evt.target.value)}>
                                    <option value="" disabled selected>
                                        {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtCountryOptional")}
                                    </option>
                                    {commonData.getCountryCodeList().map((item: any, idx: number) => (
                                        <option key={idx} value={item.code}>
                                            {getCurrentLangName(item.names)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="input-box">
                                <label>*{getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtFirstName")}</label>
                                <div className="input-box-wrap">
                                    <input
                                        className="name"
                                        type={getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtFirstName")}
                                        placeholder="이름"
                                        value={params.firstName}
                                        onChange={(evt) => onChangeInput("firstName", evt.target.value)}
                                    />
                                    <input
                                        className="first-name"
                                        type="text"
                                        placeholder={getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtLastName")}
                                        value={params.lastName}
                                        onChange={(evt) => onChangeInput("lastName", evt.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="input-box">
                                <label>*{getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtPhoneNumber")}</label>
                                <div className="input-box-wrap">
                                    <select value={params.phoneCode} onChange={(evt) => onChangeInput("phoneCode", evt.target.value)}>
                                        <option value="" disabled selected>
                                            {getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtPhoneCode")}
                                        </option>
                                        {commonData.getCountryPhoneCodeList().map((item: any, idx: number) => (
                                            <option key={idx} value={item.phoneCode}>
                                                {`${item.phoneCode} (${getCurrentLangName(item.names)})`}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="input-box-wrap">
                                    <input
                                        type="text"
                                        placeholder={getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtPhoneNumber")}
                                        value={params.phoneNumber}
                                        onChange={(evt) => {
                                            const value = getOnlyNumberValue(evt.target.value);

                                            if (value.length < 20) {
                                                onChangeInput("phoneNumber", value);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form">
                            <div className="agreement-form">
                                <label className="checkbox">
                                    <div className="wrap">
                                        <input type="checkbox" onChange={(evt) => onChangeInput("isAgreeTerm", evt.target.checked)} />
                                        <p>{getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtTermAgree")}</p>
                                    </div>
                                    <p>{getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.txtTermWarn")}</p>
                                </label>
                            </div>
                        </div>

                        <div className="btn">
                            <button ref={btnRef} onClick={saveInfo}>
                                {getLanguageValue(PAGE_TYPE.COMMON, "verifyEmail.btnSave")}
                            </button>
                        </div>
                    </div>
                </main>
            )}
        </>
    );
};

export default VerifyEmailComponent;
