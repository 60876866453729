import React, { useEffect, useRef, useState } from "react";
import AuditionInProgressComponent from "./AuditionInProgress.component";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import * as AuditionApi from "api/agency/Audition.api";
import * as DataApi from "api/common/Data.api";

const AuditionInProgressContainer = (props: any) => {
  const { id } = useParams();
  const { auditionInfo, setAuditionInfo } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const keywordParam = urlParams.get("keyword") || "";
  const isLikedParam = urlParams.get("isLiked") || true;

  const [keywordFilter, setKeywordFilter] = useState(keywordParam);
  const [isLikedFilter, setIsLikedFilter] = useState(
    isLikedParam === "true" ? true : null
  );

  const [auditionApplicantList, setAuditionApplicantList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const state = location?.state;

  // popup
  const [isShowAuditionInfoPopup, setIsShowAuditionInfoPopup] = useState(false);
  const [isShowAuditionMemoPopup, setIsShowAuditionMemoPopup] = useState(false);
  const [memoItem, setMemoItem] = useState(null);
  const [isShowAuditionClosePopup, setIsShowAuditionClosePopup] =
    useState(false);

  // pagination
  const searchParams = new URLSearchParams(location.search);
  const limit = 10;
  const [prev, setPrev] = useState(
    searchParams.get("prev") || location.state?.prev || 1
  );
  const [page, setPage] = useState(
    searchParams.get("page") || location.state?.page || 1
  );
  const [totalCount, setTotalcount] = useState(0);

  const getApplicantList = async () => {
    setIsLoading(true);
    const params = {
      auditionId: id,
      page,
      limit,
      keyword: keywordFilter,
      isLiked: isLikedFilter === true ? true : null,
    };
    const result = await AuditionApi.getApplicantList(params);

    if (result?.list) {
      const updatedList = await Promise.all(
        result.list.map(async (applicant: any) => {
          if (applicant.profileImageInfo) {
            const profileInfo = await DataApi.getImagePresignedUrl(
              applicant.profileImageInfo.id
            );
            if (profileInfo) {
              applicant.profileImageInfo.url = profileInfo.result;
            }
          }
          return applicant;
        })
      );

      setAuditionApplicantList(updatedList);
      setTotalcount(result.totalCount);
    }
    setIsLoading(false);
  };

  const go = (path: string, status: string) => {
    navigate(path, {
      state: { page, status },
    });
  };

  const search = async () => {
    await getApplicantList();
    setPage(1);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", "1");

    if (keywordFilter) {
      searchParams.set("keyword", keywordFilter);
    } else {
      searchParams.delete("keyword");
    }

    if (isLikedFilter) {
      searchParams.set("isLiked", isLikedFilter.toString());
    } else {
      searchParams.delete("isLiked");
    }

    if (state) {
      navigate(`/agency/audition/${id}?${searchParams}`, state);
    } else {
      navigate(`/agency/audition/${id}?${searchParams}`);
    }
  };

  const toggleLike = async () => {
    if (isLikedFilter) {
      setIsLikedFilter(false);
    } else {
      setIsLikedFilter(true);
    }
  };

  const toggleUserLike = async (item: any, isLiked: boolean) => {
    setAuditionApplicantList((prevAuditionApplicantList) => {
      const updatedList = prevAuditionApplicantList.map((prev) =>
        prev.id === item.id ? { ...prev, isLiked: isLiked } : prev
      );
      return updatedList;
    });

    await AuditionApi.updateLike(item.id, { isLiked });
  };

  useEffect(() => {
    getApplicantList();
  }, [page]);

  useEffect(() => {
    if (isLikedFilter !== null) {
      search();
    }
  }, [isLikedFilter]);

  return (
    <AuditionInProgressComponent
      {...props}
      id={id}
      go={go}
      isLoading={isLoading}
      auditionApplicantList={auditionApplicantList}
      setAuditionApplicantList={setAuditionApplicantList}
      auditionInfo={auditionInfo}
      toggleUserLike={toggleUserLike}
      // search
      search={search}
      keywordFilter={keywordFilter}
      setKeywordFilter={setKeywordFilter}
      isLikedFilter={isLikedFilter}
      setIsLikedFilter={setIsLikedFilter}
      toggleLike={toggleLike}
      // pagination
      prev={prev}
      limit={limit}
      page={page}
      setPage={setPage}
      totalCount={totalCount}
      // popup
      isShowAuditionInfoPopup={isShowAuditionInfoPopup}
      setIsShowAuditionInfoPopup={setIsShowAuditionInfoPopup}
      isShowAuditionMemoPopup={isShowAuditionMemoPopup}
      setIsShowAuditionMemoPopup={setIsShowAuditionMemoPopup}
      isShowAuditionClosePopup={isShowAuditionClosePopup}
      setIsShowAuditionClosePopup={setIsShowAuditionClosePopup}
      memoItem={memoItem}
      setMemoItem={setMemoItem}
    />
  );
};

export default AuditionInProgressContainer;
