import axios from "axios";
import { getAccessToken } from "helpers/Auth.helper";
import { getQueryStringFromObject } from "helpers/Api.helper";

const CHAT_API_URL = process.env.REACT_APP_CHAT_URL;

const getAxios = () => {
  const headers: any = {
    accesskey: process.env.REACT_APP_CHAT_ACCESS_KEY,
    secretkey: process.env.REACT_APP_CHAT_SECRET_KEY,
  };

  const accessToken = getAccessToken();
  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`;
  }

  return axios.create({ headers });
};

export const getAgencyChannels = async () => {
  const url = "/agency/channels";

  return getAxios()
    .get(`${CHAT_API_URL}${url}`)
    .then(({ status, data }) => {
      if (status === 200) {
        return data.data;
      }
    })
    .catch(errorHandler);
};

export const getAgencyChannel = async (channelId: string) => {
  const url = `/agency/channel/${channelId}`;

  return getAxios()
    .get(`${CHAT_API_URL}${url}`)
    .then(({ status, data }) => {
      if (status === 200) {
        return data.data;
      }
    })
    .catch(errorHandler);
};

export const getSchoolChannels = async () => {
  const url = "/school/channels";

  return getAxios()
    .get(`${CHAT_API_URL}${url}`)
    .then(({ status, data }) => {
      if (status === 200) {
        return data.data;
      }
    })
    .catch(errorHandler);
};

export const getSchoolChannel = async (channelId: string) => {
  const url = `/school/channel/${channelId}`;

  return getAxios()
    .get(`${CHAT_API_URL}${url}`)
    .then(({ status, data }) => {
      if (status === 200) {
        return data.data;
      }
    })
    .catch(errorHandler);
};

export const getStudentChannels = async () => {
  const url = "/student/channels";

  return getAxios()
    .get(`${CHAT_API_URL}${url}`)
    .then(({ status, data }) => {
      if (status === 200) {
        return data.data;
      }
    })
    .catch(errorHandler);
};

export const getStudentChannel = async (channelId: string) => {
  const url = `/student/channel/${channelId}`;

  return getAxios()
    .get(`${CHAT_API_URL}${url}`)
    .then(({ status, data }) => {
      if (status === 200) {
        return data.data;
      }
    })
    .catch(errorHandler);
};

export const getChannelMessages = async (id: string, slug = {}) => {
  const url = "/channel";
  const params = getQueryStringFromObject(slug);

  return getAxios()
    .get(`${CHAT_API_URL}${url}/${id}${params}`)
    .then(({ status, data }) => {
      if (status === 200) {
        return data.data;
      }
    })
    .catch(errorHandler);
};

export const getChannelRecentlyMessages = async (id: string, slug = {}) => {
  const url = "/channel/recently";
  const params = getQueryStringFromObject(slug);

  return getAxios()
    .get(`${CHAT_API_URL}${url}/${id}${params}`)
    .then(({ status, data }) => {
      if (status === 200) {
        return data.data;
      }
    })
    .catch(errorHandler);
};

export const createChannel = async (params: any) => {
  const url = "/channel/create";

  return getAxios()
    .post(`${CHAT_API_URL}${url}`, params)
    .then(({ status, data }) => {
      return { status, data };
    })
    .catch(errorHandler);
};

export const createNotification = async (params: any) => {
  const url = "/channel/notification";

  return getAxios()
    .post(`${CHAT_API_URL}${url}`, params)
    .then(({ status, data }) => {
      return { status, data };
    })
    .catch(errorHandler);
};

export const closeChannelByAgecny = async (id: string) => {
  const url = "/agency/channels/" + id + "/close";

  return getAxios()
    .post(`${CHAT_API_URL}${url}`)
    .then(({ status, data }) => {
      return { status, data };
    })
    .catch(errorHandler);
};

export const closeChannelBySchool = async (id: string) => {
  const url = "/school/channels/" + id + "/close";

  return getAxios()
    .post(`${CHAT_API_URL}${url}`)
    .then(({ status, data }) => {
      return { status, data };
    })
    .catch(errorHandler);
};

export const closeChannelByStudent = async (id: string) => {
  const url = "/student/channels/" + id + "/close";

  return getAxios()
    .post(`${CHAT_API_URL}${url}`)
    .then(({ status, data }) => {
      return { status, data };
    })
    .catch(errorHandler);
};

export const alarmBadWord = async (params: any) => {
    const url = "/channel/alarm-bad-word";
  
    return getAxios()
      .post(`${CHAT_API_URL}${url}`, params)
      .then(({ status, data }) => {
        return { status, data };
      })
      .catch(errorHandler);
  };
const errorHandler = ({ response }: any) => {
  console.log(response);
  alert(response.data.message);
};
