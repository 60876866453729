export const UPDATED_FAVORITE_COUNT = "AGENCY/UPDATED_FAVORITE_COUNT";
export const IS_SHOW_FILTER_POPUP = "AGENCY/IS_SHOW_FILTER_POPUP";

export const SEARCH_KEYWORD = "AGENCY/SEARCH_KEYWORD";
export const SEARCH_GENDER_TYPE_CODE = "AGENCY/SEARCH_GENDER_TYPE_CODE";
export const SEARCH_COUNTRY_CODE_LIST = "AGENCY/SEARCH_COUNTRY_CODE_LIST";
export const SEARCH_HEIGHT_RANGE = "AGENCY/SEARCH_HEIGHT_RANGE";
export const SEARCH_WEIGHT_RANGE = "AGENCY/SEARCH_WEIGHT_RANGE";
export const SEARCH_AGE_RANGE = "AGENCY/SEARCH_AGE_RANGE";
export const NEW_MESSAGE_COUNT = "AGENCY/NEW_MESSAGE_COUNT";

export const setUpdatedFavoriteCount = (updatedFavoriteCount: number) => ({
  type: UPDATED_FAVORITE_COUNT,
  updatedFavoriteCount,
});
export const setIsShowFilterPopup = (isShowFilterPopup: boolean) => ({
  type: IS_SHOW_FILTER_POPUP,
  isShowFilterPopup,
});

export const setSearchKeyword = (searchKeyword: string) => ({
  type: SEARCH_KEYWORD,
  searchKeyword,
});
export const setSearchGenderTypeCode = (searchGenderTypeCode: string) => ({
  type: SEARCH_GENDER_TYPE_CODE,
  searchGenderTypeCode,
});
export const setSearchCountryCodeList = (searchCountryCodeList: string[]) => ({
  type: SEARCH_COUNTRY_CODE_LIST,
  searchCountryCodeList,
});
export const setSearchHeightRange = (searchHeightRange: [number, number]) => ({
  type: SEARCH_HEIGHT_RANGE,
  searchHeightRange,
});
export const setSearchWeightRange = (searchWeightRange: [number, number]) => ({
  type: SEARCH_WEIGHT_RANGE,
  searchWeightRange,
});
export const setSsearchAgeRange = (searchAgeRange: [number, number]) => ({
  type: SEARCH_AGE_RANGE,
  searchAgeRange,
});
export const setNewMessageCount = (newMessageCount: number) => ({
  type: NEW_MESSAGE_COUNT,
  newMessageCount,
});

interface InitialState {
  updatedFavoriteCount: number;
  isShowFilterPopup: boolean;

  searchKeyword: string;
  searchGenderTypeCode: string;
  searchCountryCodeList: any[];

  searchHeightRange: [number, number];
  searchWeightRange: [number, number];
  searchAgeRange: [number, number];
  newMessageCount: 0;
}

const initialState: InitialState = {
  updatedFavoriteCount: 0,
  isShowFilterPopup: false,
  searchKeyword: "",
  searchGenderTypeCode: "",
  searchCountryCodeList: [],
  searchHeightRange: [140, 200],
  searchWeightRange: [40, 100],
  searchAgeRange: [0, 30],
  newMessageCount: 0,
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case UPDATED_FAVORITE_COUNT:
      return {
        ...state,
        updatedFavoriteCount: action.updatedFavoriteCount,
      };
    case IS_SHOW_FILTER_POPUP:
      return {
        ...state,
        isShowFilterPopup: action.isShowFilterPopup,
      };
    case SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.searchKeyword,
      };

    case SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.searchKeyword,
      };
    case SEARCH_GENDER_TYPE_CODE:
      return {
        ...state,
        searchGenderTypeCode: action.searchGenderTypeCode,
      };
    case SEARCH_COUNTRY_CODE_LIST:
      return {
        ...state,
        searchCountryCodeList: action.searchCountryCodeList,
      };

    case SEARCH_HEIGHT_RANGE:
      return {
        ...state,
        searchHeightRange: action.searchHeightRange,
      };

    case SEARCH_WEIGHT_RANGE:
      return {
        ...state,
        searchWeightRange: action.searchWeightRange,
      };
    case SEARCH_AGE_RANGE:
      return {
        ...state,
        searchAgeRange: action.searchAgeRange,
      };
    case NEW_MESSAGE_COUNT:
      return {
        ...state,
        newMessageCount: action.newMessageCount,
      };

    default:
      return state;
  }
}
