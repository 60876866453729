import React, { useCallback, useEffect, useRef, useState } from "react";
import HomeComponent from "./HomeV2.component";

import { getCommonCodeListByParent } from "data/common.data";
import { getFormattedDateValue } from "helpers/Datetime.helper";

import * as StudentApi from "api/student/Student.api";
import { useLocation, useNavigate } from "react-router-dom";
import {
    createPost,
    createPostComment,
    deletePost,
    editPost,
    getPostInfo,
    getPostList,
    editPostComment,
    deletePostComment,
    createPostLike,
    deletePostLike,
    getRecommendWannabeList,
    createPostCommentReport,
    createPostReport,
} from "api/Post.api";
import { getTranslateText } from "api/Translate.api";

const HomeV2Container = (props: any) => {
    const [postList, setPostList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const commentSectionRefs = useRef([]);
    const slideRefs = useRef([]);
    const postSectionRef = useRef(null);
    const navigate = useNavigate();

    const [isUnder14, setIsUnder14] = useState(false);
    const [parentEmail, setParentEmail] = useState("");
    const limit = 3;
    const [page, setPage] = useState(Number(searchParams.get("page")) || 1);
    const [tag, setTag] = useState(searchParams.get("tag") || "");
    const [isOnNetworking, setIsOnNetworking] = useState(false);
    const [recWannabeList, setRecWannabeList] = useState([]);

    const [isShowFollowPopup, setIsShowFollowPopup] = useState(false);
    const [isShowFormPopup, setIsShowFormPopup] = useState(false);
    const [isShowLikePopup, setIsShowLikePopup] = useState(false);
    const [isShowCommentPopup, setIsShowCommentPopup] = useState(false);
    const [isPostListLoading, setIsPostListLoading] = useState(false);
    const [isShowPostListLoading, setIsShowPostListLoading] = useState(false);
    const getStudentInfo = async () => {
        const studentInfo = await StudentApi.getInfoForStudent();

        if (studentInfo?.info) {
            const info = studentInfo.info;
            setIsUnder14(info.under14 && !info.isParentConfirm);
            setParentEmail(info.parentEmail);
        }
    };
    const [selectedPostId, setSelectedPostId] = useState(0);
    const [selectedCommentId, setSelectedCommentId] = useState(0);
    const [selectedCommentValue, setSelectedCommentValue] = useState("");
    const [selectedWannabeId, setSelectedWannabeId] = useState(0);
    const observer = useRef<IntersectionObserver | null>(null);

    const getPostListValue = useCallback(
        async (page: number, tag: string, isNewList: boolean) => {
            console.log(555, page, isNewList, tag);
            setIsPostListLoading(true);
            if (isNewList) {
                setIsShowPostListLoading(true);
            }
            const result = await getPostList({ page, tag, limit });

            if (result?.list) {
                console.log(222, postList);
                const list = isNewList ? [...result.list] : [...postList, ...result.list];
                console.log(4545, list);
                setPostList(list);
            }

            if (isNewList) {
                postSectionRef?.current?.scrollTo({
                    top: 0,
                    behavior: "smooth", // 부드러운 스크롤 옵션
                });
            }

            setHasMore(result?.hasMore || false);
            setIsPostListLoading(false);
            setIsShowPostListLoading(false);
            setIsLoading(false);
            setQueryString(page, tag);
        },
        [postList]
    );

    const setQueryString = (page: number, tag: string) => {
        // searchParams.set("page", page.toString());
        searchParams.set("tag", tag);

        navigate(`${location.pathname}?${searchParams}`);
    };

    const createComment = async (postId: number) => {
        setIsOnNetworking(true);
        const newList = [...postList];
        const post = newList.find((v: any) => v.id === postId);

        if (!post.inputValue) {
            return;
        }

        const params = {
            postId,
            comment: post.inputValue,
            ref: post.selectedCommentId ? post.selectedCommentId : 0,
            level: post.selectedCommentId ? 1 : 0,
        };

        const result: any = await createPostComment(params);

        if (result?.data?.data?.list) {
            post.commentList = result?.data?.data?.list;

            post.inputValue = "";
            post.selectedCommentId = 0;

            setPostList(newList);
        }
        setIsOnNetworking(false);

        const postIndex = newList.findIndex((v: any) => v.id === postId);

        commentSectionRefs.current[postIndex].scrollTo({
            top: 0,
            behavior: "smooth", // 부드러운 스크롤 옵션
        });
    };

    const createPostValue = async (postParams: any) => {
        setIsOnNetworking(true);
        const result: any = await createPost(postParams);

        if (result.status === 200) {
            setTag("");
            if (page !== 1) {
                setPage(1);
            }
            getPostListValue(1, "", true);
        }
        setIsOnNetworking(false);
        setIsShowFormPopup(false);
    };

    const [hasMore, setHasMore] = useState<boolean>(true);

    const editPostValue = async (postId: number, postParams: any) => {
        const result: any = await editPost(postId, postParams);

        if (result.status === 200) {
            const infoResult = await getPostInfo(postId);

            const newPost = infoResult?.info;

            if (newPost) {
                setPostList((prevItems) => prevItems.map((item) => (item.id === postId ? { ...item, ...newPost, id: item.id } : item)));
            }
        }

        setIsShowFormPopup(false);
        setSelectedPostId(0);
    };

    const deletePostValue = async (postId: number) => {
        if (window.confirm("정말로 삭제하시겠습니까?")) {
            const result: any = await deletePost(postId);

            if (result.status === 200) {
                setPostList(postList.filter((v) => v.id !== postId));
            }
        }
    };

    const createPostLikeValue = async (postId: number) => {
        setIsOnNetworking(true);
        const resut: any = await createPostLike({ postId });

        if (resut?.status === 200) {
            const infoResult = await getPostInfo(postId);

            const newPost = infoResult?.info;

            if (newPost) {
                setPostList((prevItems) => prevItems.map((item) => (item.id === postId ? { ...item, ...newPost, id: item.id } : item)));
            }
        }

        setIsOnNetworking(false);
    };

    const deletePostLikeValue = async (postId: number) => {
        setIsOnNetworking(true);
        const resut: any = await deletePostLike({ postId });

        if (resut?.status === 200) {
            const infoResult = await getPostInfo(postId);

            const newPost = infoResult?.info;

            if (newPost) {
                setPostList((prevItems) => prevItems.map((item) => (item.id === postId ? { ...item, ...newPost, id: item.id } : item)));
            }
        }

        setIsOnNetworking(false);
    };

    // 마지막 항목이 관찰될 때 API 호출
    const lastItemRef = useCallback(
        (node: HTMLDivElement | null) => {
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                console.log(entries[0].isIntersecting, hasMore, isPostListLoading);
                if (entries[0].isIntersecting && hasMore && !isPostListLoading) {
                    setPage((prevPage) => prevPage + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [hasMore]
    );

    const getRecommendWannabeListValue = async () => {
        const result = await getRecommendWannabeList();

        if (result?.list) {
            setRecWannabeList(result?.list);
        }
    };

    const deletePostCommentValue = async (commnetId: number, postId: number) => {
        if (window.confirm("정말로 삭제하시겠습니까?")) {
            const result: any = await deletePostComment(commnetId);

            if (result.status === 200) {
                const infoResult = await getPostInfo(postId);

                const newPost = infoResult?.info;

                if (newPost) {
                    setPostList((prevItems) => prevItems.map((item) => (item.id === postId ? { ...item, ...newPost, id: item.id } : item)));
                }
            }

            setSelectedPostId(0);
        }
    };

    const createPostReportValue = async (postId: number) => {
        if (window.confirm("정말로 신고하시겠습니까?")) {
            setIsOnNetworking(true);
            const result: any = await createPostReport({ postId });

            if (result.status === 200) {
                alert("신고가 완료되었습니다.");
            }

            setIsOnNetworking(false);
        }
    };

    const createPostCommentReportValue = async (postCommentId: number) => {
        if (window.confirm("정말로 신고하시겠습니까?")) {
            setIsOnNetworking(true);
            const result: any = await createPostCommentReport({ postCommentId });

            if (result.status === 200) {
                alert("신고가 완료되었습니다.");
            }

            setIsOnNetworking(false);
        }
    };

    const editCommnetValue = async (commentParams: any) => {
        const result: any = await editPostComment(selectedCommentId, commentParams);

        if (result.status === 200) {
            const infoResult = await getPostInfo(selectedPostId);

            const newPost = infoResult?.info;

            if (newPost) {
                setPostList((prevItems) => prevItems.map((item) => (item.id === selectedPostId ? { ...item, ...newPost, id: item.id } : item)));
            }
        }

        setIsShowCommentPopup(false);
        setSelectedCommentId(0);
        setSelectedPostId(0);
    };

    const translateComment = async (postId: number, commentObj: any) => {
        const newList = [...postList];
        const post = newList.find((v: any) => v.id === postId);
        const commentObjValue = post.commentList.find((v: any) => v.id === commentObj.id);

        if (!commentObj.translatedComment) {
            setIsOnNetworking(true);
            const result: any = await getTranslateText({ text: commentObj.comment });
            commentObjValue.translatedComment = result?.data?.data?.text;
            setIsOnNetworking(false);
        }

        commentObjValue.isTranslated = commentObj.isTranslated ? false : true;

        setPostList(newList);
    };

    useEffect(() => {
        getStudentInfo();
        getRecommendWannabeListValue();
        getPostListValue(page, tag, true);
    }, []);

    useEffect(() => {
        if (page > 1) {
            getPostListValue(page, tag, false);
        }
    }, [page]);

    useEffect(() => {
        if (tag) {
            setPage(1);
            getPostListValue(1, tag, true);
        }
    }, [tag]);

    return (
        <HomeComponent
            isLoading={isLoading}
            isUnder14={isUnder14}
            parentEmail={parentEmail}
            postList={postList}
            setPostList={setPostList}
            createComment={createComment}
            createPostValue={createPostValue}
            editPostValue={editPostValue}
            isOnNetworking={isOnNetworking}
            setIsOnNetworking={setIsOnNetworking}
            isShowFormPopup={isShowFormPopup}
            setIsShowFormPopup={setIsShowFormPopup}
            isShowLikePopup={isShowLikePopup}
            setIsShowLikePopup={setIsShowLikePopup}
            selectedPostId={selectedPostId}
            setSelectedPostId={setSelectedPostId}
            deletePostValue={deletePostValue}
            selectedCommentId={selectedCommentId}
            setSelectedCommentId={setSelectedCommentId}
            isShowCommentPopup={isShowCommentPopup}
            setIsShowCommentPopup={setIsShowCommentPopup}
            selectedCommentValue={selectedCommentValue}
            setSelectedCommentValue={setSelectedCommentValue}
            editCommnetValue={editCommnetValue}
            deletePostCommentValue={deletePostCommentValue}
            setTag={setTag}
            lastItemRef={lastItemRef}
            createPostLikeValue={createPostLikeValue}
            deletePostLikeValue={deletePostLikeValue}
            recWannabeList={recWannabeList}
            translateComment={translateComment}
            createPostReportValue={createPostReportValue}
            createPostCommentReportValue={createPostCommentReportValue}
            commentSectionRefs={commentSectionRefs}
            isShowPostListLoading={isShowPostListLoading}
            postSectionRef={postSectionRef}
            isShowFollowPopup={isShowFollowPopup}
            setIsShowFollowPopup={setIsShowFollowPopup}
            selectedWannabeId={selectedWannabeId}
            setSelectedWannabeId={setSelectedWannabeId}
            slideRefs={slideRefs}
        />
    );
};

export default HomeV2Container;
