import React, { useEffect } from "react";
import PasswordResetDoneComponent from "./PasswordResetDone.component";
import { useLocation, useNavigate } from "react-router-dom";

const PasswordResetDoneContainer = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};
  const { email = "" } = state;

  useEffect(() => {
    if (!email) {
      navigate("/");
    }
  }, []);

  return <PasswordResetDoneComponent {...props} email={email} />;
};

export default PasswordResetDoneContainer;
