import React, { useEffect, useRef, useState } from "react";
import PasswordResetComponent from "./PasswordReset.component";
import { useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import { checkIsEmail } from "helpers/Reg.helper";
import * as AuthApi from "api/common/Auth.api";

const PasswordResetContainer = (props: any) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const btnRef = useRef<any>();

  const isDisabled = () => {
    if (!email) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errEmail"));
      return true;
    } else if (!checkIsEmail(email)) {
      alert(getLanguageValue(PAGE_TYPE.COMMON, "schoolSignup.errInvalidEmail"));
      return true;
    } else {
      return false;
    }
  };

  const resetPassword = async () => {
    if (!isDisabled()) {
      btnRef.current.disabled = true;
      const result = await AuthApi.sendEmailForResetPassword({ email: email });

      if (result && result.data.data.result === true) {
        navigate("/password/reset/done", { state: { email: email } });
      }
      btnRef.current.disabled = false;
    }
  };

  useEffect(() => {}, []);

  return (
    <PasswordResetComponent
      {...props}
      email={email}
      setEmail={setEmail}
      resetPassword={resetPassword}
      btnRef={btnRef}
    />
  );
};

export default PasswordResetContainer;
