import React, { useEffect } from "react";
import AuditionNewContainer from "./AuditionNew.container";
import AuditionInProgressContainer from "./AuditionInProgress.container";
import AuditionCloseContainer from "./AuditionClose.container";
import AuditionCompleteContainer from "./AuditionComplete.container";
import { useNavigate } from "react-router-dom";

const AuditionDetailComponent = (props: any) => {
  const navigate = useNavigate();
  const { id, status, auditionInfo } = props;
  return status === "auditionStatus:scheduled" ? (
    <AuditionNewContainer {...props} auditionInfo={auditionInfo} id={id} />
  ) : status === "auditionStatus:inProgress" ? (
    <AuditionInProgressContainer
      {...props}
      auditionInfo={auditionInfo}
      id={id}
    />
  ) : status === "auditionStatus:done" ? (
    <AuditionCloseContainer {...props} auditionInfo={auditionInfo} id={id} />
  ) : status === "auditionStatus:evaluationCompleted" ? (
    <AuditionCompleteContainer {...props} auditionInfo={auditionInfo} id={id} />
  ) : (
    <main />
  );
};
export default AuditionDetailComponent;
