import React, { useEffect } from "react";

const PartnerComponent = (props: any) => {
  const { schoolList, agencyList } = props;

  useEffect(() => {}, []);

  return (
    <main>
      <div className="con">
        <div className="wrap">
          <div className="partner agency-wrap">
            <h2>AGENCY</h2>
            <div className="partner-logo-wrap partner-agency">
              <ul>
                {agencyList
                  ? agencyList.map((item: any, index: number) => (
                      <li key={`ag-${index}`}>
                        <div
                          className="logo"
                          style={{
                            backgroundImage: `url(${item.logoImageUrl})`,
                          }}
                        ></div>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
          </div>

          <div className="partner school-wrap">
            <h2>SCHOOL</h2>
            <div className="partner-logo-wrap partner-school">
              <ul>
                {schoolList
                  ? schoolList.map((item: any, index: number) => (
                      <li key={`sc-${index}`}>
                        <div
                          className="logo school-logo"
                          style={{
                            backgroundImage: `url(${item.logoImageUrl})`,
                          }}
                        ></div>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default PartnerComponent;
