import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as commonData from "data/common.data";
import { getCurrentLangName } from "helpers/String.helper";

import LoadingComponent from "commons/loading/Loading.component";

const UpdateMyComponent = (props: any) => {
  const {
    id,
    profileParams,
    imageInfo,
    setImageInfo,
    imageInfoList,
    isOnNetworking,
    hasChecked,
    uploadImage,
    onClickBtnDeleteImage,
    onChangeInput,
    onChangeCheckbox,
    onClickBtnCancelUpdate,
    onClickBtnUpdateInfo,
  } = props;

  return (
    <main>
      <div className="con profile-update">
        <h2>PRORILE UPDATE</h2>
        {id ? (
          <>
            <div className="form">
              <div className="input-box">
                <div className="img-upload">
                  <div className="img-wrap">
                    {imageInfo?.url ? (
                      <>
                        <div
                          className="close-btn"
                          onClick={(evt: any) => {
                            evt.preventDefault();
                            setImageInfo({});
                          }}></div>
                        <div
                          className="casting-manager-img img-upload"
                          style={{
                            backgroundImage: `url("${
                              imageInfo?.url ||
                              "./../../imgs/dummy/border/border-bl.png"
                            }")`,
                          }}></div>
                      </>
                    ) : (
                      <div className="img"></div>
                    )}
                  </div>
                  <p>
                    {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtImageUpload")}
                  </p>
                  <input
                    type="file"
                    id="input-profile-img"
                    onChange={(evt) => uploadImage("profile", evt)}
                    accept="image/*"
                  />
                  <label htmlFor="input-profile-img">
                    {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnSelectFile")}
                  </label>
                </div>
              </div>
            </div>

            <div className="form artists">
              <div className="input-box artist-img">
                {imageInfoList.length > 0 &&
                  imageInfoList.map((item: any, idx: number) => (
                    <div className="img-upload" key={`image-${idx}`}>
                      <div
                        className="add"
                        style={{
                          backgroundImage: `url("${item.url}")`,
                        }}>
                        <div
                          className="close-btn"
                          onClick={(evt) => onClickBtnDeleteImage(idx)}></div>
                      </div>
                    </div>
                  ))}
                <div className="img-upload">
                  <input
                    type="file"
                    id="input-file-img"
                    onChange={(evt) => uploadImage("addition", evt)}
                    accept="image/*"
                  />
                  <label htmlFor="input-file-img">
                    <div className="img-add"></div>
                  </label>
                </div>
              </div>
            </div>

            <div className="form essential-information">
              <div className="input-box">
                <label>
                  *{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtCountry")}
                </label>
                <select
                  value={profileParams.countryCode}
                  onChange={(evt) =>
                    onChangeInput("countryCode", evt.target.value)
                  }>
                  {commonData
                    .getCountryCodeList()
                    .map((item: any, idx: number) => (
                      <option
                        key={idx}
                        value={item.code}
                        selected={profileParams.countryCode === item.code}>
                        {getCurrentLangName(item.names)}
                      </option>
                    ))}
                </select>
              </div>
              <div className="input-box">
                <label>
                  *{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtName")}
                </label>
                <div className="con">
                  <input
                    className="first"
                    type="text"
                    value={profileParams.firstName}
                    onChange={(evt) =>
                      onChangeInput("firstName", evt.target.value)
                    }
                    placeholder=""
                  />
                  <input
                    className="last"
                    type="text"
                    value={profileParams.lastName}
                    onChange={(evt) =>
                      onChangeInput("lastName", evt.target.value)
                    }
                    placeholder=""
                  />
                </div>
              </div>
              <div className="input-box">
                <label>
                  *{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtBirthday")}
                </label>
                <input
                  type="date"
                  value={profileParams.birthday}
                  onChange={(evt) =>
                    onChangeInput("birthday", evt.target.value)
                  }
                  data-placeholder="YYYY-MM-DD"
                  aria-required="true"
                />
              </div>
              <div className="input-box input-radio">
                <label>
                  *{getLanguageValue(PAGE_TYPE.STUDENT, "my.txtGender")}
                </label>
                <form>
                  <div className="radio-box">
                    <input
                      type="radio"
                      id="girl"
                      value="genderType:girl"
                      onChange={(evt) =>
                        onChangeInput("genderTypeCode", evt.target.value)
                      }
                      checked={
                        profileParams.genderTypeCode === "genderType:girl"
                      }
                    />
                    <label>
                      {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtGirl")}
                    </label>
                  </div>
                  <div className="radio-box">
                    <input
                      type="radio"
                      id="boy"
                      value="genderType:boy"
                      onChange={(evt) =>
                        onChangeInput("genderTypeCode", evt.target.value)
                      }
                      checked={
                        profileParams.genderTypeCode === "genderType:boy"
                      }
                    />
                    <label>
                      {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtBoy")}
                    </label>
                  </div>
                </form>
              </div>
            </div>

            <div className="form additional-info">
              <div className="input-box">
                <label>
                  {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtNickname")}
                </label>
                <div className="wrap">
                  <input
                    type="text"
                    value={profileParams.nickname}
                    onChange={(evt) =>
                      onChangeInput("nickname", evt.target.value)
                    }
                    placeholder=""
                  />
                </div>
              </div>
              <div className="input-box">
                <label>
                  {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtHeight")}
                </label>
                <div className="wrap">
                  <input
                    type="text"
                    value={profileParams.height}
                    onChange={(evt) =>
                      onChangeInput("height", evt.target.value)
                    }
                    placeholder=""
                  />
                  <span>cm</span>
                </div>
              </div>
              <div className="input-box">
                <label>
                  {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtWeight")}
                </label>
                <div className="wrap">
                  <input
                    type="text"
                    value={profileParams.weight}
                    onChange={(evt) =>
                      onChangeInput("weight", evt.target.value)
                    }
                    placeholder=""
                  />
                  <span>kg</span>
                </div>
              </div>
              <div className="input-box">
                <label>
                  {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtShoeSize")}
                </label>
                <div className="wrap">
                  <input
                    type="text"
                    value={profileParams.shoeSize}
                    onChange={(evt) =>
                      onChangeInput("shoeSize", evt.target.value)
                    }
                    placeholder=""
                  />
                  <span>mm</span>
                </div>
              </div>
              {/* MBTI */}
              <div className="input-box">
                <label>MBTI</label>
                <div className="wrap">
                  <select
                    value={profileParams.mbtiCode}
                    onChange={(evt) =>
                      onChangeInput("mbtiCode", evt.target.value)
                    }>
                    <option value="" selected={!profileParams.mbtiCode}>
                      {getLanguageValue(
                        PAGE_TYPE.STUDENT,
                        "my.txtMbtiOptional"
                      )}
                    </option>
                    <option value="">
                      {getLanguageValue(
                        PAGE_TYPE.STUDENT,
                        "my.txtNotSetOptional"
                      )}
                    </option>
                    {commonData
                      .getMbtiCodeList()
                      .map((item: any, idx: number) => (
                        <option
                          key={idx}
                          value={item.code}
                          selected={profileParams.mbtiCode === item.code}>
                          {item.names.en}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              {/* 특기 */}
              <div className="input-box input-checkbox">
                <label>
                  {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtSpecialty")}
                </label>
                <form>
                  {commonData
                    .getCommonCodeListByParent("specialty")
                    .map((item: any) => (
                      <div className="check-box">
                        <input
                          type="checkbox"
                          id={item.names.en.toLowerCase()}
                          value={item.code}
                          onChange={(evt) =>
                            onChangeCheckbox(item.names.en.toLowerCase(), evt)
                          }
                          checked={hasChecked[item.names.en.toLowerCase()]}
                        />
                        <span>
                          {getLanguageValue(
                            PAGE_TYPE.STUDENT,
                            `my.txt${item.names.en}`
                          )}
                        </span>
                      </div>
                    ))}
                </form>
              </div>
              {/* 혈액형 */}
              <div className="input-box input-radio">
                <label>
                  {getLanguageValue(PAGE_TYPE.STUDENT, "my.txtBloodType")}
                </label>
                <form>
                  <div className="radio-box">
                    <input
                      type="radio"
                      id="skip"
                      value=""
                      onChange={(evt) =>
                        onChangeInput("bloodTypeCode", evt.target.value)
                      }
                      checked={!profileParams.bloodTypeCode}
                    />
                    <label>SIKP</label>
                  </div>
                  {commonData
                    .getCommonCodeListByParent("bloodType")
                    .map((item: any) => (
                      <>
                        <div className="radio-box">
                          <input
                            type="radio"
                            id={item.names.en.toLowerCase()}
                            value={item.code}
                            onChange={(evt) =>
                              onChangeInput("bloodTypeCode", evt.target.value)
                            }
                            checked={profileParams.bloodTypeCode === item.code}
                          />
                          <label>{item.names.en}</label>
                        </div>
                      </>
                    ))}
                </form>
              </div>
              <div className="input-box">
                <label>SLUG</label>
                <div className="input-prefix">
                  <div className="prefix">
                    <p>{process.env.REACT_APP_DOMAIN}/wannabe/info/</p>
                  </div>
                  <input
                    type="text"
                    placeholder=""
                    value={profileParams.slug}
                    onChange={(evt) => onChangeInput("slug", evt.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="btn">
              <div className="cancen-btn">
                <button onClick={(evt) => onClickBtnCancelUpdate()}>
                  {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnCancel")}
                </button>
              </div>
              <div className="update-btn">
                <button
                  onClick={(evt) => onClickBtnUpdateInfo()}
                  disabled={isOnNetworking}>
                  {getLanguageValue(PAGE_TYPE.STUDENT, "my.btnUpdate")}
                </button>
              </div>
            </div>
          </>
        ) : (
          <LoadingComponent />
        )}
      </div>
    </main>
  );
};

export default UpdateMyComponent;
