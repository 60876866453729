import React, { useEffect, useState } from "react";
import PartnerComponent from "./Partner.component";
import { getPartnerList } from "api/landing/Landing.api";

const PartnerContainer = (props: any) => {
  const [schoolList, setSchoolList] = useState([]);
  const [agencyList, setAgencyList] = useState([]);
  const getListValue = async () => {
    const result = await getPartnerList();

    if (result) {
      setSchoolList(result?.schoolObjList);
      setAgencyList(result?.agencyObjList);
    }
    console.log(result);
  };

  useEffect(() => {
    getListValue();
  }, []);

  return (
    <PartnerComponent
      {...props}
      schoolList={schoolList}
      agencyList={agencyList}
    />
  );
};

export default PartnerContainer;
