export const getImageInfo = async (fileObj: any): Promise<any | null> => {
    console.log("fileObj", fileObj);

    const result = new Promise((resolve, reject) => {
        var reader = new FileReader();
        try {
            reader.readAsDataURL(fileObj);

            reader.onload = (evt: any) => {
                var img = new Image();
                img.src = evt.target.result;

                img.onload = (e: any) => {
                    console.log(e);
                    console.log(img);
                    console.log(img.width, img.height);

                    resolve({
                        name: fileObj.name,
                        size: fileObj.size,
                        url: evt.target.result,
                        width: img.width,
                        height: img.height,
                    });
                };

                img.onerror = (err) => {
                    console.error(err);
                    resolve(null);
                };
            };
        } catch {
            console.log("image error!")
        }
    });

    return result;
};

export const getImageInfoByUrl = async (url: string): Promise<any | null> => {
    const result = new Promise((resolve, reject) => {
        var img = new Image();
        img.src = url;

        img.onload = (e: any) => {
            console.log(e);
            console.log(img);
            console.log(img.width, img.height);

            resolve({
                url: url,
                width: img.width,
                height: img.height,
            });
        };

        img.onerror = (err) => {
            console.error(err);
            resolve(null);
        };
    });

    return result;
};
