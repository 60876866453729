import React from "react";

import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import Pagination from "../../../commons/pagination/Pagination.container";
import TaggedStudentListPopup from "./TaggedStudentListPopup";
import LoadingComponent from "commons/loading/Loading.component";

const PortfolioComponent = (props: any) => {
  const {
    isLoading,
    isSearched,
    portfolioList,
    go,
    // search keyword
    keyword,
    setKeyword,
    searchKeyword,
    // pagination
    limit,
    page,
    setPage,
    totalCount,
    // popup
    isShowTaggedStudentPopup,
    setIsShowTaggedStudentPopup,
    taggedStudentPopupInfo,
    setTaggedStudentPopupInfo,
  } = props;

  return (
    <main className="">
      <div className="portfolio-list">
        <div className="wrap">
          <div className="portfolio-wrap">
            <h2>PORTFOLIO LIST</h2>
            <div className="input-box">
              <div className="input-wrap">
                <input
                  type="text"
                  onChange={(evt) => {
                    setKeyword(evt.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      searchKeyword(keyword);
                    }
                  }}
                  value={keyword}
                  placeholder={getLanguageValue(
                    PAGE_TYPE.SCHOOL,
                    "portfolio.txtSearchingKeyword"
                  )}
                />
                <div className="btn-search-icon" style={{ cursor: "pointer" }}>
                  <img
                    src="./imgs/icons/icon-search.png"
                    onClick={(evt) => {
                      searchKeyword(keyword);
                    }}
                    alt="검색"
                  />
                </div>
              </div>
            </div>
            <div className="btn">
              <button
                className="new-btn"
                onClick={(evt) => go("/school/portfolio/new")}>
                {`${getLanguageValue(
                  PAGE_TYPE.SCHOOL,
                  "portfolio.btnNewPortfolio"
                )}+`}
              </button>
            </div>
            {!isLoading ? (
              <ul className="new-portfolio-list-wrap">
                {portfolioList.length > 0 ? (
                  portfolioList.map((item: any, idx: number) => (
                    <li key={`portfolio-${idx}`} className="new-portfolio-list">
                      <div className="portfolio portfolio-1">
                        <div className="con">
                          <p className="portfolio-date">
                            {item.performedDate.replaceAll("-", ".")}
                          </p>
                        </div>
                        <div className="portfolio-img">
                          <a
                            onClick={(evt) =>
                              go(`/school/portfolio/${item.id}`)
                            }>
                            <div className="thumbnail">
                              <button className="play-btn"></button>
                              <img
                                src={`https://img.youtube.com/vi/${item.youtubeId}/maxresdefault.jpg`}
                                alt="portfolio thumbnail"
                                style={{ objectFit: "cover" }}
                              />
                              <p className="ellipsis">{item.title}</p>
                            </div>
                          </a>
                          <div className="student-list">
                            <div className="student-img">
                              <ul
                                onClick={(evt) => {
                                  setTaggedStudentPopupInfo(item);
                                  setIsShowTaggedStudentPopup(true);
                                }}
                                style={{
                                  width: "fit-content",
                                  cursor: "pointer",
                                }}>
                                {item.studentInfoList?.length > 0 &&
                                  item.studentInfoList
                                    .filter((_: any, idx: number) => idx < 4)
                                    .map((i: any, key: number) => (
                                      <li key={`student-${idx}-${key}`}>
                                        <img
                                          src={`${
                                            i.imageUrl
                                              ? i.imageUrl
                                              : "imgs/dummy/basic-img-2.png"
                                          }?w=90&h=90`}
                                          alt="Participating students"
                                        />
                                      </li>
                                    ))}
                                {item.studentInfoList.length > 4 ? (
                                  <li className="over">
                                    <img
                                      src="./imgs/student/home/over.png"
                                      alt=""
                                    />
                                    <span>{`+${
                                      item.studentInfoList.length - 4
                                    }`}</span>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <div className="no-filter">
                    <p>
                      {!isSearched
                        ? getLanguageValue(
                            PAGE_TYPE.SCHOOL,
                            "portfolio.txtNoPortfolio"
                          )
                        : getLanguageValue(
                            PAGE_TYPE.SCHOOL,
                            "portfolio.txtNoSearch"
                          )}
                    </p>
                  </div>
                )}
              </ul>
            ) : (
              <LoadingComponent />
            )}
          </div>
        </div>
      </div>

      {/* 페이지네이션 */}
      {!isLoading ? (
        <Pagination
          url="/school/portfolio"
          totalCount={totalCount}
          currentPage={page}
          pageSize={limit}
          pageGroupSize={10}
          setPage={setPage}
        />
      ) : null}

      {/* 태그된 학생 팝업 */}
      {isShowTaggedStudentPopup ? (
        <TaggedStudentListPopup
          info={taggedStudentPopupInfo}
          setIsShowTaggedStudentPopup={setIsShowTaggedStudentPopup}
        />
      ) : null}
    </main>
  );
};
export default PortfolioComponent;
