import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const VerifyPasswordEmailDoneComponent = (props: any) => {
  const navigate = useNavigate();
  return (
    <main className="find-password-success">
      <div className="con">
        <div className="form form-2">
          <p>
            {getLanguageValue(
              PAGE_TYPE.COMMON,
              "verifyPasswordEmailDone.txtPasswordResetSuccess"
            )}
          </p>
        </div>

        <div className="btn">
          <div>
            <button
              onClick={(e) => {
                navigate("/login");
              }}>
              {getLanguageValue(
                PAGE_TYPE.COMMON,
                "verifyPasswordEmailDone.btnLogin"
              )}
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};
export default VerifyPasswordEmailDoneComponent;
