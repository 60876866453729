import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import * as PortfolioApi from "api/school/Portfolio.api";

import PortfolioDetailComponent from "./PortfolioDetail.component";

const PortfolioDetailContainer = (props: any) => {
  const navigate = useNavigate();
  const page = useLocation().state;
  const { id } = useParams();

  const [portfolioInfo, setPortfolioInfo] = useState();
  const [hideTitle, setHideTitle] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const getInfo = async () => {
    const result = await PortfolioApi.getInfo(+id);

    if (result?.info) {
      setPortfolioInfo(result.info);
    }
    setIsLoading(false);
  };

  const deleteInfo = async () => {
    const confirm = window.confirm(
      getLanguageValue(PAGE_TYPE.SCHOOL, "portfolio.confirmDelete")
    );

    if (confirm) {
      const result = await PortfolioApi.deleteInfo(+id);

      if (result) {
        result.data?.errorCode
          ? alert(result.data.message)
          : navigate("/school/portfolio", { state: page });
      }
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <PortfolioDetailComponent
      isLoading={isLoading}
      portfolioInfo={portfolioInfo}
      onClickBtnDelete={deleteInfo}
      hideTitle={hideTitle}
      setHideTitle={setHideTitle}
    />
  );
};

export default PortfolioDetailContainer;
