// i18n.ts
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// 다국어 리소스 파일 import
import translationEN from "./en.json";
import translationKO from "./ko.json";
import translationJA from "./ja.json";
import translationTH from "./th.json";
import translationVI from "./vi.json";
import translationID from "./id.json";

// 다국어 리소스 설정
const resources = {
  en: {
    translation: translationEN,
  },
  ko: {
    translation: translationKO,
  },
  ja: {
    translation: translationJA,
  },
  th: {
    translation: translationTH,
  },
  vi: {
    translation: translationVI,
  },
  id: {
    translation: translationID,
  },
};

// 브라우저의 언어 설정 가져오기
const browserLanguage = navigator.language.split("-")[0];

const lng = () => {
  // 에이전시 화면의 경우 언어 설정이 한글일 경우 제외 영문 처리
  const type = window.location.pathname.split("/")[1];
  const lng =
    type === "agency" && browserLanguage !== "ko" ? "en" : browserLanguage;

  return lng;
};

i18n
  .use(initReactI18next) // 리액트 연동
  .init({
    resources,
    lng: lng(), // 기본 언어 설정
    fallbackLng: "en", // 번역이 없는 경우에 대비한 언어 설정
    interpolation: {
      escapeValue: false,
    },
  });

// 현재 언어 설정 가져오기
export const getCurrentLanguage = () =>
  i18n.language in resources ? i18n.language : "en";

// 언어 설정 값 가져오기
export const getLanguageValue = (type: string, key: string) => {
  if (!(type && key)) {
    return "";
  }

  const keys: string[] = key.split(".");

  const langObj = i18n.t(type, { returnObjects: true });

  let result: any = langObj;

  try {
    for (const keyValue of keys) {
      result = result[keyValue];
    }
  } catch (e) {
    return "";
  }

  return result;
};

export default i18n;
