import React from "react";
import { useNavigate } from "react-router";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import { getCurrentLangName } from "helpers/String.helper";
import { getFormattedDateValue, addDate } from "helpers/Datetime.helper";

import LoadingComponent from "commons/loading/Loading.component";
import LineChartComponent from "commons/chart/LineChart.component";

const HomeComponent = (props: any) => {
  const navigate = useNavigate();
  const {
    studentList,
    isLoading,
    date,
    setDate,
    dataset,
    favoriteCntInfo,
    viewCntInfo,
  } = props;

  return (
    <main className="">
      <div className="school-home">
        <div className="popular-student">
          <div className="title">
            <h2>MOST POPULAR STUDENT</h2>
            <div className="tip">
              <img
                src="./imgs/icons/info-icon.png"
                style={{ cursor: "pointer" }}
                alt="information"
              />
              <div className="tooltip">
                {getLanguageValue(PAGE_TYPE.SCHOOL, "home.favoriteTooltip")}
              </div>
            </div>
          </div>
          {isLoading ? (
            <LoadingComponent />
          ) : (
            <ul>
              {studentList.mostFavoriteList.length > 0 ? (
                studentList.mostFavoriteList.map((item: any, idx: number) => (
                  <li className="" key={`most-favorite-${idx}`}>
                    <div className={`rank${idx === 0 ? " ranking-1" : ""}`}>
                      <span>{item.rank}</span>
                    </div>
                    {item.todayCnt ? (
                      <div className="score-wrap">
                        <p className="score">{item.todayCnt}</p>
                      </div>
                    ) : null}
                    {item.rankChange > 0 ? (
                      <div className="score-ud">
                        <p className={item.rankChange > 0 ? "rise" : "fall"}>
                          <img
                            src={
                              item.rankChange > 0
                                ? "./imgs/icons/arrow-green.png"
                                : "./imgs/icons/arrow-red.png"
                            }
                            alt={`a${item.rankChange > 0 ? " rise" : " fall"}`}
                          />
                          {Math.abs(item.rankChange)}
                        </p>
                      </div>
                    ) : null}
                    <a
                      className="item"
                      onClick={(e: any) => {
                        e.preventDefault();
                        navigate(`/school/student/${item.id}`);
                      }}>
                      <div className="favorite">
                        <img
                          src="./imgs/icons/favorite-icon.png"
                          alt="favorite"
                        />
                        {item.totalCnt}
                      </div>
                      <div
                        className="img"
                        style={{
                          backgroundImage: `url("${
                            item?.profileImageUrl
                              ? item.profileImageUrl + "?w=400&h=700"
                              : "./imgs/dummy/basic-img.png"
                          }")`,
                        }}></div>
                      <div className="student-info">
                        <p className="nationalty ellipsis">
                          {getCurrentLangName(item?.countryCodeInfo?.names)}
                        </p>
                        <p className="name ellipsis">
                          {item.firstName} {item.lastName}
                        </p>
                        <p className="gender ellipsis">
                          {getCurrentLangName(item?.genderTypeCodeInfo?.names)}
                        </p>
                        <p className="birthday ellipsis">
                          {getFormattedDateValue(item?.birthday, "YYYY.MM.DD")}
                        </p>
                      </div>
                    </a>
                  </li>
                ))
              ) : (
                <div className="no-filter">
                  <p>
                    {getLanguageValue(
                      PAGE_TYPE.SCHOOL,
                      "home.noFavoritedStudents"
                    )}
                  </p>
                </div>
              )}
            </ul>
          )}
        </div>
        <div className="interest-student">
          <div className="title">
            <h2>MOST STUDENT OF INTEREST</h2>
            <div className="tip">
              <img
                src="./imgs/icons/info-icon.png"
                style={{ cursor: "pointer" }}
                alt="information"
              />
              <div className="tooltip">
                {getLanguageValue(PAGE_TYPE.SCHOOL, "home.viewTooltip")}
              </div>
            </div>
          </div>
          {isLoading ? (
            <LoadingComponent />
          ) : (
            <ul>
              {studentList.mostViewList.length > 0 ? (
                studentList.mostViewList.map((item: any, idx: number) => (
                  <li className="" key={`most-view-${idx}`}>
                    <div className={`rank${idx === 0 ? " ranking-1" : ""}`}>
                      <span>{item.rank}</span>
                    </div>
                    {item.todayCnt ? (
                      <div className="score-wrap">
                        <p className="score">{item.todayCnt}</p>
                      </div>
                    ) : null}
                    {item.rankChange !== 0 ? (
                      <div className="score-ud">
                        <p className={item.rankChange > 0 ? "rise" : "fall"}>
                          <img
                            src={
                              item.rankChange > 0
                                ? "./imgs/icons/arrow-green.png"
                                : "./imgs/icons/arrow-red.png"
                            }
                            alt={`a${item.rankChange > 0 ? " rise" : " fall"}`}
                          />
                          {Math.abs(item.rankChange)}
                        </p>
                      </div>
                    ) : null}
                    <a
                      className="item"
                      onClick={(e: any) => {
                        e.preventDefault();
                        navigate(`/school/student/${item.id}`);
                      }}>
                      <div className="view">
                        <img src="./imgs/icons/view-icon.png" alt="view" />
                        {item?.totalCnt}
                      </div>
                      <div
                        className="img"
                        style={{
                          backgroundImage: `url("${
                            item?.profileImageUrl
                              ? item.profileImageUrl + "?w=400&h=700"
                              : "./imgs/dummy/basic-img.png"
                          }")`,
                        }}></div>
                      <div className="student-info">
                        <p className="nationalty ellipsis">
                          {getCurrentLangName(item?.countryCodeInfo?.names)}
                        </p>
                        <p className="name ellipsis">
                          {item.firstName} {item.lastName}
                        </p>
                        <p className="gender ellipsis">
                          {getCurrentLangName(item?.genderTypeCodeInfo?.names)}
                        </p>
                        <p className="birthday ellipsis">
                          {getFormattedDateValue(item?.birthday, "YYYY.MM.DD")}
                        </p>
                      </div>
                    </a>
                  </li>
                ))
              ) : (
                <div className="no-filter">
                  <p>
                    {getLanguageValue(
                      PAGE_TYPE.SCHOOL,
                      "home.noStudentsViewed"
                    )}
                  </p>
                </div>
              )}
            </ul>
          )}
        </div>
        <section className="evaluation">
          <ul>
            <li>
              <div className="evaluation-graph">
                <div className="header">
                  <div className="title">
                    <p>WEEKLY FAVORITE COUNT</p>
                    <div className="tip">
                      <img
                        src="./imgs/icons/info-icon.png"
                        style={{ cursor: "pointer" }}
                        alt="information"
                      />
                      <div className="tooltip">
                        {getLanguageValue(
                          PAGE_TYPE.SCHOOL,
                          "home.favoriteGraphTooltip"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="control">
                  <input
                    type="date"
                    value={date.favorite}
                    onChange={(evt) =>
                      setDate({ ...date, favorite: evt.target.value })
                    }
                    id="currentDate"
                  />
                  <div>
                    <div className="one-week">
                      <img src="./imgs/icons/solid-line.png" alt="solid line" />
                      <span>{`${addDate(
                        -6,
                        date.favorite,
                        "M/D"
                      )} ~ ${getFormattedDateValue(
                        date.favorite,
                        "M/D"
                      )}`}</span>
                    </div>
                    <div className="last-week">
                      <img
                        src="./imgs/icons/dotted-line.png"
                        alt="dotted line"
                      />
                      <span>{`${addDate(-13, date.favorite, "M/D")} ~ ${addDate(
                        -7,
                        date.favorite,
                        "M/D"
                      )}`}</span>
                    </div>
                  </div>
                </div>
                {favoriteCntInfo?.isLoading ? (
                  <LoadingComponent />
                ) : (
                  <div className="body">
                    <LineChartComponent
                      labels={favoriteCntInfo.labels}
                      datasets={[
                        dataset(
                          favoriteCntInfo.data.week,
                          "#CF3688",
                          "#CF3688"
                        ),
                        dataset(
                          favoriteCntInfo.data.lastWeek,
                          "#AFAFAF",
                          "#AFAFAF",
                          [5, 5]
                        ),
                      ]}
                      min={0}
                      max={favoriteCntInfo.max}
                      stepSize={favoriteCntInfo.max / 4}
                    />
                  </div>
                )}
              </div>
            </li>

            <li>
              <div className="evaluation-graph">
                <div className="header">
                  <div className="title">
                    <p>WEEKLY TOTAL VIEW COUNT</p>
                    <div className="tip">
                      <img
                        src="./imgs/icons/info-icon.png"
                        style={{ cursor: "pointer" }}
                        alt="information"
                      />
                      <div className="tooltip">
                        {getLanguageValue(
                          PAGE_TYPE.SCHOOL,
                          "home.viewGraphTooltip"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="control">
                  <input
                    type="date"
                    value={date.view}
                    onChange={(evt) =>
                      setDate({ ...date, view: evt.target.value })
                    }
                    id="currentDate"
                  />
                  <div>
                    <div className="one-week">
                      <img src="./imgs/icons/solid-line.png" alt="solid line" />
                      <span>{`${addDate(
                        -6,
                        date.view,
                        "M/D"
                      )} ~ ${getFormattedDateValue(date.view, "M/D")}`}</span>
                    </div>
                    <div className="last-week">
                      <img
                        src="./imgs/icons/dotted-line.png"
                        alt="dotted line"
                      />
                      <span>{`${addDate(-13, date.view, "M/D")} ~ ${addDate(
                        -7,
                        date.view,
                        "M/D"
                      )}`}</span>
                    </div>
                  </div>
                </div>
                {viewCntInfo?.isLoading ? (
                  <LoadingComponent />
                ) : (
                  <div className="body">
                    <LineChartComponent
                      labels={viewCntInfo.labels}
                      datasets={[
                        dataset(viewCntInfo.data.week, "#CF3688", "#CF3688"),
                        dataset(
                          viewCntInfo.data.lastWeek,
                          "#AFAFAF",
                          "#AFAFAF",
                          [5, 5]
                        ),
                      ]}
                      min={0}
                      max={viewCntInfo.max}
                      stepSize={viewCntInfo.max / 4}
                    />
                  </div>
                )}
              </div>
            </li>
          </ul>
        </section>
      </div>
    </main>
  );
};

export default HomeComponent;
