import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import * as commonData from "../../../data/common.data";
import { getCurrentLangName } from "helpers/String.helper";

const StudentDirectRegisterComponent = (props: any) => {
  const {
    id,
    btnRef,
    accountParams,
    studentParams,
    termList,
    imageInfo,
    imageInfoList,
    isOnNetworking,
    uploadImage,
    onClickBtnDeleteAdditionalImage,
    onChangeInput,
    onChangeCheckbox,
    onClickBtnCancelSignup,
    onClickBtnUpdate,
    onClickBtnSignup,
    hasCheckedSpecialty,
  } = props;

  return (
    <main className="signup-form student-signup">
      <div className="con">
        <h2>
          {id
            ? `${studentParams.firstName} ${studentParams.lastName}`
            : "NEW STUDENT"}
        </h2>
        {/* 계정 정보 */}
        <div className="form account">
          <div className="title">
            <h3>ESSENTIAL INFORMATION</h3>
            <p>
              {getLanguageValue(
                PAGE_TYPE.COMMON,
                "studentSignup.txtEssentialInfoSubTitle"
              )}
            </p>
          </div>
          <div className="input-box">
            <label>*Email</label>
            <input
              type="text"
              onChange={(evt) => onChangeInput("username", evt.target.value)}
              value={accountParams.username}
              placeholder="email"
              readOnly={id ? true : false}
            />
          </div>
          <div className="input-box">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "studentSignup.txtCountry"
            )}`}</label>
            <select
              onChange={(evt) => onChangeInput("countryCode", evt.target.value)}
              value={studentParams.countryCode}
              disabled={id ? true : false}>
              <option value="" disabled selected>
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "studentSignup.txtCountryOptional"
                )}
              </option>
              {commonData.getCountryCodeList().map((item: any, idx: number) => (
                <option key={idx} value={item.code}>
                  {getCurrentLangName(item.names)}
                </option>
              ))}
            </select>
          </div>
          <div className="input-box">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "studentSignup.txtFirstName"
            )}`}</label>
            <input
              type="text"
              onChange={(evt) => onChangeInput("firstName", evt.target.value)}
              value={studentParams.firstName}
              placeholder=""
              readOnly={id ? true : false}
            />
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "studentSignup.txtLastName"
            )}`}</label>
            <input
              type="text"
              onChange={(evt) => onChangeInput("lastName", evt.target.value)}
              value={studentParams.lastName}
              placeholder=""
              readOnly={id ? true : false}
            />
          </div>
          <div className="input-box">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "studentSignup.txtBirthday"
            )}`}</label>
            <input
              type="date"
              onChange={(evt) => onChangeInput("birthday", evt.target.value)}
              value={studentParams.birthday}
              data-placeholder="YYYY-MM-DD"
              aria-required="true"
              readOnly={id ? true : false}
            />
          </div>
          <div className="input-box input-radio">
            <label>{`*${getLanguageValue(
              PAGE_TYPE.COMMON,
              "studentSignup.txtGender"
            )}`}</label>
            <form>
              <div className="radio-box">
                <input
                  type="radio"
                  id="girl"
                  name="genderTypeCode"
                  onChange={(evt) =>
                    onChangeInput("genderTypeCode", evt.target.value)
                  }
                  value="genderType:girl"
                  checked={studentParams.genderTypeCode === "genderType:girl"}
                  disabled={id ? true : false}
                />
                <label htmlFor="girl">Girl</label>
              </div>
              <div className="radio-box">
                <input
                  type="radio"
                  id="boy"
                  name="genderTypeCode"
                  onChange={(evt) =>
                    onChangeInput("genderTypeCode", evt.target.value)
                  }
                  value="genderType:boy"
                  checked={studentParams.genderTypeCode === "genderType:boy"}
                  disabled={id ? true : false}
                />
                <label htmlFor="boy">Boy</label>
              </div>
            </form>
          </div>
        </div>
        {/* 추가 정보 */}
        <div className="form">
          <div className="title">
            <h3>ADDITIONAL INFORMATION</h3>
            <p>
              {getLanguageValue(
                PAGE_TYPE.COMMON,
                "studentSignup.txtAdditionalInfoSubTitle"
              )}
            </p>
          </div>
          <div className="input-box">
            <label>
              {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtNickname")}
            </label>
            <input
              type="text"
              onChange={(evt) => onChangeInput("nickname", evt.target.value)}
              value={studentParams.nickname}
              placeholder=""
            />
          </div>
          <div className="input-box">
            <label>
              {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtHeight")}
            </label>
            <input
              type="text"
              onChange={(evt) => onChangeInput("height", evt.target.value)}
              value={studentParams.height}
              placeholder=""
            />
          </div>
          <div className="input-box">
            <label>
              {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtWeight")}
            </label>
            <input
              type="text"
              onChange={(evt) => onChangeInput("weight", evt.target.value)}
              value={studentParams.weight}
              placeholder=""
            />
          </div>
          <div className="input-box">
            <label>
              {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtShoeSize")}
            </label>
            <input
              type="text"
              onChange={(evt) => onChangeInput("shoeSize", evt.target.value)}
              value={studentParams.shoeSize}
              placeholder=""
            />
          </div>
          <div className="input-box">
            <label>MBTI</label>
            <select
              onChange={(evt) => onChangeInput("mbtiCode", evt.target.value)}
              value={studentParams.mbtiCode}>
              <option value="" disabled selected>
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "studentSignup.txtMbtiOptional"
                )}
              </option>
              <option value="">
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "studentSignup.txtNotSetOptional"
                )}
              </option>
              {commonData.getMbtiCodeList().map((item: any, idx: number) => (
                <option key={idx} value={item.code}>
                  {item.names.en}
                </option>
              ))}
            </select>
          </div>
          <div className="input-box input-checkbox">
            <label>
              {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtSpecialty")}
            </label>
            <form>
              <div className="check-box">
                <input
                  type="checkbox"
                  id="vocal"
                  onChange={(evt) =>
                    onChangeCheckbox("vocal", null, evt.target.checked)
                  }
                  value="specialty:vocal"
                  checked={
                    hasCheckedSpecialty && hasCheckedSpecialty.vocal === true
                  }
                />
                <label htmlFor="vocal">VOCAL</label>
              </div>
              <div className="check-box">
                <input
                  type="checkbox"
                  id="dance"
                  onChange={(evt) =>
                    onChangeCheckbox("dance", null, evt.target.checked)
                  }
                  value="specialty:dance"
                  checked={
                    hasCheckedSpecialty && hasCheckedSpecialty.dance === true
                  }
                />
                <label htmlFor="dance">DANCE</label>
              </div>
              <div className="check-box">
                <input
                  type="checkbox"
                  id="rap"
                  onChange={(evt) =>
                    onChangeCheckbox("rap", null, evt.target.checked)
                  }
                  value="specialty:rap"
                  checked={
                    hasCheckedSpecialty && hasCheckedSpecialty.rap === true
                  }
                />
                <label htmlFor="rap">RAP</label>
              </div>
            </form>
          </div>
          <div className="input-box input-radio">
            <label>
              {getLanguageValue(PAGE_TYPE.COMMON, "studentSignup.txtBloodType")}
            </label>
            <form>
              <div className="radio-box">
                <input
                  type="radio"
                  name="bloodType"
                  id="skip"
                  onChange={(evt) => onChangeInput("bloodTypeCode", null)}
                />
                <label htmlFor="skip">SIKP</label>
              </div>
              <div className="radio-box">
                <input
                  type="radio"
                  name="bloodType"
                  id="a"
                  onChange={(evt) =>
                    onChangeInput("bloodTypeCode", evt.target.value)
                  }
                  value="bloodType:a"
                  checked={studentParams.bloodTypeCode === "bloodType:a"}
                />
                <label htmlFor="a">A</label>
              </div>
              <div className="radio-box">
                <input
                  type="radio"
                  name="bloodType"
                  id="b"
                  onChange={(evt) =>
                    onChangeInput("bloodTypeCode", evt.target.value)
                  }
                  value="bloodType:b"
                  checked={studentParams.bloodTypeCode === "bloodType:b"}
                />
                <label htmlFor="b">B</label>
              </div>
              <div className="radio-box">
                <input
                  type="radio"
                  name="bloodType"
                  id="o"
                  onChange={(evt) =>
                    onChangeInput("bloodTypeCode", evt.target.value)
                  }
                  value="bloodType:o"
                  checked={studentParams.bloodTypeCode === "bloodType:o"}
                />
                <label htmlFor="o">O</label>
              </div>
              <div className="radio-box">
                <input
                  type="radio"
                  name="bloodType"
                  id="ab"
                  onChange={(evt) =>
                    onChangeInput("bloodTypeCode", evt.target.value)
                  }
                  value="bloodType:ab"
                  checked={studentParams.bloodTypeCode === "bloodType:ab"}
                />
                <label htmlFor="ab">AB</label>
              </div>
            </form>
          </div>
        </div>
        {/* 프로필 사진 */}
        <div className="form">
          <div className="title gap">
            <h3>PROFILE</h3>
          </div>
          <div className="input-box">
            <div className="img-upload">
              <div className="img-wrap">
                <div
                  className={
                    imageInfo?.url ? "casting-manager-img img-upload" : "img"
                  }
                  style={{
                    backgroundImage: `url(${
                      imageInfo?.url + "?w=556&h=556" ||
                      "./../../imgs/icons/camera.png"
                    })`,
                  }}
                />
              </div>
              <p>
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "studentSignup.txtImageUpload"
                )}
              </p>
              <input
                type="file"
                id="input-file-img-profile"
                onChange={(evt) => uploadImage("profile", evt)}
                accept="image/*"
              />
              <label htmlFor="input-file-img-profile">
                {getLanguageValue(
                  PAGE_TYPE.COMMON,
                  "studentSignup.btnUploadFile"
                )}
              </label>
            </div>
          </div>
        </div>
        {/* 추가 사진 */}
        <div className="form artists">
          <div className="title">
            <h3>ADDITIONAL PHOTO</h3>
          </div>
          <div className="input-box artist-img">
            {imageInfoList.length > 0 &&
              imageInfoList.map((item: any, idx: number) => (
                <div key={`image-${idx}`} className="img-upload">
                  <div
                    className="add"
                    style={{ backgroundImage: `url(${item.url}?w=460&h=460)` }}>
                    <div
                      className="close-btn"
                      onClick={(evt) =>
                        onClickBtnDeleteAdditionalImage(idx)
                      }></div>
                  </div>
                </div>
              ))}
            <div className="img-upload">
              <input
                type="file"
                id="input-file-img-addition"
                onChange={(evt) => uploadImage("addition", evt)}
                accept="image/*"
              />
              <label htmlFor="input-file-img-addition">
                <div className="img-add"></div>
              </label>
            </div>
          </div>
        </div>
        {id ? null : (
          <>
            <div className="form">
              <div className="title gap">
                <h3>ENTER YOUR FAVORITES SLUG</h3>
                <p>
                  {getLanguageValue(
                    PAGE_TYPE.COMMON,
                    "studentSignup.txtSlugSubTitle"
                  )}
                </p>
              </div>
              <div className="input-box">
                <label>SLUG</label>
                <input
                  type="text"
                  onChange={(evt) => onChangeInput("slug", evt.target.value)}
                  value={studentParams.slug}
                  placeholder=""
                />
                <p className="slug-message">
                  {getLanguageValue(
                    PAGE_TYPE.COMMON,
                    "studentSignup.txtSlugDesc"
                  )}
                </p>
              </div>
            </div>
            <div className="form">
              <div className="input-box agreement-form">
                <ul className="list">
                  {termList.length > 0 &&
                    termList.map((item: any, idx: number) => (
                      <li key={`term-${idx}`} className="item">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            onChange={(evt) => {
                              onChangeCheckbox(null, idx, evt.target.checked);
                            }}
                            checked={item.checked}
                          />
                          {item.isRequired === 1 ? (
                            <span className="txt">{`(${getLanguageValue(
                              PAGE_TYPE.COMMON,
                              "studentSignup.txtRequired"
                            )}) ${item.title}`}</span>
                          ) : (
                            <span className="txt">{`(${getLanguageValue(
                              PAGE_TYPE.COMMON,
                              "studentSignup.txtOptional"
                            )}) ${item.title}`}</span>
                          )}
                        </label>
                        {idx !== 0 ? (
                          <button
                            type="button"
                            className="btn"
                            onClick={(evt) =>
                              window.open(
                                `${process.env.REACT_APP_DOMAIN}${item.link}${item.id}`
                              )
                            }>{`(${getLanguageValue(
                            PAGE_TYPE.COMMON,
                            "studentSignup.txtShow"
                          )})`}</button>
                        ) : null}
                      </li>
                    ))}
                </ul>
                <div className="all-check">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      className="select-all"
                      onChange={(evt) => {
                        onChangeCheckbox(null, "all", evt.target.checked);
                      }}
                      checked={
                        termList && termList.length > 0
                          ? !termList.find((i: any) => !i.checked)
                          : false
                      }
                    />
                    <span className="txt">
                      {getLanguageValue(
                        PAGE_TYPE.COMMON,
                        "studentSignup.txtAgreeWithAllTerms"
                      )}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="btn">
          <button
            className="cancel-btn"
            onClick={(evt) => onClickBtnCancelSignup()}>
            CANCEL
          </button>
          {id ? (
            <button
              className="signup-btn"
              ref={btnRef}
              onClick={(evt) => onClickBtnUpdate()}
              disabled={isOnNetworking}>
              UPDATE
            </button>
          ) : (
            <button
              className="signup-btn"
              ref={btnRef}
              onClick={(evt) => onClickBtnSignup()}
              disabled={isOnNetworking}>
              SIGNUP
            </button>
          )}
        </div>
      </div>
    </main>
  );
};
export default StudentDirectRegisterComponent;
