import React from "react";
import { useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import LoadingComponent from "commons/loading/Loading.component";
import Pagination from "commons/pagination/Pagination.container";
import { formatTimeAgo, getFormattedDateValue } from "helpers/Datetime.helper";
import { getUserType } from "helpers/Common.helper";
import { useSelector } from "react-redux";

const NoticeComponent = (props: any) => {
    const {
        isLoading,
        importantList,
        noticeList,
        go,
        // pagination
        limit,
        page,
        setPage,
        totalCount,
        //
        onClickCheckNotification,
    } = props;

    const navigate = useNavigate();
    const { info: userInfo } = useSelector((state: any) => state.user);

    const noticePath = `/${getUserType(userInfo?.accountTypeCode)}/notice`;
    const goDetailPage = (id: number) => {
        navigate(`${noticePath}/${id}`, {
            state: {
                page,
            },
        });
    };

    return (
        <main className="all-view-notice">
            {isLoading ? (
                <LoadingComponent />
            ) : noticeList && noticeList.length === 0 ? (
                <>
                    <h2>{getLanguageValue(PAGE_TYPE.COMMON, "notice.txtMenuTitle")}</h2>
                    <div className="no-data">
                        <p>No data</p>
                    </div>
                </>
            ) : (
                <>
                    <h2>{getLanguageValue(PAGE_TYPE.COMMON, "notice.txtMenuTitle")}</h2>
                    <div className="table">
                        {/* <!-- pc --> */}
                        <div className="pc">
                            <table>
                                <thead>
                                    <tr>
                                        <td className="alarm">
                                            <p>{getLanguageValue(PAGE_TYPE.COMMON, "notice.txtTitle")}</p>
                                        </td>
                                        <td className="date-time">
                                            <p>{getLanguageValue(PAGE_TYPE.COMMON, "notice.txtCreatedAt")}</p>
                                        </td>
                                    </tr>
                                </thead>

                                <tbody>
                                    {importantList.map((notice: any) => (
                                        <tr key={notice.id}>
                                            <td className="alarm important">
                                                <p
                                                    onClick={(e) => {
                                                        goDetailPage(notice.id);
                                                    }}>
                                                    {notice.title}
                                                </p>
                                            </td>
                                            <td className="date-time" style={{ cursor: "default" }}>
                                                <p style={{ cursor: "default" }}>{getFormattedDateValue(notice.createdAt, "YYYY.MM.DD HH:mm")}</p>
                                            </td>
                                        </tr>
                                    ))}
                                    {noticeList.map((notice: any) => (
                                        <tr key={notice.id}>
                                            <td className="alarm" style={{ cursor: "default" }}>
                                                <p
                                                    onClick={(e) => {
                                                        goDetailPage(notice.id);
                                                    }}>
                                                    {notice.title}
                                                </p>
                                            </td>
                                            <td className="date-time" style={{ cursor: "default" }}>
                                                <p style={{ cursor: "default" }}>{getFormattedDateValue(notice.createdAt, "YYYY.MM.DD HH:mm")}</p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* <!-- mobile --> */}
                        <div className="mb">
                            <table>
                                <thead>
                                    <tr>
                                        <td className="alarm"></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {importantList.map((notice: any) => (
                                        <tr
                                            key={notice.id}
                                            onClick={(e) => {
                                                goDetailPage(notice.id);
                                            }}>
                                            <td className="alarm">
                                                <div className="important">
                                                    <p>{notice.title}</p>
                                                </div>
                                                <div className="date-time">
                                                    <p>{getFormattedDateValue(notice.createdAt, "YYYY.MM.DD HH:mm")}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    {noticeList.map((notice: any) => (
                                        <tr
                                            key={notice.id}
                                            onClick={(e) => {
                                                goDetailPage(notice.id);
                                            }}>
                                            <td className="alarm">
                                                <div>
                                                    <p>{notice.title}</p>
                                                </div>
                                                <div className="date-time">
                                                    <p>{getFormattedDateValue(notice.createdAt, "YYYY.MM.DD HH:mm")}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination url={noticePath} totalCount={totalCount} currentPage={page} pageSize={limit} pageGroupSize={10} setPage={setPage} />
                    </div>
                </>
            )}
        </main>
    );
};

export default NoticeComponent;
