import * as BaseApi from "./Base.api";

const baseUrl: string = process.env.REACT_APP_API_URL as string;

export const getCommonCodeList = async () => {
  console.log("AppApi.api:getCommonCodeList");

  const url = baseUrl + "/app/common-codes";
  const result = await BaseApi.get(url);

  if (result.errorCode) {
    alert(result.message);
    return null;
  } else {
    return result.data;
  }
};

export const getCountryCodeList = async () => {
  console.log("AppApi.api:getCommonCodeList");

  const url = "/app/country-codes";
  return BaseApi.get(url);
};

export const updateUserTerm = async (id: number, params: any) => {
  console.log("AppApi.api:updateUserTerm");

  const url = `/app/term/${id}`;
  return BaseApi.put(url, params);
};

export const getAppVersionInfo = async (params: any) => {
    console.log("AppApi.api:getAppVersionInfo");
  
    const url = "/app/app-version";
    return BaseApi.get(url, params);
  };