import React, { useEffect, useRef } from "react";

const ThumbnailComponent = (props: any) => {
  const {
    videoId,
    videoUrl,
    setSelectedVideoId,
    setSelectedVideoUrl,
    setIsShowVideoPopup,
    isEdit,
    index,
    onClickBtnDeleteVideo,
    getVideoUrl,
  } = props;
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (!video || !canvas) return;

    const context = canvas.getContext("2d");
    if (!context) return;

    const handleLoadedMetadata = () => {
      console.log("Video metadata loaded");
      video.currentTime = 0; // 첫 프레임으로 이동
    };

    const handleCanPlay = () => {
      console.log("Video can play");
      video
        .play()
        .then(() => {
          video.pause();
          requestAnimationFrame(() => {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            console.log("Canvas updated with video frame");
          });
        })
        .catch((error: any) => {
          console.error("Error playing video: ", error);
        });
    };

    video.addEventListener("loadedmetadata", handleLoadedMetadata);
    video.addEventListener("canplay", handleCanPlay);

    // 비디오를 로드합니다.
    video.src = videoUrl;
    video.muted = true;
    video.playsInline = true;
    video.load();

    return () => {
      video.removeEventListener("loadedmetadata", handleLoadedMetadata);
      video.removeEventListener("canplay", handleCanPlay);
    };
  }, [videoUrl]);

  const handlePlayButtonClick = async () => {
    setSelectedVideoId(videoId);
    setIsShowVideoPopup(true);
  };

  return (
    <li className="media-list">
      {isEdit && (
        <div
          className="btn-delete"
          onClick={() => onClickBtnDeleteVideo(index)}></div>
      )}
      <div className="thumbnail">
        <button className="play-btn" onClick={handlePlayButtonClick}></button>
        <canvas ref={canvasRef} width="300" height="190"></canvas>
        <video ref={videoRef} style={{ display: "none" }} muted playsInline>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </li>
  );
};

export default ThumbnailComponent;
