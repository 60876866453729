import StudentCardComponent from "commons/card/StudentCard.component";
import LoadingComponent from "commons/loading/Loading.component";
import React, { useEffect } from "react";
import YouTube, { YouTubeProps } from "react-youtube";

import TaggedStudentPopup from "commons/popup/TaggedStudentPopup.component";
import { createPortfolioNotificationInfo } from "api/notification/Notification.api";

const HomeComponent = (props: any) => {
  const {
    portfolioList,
    spotlightStudentList,
    newStudentList,
    changeIsFavorited,
    isLoading,
    isLoading2,
    isShowTaggedStudentPopup,
    setIsShowTaggedStudentPopup,
    taggedStudentPopupInfo,
    setTaggedStudentPopupInfo,
    setPortfolioList,
  } = props;

  const opts: YouTubeProps["opts"] = {
    height: "100%",
    width: "100%",
    playerVars: {},
  };

  return (
    <main>
      <div className="con">
        {portfolioList.length > 0 ? (
          <div className="new-portfolio-wrap">
            <h2 className="subtitle">
              NEW <span>PORTFOLIO</span>
            </h2>
            <div className="new-portfolio">
              {isLoading2 ? (
                <LoadingComponent />
              ) : (
                <ul className="new-portfolio-list-wrap">
                  {portfolioList.map((item: any, idx: number) => (
                    <li className="new-portfolio-list" key={`portfolio-${idx}`}>
                      <div className="portfolio portfolio-1">
                        <div className="con">
                          <p className="portfolio-title ellipsis">
                            {item.schoolName}
                          </p>
                          <p className="portfolio-date">{item.performedDate}</p>
                        </div>
                        <div className="portfolio-img">
                          <a>
                            <div className="thumbnail">
                              <YouTube
                                className="img"
                                videoId={item.youtubeId}
                                opts={opts}
                                onStateChange={(e: any) => {
                                  // player state
                                  // 시작되지 않음: -1, 종료: 0, 재생 중: 1, 일시중지: 2, 버퍼링: 3, 동영상 신호: 5
                                  const videoState = e.target.getPlayerState();
                                  const newList = [...portfolioList];
                                  const selectedItem = newList.find(
                                    (v: any) => v.id === item.id
                                  );
                                  // 재생중일 때만 체크
                                  selectedItem.hideTitle =
                                    videoState === 1 ||
                                    videoState === 2 ||
                                    videoState === 3;
                                  setPortfolioList(newList);

                                  if (videoState !== -1 && !item.firstview) {
                                    createPortfolioNotificationInfo({
                                      portfolioId: item.id,
                                    });
                                    setPortfolioList((prevList: any) =>
                                      prevList.map((prevItem: any) =>
                                        prevItem.id === item.id
                                          ? { ...prevItem, firstview: true }
                                          : prevItem
                                      )
                                    );
                                  }
                                }}
                              />
                              {/* 재생중일 때 제목 영역 비노출*/}
                              {!item.hideTitle ? (
                                <p className="ellipsis">{item.title}</p>
                              ) : null}
                            </div>
                          </a>
                          {isShowTaggedStudentPopup ? (
                            <TaggedStudentPopup
                              studentDetailUrlPrefix="/agency/wannabe/info/"
                              info={taggedStudentPopupInfo}
                              setIsShowTaggedStudentPopup={
                                setIsShowTaggedStudentPopup
                              }
                            />
                          ) : null}
                          <div className="student-list">
                            <div className="student-img">
                              <ul
                                style={{
                                  width: "fit-content",
                                  cursor: "pointer",
                                }}
                                onClick={(evt) => {
                                  setTaggedStudentPopupInfo(item);
                                  setIsShowTaggedStudentPopup(true);
                                }}>
                                {item.studentInfoList?.length > 0 &&
                                  item.studentInfoList
                                    .filter((_: any, idx: number) => idx < 4)
                                    .map((i: any, key: number) => (
                                      <li key={`tagged-student${idx}-${key}`}>
                                        <img
                                          src={`${
                                            i.imageUrl
                                              ? i.imageUrl
                                              : "./imgs/dummy/basic-img-2.png"
                                          }?w=90&h=90`}
                                          alt="Participating students"
                                        />
                                      </li>
                                    ))}
                                {item.studentInfoList.length > 4 ? (
                                  <li className="over">
                                    <img
                                      src="./imgs/student/home/over.png"
                                      alt=""
                                    />
                                    <span>{`+${
                                      item.studentInfoList.length - 4
                                    }`}</span>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ) : null}

        <div className="spotlight-wrap">
          <h2>
            <span>SPOTLIGHT</span> WANNABES
          </h2>
          <div className="spotlight">
            {isLoading ? (
              <LoadingComponent />
            ) : (
              <ul>
                {spotlightStudentList.length > 0
                  ? spotlightStudentList.map((item: any, index: number) => (
                      <StudentCardComponent
                        key={`new-wanna-${index}`}
                        item={item}
                        changeFavoriteFunc={changeIsFavorited}
                        {...props}
                      />
                    ))
                  : null}
              </ul>
            )}
          </div>
        </div>

        <div className="spotlight-wrap new-wannabes-wrap">
          <h2>
            <span>NEW</span> WANNABES
          </h2>
          <div className="spotlight">
            {isLoading ? (
              <LoadingComponent />
            ) : (
              <ul>
                {newStudentList.length > 0
                  ? newStudentList.map((item: any, index: number) => (
                      <StudentCardComponent
                        key={`new-wanna-${index}`}
                        item={item}
                        changeFavoriteFunc={changeIsFavorited}
                        {...props}
                      />
                    ))
                  : null}
              </ul>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default HomeComponent;
