import React from "react";
import { useNavigate } from "react-router-dom";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoadingComponent from "commons/loading/Loading.component";
import { getCurrentLangName } from "helpers/String.helper";

const StudentDetailComponent = (props: any) => {
  const navigate = useNavigate();

  const {
    studentInfo,
    //isPublic,
    isLogin,
    myInfo,
    // favorite
    btnRef,
    isFavorited,
    changeFavoriteStatus,
    // image popup
    sliderRef,
    showImagePopup,
    isShowPopup,
    setIsShowPopup,
    initialSlideIdx,
    // message
    messageRequest,
    createRequest,
    cancelRequest,
    isLoading,
    agencyId,
  } = props;

  const Arrow = () => {
    return <div />;
  };

  const settings = {
    className: "slider",
    infinite: studentInfo?.imageInfoList.length > 1 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialSlideIdx,
    prevArrow: <Arrow />,
    nextArrow: <Arrow />,
  };

  return (
    <main className="">
      {(studentInfo?.userSchoolId && !isLoading) ||
      (!studentInfo?.userSchoolId && studentInfo?.id) ? (
        <div className="wannabe-info">
          <div className="wrap">
            <div className="back-btn" onClick={(evt) => navigate("/")}></div>

            {/* <!-- 2024.07.29 landing 페이지 학생 상세정보 추가 --> */}
            <section className="profile">
              <div className="back-btn" onClick={(evt) => navigate("/")}></div>
              <div className="profile-photo">
                <div
                  className="img"
                  style={{
                    backgroundImage: `url('${
                      studentInfo.profileImageInfo?.url
                        ? studentInfo.profileImageInfo?.url + "?w=800&h=800"
                        : "./imgs/dummy/basic-img.png"
                    }')`,
                    cursor: `${
                      studentInfo.profileImageInfo?.url ? "pointer" : "default"
                    }`,
                  }}
                  onClick={(e: any) => {
                    if (studentInfo.profileImageInfo?.url) {
                      showImagePopup(0);
                    }
                  }}></div>
              </div>
              <div className="info">
                <ul>
                  <li>
                    <div className="nationality">
                      <p>
                        {getCurrentLangName(studentInfo.countryCodeInfo.names)}
                      </p>
                    </div>
                  </li>

                  <li>
                    <div className="name">
                      <p>{`${
                        studentInfo.nickname ? studentInfo.nickname : "WANNABE"
                      } (${getCurrentLangName(
                        studentInfo?.genderTypeCodeInfo?.names
                      )})`}</p>
                    </div>
                  </li>

                  <li>
                    <div className="birthday">
                      {/* <p>{studentInfo.birthday.replaceAll("-", ".")}</p> */}
                    </div>
                  </li>
                  <li>
                    <div className="specialty">
                      {studentInfo.specialtyCodeInfoList.length > 0
                        ? studentInfo.specialtyCodeInfoList.map((item: any) => (
                            <div className="specialty-tip">
                              <p>{`${getCurrentLangName(item.names)}`}</p>
                            </div>
                          ))
                        : null}

                      {studentInfo.mbtiCode ? (
                        <div className="specialty-tip">
                          <p>{studentInfo.mbtiCode}</p>
                        </div>
                      ) : null}
                    </div>
                  </li>
                </ul>
              </div>
            </section>

            <div className="info-3 infof-3-landing">
              <h2>STATISTICS</h2>
              <div className="wrap">
                <div className="favorite">
                  <img src="./imgs/wannabe-info/favorite.png" alt="favorite" />
                  <span>{studentInfo.favoriteCnt}</span>
                </div>
                <div className="view">
                  <img src="./imgs/wannabe-info/view.png" alt="view" />
                  <span>{studentInfo.viewCnt}</span>
                </div>
              </div>
            </div>

            {studentInfo.imageInfoList.length > 0 ||
            studentInfo.profileImageInfo?.url ? (
              <div className="info-9">
                {studentInfo.imageInfoList.length > 0 ? (
                  <>
                    <h2>ADDITIONAL PHOTOS</h2>
                    <ul>
                      {studentInfo.imageInfoList.map(
                        (item: any, idx: number) => (
                          <li
                            key={`student-${idx}`}
                            onClick={(evt) => showImagePopup(idx + 1)}>
                            <img
                              src={`${item.url}?w=700&h=700`}
                              alt="additional photo"
                            />
                          </li>
                        )
                      )}
                    </ul>
                  </>
                ) : null}

                {/* <!-- 이미지 클릭시 팝업 --> */}
                {isShowPopup ? (
                  <div className="popup popup-wannabe-info">
                    <div
                      className="close-btn"
                      onClick={(evt) => setIsShowPopup(false)}></div>
                    <h2>PHOTOS</h2>
                    <div className="wrap">
                      <Slider ref={sliderRef} {...settings}>
                        <>
                          <div
                            className="img"
                            style={{
                              backgroundImage: `url("${
                                studentInfo.profileImageInfo?.url
                                  ? `${studentInfo.profileImageInfo?.url}?w=1200&h=1200`
                                  : "./imgs/dummy/basic-img.png"
                              })`,
                            }}></div>
                        </>
                        {studentInfo.imageInfoList.map((item: any) => (
                          <>
                            <div
                              className="img"
                              style={{
                                backgroundImage: `url("${item.url}?w=1200&h=1200")`,
                              }}></div>
                          </>
                        ))}
                      </Slider>
                      {studentInfo.imageInfoList.length > 1 ? (
                        <>
                          <button
                            className="custom-prev"
                            onClick={(evt) => sliderRef.current.slickPrev()}
                          />
                          <button
                            className="custom-next"
                            onClick={(evt) => sliderRef.current.slickNext()}
                          />
                        </>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            {/* <!-- 2024.07.16 대화요청 버튼 floating bar로 변경 --> */}
            {isLogin ? (
              myInfo.accountTypeCode === "accountType:agency" ? (
                <div className="floating-bar">
                  <div className="wrap">
                    <div className="chat-request-btn">
                      {messageRequest ? (
                        messageRequest.messageStatusCode ===
                        "messageType:accept" ? (
                          <button
                            onClick={() => {
                              navigate(
                                `/agency/message/${messageRequest.channelId}`,
                                {
                                  state: {
                                    schoolName: studentInfo.schoolInfo?.name,
                                  },
                                }
                              );
                            }}>
                            {getLanguageValue(
                              PAGE_TYPE.COMMON,
                              "studentDetail.btnGoToMessagePage"
                            )}
                          </button>
                        ) : messageRequest.messageStatusCode ===
                          "messageType:request" ? (
                          <button
                            onClick={(e) => {
                              cancelRequest(studentInfo?.id);
                            }}>
                            {getLanguageValue(
                              PAGE_TYPE.COMMON,
                              "studentDetail.btnCancelRequest"
                            )}
                          </button>
                        ) : (
                          <button
                            onClick={(e) => {
                              createRequest(studentInfo?.id);
                            }}>
                            {getLanguageValue(
                              PAGE_TYPE.COMMON,
                              "studentDetail.btnRequestMessage"
                            )}
                          </button>
                        )
                      ) : (
                        <button
                          onClick={(e) => {
                            createRequest(studentInfo?.id);
                          }}>
                          {getLanguageValue(
                            PAGE_TYPE.COMMON,
                            "studentDetail.btnRequestMessage"
                          )}
                        </button>
                      )}
                    </div>

                    <div className="btn-con">
                      <button
                        className={
                          isFavorited ? "favorite-btn" : "favorite-disabled-btn"
                        }
                        ref={btnRef}
                        onClick={(evt) => changeFavoriteStatus()}>
                        <img
                          src={
                            isFavorited
                              ? "./imgs/student/home/favorite-btn-2.png"
                              : "./imgs/student/home/favorite-btn-1.png"
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              ) : null
            ) : agencyId ? (
              <div className="floating-bar">
                <div className="wrap">
                  <div className="chat-request-btn">
                    <button
                      onClick={(e) => {
                        createRequest(studentInfo?.id);
                      }}>
                      {getLanguageValue(
                        PAGE_TYPE.COMMON,
                        "studentDetail.btnRequestMessage"
                      )}
                    </button>
                  </div>

                  <div className="btn-con">
                    <button
                      className={
                        isFavorited ? "favorite-btn" : "favorite-disabled-btn"
                      }
                      ref={btnRef}
                      onClick={(evt) => changeFavoriteStatus()}>
                      <img
                        src={
                          isFavorited
                            ? "./imgs/student/home/favorite-btn-2.png"
                            : "./imgs/student/home/favorite-btn-1.png"
                        }
                      />
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <LoadingComponent />
      )}
    </main>
  );
};
export default StudentDetailComponent;
