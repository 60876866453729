import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import PagenationComponent from "./Pagination.component";

const LayoutContainer = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const { url, totalCount, currentPage, pageSize, pageGroupSize, setPage } =
    props;

  const [pageButtonCount, setPageButtonCount] = useState(0);
  const [pagingData, setPagingData] = useState(null);

  const setQueryString = (page: string, prev: string) => {
    searchParams.set("page", page);
    if (prev) {
      searchParams.set("prev", prev);
    }
    const state = location.state;
    navigate(`${url}?${searchParams}`, { state });
  };

  const changePage = (page: number) => {
    setPage(page);
    const prev = location.state?.prev;
    setQueryString(page.toString(), prev);
  };

  useEffect(() => {
    if (currentPage) {
      const totalPage = Math.ceil(totalCount / pageSize);
      const from =
        Math.floor((currentPage - 1) / pageGroupSize) * pageGroupSize + 1;
      const to =
        from + pageGroupSize - 1 < totalPage
          ? from + pageGroupSize - 1
          : totalPage;
      setPagingData({ totalPage, from, to });
    }
  }, [currentPage, totalCount]);

  useEffect(() => {
    if (pagingData) {
      setPageButtonCount(pagingData.to - pagingData.from + 1);
    }
  }, [pagingData]);

  useEffect(() => {
    if (!location.search) {
      const page = location.state?.page || 1;
      const prev = location.state?.prev;
      setQueryString(page, prev);
    }
    console.log(currentPage);
  }, []);

  return (
    <PagenationComponent
      pageButtonCount={pageButtonCount}
      pagingData={pagingData}
      currentPage={currentPage}
      changePage={changePage}
    />
  );
};

export default LayoutContainer;
