import { getTermList } from "api/common/Auth.api";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const TermComponent = (props: any) => {
    const { termList, info } = props;

    const navigate = useNavigate();

    const { isApp } = useSelector((state: any) => state.common);
    return (
        <main className="terms">
            <div className="con">
                {isApp ? (
                    <div
                        className="back-btn"
                        onClick={(e) => {
                            navigate(-1);
                        }}></div>
                ) : null}

                {termList.length > 0 ? (
                    <ul className="policy-container">
                        {termList
                            ? termList.map((item: any, index: number) => (
                                  <li className={`policy ${info?.id === item.id ? "on" : ""}`} key={`term-${index}`}>
                                      <a
                                          onClick={(e: any) => {
                                              e.preventDefault();

                                              navigate(`/term/common/${item?.id}`);
                                          }}>
                                          {item?.title}
                                      </a>
                                  </li>
                              ))
                            : null}
                    </ul>
                ) : null}

                <div
                    className="content"
                    dangerouslySetInnerHTML={{
                        __html: info?.text,
                    }}></div>
            </div>
        </main>
    );
};
export default TermComponent;
