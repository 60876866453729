import { PAGE_TYPE } from "enums";
import { getLanguageValue } from "locales/i18n";
import React, { useEffect } from "react";

const AboutComponent = (props: any) => {
    useEffect(() => {}, []);

    return (
        <main className="about">
            <div className="con">
                <div className="main-title">
                    <h1>WANNABE</h1>
                    <p>{getLanguageValue(PAGE_TYPE.COMMON, "about.wannabeText")} &#11088;</p>
                </div>
                <div className="section section-1">
                    <div className="wrap">
                        <div className="title">
                            <h2>CREATE YOUR PROFILE</h2>
                            <p className="text">
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section11")} <br />
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section12")}
                            </p>
                        </div>
                        <img src="./imgs/about/section-1.png" alt="CREATE YOUR PROFILE" />
                    </div>
                </div>
                <div className="section section-2">
                    <div className="wrap pc">
                        <img src="./imgs/about/section-2.png" alt="SHARE YOUR WANNABE CARD" />
                        <div className="title">
                            <h2>SHARE YOUR WANNABE CARD</h2>
                            <p className="text">
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section21")} <br />
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section22")}
                            </p>
                        </div>
                    </div>

                    {/* <!-- 모바일 --> */}
                    <div className="wrap mb">
                        <div className="title">
                            <h2>SHARE YOUR WANNABE CARD</h2>
                            <p className="text">
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section21")} <br />
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section22")}
                            </p>
                        </div>
                        <img src="./imgs/about/section-2.png" alt="SHARE YOUR WANNABE CARD" />
                    </div>
                </div>
                <div className="section section-3">
                    <div className="wrap">
                        <div className="title">
                            <h2>APPLY FOR AN AUDITION</h2>
                            <p className="text">
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section31")} <br />
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section32")}
                            </p>
                        </div>
                        <img src="./imgs/about/section-3.png" alt="APPLY FOR AN AUDITION" />
                    </div>
                </div>

                <div className="main-title main-title-gap">
                    <h1>AGENCY</h1>
                    <p>{getLanguageValue(PAGE_TYPE.COMMON, "about.agencyText")} &#127970;</p>
                </div>
                <div className="section section-4 common-section">
                    <div className="wrap">
                        <div className="title">
                            <h2>SEARCH YOUR FUTURE K-POP STAR</h2>
                            <p className="text">
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section41")} <br />
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section42")}
                            </p>
                        </div>
                        <img src="./imgs/about/section-4.png" alt="APPLY FOR AN AUDITION" />
                    </div>
                </div>
                <div className="section section-5 common-section">
                    <div className="wrap">
                        <div className="title">
                            <h2>REGISTER WANNABE TO YOUR FAVORITES</h2>
                            <p className="text">
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section51")} <br />
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section52")}
                            </p>
                        </div>
                        <img src="./imgs/about/section-5.png" alt="APPLY FOR AN AUDITION" />
                    </div>
                </div>
                <div className="section section-6 common-section">
                    <div className="wrap">
                        <div className="title">
                            <h2>HOST AN AUDITION</h2>
                            <p className="text">
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section61")} <br />
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section62")}
                            </p>
                        </div>
                        <img src="./imgs/about/section-6.png" alt="APPLY FOR AN AUDITION" />
                    </div>
                </div>

                <div className="main-title main-title-gap">
                    <h1>SCHOOL</h1>
                    <p>{getLanguageValue(PAGE_TYPE.COMMON, "about.schoolText")} &#127979;</p>
                </div>
                <div className="section section-7 common-section">
                    <div className="wrap">
                        <div className="title">
                            <h2>REGISTER YOUR STUDENT AS WANNABE</h2>
                            <p className="text">
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section71")} <br />
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section72")}
                            </p>
                        </div>
                        <img src="./imgs/about/section-7.png" alt="APPLY FOR AN AUDITION" />
                    </div>
                </div>
                <div className="section section-8 common-section">
                    <div className="wrap">
                        <div className="title">
                            <h2>FILL OUT THE EVALUATION INFORMATION</h2>
                            <p className="text">
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section81")} <br />
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section82")}
                            </p>
                        </div>
                        <img src="./imgs/about/section-8.png" alt="APPLY FOR AN AUDITION" />
                    </div>
                </div>
                <div className="section section-9 common-section">
                    <div className="wrap">
                        <div className="title">
                            <h2>CREATE A PORTFOLIO FOR WANNABE</h2>
                            <p className="text">
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section91")} <br />
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section92")} <br />
                                {getLanguageValue(PAGE_TYPE.COMMON, "about.section93")}
                            </p>
                        </div>
                        <img src="./imgs/about/section-9.png" alt="APPLY FOR AN AUDITION" />
                    </div>
                </div>
            </div>
        </main>
    );
};
export default AboutComponent;
