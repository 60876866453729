import React from "react";
import { useNavigate } from "react-router-dom";

import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { getCurrentLangName } from "helpers/String.helper";

import { Watermark } from "@hirohe/react-watermark";

import StudentEvaluationGraphContainer from "commons/evaluation/StudentEvalutionGraph.container";
import LoadingComponent from "commons/loading/Loading.component";
import YouTube, { YouTubeProps } from "react-youtube";
import { createPortfolioNotificationInfo } from "api/notification/Notification.api";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { useSelector } from "react-redux";
import ImageWithWatermarkComponent from "commons/image/ImageWitehWatermark.component";

const WannabeProfileComponent = (props: any) => {
    const navigate = useNavigate();

    const { info: userInfo } = useSelector((state: any) => state.user);

    const {
        studentInfo,
        isPublic,
        // favorite
        btnRef,
        isFavorited,
        changeFavoriteStatus,
        // message
        messageRequest,
        createRequest,
        cancelRequest,
        isLoading,
        messageBtnRef,
        //profile
        profile,

        //video
        videoRef,
        selectedVideoUrl,
        createRequestProfile,
        //
        isShowPopup,
        setIsShowPopup,
        popupList,
        setPopupList,
        openViewPopup,
        categoryCode,
        typeCode,
        sliderRef,

        hasVerifiedEmail,

        updateMediaViewCount,
        isRequestButtonDisabled,
    } = props;

    const Arrow = () => {
        return <div />;
    };

    const settings = {
        className: "slider",
        infinite: popupList?.length > 1 ? true : false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        prevArrow: <Arrow />,
        nextArrow: <Arrow />,
        afterChange: async (current: any) => {
            const item = popupList[current];

            if (!item.isViewCountUpdated) {
                await updateMediaViewCount(item.id, "profileImage");
                item.isViewCountUpdated = true;
            }

            setPopupList([...popupList]);
        },
    };

    return (
        <main className="">
            {isLoading ? (
                <LoadingComponent />
            ) : (
                <>
                    <div className="area">
                        <div className="title">
                            {/* <!-- 선택한 title에 class active 추가 --> */}
                            <button onClick={(evt) => navigate(`/agency/wannabe/info/${studentInfo?.id}`)}>
                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeInfo.txtTitle")}
                            </button>
                            {/* <button onClick={(evt) => navigate(`/agency/wannabe/practice-video/${studentInfo?.id}`)}>
                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabePracticeVideo.txtTitle")}
                            </button> */}
                            <button className="active">{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtTitle")}</button>
                            {studentInfo?.userSchoolId ? (
                                <button onClick={(evt) => navigate(`/agency/wannabe/school-evaluation/${studentInfo?.id}`)}>
                                    {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeSchoolEvaluation.txtTitle")}
                                </button>
                            ) : null}
                        </div>
                        <div className="back-btn" onClick={(evt) => navigate(-1)}></div>
                    </div>
                    {(isPublic !== null && !isPublic) || !hasVerifiedEmail ? (
                        <div className="private-profile">
                            <img src="./imgs/icons/lock-icon.png" alt="lock" />
                            <p>{getLanguageValue(PAGE_TYPE.AGENCY, `studentInfo.${!hasVerifiedEmail ? "txtNeedEmailVerifying" : "txtPrivateProfile"}`)}</p>
                        </div>
                    ) : (
                        <div className="agency-wannabe-profile">
                            <div className="wrap wrap-video">
                                <div className="title">
                                    <h2>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtIntroVideo")}</h2>
                                </div>
                                {selectedVideoUrl ? (
                                    <div className="con">
                                        <div className="intro-video">
                                            <video controls ref={videoRef} muted playsInline controlsList="nodownload">
                                                <source type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="con empty">
                                        <div className="intro-video-empty">
                                            <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtNoVideo")}</p>
                                            <div className="control-wrap">
                                                <button
                                                    className="btn-request"
                                                    disabled={isRequestButtonDisabled}
                                                    onClick={() => {
                                                        createRequestProfile("introVideo", "", "");
                                                    }}>
                                                    {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtRequestVideo")}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="wrap wrap-photo wrap-photo-face">
                                <div className="title">
                                    <h2>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtFaceImage")}</h2>
                                </div>
                                <div className="con">
                                    <ul>
                                        <li>
                                            <div className={`item ${profile?.faceImageInfo?.front && profile?.faceImageInfo?.front.url ? "" : "empty"}`}>
                                                <div
                                                    className="img-wrap"
                                                    onClick={() => {
                                                        if (profile?.faceImageInfo?.front && profile?.faceImageInfo?.front.url) {
                                                            openViewPopup(profile.faceImageInfo.front.id, "profileImage", "face", "front");
                                                        }
                                                    }}>
                                                    {profile?.faceImageInfo?.front && profile.faceImageInfo.front.url ? (
                                                        // <Watermark
                                                        //     text={userInfo.agencyName}
                                                        //     textSize={12}
                                                        //     lineHeight="0.8rem"
                                                        //     wrapperStyle={{ height: "100%" }}>
                                                        //     <div
                                                        //         onClick={() => {
                                                        //             openViewPopup(profile.faceImageInfo.front.id, "profileImage", "face", "front");
                                                        //         }}
                                                        //         className="img"
                                                        //         style={{
                                                        //             backgroundImage: `url(${
                                                        //                 profile?.faceImageInfo?.front && profile.faceImageInfo.front.url
                                                        //                     ? profile.faceImageInfo.front.url.replace(/ /g, "%20")
                                                        //                     : "./imgs/dummy/basic-img-3.png"
                                                        //             })`,
                                                        //         }}></div>
                                                        // </Watermark>

                                                        <ImageWithWatermarkComponent
                                                            imageUrl={profile.faceImageInfo.front.url}
                                                            watermarkText={userInfo.agencyName}
                                                        />
                                                    ) : (
                                                        <div
                                                            onClick={() => {
                                                                openViewPopup(profile.faceImageInfo.front.id, "profileImage", "face", "front");
                                                            }}
                                                            className="img"
                                                            style={{
                                                                backgroundImage: `url(${
                                                                    profile?.faceImageInfo?.front && profile.faceImageInfo.front.url
                                                                        ? profile.faceImageInfo.front.url.replace(/ /g, "%20")
                                                                        : "./imgs/dummy/basic-img-3.png"
                                                                })`,
                                                            }}></div>
                                                    )}

                                                    <div className="img-empty">
                                                        <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtNoImage")}</p>
                                                        <div className="control-wrap">
                                                            <button
                                                                className="btn-request"
                                                                disabled={isRequestButtonDisabled}
                                                                onClick={() => {
                                                                    createRequestProfile("profileImage", "face", "front");
                                                                }}>
                                                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtRequestImage")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <div className="type">
                                                        <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtFront")}</p>
                                                    </div>

                                                    <div className="date">
                                                        <p>
                                                            {profile?.faceImageInfo?.front?.id
                                                                ? getFormattedDateValue(profile?.faceImageInfo?.front?.createdDate, "YYYY.MM.DD")
                                                                : null}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={`item ${profile?.faceImageInfo?.left && profile.faceImageInfo.left.url ? "" : "empty"}`}>
                                                <div
                                                    className="img-wrap"
                                                    onClick={() => {
                                                        if (profile?.faceImageInfo?.left && profile.faceImageInfo.left.url) {
                                                            openViewPopup(profile.faceImageInfo.left.id, "profileImage", "face", "left");
                                                        }
                                                    }}>
                                                    {profile?.faceImageInfo?.left && profile.faceImageInfo.left.url ? (
                                                        // <Watermark
                                                        //     text={userInfo.agencyName}
                                                        //     textSize={12}
                                                        //     lineHeight="0.8rem"
                                                        //     wrapperStyle={{ height: "100%" }}>
                                                        //     <div
                                                        //         onClick={() => {
                                                        //             openViewPopup(profile.faceImageInfo.left.id, "profileImage", "face", "left");
                                                        //         }}
                                                        //         className="img"
                                                        //         style={{
                                                        //             backgroundImage: `url(${
                                                        //                 profile?.faceImageInfo?.left && profile.faceImageInfo.left.url
                                                        //                     ? profile.faceImageInfo.left.url.replace(/ /g, "%20")
                                                        //                     : "./imgs/dummy/basic-img-3.png"
                                                        //             })`,
                                                        //         }}></div>
                                                        // </Watermark>
                                                        <ImageWithWatermarkComponent
                                                            imageUrl={profile.faceImageInfo.left.url}
                                                            watermarkText={userInfo.agencyName}
                                                        />
                                                    ) : (
                                                        <div
                                                            onClick={() => {
                                                                openViewPopup(profile.faceImageInfo.left.id, "profileImage", "face", "left");
                                                            }}
                                                            className="img"
                                                            style={{
                                                                backgroundImage: `url(${
                                                                    profile?.faceImageInfo?.left && profile.faceImageInfo.left.url
                                                                        ? profile.faceImageInfo.left.url.replace(/ /g, "%20")
                                                                        : "./imgs/dummy/basic-img-3.png"
                                                                })`,
                                                            }}></div>
                                                    )}

                                                    <div className="img-empty">
                                                        <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtNoImage")}</p>
                                                        <div className="control-wrap">
                                                            <button
                                                                className="btn-request"
                                                                disabled={isRequestButtonDisabled}
                                                                onClick={() => {
                                                                    createRequestProfile("profileImage", "face", "left");
                                                                }}>
                                                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtRequestImage")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <div className="type">
                                                        <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtLeft")}</p>
                                                    </div>

                                                    <div className="date">
                                                        <p>
                                                            {profile?.faceImageInfo?.left?.id
                                                                ? getFormattedDateValue(profile?.faceImageInfo?.left?.createdDate, "YYYY.MM.DD")
                                                                : null}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={`item ${profile?.faceImageInfo?.right && profile.faceImageInfo.right.url ? "" : "empty"}`}>
                                                <div
                                                    className="img-wrap"
                                                    onClick={() => {
                                                        if (profile?.faceImageInfo?.right && profile.faceImageInfo.right.url) {
                                                            openViewPopup(profile.faceImageInfo.right.id, "profileImage", "face", "right");
                                                        }
                                                    }}>
                                                    {profile?.faceImageInfo?.right && profile.faceImageInfo.right.url ? (
                                                        // <Watermark
                                                        //     text={userInfo.agencyName}
                                                        //     textSize={12}
                                                        //     lineHeight="0.8rem"
                                                        //     wrapperStyle={{ height: "100%" }}>
                                                        //     <div
                                                        //         onClick={() => {
                                                        //             openViewPopup(profile.faceImageInfo.right.id, "profileImage", "face", "right");
                                                        //         }}
                                                        //         className="img"
                                                        //         style={{
                                                        //             backgroundImage: `url(${
                                                        //                 profile?.faceImageInfo?.right && profile.faceImageInfo.right.url
                                                        //                     ? profile.faceImageInfo.right.url.replace(/ /g, "%20")
                                                        //                     : "./imgs/dummy/basic-img-3.png"
                                                        //             })`,
                                                        //         }}></div>
                                                        // </Watermark>
                                                        <ImageWithWatermarkComponent
                                                            imageUrl={profile.faceImageInfo.right.url}
                                                            watermarkText={userInfo.agencyName}
                                                        />
                                                    ) : (
                                                        <div
                                                            onClick={() => {
                                                                openViewPopup(profile.faceImageInfo.right.id, "profileImage", "face", "right");
                                                            }}
                                                            className="img"
                                                            style={{
                                                                backgroundImage: `url(${
                                                                    profile?.faceImageInfo?.right && profile.faceImageInfo.right.url
                                                                        ? profile.faceImageInfo.right.url.replace(/ /g, "%20")
                                                                        : "./imgs/dummy/basic-img-3.png"
                                                                })`,
                                                            }}></div>
                                                    )}

                                                    <div className="img-empty">
                                                        <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtNoImage")}</p>
                                                        <div className="control-wrap">
                                                            <button
                                                                className="btn-request"
                                                                disabled={isRequestButtonDisabled}
                                                                onClick={() => {
                                                                    createRequestProfile("profileImage", "face", "right");
                                                                }}>
                                                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtRequestImage")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <div className="type">
                                                        <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtRight")}</p>
                                                    </div>

                                                    <div className="date">
                                                        <p>
                                                            {profile?.faceImageInfo?.right?.id
                                                                ? getFormattedDateValue(profile?.faceImageInfo?.right?.createdDate, "YYYY.MM.DD")
                                                                : null}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={`item ${profile?.faceImageInfo?.back && profile.faceImageInfo.back.url ? "" : "empty"}`}>
                                                <div
                                                    className="img-wrap"
                                                    onClick={() => {
                                                        if (profile?.faceImageInfo?.back && profile.faceImageInfo.back.url) {
                                                            openViewPopup(profile.faceImageInfo.back.id, "profileImage", "face", "back");
                                                        }
                                                    }}>
                                                    {profile?.faceImageInfo?.back && profile.faceImageInfo.back.url ? (
                                                        // <Watermark
                                                        //     text={userInfo.agencyName}
                                                        //     textSize={12}
                                                        //     lineHeight="0.8rem"
                                                        //     wrapperStyle={{ height: "100%" }}>
                                                        //     <div
                                                        //         onClick={() => {
                                                        //             openViewPopup(profile.faceImageInfo.back.id, "profileImage", "face", "back");
                                                        //         }}
                                                        //         className="img"
                                                        //         style={{
                                                        //             backgroundImage: `url(${
                                                        //                 profile?.faceImageInfo?.back && profile.faceImageInfo.back.url
                                                        //                     ? profile.faceImageInfo.back.url.replace(/ /g, "%20")
                                                        //                     : "./imgs/dummy/basic-img-3.png"
                                                        //             })`,
                                                        //         }}></div>
                                                        // </Watermark>
                                                        <ImageWithWatermarkComponent
                                                            imageUrl={profile.faceImageInfo.back.url}
                                                            watermarkText={userInfo.agencyName}
                                                        />
                                                    ) : (
                                                        <div
                                                            onClick={() => {
                                                                openViewPopup(profile.faceImageInfo.back.id, "profileImage", "face", "back");
                                                            }}
                                                            className="img"
                                                            style={{
                                                                backgroundImage: `url(${
                                                                    profile?.faceImageInfo?.back && profile.faceImageInfo.back.url
                                                                        ? profile.faceImageInfo.back.url.replace(/ /g, "%20")
                                                                        : "./imgs/dummy/basic-img-3.png"
                                                                })`,
                                                            }}></div>
                                                    )}

                                                    <div className="img-empty">
                                                        <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtNoImage")}</p>
                                                        <div className="control-wrap">
                                                            <button
                                                                className="btn-request"
                                                                disabled={isRequestButtonDisabled}
                                                                onClick={() => {
                                                                    createRequestProfile("profileImage", "face", "back");
                                                                }}>
                                                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtRequestImage")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <div className="type">
                                                        <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtBack")}</p>
                                                    </div>

                                                    <div className="date">
                                                        <p>
                                                            {profile?.faceImageInfo?.back?.id
                                                                ? getFormattedDateValue(profile?.faceImageInfo?.back?.createdDate, "YYYY.MM.DD")
                                                                : null}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="wrap wrap-photo wrap-photo-fullbody">
                                <div className="title">
                                    <h2>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtBodyImgae")}</h2>
                                </div>
                                <div className="con">
                                    <ul>
                                        <li>
                                            <div className={`item ${profile?.fullBodyImageInfo?.front && profile.fullBodyImageInfo.front.url ? "" : "empty"}`}>
                                                <div
                                                    className="img-wrap"
                                                    onClick={() => {
                                                        if (profile?.fullBodyImageInfo?.front && profile.fullBodyImageInfo.front.url) {
                                                            openViewPopup(profile.fullBodyImageInfo.front.id, "profileImage", "fullBody", "front");
                                                        }
                                                    }}>
                                                    {profile?.fullBodyImageInfo?.front && profile.fullBodyImageInfo.front.url ? (
                                                        <ImageWithWatermarkComponent
                                                            imageUrl={profile.fullBodyImageInfo.front.url}
                                                            watermarkText={userInfo.agencyName}
                                                        />
                                                    ) : (
                                                        <div
                                                            onClick={() => {
                                                                openViewPopup(profile.fullBodyImageInfo.front.id, "profileImage", "fullBody", "front");
                                                            }}
                                                            className="img"
                                                            style={{
                                                                backgroundImage: `url(${
                                                                    profile?.fullBodyImageInfo?.front && profile.fullBodyImageInfo.front.url
                                                                        ? profile.fullBodyImageInfo.front.url.replace(/ /g, "%20")
                                                                        : "./imgs/dummy/basic-img-3.png"
                                                                })`,
                                                            }}></div>
                                                    )}

                                                    <div className="img-empty">
                                                        <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtNoImage")}</p>
                                                        <div className="control-wrap">
                                                            <button
                                                                className="btn-request"
                                                                disabled={isRequestButtonDisabled}
                                                                onClick={() => {
                                                                    createRequestProfile("profileImage", "fullBody", "front");
                                                                }}>
                                                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtRequestImage")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <div className="type">
                                                        <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtFront")}</p>
                                                    </div>

                                                    <div className="date">
                                                        <p>
                                                            {profile?.fullBodyImageInfo?.front?.id
                                                                ? getFormattedDateValue(profile?.fullBodyImageInfo?.front.createdDate, "YYYY.MM.DD")
                                                                : null}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={`item ${profile?.fullBodyImageInfo?.left && profile.fullBodyImageInfo.left.url ? "" : "empty"}`}>
                                                <div
                                                    className="img-wrap"
                                                    onClick={() => {
                                                        if (profile?.fullBodyImageInfo?.left && profile.fullBodyImageInfo.left.url) {
                                                            openViewPopup(profile.fullBodyImageInfo.front.id, "profileImage", "fullBody", "left");
                                                        }
                                                    }}>
                                                    {profile?.fullBodyImageInfo?.left && profile.fullBodyImageInfo.left.url ? (
                                                        <ImageWithWatermarkComponent
                                                            imageUrl={profile.fullBodyImageInfo.left.url}
                                                            watermarkText={userInfo.agencyName}
                                                        />
                                                    ) : (
                                                        <div
                                                            onClick={() => {
                                                                openViewPopup(profile.fullBodyImageInfo.left.id, "profileImage", "fullBody", "left");
                                                            }}
                                                            className="img"
                                                            style={{
                                                                backgroundImage: `url(${
                                                                    profile?.fullBodyImageInfo?.left && profile.fullBodyImageInfo.left.url
                                                                        ? profile.fullBodyImageInfo.left.url.replace(/ /g, "%20")
                                                                        : "./imgs/dummy/basic-img-3.png"
                                                                })`,
                                                            }}></div>
                                                    )}

                                                    <div className="img-empty">
                                                        <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtNoImage")}</p>
                                                        <div className="control-wrap">
                                                            <button
                                                                className="btn-request"
                                                                disabled={isRequestButtonDisabled}
                                                                onClick={() => {
                                                                    createRequestProfile("profileImage", "fullBody", "left");
                                                                }}>
                                                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtRequestImage")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <div className="type">
                                                        <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtLeft")}</p>
                                                    </div>

                                                    <div className="date">
                                                        <p>
                                                            {profile?.fullBodyImageInfo?.left?.id
                                                                ? getFormattedDateValue(profile?.fullBodyImageInfo?.left.createdDate, "YYYY.MM.DD")
                                                                : null}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={`item ${profile?.fullBodyImageInfo?.right && profile.fullBodyImageInfo.right.url ? "" : "empty"}`}>
                                                <div
                                                    className="img-wrap"
                                                    onClick={() => {
                                                        if (profile?.fullBodyImageInfo?.right && profile.fullBodyImageInfo.right.url) {
                                                            openViewPopup(profile.fullBodyImageInfo.front.id, "profileImage", "fullBody", "right");
                                                        }
                                                    }}>
                                                    {profile?.fullBodyImageInfo?.right && profile.fullBodyImageInfo.right.url ? (
                                                        <ImageWithWatermarkComponent
                                                            imageUrl={profile.fullBodyImageInfo.right.url}
                                                            watermarkText={userInfo.agencyName}
                                                        />
                                                    ) : (
                                                        <div
                                                            onClick={() => {
                                                                openViewPopup(profile.fullBodyImageInfo.right.id, "profileImage", "fullBody", "right");
                                                            }}
                                                            className="img"
                                                            style={{
                                                                backgroundImage: `url(${
                                                                    profile?.fullBodyImageInfo?.right && profile.fullBodyImageInfo.right.url
                                                                        ? profile.fullBodyImageInfo.right.url.replace(/ /g, "%20")
                                                                        : "./imgs/dummy/basic-img-3.png"
                                                                })`,
                                                            }}></div>
                                                    )}

                                                    <div className="img-empty">
                                                        <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtNoImage")}</p>
                                                        <div className="control-wrap">
                                                            <button
                                                                className="btn-request"
                                                                disabled={isRequestButtonDisabled}
                                                                onClick={() => {
                                                                    createRequestProfile("profileImage", "fullBody", "right");
                                                                }}>
                                                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtRequestImage")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <div className="type">
                                                        <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtRight")}</p>
                                                    </div>

                                                    <div className="date">
                                                        <p>
                                                            {profile?.fullBodyImageInfo?.right?.id
                                                                ? getFormattedDateValue(profile?.fullBodyImageInfo?.right.createdDate, "YYYY.MM.DD")
                                                                : null}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={`item ${profile?.fullBodyImageInfo?.back && profile.fullBodyImageInfo.back.url ? "" : "empty"}`}>
                                                <div
                                                    className="img-wrap"
                                                    onClick={() => {
                                                        if (profile?.fullBodyImageInfo?.back && profile.fullBodyImageInfo.back.url) {
                                                            openViewPopup(profile.fullBodyImageInfo.front.id, "profileImage", "fullBody", "back");
                                                        }
                                                    }}>
                                                    {profile?.fullBodyImageInfo?.back && profile.fullBodyImageInfo.back.url ? (
                                                        <ImageWithWatermarkComponent
                                                            imageUrl={profile.fullBodyImageInfo.back.url}
                                                            watermarkText={userInfo.agencyName}
                                                        />
                                                    ) : (
                                                        <div
                                                            onClick={() => {
                                                                openViewPopup(profile.fullBodyImageInfo.back.id, "profileImage", "fullBody", "back");
                                                            }}
                                                            className="img"
                                                            style={{
                                                                backgroundImage: `url(${
                                                                    profile?.fullBodyImageInfo?.back && profile.fullBodyImageInfo.back.url
                                                                        ? profile.fullBodyImageInfo.back.url.replace(/ /g, "%20")
                                                                        : "./imgs/dummy/basic-img-3.png"
                                                                })`,
                                                            }}></div>
                                                    )}

                                                    <div className="img-empty">
                                                        <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtNoImage")}</p>
                                                        <div className="control-wrap">
                                                            <button
                                                                className="btn-request"
                                                                disabled={isRequestButtonDisabled}
                                                                onClick={() => {
                                                                    createRequestProfile("profileImage", "fullBody", "back");
                                                                }}>
                                                                {getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtRequestImage")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <div className="type">
                                                        <p>{getLanguageValue(PAGE_TYPE.AGENCY, "wannabeProfile.txtBack")}</p>
                                                    </div>

                                                    <div className="date">
                                                        <p>
                                                            {profile?.fullBodyImageInfo?.back?.id
                                                                ? getFormattedDateValue(profile?.fullBodyImageInfo?.back.createdDate, "YYYY.MM.DD")
                                                                : null}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="floating-bar">
                        <div className="wrap">
                            <div className="chat-request-btn">
                                {messageRequest ? (
                                    messageRequest.messageStatusCode === "messageType:accept" ? (
                                        <button
                                            ref={messageBtnRef}
                                            onClick={() => {
                                                messageBtnRef.current.disabled = true;
                                                navigate(`/agency/message/${messageRequest.channelId}`, {
                                                    state: {
                                                        schoolName: studentInfo.schoolInfo?.name,
                                                    },
                                                });
                                                messageBtnRef.current.disabled = false;
                                            }}>
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnGoToMessagePage")}
                                        </button>
                                    ) : messageRequest.messageStatusCode === "messageType:request" ? (
                                        <button
                                            ref={messageBtnRef}
                                            onClick={(e) => {
                                                cancelRequest();
                                            }}>
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnCancelRequest")}
                                        </button>
                                    ) : (
                                        <button
                                            ref={messageBtnRef}
                                            onClick={(e) => {
                                                createRequest();
                                            }}>
                                            {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnRequestMessage")}
                                        </button>
                                    )
                                ) : (
                                    <button
                                        ref={messageBtnRef}
                                        onClick={(e) => {
                                            createRequest();
                                        }}>
                                        {getLanguageValue(PAGE_TYPE.COMMON, "studentDetail.btnRequestMessage")}
                                    </button>
                                )}
                            </div>

                            <div className="btn-con">
                                <button
                                    className={isFavorited ? "favorite-btn" : "favorite-disabled-btn"}
                                    ref={btnRef}
                                    onClick={(evt) => changeFavoriteStatus()}>
                                    <img src={isFavorited ? "./imgs/student/home/favorite-btn-2.png" : "./imgs/student/home/favorite-btn-1.png"} />
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* <!-- 이미지 클릭시 팝업 --> */}
                    {isShowPopup ? (
                        <div className="popup popup-wannabe-info">
                            <div
                                className="close-btn"
                                onClick={() => {
                                    setIsShowPopup(false);
                                }}></div>
                            <h2>
                                {`${getLanguageValue(
                                    PAGE_TYPE.AGENCY,
                                    `wannabeProfile.${categoryCode === "fullBody" ? "txtBodyImgae" : "txtFaceImage"}`
                                )} - ${getLanguageValue(PAGE_TYPE.AGENCY, `wannabeProfile.txt${typeCode.charAt(0).toUpperCase()}${typeCode.slice(1)}`)}`}
                            </h2>
                            <div className="wrap">
                                <Slider ref={sliderRef} {...settings}>
                                    {popupList.map((item: any) => (
                                        <>
                                            <ImageWithWatermarkComponent imageUrl={item.url} watermarkText={userInfo.agencyName} />
                                        </>
                                    ))}
                                </Slider>
                                {popupList.length > 1 ? (
                                    <>
                                        <button className="custom-prev" onClick={(evt) => sliderRef.current.slickPrev()} />
                                        <button className="custom-next" onClick={(evt) => sliderRef.current.slickNext()} />
                                    </>
                                ) : null}
                            </div>
                        </div>
                    ) : null}
                </>
            )}
        </main>
    );
};

export default WannabeProfileComponent;
