import React from "react";
import { getFormattedDateValue } from "helpers/Datetime.helper";
import { getCurrentLangName } from "helpers/String.helper";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";
import StudentEvaluationGraphContainer from "commons/evaluation/StudentEvalutionGraph.container";
import TaggedStudentListPopup from "../portfolio/TaggedStudentListPopup";
import LoadingComponent from "commons/loading/Loading.component";
import YouTube, { YouTubeProps } from "react-youtube";

const StudentDetailComponent = (props: any) => {
  const {
    id,
    pageParam,
    searchParam,
    genderParam,
    verifiedParam,
    navigate,
    evaluationList,
    evaluationCategory,
    studentInfo,
    studentPortfolioList,
    initialSlideIdx,
    isShowPopup,
    setIsShowPopup,
    showImagePopup,
    sliderRef,
    handleCertificationChange,
    deleteAccount,
    isStudentShowLoading,
    isPortfolioShowLoading,
    isRecentlyEvaluationShowLoading,
    isShowTaggedStudentPopup,
    setIsShowTaggedStudentPopup,
    taggedStudentPopupInfo,
    setTaggedStudentPopupInfo,
    setStudentPortfolioList,
  } = props;

  const opts: YouTubeProps["opts"] = {
    height: "100%",
    width: "100%",
    playerVars: {},
  };

  const Arrow = () => {
    return <div />;
  };

  const settings = {
    className: "slider",
    infinite:
      studentInfo.imageInfoList && studentInfo?.imageInfoList.length > 1
        ? true
        : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialSlideIdx,
    prevArrow: <Arrow />,
    nextArrow: <Arrow />,
  };

  return (
    <main className="">
      <div className="btn-wrap">
        <div
          className="back-btn"
          onClick={() => {
            navigate(
              `/school/student?page=${pageParam}&search=${searchParam}&gender=${genderParam}&verified=${verifiedParam}`
            );
          }}></div>
      </div>

      <div className="school-student-detail">
        {isStudentShowLoading ? (
          <LoadingComponent />
        ) : (
          <>
            <section className="profile">
              <div className="profile-photo">
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(${
                      studentInfo && studentInfo.profileImageInfo
                        ? studentInfo.profileImageInfo.url.replace(
                            / /g,
                            "%20"
                          ) + "?w=900&h=900"
                        : "./imgs/dummy/basic-img-2.png"
                    })`,
                  }}></div>
              </div>
              <div className="info">
                <ul>
                  <li>
                    <div className="nationality">
                      <p>
                        {studentInfo.countryCodeInfo
                          ? getCurrentLangName(
                              studentInfo.countryCodeInfo.names
                            )
                          : ""}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="name">
                      <p>
                        {studentInfo.firstName} {studentInfo.lastName}(
                        {studentInfo.genderTypeCodeInfo
                          ? getCurrentLangName(
                              studentInfo.genderTypeCodeInfo.names
                            )
                          : ""}
                        )
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="birthday">
                      <p>
                        {getFormattedDateValue(
                          studentInfo.birthday,
                          "YYYY.MM.DD"
                        )}
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="specialty">
                      {studentInfo.specialtyNamesList
                        ? studentInfo.specialtyNamesList.map((item: any) => (
                            <div className="specialty-tip">
                              <p>{getCurrentLangName(item)}</p>
                            </div>
                          ))
                        : null}
                    </div>
                  </li>
                  {studentInfo.height ? (
                    <li>
                      <div className="height">
                        <p>
                          {getLanguageValue(
                            PAGE_TYPE.SCHOOL,
                            "studentDetail.txtHeight"
                          )}{" "}
                          : {studentInfo.height}cm
                        </p>
                      </div>
                    </li>
                  ) : null}
                  {studentInfo.weight ? (
                    <li>
                      <div className="weight">
                        <p>
                          {getLanguageValue(
                            PAGE_TYPE.SCHOOL,
                            "studentDetail.txtWeight"
                          )}{" "}
                          : {studentInfo.weight}kg
                        </p>
                      </div>
                    </li>
                  ) : null}
                  {studentInfo.shoeSize ? (
                    <li>
                      <div className="shoe-size">
                        <p>
                          {getLanguageValue(
                            PAGE_TYPE.SCHOOL,
                            "studentDetail.txtShoeSize"
                          )}{" "}
                          : {studentInfo.shoeSize}mm
                        </p>
                      </div>
                    </li>
                  ) : null}
                  {studentInfo.bloodTypeCodeInfo ? (
                    <li>
                      <div className="blood-type">
                        <p>
                          {getLanguageValue(
                            PAGE_TYPE.SCHOOL,
                            "studentDetail.txtBloodType"
                          )}{" "}
                          :{" "}
                          {studentInfo.bloodTypeCodeInfo
                            ? getCurrentLangName(
                                studentInfo.bloodTypeCodeInfo.names
                              )
                            : ""}
                        </p>
                      </div>
                    </li>
                  ) : null}
                </ul>
              </div>
            </section>
            {studentInfo.imageInfoList &&
            studentInfo.imageInfoList.length > 0 ? (
              <section className="additional-photos">
                <div className="title">
                  <h2>
                    {getLanguageValue(
                      PAGE_TYPE.SCHOOL,
                      "studentDetail.txtAdditionalPhotos"
                    )}
                  </h2>
                </div>
                <ul>
                  {studentInfo.imageInfoList.map(
                    (imageInfo: any, index: number) => (
                      <li key={index} onClick={(evt) => showImagePopup(index)}>
                        <div
                          className="img"
                          style={{
                            backgroundImage: `url(${
                              imageInfo
                                ? imageInfo.url.replace(/ /g, "%20") +
                                  "?w=600&h=600"
                                : ""
                            })`,
                          }}></div>
                      </li>
                    )
                  )}
                </ul>
              </section>
            ) : null}
          </>
        )}

        {isRecentlyEvaluationShowLoading ? (
          <LoadingComponent />
        ) : evaluationList && evaluationList.length > 0 ? (
          <section className="evaluation">
            <div className="title">
              <h2>
                {getLanguageValue(
                  PAGE_TYPE.SCHOOL,
                  "studentDetail.txtEvaluationTitle"
                )}
              </h2>
            </div>
            <div className="summary">
              <dl>
                {evaluationCategory &&
                  evaluationCategory.map((category: any) => {
                    const matchingEvaluation = evaluationList.find(
                      (evaluation: any) =>
                        evaluation.evaluationCategoryCode === category.code
                    );
                    return matchingEvaluation ? (
                      <React.Fragment key={matchingEvaluation.id}>
                        <dt>{getCurrentLangName(category.names)}</dt>
                        <dd>{matchingEvaluation.evaluation}</dd>
                      </React.Fragment>
                    ) : null;
                  })}
              </dl>
            </div>

            <StudentEvaluationGraphContainer id={id} />
          </section>
        ) : null}

        {isPortfolioShowLoading ? (
          <LoadingComponent />
        ) : studentPortfolioList && studentPortfolioList.length > 0 ? (
          <section className="portfolio">
            <div className="title">
              <h2>
                {getLanguageValue(
                  PAGE_TYPE.SCHOOL,
                  "studentDetail.txtTaggedPortfolio"
                )}
              </h2>
            </div>

            <ul>
              {studentPortfolioList.map((portfolioItem: any) => (
                <li>
                  <div className="item">
                    <div className="date">
                      <p>{portfolioItem.performedDate.replaceAll("-", ".")}</p>
                    </div>
                    <div className="info">
                      {/* <div className="img">
                        <iframe
                          width={`100%`}
                          height={`100%`}
                          src={`https://www.youtube.com/embed/${portfolioItem.youtubeId}`}
                        />
                      </div>
                      <div className="name">
                        <p className="ellipsis">{portfolioItem.title}</p>
                      </div> */}
                      <YouTube
                        className="img"
                        videoId={portfolioItem.youtubeId}
                        opts={opts}
                        onStateChange={(e: any) => {
                          // player state
                          // 시작되지 않음: -1, 종료: 0, 재생 중: 1, 일시중지: 2, 버퍼링: 3, 동영상 신호: 5
                          const videoState = e.target.getPlayerState();
                          const newList = [...studentPortfolioList];
                          const selectedItem = newList.find(
                            (v: any) => v.id === portfolioItem.id
                          );
                          // 재생중일 때만 체크
                          selectedItem.hideTitle =
                            videoState === 1 ||
                            videoState === 2 ||
                            videoState === 3;
                          setStudentPortfolioList(newList);
                        }}
                      />
                      {/* 재생중일 때 제목 영역 비노출*/}
                      {!portfolioItem.hideTitle ? (
                        <div className="name">
                          <p className="ellipsis">{portfolioItem.title}</p>
                        </div>
                      ) : null}
                    </div>
                    <div className="tag-list">
                      <ul
                        onClick={(evt) => {
                          setTaggedStudentPopupInfo(portfolioItem);
                          setIsShowTaggedStudentPopup(true);
                        }}>
                        {portfolioItem.studentInfoList?.length > 0 &&
                          portfolioItem.studentInfoList
                            .filter((_: any, idx: number) => idx < 4)
                            .map((item: any, key: number) => (
                              <li key={`student-${key}`}>
                                <div className="item">
                                  <div
                                    className="img"
                                    style={{
                                      backgroundImage: `url(${
                                        item.imageUrl
                                          ? item.imageUrl.replace(/ /g, "%20") +
                                            "?w=100&h=100"
                                          : "imgs/dummy/basic-img-2.png"
                                      })`,
                                    }}></div>
                                </div>
                              </li>
                            ))}
                        {portfolioItem.studentInfoList.length > 4 ? (
                          <li>
                            <div className="item">
                              <div className="text">
                                <p>
                                  +{portfolioItem.studentInfoList.length - 4}
                                </p>
                              </div>
                            </div>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </section>
        ) : null}
        <div className="control">
          <div className="top">
            <label>
              <input
                type="checkbox"
                checked={studentInfo.isVerified === 1}
                onChange={(e) =>
                  handleCertificationChange(e.target.checked ? 1 : 0)
                }
              />
              <p>
                {getLanguageValue(
                  PAGE_TYPE.SCHOOL,
                  "studentDetail.txtCertificatedBySchool"
                )}
              </p>
            </label>
          </div>
          <div className="bottom">
            <div className="col">
              <button className="btn btn-remove" onClick={deleteAccount}>
                {getLanguageValue(PAGE_TYPE.SCHOOL, "studentDetail.txtRemove")}
              </button>
            </div>
            <div className="col">
              <button
                className="btn btn-edit"
                onClick={() => {
                  navigate(`/school/student/${id}/edit`);
                }}>
                {getLanguageValue(PAGE_TYPE.SCHOOL, "studentDetail.txtEdit")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {isShowPopup ? (
        <div className="popup popup-student-info">
          <div
            className="close-btn"
            onClick={(evt) => setIsShowPopup(false)}></div>
          <h2>
            {getLanguageValue(PAGE_TYPE.SCHOOL, "studentDetail.txtPhotos")}
          </h2>
          <div className="wrap">
            <Slider ref={sliderRef} {...settings}>
              {studentInfo.imageInfoList.map((item: any) => (
                <img className="img" src={item.url} />
              ))}
            </Slider>
            {studentInfo.imageInfoList.length > 1 ? (
              <>
                <button
                  className="custom-prev"
                  onClick={(evt) => sliderRef.current.slickPrev()}
                />
                <button
                  className="custom-next"
                  onClick={(evt) => sliderRef.current.slickNext()}
                />
              </>
            ) : null}
          </div>
        </div>
      ) : null}

      {/* 태그된 학생 팝업 */}
      {isShowTaggedStudentPopup ? (
        <TaggedStudentListPopup
          info={taggedStudentPopupInfo}
          setIsShowTaggedStudentPopup={setIsShowTaggedStudentPopup}
        />
      ) : null}
    </main>
  );
};

export default StudentDetailComponent;
