import React from "react";
import { getLanguageValue } from "locales/i18n";
import { PAGE_TYPE } from "enums";

const CheckPasswordComponent = (props: any) => {
    const { password, setPassword, onClickBtnCheckPassword, isButtonDisabled } = props;

    return (
        <main className="check-password">
            <div className="con">
                <h2>PASSWORD</h2>
                <div className="form">
                    <div className="con input">
                        <div className="input-box">
                            <label>{getLanguageValue(PAGE_TYPE.COMMON, "checkPassword.txtPassword")}</label>
                            <input type="password" onChange={(evt) => setPassword(evt.target.value)} placeholder="" />
                        </div>
                        <div className="btn">
                            <div className="next-btn">
                                <button onClick={(evt) => onClickBtnCheckPassword()} disabled={!password || isButtonDisabled}>
                                    {getLanguageValue(PAGE_TYPE.COMMON, "checkPassword.btnNext")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default CheckPasswordComponent;
